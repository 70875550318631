import { OnInit, Component, EventEmitter, Input, Output, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { Subscription } from "rxjs";
import { Userresponsedto } from "src/app/_models/user/userresponsedto";
import { DatePipe } from "@angular/common";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { Claims } from "src/app/_models/common/claims";
import { HowDoIVideoLinks } from "src/app/common/commonvariables";
import { BaseComponent } from "src/app/shared/base/base-component";
import { AlertService } from "src/app/alert-panel/alert.service";
import { SelectForignCurrencyPopupModel } from 'src/app/_models/claimant-on-web/claimant-on-web';
import { CurrencyService } from "src/app/_services/claimant/currency.service";
import { FormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ApiRequest, FxRateRule, IApiresponse } from "../../../../_models";
import { environment } from "../../../../../environments/environment";
import { OrganisationSettingService } from "../../../../_services/organisation-setting/organisation-setting.service";

@Component({
    selector: "select-foreign-currency-panel",
    templateUrl: "./select-foreign-currency-panel.component.html",
    styleUrls: ["./select-foreign-currency-panel.component.scss"],
})
export class SelectForeignCurrencyPanelComponent extends BaseComponent implements OnInit, OnDestroy {

    @Input()
    inputData: SelectForignCurrencyPopupModel;

    @Output() closePanelEvent = new EventEmitter<boolean>();
    @Output() selectItemEvent = new EventEmitter<SelectForignCurrencyPopupModel>();

    claimsSubscription: Subscription;
    userActionEventSubscription: Subscription;
    currentUser: Userresponsedto;
    claims: Claims;
    param: any;
    howDoIVideoLinks = HowDoIVideoLinks;

    foreignCurrencyList = <any[]>[];
    foreignCurrencyForm: UntypedFormGroup; 
    isForeignCurrencyFormSubmitted: boolean = false;
    disableSubmitButton: boolean = false;
    devideValue: number = 1;
    baseCurrency: any = {icon:''};
    fxRateRule:number;
    fxRateSubRule:number;
   
    get FxRateRule() {
        return FxRateRule;
      }
    constructor(
        public datepipe: DatePipe,
        private claimsService: ClaimsService,
        private alertService: AlertService,
        private currencyService: CurrencyService,
        public fb: FormBuilder,
        private organisationSettingService: OrganisationSettingService,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
        super();
        this.claimsSubscription = this.claimsService.currentClaims.subscribe((claims) => {
            this.claims = claims;
        });
        this.userActionEventSubscription = this.alertService.getUserActionEvent().subscribe(action => {
            if (action) {
                this.closePanelEvent.emit(true);
            }
        });
        
        this.GetOrganisationPreferences();
       
    }
    ngOnInit(): void {
        this.foreignCurrencyForm = this.fnCreateForeignCurrencyForm();
        this.getBaseCurrency();
   
        this.fnGetForeignCurrencyList();                 
        
    }

    GetOrganisationPreferences() {
        let request = new ApiRequest<any>(
          "GetOrganisationPreferences",
          environment.applicationVersion,
          environment.requestId
        );
        this.organisationSettingService
          .GetOrganisationPreferences(request)
          .subscribe((data: IApiresponse<any>) => {
            if (data.statusCode == 0) {          
              this.fxRateRule = data.responseInfo.fxRateRule;
              this.fxRateSubRule = data.responseInfo.fxRateSubRule;
                 
            }
          });
      }

    ngOnDestroy(): void {
        this.claimsSubscription.unsubscribe();
        this.userActionEventSubscription.unsubscribe();
    }

    canDeactivate(): boolean {
        return !this.foreignCurrencyForm.dirty;
    }

    fnClosePanel() {
        if (this.canDeactivate()) {
            this.closePanelEvent.emit(true);
        }
        else {
            this.alertService.onFormClosed();
        }
    }
    getBaseCurrency() {
    this.currencyService.getAllExpenseCurrency(this.claims.OrgId)
            .subscribe((data: any) => {
                if (data && data.length > 0) {
                    this.baseCurrency = data.filter(item => item.currencyId == this.inputData.currencyId)[0];                    
                    if(this.inputData.baseCurrencyIcon)
                    {
                        this.foreignCurrencyForm.controls.currencyCode.setValue(this.baseCurrency.description.split(' - ')[1] + ' - ' +this.baseCurrency.description.split(' - ')[0], {onlySelf: true});                        
                    }          
                }
            });
    }

    fnGetForeignCurrencyList() {
        this.currencyService.getAllExpenseCurrency(this.claims.OrgId, this.inputData.expenseDate,true)
                .subscribe((dataForeign: any) => {
                    if (dataForeign && dataForeign.length > 0) {      
                        this.foreignCurrencyList = dataForeign.filter(item => item.currencyId !== this.inputData.currencyId); 
                        this.foreignCurrencyList.forEach(element => {
                            element.currencyDescription = element.description.split(' - ')[1] + ' - ' + element.description.split(' - ')[0]
                        });                        
                    }
                    this.changeDetectorRef.detectChanges();
                });
        }
    fnCreateForeignCurrencyForm() {
        let form = this.fb.group({
            currencyId: [this.inputData.currencyId ? this.inputData.currencyId : null, [Validators.required]],
            currencyCode: [this.inputData.currencyCode ? this.inputData.currencyCode : null, [Validators.required]],
            spentCurrencyId: [this.inputData.spentCurrencyId ? this.inputData.spentCurrencyId : null, [Validators.required]],
            spentCurrencyCode: [this.inputData.spentCurrencyCode ? this.inputData.spentCurrencyCode : null, [Validators.required]],
            conversionRate: [this.inputData.spentCurrencyId == null || this.inputData.spentCurrencyId == 0 ? null : this.inputData.conversionRate, [Validators.required]]
        });
        
        return form;
    }
    fnSubmitForeignCurrency() {
        this.disableSubmitButton = true;

        if (!Number(this.foreignCurrencyForm.value.conversionRate)) {
            this.foreignCurrencyForm.controls.conversionRate.setErrors({ 'required': true });
        }

        if (this.foreignCurrencyForm.invalid) {
            this.isForeignCurrencyFormSubmitted = true;
            this.disableSubmitButton = false;
            return;
        } else {
            //emit submit event
            let selectForignCurrencyOutputModel = new SelectForignCurrencyPopupModel();
            selectForignCurrencyOutputModel.currencyId = this.foreignCurrencyForm.value.currencyId;
            selectForignCurrencyOutputModel.currencyCode = this.foreignCurrencyForm.value.currencyCode;
            selectForignCurrencyOutputModel.spentCurrencyId = this.foreignCurrencyForm.value.spentCurrencyId;
            selectForignCurrencyOutputModel.spentCurrencyCode = this.foreignCurrencyForm.value.spentCurrencyCode;
            selectForignCurrencyOutputModel.conversionRate = this.foreignCurrencyForm.value.conversionRate;

            this.selectItemEvent.emit(selectForignCurrencyOutputModel);
        }

    }
    fnGetSpentCurrencyCode(currencyId) {
        if (currencyId) {
            let currecnyDetails = this.foreignCurrencyList.find(item => item.currencyId == currencyId);
            if (currecnyDetails) {
                this.foreignCurrencyForm.patchValue({
                    spentCurrencyCode: currecnyDetails.currencyDescription,
                    conversionRate :currecnyDetails.exchangeRate.replace(/,/g, '.') 
                });
            }
        }
    }
    fnCalculateConversionRates() {
        let amount = '';
        if (Number(this.foreignCurrencyForm.value.conversionRate)) {
            amount = (this.devideValue / parseFloat(this.foreignCurrencyForm.value.conversionRate)).toFixed(6);
        }
        return amount;
    }    
}
