import { formatDate } from "@angular/common";
import { OnInit, Component, Output, EventEmitter,Input, ChangeDetectorRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BaseComponent } from "src/app/shared/base/base-component";
import { AdvancePassbookItem } from "src/app/_models/advances/advances";
import { ExpenseCurrency } from "src/app/_models/claimant-on-web/claimant-on-web";
import { ExpenseCategoryRequest, ExpenseCategoryRequestInfo } from "src/app/_models/claimant/expense-category-request";
import { Claims } from "src/app/_models/common/claims";
import { CurrencyService, ExpenseCategoryService } from "src/app/_services";
import { AdvancesService } from "src/app/_services/advances/advance-service";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "advance-passbook-panel",
    templateUrl: "./advance-passbook-panel.component.html"
})
export class AdvancePassbookPanelComponent extends BaseComponent implements OnInit {

    @Output() closePanelEvent = new EventEmitter<boolean>();

    @Input()
    currencyCode: number;
    
    advanceBalance: number;
    claims: Claims;
    categoryList: any;
    advanceClaimantList: any[] = [];
    balanceAdvanceAmount: number;
    currencytList: ExpenseCurrency[];
    param: any;

    constructor(private expenseCategoryService: ExpenseCategoryService,
        private advancesService: AdvancesService, private claimsService: ClaimsService,
        private translate: TranslateService, private currencyService: CurrencyService,
        private cdr:ChangeDetectorRef
    ) {
        super();

        this.claimsService.currentClaims.subscribe((claims) => {
            this.claims = claims;
        });
    }

    ngOnInit(): void { this.getAdvancePassbook(); this.getAdvancePassbookBalance(); this.getCurrrencyList(); }

    getAdvancePassbook() {
        let request = new ExpenseCategoryRequest();
        request.WebApiName = "GetExpenseCategoryByID";
        request.WebApiVersion = environment.applicationVersion;
        request.RequestId = Number(environment.requestId);
        request.RequestInfo = new ExpenseCategoryRequestInfo();
        request.RequestInfo.OrgId = this.claims.OrgId;
        request.RequestInfo.LastUpdatedTimeStamp = 0;
        this.currencyService.getAllExpenseCurrency(this.claims.OrgId)
            .subscribe((data: any) => {
                if (data && data.length > 0) {
                    this.currencytList = data;
                    this.expenseCategoryService.getOrgExpenseCategory(request).subscribe((response) => {
                        if (response.responseInfo != null) {
                            this.categoryList = response.responseInfo.categories;

                            this.advancesService.getAdvancesPassbook(this.claims.UserId).subscribe(result => {
                                if (result) {

                                    this.advanceClaimantList = [];
                                    result.forEach(row => {
                                        this.advanceClaimantList.push({
                                            passbookId: row.advancePassbookId,
                                            advanceId: row.advanceId,
                                            expenseId: row.expenseId,
                                            date: row.createdDate,
                                            dateString: formatDate(row.createdDate, 'dd-MMM-yyyy', 'en-US'),
                                            details: this.getCategoryDetail(row),
                                            creditCardAmount: row.categoryId == 2 && row.advanceId ? row.amount.toFixed(2) : '',
                                            cashAmount: row.amount.toFixed(2),
                                            expenseAmount: row.expenseId || row.advanceReturnId ? row.amount.toFixed(2) : '',
                                            balance: row.advanceBalance.toFixed(2),
                                            hasReceipt: row.hasReceipt,
                                            conversionRate: row.conversionRate,
                                            spentCurrencyId: row.spentCurrencyId ?? 0,
                                            currencyId: row.currencyId,
                                            spentAmount: row.spentAmount,
                                            currencyCode: this.getCurrencyName(row.spentCurrencyId),
                                            advanceReturnId: row.advanceReturnId,
                                            categoryId: row.categoryId,
                                            isLinkedToCC: row.isLinkedToCreditCardTransaction
                                        });
                                    });
                                }

                                this.cdr.detectChanges();
                            });
                        }
                    });
                }
            });
    }

    getCurrrencyList() {
        this.currencyService.getAllExpenseCurrency(this.claims.OrgId)
            .subscribe((data: any) => {
                if (data && data.length > 0) {
                    this.currencytList = data;
                    this.cdr.detectChanges();
                }
            });
    }

    getCurrencyName(currencyId: number) {
        if (this.currencytList) {
            return this.currencytList.filter(a => a.currencyId == currencyId).map(function (b) { return b.currencyCode })[0];
        }
    }

    getCategoryDetail(row: AdvancePassbookItem): string {
        let details: string = '';
        if (row.advanceId) {
            if (row.categoryId == 1) {
                details = this.translate.instant('cash_advance_title');
            }
            else if (row.categoryId == 2) {
                details = this.translate.instant('cash_on_cc_advance');
            }
        }
        else if (row.expenseId) {
            const category = this.categoryList.find(c => c.orgExpenseCategoryId == row.categoryId);
            if (category) {
                details = category.expenseCategoryName;
            }
        }
        else if (row.advanceReturnId) {
            details = this.translate.instant('advance_balance_return');
        }
        return details;
    }


    getAdvancePassbookBalance() {
        this.advancesService.getAdvancePassbookBalance().subscribe(result => {
            if (result && result.responseInfo) {

                this.balanceAdvanceAmount = result.responseInfo.balance;
            }
            else {
                this.balanceAdvanceAmount = 0;
            }
            this.cdr.detectChanges();
        });
    }

    fnClosePanel() {
        this.closePanelEvent.emit(true);
    }


    canDeactivate(): boolean {
        return true;
    }
}

