import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';

@Component({
    selector: 'formly-advance-policy-violation',
    templateUrl: "./formly-advance-policy-violation.component.html"
})
export class FormlyAdvancePolicyViolationComponent extends FieldType implements OnInit, OnDestroy {

    isFormSubmitted: boolean = false;
    formSubmissionSubscription: Subscription;
    fieldKey: string;
    openPanelSubscription: Subscription;

    showPolicyViolationPanel: boolean = false;
    policyViolationInputModel: any;

    constructor(private dynamicFormService: DynamicFormService) {
        super();
    }

    ngOnInit() {
        if (this.field.key) {
            this.fieldKey = this.field.key.toString();
        }
        this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
            this.isFormSubmitted = data;
        });
        this.openPanelSubscription = this.dynamicFormService.getOpenPanelEvent().subscribe(key => {
          if (key == this.field.key) {
            this.fnOpenPolicyViolationPanel();
          }
        });
    }

    ngOnDestroy() {
        if (this.formSubmissionSubscription) {
            this.formSubmissionSubscription.unsubscribe();
        }
        if(this.openPanelSubscription) {
          this.openPanelSubscription.unsubscribe();
        }
    }

    fnOpenPolicyViolationPanel() {
      if(this.model.amount) {

        this.policyViolationInputModel = {
          justificationNotes: this.model.justificationNotes ? this.model.justificationNotes : '',
          amount: this.model.amount,
          advanceBalance: this.model.passbookBalance,
          advanceLimit: this.model.advanceLimit,
          advanceOverLimit: this.model.advanceOverLimit,
          disableJustificationNotes: true
        };
        this.showPolicyViolationPanel = true;
      }
    }

    fnClosePolicyViolationPanel(justificationNotes) {
      this.policyViolationInputModel = null;
      this.showPolicyViolationPanel = false;
      if (justificationNotes) {
        this.to.change(this.field, justificationNotes);
      }
    }

}
