import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";
import { BaseComponent } from "../shared/base/base-component";
import { AlertService } from "../alert-panel/alert.service";
import { Observable } from "rxjs";


@Injectable()
export class CanDeactivateGuard implements CanDeactivate<BaseComponent> {

    constructor(private alertService: AlertService) {
    }

    canDeactivate(component: BaseComponent): boolean | Observable<boolean> {
        if (!component.canDeactivate()) {
            this.alertService.onFormClosed();
            return this.alertService.getUserActionEvent();
        }
        return true;
    }
}