import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AssociateCreditCardExpenseInputModel, AssociateCreditCardExpenseOutputModel } from 'src/app/_models/claimant-on-web/claimant-on-web';
import { AlertPanelModel } from 'src/app/_models/common/alertPanelModel';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';

@Component({
    selector: 'formly-expense-amount-input',
    templateUrl: "./formly-expense-amount-input.component.html"
})
export class FormlyExpenseAmountInputComponent extends FieldType implements OnInit, OnDestroy {

    isFormSubmitted: boolean = false;
    formSubmissionSubscription: Subscription;
    fieldKey: string;
    showAdvancePassbookPanel: boolean;

    selectAssociateCategoryAlert = new AlertPanelModel(false, "", [""], "", "", true, "");
    showAssociateCreditCardExpensePanel: boolean = false;
    associateCreditCardExpenseInputModel: AssociateCreditCardExpenseInputModel;
    digitDirective: string = "sixDigitBeforeAndTwoDigitAfterDecimalNumber";
    isSix: boolean = false;

    constructor(private dynamicFormService: DynamicFormService,
      private translate: TranslateService,
      private datePipe: DatePipe, private cdr:ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
      if (this.field.key) {
          this.fieldKey = this.field.key.toString();
      }
      this.digitDirective = "sixDigitBeforeAndTwoDigitAfterDecimalNumber";
      if (this.field?.templateOptions?.digitDirective) {        
        this.digitDirective = this.field?.templateOptions?.digitDirective;
      }   
      this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {        
          this.isFormSubmitted = data;
          this.cdr.detectChanges();
      });
    }

    ngOnDestroy() {
        if (this.formSubmissionSubscription) {
            this.formSubmissionSubscription.unsubscribe();
        }
    }

    onNumberInput() {
        this.to.change(this.field, this.formControl.value);
    }

    fnOpenAssociateCreditCardExpensePanel() {
      if (this.model.orgExpenseCategoryId && this.model.orgExpenseCategoryId > 0) {
        this.associateCreditCardExpenseInputModel = new AssociateCreditCardExpenseInputModel();
        this.associateCreditCardExpenseInputModel.orgExpenseCategoryId = this.model.orgExpenseCategoryId;
        this.associateCreditCardExpenseInputModel.supplier = this.model.supplier;
        this.associateCreditCardExpenseInputModel.expenseId = this.model.cardAssociateId;
        this.associateCreditCardExpenseInputModel.expenseCategoryName = this.model.expenseCategoryName;
        this.associateCreditCardExpenseInputModel.claimantId = this.model.userId;
        this.associateCreditCardExpenseInputModel.claimantName = this.model.userName;

        this.showAssociateCreditCardExpensePanel = true;

      } else {
        this.selectAssociateCategoryAlert = new AlertPanelModel(
          true,
          this.translate.instant("label_alert"),
          [],
          this.translate.instant("text_cancel"),
          this.translate.instant("text_ok"),
          true,
          this.translate.instant("please_select_relevant_category_first_to_associate_this_expense")
        );
      }
    }

    fnCloseAssociateCreditCardExpensePanel() {
      this.showAssociateCreditCardExpensePanel = false;
    }

    fnSelectAssociateCreditCardExpense(data: AssociateCreditCardExpenseOutputModel) {
      if (data) {
        let cardDescription = this.model.cardDescription;
        cardDescription += '\n\n' + this.translate.instant("association_details") +
                          '\n' + this.translate.instant("expense_date") + ": " + this.datePipe.transform(new Date(data.expenseDate), "yyyy-MM-dd") +
                          '\n' + this.translate.instant("amount") + ": " + data.amount + ' ' + data.currencyCode +
                          '\n' + this.translate.instant("supplier") + ": " + data.supplier;

        this.model.cardAssociateId = data.expenseId;
        this.model.cardDescription = cardDescription;

        this.form.controls["cardDescription"].setValue(cardDescription);
      }
      this.showAssociateCreditCardExpensePanel = false;
    }

    selectAssociateCategoryAlertPanelReceiver(returnValue) {
      this.selectAssociateCategoryAlert = new AlertPanelModel(false, "", [""], "", "", true, "");
    }

    numberOnly(event): boolean {
      const charCode = event.which ? event.which : event.keyCode;
      if (this.field?.templateOptions?.allowNotZero && charCode == 48 && !this.formControl.value) {        
        return false;
      }    
      return true;
    }

}
