<div class="row mt-0 border-bottom">
    <div class="col-lg-10 padding-left-0">
        <p class="text-charcoal-black margin-bottom-5"><strong>{{field.templateOptions.label}}</strong> 
            <small class="d-block lH-20">{{field.templateOptions.description}}</small> </p>
    </div>
    <div class="col-lg-2 padding-right-0 position-relative text-right">
        <div class="custom-control custom-switch top--10px right-0">
            <input id="{{ 'cbx' + fieldKey }}" type="checkbox" class="custom-control-input" [checked]="formControl.value"
                        [formControl]="formControl" (input)="onValueChange()" [disabled]="field.templateOptions.disabled">
            <label class="custom-control-label cursor-pointer" for="{{ 'cbx' + fieldKey }}"></label>
        </div>
    </div>
</div>