import { Component, ViewEncapsulation } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: 'custom-actions-renderer',
  templateUrl: './custom-actions-renderer.component.html',
  encapsulation: ViewEncapsulation.None
})

export class CustomActionsRendererComponent implements ICellRendererAngularComp {
  actionButtons: any[] = [];
  params: ICellRendererParams;
  buttonAlignmentClass: string = 'text-right';
  constructor() {

  }

  onUserAction(action) {
    this.params.data.userAction = action;
    this.params.node.setData(this.params.data);
    this.params.eGridCell.click();
  }

  initilize(params: ICellRendererParams) {
    if (params && params.data) {
      this.params = params;
      this.actionButtons = JSON.parse(JSON.stringify(params.colDef.headerComponentParams));      
      for (let i = 0; i < this.actionButtons.length; i++) {
        this.actionButtons[i].isDisabled = this.actionButtons[i].disableProperty && this.params.data[this.actionButtons[i].disableProperty]
        this.actionButtons[i].isHidden = this.actionButtons[i].hideProperty && this.params.data[this.actionButtons[i].hideProperty]
        this.buttonAlignmentClass = this.actionButtons[i].buttonAlignmentClass ?? this.buttonAlignmentClass;       
      }
    }
    else {
      this.actionButtons = [];
    }
  } 

  showEditGradePanelFunction() {

  }
  // called on init
  agInit(params: any): void {
    this.initilize(params);
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.initilize(params);
    return true;
  }

}
