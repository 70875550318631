import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-multi-color-text-renderer',
  templateUrl: './multi-color-text-renderer.component.html'
})
export class MultiColorTextRendererComponent implements ICellRendererAngularComp {

  text: string = '';
  class: string = '';
  dataArray: any[] = [];
  constructor(private sanitizer: DomSanitizer) {

  }
  initilize(params: ICellRendererParams) {
    if (params.data) {
      if (params.data.pageType && params.data.pageType > 0
        && params.data.countArray && params.data.countArray.length > 0) {
        //Create array
        params.data.countArray.forEach(element => {
          this.dataArray.push({
            text: element.text,
            class: element.class,
            ngbPopover: element.ngbPopover,
            ngbPopoverClass: element.ngbPopoverClass
          })
        });
      }
      else {
        this.dataArray.push({
          text: params.data.text,
          class: params.data.class,
          ngbPopover: params.data.ngbPopover,
          ngbPopoverClass: params.data.ngbPopoverClass
        });
      }

    }
  }

  agInit(params: any): void {
    this.initilize(params);
  }

  refresh(params: any): boolean {
    this.initilize(params);
    return true;
  }
  sanitizerHtml(element) {
    return this.sanitizer.bypassSecurityTrustHtml(element);
  }
}


