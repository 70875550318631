import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { OAuthService } from "angular-oauth2-oidc";
import { OrganisationFunctionService } from "../organisation-setting/organisation-function.service";
import { FunctionPrivilegeService } from "../secondary-fm/function-privilege.service";
import { ClaimsService } from "./claims.service";
import { Observable, of } from "rxjs";
import { mergeMap, map } from "rxjs/operators";
import { FMRoleType } from "src/app/_models";
import { environment } from "src/environments/environment";
import { Claims } from "src/app/_models/common/claims";
import { date } from "@rxweb/reactive-form-validators";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService {
  claims: Claims;
  restrictedRoutes = environment.restrictedRoutes;

  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private organisationService: OrganisationFunctionService,
    private functionPrivilegeService: FunctionPrivilegeService,
    private claimsService: ClaimsService
  ) {
    this.claimsService.currentClaims.subscribe((claims) => {
      if (claims) {
        this.claims = claims;
      }
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> {

    if(state.url.split("?")[0].includes('export/summaryofexport')){
      return true;
    }

    if (this.verifyAccessToken() == false) {
      this.router.navigate(["/login"]);
      return false;
    }

    this.claimsService.currentClaims.subscribe((claims) => {
      if (claims) {
        this.claims = claims;
        var url = state.url.split("?")[0];

        if (
          (this.claims && this.claims.orgRoleId == 2) ||
          this.claims.orgRoleId == 1
        ) {
          if (this.restrictedRoutes.filter((r) => url.includes(r)).length > 0) {
            if(!url.includes('claimantfcdashboard'))
              {
                this.fnRedirectUsertoCorrectRoot();
                return of(false);
              }
            
          }
          else if ( this.claims.orgRoleId == 2 && url.includes('approver/appclaimantexpenses/1?claimantID')) {
            return of(true);
          }
        }      

        if (
          this.claims?.isAdvanceBalanceOutstanding &&
          (url == "/dashboard/advances" ||
            url == "/dashboard/advancemanagement")
        ) {
          return true;
        }

        if (url == "/" && this.claims?.orgRoleId != 3) {
          this.fnRedirectUsertoCorrectRoot();
          return of(false);
        }

        return this.organisationService
          .checkFunctionRouteSubscribed(url, this.claims?.OrgId)
          .pipe(
            mergeMap<boolean, Observable<boolean>>((result1) => {
              if (result1) {
                return this.verifyPrivileges(url);
              } else {
                this.fnRedirectUsertoCorrectRoot();
                return of(false);
              }
            })
          );
      }
    });
  }

  verifyPrivileges(url): Observable<boolean> {
    if (this.claims?.FMRoleType == FMRoleType.AllPrivilege) {
      return of(true);
    }

    if (this.claims?.FMRoleType == FMRoleType.NoPrivilege) {
      if (
        environment.fmNoPrivilegeRoutes.filter((r) => url.includes(r)).length >
        0
      ) {
        return of(true);
      }
      this.fnRedirectUsertoCorrectRoot();
      return of(false);
    }

    if (
      environment.restrictedRoutes.filter((r) => url.includes(r)).length > 0
    ) {
      this.fnRedirectUsertoCorrectRoot();
      return of(false);
    }

    return this.functionPrivilegeService
      .checkRoutePrivilegeAccess(url, this.claims?.UserId)
      .pipe(
        map((result2) => {
          if (result2) {
            return true;
          } else {
            this.fnRedirectUsertoCorrectRoot();
            return false;
          }
        })
      );
  }

  fnRedirectUsertoCorrectRoot() {
    if (this.claims && this.claims.orgRoleId == 3) {
      this.router.navigate([""]);
    } else if (this.claims && this.claims.orgRoleId == 2) {      
        this.router.navigate(["/approver/appclaimant/1"]);
    } else {
      this.router.navigate(["/claimant/expenselist"]);
    }
  }

  verifyAccessToken() {
    var hasAccessToken = this.oauthService.hasValidAccessToken();
    var hasAccesTokenExpired =
      this.oauthService.getAccessTokenExpiration() <= new Date().getTime();
    return hasAccessToken && !hasAccesTokenExpired;
  }
}
