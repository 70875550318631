import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AlertPanelModel } from "../_models/common/alertPanelModel";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ClaimsService } from "../_services/common/claims.service";
import { Claims } from "../_models/common/claims";

@Component({
  selector: "app-alert-panel",
  templateUrl: "./alert-panel.component.html"
})
export class AlertPanelComponent implements OnInit {
  @Input() data: AlertPanelModel;
  @Input() isConfirmDisplay: boolean = true;
  
  param: string;
  claims: Claims;
  bodyClass : string = "block padding-top-10 padding-bottom-10";
  hideInfoIcon : boolean = false;
  singleMessageTextClass:string = "";
  constructor(private translate: TranslateService, private sanitizer: DomSanitizer,
    private claimsService: ClaimsService) {
    this.claimsService.currentClaims.subscribe(claims => this.claims = claims);
  }

  ngOnInit() {   
      if(this.data.hideInfoIcon)
      {    
          this.hideInfoIcon = this.data.hideInfoIcon;        
      }
      if(this.data.bodyClass)
      {
        this.bodyClass = this.data.bodyClass;
      }
      if(this.data.singleMessagetextClass)
      {
        this.singleMessageTextClass = this.data.singleMessagetextClass;
      }
  }

  @Output() callbackEvent = new EventEmitter<any>();

  accept() {
    this.callbackEvent.emit(true);
  }

  dismiss()
  {
    this.callbackEvent.emit(false);
  }

  sanitizerHtml(element) {
    let safeText = this.sanitizer.bypassSecurityTrustHtml(element);
    return safeText;
  }

  setClasses(){
    let divClass = '';
    if(this.data.bodyClass)
    {
      divClass = this.data.bodyClass;
    }
    else
    {
      divClass = 'block padding-top-10 padding-bottom-10';
    }
    return divClass;
  }

  fnSendAction(action:number)
  {
    this.callbackEvent.emit(action);
  }

}
