import { Injectable, Inject, forwardRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { OrgSubscription, IOrgSubscription } from '../../_models/common/OrgSubscription';
import { ISaveOrderCartDetailsRequest, SaveOrderCartDetailsRequest } from '../../_models/cart-order/SaveOrderCartDetailsRequest';
import { ISaveOrderCartDetailsResponse, SaveOrderCartDetailsResponse } from '../../_models/cart-order/SaveOrderCartDetailsResponse';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IApiRequest, IApiresponse } from '../../_models';
import { PaymentCustomerAndIntentIdResponse } from 'src/app/_models/stripe/PaymentCustomerAndIntentIdResponse';
import { ISubscriptionCardDetailsRequest } from 'src/app/_models/stripe/SubscriptionCardDetailsRequest';
import { IOrganisationSubscriptionCardDetails } from 'src/app/_models/stripe/OrganisationSubscriptionCardDetails';
import { setHeader } from 'src/app/_helpers/setRequestHeader';
import { ClaimsHeaderService } from 'src/app/shared/claimsHeader/claims-header.service';
import { IStripeRegisterUserResponse } from 'src/app/_models/stripe/stripe';
import { IStripeUserCardResponse } from 'src/app/_models/stripe/StripeUserCardResponse';
import { IOrderCartDetailsResponse, OrderCartDetailsResponse } from '../../_models/cart-order/OrderCartDetailsResponse';


@Injectable({
  providedIn: 'root'
})
export class SaveOrderCartDetailsService {
  constructor(private http: HttpClient, private claimsHeaderService: ClaimsHeaderService) { }

  AddCartOrder(requestinfo: IApiRequest<ISaveOrderCartDetailsRequest>): Observable<IApiresponse<SaveOrderCartDetailsResponse>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<SaveOrderCartDetailsResponse>>(`${environment.catalogApiUrl}SaveOrderCartDetails`, body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  FindNavigationForPayment(requestinfo: IApiRequest<IOrgSubscription>): Observable<IApiresponse<PaymentCustomerAndIntentIdResponse>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<PaymentCustomerAndIntentIdResponse>>(`${environment.billingApiUrl}CheckSubscriptionCCStatus`, body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  AddCardDetails(requestinfo: IApiRequest<ISubscriptionCardDetailsRequest>): Observable<IApiresponse<IOrganisationSubscriptionCardDetails>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IOrganisationSubscriptionCardDetails>>(`${environment.billingApiUrl}AddSubscriptionCardDetails`, body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  getCardDetailsFromStripe(requestinfo: string): Observable<IApiresponse<IStripeUserCardResponse>> {
    return this.http.get<IApiresponse<IStripeUserCardResponse>>(`${environment.billingApiUrl}RegisterUserOnStripe/${requestinfo}`,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  GetStripeCustomerDetailsByOrgId(organisationId: number) {
    let params = new HttpParams();
    params = params.append("orgId", organisationId.toString());

    return this.http.get<any>(
      `${environment.billingApiUrl}GetStripeCustomerDetailsByOrgId`,
      { params: params, headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  getOrderCartDetails(requestinfo: IApiRequest<IOrgSubscription>): Observable<IApiresponse<OrderCartDetailsResponse>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<OrderCartDetailsResponse>>(`${environment.catalogApiUrl}FindOrderCartDetails`, body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  GetOrgVatInfo() {
    let params = new HttpParams();

    return this.http.get<any>(
      `${environment.billingApiUrl}GetOrgVatInfo`,
      { params: params, headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }
}
