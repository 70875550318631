import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-category-icons',
  templateUrl: './category-icons.component.html',
  styleUrls: ['./category-icons.component.scss']
})
export class CategoryIconsComponent implements OnInit {
  @Input('expenseCategoryId') expenseCategoryId: any;
  @Input('className') className: string;
  constructor() { }
  ngOnInit() { }
}
