import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  IMileageWizardRequestDto, IApiRequest, IApiresponse,
  IOrgMileageWizardInfo, ISaveMileageWizardResponseDto
} from '../../_models';
import { ClaimsHeaderService } from 'src/app/shared/claimsHeader/claims-header.service';
import { map } from 'rxjs/operators';
import { ISaveOrgMileageWizardResponse } from 'src/app/_models/advance-mileage/save-org-mileage-wizard-response';

@Injectable({
  providedIn: 'root'
})
export class MileageWizardService {
  //httpHeaders: any = this.header.createAuthorizationHeader();
  constructor(private http: HttpClient, private claimsHeaderService: ClaimsHeaderService) { }


  GetOrgWizardDetails(request: IApiRequest<IMileageWizardRequestDto>): Observable<IApiresponse<IOrgMileageWizardInfo>> {
    const cacheKey = "OrgWizardDetailsCache_" + request.requestInfo.orgId;
    let cacheData = sessionStorage.getItem(cacheKey);
    if (cacheData) {
      return of(JSON.parse(cacheData));
    }
    else {
      let body = JSON.stringify(request);
      // changed to billing
      return this.http.post<IApiresponse<IOrgMileageWizardInfo>>(`${environment.billingApiUrl}GetOrgMileageWizardDetails`,
        body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }).pipe(map(data => {
          sessionStorage.setItem(cacheKey, JSON.stringify(data));
          return data;
        }));
    }
  }

  DropOrgWizardDetailsCache(organisationId) {
    const cacheKey = "OrgWizardDetailsCache_" + organisationId;
    sessionStorage.removeItem(cacheKey);
  }

  SaveOrgWizardDetails(requestinfo: IApiRequest<IOrgMileageWizardInfo>): Observable<IApiresponse<ISaveMileageWizardResponseDto>> {
    let body = JSON.stringify(requestinfo);
    // changed to billing
    return this.http.post<IApiresponse<ISaveMileageWizardResponseDto>>(`${environment.billingApiUrl}SaveOrgMileageWizardDetails`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  SaveOrgCartDetails(requestinfo: IApiRequest<IOrgMileageWizardInfo>): Observable<IApiresponse<ISaveMileageWizardResponseDto>> {
    let body = JSON.stringify(requestinfo);
    // changed to billing
    return this.http.post<IApiresponse<ISaveMileageWizardResponseDto>>(`${environment.billingApiUrl}SaveOrgCartMileageInfo`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  SaveMultipleVehicleSetup(request: IApiRequest<any>): Observable<IApiresponse<ISaveOrgMileageWizardResponse>> {
    let body = JSON.stringify(request);
    return this.http.post<IApiresponse<ISaveOrgMileageWizardResponse>>(`${environment.billingApiUrl}SaveMultipleVehicleSetup`,
      body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }).pipe(map(data => {
        return data;
      }));
  }

}
