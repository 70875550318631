import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { SelectTagsPopupModel } from 'src/app/_models/claimant-on-web/claimant-on-web';

@Component({
  selector: 'formly-tag-input',
  templateUrl: "./formly-tag-input.component.html"
})
export class FormlyTagInputComponent extends FieldType {

  inputModel: SelectTagsPopupModel;
  showCreateTagPanel: boolean = false;

  fnOpenCreateTagPanel() {
    this.inputModel = new SelectTagsPopupModel();
    this.inputModel.tagList = this.formControl.value;
    this.inputModel.claimantId = this.model.userId;
    this.inputModel.claimantName = this.model.userName;
    this.showCreateTagPanel = true;
  }

  fnSelectCreateTag(data) {
    if (data && data.tagList) {
      this.formControl.setValue(data.tagList);
      this.to.change(this.field, data);
    }
    this.fnCloseCreateTagPanel(true);
  }

  fnCloseCreateTagPanel(isClose: boolean) {
    if (isClose) {
      this.showCreateTagPanel = false;
    }
  }

}
