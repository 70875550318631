export interface IDashBoardAPI {
  monthsList: string;
  approvedExpenses: string;
  rejectedExpenses: string;
  pendingInvite: string;
  notInvited: string;
  activeEmp: string;
  mileage: string;
  mileageGoogle: string;
  mileageGPS: string;
  responseInfo: DashBoardAPI;
}
export class DashBoardAPI implements IDashBoardAPI {
  responseInfo: DashBoardAPI;
  monthsList: string;
  approvedExpenses: string;
  rejectedExpenses: string;
  pendingInvite: string;
  notInvited: string;
  activeEmp: string;
  mileage: string;
  mileageGoogle: string;
  mileageGPS: string;
}

export class DashboardDataRequest {
  orgId: number;
  userId: number;
  requestCountType: number;
  loginId: string;
}

