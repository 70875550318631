
import { INRFCJustificationNotesViewModel, NRFCJustificationNotesViewModel } from "./nrfcjustification-notes-view-model";
import { IReceiptInfo, ReceiptInfo } from "../twostepapproval/claimant-expense-detail";
export interface IFCReceiptResponse {
    fcReceiptId: number;
    receiptId: number;
    orgId: number;
    userId: number;
    vehicleId: string;
    openingBalance: number;
    openingBalanceDate: number;
    openingBalanceDateString: string;
    receiptDateString: string;
    closingBalance: number;
    closingBalanceDate: number;
    closingBalanceDateString: string;
    distanceAvailable: number;
    distanceTravelled: number;
    businessDistance: number;
    privateDistance: number;
    notes: string;
    fcCurrentStatus: number;
    submissionDate: number;
    receiptDate: number;
    hasReceipt: number;
    nrfcReceiptInfo: IReceiptInfo[];
    nrfcJustificationNotesViewModel: INRFCJustificationNotesViewModel[];
}


export class FCReceiptResponse implements IFCReceiptResponse {
    fcReceiptId: number;
    receiptId: number;
    orgId: number;
    userId: number;
    vehicleId: string;
    openingBalance: number;
    openingBalanceDate: number;
    openingBalanceDateString: string;
    receiptDateString: string;
    closingBalance: number;
    closingBalanceDate: number;
    closingBalanceDateString: string;
    distanceAvailable: number;
    distanceTravelled: number;
    businessDistance: number;
    privateDistance: number;
    notes: string;
    fcCurrentStatus: number;
    submissionDate: number;
    receiptDate: number;
    hasReceipt: number;
    nrfcReceiptInfo = <ReceiptInfo[]>[];
    nrfcJustificationNotesViewModel = <NRFCJustificationNotesViewModel[]>[];
}

export class FuelCardDetails {
    fcReceiptId: number;
    orgId: number;
    userId: number;
    vehicleId: string;
    openingBalance: number;
    openingBalanceDate: number;
    closingBalance: number;
    closingBalanceDate: number;
    closingBalanceDateString: string;
    distanceAvailable: number;
    distanceTravelled: number;
    businessDistance: number;
    privateDistance: number;
}

