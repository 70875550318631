<ng-select *ngIf="!isShowText" appendTo="body" class="grid-dropdown" [items]="items" bindLabel="value" bindValue="id"
    [clearable]="false" [selectOnTab]="true" [(ngModel)]="value" (change)="onChange($event)"
    [placeholder]="placeholder">

    <ng-template ng-option-tmp let-item="item" let-index="index">
        <div [class.disabled]="item.isDisabled">
            {{ item.value }}
        </div>

    </ng-template>

</ng-select>



<span *ngIf="isShowText"> {{ value }}</span>