<div class="modal fade function-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered box-shadow">
    <div class="modal-content">

      <div class="row modal-content-header">
        <p class="parent-function-name">{{infoModel.title}}</p>
        <p class="child-function-name">{{infoModel.subTitle}}</p>
        <button type="button" class="close" data-dismiss="modal" (click)="close();">&times;</button>
      </div>

      <div class="row">
        <div class="box-para w-100pc">
          <p class="lH-20">
            <span [innerHTML]="sanitizerHtml(infoModel.description)">
            </span>
          </p>
        </div>
      </div>

      <div class="row mt-4">
        <p class="f-20"><strong>{{ "pricing_See_it_action" | translate }}</strong></p>
        <div class="function-video">
          <iframe width="100%" height="275" [src]="infoModel.videoURLs" title="function video"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" frameborder="0"
            webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
        </div>
      </div>

      <div class="row">
        <button (click)="fnBtnClick()" data-dismiss="modal"
          class="btn btn-primary mt-3 mx-auto color-black fw-600">{{infoModel.btnLabel}}
        </button>
      </div>

    </div>
  </div>
</div>