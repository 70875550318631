import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { ClaimsHeaderService } from "src/app/shared/claimsHeader/claims-header.service";
import { Injectable } from "@angular/core";
import {
  IRequestGetCustomer,
  ICustomerResponse,
  IResponseCustomerList,
  ICustomerListRequest,
  IRequestGetCustomersByOrgIdAndStatus,
  ICustomerRequest,
  IBulkActionOnCustomerRequest,
  IRequestGetEmployeesForCustomerRule,
  IResponseGetEmployeesForCustomerRule,
  IBulkActionsForCustomerRuleRequest,
  RequestGetCustomerHistory,
  CustomerHistoryResponse,
  ICustomerCsvRecordExport,
  CustomerCsvRecordExport,
  IRequestGetCustomersByCustomerIdsOrgId,
  IGetCustomerResponse,
  EmployeeListForCustomerCostCentreRuleAssignmentResponse,
  GetEmployeeForCustomerCostCentreRuleAssignmentRequest,
  AssignCustomerCostCentreAccessRuleToEmployeesRequest,
  CustomerResponse,
  IBulkSaveCustomerRequest,
  IBulkSaveCustomerResponse,
} from "src/app/_models/customer/customer";
import { IApiRequest, IApiresponse } from "src/app/_models";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ExcelExportService } from "../common/excel-export.service";

@Injectable({
  providedIn: "root",
})
export class CustomerService {
  httpHeaders: HttpHeaders;
  constructor(
    private http: HttpClient,
    private claimsHeaderService: ClaimsHeaderService,
    private excelExportService: ExcelExportService
  ) {}

  GetCustomerById(
    requestInfo: IApiRequest<IRequestGetCustomer>
  ): Observable<IApiresponse<ICustomerResponse>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<ICustomerResponse>>(
      `${environment.billingApiUrl}GetCustomerById`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetCustomerByOrgId(
    requestInfo: IApiRequest<ICustomerListRequest>
  ): Observable<IApiresponse<IResponseCustomerList>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<IResponseCustomerList>>(
      `${environment.billingApiUrl}GetCustomerByOrgId`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetCustomersByCustomerIdsOrgId(
    requestInfo: IApiRequest<IRequestGetCustomersByCustomerIdsOrgId>
  ): Observable<IApiresponse<ICustomerResponse[]>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<ICustomerResponse[]>>(
      `${environment.billingApiUrl}GetCustomersByCustomerIdsOrgId`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetCustomersByOrgIdAndStatus(
    requestInfo: IApiRequest<IRequestGetCustomersByOrgIdAndStatus>
  ): Observable<IApiresponse<ICustomerResponse[]>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<ICustomerResponse[]>>(
      `${environment.billingApiUrl}GetCustomersByOrgIdAndStatus`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  AddCustomer(
    requestInfo: IApiRequest<ICustomerRequest>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}AddCustomer`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  UpdateCustomer(
    requestInfo: IApiRequest<ICustomerRequest>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}UpdateCustomer`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  BulkActionsOnCustomer(
    requestInfo: IApiRequest<IBulkActionOnCustomerRequest>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}BulkActionsOnCustomer`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetOrganisationEmployeesForCustomerRule(
    requestInfo: IApiRequest<IRequestGetEmployeesForCustomerRule>
  ): Observable<IApiresponse<IResponseGetEmployeesForCustomerRule>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<IResponseGetEmployeesForCustomerRule>>(
      `${environment.billingApiUrl}GetOrganisationEmployeesForCustomerRule`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  BulkActionsForCustomerRule(
    requestInfo: IApiRequest<IBulkActionsForCustomerRuleRequest[]>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}BulkActionsForCustomerRule`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetCustomerHistoryByCustomerId(
    requestInfo: IApiRequest<RequestGetCustomerHistory>
  ): Observable<IApiresponse<CustomerHistoryResponse[]>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<CustomerHistoryResponse[]>>(
      `${environment.billingApiUrl}GetCustomerHistoryByCustomerId`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  exportData(
    exportedData: any[],
    excelFileName: string,
    addErrorColumn: boolean
  ) {
    let mappedData: ICustomerCsvRecordExport[] = [];
    for (let i = 0; i < exportedData.length; i++) {
      let recordExport = new CustomerCsvRecordExport();
      recordExport.CustomerName = exportedData[i].customerName;
      recordExport.CustomerCode1 = exportedData[i].customerCode1;
      recordExport.CustomerCode2 = exportedData[i].customerCode2;
      if (addErrorColumn) {
        recordExport.Error = exportedData[i].error;
      }

      mappedData.push(recordExport);
    }

    this.excelExportService.exportAsExcelFile(mappedData, excelFileName);
  }

  GetActiveCustomers(): Observable<IApiresponse<IGetCustomerResponse>> {
    return this.http.get<IApiresponse<IGetCustomerResponse>>(
      `${environment.billingApiUrl}GetActiveCustomers`,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  GetEmployeeListForCustomerCostCentreRuleAssignment(
    requestInfo: IApiRequest<GetEmployeeForCustomerCostCentreRuleAssignmentRequest>
  ): Observable<
    IApiresponse<EmployeeListForCustomerCostCentreRuleAssignmentResponse>
  > {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}GetEmployeeListForCustomerCostCentreRuleAssignment`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  AssignCustomerCostCentreAccessRuleToEmployees(
    requestInfo: IApiRequest<AssignCustomerCostCentreAccessRuleToEmployeesRequest>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}AssignCustomerCostCentreAccessRuleToEmployees`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetAllCustomers(
    organisationId: number
  ): Observable<IApiresponse<CustomerResponse[]>> {
    let params = new HttpParams();
    params = params.append("organisationId", organisationId.toString());
    return this.http.get<IApiresponse<CustomerResponse[]>>(
      `${environment.billingApiUrl}GetAllCustomers`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  BulkSaveCustomer(
    requestInfo: IApiRequest<IBulkSaveCustomerRequest>
  ): Observable<IApiresponse<IBulkSaveCustomerResponse>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<IBulkSaveCustomerResponse>>(
      `${environment.billingApiUrl}BulkSaveCustomer`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }
}
