import { INRFCJustificationNotesViewModel, NRFCJustificationNotesViewModel } from "./nrfcjustification-notes-view-model";
import { IReceiptInfo, ReceiptInfo } from "../twostepapproval/claimant-expense-detail";
export interface INRReceiptResponse {
    nrReceiptId: number;
    orgId: number;
    userId: number;
    receiptDate: number;
    amount: number;
    nrCurrentStatus: number;
    paymentTypeId: number;
    supplier: string;
    spentCurrencyID: number;
    notes: string;
    approverNotes: string;
    submissionDate: number;
    nrfcReceiptInfo: IReceiptInfo[];
    nrfcJustificationNotesViewModel: INRFCJustificationNotesViewModel[];
}


export class NRReceiptResponse implements INRReceiptResponse {
    nrReceiptId: number;
    orgId: number;
    userId: number;
    receiptDate: number;
    amount: number;
    nrCurrentStatus: number;
    paymentTypeId: number;
    supplier: string;
    spentCurrencyID: number;
    notes: string;
    approverNotes: string;
    submissionDate: number;
    nrfcReceiptInfo = <ReceiptInfo[]>[];
    nrfcJustificationNotesViewModel = <NRFCJustificationNotesViewModel[]>[];
}
