export interface IRequestGetGrade {
  gradeId: number;
}

export class RequestGetGrade implements IRequestGetGrade {
  gradeId: number;
  constructor(gradeId: number) {
    this.gradeId = gradeId;
  }
}

export interface IResponseGrade {
  gradeId: number;
  organisationId: number;
  gradeName: string;
  gradeDescription: string;
  gradeStatus: number;
  isDefault: boolean;
  createdBy: number;
  createdDate: Date;
  modifiedBy: number;
  modifiedDate: Date;
  isAnyEmployeeAssigned: boolean;
  updatedOn: number;
  isRowHighlighted: boolean;
  selected: boolean;
}

export class ResponseGrade implements IResponseGrade {
  gradeId: number;
  organisationId: number;
  gradeName: string;
  gradeDescription: string;
  gradeStatus: number;
  isDefault: boolean;
  createdBy: number;
  createdDate: Date;
  modifiedBy: number;
  modifiedDate: Date;
  isAnyEmployeeAssigned: boolean;
  updatedOn: number;
  isRowHighlighted: boolean = false;
  selected: boolean;
  constructor() { }
}

export interface IRequestGradeList {
  organisationId: number;
  status: number;
  pageNumber: number;
  sortOrder: number;
  sortColumn: string;
  pageSize: number;
}

export class RequestGradeList implements IRequestGradeList {
  organisationId: number;
  status: number;
  pageNumber: number;
  sortOrder: number;
  sortColumn: string;
  pageSize: number;
  constructor(
    organisationId: number,
    status: number,
    pageNumber: number,
    sortOrder: number,
    sortColumn: string
  ) {
    this.organisationId = organisationId;
    this.status = status;
    this.pageNumber = pageNumber;
    this.sortOrder = sortOrder;
    this.sortColumn = sortColumn;
  }
}

export interface IResponseGradeList {
  gradeList: IResponseGrade[];
  totalGradeCount: number;
  activeGradeCount: number;
  inActiveGradeCount: number;
}

export class ResponseGradeList implements IResponseGradeList {
  gradeList: ResponseGrade[];
  totalGradeCount: number;
  activeGradeCount: number;
  inActiveGradeCount: number;
}

export interface IRequestGrade {
  gradeId: number;
  organisationId: number;
  gradeName: string;
  gradeDescription: string;
  gradeStatus: number;
  isDefault: boolean;
  createdBy: number;
  createdDate: Date;
  modifiedBy: number;
  modifiedDate: Date;
  userName: string;
}

export class RequestGrade implements IRequestGrade {
  gradeId: number;
  organisationId: number;
  gradeName: string;
  gradeDescription: string;
  gradeStatus: number;
  isDefault: boolean;
  createdBy: number;
  createdDate: Date;
  modifiedBy: number;
  modifiedDate: Date;
  userName: string;

  constructor(
    organisationId: number,
    gradeName: string,
    gradeDescription: string,
    gradeStatus: number,
    isDefault: boolean,
    createdBy: number,
    userName: string
  ) {
    this.organisationId = organisationId;
    this.gradeName = gradeName;
    this.gradeDescription = gradeDescription;
    this.gradeStatus = gradeStatus;
    this.isDefault = isDefault;
    this.createdBy = createdBy;
    this.userName = userName;
  }
}



export interface IRequestDeactivateGrade {
  gradeId: number;
  modifiedBy: number;
  userName: string;
}

export class RequestDeactivateGrade implements IRequestDeactivateGrade {
  gradeId: number;
  modifiedBy: number;
  userName: string;
  constructor(gradeId: number, modifiedBy: number, userName: string) {
    this.gradeId = gradeId;
    this.modifiedBy = modifiedBy;
    this.userName = userName;
  }
}

export interface IRequestReactivateGrade {
  gradeId: number;
  modifiedBy: number;
  userName: string;
}

export class RequestReactivateGrade implements IRequestReactivateGrade {
  gradeId: number;
  modifiedBy: number;
  userName: string;
  constructor(gradeId: number, modifiedBy: number, userName: string) {
    this.gradeId = gradeId;
    this.modifiedBy = modifiedBy;
    this.userName = userName;
  }
}

export interface IRequestGradeHistory {
  gradeId: number;
}

export class RequestGradeHistory implements IRequestGradeHistory {
  gradeId: number;
  constructor(gradeId: number) {
    this.gradeId = gradeId;
  }
}

export interface IResponseGradeHistory {
  gradeHistoryId: number;
  gradeId: number;
  organisationId: number;
  gradeName: string;
  gradeDescription: string;
  createdBy: number;
  createdDate: Date;
  actionPerformed: number;
  notes: string;
}

export class ResponseGradeHistory implements IResponseGradeHistory {
  gradeHistoryId: number;
  gradeId: number;
  organisationId: number;
  gradeName: string;
  gradeDescription: string;
  createdBy: number;
  createdDate: Date;
  actionPerformed: number;
  notes: string;
}

export interface IResponseGradeHistoryList {
  gradeHistoryList: IResponseGradeHistory[];
}

export class ResponseGradeHistoryList implements IResponseGradeHistoryList {
  gradeHistoryList: IResponseGradeHistory[];
}

export interface IRequestGradeListForAssignEmployee {
  organisationId: number;
  status: number;
}

export class RequestGradeListForAssignEmployee
  implements IRequestGradeListForAssignEmployee {
  organisationId: number;
  status: number;
  constructor(organisationId: number, status: number) {
    this.organisationId = organisationId;
    this.status = status;
  }
}

export interface IEmployeeListForAssignedGrade {
  employeeId: number;
  gradeId: number;
  employeeName: string;
  employeeEmail:string;
}

export class EmployeeListForAssignedGrade
  implements IEmployeeListForAssignedGrade {
  employeeId: number;
  gradeId: number;
  employeeName: string;
  employeeEmail:string;
}

export interface IResponseGradeForAssignEmployee {
  gradeId: number;
  organisationId: number;
  gradeName: string;
  gradeStatus: number;
  assignedGradeEmployeeCount: number;
  assignedGradeEmployeeLists: IEmployeeListForAssignedGrade[];
}

export class ResponseGradeForAssignEmployee
  implements IResponseGradeForAssignEmployee {
  gradeId: number;
  organisationId: number;
  gradeName: string;
  gradeStatus: number;
  assignedGradeEmployeeCount: number;
  assignedGradeEmployeeLists: IEmployeeListForAssignedGrade[];
}

export interface IResponseGradeListForAssignEmployee {
  totalGrade: number;
  totalEmployees: number;
  totalUnassignedEmployees: number;
  gradeListForAssignEmployee: IResponseGradeForAssignEmployee[];
  unassignedGradeEmployeeList: IEmployeeListForAssignedGrade[];
}

export class ResponseGradeListForAssignEmployee
  implements IResponseGradeListForAssignEmployee {
  totalGrade: number;
  totalEmployees: number;
  totalUnassignedEmployees: number;
  gradeListForAssignEmployee: IResponseGradeForAssignEmployee[];
  unassignedGradeEmployeeList: IEmployeeListForAssignedGrade[];
}

export interface IGradeMappedEmployeeList {
  gradeId: number;
  organisationId: number;
  employeeIds: number[];
}

export class GradeMappedEmployeeList implements IGradeMappedEmployeeList {
  gradeId: number;
  organisationId: number;
  employeeIds: number[];
  constructor(gradeId: number, organisationId: number, employeeIds: number[]) {
    this.gradeId = gradeId;
    this.organisationId = organisationId;
    this.employeeIds = employeeIds;
  }
}

export interface IRequestGradeBulkDeactivate {
  gradeIds: number[];
  modifiedBy: number;
  userName: string;
}

export class RequestGradeBulkDeactivate implements IRequestGradeBulkDeactivate {
  gradeIds: number[];
  modifiedBy: number;
  userName: string;
  constructor(gradeIds: number[], modifiedBy: number, userName: string) {
    this.gradeIds = gradeIds;
    this.modifiedBy = modifiedBy;
    this.userName = userName;
  }
}

export interface IRequestGradeListAll {
  organisationId: number;
}

export class RequestGradeListAll implements IRequestGradeListAll {
  organisationId: number;
  constructor(organisationId: number) {
    this.organisationId = organisationId;
  }
}

export interface IRequestGradeBulkAdd {
  gradeList: IRequestGrade[];
}

export class RequestGradeBulkAdd implements IRequestGradeBulkAdd {
  gradeList: IRequestGrade[];
  constructor(gradeList: IRequestGrade[]) {
    this.gradeList = gradeList;
  }
}

export interface IGradeForBulkAdd {
  gradeName: string;
  isGradeNameComplete: boolean;
  gradeNameValidationMessage: string;
  gradeDescription: string;
  isGradeDescriptionComplete: boolean;
  gradeDescriptionValidationMessage: string;
  tempId: any;
}
export class GradeForBulkAdd implements IGradeForBulkAdd {
  gradeNameValidationMessage: string;
  gradeDescriptionValidationMessage: string;
  gradeName: string;
  isGradeNameComplete: boolean;
  gradeDescription: string;
  isGradeDescriptionComplete: boolean;
  tempId: any;
}

export interface IGradeCsvRecord {
  gradeName: string;
  gradeDescription: string;
  errors: string;
}
export class GradeCsvRecord implements IGradeCsvRecord {
  public gradeName: string;
  public gradeDescription: string;
  public errors: string;
  constructor() { }
}

export interface IFailedGradeImportInfo {
  gradeName: string;
  gradeDescription: string;
  error: string;
}
export class FailedGradeImportInfo implements IFailedGradeImportInfo {
  gradeName: string;
  gradeDescription: string;
  error: string;

  constructor(gradeName: string, gradeDescription: string, error: string) {
    this.gradeName = gradeName;
    this.gradeDescription = gradeDescription;
    this.error = error;
  }
}

export interface IGradeCsvRecordExport {
  GradeName: string;
  Description: string;
  Error: string;
}

export class GradeCsvRecordExport implements IGradeCsvRecordExport {
  GradeName: string;
  Description: string;
  Error: string;
}

export interface IGradeModelError {
  gradeNameValidationMessage: string;
  gradeDescriptionValidationMessage: string;
}

export class GradeModelError implements IGradeModelError {
  gradeNameValidationMessage: string = "";
  gradeDescriptionValidationMessage: string = "";
  constructor() { }
}

export class assignCategoriesToGradesByGradeRequest {
  GrageId: number;
}

export class  AssignCategoriesToGradeListPanelInputModel {
  title: string;
  constructor(title: string) {
      this.title = title;
  }
}

export class AddGradePanelInputModel {
  gradeId: number;
  isEditMode: boolean;
  isCopy:boolean;
  constructor(gradeId: number, isEditMode: boolean, isCopy: boolean) {
      this.gradeId = gradeId;
      this.isEditMode = isEditMode;
      this.isCopy = isCopy;
  }
}

export class AddGradePanelOutputModel {
  filterStatus: number;
  constructor(filterStatus: number) {
      this.filterStatus = filterStatus;
  }
}

export class Grade {
  gradeId: number;
  organisationId: number;
  gradeName: string;
  gradeDescription: string;
  gradeStatus: number;
  isDefault: boolean;
  createdBy: number;
  createdDate: Date;
  modifiedBy: number;
  modifiedDate: Date;
}

export class GradeFormModel extends Grade {
  isEditMode: boolean;
  gradeToggle: boolean;
  userName: string;
  isAnyEmployeeAssigned: boolean;
}

export interface BulkImportGradeRequest {
  organisationId: number;
  fmName: string;
  fmEmail: string;
  fmUserId: number;
  gradeList: IRequestGrade[];
}
