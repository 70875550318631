<div class="sliderContainer border">
    <div class="row">
        <div class="col-lg-12 no-padding-right-left">
            <div class="bg-white margin-5 padding-10">
                <div class="logo-container">
                    <div class="logo-imgHolder">
                        <img [src]="imgUrl" class="height-auto mx-auto" alt="logo" />
                    </div>
                </div>
                <p class="mx-auto w-100pc text-center">
                    <input id="file" #fileInput type="file" [accept]="fileExtensions" class="fileUpload"
                        (change)="onValueChange(fileInput.files)" (click)="fileInput.value = null" />
                    <button class="btn btn-primary" onclick="file.click(); return false">
                        {{'chage_logo'| translate }}
                    </button>
                </p>
            </div>
        </div>
    </div>

    <div class="row margin-top-10">
        <div class="col-lg-12 no-padding-right-left text-center mx-auto">
            <ul class="mx-auto w-90pc position-relative f-13">
                <li class="position-relative d-block text-right w-50pc">
                    <span class="d-block">{{'logo_dimensions' | translate}}</span>
                    <span class="d-block">{{'size'| translate}}</span>
                    <span class="d-block">{{'accepted_formats' | translate }}</span>
                    <p class="position-absolute right-120px top-20px">
                        <span><i class="fas fa-info-circle f-20 text-mustard"></i></span>
                    </p>
                </li>
                <li class="w-2pc">&nbsp;</li>
                <li class="d-block text-left w-48pc">
                    <span class="d-block fw-700">{{'w_160px' | translate }} | {{'h_50px' | translate}}</span>
                    <span class="d-block fw-700">200Kb</span>
                    <span class="d-block fw-700">{{'png_jpg_jpeg' | translate}}</span>
                </li>
            </ul>
        </div>
    </div>
</div>

<app-alert-panel *ngIf="showAlertPanel" [data]="alertPanelModel" (callbackEvent)="alertPanelReceiver($event)">
</app-alert-panel>