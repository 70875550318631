import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { param } from "jquery";

@Component({
  selector: 'check-icon-cell',
  templateUrl: './check-icon-renderer.component.html'
})

export class CheckIconRendererComponent implements ICellRendererAngularComp {

  showCheckIcon: boolean;

  constructor() {

  }

  initilize(params: ICellRendererParams) {

    if (params && params.data) {

      this.showCheckIcon = params.getValue();
    
    }

  }

  // called on init
  agInit(params: any): void {
    this.initilize(params);

  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.initilize(params);
    return true;
  }

}
