import { OnInit, Component, EventEmitter, Input, Output, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { ApiRequest, IApiresponse } from "src/app/_models";
import { first } from "rxjs/operators";
import { HowDoIVideoLinks } from "src/app/common/commonvariables";
import { BaseComponent } from "src/app/shared/base/base-component";
import { AlertService } from "src/app/alert-panel/alert.service";
import { IPreApprovalBaseRequest, PreApprovalBaseRequest, IPreApprovalTagListResponse, SelectPreApprovalTagPopupModel, preApprovalTags } from "src/app/_models/claimant-on-web/claimant-on-web";
import { ClaimantOnWebService } from "src/app/_services/claimant-on-web/claimant-on-web.service";
import { environment } from "src/environments/environment";
import { ClaimsService } from "src/app/_services/common/claims.service";

@Component({
    selector: "select-pre-approval-tag-panel",
    templateUrl: "./select-pre-approval-tag-panel.component.html"
})
export class SelectPreApprovalTagPanelComponent extends BaseComponent implements OnInit, OnDestroy {

    @Input()
    inputData: SelectPreApprovalTagPopupModel;

    @Output() closePanelEvent = new EventEmitter<boolean>();
    @Output() selectItemEvent = new EventEmitter<SelectPreApprovalTagPopupModel>();

    userActionEventSubscription: Subscription;
    claimsSubscription: Subscription;
    localOrganisationId: any;
    param: any;
    howDoIVideoLinks = HowDoIVideoLinks;
    hasFormChanges: boolean = false;

    disableSubmitButton: boolean = false;
    preApprovalTagList = <preApprovalTags[]>[];
    selectedPreApprovalTag: number = 0;

    constructor(private alertService: AlertService,
      private claimsService: ClaimsService,
      private claimantOnWebService: ClaimantOnWebService) {
      super();
      this.claimsSubscription = this.claimsService.currentClaims.subscribe((claims) => {
        this.localOrganisationId = claims.OrgId;
      });
      this.userActionEventSubscription = this.alertService.getUserActionEvent().subscribe(action => {
        if (action) {
            this.closePanelEvent.emit(true);
        }
      });
    }

    ngOnInit(): void {
        this.fnGetPreApprovalTagList();
        this.fnSetPreApprovalDetails();
    }

    ngOnDestroy(): void {
        this.userActionEventSubscription.unsubscribe();
        this.claimsSubscription.unsubscribe();
    }

    canDeactivate(): boolean {
        return !this.hasFormChanges;
    }

    fnClosePanel() {
        if (this.canDeactivate()) {
            this.closePanelEvent.emit(true);
        }
        else {
            this.alertService.onFormClosed();
        }
    }

    fnGetPreApprovalTagList() {
        let request = new ApiRequest<IPreApprovalBaseRequest>("GetPreApprovalTagList", environment.applicationVersion, environment.requestId);
        let requestInfo = new PreApprovalBaseRequest(this.localOrganisationId, this.inputData.claimantId);
        request.requestInfo = requestInfo;

        this.claimantOnWebService
            .GetPreApprovalTagList(request)
            .pipe(first())
            .subscribe((response: IApiresponse<IPreApprovalTagListResponse>) => {
                if (response.responseInfo != null) {
                    this.preApprovalTagList = response.responseInfo.preApprovalTagList;
                    this.fnGetSelectedPreApprovalTagOnTop();
                }
            });
    }
    fnOnChangeApprovalTag(event, preApprovalId: number) {
        if (event.target.checked) {
            this.selectedPreApprovalTag = preApprovalId;
        } else {
            this.selectedPreApprovalTag = 0;
        }
    }

    fnSelectApprovalTag() {
        let selectPreApprovalTagOutputModel = new SelectPreApprovalTagPopupModel();
        if (this.selectedPreApprovalTag > 0) {
            let preApprovalTag = this.preApprovalTagList.find(item => item.preApprovalId == this.selectedPreApprovalTag);
            if (preApprovalTag) {
                selectPreApprovalTagOutputModel.preApprovalId = preApprovalTag.preApprovalId;
                selectPreApprovalTagOutputModel.preApprovalBalance = preApprovalTag.balance;
                selectPreApprovalTagOutputModel.preApprovalTag = preApprovalTag.preApprovalTag;
            }
        } else {
            selectPreApprovalTagOutputModel.preApprovalId = 0;
            selectPreApprovalTagOutputModel.preApprovalBalance = '0';
            selectPreApprovalTagOutputModel.preApprovalTag = null;
        }
        this.selectItemEvent.emit(selectPreApprovalTagOutputModel);
    }

    fnSetPreApprovalDetails() {
        if (this.inputData) {
            this.selectedPreApprovalTag = this.inputData.preApprovalId;
        }
    }

    fnGetSelectedPreApprovalTagOnTop() {
        let preApprovalId = this.inputData && this.inputData.preApprovalId ? this.inputData.preApprovalId : 0;

        if (preApprovalId && this.preApprovalTagList && this.preApprovalTagList.length > 0) {
            const sortedArr = this.preApprovalTagList.reduce((acc, element) => {
                if (element.preApprovalId == preApprovalId) {
                    return [element, ...acc];
                }
                return [...acc, element];
            }, []);
            this.preApprovalTagList = sortedArr;
        }
    }
}
