import { Component, OnDestroy, OnInit, EventEmitter } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';

@Component({
    selector: 'formly-ng-select-description-input',
    templateUrl: "./formly-ng-select-description-input.component.html"
})
export class FormlyNgSelectDescriptionInputComponent extends FieldType implements OnInit, OnDestroy {

    isFormSubmitted: boolean = false;
    formSubmissionSubscription: Subscription;
    fieldKey: string;

    constructor(
        private dynamicFormService: DynamicFormService
    ) {
        super();
    }

    ngOnInit() {
        if (this.field.key) {
            this.fieldKey = this.field.key.toString();
        }
        this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
            this.isFormSubmitted = data;
        });
    }

    ngOnDestroy() {
        if (this.formSubmissionSubscription) {
            this.formSubmissionSubscription.unsubscribe();
        }
    }

    onValueChange() {
        if (this.to.change) {
        this.to.change(this.field, this.formControl.value);
        }
    }
    clickAction() {
        if (this.to.attributes && this.to.attributes.isReadonly == 0) {
            if (this.to.onClick) {
                this.to.onClick(this.field, this.to.attributes.isReadonly)
            }
        }
    }
    onTinyClick(){
        this.to.onTinyClick();
    }
}