
export interface ISoloCheckFuncPurchaseListRequest {
    userId: number;
    orgId: number;
}

export class SoloCheckFuncPurchaseListRequest implements ISoloCheckFuncPurchaseListRequest {
    userId: number;
    orgId: number;
    constructor(orgId: number, userId: number) {
        this.orgId = orgId;
        this.userId = userId;
    }
}
