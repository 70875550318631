import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-text-tooltip-renderer2',
    templateUrl: './text-tooltip-renderer2.component.html',
})
export class TextWithTooltipRenderer2Component implements ICellRendererAngularComp {
    text: string;
    toolTipText: string;
    constructor() {

    }

    initilize(params: ICellRendererParams) {
        const text = params.getValue();

        if (text && params && params.data) {
            this.text = text;
            this.toolTipText = params.data.tooltipText
        }
    }

    // called on init
    agInit(params: any): void {
        this.initilize(params);

    }

    // called when the cell is refreshed
    refresh(params: any): boolean {
        this.initilize(params);
        return true;
    }

}
