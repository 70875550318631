<div class="margin-bottom-30 padding-15 bg-danger-polVio-claimant rounded fw-700 cursor-pointer"
  (click)="fnOpenPolicyViolationPanel()">
  <span class="padding-right-5">
      <i class="far fa-exclamation-triangle color-red"></i>
  </span>
  {{ 'click_to_view_policy_violation' | translate }}
  <span class="position-absolute right-30">
    <i class="fas fa-chevron-circle-right text-charcoal-black" aria-hidden="true"></i>
  </span>
</div>

<advance-policy-violation-panel *ngIf="showPolicyViolationPanel" [inputData]="policyViolationInputModel"
    (closePanelEvent)="fnClosePolicyViolationPanel($event)"></advance-policy-violation-panel>
