<h4 class="eod-text-secondary fw-600">{{'step_1_5' | translate}}</h4>
<h1 class="onboarding-title pt-3">
       {{'sign_up' | translate}}
</h1>
<form id="signup-form"
      class="form onboarding onboarding-width pt-5"
      [formGroup]="registerForm"
      (ngSubmit)="onRegistrationSubmit()">
       <div class="d-flex flex-column align-content-between">
              <div>
                     <div class="form-group">
                            <label class="input-label"
                                   for="first-name">{{'label_first_name' | translate}}<span>*</span></label>
                            <input class="form-control form-input"
                                   trim="blur"
                                   type="text"
                                   NoWhiteSpaces
                                   formControlName="firstname"
                                   (keypress)="omit_special_char($event)"
                                   maxlength="30"
                                   id="fName"
                                   appBlockCopyPaste
                                   autocomplete="off"
                                   #firstNameField />
                            <div *ngIf="
                                    (firstname.invalid &&
                                      (firstname.dirty || firstname.touched)) ||
                                    (firstname.value == '' && registrationsubmitted) ||
                                    (!firstname.valid && !firstname.pristine)
                                  "
                                 class="alert-danger">
                                   <div *ngIf="
                                      (firstname.errors.required && registrationsubmitted) ||
                                      (!firstname.valid && !firstname.pristine)
                                    ">
                                          {{ "label_Required" | translate: param }}
                                   </div>
                            </div>
                            <div *ngIf="!ShowSpecialCharErrorFName"
                                 class="alert-danger">
                                   <div *ngIf="!ShowSpecialCharErrorFName">
                                          {{ "numeric_keys_not_allowed" | translate: param }}
                                   </div>
                            </div>

                     </div>
                     <div class="form-group">
                            <label class="input-label"
                                   for="last-name">{{'label_last_name' | translate}}</label>
                            <input class="form-control form-input"
                                   rim="blur"
                                   type="text"
                                   formControlName="lastname"
                                   (keypress)="omit_special_char($event)"
                                   maxlength="30"
                                   id="lName"
                                   appBlockCopyPaste
                                   autocomplete="off" />
                            <div *ngIf="!ShowSpecialCharErrorLName"
                                 class="alert-danger">
                                   <div *ngIf="!ShowSpecialCharErrorLName">
                                          {{ "numeric_keys_not_allowed" | translate: param }}
                                   </div>
                            </div>

                     </div>

                     <div class="form-group">
                            <label class="input-label"
                                   for="e-mail">{{'label_email' | translate}}<span>*</span></label>
                            <input class="form-control form-input"
                                   trim="blur"
                                   type="text"
                                   formControlName="email"
                                   maxlength="100"
                                   id="eMailr"
                                   (input)="emailAlreadyExists=false"
                                   autocomplete="off" />
                            <div *ngIf="
                                    (email.invalid && (email.dirty || email.touched)) ||
                                    (email.value == '' && registrationsubmitted)"
                                 class="alert-danger">
                                   <div *ngIf="email.errors?.required && registrationsubmitted">
                                          {{ "label_Required" | translate: param }}
                                   </div>
                                   <div *ngIf="
                                      email.errors &&
                                      email.errors.invalidEmail &&
                                      registrationsubmitted
                                    ">
                                          {{ "label_Emailisnotvalid" | translate: param }}
                                   </div>
                            </div>

                     </div>
                     <p *ngIf="emailAlreadyExists &&
                              registrationsubmitted
                            "
                        class="fw-600 f-16 eod-text-danger w-90pc lH-22 mt-2 mb-2 d-flex justify-content-center"><span
                                  class="padding-right-5"><i class="fas fa-info-circle text-red f-14"
                                      aria-hidden="true"></i></span>{{'claimant_exists' | translate }} </p>
              </div>
              <div class="mt-auto">
                     <button type="submit"
                             id="next-button"
                             [disabled]="IsRegisterCanBeDisabled"
                             class="btn eod-btn-secondary btn-lg btn-block">{{'next' | translate}}</button>
                     <div class="text-center pt-4">
                            <span>{{'already_have_an_account' | translate}} </span>
                            <a data-href="javascript:void(0)"
                               (click)="gotoLogin()"
                               class="forgot-password eod-text-danger2 fw-600">{{'login' | translate}}</a>
                     </div>
              </div>
       </div>
</form>


<!-- Button trigger modal -->
<button id="openModalButton"
        type="button"
        [hidden]="true"
        class="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop">
        {{ 'modal_open' | translate}}
</button>

<!-- Modal -->
<div class="modal fade"
     id="staticBackdrop"
     data-bs-backdrop="static"
     data-bs-keyboard="false"
     tabindex="-1"
     aria-labelledby="staticBackdropLabel"
     aria-hidden="true">
       <div class="modal-dialog modal-dialog-centered modal-xl">
              <div class="modal-content margin-left--15 border-radius-13px">
                     <div class="modal-body p-4  border-radius-top-10px"
                          style="background-color: #333;color: white;">
                            <div class="text-center w-100pc pb-3">
                                   <span class=""><i aria-hidden="true"
                                             class="color-red fas fa-exclamation-circle f-28"></i></span>
                            </div>


                            <p class="mb-3 f-16">{{'you_are_registering_as' | translate}} '<b
                                      style="color: #fdc615">{{email.value}}</b>'
                            </p>
                            <p class="text-justify f-14">{{'this_email_domain' |translate}} '<b
                                      style="color: #fdc615">{{domainName}}</b>' {{'is_already_registered_by_your_admin'
                                   | translate}}
                            </p>

                            <p class="text-justify f-14 pt-2">{{'hense_new_subscription_cannot_be_created' | translate}}
                            </p>
                     </div>
                     <div class="modal-footer d-block">

                            <div class="ml-2">
                                   <p class="f-14">{{'please' | translate}} <a data-href="javascript:void(0)"
                                             style="color: #29b7e9"
                                             (click)="SendInviteeNotificationToFinanceManager()"
                                             data-bs-dismiss="modal"
                                             class="forgot-password">{{'click_here' |translate}}</a>
                                          {{'to_receive_an_invitation' | translate}}
                                   </p>
                                   <p class="f-14 mt-2">{{'if_you_already_have_an_invitation_to_join' | translate}}<a
                                             data-href="javascript:void(0)"
                                             style="color: #29b7e9"
                                             data-bs-dismiss="modal"
                                             (click)="gotoLogin()"
                                             class="forgot-password">{{'click_here' |translate}}</a> {{'to_login' |
                                          translate}}
                                   </p>
                            </div>
                            <div class="row justify-content-center w-100pc pt-3">
                                   <button type="button"
                                           class="btn eod-btn-primary w-15pc"
                                           (click)="gotoLogin()"
                                           data-bs-dismiss="modal">{{'label_ok' | translate}}</button>
                            </div>
                     </div>
              </div>
       </div>
</div>