import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-onboarding",
  templateUrl: "./onboarding.component.html",
  styleUrls: ["./onboarding.component.scss"],
})
export class OnboardingComponent implements OnInit {
  constructor(private router: Router) {}
  isLoginActive: boolean = true;
  isSignupActive: boolean = false;
  isOTPVerificationActive = false;
  isOrgDetailActive = false;
  isInviteEmployeeActive = false;
  isAppDownloadActive = false;

  currentStep = 1;

  ngOnInit(): void {

    if(this.router.url.includes("register")){
      this.navigateToRoute(2);
    }
  }

  navigateToRoute(step) {
    this.currentStep = step;
    switch (step) {
      case 1:
        this.isLoginActive = true;
        this.isSignupActive = false;
        this.isOTPVerificationActive = false;
        this.isOrgDetailActive = false;
        this.isInviteEmployeeActive = false;
        this.isAppDownloadActive = false;
        break;
      case 2:
        this.isLoginActive = false;
        this.isSignupActive = true;
        this.isOTPVerificationActive = false;
        this.isOrgDetailActive = false;
        this.isInviteEmployeeActive = false;
        this.isAppDownloadActive = false;
        break;
      case 3:
        this.isLoginActive = false;
        this.isSignupActive = false;
        this.isOTPVerificationActive = true;
        this.isOrgDetailActive = false;
        this.isInviteEmployeeActive = false;
        this.isAppDownloadActive = false;
        break;
      case 4:
        this.isLoginActive = false;
        this.isSignupActive = false;
        this.isOTPVerificationActive = false;
        this.isOrgDetailActive = true;
        this.isInviteEmployeeActive = false;
        this.isAppDownloadActive = false;
        break;
      case 5:
        this.isLoginActive = false;
        this.isSignupActive = false;
        this.isOTPVerificationActive = false;
        this.isOrgDetailActive = false;
        this.isInviteEmployeeActive = true;
        this.isAppDownloadActive = false;
        break;
      case 6:
        this.isLoginActive = false;
        this.isSignupActive = false;
        this.isOTPVerificationActive = false;
        this.isOrgDetailActive = false;
        this.isInviteEmployeeActive = false;
        this.isAppDownloadActive = true;
        break;
    }
  }


  gotoLogin() {
    this.navigateToRoute(1);
  }

  gotoSignUp() {
    this.navigateToRoute(2);
  }

  gotoOTPVerification() {
    this.navigateToRoute(3);
  }

  gotoOrgDetails() {
    this.navigateToRoute(4);
  }

  gotoInviteEmployee() {
    this.navigateToRoute(5);
  }
  gotoAppDownload() {
    this.navigateToRoute(6);
  }

  onLoginClick($event){
    this.navigateToRoute($event);
  }
}
