import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-approval-data-renderer',
  templateUrl: './approval-data-renderer.component.html',
  styleUrls: ['./approval-data-renderer.component.scss']
})
export class ApprovalDataRendererComponent implements ICellRendererAngularComp {

  status: number;
  approverName: string;
  approvalData: string;
  constructor() {

  }

  initilize(params: ICellRendererParams) {
    
    if (params && params.data) {
      this.status = params.data.status;
      this.approverName = params.data.approverName;
      this.approvalData = params.data.approvalData;
    }
  }

  // called on init
  agInit(params: any): void {
    this.initilize(params);
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.initilize(params);
    return true;
  }

}
