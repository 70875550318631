import { TranslateLoader } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable, of } from "rxjs";

import { map } from 'rxjs/operators';

export class TranslationLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string): Observable<any> {
    let TokenHeader = {
      "Content-Type": "application/json",
      DefaultParams: "",
      Authorization: `Bearer ` + localStorage.getItem("loginToken"),
      'Ocp-Apim-Subscription-Key': environment.apimSubscriptionKey,
      'Ocp-Apim-Trace': environment.apimTraceEnabled,
    };

    ////Commented Intentionaly
    // return this.http.get<any[]>(`${environment.catalogApiUrl}GetTranslatorKeys/` + lang, {
    //   headers: TokenHeader
    // });

    if (sessionStorage.getItem("currenttranslatorlanguage") != lang) {
      sessionStorage.removeItem("translatorKeys");
      sessionStorage.removeItem("OrganisationSettingListData");
    }

   

    if (sessionStorage.getItem("translatorKeys")) {
      return of(JSON.parse(sessionStorage.getItem("translatorKeys")));
    } else {
      return this.http
        .get(`${environment.catalogApiUrl}GetTranslatorKeys/` + lang, {
          headers: TokenHeader,
        })
        .pipe(map((res: any) => {
          sessionStorage.setItem("currenttranslatorlanguage", lang);
          sessionStorage.setItem("translatorKeys", JSON.stringify(res));
          return res;
        }));
    }

  }
}
