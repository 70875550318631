<div [ngClass]="mainClass" style="height: 35px !important;" class="padding-left-25">
    <input id="{{ 'cbx' + fieldKey }}" type="checkbox" class="custom-control-input" [checked]="formControl.value"
        [formControl]="formControl" (input)="onValueChange()">

    <label class="custom-control-label cursor-pointer f-14 lH-25 padding-left-5 position-relative" for="{{ 'cbx' + fieldKey }}"
        *ngIf="formControl.value">
        {{field.templateOptions.labelActive}}
    </label>
    <label class="custom-control-label cursor-pointer f-14 lH-25 padding-left-5 position-relative" for="{{ 'cbx' + fieldKey }}"
        *ngIf="!formControl.value">
        {{field.templateOptions.labelDeactive}}
    </label>
</div>