import { Component, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-grid-header',
  templateUrl: './custom-header.component.html',
  styleUrls: ['./custom-header.component.scss']
})
export class CustomHeaderComponent implements ICellRendererAngularComp {
  params: any;
  sortOrder: string;
  ascSort: string;
  descSort: string;
  noSort: string;
  fieldKey: string;
  isRightAligned: boolean;
  sortIconClass: string;
  tooltipText: string;
  isGridEmpty: boolean = true;
  headerTooltip: string;
  headerTooltipIcon: string;
  headerTooltipPosition: string;
  headerEditIcon:string;
  constructor(private translate: TranslateService, private sanitizer: DomSanitizer) {
    this.tooltipText = this.translate.instant('bulk_action_text');
  }

  initilize(params: ICellRendererParams) {    
    this.params = params;
  }
  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.initilize(params);
    return true;
  }

  @ViewChild('menuButton', { read: ElementRef }) public menuButton;
  
  agInit(params): void {    
    this.params = params;
    if (params.isCheckbox) {
      this.fieldKey = 'headcbx' + params.column.colDef.field;
    }
    else {
      this.fieldKey = 'head' + params.column.colDef.field;
    }

    if (params.column.colDef.headerClass) {
      let alignRightstr = params.column.colDef.headerClass.indexOf("text-right")
      this.isRightAligned = alignRightstr > -1;
    }

    if (params.column.colDef.headerComponentParams) {
      this.isGridEmpty = !params.column.colDef.headerComponentParams.rowCount;      
      this.headerEditIcon = params.column.colDef.headerComponentParams.headerEditIcon ? params.column.colDef.headerComponentParams.headerEditIcon: null;
    }

    if (params.column.colDef.tooltipComponentParams) {
      this.headerTooltip = params.column.colDef.tooltipComponentParams.headerTooltip ? params.column.colDef.tooltipComponentParams.headerTooltip: null;
      this.headerTooltipIcon = params.column.colDef.tooltipComponentParams.headerTooltipIcon ? params.column.colDef.tooltipComponentParams.headerTooltipIcon : null;
      this.headerTooltipPosition = params.column.colDef.tooltipComponentParams.headerTooltipPosition? params.column.colDef.tooltipComponentParams.headerTooltipPosition:'right';
    }

    params.column.addEventListener(
      'sortChanged',
      this.onSortChanged.bind(this)
    );

    this.onSortChanged();
  }

  onMenuClicked(event) {
    this.params.showColumnMenu(this.menuButton.nativeElement);
  }

  onCheckboxChange(event) {
    this.params.onCheckAll(event);
  }

  onSortChanged() {

    this.ascSort = this.descSort = this.noSort = 'inactive';

    if (this.params.column.isSortAscending()) {
      this.ascSort = 'active';
      this.sortOrder = 'asc';
      this.sortIconClass = 'ascSort customSortDownLabel';
    } else if (this.params.column.isSortDescending()) {
      this.descSort = 'active';
      this.sortOrder = 'desc';
      this.sortIconClass = 'descSort customSortUpLabel';
    } else {
      this.noSort = 'active';
      this.sortOrder = '';
      this.sortIconClass = 'noSort customSortRemoveLabel';
    }

  }

  onSortRequested(event) {

    if (this.isGridEmpty) {
      return;
    }

    if (this.params.enableSorting) {
      if (!this.sortOrder) {
        this.sortOrder = 'asc';
      }
      else if (this.sortOrder == 'asc') {
        this.sortOrder = 'desc';
      }
      else {
        this.sortOrder = '';
      }
      this.params.setSort(this.sortOrder, event.shiftKey);
    }
  }

  sanitizerHtml(element) {
    let safeText = this.sanitizer.bypassSecurityTrustHtml(element);
    return safeText;
  }

  handleHeaderClick(_event) {
   this.params.onClick(this.params.column);   
  }
}
