import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';
import { PolicyViolationInputModel } from "src/app/_models/claimant-on-web/claimant-on-web";

@Component({
    selector: 'formly-policy-violation',
    templateUrl: "./formly-policy-violation.component.html"
})

export class FormlyPolicyViolationComponent extends FieldType implements OnInit, OnDestroy {

    isFormSubmitted: boolean = false;
    formSubmissionSubscription: Subscription;
    fieldKey: string;
    openPanelSubscription: Subscription;
    zindexClass:string;
    showPolicyViolationPanel: boolean = false;
    policyViolationInputModel: PolicyViolationInputModel;
    isReceiptNumberSettingsEnabled:boolean=false;

    constructor(private dynamicFormService: DynamicFormService) {
        super();
    }

    ngOnInit() {
        if (this.field.key) {
            this.fieldKey = this.field.key.toString();
        }
        
        if (this.field && this.field.templateOptions) {
        this.isReceiptNumberSettingsEnabled = this.field.templateOptions.isReceiptNumberSettingsEnabled;
        }

        this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
            this.isFormSubmitted = data;
        });
        this.openPanelSubscription = this.dynamicFormService.getOpenPanelEvent().subscribe(key => {
          if (key == this.field.key) {
            this.fnOpenPolicyViolationPanel();
          }
        });
    }

    ngOnDestroy() {
        if (this.formSubmissionSubscription) {
            this.formSubmissionSubscription.unsubscribe();
        }
        if(this.openPanelSubscription) {
          this.openPanelSubscription.unsubscribe();
        }
    }

    fnOpenPolicyViolationPanel() {
      if(this.model.orgExpenseCategoryId) {
        this.zindexClass="z-99999";
        this.policyViolationInputModel = new PolicyViolationInputModel(
          this.model.expenseId,
          this.model.expenseCategoryName,
          this.model.baseCategoryId,
          this.model.justificationNotes,
          false,
          ''
        );
        this.showPolicyViolationPanel = true;
      }
    }

    fnClosePolicyViolationPanel(justificationNotes) {
      this.policyViolationInputModel = null;
      this.showPolicyViolationPanel = false;
      if (justificationNotes) {
        this.to.change(this.field, justificationNotes);
      }
    }

}
