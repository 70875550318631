import { Component, ViewEncapsulation } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: 'count-with-checkbox-renderer',
  templateUrl: './count-with-checkbox-renderer.component.html',
  encapsulation: ViewEncapsulation.None
})
export class CountWithCheckBoxRendererComponent implements ICellRendererAngularComp {
    public data: any;
    text: string = '';
    params: any;
    isSelected: boolean;
    isDisabled: boolean;

    initilize(params: ICellRendererParams) {
        if (params) {
            this.params = params;
            this.text = params.getValue();

            if (params.data) {
                this.data = params.data;
                if(params.data.selected){
                    this.isSelected = params.data.selected;
                }  
                if(params.data.disabled){
                    this.isDisabled = params.data.disabled;
                }           
            }
        }

    }

    // called on init
    agInit(params: any): void {
        this.initilize(params);

    }

    // called when the cell is refreshed
    refresh(params: any): boolean {
        this.initilize(params);
        return true;
    }

    onClick($event) {

        if (this.params.onClick instanceof Function && $event && $event.target) {
            this.data.selected = $event.target.checked;
            this.params.onClick(this.data);
        }
    }
}
