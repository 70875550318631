import { OnInit, Component, EventEmitter, Input, Output, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { Subscription } from "rxjs";
import { Userresponsedto } from "src/app/_models/user/userresponsedto";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "src/app/_services";
import { DatePipe } from "@angular/common";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { TranslateService } from "@ngx-translate/core";
import { Claims } from "src/app/_models/common/claims";
import { ApiRequest, IApiresponse } from "src/app/_models";
import { first } from "rxjs/operators";
import { HowDoIVideoLinks } from "src/app/common/commonvariables";
import { AlertPanelModel } from "src/app/_models/common/alertPanelModel";
import { BaseComponent } from "src/app/shared/base/base-component";
import { AlertService } from "src/app/alert-panel/alert.service";
import { Observable } from "rxjs";
import { FormBuilder, UntypedFormGroup, UntypedFormControl, Validators, FormArray } from "@angular/forms";
import { SelectDetourInputModel, SelectDetourOutputModel } from "src/app/_models/claimant-on-web/claimant-on-web";
import { ClaimantOnWebService } from "src/app/_services/claimant-on-web/claimant-on-web.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "create-detour-panel",
    templateUrl: "./create-detour-panel.component.html"
})
export class CreateDetourPanelComponent extends BaseComponent implements OnInit, AfterViewInit {

    @Input()
    inputData: SelectDetourInputModel;

    @Output() closePanelEvent = new EventEmitter<boolean>();
    @Output() selectItemEvent = new EventEmitter<SelectDetourOutputModel>();

    currentUserSubscription: Subscription;
    userActionEventSubscription: Subscription;
    currentUser: Userresponsedto;
    claims: Claims;
    param: any;
    howDoIVideoLinks = HowDoIVideoLinks;

    createDetourForm: UntypedFormGroup;
    isDetourFormSubmitted: boolean = false;
    disableSubmitButton: boolean = false;

    constructor(
        private router: Router,
        private toastr: ToastrService,
        private authenticationService: AuthenticationService,
        public datepipe: DatePipe,
        private claimsService: ClaimsService,
        private translate: TranslateService,
        private alertService: AlertService,
        public fb: FormBuilder,
        private claimantOnWebService: ClaimantOnWebService
    ) {
        super();
        this.claimsService.currentClaims.subscribe((claims) => {
            this.claims = claims;
        });
        this.currentUserSubscription = this.authenticationService.currentUser.subscribe(
            (user) => {
                this.currentUser = user;
            }
        );
        this.observeUnsavedChangesAlerts();
    }
    ngOnInit(): void {
        this.createDetourForm = this.fnCreateDetourForm();
        this.fnSetDetourInputDetails();
    }

    ngAfterViewInit() {

    }
    canDeactivate(): boolean {
        return !this.createDetourForm.dirty;
    }

    observeUnsavedChangesAlerts() {
        var currentContext = this;
        this.userActionEventSubscription = this.alertService.getUserActionEvent().subscribe(action => {
            if (action) {
                this.closePanelEvent.emit(true);
            }
        });
    }

    fnClosePanel() {
        if (this.canDeactivate()) {
            this.closePanelEvent.emit(true);
        }
        else {
            this.alertService.onFormClosed();
        }
    }
    fnCreateDetourForm() {
        let form = this.fb.group({
            detour: [null, [Validators.required]],
            detourJustificationNotes: [null, [Validators.required, this.noWhitespaceValidator]]
        });
        return form;
    }

    fnOnSubmitDetour() {

        if (!Number(this.createDetourForm.value.detour)) {
            this.createDetourForm.controls.detour.setErrors({ 'required': true });
        }

        if (this.createDetourForm.invalid) {
            this.isDetourFormSubmitted = true;
            this.disableSubmitButton = false;
            return;
        }
        let selectDetourOutputModel = new SelectDetourOutputModel();
        selectDetourOutputModel.detour = this.createDetourForm.value.detour;
        selectDetourOutputModel.detourJustificationNotes = this.createDetourForm.value.detourJustificationNotes;
        this.disableSubmitButton = true;
        this.selectItemEvent.emit(selectDetourOutputModel);
    }

    fnSetDetourInputDetails() {
        if (this.inputData) {
            this.createDetourForm.patchValue({
                detour: this.inputData.detour ? this.inputData.detour : null,
                detourJustificationNotes: this.inputData.detourJustificationNotes ? this.inputData.detourJustificationNotes : null
            })
        }
    }
    public noWhitespaceValidator(control: UntypedFormControl) {
        const isWhitespace = (control.value || "").trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { whitespace: true };
    }
}
