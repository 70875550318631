<div [ngClass]="class" [hidden]="!text || isPopOverDisplay">{{text}}
    <!-- <span class="color-grey f-10">
        <p>(Before Passed for Payment)</p>
    </span> -->
</div>

<span [hidden]="!isPopOverDisplay">
    <ng-template #htmlContent>
        <div class="popoverCustomWidth">
            <p>
                {{ popOverText }}
            </p>
        </div>
    </ng-template>
    <span [ngClass]="class" [hidden]="!text" [ngbPopover]="htmlContent" triggers="mouseenter:mouseleave"
        placement="right-bottom" container="body">{{text}}</span>
</span>