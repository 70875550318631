//Node
import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

//App
import { environment } from '../../../../environments/environment';
import { ClaimsService } from '../claims.service';

@Injectable({
    providedIn: 'root'
})
export class MonitoringService {
    appInsights: ApplicationInsights;
    appInsightsEnabled: boolean = false;

    constructor(private claimsService: ClaimsService) {
        if (environment.instrumentationkey) {
            this.appInsightsEnabled = true;
            this.appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: environment.instrumentationkey,
                    enableAutoRouteTracking: true,
                    enableCorsCorrelation: true,
                    correlationHeaderExcludedDomains: ['*.googleapis.com']
                }
            });
            this.appInsights.loadAppInsights();
            this.appInsights.addTelemetryInitializer(envelope => {
                envelope.tags['ai.cloud.role'] = "EOD_SPA";
            })
            this.claimsService.currentClaims.subscribe((claims) => {
                if (claims && claims.UserId) {
                    const accountId = claims.OrgId + "##" + claims.UserId;
                    this.setUserId(accountId);
                }
                else {
                    this.clearUserId();
                }
            });
        }
    }

    setUserId(userId: string) {
        if (this.appInsightsEnabled) {
            this.appInsights.setAuthenticatedUserContext(userId);
        }
    }

    clearUserId() {
        if (this.appInsightsEnabled) {
            this.appInsights.clearAuthenticatedUserContext();
        }
    }

    logPageView(name?: string, url?: string) { // option to call manually
        if (this.appInsightsEnabled) {
            this.appInsights.trackPageView({
                name: name,
                uri: url
            });
        }
    }

    logEvent(name: string, properties?: { [key: string]: any }) {
        if (this.appInsightsEnabled) {
            this.appInsights.trackEvent({ name: name }, properties);
        }
    }

    logMetric(name: string, average: number, properties?: { [key: string]: any }) {
        if (this.appInsightsEnabled) {
            this.appInsights.trackMetric({ name: name, average: average }, properties);
        }
    }

    logException(exception: Error, severityLevel?: number) {
        if (this.appInsightsEnabled) {
            this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
        }
    }

    logTrace(message: string, properties?: { [key: string]: any }) {
        if (this.appInsightsEnabled) {
            this.appInsights.trackTrace({ message: message }, properties);
        }
    }
}
