import { Injectable, Inject, forwardRef } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { IApiresponse } from "../../_models/common/request-response/apiresponse";
import { IApiRequest } from "../../_models/common/request-response/api-request";
import {
  IOrganisationGooglePackageSaveRequest,
  IOrganisationGooglePackageSaveResponse,
  IOrganisationGooglePackageUpdateCallsRequest,
  IOrganisationGooglePackageUpdateCallsResponse,
  IUnsubscribeGooglePackageCallsRequest,
  IDismissGooglePackagePurchaseReminderRequest,
  IUpdateOrganisationGooglePackagePaymentDetailStatusRequest,
  IPlaidConnectionResponse,
} from "../../_models/google-mapping/google-mapping";
import { setHeader } from "src/app/_helpers/setRequestHeader";
import { ClaimsHeaderService } from "src/app/shared/claimsHeader/claims-header.service";

@Injectable({
  providedIn: "root",
})
export class GoogleMappingService {
  httpHeaders: HttpHeaders;
  constructor(
    private http: HttpClient,
    private claimsHeaderService: ClaimsHeaderService
  ) {}

  SaveOrganisationGooglePackage(
    requestinfo: IApiRequest<IOrganisationGooglePackageSaveRequest>
  ): Observable<IApiresponse<IOrganisationGooglePackageSaveResponse>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IOrganisationGooglePackageSaveResponse>>(
      `${environment.billingApiUrl}SaveOrganisationGooglePackage`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetOrganisationGooglePackage(organisationId: number) {
    let params = new HttpParams();
    params = params.append("organisationId", organisationId.toString());

    return this.http.get<any>(
      `${environment.billingApiUrl}GetOrganisationGooglePackage`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  UpdateOrganisationGooglePackageCalls(
    requestinfo: IApiRequest<IOrganisationGooglePackageUpdateCallsRequest>
  ): Observable<IApiresponse<IOrganisationGooglePackageUpdateCallsResponse>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<
      IApiresponse<IOrganisationGooglePackageUpdateCallsResponse>
    >(
      `${environment.billingApiUrl}UpdateOrganisationGooglePackageCalls`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetActiveGooglePackageDetailsForOrganisation(organisationId: number) {
    let params = new HttpParams();
    params = params.append("organisationId", organisationId.toString());

    return this.http.get<any>(
      `${environment.billingApiUrl}GetActiveGooglePackageDetailsForOrganisation`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  UnsubscribeGooglePackageCalls(
    requestinfo: IApiRequest<IUnsubscribeGooglePackageCallsRequest>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}UnsubscribeGooglePackageCalls`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  DismissGooglePackagePurchaseReminder(
    requestinfo: IApiRequest<IDismissGooglePackagePurchaseReminderRequest>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}DismissGooglePackagePurchaseReminder`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  UpdateOrganisationGooglePackagePaymentDetailStatus(
    requestinfo: IApiRequest<IUpdateOrganisationGooglePackagePaymentDetailStatusRequest>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}UpdateOrganisationGooglePackagePaymentDetailStatus`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SaveOrganisationPlaidPackage(
    requestinfo: IApiRequest<any>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IOrganisationGooglePackageSaveResponse>>(
      `${environment.billingApiUrl}SaveOrganisationPlaidPackage`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetOrganisationPlaidPackage(organisationId: number) {
    let params = new HttpParams();
    params = params.append("organisationId", organisationId.toString());

    return this.http.get<any>(
      `${environment.billingApiUrl}GetOrganisationPlaidPackage`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  GetActivePlaidPackageDetailsForOrganisation(organisationId: number) {
    let params = new HttpParams();
    params = params.append("organisationId", organisationId.toString());

    return this.http.get<any>(
      `${environment.billingApiUrl}GetActivePlaidPackageDetailsForOrganisation`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }
  UnsubscribePlaidPackageCalls(
    requestinfo: IApiRequest<IUnsubscribeGooglePackageCallsRequest>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}UnsubscribePlaidPackageCalls`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetOrganisationPlaidConnections(organisationId: number) {
    let params = new HttpParams();
    params = params.append("organisationId", organisationId.toString());

    return this.http.get<any>(
      `${environment.billingApiUrl}GetOrganisationPlaidConnections`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  AddUpdateOrganisationPlaidConnections(
    requestinfo: IApiRequest<IPlaidConnectionResponse>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}AddUpdateOrganisationPlaidConnections`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetPlaidToken(countryCodes): Observable<any> {
    let clientId = "";
    let clientSecret = "";

    if (countryCodes[0] == "GB") {
      clientId = environment.plaidClientId_UK;
      clientSecret = environment.plaidClientSecret_UK;
    } else if (countryCodes[0] == "US") {
      clientId = environment.plaidClientId_US;
      clientSecret = environment.plaidClientSecret_US;
    }

    let body = {
      client_id: clientId,
      secret: clientSecret,
      client_name: "ExpenseOnDemand",
      user: {
        client_user_id: clientId,
      },
      products: ["transactions"],
      country_codes: countryCodes,
      language: "en",
      webhook: "https://apps.expenseondemand.com/orgsetting/ccsetup",
      redirect_uri: "https://apps.expenseondemand.com/orgsetting/ccsetup",
    };

    return this.http.post(`${environment.functionsUrl}GetPlaidToken`, body, {
      headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
    });
  }

  GetPlaidAccessToken(publicToken,countryCodes): Observable<any> {

    let clientId = "";
    let clientSecret = "";

    if (countryCodes[0] == "GB") {
      clientId = environment.plaidClientId_UK;
      clientSecret = environment.plaidClientSecret_UK;
    } else if (countryCodes[0] == "US") {
      clientId = environment.plaidClientId_US;
      clientSecret = environment.plaidClientSecret_US;
    }


    let body = {
      client_id: clientId,
      secret: clientSecret,
      public_token: publicToken,
    };

    return this.http.post(
      `${environment.functionsUrl}GetPlaidAccessToken`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }
}
