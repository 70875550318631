import { Injectable, OnInit, Inject, forwardRef } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { OrgSubscription } from "../../_models/common/OrgSubscription";
import { IOrgSubscriptionFunctionList } from "../../_models/billing-pricing/OrgSubscriptionFunctionList";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { IApiRequest, IApiresponse } from "../../_models";
import { IOrgSubscriptionPurchaseFunctionList } from "../../_models/billing-pricing/IOrgSubscriptionPurchaseFunctionList";
import {
  PurchaseAndEndTrialFunctionRequest,
  IPurchaseAndEndTrialFunctionRequest,
} from "../../_models/billing-pricing/PurchaseAndEndTrialFunctionRequest";
import { FunctionDetail } from "src/app/_models/billing-pricing/FunctionDetail";
import { IOrganisationPaidCurrencyResponse } from "src/app/_models/billing-pricing/OrganisationPaidCurrencyResponse";
import { ClaimsHeaderService } from "src/app/shared/claimsHeader/claims-header.service";
import {
  RequestUpdateBillingPlanChange,
  IRequestUpdateBillingPlanChange,
  IRequestChangeBillingPlan,
} from "../../_models/billing-pricing/BillingPlanChange";
import { IBillingPlanDetails } from "../../_models/billing-pricing/BillingPlanDetails";
import {
  SoloCheckFuncPurchaseListResponse
} from "../../_models/billing-pricing/SoloCheckFuncPurchaseListResponse";

@Injectable({
  providedIn: "root",
})
export class OrgSubscriptionFunctionService {
  constructor(
    private http: HttpClient,
    private claimsHeaderService: ClaimsHeaderService
  ) { }

  getOrgSubscriptionPurchaseFunctionList(
    requestinfo: IApiRequest<OrgSubscription>
  ): Observable<IApiresponse<IOrgSubscriptionPurchaseFunctionList>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IOrgSubscriptionPurchaseFunctionList>>(
      `${environment.billingApiUrl}PurchaseFunctionListDetail`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  getOrgSubscriptionPurchaseFunction(
    requestinfo: IApiRequest<OrgSubscription>
  ): Observable<IApiresponse<SoloCheckFuncPurchaseListResponse>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<SoloCheckFuncPurchaseListResponse>>(
      `${environment.billingApiUrl}PurchaseFunctionList`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  getOrganisationPaymentCurrency(
    requestinfo: IApiRequest<FunctionDetail>
  ): Observable<IApiresponse<IOrganisationPaidCurrencyResponse>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IOrganisationPaidCurrencyResponse>>(
      `${environment.catalogApiUrl}GetOrganisationPaymentCurrency`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  getOrgSubscriptionTrialingFunctionList(
    requestinfo: IApiRequest<OrgSubscription>
  ): Observable<IApiresponse<IOrgSubscriptionFunctionList[]>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IOrgSubscriptionFunctionList[]>>(
      `${environment.billingApiUrl}TrialingFunctionListDetail`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  getOrgSubscriptionCancelledFunctionList(
    requestinfo: IApiRequest<OrgSubscription>
  ): Observable<IApiresponse<IOrgSubscriptionFunctionList[]>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IOrgSubscriptionFunctionList[]>>(
      `${environment.billingApiUrl}CancelledFunctionListDetail`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  UnsubscribeFunction(
    requestinfo: IApiRequest<IPurchaseAndEndTrialFunctionRequest>
  ): Observable<IApiresponse<IOrgSubscriptionPurchaseFunctionList>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IOrgSubscriptionPurchaseFunctionList>>(
      `${environment.billingApiUrl}FunctionUnSubscribe`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  purchaseEndTrial(
    requestinfo: IApiRequest<IPurchaseAndEndTrialFunctionRequest>
  ): Observable<IApiresponse<IOrgSubscriptionFunctionList[]>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IOrgSubscriptionFunctionList[]>>(
      `${environment.billingApiUrl}FunctionSubscribe`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  EndTrialFunction(
    requestinfo: IApiRequest<IPurchaseAndEndTrialFunctionRequest>
  ): Observable<IApiresponse<IOrgSubscriptionFunctionList[]>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IOrgSubscriptionFunctionList[]>>(
      `${environment.billingApiUrl}FunctionEndTrial`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  ReStartTrialFunction(
    requestinfo: IApiRequest<IPurchaseAndEndTrialFunctionRequest>
  ): Observable<IApiresponse<IOrgSubscriptionFunctionList[]>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IOrgSubscriptionFunctionList[]>>(
      `${environment.billingApiUrl}FunctionReTrialSubscribe`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  ChangeBillingPlan(
    requestinfo: IApiRequest<IRequestUpdateBillingPlanChange>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}ChangeBillingPlan`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  ChangeBillingPlanV2(
    requestinfo: IApiRequest<IRequestChangeBillingPlan>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}ChangeBillingPlanV2`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  CheckPackagePurchasedOrTrial(
    orgId: number
  ): Observable<IApiresponse<boolean>> {
    let params = new HttpParams();
    params = params.append("orgId", orgId.toString());
    let url = `${environment.billingApiUrl}CheckPackagePurchasedOrTrial`;
    return this.http.get<IApiresponse<boolean>>(url, {
      params: params,
      headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
    });
  }
  GetOrganisationBillingPlan(
    organisationId: number,
    currencyId: number
  ): Observable<IApiresponse<IBillingPlanDetails>> {
    let params = new HttpParams();
    params = params.append("organisationId", organisationId.toString());
    params = params.append("currencyId", currencyId.toString());
    let url = `${environment.billingApiUrl}GetOrganisationBillingPlan`;
    return this.http.get<IApiresponse<IBillingPlanDetails>>(url, {
      params: params,
      headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
    });
  }
}
