export interface IWorkflowRequest {
    expenseIds: number[];
    currentStatus: number;
}


export class WorkflowRequest implements IWorkflowRequest {
    expenseIds: number[];
    currentStatus: number;
    constructor(expenseIds: number[]) {
        this.expenseIds = expenseIds;
    }
} 
