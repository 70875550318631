export const publicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA9iRV7lAR1iZsogYodz6n
02XGkACA3hMQCI8Um8jZycIb6X1thwu+pOrO+KOtlgJIqYj6ZBAgD3BIfF60b4/y
qX1k/GteKS98Itwwp4b/40g4eCLVvzM6h2TAVok55cT/OFBZ5wJL83nScDWjWUDb
6/Rcocm/LkItWoXosHoUNmZOh6Eha0xTrpA3w+3mPUXaz9D257jGuRVjiCYji9An
uE+uD42YPCHpiC8s9rXejc3iaYnzAW6ZhWI7Re6MUuQP4jFHOhyr6n0U2Xvr8/WC
vY+a4X45wL3zYtSIDtV/q9Y1dcdkyyOn4qm9kfO7C5wuskGnwQWppGiH5abv3Twz
7QIDAQAB
-----END PUBLIC KEY-----`;

export const privateKey = `-----BEGIN RSA PRIVATE KEY-----
MIIEpQIBAAKCAQEA9iRV7lAR1iZsogYodz6n02XGkACA3hMQCI8Um8jZycIb6X1t
hwu+pOrO+KOtlgJIqYj6ZBAgD3BIfF60b4/yqX1k/GteKS98Itwwp4b/40g4eCLV
vzM6h2TAVok55cT/OFBZ5wJL83nScDWjWUDb6/Rcocm/LkItWoXosHoUNmZOh6Eh
a0xTrpA3w+3mPUXaz9D257jGuRVjiCYji9AnuE+uD42YPCHpiC8s9rXejc3iaYnz
AW6ZhWI7Re6MUuQP4jFHOhyr6n0U2Xvr8/WCvY+a4X45wL3zYtSIDtV/q9Y1dcdk
yyOn4qm9kfO7C5wuskGnwQWppGiH5abv3Twz7QIDAQABAoIBAAJw9dE5RhQDqYwp
13jnTC7SkZeYCa8qLTyIfsHUyiQwIqq8LbvrJ+bm1UquBOi5+RKFY0ZeypKcoHtr
TQrWOKfpsHi4b+SkreyClpDyy9LrQnQelFQjI+931liFX+ehfo6GbX3XDf3KNYYb
YhVeiU/bypdoMtc4nM6LCXXGoDCx6haqyJw5KYVf/mIUG5eB+Lc6nfSroPybflPQ
4w1tWBzd9yS4k/2jKzZUaG8Ure6db4uhiyosxTgAQcCAMqNWNUTojP57GkyfrJnM
obO+CyAV3ZZ/kh3ri3e12V8/sxD4b0piTHaxkYSdQgUcinY0u5mOlN0+9dT1ljOE
bibiK5kCgYEA/fbO+sVfmRecODE9YhVVeM+9ycE+ldBoxzA/B/ecN25SE3xq12Su
hWoqCZ6URi7BABY34mkRDyvt+vy4BmdcpsutDNklsSMJpbhVI+Mc8rlxZpJ9leCk
8Nyb5WhcVdjI06KNzSTtQvMbcRRPDhcyglaNQe3NFlbpoC7cBWsj7x8CgYEA+B15
bSTVMuUgNFHnyNYw4rWBxj5FjPnQefT2hQGJMMvt2Ig3vZshIu+yIS67lX+YPhC5
ug9VHVgV00k7lqUXUc4ep3Yxv89WmEv9Ouvss2lXC/4zmQxXeowBEhH3Ee4Hw/bV
OLxBVq9yetG0mPfhep6WTK/YDW07foQhsTbkF3MCgYEA8odSXlVC2Dad72PGlPZ+
q2LogdXT6MogZaiDaR5jxuPJ6eX1OkFiEPKztoAEZYmcsnKFZ+iia/StzfSt4R+w
cXEsj4qH3kPxoJsKY70uFfZWf3gCCrF/kq17C3fARhNCpikkdfZ/xmHXoQOeA3zP
tXzmZUGti1nwvhJB98K7jE0CgYEA6egfiV2oAIsxiSGOOjBzdcdXyOT7/KOmftMJ
dOq1w2L9n50Lr5yH6L/AVrgP8CiSBjEOZvH9c+SO7tmja6nICj8fdxBeZk25fWpm
GR5B0AIHJi9HxYl1DvreJ4L4Qdb9SylwakRP9MECU/dPIsbNsyO+3h0Iud+4rtUy
TjdJYTECgYEArSw13DznuzGRfLNIZY1ds1aVaCGKh7kFewNR6CFgB5yTe5eiYNlz
223sy+szc46F5DyqDiF92SmUGfty9jAVv56B1KM4W4XWPa/oMCfo5SO7zpUa6bfa
WTy72MpG76ovGFQrDiSc6OOpZ+vCFQjWn5qwuc933zvlZlFPmSK5gDA=
-----END RSA PRIVATE KEY-----`;


   