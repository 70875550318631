<h6 class="color-orange">
    <span class="padding-right-10 color-orange">
        <i class="far fa-file-signature" aria-hidden="true"></i>
    </span>{{ field.templateOptions.options[0].otherInfo.title1 }}
    <small class="color-blue f-10">
        {{ field.templateOptions.options[0].otherInfo.title2 }}
    </small>
</h6>
<span class="noteCount">
    {{ field.templateOptions.options[0].otherInfo.notesCount }}
</span>
<div class="historyNotes height-130px" *ngIf="field.templateOptions.options[0].otherInfo.notesCount > 0">
    <ul>
        <li *ngFor="let item of field.templateOptions.options[0].ListItems" style="width: 100%">
            <p>
                {{ item.justificationNotes
                }}
                <span class="d-block color-orange">
                    <small>
                        {{ fnActionString(item) }}
                    </small>
                </span>
            </p>
        </li>
    </ul>
</div>
<div class="historyNotes height-130px" *ngIf="field.templateOptions.options[0].otherInfo.notesCount <= 0">
    <p class="fw-700 lH-100 text-center">
        {{ "no_notes_available" | translate: param }}
    </p>
</div>