import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';
import { Router } from "@angular/router";

@Component({
  selector: 'formly-google-purchase',
  templateUrl: "./formly-google-purchase.component.html"
})
export class FormlyGooglePurchaseComponent extends FieldType implements OnInit, OnDestroy {

  isFormSubmitted: boolean = false;
  formSubmissionSubscription: Subscription;

  constructor(private dynamicFormService: DynamicFormService, public datepipe: DatePipe, private router: Router) {
    super();
  }

  ngOnInit() {
    this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
      this.isFormSubmitted = data;
    });
    let dddd = this.model;
  }

  ngOnDestroy() {
    if (this.formSubmissionSubscription) {
      this.formSubmissionSubscription.unsubscribe();
    }
  }

  onClick(item) {
    this.to.click(item, this.formControl.value);
  }
  fnGoToPurchaseGooglePackage() {
    localStorage.setItem("InvoiceDefaultActiveTab", "nav-googleMapping");
    let url = "orgsetting/mileagewizard";
              this.router.navigate([url], {
                queryParams: {
                  step: "5",
                },
              });
  }
}