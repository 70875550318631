<span class="position-relative ">

    <span>
        <span [hidden]="!isExpenseEditedByFM"  placement="left" [ngbPopover]="IsExpenseEditedByFMpopContent" container="body"  triggers="mouseenter:mouseleave">
            <em class="fas fa-solid fa-pencil color-black padding-LR-5"></em>
         </span>
  
          <ng-template #IsExpenseEditedByFMpopContent>
              <div>{{ "fm_edit_expense_for_msg12" | translate }}</div>
          </ng-template>

          <span [hidden]="!IsRecalledExpense"  placement="left" [ngbPopover]="IsRecalledExpensepopContent" container="body"  triggers="mouseenter:mouseleave">
            <em class="fas fa-solid fa-reply-all color-black padding-LR-5"></em>
         </span>
  
          <ng-template #IsRecalledExpensepopContent>
              <div>This expense (Export No: {{RecalledExportId}}) was Rolled Back on {{RecalledExpenseDate | date: "dd-MMM-yyyy"}}</div>
          </ng-template>

        <span [hidden]="!hasDetour"  placement="left" [ngbPopover]="DetourpopContent" container="body"  triggers="mouseenter:mouseleave">
            <em class="fas fa-route padding-LR-5"></em>
         </span>
  
          <ng-template #DetourpopContent>
              <div>{{ "took_detour" | translate }}</div>
          </ng-template>
        <span [hidden]="!exhangeRateTooltip"  placement="left" [ngbPopover]="popContent" container="body"  triggers="mouseenter:mouseleave">
          <em class="fas fa-info-circle text-mustard padding-LR-5"></em >
       </span>

        <ng-template #popContent>
            <div [innerHTML]="sanitizerHtml(exhangeRateTooltip)"></div>
        </ng-template>

        <span [hidden]="isOtherPolicyDisplay">
            <span [hidden]="!isPolicyViolated || !isPolicyIconDisplay">
                <em class="fas fa-exclamation-triangle icon-Orange padding-LR-5" placement="right" container="body"
                    ngbTooltip="{{violationTooltip}}" tooltipClass="my-custom-class-additional-fields">
                </em>
            </span> 
        </span>
        <span [hidden]="!isOtherPolicyDisplay">
            <ng-template #htmlContent>
                <div class="popoverCustomWidth">
                    <p *ngFor="let item of policyArray">
                        # {{ item | translate }}<br />
                    </p>
                </div>
            </ng-template>
            <span class="policyViolationPopOver">
                <em [hidden]="!isPolicyViolated" class="fas fa-exclamation-triangle icon-Orange padding-LR-5" [ngbPopover]="htmlContent"
                    popoverTitle="{{breachTooltip}}" triggers="mouseenter:mouseleave"
                    placement="right-bottom" container="body"></em>
            </span>
        </span>

        </span>

        <span [ngClass]="{ 'color-red': amount && amount < 0}">{{ amount | number: "1.2-2" | noComma }}</span>

</span>
