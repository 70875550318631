import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { Claims } from "src/app/_models/common/claims";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Location } from "@angular/common";
import { AlertPanelModel } from "src/app/_models/common/alertPanelModel";
import { ActionPanelConfig, ActionPanelOutput } from 'src/app/_models/common/form/form';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CheckListPanelModel } from "src/app/_models/universal-importer/universal-importer";
import { CheckListData } from "src/app/_models/universal-importer/universal-importer";
import { DomSanitizer } from "@angular/platform-browser";
import { AppGridComponent } from "src/app/shared/grid/grid-component/grid.component";
import { GridConfig } from "src/app/_models/common/grid/grid-config";
import { ColDef } from "ag-grid-community";
import { ImportEmployeesResponseEnum } from "src/app/_models";

@Component({
    selector: "import-checklist-panel",
    templateUrl: "./import-checklist-panel.component.html",
    styleUrls: ["./import-checklist-panel.component.scss"],
})

export class ImportChecklistPanelComponent implements OnInit {
    @Input() inputData: CheckListPanelModel;
    @Output() onCloseActionPanel = new EventEmitter<any>();
    @Output() onSubmitBulkAction = new EventEmitter<any>();
    param: any;
    claims: Claims;
    @ViewChild(AppGridComponent) gridComponent: AppGridComponent;
    gridData: any = [];
    gridConfigItems: GridConfig;
    gridColumnDefs: ColDef[];
    gridFilterStatus: number = 0;
    gridFilterItems: any;
    noDataAvailableMessage: string;

    constructor(
        private toastr: ToastrService,
        private claimsService: ClaimsService,
        private router: Router,
        private location: Location,
        private translate: TranslateService,
        private sanitizer: DomSanitizer
    ) {
        this.claimsService.currentClaims.subscribe((claims) => {
            this.claims = claims;
        });
    }

    ngOnInit() {
        if (this.inputData && this.inputData.isGridPanel) {
            this.gridData = this.inputData.data;

            this.fnInitialiseGrid();
        }

    }
    fnCreateColumnDefs() {
        this.gridColumnDefs = [
            {
                headerName: '',
                headerClass: "d-none",
                field: 'text',
                resizable: true,
                sortable: true,
                suppressMenu: true,
                maxWidth: 500,
                cellClass: 'text-left',
                cellRenderer: 'safeTextRendererComponent'
            },
            {
                headerName: "",
                headerClass: "d-none",
                field: 'value',
                resizable: true,
                sortable: true,
                suppressMenu: true,
                maxWidth: 80,
            }
            ,
            {
                headerName: '',
                headerClass: "d-none",
                field: 'iconClass',
                resizable: true,
                sortable: true,
                suppressMenu: true,
                maxWidth: 80,
                cellClass: 'text-left',
                cellRenderer: 'safeTextRendererComponent'
            },
            {
                headerName: "",
                headerClass: "d-none",
                cellRenderer: 'buttonRenderer',
                field: 'userActionField',
                suppressMovable: true,
                suppressMenu: true,
                sortable: false,
                maxWidth: 100,
                cellClass: 'text-center',
                headerComponentParams:
                    [
                        {
                            buttonClass: 'btn-primary',
                            buttonText: this.translate.instant('label_view'),
                            disableProperty: '',
                            hideProperty: 'isButtonHide',
                            userAction: 'edit',
                            textBeforeBtn: ''
                        },

                    ]
            }




        ];
    }

    fnGetGridConfigItems() {
        let countByStatusArray = [];

        this.gridConfigItems = new GridConfig(
            true,
            false,
            "sequence",
            "id",
            this.gridFilterStatus,
            false,
            countByStatusArray,
            true,
            true,
            false,
            null,
            'asc',
            40,
            'no_data_availabe',
            null,
            null, false, false,
            '',
            '',
            false
        );
    }

    fnInitialiseGrid() {

        this.fnCreateColumnDefs();
        this.fnGetGridConfigItems();
        setTimeout(() => {
            this.gridComponent.initializeGrid(false);
        }, 100);

    }
    fnOnGridCellClick(params) {
        if (params.column.colId == 'userActionField') {
            switch (params.data.id) {
                case ImportEmployeesResponseEnum.Grade:

                    break;
                case ImportEmployeesResponseEnum.CostCentre:

                    break;
                case ImportEmployeesResponseEnum.EmployeeErrorsList:

                    break;


            }
        }
        this.onSubmitBulkAction.emit(params.data);
    }
    closePanelData()
    {
        let returnData = {
            close: true,
            route:""
        }
        this.onClosePanel(returnData);
    }

    onClosePanel(returnData) {
        this.onCloseActionPanel.emit(returnData);
    }

    onSubmitPanel(data: CheckListData) {
        if (data) {
            this.onSubmitBulkAction.emit(data);
        }
    }

    sanitizerHtml(element) {
        return this.sanitizer.bypassSecurityTrustHtml(element);
    }
    goToManEmp()
    {
        let returnData = {
            close: true,
            route:"/orgsetting/manageemployee"
        }
        this.onClosePanel(returnData);
        
    }

}
