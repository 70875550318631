import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class AlertService {

    private static isFormClosingSubject: Subject<boolean> = new Subject<boolean>();
    private static onUserActionSubject: Subject<boolean> = new Subject<boolean>();
    private static onFunctionSubscriptionChangeSubject: Subject<boolean> = new Subject<boolean>();

    constructor() {
    }

    public onFunctionSubscriptionChange() {
        AlertService.onFunctionSubscriptionChangeSubject.next(true);
    }

    public onFunctionSubscriptionChangeEvent(): Observable<boolean> {
        return AlertService.onFunctionSubscriptionChangeSubject.asObservable();
    }

    public onFormClosed() {
        AlertService.isFormClosingSubject.next(true);
    }

    public getFormClosingEvent(): Observable<boolean> {
        return AlertService.isFormClosingSubject.asObservable();
    }

    public onUserAction(value: boolean) {
        AlertService.onUserActionSubject.next(value);
    }

    public getUserActionEvent(): Observable<boolean> {
        return AlertService.onUserActionSubject.asObservable();
    }

}
