import { Component } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
    selector: 'amount-cell',
    templateUrl: './amount-renderer.component.html'
})
export class AmountRendererComponent implements ICellRendererAngularComp {
    public amount: number;
    public displayAmount: boolean = true;
    public buttonClass: string = "display-none";
    public buttonTooltip: string;
    constructor() {
    }

    initilize(params: ICellRendererParams) {
        this.amount = params.getValue();
        if (params && params.data) {
            if (params.data.buttonClass && params.colDef && params.colDef.headerComponentParams) {
                if (params.colDef.headerComponentParams.displayOnlyIcon) {
                    this.buttonClass = params.data.buttonClass;
                    this.buttonTooltip = params.data.buttonTooltip;
                    this.displayAmount = false;
                }
                else {
                    this.buttonClass = "display-none";
                }

            }
            else {
                this.buttonClass = "display-none";
            }
        }
        else {
            this.buttonClass = "display-none";
        }
    }

    // called on init
    agInit(params: any): void {
        this.initilize(params);
    }

    // called when the cell is refreshed
    refresh(params: any): boolean {
        this.initilize(params);
        return true;
    }

}
