export interface IExpenseCategory {
  CategoryID: number;
  CategoryName: string;
}
export class ExpenseCategory implements IExpenseCategory {

  constructor(public CategoryID: number, public CategoryName: string) {
    this.CategoryID = CategoryID;
    this.CategoryName = CategoryName;
  }

}
