import { Component, OnInit, Inject, forwardRef } from "@angular/core";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map, debounceTime } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { IEmailSendRequest, ISendReminderResponse } from "src/app/_models/common/email.model";
import { setHeader } from "src/app/_helpers/setRequestHeader";
import { ClaimsHeaderService } from "src/app/shared/claimsHeader/claims-header.service";
import { Observable } from "rxjs";
import { IApiRequest, IApiresponse } from 'src/app/_models';
import { EmailModel } from "src/app/_models/common/EmailModel";

@Injectable({
    providedIn: 'root'
})
export class EmailService {

    loginTokenHeader: HttpHeaders;
    constructor(private http: HttpClient, private claimsHeaderService: ClaimsHeaderService, private header: setHeader,) { }

    SendReminderEmail(requestinfo: IApiRequest<IEmailSendRequest>): Observable<IApiresponse<ISendReminderResponse>> {
        let body = JSON.stringify(requestinfo);
        return this.http.post<IApiresponse<ISendReminderResponse>>(`${environment.billingApiUrl}GetSetDetailForSendEmail`, body,
            { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
    }

    SendEmail(requestinfo: IApiRequest<EmailModel>) {

        this.loginTokenHeader = this.header.authorizationHeader();

        let body = JSON.stringify(requestinfo);
        return this.http.post(`${environment.notificationApiUrl}SendInstantEmail`, body,
            { headers: this.loginTokenHeader });
    }
}
