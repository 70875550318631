import { OnInit, Component, EventEmitter, Input, Output } from "@angular/core";
import { Subscription } from "rxjs";
import { DatePipe } from "@angular/common";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { Claims } from "src/app/_models/common/claims";
import { BaseComponent } from "src/app/shared/base/base-component";
import { AlertService } from "src/app/alert-panel/alert.service";
import { FormBuilder } from "@angular/forms";
import { MileageAllowance } from "src/app/_models/claimant-on-web/claimant-on-web";

@Component({
    selector: "add-passenger-load-panel",
    templateUrl: "./add-passenger-load-panel.component.html"
})
export class AddPassengerLoadPanelComponent extends BaseComponent implements OnInit {

    @Input()
    inputData: MileageAllowance[];

    @Output() closePanelEvent = new EventEmitter<boolean>();
    @Output() selectItemEvent = new EventEmitter<MileageAllowance>();

    currentUserSubscription: Subscription;
    userActionEventSubscription: Subscription;
    claims: Claims;
    param: any;
    isCreateTagsFormSubmitted: boolean = false;
    disableSubmitButton: boolean = false;

    constructor(
        public datepipe: DatePipe,
        private claimsService: ClaimsService,
        private alertService: AlertService,
        public fb: FormBuilder,
    ) {
        super();
        this.claimsService.currentClaims.subscribe((claims) => {
            this.claims = claims;
        });

        this.observeUnsavedChangesAlerts();
    }
    ngOnInit(): void {
    }
    canDeactivate(): boolean {
        return true;
    }

    observeUnsavedChangesAlerts() {
        this.userActionEventSubscription = this.alertService.getUserActionEvent().subscribe(action => {
            if (action) {
                this.closePanelEvent.emit(true);
            }
        });
    }

    fnClosePanel() {
        if (this.canDeactivate()) {
            this.closePanelEvent.emit(true);
        }
        else {
            this.alertService.onFormClosed();
        }
    }
    fnSelectPassengerLoad(item: MileageAllowance) {
        if (item) {
            this.selectItemEvent.emit(item)
        }
    }
    fnCheckForItems() {
        let items = this.inputData.filter(a => a.allowanceId > 3);
        if (items && items.length > 0) {
            return true;
        }
        else {
            return false;
        }
    }
}
