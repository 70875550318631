import { Component, OnInit, Inject, forwardRef } from '@angular/core';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
//import { map } from 'rxjs/operators';
import { IDashboard } from '../../_models/common/dashboard';

import { environment } from '../../../environments/environment';
import { DashboardDataRequest, IDashBoardAPI } from '../../_models/Dashboard/dashboardAPI';
import { setHeader } from 'src/app/_helpers/setRequestHeader';
import { ClaimsHeaderService } from 'src/app/shared/claimsHeader/claims-header.service';
import { Observable, Subject } from 'rxjs';
import { ApiRequest } from 'src/app/_models';
import { UserNotificationSettings } from 'src/app/_models/Notifications/user-notification-settings';
import { SetupPayStepRequest } from "src/app/_models/Dashboard/pay-step";
import { IApiresponse } from "src/app/_models/common/request-response/apiresponse";
import { IApiRequest } from "src/app/_models/common/request-response/api-request";
//import { Observable, of } from 'rxjs';

//@CrossOrigin(origins = "*")
@Injectable({
  providedIn: 'root'
})
export class DashboardService implements OnInit {

  userUrl: any;
  token: any;
  orgId: number;
  userid: string;
  loginid: string;
  httpHeaders: HttpHeaders;
  constructor(private http: HttpClient, private claimsHeaderService: ClaimsHeaderService) {

  }
  ngOnInit() {

  }

  getFmDashboardApproveRejectData(orgId, token) {
    this.userUrl = `${environment.expenseApiUrl}GetFMApproveRejectGraph/` + orgId + ``;

    return this.http.get<IDashBoardAPI>(this.userUrl,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });

  }

  SoloGetActionableRecordForDashboard(reqInfo: DashboardDataRequest) {
    let request = new ApiRequest<DashboardDataRequest>("GetActionableRecordForDashboard",
      environment.applicationVersion, environment.requestId);
    request.requestInfo = reqInfo;
    let body = JSON.stringify(request);
    return this.http.post<any>(`${environment.expenseApiUrl}GetActionableRecordForDashboard`, body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }
  getPendingDeputiesCount() {
    this.userUrl = `${environment.billingApiUrl}GetPendingDeputiesCount`;

    return this.http.get<any>(this.userUrl,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  getPendingEmployeeWithGradeCount() {
    this.userUrl = `${environment.billingApiUrl}GetPendingEmployeeWithGradeCount`;

    return this.http.get<any>(this.userUrl,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }
  
  GetWizardCount(reqInfo: DashboardDataRequest) {
    let request = new ApiRequest<DashboardDataRequest>("GetWizardCount",
      environment.applicationVersion, environment.requestId);
    request.requestInfo = reqInfo;
    let body = JSON.stringify(request);
    return this.http.post<any>(`${environment.expenseApiUrl}GetWizardCount`, body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  public userNotificationSettingsSubject$ = new Subject();
  saveUserNotificationSettings(reqInfo: UserNotificationSettings) {

    let request = new ApiRequest<UserNotificationSettings>("/api/SaveUserNotificationSettings",
      environment.applicationVersion, environment.requestId);
    request.requestInfo = reqInfo;
    let body = JSON.stringify(request);
    return this.http.post<any>(`${environment.billingApiUrl}SaveUserNotificationSettings`, body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  getUserNotificationSettings() {
    this.userUrl = `${environment.billingApiUrl}GetUserNotificationSettings`;

    return this.http.get<any>(this.userUrl,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  SetupPayStep( requestInfo: IApiRequest<SetupPayStepRequest>): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<any>>(
        `${environment.billingApiUrl}SetupPayStep`,
        body,
        { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
}

}
