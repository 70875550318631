import { EMAIL_REGEX } from "src/util/constants";
import { ValidationErrors, UntypedFormControl } from "@angular/forms";

export function EmailValidator(control: UntypedFormControl): ValidationErrors {
    return !control.value || EMAIL_REGEX.test(control.value) ? null : { invalidEmail: true };
}

export function NoWhitespaceValidator(control: UntypedFormControl): ValidationErrors {
    const isWhitespace = (control.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
}
