import { Injectable, Inject, forwardRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OrgSubscription } from '../../_models/common/OrgSubscription';
import { IPaymentMethodList } from '../../_models/billing-pricing/PaymentMethodList';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IApiRequest, IApiresponse } from '../../_models';
import { ClaimsHeaderService } from 'src/app/shared/claimsHeader/claims-header.service';
//import { setHeader } from 'src/app/_helpers/setRequestHeader';


@Injectable({
  providedIn: 'root'
})
export class PaymentMethodService {

  constructor(private http: HttpClient, private claimsHeaderService: ClaimsHeaderService) { }


  getPaymentTypeList(requestinfo: IApiRequest<OrgSubscription>): Observable<IApiresponse<IPaymentMethodList[]>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IPaymentMethodList[]>>(`${environment.billingApiUrl}OrganisationPaymentMethod`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

}
