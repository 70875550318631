import { Component, OnInit, Inject, EventEmitter, Output } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { environment } from "../../../environments/environment";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { Claims } from "src/app/_models/common/claims";
import { AuthenticationService } from "src/app/_services";

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
})
/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {
  element: any;
  flagvalue: any;
  cookieValue: any;
  countryName: any;
  valueset: any;
  claims: Claims;
  imageSource='assets/images/logo.png';

  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private authService: AuthenticationService,
    public _cookiesService: CookieService,
    private claimsService: ClaimsService
  ) {
    this.claimsService.currentClaims.subscribe((claims) => {
      this.claims = claims;
      if(this.claims)
        {
          this.imageSource = this.claims.OrgLogo;
        }
      
    });
  }
  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit(): void {
    this.element = document.documentElement;
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle("fullscreen-enable");
    if (
      !document.fullscreenElement &&
      !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement
    ) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu() {
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
      this.authService.logout();
    this.router.navigate(["/login"]);
  }
}
