import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule, NgbTooltipModule, NgbPopoverModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LayoutsModule } from './layouts/layouts.module';
import { PagesModule } from './pages/pages.module';
import { ToastrModule } from 'ngx-toastr';
import { OAuthModule } from 'angular-oauth2-oidc';
import { ServiceWorkerModule } from '@angular/service-worker';
import { setHeader } from './_helpers/setRequestHeader';
import { TranslationLoader } from './TranslationLoader.service';
import { DatePipe, DecimalPipe } from '@angular/common';
//import { LoginComponent } from './user/login';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  RECAPTCHA_SETTINGS,
  RecaptchaModule,
  RecaptchaSettings,
} from "ng-recaptcha";
import { TimeFormatPipe, TimeFormatPipeNew, MonthFormatPipe } from './_helpers/pipe.module';
import { FormlyRadioInputWithSideDescriptionComponent } from './shared/forms/form-controls/formly-radio-input-with-side-description/formly-radio-input-with-side-description.component';
import { BuildDetailsHttpService } from './build-details.http.service';




import {
  IPublicClientApplication,
  PublicClientApplication,
  BrowserCacheLocation,
  LogLevel,
  InteractionType,
} from '@azure/msal-browser';
import {
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalInterceptorConfiguration,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalBroadcastService,
  MsalService,
  MsalGuard,
  MsalModule,
  MsalInterceptor,
} from '@azure/msal-angular';
import { LoggingPanelComponent } from './shared/logging-panel/logging-panel.component';
import { CanDeactivateGuard } from './_guards/canDeactivate.guard';
import { AlertModule } from './alert-panel/alert.module';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: '1e16a093-0850-4b9c-9ef5-5f8ee7bbf41e',
      authority: 'https://login.microsoftonline.com/common',
      redirectUri: environment.AppUrl + 'orglogin'// 'https://demo.expenseondemand.com/orglogin',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(GRAPH_ENDPOINT, ['Calendars.Read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['Calendars.Read'],
    },
  };
}

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

const globalSettings: RecaptchaSettings = {
  siteKey: "6LcDN4AUAAAAAEfgraLH8JvgyQiE5zql0wynjr89",
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslationLoader(http);
}

export function fetchBuildDetails(buildDetailsService: BuildDetailsHttpService) {
  return () => buildDetailsService.fetchBuildDetails();
}


@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    TimeFormatPipe,
    TimeFormatPipeNew,
    MonthFormatPipe,
    FormlyRadioInputWithSideDescriptionComponent,
    LoggingPanelComponent
  ],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient],
      },
    }),
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    RecaptchaModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    PagesModule,
    NgbModule,
    NgbTooltipModule,
    NgbPopoverModule,
    NgbNavModule,
    LayoutsModule,
    TranslateModule,
    AlertModule,
    ToastrModule.forRoot({
      positionClass: "toast-top-right",
      closeButton: true,
      preventDuplicates: true,
      timeOut: 2000,
    }),
    OAuthModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      registrationStrategy: 'registerImmediately:30000'
    }),
    MsalModule

  ],
  providers: [
    setHeader,
    DatePipe,
    TimeFormatPipe,
    TimeFormatPipeNew,
    DecimalPipe,
    MonthFormatPipe,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: globalSettings,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    CanDeactivateGuard,
    { provide: APP_INITIALIZER, useFactory: fetchBuildDetails, deps: [BuildDetailsHttpService], multi: true },
    { provide: 'googleTagManagerId', useValue: 'GTM-5DNQVG9' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
