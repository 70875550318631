import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CountUpModule } from 'ngx-countup';

import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgbDropdownModule, NgbAccordionModule, NgbNavModule, NgbProgressbarModule, NgbTooltipModule, NgbPopoverModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { GoogleMapsModule } from '@angular/google-maps';

// Routings
import { PagesRoutingModule } from './pages-routing.modules';

// Modules
import { WidgetModule } from '../shared/widget/widget.module';

// Components
import { TranslateModule } from '@ngx-translate/core';
import { HomeComponent } from '../home';
import { SharedModule } from '../shared.module';
import { OrganisationSettingComponent } from '../organisation-setting/organisation-setting.component';
import { PresetExportComponent } from '../home/preset-export/preset-export.component';
import { GridModule } from '../_modules/modules/grid.module';
import { DynamicFormsModule } from '../_modules/modules/dynamic-forms.module';
import { ApprovalItemsPanelComponent } from '../manage-employees/approvers/approval-items-panel/approval-items-panel.component';
import {SetupPayStepPanelComponent} from "src/app/home/setup-pay-step-panel/setup-pay-step-panel.component";
import { FormsModule } from '@angular/forms';
import { CalanderSchedulerComponent } from "src/app/calander-scheduler/calander-scheduler.component";
import { CalendarModule, DateAdapter, MOMENT  } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { SchedulerModule } from "angular-calendar-scheduler";
import { AppService } from '../_services/common/app.service';
import * as moment from 'moment';
import { FeedbackPanelComponent } from '../shared/panels/feedback-panel/feedback-panel.component';


@NgModule({
  declarations: [
    HomeComponent,
    OrganisationSettingComponent,
    PresetExportComponent,
    ApprovalItemsPanelComponent,
    SetupPayStepPanelComponent,
    FeedbackPanelComponent,
    CalanderSchedulerComponent,
  ],
  imports: [
    CommonModule,
    WidgetModule,
    PagesRoutingModule,
    ScrollToModule.forRoot(),
    NgApexchartsModule,
    NgbDropdownModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbProgressbarModule,
    NgbCollapseModule,
    NgbTooltipModule,
    NgbPopoverModule,
    CountUpModule,
    SharedModule,
    GoogleMapsModule,
    TranslateModule,
    GridModule,
    DynamicFormsModule,
    FormsModule,    
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    SchedulerModule.forRoot({
      locale: "en",
      headerDateFormat: "daysRange",
      logEnabled: true,
    })
  ],
  
  providers: [
    AppService,
    { provide: LOCALE_ID, useValue: 'en-US' },
    { provide: MOMENT, useValue: moment }

  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class PagesModule { }
