import { Component } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
    selector: 'check-box-with-text',
    templateUrl: './check-box-with-text.component.html'
})
export class CheckBoxwithTextRenderComponent implements ICellRendererAngularComp {
    public data: any;
    public showCheckBoxWithText: boolean = false;
    text: string = '';
    params: any;


    initilize(params: ICellRendererParams) {
        if (params) {
            this.params = params;
            this.text = params.getValue();

            if (params.data) {
                this.data = params.data;
                if (this.data.showCheckBoxWithText) {
                    this.showCheckBoxWithText = this.data.showCheckBoxWithText;
                }
            }
        }

    }

    // called on init
    agInit(params: any): void {
        this.initilize(params);

    }

    // called when the cell is refreshed
    refresh(params: any): boolean {
        this.initilize(params);
        return true;
    }

    onClick($event) {

        if (this.params.onClick instanceof Function && $event && $event.target) {
            this.data.selected = $event.target.checked;
            this.params.onClick($event.target.checked);

        }
    }
}
