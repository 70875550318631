import { IMileageMultiRateInfo, MileageMultiRateInfo } from "./mileage-multi-rate-info";


export interface IOrgUKMileageRateInfo {
    sNo: number;
    effectiveFrom: number;
    statusCode: number;
    mileageMultiRateInfoList: IMileageMultiRateInfo[];
    statusValue: number;
    selected: boolean;

}


export class OrgUKMileageRateInfo implements IOrgUKMileageRateInfo {
    sNo: number;
    effectiveFrom: number;
    statusCode: number;
    mileageMultiRateInfoList: MileageMultiRateInfo[];
    statusValue: number;
    selected: boolean;
}

export class OrgOwnershipTypeResponse {
    orgOwnershipTypeList: OrgOwnershipTypeInfo[];
    importValidation: ImportValidation[];
    currentFinancialYear: number;
}

export class OrgOwnershipTypeInfo {
    orgVehicleTypeMapId: number;
    vehicleTypeId: number;
    vehicleSubTypeId: number;
    rateType: number;
    baseName: string;
    mileageRate: number;
    isMultiRange: boolean;
    multiMileageRate: string;
    vehicleTypeName: string;
    vehicleSubTypeName: string;
    vehicleLabel: string;
    vehicleLabelDisplayName: string;
    sequence: number;
    rateMechanism: string;
    effectiveFrom: number;
    effectiveFromString: string;
    nrFuelRate: any;
    statusCode: number;
    statusValue: string;
    isCheckboxDisabled: boolean;
    disableEdit: true;
    mileageMultiRateInfoList: MileageMultiRateInfo[];
    sNo: number;
    rowKey: number;
    classStatusValue: string;
    vatRateString: string;
    vatRate: any;
    vatRateId: any;
    mileageRateString: string;
    updatedOn: number;
    nRFuelRateString: string;
    selected: boolean;
    isExpenseCreated: boolean;
    rowClassName:string;
    effectiveTo: number;
    effectiveToString: string;
    totalActiveVehicle:number;
}
export class CloseFormAction {
    action: number;
    isRefreshRequired: boolean;
    isUpdatedSortRequired: boolean;
    otherAction: number;
}
export class ImportValidation {
    validationArray: ValidationArray[];
}
export class ValidationArray {
    keyString: string;
    stringValue: string;
    validationTypeAndMessage: ValidationTypeAndMessage[];
    compareProp: CompareProp[];
}
export class ValidationTypeAndMessage {
    validationMessage: string;
    validationType: number;
}
export class CompareProp {
    comparekeyString: string;
    compareStringValue: string;
    compareValidationEnum:number
}
