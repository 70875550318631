<span> {{ functionPackageName }}</span>

<span class="cursor-pointer">
    <i class="fas fa-info-circle text-mustard padding-left-3" placement="top" [ngbPopover]="popContent" container="body"
        triggers="mouseenter:mouseleave">
    </i>
</span>

<ng-template #popContent>
    <div [innerHTML]=" sanitizerHtml( functionPackageDescripition)"></div>
</ng-template>