import { Injectable, OnDestroy } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import {
  IEmployeeCounter,
  IBulkUpdateEmployeeRole,
  IBulkUpdateEmployeeStatus,
  IOwnershipTypeList,
  ILoginIDHistoryList,
  IGetAllEmployeesRequest,
  IGetAllTypeEmployeesRequest,
  IEmpListRequestDto,
  IAppListRequestDto,
  IAppListResponseDto,
  IEmpLogRequestDto,
  IEmpLogResponseDto,
  IAddEmpLogRequestDto,
  IAddEmpImportLogResponseDto,
  IAddFailedImportDto,
  IFailedEmpImportReqDto,
  IFailedEmpImportRespDto,
  IUpdateVehicleTypeRequest,
  IGetExistingEmployeeCountRequest,
  IGetExistingEmployeeCountResponse,
  IAddUpdateBulkEmployeeRequestDto,
  IEmpList,
  EmpList,
  IEmpListResponseDto,
  IOrgVehicleTypeResponse,
  IImportEmployeeRequest,
} from "../../_models/manage-emp/manage-emp";
import { environment } from "../../../environments/environment";
import {
  IApiRequest,
  IApiresponse,
  ApiRequest,
  FunctionPackageEnum,
  FunctionPrivilege,
  BillingMethod,
  IRequestVehicleTypes,
} from "../../_models";
import { ClaimsHeaderService } from "../../shared/claimsHeader/claims-header.service";
import { IDashboardGraphDetail } from "src/app/_models/manage-emp/dashboard-graph-detail";
import { map, mergeMap } from "rxjs/operators";
import { Observable, of, Subscription } from "rxjs";
import {
  BackgroundTaskKeys,
  BackgroundTaskNotificationBody,
  BackgroundTaskNotificationHeaders,
  BackgroundTaskTopics,
} from "src/app/common/commonvariables";
import { BackgroundTask } from "src/app/_models/common/background-task";
import { NotificationService } from "../common/notification.service";
import { OrganisationFunctionService } from "../organisation-setting/organisation-function.service";
import { AlertService } from "src/app/alert-panel/alert.service";
import {
  EmployeeStatusEnum,
  UserTypeEnum,
} from "src/app/_models/common/manage-emp-enum.enum";
import { CostCentreService } from "../costCentre/costCentre.service";
import { TranslateService } from "@ngx-translate/core";
import {
  IPurchaseFunctionListRequest,
  PurchaseFunctionListRequest,
} from "src/app/_models/organisation-setting/organisation-setting";
import { Claims } from "src/app/_models/common/claims";
import { OrganisationSettingService } from "../organisation-setting/organisation-setting.service";
import { ClaimsService } from "../common/claims.service";

@Injectable({
  providedIn: "root",
})
export class ManageempService implements OnDestroy {
  private static AllEmployeesList: IEmpList[] = [];
  private static AllTypeEmployeesList: IEmpList[] = [];
  private static AllVendorList: IEmpList[] = [];
  employeeListUrl: any;
  employeeCounterUrl: any;
  approverListUrl: any;
  vehicleListUrl: any;
  ownershipTypeList: any;
  loginIDHistoryUrl: any;
  functionPackageChangeSubscription: Subscription;
  claims: Claims;
  constructor(
    private http: HttpClient,
    private claimsHeaderService: ClaimsHeaderService,
    private notificationService: NotificationService,
    private alertService: AlertService,
    private organisationFunctionService: OrganisationFunctionService,
    private costCentreService: CostCentreService,
    private translate: TranslateService,
    private organisationSettingService: OrganisationSettingService,
    private claimsService: ClaimsService
  ) {
    this.functionPackageChangeSubscription = this.alertService
      .onFunctionSubscriptionChangeEvent()
      .subscribe((result) => {
        this.DropOrgAllEmployeesCache();
      });
    this.claimsService.currentClaims.subscribe(
      (claims) => (this.claims = claims)
    );
  }

  ngOnDestroy() {
    this.functionPackageChangeSubscription.unsubscribe();
  }

  GetLog(
    requestinfo: IApiRequest<IEmpLogRequestDto>
  ): Observable<IApiresponse<IEmpLogResponseDto>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IEmpLogResponseDto>>(
      `${environment.billingApiUrl}GetEmployeeImportLog`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetFailedImportInfo(
    requestinfo: IApiRequest<IFailedEmpImportReqDto>
  ): Observable<IApiresponse<IFailedEmpImportRespDto>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IFailedEmpImportRespDto>>(
      `${environment.billingApiUrl}GetFailedEmployeeImport`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  DeleteFailedImportInfo(
    requestinfo: IApiRequest<IFailedEmpImportReqDto>
  ): Observable<IApiresponse<object>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<object>>(
      `${environment.billingApiUrl}DeleteFailedEmployeeImport`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  UpdateEmployeeImportInfo(
    requestinfo: IApiRequest<IFailedEmpImportReqDto>
  ): Observable<IApiresponse<object>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<object>>(
      `${environment.billingApiUrl}UpdateEmployeeImportLog`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  AddLog(
    requestinfo: IApiRequest<IAddEmpLogRequestDto>
  ): Observable<IApiresponse<IAddEmpImportLogResponseDto>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IAddEmpImportLogResponseDto>>(
      `${environment.billingApiUrl}AddEmployeeImport`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  AddFailedImportData(
    requestinfo: IApiRequest<IAddFailedImportDto>
  ): Observable<IApiresponse<object>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<object>>(
      `${environment.billingApiUrl}AddFailedEmployeeImport`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  AssignCostCentreToExpenses(employeesList): Observable<boolean> {
    let body = JSON.stringify(employeesList);
    return this.http.post<boolean>(
      `${environment.expenseApiUrl}AssignCostCentreToExpenses`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetAllCostCentresAndEmployees(
    request: IApiRequest<IGetAllEmployeesRequest>,
    isDropCache: boolean
  ): Observable<any> {
    if (isDropCache) {
      this.DropOrgAllEmployeesCache();
    }

    return this.costCentreService.GetCostCentresWithApprovers().pipe(
      mergeMap((costCentres) => {
        const costCentreList: any[] =
          costCentres &&
          costCentres.responseInfo &&
          costCentres.responseInfo.length > 0
            ? costCentres.responseInfo
            : [];

        return this.GetAllClaimants(request).pipe(
          map((response: any[]) => {
            if (response && response.length > 0) {
              const employeesList = response;

              if (costCentreList.length > 0) {
                employeesList.forEach((emp) => {
                  if (
                    emp.employeeRole == UserTypeEnum.Approver ||
                    emp.employeeRole == UserTypeEnum.FinanceManager
                  ) {
                    emp.costCentresForApprover = [];
                    for (let i = 0; i < costCentreList.length; i++) {
                      if (
                        costCentreList[i].approverIds.indexOf(emp.employeeID) >
                        -1
                      ) {
                        emp.costCentresForApprover.push({
                          costCentreName: costCentreList[i].costCentreName,
                          costCentreId: costCentreList[i].costCentreId,
                          costCentreCode1: costCentreList[i].costCentreCode1,
                        });
                      }
                    }
                    emp.costCentresData =
                      emp.costCentresForApprover.length +
                      " " +
                      this.translate.instant("cost_centre");
                  }
                });

                costCentreList.forEach((costCentre) => {
                  costCentre.claimantsList = employeesList.filter(
                    (e) => e.costCentreId == costCentre.costCentreId
                  );
                  costCentre.approversList = employeesList.filter(
                    (e) => costCentre.approverIds.indexOf(e.employeeID) > -1
                  );

                  costCentre.claimantCount = costCentre.claimantsList.length;
                  costCentre.approverCount = costCentre.approverIds.length;

                  costCentre.assignedApproverCount = costCentre.approverCount;
                  costCentre.assignedApproverList = costCentre.approversList;

                  if (costCentre.approverCount <= 0) {
                    costCentre.approvers =
                      this.translate.instant("no_approver");
                  } else if (costCentre.approverCount == 1) {
                    costCentre.approvers =
                      costCentre.approversList[0].displayName;
                  } else {
                    costCentre.approvers = `${
                      costCentre.approversList[0].displayName
                    } (+${costCentre.approverCount - 1})`;
                  }
                });
              }

              return {
                costCentreList: costCentreList,
                employeesList: employeesList,
              };
            } else {
              return null;
            }
          })
        );
      })
    );
  }

  GetAllApprovers(
    request: IApiRequest<IGetAllEmployeesRequest>
  ): Observable<any[]> {
    return this.GetAllClaimants(request).pipe(
      map((employees) => {
        return employees.filter(
          (e) =>
            parseInt(e.employeeRole) == UserTypeEnum.Approver ||
            parseInt(e.employeeRole) == UserTypeEnum.FinanceManager
        );
      })
    );
  }

  GetAllClaimants(
    request: IApiRequest<IGetAllEmployeesRequest>
  ): Observable<any[]> {
    return this.GetAllEmployees(request).pipe(
      mergeMap((employees) => {
        if (employees && employees.length > 0) {
          const functions = [
            FunctionPackageEnum.SetupCostCentre_Assign_toClaimants,
          ];
          const defaultSortColumn = "displayName";

          return this.organisationFunctionService
            .checkFunctionsSubscribed(functions, this.claimsHeaderService.orgId)
            .pipe(
              map((subscription) => {
                const isCostCenterAccess =
                  subscription.indexOf(functions[0]) > -1;

                let employeesList: any[] = employees.sort((a, b) => {
                  let x = a[defaultSortColumn].toLowerCase();
                  let y = b[defaultSortColumn].toLowerCase();
                  return x < y ? -1 : x > y ? 1 : 0;
                });
                employeesList.forEach((emp) => {
                  if (emp.approverId) {
                    const approver = employeesList.find(
                      (e) => e.employeeID == emp.approverId
                    );
                    if (approver) {
                      emp.approverStatus = approver.status;
                      emp.approverName = approver.displayName;
                    }
                  }
                  if (!isCostCenterAccess) {
                    emp.costCentreName = "N/A";
                    emp.costCentreCode1 = "N/A";
                  }
                  emp.isCheckboxDisabled = false;
                });
                return employees;
              })
            );
        } else {
          return of([]);
        }
      })
    );
  }

  GetAllEmployees(
    request: IApiRequest<IGetAllEmployeesRequest>
  ): Observable<IEmpList[]> {
    if (ManageempService.AllEmployeesList.length > 0) {
      return of(ManageempService.AllEmployeesList.slice());
    } else {
      let body = JSON.stringify(request);
      return this.http
        .post<any>(`${environment.billingApiUrl}GetAllEmployees`, body, {
          headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
        })
        .pipe(
          map((data) => {
            if (data && data.responseInfo && data.responseInfo.empList) {
              ManageempService.AllEmployeesList = this.mapEmployees(
                data.responseInfo.empList
              );
              return ManageempService.AllEmployeesList;
            } else {
              return [];
            }
          })
        );
    }
  }

  GetAllTypeEmployees(
    request: IApiRequest<IGetAllTypeEmployeesRequest>
  ): Observable<IEmpList[]> {
    if (ManageempService.AllTypeEmployeesList.length > 0) {
      return of(ManageempService.AllTypeEmployeesList.slice());
    } else {
      let body = JSON.stringify(request);
      return this.http
        .post<any>(`${environment.billingApiUrl}GetAllTypeEmployees`, body, {
          headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
        })
        .pipe(
          map((data) => {
            if (data && data.responseInfo && data.responseInfo.empList) {
              ManageempService.AllEmployeesList = this.mapEmployees(
                data.responseInfo.empList
              );
              return ManageempService.AllEmployeesList;
            } else {
              return [];
            }
          })
        );
    }
  }

  GetAllVendors(
    request: IApiRequest<IGetAllEmployeesRequest>
  ): Observable<IEmpList[]> {
    if (ManageempService.AllVendorList.length > 0) {
      return of(ManageempService.AllVendorList.slice());
    } else {
      let body = JSON.stringify(request);
      return this.http
        .post<any>(`${environment.billingApiUrl}GetAllEmployees`, body, {
          headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
        })
        .pipe(
          map((data) => {
            if (data && data.responseInfo && data.responseInfo.empList) {
              ManageempService.AllVendorList = this.mapEmployees(
                data.responseInfo.empList
              );
              return ManageempService.AllVendorList;
            } else {
              return [];
            }
          })
        );
    }
  }

  mapEmployees(data: any[]): EmpList[] {
    let result: IEmpList[] = [];
    if (data && data.length > 0) {
      data.forEach((row) => {
        let emp: EmpList = {
          employeeID: row.eid,
          employeeCode: row.ec,
          employeeCode2: row.ec2,
          employeeCode3: row.ec3,
          employeeCode4: row.ec4,
          firstName: row.fn,
          lastName: row.ln,
          displayName: row.dn,
          email: row.e,
          status: row.s,
          employeeRole: row.er,
          vehicleTypeId: row.vti,
          derivedName: row.den,
          emailDisplayName: row.edn,
          vehicleStatus: row.vs,
          vehicleSubTypeId: row.vsti,
          rateType: row.rt,
          orgVehicleTypeMapId: row.ovtm,
          approverId: row.ai,
          updatedOn: row.uo,
          approverName: row.an,
          costCentreId: row.cci ? row.cci : 0,
          costCentreName: row.ccn ? row.ccn : "",
          disableDeactivate: false,
          disableOthers: false,
          isRowHighlighted: false,
          selected: false,
          hideDeactivate: false,
          hideResendInvite: row.s != EmployeeStatusEnum.Pending,
          hideSendInvite: row.s != EmployeeStatusEnum.NotInvited,
          hideActivate: row.s != EmployeeStatusEnum.Inactive,
          disableResendInvite: false,
          disableSendInvite: false,
          disableActivate: false,
          disableEdit: false,
          hideEdit: false,
          isCheckboxDisabled: false,
          perPersonBillingType: row.bt == BillingMethod.PerPersonBilling,
          hybridBillingType: row.bt == BillingMethod.HybridBilling,
          createdOn: row.co,
          costCentreCode1: row.ccc1 ? row.ccc1 : "",
          gradeId: row.gi,
          gradeName: row.gn,
          linkedToXero: row.ltx,
          userType: row.ut,
          kycStatus: row.kycs,
          assignedExpCatIds: row.aeIds,
          assignedFormIds: row.afIds,
          companyName: row.comn,
          isConvertedToPowerUser: row.icpu,
          costCentreRuleId: row.crid,
          isDeleted: false,
          userUpdatedOn: row.uuo
        };

        result.push(emp);
      });
    }
    return result;
  }

  DropOrgAllEmployeesCache() {
    ManageempService.AllEmployeesList = [];
  }

  DropOrgAllVendorCache() {
    ManageempService.AllVendorList = [];
  }

  GetExistingEmployeeCount(
    requestinfo: IApiRequest<IGetExistingEmployeeCountRequest>
  ): Observable<IApiresponse<IGetExistingEmployeeCountResponse>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IGetExistingEmployeeCountResponse>>(
      `${environment.billingApiUrl}GetExistingEmployeeCount`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetAllFinanceManager(
    requestinfo: IApiRequest<IGetAllEmployeesRequest>
  ): Observable<IEmpList[]> {
    let body = JSON.stringify(requestinfo);
    return this.http
      .post<any>(`${environment.billingApiUrl}GetAllFinanceManagers`, body, {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      })
      .pipe(
        map((data) => {
          let empList = this.mapEmployees(data.responseInfo.employees);
          return empList;
        })
      );
  }

  GetEmployeeListByStatus(
    requestinfo: IApiRequest<IEmpListRequestDto>
  ): Observable<IEmpListResponseDto> {
    let body = JSON.stringify(requestinfo);
    return this.http
      .post<any>(`${environment.billingApiUrl}GetEmployeeList`, body, {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      })
      .pipe(
        map((data) => {
          let result: IEmpListResponseDto = {
            counter: data.responseInfo.counter,
            employees: this.mapEmployees(data.responseInfo.empList),
          };
          return result;
        })
      );
  }

  GetLoginIDHistoryList(UserID): Observable<ILoginIDHistoryList[]> {
    this.loginIDHistoryUrl =
      `${environment.billingApiUrl}GetLoginIDHistory/` + UserID + ``;
    return this.http.get<ILoginIDHistoryList[]>(this.loginIDHistoryUrl, {
      headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
    });
  }

  GetOrgVehcileTypeList(
    requestinfo: IApiRequest<IRequestVehicleTypes>
  ): Observable<IApiresponse<IOrgVehicleTypeResponse>> {
    requestinfo = this.fnCheckPurchaseFunctionList(requestinfo);
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IOrgVehicleTypeResponse>>(
      `${environment.billingApiUrl}GetOrgVehicleType`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }
  fnCheckPurchaseFunctionList(requestinfo: IApiRequest<IRequestVehicleTypes>) {
    let request = new ApiRequest<IPurchaseFunctionListRequest>(
      "SoloPurchaseFunctionList",
      environment.applicationVersion,
      environment.requestId
    );
    request.requestInfo = new PurchaseFunctionListRequest(
      this.claims.UserId,
      this.claims.OrgId
    );
    let functionIds = [];
    this.organisationSettingService
      .soloPurchaseFunctionList(request)
      .subscribe((data: IApiresponse<any>) => {
        if (data.responseInfo.orgPaidFunctionList.length > 0) {
          let purchaseFuncListDetails =
            data.responseInfo.orgPaidFunctionList[0].paidFunctions;
          purchaseFuncListDetails.forEach((element) => {
            functionIds.push(element.functionId);
          });
        }
        requestinfo.requestInfo.purchaseFunctionList = functionIds;
      });
    return requestinfo;
  }

  GetApproverList(
    requestinfo: IApiRequest<IAppListRequestDto>
  ): Observable<IApiresponse<IAppListResponseDto>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IAppListResponseDto>>(
      `${environment.billingApiUrl}GetApproverList`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetOwnershipType(orgID): Observable<IOwnershipTypeList> {
    this.ownershipTypeList =
      `${environment.billingApiUrl}GetOwnershipTypes/` + orgID + ``;
    return this.http.get<IOwnershipTypeList>(this.ownershipTypeList, {
      headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
    });
  }

  GetOrganisationEmployeeCounters(orgID): Observable<IEmployeeCounter> {
    this.employeeCounterUrl =
      `${environment.billingApiUrl}GetOrganisationEmployeeCounters/` +
      orgID +
      ``;
    return this.http.get<IEmployeeCounter>(this.employeeCounterUrl, {
      headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
    });
  }

  BulkAddUpdateEmployee(
    addUpdatereq: IAddUpdateBulkEmployeeRequestDto
  ): Observable<any> {
    let userType = addUpdatereq.employees.find((it) => it.userType == 1)
      ? 0
      : 1;
    if (addUpdatereq.employees.length > 5 || addUpdatereq.isImport) {
      if (userType == UserTypeEnum.Claimant) {
        const notificationBody = addUpdatereq.isImport
          ? BackgroundTaskNotificationBody.BulkInviteEmployees
          : BackgroundTaskNotificationBody.BulkUpdateEmployees;
        const route = addUpdatereq.isImport
          ? "orgsetting/importemployee"
          : "orgsetting/manageemployee";
        let jobInfo = new BackgroundTask(
          BackgroundTaskTopics.Billing,
          BackgroundTaskKeys.BulkInviteEmployees,
          addUpdatereq,
          addUpdatereq.employees.map((e) => e.email),
          BackgroundTaskNotificationHeaders.BulkInviteEmployees,
          notificationBody,
          route,
          FunctionPackageEnum.ManageEmployees,
          FunctionPrivilege.ManageEmployees,
          addUpdatereq.fmName
        );

        return this.notificationService.submitBackgroundJob(jobInfo);
      } else {
        const notificationBody = addUpdatereq.isImport
          ? BackgroundTaskNotificationBody.BulkInviteVendors
          : BackgroundTaskNotificationBody.BulkUpdateVendors;
        const route = "vendorSetup";
        let jobInfo = new BackgroundTask(
          BackgroundTaskTopics.Billing,
          BackgroundTaskKeys.BulkInviteEmployees,
          addUpdatereq,
          addUpdatereq.employees.map((e) => e.email),
          BackgroundTaskNotificationHeaders.BulkInviteVendors,
          notificationBody,
          route,
          FunctionPackageEnum.ManageEmployees,
          FunctionPrivilege.ManageEmployees,
          addUpdatereq.fmName
        );

        return this.notificationService.submitBackgroundJob(jobInfo);
      }
    } else {
      let requestinfo = new ApiRequest<IAddUpdateBulkEmployeeRequestDto>(
        "BulkInviteEmployee",
        1.0,
        "111"
      );
      requestinfo.requestInfo = addUpdatereq;
      let body = JSON.stringify(requestinfo);
      return this.http.post<IApiresponse<any>>(
        `${environment.billingApiUrl}BulkInviteEmployee`,
        body,
        { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
      );
    }
  }

  async SoloSaveDashboardGraphData(
    requestinfo: IApiRequest<IDashboardGraphDetail>
  ): Promise<IApiresponse<any>> {
    let body = JSON.stringify(requestinfo);
    let result = await this.http
      .post<IApiresponse<any>>(
        `${environment.expenseApiUrl}SaveDashboardGraphData`,
        body,
        { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
      )
      .toPromise();
    return result;
  }

  UpdateVehicle(
    requestInfo: ApiRequest<IUpdateVehicleTypeRequest>
  ): Observable<any> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<any>(
      `${environment.billingApiUrl}BulkUpdateVehicle`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  UpdateRole(employeeList: IBulkUpdateEmployeeRole[]): Observable<any> {
    let body = JSON.stringify(employeeList);
    return this.http.post<any>(
      `${environment.billingApiUrl}BulkUpdateRole`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  UpdateStatus(employeeList: IBulkUpdateEmployeeStatus[]): Observable<any> {
    let body = JSON.stringify(employeeList);
    return this.http.post<any>(
      `${environment.billingApiUrl}BulkUpdateEmployeeStatus`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetEmployeeImportSampleData() {
    return this.http.get("/assets/sample-data/import/import-employee.json");
  }

  BulkImportEmployees(
    saveRequest: IApiRequest<IImportEmployeeRequest>
  ): Observable<any> {
    const notificationBody = BackgroundTaskNotificationBody.BulkImportEmployees;
    const route = "universalimport/importsummary";
    let jobInfo = new BackgroundTask(
      BackgroundTaskTopics.Billing,
      BackgroundTaskKeys.BulkImportEmployees,
      saveRequest,
      saveRequest.requestInfo.employees.map((e) => e.email),
      BackgroundTaskNotificationHeaders.BulkImportEmployees,
      notificationBody,
      route,
      FunctionPackageEnum.UniversalImport,
      FunctionPrivilege.ManageEmployees,
      saveRequest.requestInfo.fmName
    );

    return this.notificationService.submitBackgroundJob(jobInfo);
  }

  GetCostCentreApproverImportSampleData() {
    return this.http.get(
      "/assets/sample-data/import/costcentre-approver-import.json"
    );
  }

  GetViewUserDetailsById(userId: number): Observable<IApiresponse<any>> {
    let params = new HttpParams();
    params = params.append("userId", userId.toString());
    return this.http.get<IApiresponse<any>>(
      `${environment.billingApiUrl}GetViewUserDetailsById`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  GetEmployeeDomainList(): Observable<IApiresponse<any>> {
    let params = new HttpParams();
    return this.http.get<IApiresponse<any>>(
      `${environment.billingApiUrl}GetEmployeeDomainList`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

 

  UpdateEmployeeDomainListUpdate(request: IApiRequest<any>): Observable<any> {
    let body = JSON.stringify(request);
    return this.http.post<any>(
      `${environment.billingApiUrl}UpdateEmployeeDomainListUpdate`,
      body,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  GetEmployeesToEnableSSO(): Observable<IApiresponse<any>> {
    let params = new HttpParams();
    return this.http.get<IApiresponse<any>>(
      `${environment.billingApiUrl}GetEmployeesToEnableSSO`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  UpdateEmployeeSSOStatus(request: IApiRequest<any>): Observable<any> {
    let body = JSON.stringify(request);
    return this.http.post<any>(
      `${environment.billingApiUrl}UpdateEmployeeSSOStatus`,
      body,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  UpdateSSOPreference(isSSOEnabled): Observable<IApiresponse<boolean>> {
    return this.http.get<IApiresponse<boolean>> (
      `${environment.billingApiUrl}UpdateSSOPreference?isSSOEnabled=` + isSSOEnabled,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

}
