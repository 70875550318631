<div class="overLayDiv active"></div>

<div id="rightSideBar">
    <div class="rightSidePanel active">
        <header>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding-right-left">
                    <div class="row">
                        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8 padding-left-0">
                            <h2>{{'Notifications' | translate}}</h2>
                        </div>
                        <div
                             class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 padding-right-0 header-icons text-right">
                            <ul class="d-flex justify-content-end w-100pc">
                                <li *ngIf="notificationGroups && notificationGroups.length > 0"
                                    class="margin-left-15 margin-right-15"><button (click)="onDeleteNotifications()"
                                            class="btn btn-primary height-38px">{{"notifications_delete" | translate}}</button></li>
                                <li>
                                    <button class="btn btn-outline-secondary border-color-warning text-mustard height-38px"
                                            (click)="closeNotificationPanel()">
                                        {{ 'label_close' | translate }}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div class="clear"></div>
        <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 margin-top-10 no-padding-right-left"
             id="liability-report">
            <div class="block padding-top-10 box-shadow overflow-auto scrollbar-visible heightCalc-6"
                 *ngIf="notificationGroups && notificationGroups.length > 0">
                <div class="row"
                     id="taxRateSetup-expiredSlab"
                     *ngFor="let notificationGroup of notificationGroups">
                    <div class="col-md-12 col-sm-12 no-padding-right-left"
                         *ngIf="notificationGroup.notifications.length > 0">
                        <div id="accordion">
                            <div class="card">
                                <div class="card-header">
                                    <a class="card-link"
                                       id="{{'notificationAccordion' + notificationGroup.header}}"
                                       data-bs-toggle="collapse"
                                       [attr.data-bs-target]="'#' + notificationGroup.header"
                                       (click)="onNotificationGroupClick(notificationGroup)"
                                       aria-expanded="false">
                                        <span class="fw-normal"
                                              *ngIf="!notificationGroup.unreadCount"
                                              [innerHTML]="sanitizerHtml(notificationGroup.headerText)">
                                        </span>
                                        <span *ngIf="notificationGroup.unreadCount"
                                              [innerHTML]="sanitizerHtml(notificationGroup.headerText)">
                                            <span class="badge badge-pill badge-danger align-middle badge-pill-style lH-normal"
                                                  style="top: 0px !important;float:right !important;margin-right: 1em;">
                                                {{notificationGroup.unreadCount}}
                                            </span>
                                        </span>
                                    </a>
                                </div>
                                <div 
                                [attr.id]="notificationGroup.header"  class="collapse">
                                    <div class="card-body padding-10">
                                        <div class="row margin-top-0">
                                            <div class="col-md-12 no-padding-right-left margin-top-0">
                                                <div class="row ">
                                                    <div class="w-100pc "
                                                         style="overflow-x:auto;overflow-y:auto;position: relative;height:auto">
                                                        <div class="row margin-top-0">
                                                            <ul class="flexList"
                                                                style="overflow-x:hidden;overflow-y:auto"
                                                                [ngClass]="{'heightCalc-22 scrollbar-visible': notificationGroup.notifications.length >= 5 }">
                                                                <li class="col-md-12 no-padding-right-left margin-top-0"
                                                                    *ngFor="let notification of notificationGroup.notifications"
                                                                    [ngClass]="{ 'font-weight-bold': notification.isNew }"
                                                                    [@anim]="notification.isVisible">
                                                                    <div>
                                                                        <p class="padding-0 padding-top-5"
                                                                           *ngIf="!notification.failNotification">
                                                                            <span class="margin-right-20">
                                                                                <img [src]="notification.iconUrl"
                                                                                     class="w-40">
                                                                            </span>
                                                                        </p>
                                                                        <p class="w-75pc padding-0 f-13">
                                                                            <span
                                                                                  [innerHTML]="sanitizerHtml(notification.displayText)"></span>
                                                                            <button [hidden]="!notification.actionsBtnVisible"
                                                                            *ngIf="notification.text.header == 'BulkImportEmployeesHeader'"
                                                                                    (click)="onActionClick(notification)"
                                                                                    class="btn btn-primary f-12 mt-2">
                                                                                
                                                                                <span>{{this.translate.instant("emp_imp_exp_msg1")}}</span></button>
                                                                        </p>
                                                                        <p
                                                                           class="padding-0 float-right text-right my-custom-tooltip-addtocart"
                                                                           [ngClass]="notification.failNotification? 'w-25pc':'w-18pc'">
                                                                            <span class="cursor-pointer"
                                                                                  (click)="onSnoozeClick(notification)"
                                                                                  [hidden]="!notification.snoozeBtnVisible">
                                                                                <i class="fas fa-alarm-snooze text-mustard f-20"
                                                                                   ngbTooltip="{{ 'Snooze' | translate }}"
                                                                                   placement="left"
                                                                                   aria-hidden="true">
                                                                                </i>
                                                                            </span>
                                                                            <span *ngIf="notification.text.header != 'BulkImportEmployeesHeader'"
                                                                                  class="padding-LR-10 cursor-pointer"
                                                                                  (click)="onActionClick(notification)"
                                                                                  [hidden]="!notification.actionsBtnVisible">
                                                                                <i class="fas fa-cog text-logo-blue f-20"
                                                                                   [ngClass]="(notification.functionId == 60)?'fa-download':'fa-cog'"
                                                                                   [ngbTooltip]="((notification.functionId == 60) ? DownloadTooltip : NotificationActionTooltip)"
                                                                                   placement="left"
                                                                                   aria-hidden="true">
                                                                                </i>
                                                                            </span>
                                                                            <span class="cursor-pointer"                                                                                  
                                                                                  (click)="onRemoveClick(notification)"
                                                                                  [hidden]="!notification.actionsBtnVisible">
                                                                                <i class="fas fa-times-circle text-secondary f-20"
                                                                                   ngbTooltip="{{ 'Close' | translate }}"
                                                                                   placement="left"
                                                                                   aria-hidden="true">
                                                                                </i>
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                    <div *ngIf="notification.text.header == 'ClaimantsTryingNewSubscription'" class="row margin-left-60">
                                                                        <button id="btnSendInviteToEmployee"
                                                                                class="btn eod-btn-primary"
                                                                                (click)="sendEmployeeInvite(notification)"
                                                                                style="width: auto;">{{"send_invite" | translate}}
                                                                        </button>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="block padding-top-40 box-shadow"
                 *ngIf="!notificationGroups || notificationGroups.length <= 0">
                <p class="padding-top-10 padding-bottom-10"
                   style="font-weight: bold;text-align: center;position: absolute;top: 50%;-ms-transform: translateY(-50%);transform: translateY(-50%);left: 37%;">
                    {{ 'NotificationsNotAvailable' | translate }}
                </p>
            </div>
        </div>
    </div>
</div>
<import-checklist-panel *ngIf="showCheckListPanel" [inputData]="checkListPanelModel"
    (onCloseActionPanel)="fnCloseCheckList($event)" (onSubmitBulkAction)="fnSubmitCheckList($event)">
</import-checklist-panel>

<import-checklist-panel *ngIf="showCheckListDetailsPanel" [inputData]="checkListDetailsPanelModel"
    (onCloseActionPanel)="fnCloseCheckListDetails($event)">
</import-checklist-panel>
<import-employee-error-panel *ngIf="showImportEmployeeErrorPanel"
    [employeeErrorList]="this.importEmployeeResponse.ErrorEmployeeList"
    (closePanelEvent)="fnCloseImportEmployeeErrorPanel($event)"></import-employee-error-panel>