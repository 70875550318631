import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';


@Component({
    selector: 'formly-three-digit-decimal-input',
    templateUrl: "./formly-three-digit-decimal-input.component.html"
})
export class FormlyThreeDigitDecimalInputComponent extends FieldType implements OnInit, OnDestroy {

    isFormSubmitted: boolean = false;
    formSubmissionSubscription: Subscription;
    fieldKey: string;

    constructor(private dynamicFormService: DynamicFormService) {
        super();
    }

    ngOnInit() {
        if (this.field.key) {
            this.fieldKey = this.field.key.toString();
        }
        this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
            this.isFormSubmitted = data;
        });
    }

    ngOnDestroy() {
        if (this.formSubmissionSubscription) {
            this.formSubmissionSubscription.unsubscribe();
        }
    }

    onNumberInput() {
        this.to.change(this.field, this.formControl.value);
    }

}
