<div class="overLayDiv" [ngClass]="{ active: isPanelDisplay }"></div>
<div id="rightSideBar" *ngIf="isPanelDisplay">
    <div class="rightSidePanel active">
        <header>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding-right-left">
                    <div class="row">
                        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8 padding-left-0">
                            <h2> <span class="padding-right-5 no-active"><i
                                        class="far fa-exclamation-circle color-red align-middle"
                                        aria-hidden="true"></i></span>
                                {{'multiple_legs' | translate: param}}
                            </h2>
                        </div>
                        <div
                            class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 padding-right-0 header-icons text-right">
                            <ul class="d-flex justify-content-end w-100pc">
                                <li (click)="fnMapData(true,0,null)"
                                class="padding-right-0" *ngIf="isMileageCategory">
                              <button
                                      class="btn btn-outline-secondary border-color-warning text-mustard height-38px  margin-right-15">
                                      {{ 'view_whole_journey' | translate: param }}
                              </button>
                            </li>
                                <li><button (click)="fnCloseMileageDetailPanel()"
                                        class="btn btn-outline-secondary border-color-warning text-mustard height-38px">
                                    
                                        {{"close" | translate }}</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div class="clear"></div>
        <div class="col-lg-12 margin-top-10 no-padding-right-left heightCalc-6 overflow-auto scrollbar-visible">
            <div class="row">
                <div class="col-lg-12 padding-left-0 text-right">
                    <span class="d-block f-13" *ngIf="!(isCFPReporting && !isMileageCategory)">
                        <span class="w-85pc d-inline-block">
                            {{ 'label_amount' | translate: param }}:
                        </span>
                        <span class="fw-700 w-15pc d-inline-block padding-left-5 text-logo-blue">
                            {{ this.expenseDetailResponse.spentAmount | number: ".2" | noComma }} {{currency}}
                        </span>
                    </span>
                    <span class="d-block f-13">
                        <span class="w-85pc d-inline-block">
                            {{ 'miles_paid_multipleleg' | translate: param }}:
                        </span>
                        <span class="fw-700  w-15pc d-inline-block padding-left-5 text-logo-blue">
                            {{ this.expenseDetailResponse.mileage.totalDistance | number: ".2" | noComma }}
                            {{mileageLabel}}
                        </span>
                    </span>
                    <span class="d-block f-13" *ngIf="isCFPReporting">
                        <span class="w-85pc d-inline-block">
                            {{ 'total_carbon_emission' | translate: param }}:
                        </span>
                        <span class="fw-700  w-15pc d-inline-block padding-left-5 text-logo-blue">
                            {{ this.expenseDetailResponse.mileage.carbonFootprint | number: ".2" | noComma }}
                            {{ carbonEmissionLabel}}
                        </span>
                    </span>
                </div>
            </div>

            <div class="block padding-top-10 box-shadow">
                <div class="row" id="taxRateSetup-expiredSlab"
                    *ngFor="let item of expenseDetailResponse?.mileage.sourceDestinationInformation;index as rowIndex">
                    <div class="col-md-12 col-sm-12 no-padding-right-left ">
                        <div id="accordion">
                            <div class="card margin-top-0">
                                <div class="card-header">
                                    <a class="card-link padding-0 collapsed" id="{{'multipleJourney' + rowIndex}}"
                                        data-bs-toggle="collapse"
                                        (click)="closeMultipleJourneyGroups(rowIndex)"
                                        [attr.data-bs-target]="'#collapseOne-ML' + rowIndex"
                                        [attr.aria-expanded]="rowIndex == 0? true :false">
                                        <span
                                            class="d-inline-block card-link padding-left-10 d-block color-black lH-40 font-weight-bold cursor-pointer">
                                            <div class="row">
                                                <div class="col-lg-9 no-padding-right-left">
                                                    <div class="row">
                                                        <div class="col-lg-8 no-padding-right-left text-truncate ">
                                                            <span class="color-green ">
                                                                {{"uk_mileage_range_from" | translate: param }}:
                                                                <span #fromLocation
                                                                    class="fw-600 text-charcoal-black padding-left-5"
                                                                    *ngIf="item.fromLocation.location.length <= fromTextLength">
                                                                    {{item.fromLocation.location}}
                                                                </span>
                                                                <span #fromLocation
                                                                    class="fw-600 text-charcoal-black padding-left-5"
                                                                    *ngIf="item.fromLocation.location.length > fromTextLength"
                                                                    ngbPopover=" {{item.fromLocation.location}}"
                                                                    triggers="mouseenter:mouseleave" container="body">
                                                                    {{item.fromLocation.location}}
                                                                </span>
                                                            </span>

                                                        </div>
                                                        <div class="col-lg-4 no-padding-right-left text-truncate">
                                                            <span class="color-red padding-left-10 ">
                                                                {{"uk_mileage_range_to" | translate: param }}:

                                                                <span #toLocation
                                                                    class="fw-600 text-charcoal-black padding-left-5"
                                                                    *ngIf="item.toLocation.location.length <= toTextLength">
                                                                    {{item.toLocation.location}}
                                                                </span>
                                                                <span #toLocation
                                                                    class="fw-600 text-charcoal-black padding-left-5"
                                                                    *ngIf="item.toLocation.location.length > toTextLength"
                                                                    ngbPopover=" {{item.toLocation.location}}"
                                                                    triggers="mouseenter:mouseleave" container="body">
                                                                    {{item.toLocation.location}}
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div class="col-lg-3  padding-right-30 text-right text-logo-blue">
                                                    {{ (item.detour+item.milesTraveled) | number: ".2" | noComma }}
                                                    {{mileageLabel}}
                                                </div>
                                            </div>

                                        </span>
                                    </a>
                                </div>
                                <div id="{{'collapseOne-ML' + rowIndex}}" class="in"
                                    [ngClass]="rowIndex == 0?'collapse show':'collapse'" data-bs-parent="#accordion">
                                    <div class="card-body padding-10 bg-white">
                                        <div class="row border-bottom padding-bottom-10">
                                            <div class="col-lg-6 padding-left-0">
                                                <ul>
                                                    <li *ngFor="let innerItem of fnBindMileageSequence(item)"
                                                        [ngClass]="innerItem.class">
                                                        {{ innerItem.name }}</li>
                                                </ul>
                                            </div>
                                            <div class="col-lg-6 padding-right-0">
                                                <ul>
                                                    <li *ngFor="let innerItem of fnBindMileageSequence(item)"
                                                        [ngClass]="innerItem.valueClass" [innerHTML]="innerItem.value">
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="row margin-top-0">
                                            <div class="col-md-12 no-padding-right-left margin-top-0">
                                                <div class="row margin-top-30" [ngClass]="{'no-active':item.detour==0}">
                                                    <div class="col-md-12 no-padding-right-left">
                                                        <div class="input-group ">
                                                            <input type="text"
                                                                class="form-control has-value color-light-grey-disabledType pointer-events-none"
                                                                required="" value="{{item.detourJustificationNotes}}"
                                                                name="advAmt">
                                                            <label for="advAmt">
                                                                {{"detour_justification" | translate: param }}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row margin-top-30" [ngClass]="{'no-active':item.notes==''}">
                                                    <div class="col-md-12 no-padding-right-left">
                                                        <div class="input-group ">
                                                            <input type="text"
                                                                class="form-control has-value color-light-grey-disabledType pointer-events-none"
                                                                required="" value="{{item.notes}}" name="advAmt">
                                                            <label for="advAmt">
                                                                {{"text_notes" | translate: param }}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row margin-top-15 margin-bottom-15">
                                                    <div class="col-lg-12 mx-auto">
                                                        <div class="row">
                                                            <div class="col-lg-6 no-padding-right-left text-center ">

                                                                <button (click)="fnShowViewTagPanel(item.sequence)"
                                                                    [ngClass]="{'no-active':item.tagList==null || item.tagList?.length==0}"
                                                                    class="f-11 bg-transparent text-logo-blue fw-700"><span
                                                                        class="d-block"><i class="far fa-tag"
                                                                            aria-hidden="true"></i></span>
                                                                    {{"view_tag" | translate: param }}
                                                                </button>
                                                                <span
                                                                    [ngClass]="{'no-active':item.tagList==null || item.tagList?.length==0}"
                                                                    class="badge badge-pill badge-danger badge-pill-style right-25 ">
                                                                    {{item.tagList?.length}}
                                                                </span>
                                                            </div>

                                                            <div class=" text-center " [ngClass]="{'col-lg-12':item.tagList==null || item.tagList?.length==0,
                                                            'col-lg-6':item.tagList?.length>0}" *ngIf="!(isCFPReporting && !isMileageCategory)"> <button
                                                                    (click)="fnMapData(false,item.sequence,null)"
                                                                    class="f-11 bg-transparent text-logo-blue fw-700"><span
                                                                        class="d-block"><i class="far fa-map-marker-alt"
                                                                            aria-hidden="true"></i></span>
                                                                    {{"view_map" | translate: param }}
                                                                </button></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<app-view-receipt #viewReceiptComponent [milesTravelled]="milesTravelled" [triangulationMiles]="triangulationMiles"
    [detourMiles]="detourMiles" [amount]="amount" [distance]="distance" [mileageRate]="mileageRate" [origin]="origin"
    [destination]="destination" [receiptLat]="receiptLat" [receiptLong]="receiptLong" [latLongArray]="latLongArray"
    [hasReturnJourney]="hasReturnJourney" [mileageArray]="mileageArray" [mileage]="expenseDetailResponse?.mileage"
    [isMileageCategory]="isMileageCategory" [showMapSectionOnly]="showMapSectionOnly">
</app-view-receipt>
<view-tag-panel *ngIf="showViewTagPanel" [inputData]="this.tagList" (closePanelEvent)="showViewTagPanel = false">
</view-tag-panel>