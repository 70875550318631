import { Component, OnDestroy, OnInit, EventEmitter } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';

@Component({
    selector: 'formly-ng-multi-select-input',
    templateUrl: "./formly-ng-multi-select-input.component.html"
})
export class FormlyNgMultiSelectInputComponent extends FieldType implements OnInit, OnDestroy {

    param: any;

    isFormSubmitted: boolean = false;
    formSubmissionSubscription: Subscription;
    fieldKey: string;

    constructor(
        private dynamicFormService: DynamicFormService
    ) {
        super();
    }

    ngOnInit() {
        if (this.field.key) {
            this.fieldKey = this.field.key.toString();
        }
        this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
            this.isFormSubmitted = data;
        });
    }

    ngOnDestroy() {
        if (this.formSubmissionSubscription) {
            this.formSubmissionSubscription.unsubscribe();
        }
    }

    onValueChange() {
        if (this.to.change) {
            this.to.change(this.field, this.formControl.value);
        }
    }

    onOpen() {
        setTimeout(() => {
            const scrollContainer = document.querySelector(
                ".ng-dropdown-panel-items"
            );
            if (scrollContainer) {
                scrollContainer.scrollTop = 0;
            }
        }, 0);
    }
}