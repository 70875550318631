import { JSEncrypt } from "jsencrypt";
import { publicKey, privateKey } from "src/app/_models/common/rsa-keys";

export class RSAHelper {
  encrypt: any;
  decrypt:any;

  constructor() {}

  Encrypt(stringToEncrypt): string {
    this.encrypt = new JSEncrypt();
    this.encrypt.setPublicKey(publicKey);
    return this.encrypt.encrypt(stringToEncrypt);
  }

  Decrypt(stringToDecrypt): string {
    this.decrypt = new JSEncrypt();
    this.decrypt.setPrivateKey(privateKey);
    return this.decrypt.decrypt(stringToDecrypt);
  }
}
