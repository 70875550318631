import { OnInit, Component, EventEmitter, Input, Output, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { Subscription } from "rxjs";
import { IApiresponse } from "src/app/_models";
import { first } from "rxjs/operators";
import { HowDoIVideoLinks } from "src/app/common/commonvariables";
import { BaseComponent } from "src/app/shared/base/base-component";
import { AlertService } from "src/app/alert-panel/alert.service";
import { CustomerService } from "src/app/_services/customer/customer.service";
import { IGetCustomerResponse, ActiveCustomerResponse } from "src/app/_models/customer/customer";
import { SelectCustomerPopupModel } from "src/app/_models/claimant-on-web/claimant-on-web";

@Component({
    selector: "select-customer-panel",
    templateUrl: "./select-customer-panel.component.html"
})
export class SelectCustomerPanelComponent extends BaseComponent implements OnInit, OnDestroy {

    @Input()
    inputData: SelectCustomerPopupModel;

    @Output() closePanelEvent = new EventEmitter<boolean>();
    @Output() selectItemEvent = new EventEmitter<SelectCustomerPopupModel>();

    userActionEventSubscription: Subscription;
    param: any;
    howDoIVideoLinks = HowDoIVideoLinks;
    searchText: string = '';
    hasFormChanges: boolean = false;

    customersList = <ActiveCustomerResponse[]>[];
    selectedCustomerId: number = 0;

    constructor(
        private alertService: AlertService,
        private customerService: CustomerService,
        private cdr:ChangeDetectorRef
    ) {
      super();
      this.userActionEventSubscription = this.alertService.getUserActionEvent().subscribe(action => {
        if (action) {
            this.closePanelEvent.emit(true);
        }
      });
    }
    ngOnInit(): void {
        this.fnGetActiveCustomersList();
        this.fnSetCustomerInputValues();
    }

    ngOnDestroy(): void {
        this.userActionEventSubscription.unsubscribe();
    }

    canDeactivate(): boolean {
        return !this.hasFormChanges;
    }

    fnClosePanel() {
        if (this.canDeactivate()) {
            this.closePanelEvent.emit(true);
        }
        else {
            this.alertService.onFormClosed();
        }
    }

    fnGetActiveCustomersList() {
        this.customerService
            .GetActiveCustomers()
            .pipe(first())
            .subscribe((response: IApiresponse<IGetCustomerResponse>) => {
                if (response.responseInfo != null) {
                    this.customersList = response.responseInfo.customerValues;
                    this.fnGetSelectedCustomerOnTop();
                    this.cdr.detectChanges();
                }
            });
    }

    fnSelectCustomer() {
        let selectCustomersOutputModel = new SelectCustomerPopupModel();
        if (this.selectedCustomerId > 0) {
            let customserDetails = this.customersList.find(item => item.customerId == this.selectedCustomerId);
            if (customserDetails) {
                selectCustomersOutputModel.customerId = customserDetails.customerId;
                selectCustomersOutputModel.customerName = customserDetails.customerName +' - '+ customserDetails.customerCode1;
            }
        } else {
            selectCustomersOutputModel.customerId = 0;
            selectCustomersOutputModel.customerName = '';
        }
        this.selectItemEvent.emit(selectCustomersOutputModel);
    }

    fnOnChangeCustomer(customerId: number) {
        if (customerId == this.selectedCustomerId) {
            this.selectedCustomerId = 0;
        } else {
            this.selectedCustomerId = customerId;
        }
    }

    fnSetCustomerInputValues() {
        if (this.inputData) {
            this.selectedCustomerId = this.inputData.customerId;
        }
    }
    fnGetSelectedCustomerOnTop() {
        let customerId = this.inputData && this.inputData.customerId ? this.inputData.customerId : 0;

        if (customerId && this.customersList && this.customersList.length > 0) {
            const sortedArr = this.customersList.reduce((acc, element) => {
                if (element.customerId == customerId) {
                    return [element, ...acc];
                }
                return [...acc, element];
            }, []);
            this.customersList = sortedArr;
        }
    }
}
