import {
  OnInit,
  Component,
  EventEmitter,
  Output,
  ViewChild,
  NgZone,
  ChangeDetectorRef,
} from "@angular/core";
import { Subscription } from "rxjs";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { Claims } from "src/app/_models/common/claims";
import { IApiresponse } from "src/app/_models";
import { AppGridComponent } from "src/app/shared/grid/grid-component/grid.component";
import { ColDef } from "ag-grid-community";
import { GridConfig } from "src/app/_models/common/grid/grid-config";
import { CloseFormAction } from "src/app/_models/advance-mileage/org-ukmileage-rate-info";
import { UkMileageRateSettingService } from "src/app/_services/advance-mileage/uk-mileage-rate-setting.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "office-address-panel",
  templateUrl: "./office-address-panel.component.html",
})
export class OfficeAddressPanelComponent implements OnInit {
  @Output() closePanelEvent = new EventEmitter<any>();
  claims: Claims;
  isOverlayEnabled: boolean;
  param: string;
  userActionEventSubscription: Subscription;
  @ViewChild(AppGridComponent) gridComponent: AppGridComponent;
  gridColumnDefs: ColDef[];
  gridConfigItems: GridConfig;
  gridData: any = [];
  gridFilterStatus: number;
  //Ag Grid Variable Declaration
  isOverlayRequired = true;
  closeFormAction: CloseFormAction = new CloseFormAction();
  constructor(
    public zone: NgZone,
    private ukMileageRateSettingService: UkMileageRateSettingService,
    private translate: TranslateService,
    private claimsService: ClaimsService,
    private cdfr: ChangeDetectorRef
  ) {
    this.isOverlayEnabled = false;
    this.claimsService.currentClaims.subscribe((claims) => {
      this.claims = claims;
    });
  }

  ngOnInit() {
    this.fnInitialiseGrid();
    this.fnGetGridItems();
  }

  //Column Definitions
  fnCreateColumnDefs() {
    this.gridColumnDefs = [
      {
        headerName: this.translate.instant("uk_mileage_office_name"),
        field: "officeName",
        resizable: true,
        sortable: true,
        suppressMenu: true,
        minWidth: 100,
        width: 120,
        cellClass: "cursor-pointer",
      },
      {
        headerName: this.translate.instant("uk_mileage_office_address"),
        field: "officeAddress",
        resizable: true,
        sortable: true,
        suppressMenu: true,
        minWidth: 100,
        cellClass: "cursor-pointer",
      },
    ];
  }

  //Grid Config Items
  fnGetGridConfigItems() {
    this.gridConfigItems = new GridConfig(
      true,
      true,
      "officeName",
      "officePostcodeId",
      0,
      false,
      [],
      true,
      true,
      false,
      null,
      "asc",
      40,
      "no_data_availabe",
      null,
      null,
      false,
      false,
      "",
      "",
      false
    );
  }

  //Initialise Grid
  fnInitialiseGrid() {
    this.fnCreateColumnDefs();
    this.fnGetGridConfigItems();
    setTimeout(() => {
      this.gridComponent.initializeGrid(false);
    }, 100);
  }

  fnOnGridCellClick(params) {
    this.closePanelEvent.emit(params.data);
  }

  fnGetGridItems() {
    this.ukMileageRateSettingService
      .SoloGetOrgOfficeCodes()
      .subscribe((res: IApiresponse<any>) => {
        this.gridData = res.responseInfo.officeDetailsList;
        this.fnGetGridConfigItems();
        setTimeout(() => {
          this.gridComponent.refreshGridData();
        }, 100);
        this.cdfr.detectChanges();
      });
  }

  onClosePanel() {
    this.closePanelEvent.emit(false);
  }
}
