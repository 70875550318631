<div class="block">
    <div class="row">
            <div class="col-lg-8 padding-left-0">  
                <h6 class="text-left margin-top-7">
                 <span> {{field.templateOptions.label}}</span> 
                </h6>
            </div>

            <div class="col-lg-4 padding-right-0">  
                <div class="custom-control custom-switch text-right position-relative top--10px"  >
                <input type="checkbox" checked class="custom-control-input" id="{{ 'cbx' + fieldKey }}" [checked]="formControl.value"
                [formControl]="formControl" (input)="onValueChange()" [disabled]="field.templateOptions.disabled">

                <label class="custom-control-label cursor-pointer f-14 fw-600 lH-25 padding-left-5" for="{{ 'cbx' + fieldKey }}"></label>

            </div>
            
        </div>
    </div>
</div>