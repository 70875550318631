export interface IOrgSubscription {
  orgId: number;
}

export class OrgSubscription implements IOrgSubscription {
  orgId: number;
  constructor(orgId: number)
  {
    this.orgId = orgId;
  }
}


export interface IUpdateOrganisationSubscriptionInvoiceStatusRequest {
  organisationSubscriptionInvoiceID: number;
	status :number;
	userId: number;
}

export class UpdateOrganisationSubscriptionInvoiceStatusRequest implements IUpdateOrganisationSubscriptionInvoiceStatusRequest {
  organisationSubscriptionInvoiceID: number;
	status :number;
	userId: number;
}