import { NgModule } from "@angular/core";
import { AlertPanelComponent } from "./alert-panel.component";
import { NgbTooltipModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { AlertService } from "./alert.service";
import { TranslateModule } from "@ngx-translate/core";


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        NgbTooltipModule,
        TranslateModule,
    ],
    declarations: [
        AlertPanelComponent
    ],
    providers: [AlertService],
    exports: [
        NgbTooltipModule,
        AlertPanelComponent
    ]
})
export class AlertModule {
    constructor() { }
}
