<div *ngFor="let option of to.options" [ngClass]="option.class">
  <span class="position-absolute padding-top-10 left-0px top-10pc">
    <input type="radio" [name]="to.name" [formControl]="formControl" [formlyAttributes]="field" [value]="option.id"
      class="svg-18">
  </span>
  <div class="float-left w-90pc text-left ">
    <p class="lH-normal">
      <b>{{ option.value }}</b>
      <span class="d-block color-grey">
        {{ option.description }}
      </span>
    </p>
  </div>
</div>