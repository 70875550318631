export class FunctionPrivilegeMapping {
    constructor(public privilegeID: number, public privilegeName: string, public privilegeCategory: string,
        public privilegeIconUrl: string, public routes: FunctionRouteMapping[]) {

    }
}

export class FunctionRouteMapping {
    constructor(public functionID: number, public route: string) {

    }
}