<span *ngIf="data && data.statusData">
    <span *ngIf="data" [hidden]="!data.statusData.toolTipText" class="{{data.statusData.statusClass}}" placement="left"
        [ngbPopover]="popContent" container="body" triggers="mouseenter:mouseleave"> {{data.statusData.statusText}}
        <i style="padding-left: 5px" class="fas fa-info-circle text-mustard"></i>
    </span>

    <span *ngIf="data" [hidden]="data.statusData.toolTipText" class="{{data.statusData.statusClass}}">
        {{data.statusData.statusText}}
    </span>

    <ng-template #popContent>
        <div [innerHTML]="data.statusData.toolTipText"></div>
    </ng-template>
</span>