import { OnInit, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "advance-policy-violation-panel",
    templateUrl: "./advance-policy-violation-panel.component.html"
})
export class AdvancePolicyViolationPanelComponent implements OnInit {
    @Input()
    inputData: any;
    @Output() closePanelEvent = new EventEmitter<string>();

    showPolicyViolation: boolean = false;
    isNoteError: boolean = false;
    hasFormChanges: boolean = false;
    openSectionName = "advanceCappingLimit";
    advanceCappingLimit = false;

    ngOnInit() {
      this.advanceCappingLimit = true;
    }

    fnClosePanel() {
        this.closePanelEvent.emit();
    }

    fnSubmitPanel() {
        if (this.inputData.justificationNotes && this.inputData.justificationNotes.trim()) {
            this.closePanelEvent.emit(this.inputData.justificationNotes.trim());
        } else {
            this.isNoteError = true;
        }
    }

}
