<div class="overLayDiv active"></div>
<div id="rightSideBar">
  <div class="rightSidePanel active w-65pc">
    <header>
      <div class="row">
        <div class="col-lg-12 no-padding-right-left">
          <div class="row">
            <div class="col-lg-8  padding-left-0">
              <h2>{{ 'preview' | translate }}</h2>
            </div>
            <div class="col-lg-4 padding-right-0 header-icons text-right">
              <ul class="d-flex justify-content-end w-100pc">
                <li (click)="fnSaveReceipt()">
                  <button type="button" class="btn btn-primary color-white height-38px margin-right-15" 
                  [ngClass]="{'disabled-state': hasFormChanges == false}"
                  (click)="fnSavePreview()">
                      {{ 'label_save' | translate: param }}
                  </button>
                </li>
                <li><button class="btn btn-outline-secondary border-color-warning text-mustard height-38px" (click)="fnClosePanel()">
                    {{ 'close' | translate: param }}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="clear"></div>
    <div class="col-lg-12 margin-top-10 no-padding-right-left">
      <div class="row">
        <div class="col-lg-6 ">
          <div class="block heightCalc-6 d-flex flex-column">
                <div class="row">
                    <div class="col-lg-12 padding-left-0 ">                                
                      <div class="floatingLabels_Container margin-top-50">
                          <div class="row margin-top-20">
                              <div class="col-lg-12 no-padding-right-left">
                                <div class="input-group">
                                  <input type="text" class="form-control has-value"
                                  [ngClass]="{'color-red': date == 'Not clear enough for scanning', 'color-green' : date != 'Not clear enough for scanning'}"
                                  [(ngModel)]="date" required="">
                                  <label for="ExpenseCategory">{{ 'label_date' | translate: param }}</label>
                                </div>
                              </div>
                            </div>
                            <div class="row margin-top-40">
                              <div class="col-lg-12 no-padding-right-left">
                                <div class="input-group">
                                  <input type="text" class="form-control has-value"
                                  [ngClass]="{'color-red': amount == 'Not clear enough for scanning', 'color-green' : amount != 'Not clear enough for scanning'}"
                                  [(ngModel)]="amount" required="">
                                  <label for="ExpenseCategory">{{ 'label_amount' | translate: param }}</label>
                                </div>
                              </div>
                            </div>
                            <div class="row margin-top-40">
                              <div class="col-lg-12 no-padding-right-left">
                                <div class="input-group">
                                  <input type="text" class="form-control has-value"
                                    [ngClass]="{'color-red': supplier == 'Not clear enough for scanning', 'color-green' : supplier != 'Not clear enough for scanning'}"
                                    [(ngModel)]="supplier" required="">
                                  <label for="ExpenseCategory">{{ 'text_supplier' | translate: param }}</label>
                                </div>
                              </div>
                            </div>                            
                          </div>                       
                          </div>                            
                        </div>          
          </div>
        </div>
        <div class="col-lg-6">
          <div class="block heightCalc-6" cdkDropList
          (cdkDropListDropped)="drop($event)" style="position: relative;">
            <span class="d-flex justify-content-center">
                <canvas id="canvas"  class="heightCalc-11"></canvas>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>