import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import { IApiresponse } from "src/app/_models/common/request-response/apiresponse";
import {
  IOrganisationSettingResponse,
  IGetCartDetailsRequest,
  ICartDetailsResponse,
  ICartDetailsRequest,
  IFunctionDefaultCurrency,
  IListCartDetails,
  IRemoveFunction,
  IGetOrgDependentFunctionListRequest,
  IOrgDependentFunctionDetails,
  IPurchaseFunctionListRequest,
  IPurchaseFunctionListDetails,
  IFunctionPackageCurrencyMappingResponse,
  RequestGetPreferences,
} from "src/app/_models/organisation-setting/organisation-setting";
import { IApiRequest } from "src/app/_models";
import { OrganisationFeedback } from "src/app/_models/feedback/feedback";
import { ClaimsHeaderService } from "src/app/shared/claimsHeader/claims-header.service";
import { IFunctionPackageCancellationPolicyResponse } from "src/app/_models/organisation-setting/functionPackageCancellationPolicyResponse";
import { map } from "rxjs/operators";
import { AlertService } from "src/app/alert-panel/alert.service";
import { ApprovalRuleSaveRequest } from "src/app/_models/application-setting/approval-rule";
import { IReceiptNumberPreference, IReqReceiptTypeDetails } from "../../_models/vat-novat-receipt/vat-novat-receipt";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class OrganisationSettingService {
  organisationFuncPurchaseCacheKey = "organisationFuncPurchaseCacheKey";
  constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private claimsHeaderService: ClaimsHeaderService,
    private translate:TranslateService
  ) { }
  GetOrganisationSettingList(): Observable<IApiresponse<IOrganisationSettingResponse>> {
    const cacheKey = "OrganisationSettingListData";
    let cacheData = sessionStorage.getItem(cacheKey);
    if (cacheData) {
      return of(JSON.parse(cacheData));
    }
    else 
    {
      var lang= this.translate.getBrowserLang();
      return this.http.get<IApiresponse<IOrganisationSettingResponse>>(
        `${environment.catalogApiUrl}OrganisationSettingList/`+lang,
                { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
      ).pipe(map(data => {
        sessionStorage.setItem(cacheKey, JSON.stringify(data));
        return data;
      }));
    }
  }

  SubmitOrgFeedback(feedbackModel: OrganisationFeedback): Observable<boolean> {
    return this.http.post<boolean>(
      `${environment.billingApiUrl}SubmitOrganisationFeedback`,
      feedbackModel,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  isFeedbackSubmitted(type): Observable<boolean> {
    return this.http.get<boolean>(
      `${environment.billingApiUrl}IsFeedbackSubmitted?type=` + type,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  resetOrganisationSettingListCache(organisationId) {
    const cacheKey = "OrganisationSettingListData";
    sessionStorage.removeItem(cacheKey);
    const cacheKey2 = "SubscribedFunctionsForOrganisationData_" + organisationId;
    sessionStorage.removeItem(cacheKey2);
    this.alertService.onFunctionSubscriptionChange();
  }

  saveApprovalRuleSetting(approvalRuleSaveRequest: ApprovalRuleSaveRequest) {
    return this.http.post<IApiresponse<IListCartDetails>>(
      `${environment.billingApiUrl}SaveApprovalRuleSetting`,
      approvalRuleSaveRequest,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  getSubscribedFunctionsForOrganisation(organisationId: number) {
    const cacheKey = "SubscribedFunctionsForOrganisationData_" + organisationId;
    let cacheData = sessionStorage.getItem(cacheKey);
    if (cacheData) {
      return of(JSON.parse(cacheData));
    }
    else {
      let params = new HttpParams();
      params = params.append("organisationId", organisationId.toString());
      return this.http.get<any>(
        `${environment.billingApiUrl}GetSubscribedFunctionsForOrganisation`,
        {
          params: params,
          headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
        }
      ).pipe(map(data => {
        sessionStorage.setItem(cacheKey, JSON.stringify(data));
        return data;
      }));
    }
  }

  getCartDetailsList(
    requestinfo: IApiRequest<IGetCartDetailsRequest>
  ): Observable<IApiresponse<IListCartDetails>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IListCartDetails>>(
      `${environment.catalogApiUrl}GetCartDetails`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SaveCartDetails(
    requestinfo: IApiRequest<ICartDetailsRequest>
  ): Observable<IApiresponse<ICartDetailsResponse>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<ICartDetailsResponse>>(
      `${environment.catalogApiUrl}SaveCartDetails`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetFunctionDefaultCurrency(
    requestinfo: IApiRequest<IFunctionDefaultCurrency>
  ): Observable<any> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<ICartDetailsResponse>>(
      `${environment.catalogApiUrl}GetFunctionDefaultCurrency`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  RemoveFunction(
    requestinfo: IApiRequest<IRemoveFunction>
  ): Observable<IApiresponse<ICartDetailsResponse>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<ICartDetailsResponse>>(
      `${environment.catalogApiUrl}RemoveCartFunction`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  getOrgDependentFunctionList(
    requestinfo: IApiRequest<IGetOrgDependentFunctionListRequest>
  ): Observable<IApiresponse<IOrgDependentFunctionDetails>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IOrgDependentFunctionDetails>>(
      `${environment.catalogApiUrl}GetOrgDependentFunctionList`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  soloPurchaseFunctionList(
    requestinfo: IApiRequest<IPurchaseFunctionListRequest>
  ): Observable<IApiresponse<IPurchaseFunctionListDetails>> {

    let cacheData = sessionStorage.getItem(this.organisationFuncPurchaseCacheKey);
    if (cacheData) {
      return of(JSON.parse(cacheData));
    }
    else {
      let body = JSON.stringify(requestinfo);
      return this.http.post<IApiresponse<IPurchaseFunctionListDetails>>(
        `${environment.billingApiUrl}PurchaseFunctionList`,
        body,
        { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
      ).pipe(map(result => {
        sessionStorage.setItem(this.organisationFuncPurchaseCacheKey, JSON.stringify(result));
        return result;
      }));
    }
  }

  SoloGetAllFunctionPackageCancellationPolicysByPackageId(
    functionPackageId: number,
    langaugeCode: string
  ): Observable<IApiresponse<IFunctionPackageCancellationPolicyResponse[]>> {
    let url =
      `${environment.catalogApiUrl}GetAllFunctionPackageCancellationPolicysByPackageId/` +
      functionPackageId +
      `/` +
      langaugeCode +
      ``;
    return this.http.get<
      IApiresponse<IFunctionPackageCancellationPolicyResponse[]>
    >(url, {
      headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
    });
  }

  getOrgAllDependentFunctionList(): Observable<
    IApiresponse<IOrgDependentFunctionDetails>
  > {
    return this.http.get<IApiresponse<IOrgDependentFunctionDetails>>(
      `${environment.catalogApiUrl}GetAllDependentFuncList`,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetReceiptNumberSettings(): Observable<any> {
    return this.http.get<any>(
      `${environment.billingApiUrl}GetReceiptNumberSettings`,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SaveReceiptNumberSettings(requestinfo: IApiRequest<IReceiptNumberPreference>): Observable<IApiresponse<boolean>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<boolean>>(`${environment.billingApiUrl}SaveReceiptNumberSettings`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  getVatNoVatReceiptType(): Observable<any> {
    return this.http.get<any>(
      `${environment.catalogApiUrl}GetVatNoVatReceiptType`,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  updateVatNoVatReceiptType(requestinfo: IApiRequest<IReqReceiptTypeDetails>): Observable<IApiresponse<boolean>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<boolean>>(`${environment.billingApiUrl}UpdateReceiptTypeSettings`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }


  getAllFunctionsForOrganisation(organisationId: number) {
    let params = new HttpParams();
    params = params.append("organisationId", organisationId.toString());
    return this.http.get<any>(
      `${environment.billingApiUrl}GetAllFunctionsForOrganisation`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }
  getCartSubscriptionType(organisationId: number) {
    let params = new HttpParams();
    params = params.append("organisationId", organisationId.toString());
    return this.http.get<any>(
      `${environment.catalogApiUrl}GetCartSubscriptionType`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }
  GetOrgPricingFunctionList(currencyId: number) {
    let params = new HttpParams();
    params = params.append("currencyId", currencyId.toString());
    return this.http.get<any>(
      `${environment.catalogApiUrl}GetOrgPricingFunctionList`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }
  GetOrgBasicFunctionList() {
    return this.http.get<any>(
      `${environment.catalogApiUrl}GetOrgBasicFunctionList`,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }
  GetOrgFunctionPackageCurrencyMapping(currencyId: number) {
    let params = new HttpParams();
    params = params.append("currencyId", currencyId.toString());
    return this.http.get<IApiresponse<IFunctionPackageCurrencyMappingResponse>>(
      `${environment.catalogApiUrl}GetOrgFunctionPackageCurrencyMapping`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }
  GetOrgFunctionTrialDays(organisationId: number) {
    let params = new HttpParams();
    params = params.append("organisationId", organisationId.toString());
    return this.http.get<any>(
      `${environment.billingApiUrl}GetOrgFunctionTrialDays`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }
  GetOrganisationPreferences(
    requestinfo: IApiRequest<any>
  ): Observable<IApiresponse<any>> {
    requestinfo.requestInfo = new RequestGetPreferences();
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}GetOrganisationPreferences`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    ).pipe(map(result => {
      return result;
    }));
  }


  UpdateAttendeeOrgPreferences(requestinfo: IApiRequest<any>): Observable<IApiresponse<boolean>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<boolean>>(`${environment.billingApiUrl}UpdateAttendeeOrgPreferences`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  
  UpdatePowerUserOrgPreferences(requestinfo: IApiRequest<any>): Observable<IApiresponse<boolean>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<boolean>>(`${environment.billingApiUrl}UpdatePowerUserOrgPreferences`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  UpdateManageCCPreference(requestinfo: IApiRequest<any>): Observable<IApiresponse<boolean>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<boolean>>(`${environment.billingApiUrl}UpdateManageCCPreference`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  UpdateCCFeedPreferences(requestinfo: IApiRequest<any>): Observable<IApiresponse<boolean>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<boolean>>(`${environment.billingApiUrl}UpdateCCFeedPreferences`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  GetCCFeedPreferences(): Observable<IApiresponse<any>> {
    return this.http.get<IApiresponse<any>>(
      `${environment.billingApiUrl}GetCCFeedPreferences`,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetAttendeeOrgPreferences(): Observable<IApiresponse<any>> {
    return this.http.get<IApiresponse<any>>(
      `${environment.billingApiUrl}GetAttendeeOrgPreferences`,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  AddUpdateCompliancePreferences(requestinfo: IApiRequest<any>): Observable<IApiresponse<boolean>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<boolean>>(`${environment.billingApiUrl}AddUpdateCompliancePreferences`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  AddUpdateCounterApproverPreferences(requestinfo: IApiRequest<any>): Observable<IApiresponse<boolean>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<boolean>>(`${environment.billingApiUrl}AddUpdateCounterApproverPreferences`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

}
