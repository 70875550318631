<div class="input-group margin-top-20 " [ngClass]="{requiredAsterisk : field.templateOptions.required}">

  <ng-select id="{{ 'select' + fieldKey }}" class="solo-dropdown filterDropdown multiSelectCategoryDropdown"
    [items]="to.options" bindLabel="value" bindValue="id" [clearable]="false"
    [placeholder]="field.templateOptions.placeholder" [formControl]="formControl" [selectOnTab]="true"
    notFoundText="{{ 'no_data_available' | translate: param }}" (change)="onValueChange()" [multiple]="true"
    groupBy="groupByField" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="false"
    [selectOnTab]="true" (open)="onOpen()">
    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      <div *ngIf="items.length != to.options.length">
        <div class="ng-value" *ngFor="let item of items | slice: 0:1">
          {{ item.value }}
        </div>
      </div>
      <div *ngIf="items.length == to.options.length" class="ng-value">
        {{ 'all' | translate}}
      </div>
      <div *ngIf="
          items.length != to.options.length &&
          items.length > 1
        " class="ng-value">
        <span class="ng-value-label">(+{{ items.length - 1 }})</span>
      </div>
    </ng-template>
    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
      <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" />
      {{ 'all' | translate}}
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
      <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" />
      {{ item.value }}
    </ng-template>
  </ng-select>

  <div *ngIf="formControl.errors && formControl.errors.required && isFormSubmitted" class="alert-danger">
    <span> <strong>{{ "label_Required" | translate: param }}</strong></span>
  </div>

  <span *ngIf="field.templateOptions.customErrors && isFormSubmitted">
    <div class="alert-danger" *ngFor="let error of field.templateOptions.customErrors">
      <span><strong>{{error}}</strong></span>
    </div>
  </span>

  <div *ngIf="field.templateOptions.alertNote" class="alert-danger">
    {{ field.templateOptions.alertNote }}
  </div>

</div>