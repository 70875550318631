import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';


@Component({
    selector: 'formly-toggle-input',
    templateUrl: "./formly-toggle-input.component.html"
})
export class FormlyToggleInputComponent extends FieldType implements OnInit, OnDestroy {
    param: any;
    isFormSubmitted: boolean = false;
    formSubmissionSubscription: Subscription;
    fieldKey: string;
    mainClass: string;
    enableBigToggle = true;
    constructor(private dynamicFormService: DynamicFormService) {
        super();
    }

    ngOnInit() {
        if (this.to.options && this.to.options[0] && this.to.options[0].mainClass) {
            this.mainClass = this.to.options[0].mainClass;
        }
        else {
            this.mainClass = "custom-control custom-switch text-right position-relative top--10px margin-right-15";
        }
        if (this.to.options && this.to.options[0] && this.to.options[0].enableBigToggle != undefined){
            this.enableBigToggle = this.to.options[0].enableBigToggle;
        }
        if (this.field.key) {
            this.fieldKey = this.field.key.toString();
        }
        this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
            this.isFormSubmitted = data;
        });
    }

    ngOnDestroy() {
        if (this.formSubmissionSubscription) {
            this.formSubmissionSubscription.unsubscribe();
        }
    }

    onValueChange() {
        this.to.change(this.field, this.formControl.value);
    }

}