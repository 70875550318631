import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { Subscription } from 'rxjs';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';

@Component({
    selector: 'formly-calendar-date-input',
    templateUrl: "./formly-calendar-date-input.component.html"
})
export class FormlyCalendarDateInputComponent extends FieldType implements OnInit, OnDestroy, AfterViewInit {
    @Output() dateChange = new EventEmitter<Date>();
    dateFormats = [
        {
         id:1,
         name:"d/m/y"
        },
        { 
          id:2,
          name:"d/m/Y"
        },
        {
          id:3,
          name:"m/d/d"
        },
        {
          id:4,
          name:"m/d/Y"
        },
        {
          id:5,
          name:"y/m/d"
        },
        {
          id:6,
          name:"Y/M"
        },
        {
          id:7,
          name:"m-d-y"
        },
        {
          id:8,
          name:"d-m-y"
        },
        {
          id:9,
          name:"d-M-y"
        },
        {
          id:10,
          name:"d-M-Y"
        },
        {
          id:11,
          name:"d.m.y"
        },
        {
          id:12,
          name:"d.m.Y"
        },
        {
          id:13,
          name:"y.m.d"
        },
        {
          id:14,
          name:"M d, y"
        },
        {
          id:15,
          name:"M d, Y"
        },
        {
          id:16,
          name:"dmy"
        },
        {
          id:17,
          name:"ymd"
        },
        {
          id:18,
          name:"dmY"
        },
        {
          id:19,
          name:"Ymd"
        },
      ];
      dateFormatString: string = "d-M-Y";
      defaultDate: Date = new Date();
      datePickerOptions: FlatpickrOptions = {
        mode: "single",
        defaultDate: new Date(),
        altInput: true,
        altFormat: this.dateFormatString,
        dateFormat: this.dateFormatString
      };
    @ViewChild("dateInput") dateInput: any;
    isFormSubmitted: boolean = false;
    formSubmissionSubscription: Subscription;
    fieldKey: string;

    constructor(private dynamicFormService: DynamicFormService) {
        super();
    }

    ngOnInit() {
        if (this.field.key) {
            this.fieldKey = this.field.key.toString();
        }

        this.datePickerOptions.defaultDate = new Date();
        if (this.field && this.field.formControl && this.field.formControl.value) {
           if(typeof this.field.formControl.value === 'string')
           {
            this.datePickerOptions.defaultDate = new Date(+this.field.formControl.value);
           } else
            this.datePickerOptions.defaultDate = new Date(this.field.formControl.value);
            this.defaultDate = this.datePickerOptions.defaultDate;
        } else {
            this.formControl.setValue(new Date());
        }

        if (this.field && this.field.templateOptions && this.field.templateOptions.dateFormat) {
            this.datePickerOptions.dateFormat = this.field.templateOptions.dateFormat;
            this.dateFormatString = this.dateFormats.find(it=>it.id==this.field.templateOptions.dateFormat).name ;
            this.datePickerOptions = {
              mode: "single",
              defaultDate: this.defaultDate,
              altInput: true,
              altFormat: this.dateFormatString,
              dateFormat: this.dateFormatString
            };
          }

        this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
            this.isFormSubmitted = data;
        });
    }

    ngOnDestroy() {
        if (this.formSubmissionSubscription) {
            this.formSubmissionSubscription.unsubscribe();
        }
    }

    ngAfterViewInit() {
        //Initialize datePicker
        this.dateInput.nativeElement.flatpickr(this.datePickerOptions);
    }

    onValueChange() {
        if (this.to.change) {
            this.to.change(this.field, this.formControl.value);
        }
    }
}
