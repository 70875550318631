import { Component, ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
    selector: 'formatted-text-cell',
    templateUrl: './formatted-text-renderer.component.html',
    encapsulation: ViewEncapsulation.None
})
export class FormattedTextRenderer implements ICellRendererAngularComp {
    text: string = '';
    class: string = '';
    constructor(private translate: TranslateService) {
    }

    initilize(params: ICellRendererParams) {
        this.text = params.getValue();
        if(params.data && params.data.customClass)
        {
            this.class = this.text == this.translate.instant('not_applicable_string') || this.text == null ? '' : params.data.customClass;
        }
        else
        {
            if (params.colDef.headerComponentParams && params.colDef.headerComponentParams.class) {
                this.class = this.text == this.translate.instant('not_applicable_string') || this.text == null ? '' : params.colDef.headerComponentParams.class;
            }
        }
        
    }

    agInit(params: any): void {
        this.initilize(params);
    }

    refresh(params: any): boolean {
        this.initilize(params);
        return true;
    }
}