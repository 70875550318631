<div id="rightSideBar" [hidden]="!selectedItems || selectedItems.length == 0">
    <div class="rightSidePanel w-70pc active">
        <header>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding-right-left">
                    <div class="row">
                        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8 padding-left-0">
                            <h2>{{ panelHeading }}</h2>
                        </div>
                        <div
                            class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 padding-right-0 header-icons text-right">
                            <ul class="d-flex justify-content-end w-100pc">
                                <li *ngIf="gridData.length === 1" (click)="fnShowConfigureshowLoggingAlertPanel()">
                                    <button
                                        class="btn btn-outline-secondary border-color-warning text-mustard height-38px margin-right-15">
                                        {{ 'label_log' | translate: param }}
                                    </button>
                                </li>
                                <li>
                                    <button class="btn btn-primary height-38px margin-right-15" (click)="saveAssignments()" [ngClass]="{'disabled bg-secondary opacity-60': disableSave }"> {{ 'save_text' |
                                        translate }} </button>
                                </li>
                                <li class="padding-right-0" (click)="fnClosePanel()">
                                    <button
                                        class="btn btn-outline-secondary border-color-warning text-mustard height-38px">
                                        {{ 'label_close' | translate }}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div class="clear"></div>
        <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 margin-top-10 no-padding-right-left">
            <div class="block">
                <div class="row padding-top-10">
                    <div class="col-md-4 no-padding-right-left">
                        <div class="input-group">
                            <ng-select class="solo-dropdown filterDropdown" [items]="approversList"
                                bindLabel="displayName" bindValue="employeeID" placeholder="Approver Name"
                                [clearable]="false" [selectOnTab]="true" required-field [(ngModel)]="assignedApproverId"
                                (change)="onApproverChange()" notFoundText="{{ 'no_data_available' | translate }}">
                            </ng-select>
                        </div>
                        <div class="d-block mt-1" *ngIf="assignedApproverId">
                            <small>
                                <span><strong>{{ 'label_email' | translate }}:</strong> {{selectedApproverEmail}}
                                </span>
                                <span class="d-block"><strong>{{ 'label_cost_centre' | translate }}:</strong>
                                    {{formateCostCentreName()}} </span>
                            </small>
                        </div>
                        <div class="alert-danger" style="font-weight: normal;" *ngIf="selectedApproverStatus">
                            <span> <strong>{{ 'label_approver_status' | translate }}:&nbsp;</strong>
                                {{ selectedApproverStatus }}
                            </span>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <span>
                            <button class="btn btn-primary f-13"
                                [ngClass]="{'disabled bg-secondary opacity-60': disableAssign}"
                                (click)="assignApprovers()">
                                {{ "label_assign" | translate }}
                            </button>
                        </span>
                    </div>
                    <div class="col-md-7">
                        <span class="position-relative fw-600 text-logo-blue lH-30">{{ anyTextForDisplay }}</span>
                    </div>
                </div>
            </div>
            <div class="block heightCalc-14">
                <div class="row">
                    <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 no-padding-right-left heightCalc-17">
                        <h6 class="text-charcoal-black fw-700 f-14">
                            {{ selectedItemsText }}:
                        </h6>

                        <!----------------------Start Grid--------------------------->
                        <app-grid [columns]="gridColumnDefs" [data]="gridData" [gridConfigItems]="gridConfigItems"
                            (onCellClick)="fnOnGridCellClick($event)">
                        </app-grid>
                        <!----------------------End Grid--------------------------->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!----------------------Side Panels--------------------------->
<employee-status-panel [hidden]="!showApproverStatusPanel" (closePanel)="closeEmployeeStatusPanel($event)"
    (onRemoveApprover)="onRemoveApprover($event)" [employeesList]="employeesList" [costCentre]="approverListCostCentre"
    [costCentreCode]="approverListCostCentreCode" [costCentreId]="approverListCostCentreId" [itemType]="panelItemType">
</employee-status-panel>