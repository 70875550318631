import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'formly-text-input',
    templateUrl: "./formly-text-input.component.html"
})
export class FormlyTextInputComponent extends FieldType implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('textboxToShowTooltip') textarea: ElementRef

    isFormSubmitted: boolean = false;
    formSubmissionSubscription: Subscription;
    fieldKey: string;
    mainClass: string = "input-group margin-top-20";
    notesClass: string = "";
    constructor(private dynamicFormService: DynamicFormService, private cdRef: ChangeDetectorRef
        , private sanitizer: DomSanitizer) {
        super();
    }
    ngAfterViewInit() {
        //https://gitter.im/formly-js/ng2-formly?at=5ddf7f0b55066245982d0e87
        //https://stackblitz.com/edit/angular-x3wc97-gijbl3
        this.form.valueChanges.subscribe(() => {
            this.cdRef.detectChanges()
        });

        setTimeout(() => {
            this.checkOverflow();
        }, 300);

    }
    ngOnInit() {
        if (this.field.key) {
            this.fieldKey = this.field.key.toString();
        }
        if (this.field?.templateOptions?.mainClass) {
            this.mainClass = this.field?.templateOptions?.mainClass;
        }
        if (this.field?.templateOptions?.headingClass) {
            this.notesClass = this.field?.templateOptions?.headingClass;
        }
        this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
            this.isFormSubmitted = data;
            if (this.isFormSubmitted) {
                if (!this.formControl.value || this.formControl.value.trim() == '') {
                    this.formControl.setValue('');
                }
            }
            setTimeout(() => {
                this.cdRef.detectChanges()
            }, 100);
            
        });


    }

    ngOnDestroy() {
        if (this.formSubmissionSubscription) {
            this.formSubmissionSubscription.unsubscribe();
        }
    }

    onValueChange() {
        if (this.to.change) {
            this.to.change(this.field, this.formControl.value);
            this.cdRef.detectChanges()
        }
    }

    omitSpecialChar(event) {
        if (this.field.templateOptions.omitSpecialChar) {
            let k;
            k = event.charCode;
            return (k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32;
        }        
        if (this.field.templateOptions.alphaNumericOnly) {
            return this.alphaNumericOnly(event);
        }
        if (this.field.templateOptions.allowOnlyApostropheAndHyphenChar) {
            return this.allowOnlyApostropheAndHyphenChar(event);
        }
        if (this.field.templateOptions.allowAcuteChar) {
            return this.allowAcuteChar(event);
        }
        if (this.field.templateOptions.allowOnlyNumeric) {
            return this.numaricOnly(event);
        }
        if (this.field.templateOptions.alphaNumericSpecialWithoutComma) {
            return this.alphaNumericSpecialWithoutComma(event);
        }
        return this.notAllowOnlySpaceChar(event);
    }

    numaricOnly(e) {
        let regex =this.field.templateOptions.allowSpecialChar ? new RegExp("^[ 0-9_~`!$%^*(){};:\"'<>,@./#&+-]*$") : new RegExp("^[0-9 ]+$");
        let str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (regex.test(str)) {
            return true;
        }
        else return false;
        //return e.charCode >= 48 && e.charCode <= 57
    }
    alphaNumericOnly(e) {
        let regex =this.field.templateOptions.allowSpecialChar ?
        new RegExp("^[ A-Za-z0-9_~`!$%^*(){};:\"'<>,@./#&+-]*$") : new RegExp("^[a-zA-Z0-9 ]+$");
        let str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (regex.test(str)) {
            return true;
        }
        else return false;

    }

    allowOnlyApostropheAndHyphenChar(e) {
        let regex = new RegExp("^[a-zA-Z '-]+$");
        let str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (regex.test(str)) {
            return true;
        }
        else return false;
    }

    allowAcuteChar(e) {
        let regex = this.field.templateOptions.allowSpecialChar ?
                      new RegExp("^[ A-Za-z_~`!$%^*(){};:\"'<>,@./#&+-]*$"): new RegExp("^[a-zA-ZÀ-ú\p{L} '-]+$");
        let str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (regex.test(str)) {
            return true;
        }
        else return false;
    }
    alphaNumericSpecialWithoutComma(e) {
        let regex =  new RegExp("^[ A-Za-z0-9_~`!$%^*(){};:\"'<>@./#&+-]*$");  
        let str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (regex.test(str)) {
            return true;
        }
        else return false;

    }
    
    notAllowOnlySpaceChar(e) {
        if(this.field.templateOptions.notAllowOnlySpaceChar)
        {            
            let str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
            let s = str.trim();
            if(!s && !this.formControl.value)
            {
                return false;
            }
        }
         return true;
    }

    showTooltip = false;
    checkOverflow() {
        this.showTooltip = this.textarea.nativeElement.offsetHeight < this.textarea.nativeElement.scrollHeight ||
            this.textarea.nativeElement.offsetWidth < this.textarea.nativeElement.scrollWidth;
    }
    sanitizerHtml(htmlCustomMsg) {
        if (htmlCustomMsg) {
            let safeText = this.sanitizer.bypassSecurityTrustHtml(htmlCustomMsg);
            return safeText;

        }
        else {
            return '&nbsp';
        }
    }
}