import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-textbox-renderer',
  templateUrl: './textbox-renderer.component.html'
})
export class TextboxRendererComponent implements ICellRendererAngularComp {
  errDeriveName: boolean = false;
  deriveName: string;
  params: ICellRendererParams;

  constructor() {
  }

  initilize(params: ICellRendererParams) {
    this.params = params;
    this.deriveName = params.getValue();
  }

  agInit(params: any): void {
    this.initilize(params);
  }

  refresh(params: any): boolean {
    this.initilize(params);
    return true;
  }

  onChange(event) {
    
    this.deriveName = event.target.value;
    this.errDeriveName = this.deriveName == '' ? true : false;
    this.params.setValue(this.deriveName);
    this.params.eGridCell.click();
  }



}
