

export interface IDeviceInfo {
    languageCode: string;
    platform: string;
    applicationVersion: string;
    appId: string;
    deviceId: string;
    browser:string;
    bVersion:string;
}

export class DeviceInfo implements IDeviceInfo {
    languageCode: string;
    platform: string;
    applicationVersion: string;
    appId: string;
    deviceId: string;
    browser:string;
    bVersion:string;

    constructor(languageCode: string, platform: string, applicationVersion: string, appId: string, deviceId: string,
        browser:string, bVersion:string) {
        this.languageCode = languageCode;
        this.platform = platform;
        this.applicationVersion = applicationVersion;
        this.appId = appId;
        this.deviceId = deviceId;
        this.browser = browser;
        this.bVersion = bVersion;
    }
}
