import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { MarketingPopup } from "src/app/_models/common/marketing-popup";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { RSAHelper } from "src/app/_helpers/RSAHelper";
import { OrganisationSettingService } from "src/app/_services/organisation-setting/organisation-setting.service";
import { ApiRequest, FunctionPackageEnum, IApiresponse } from "src/app/_models";
import { GetOrgDependentFunctionListRequest, CartDetailsRequest, ICartDetailsRequest, GetCartDetailsRequest, IPurchaseFunctionListRequest, PurchaseFunctionListRequest } from "src/app/_models/organisation-setting/organisation-setting";
import { Claims } from "src/app/_models/common/claims";
import { environment } from "src/environments/environment";
import { OrgSubscriptionFunctionService } from "src/app/_services/billing-pricing/Org-Subscription-Function.service";
import { AccountingIntegrationService } from "src/app/_services/Accounting/accounting-integration.service";
import { ISoloCheckFuncPurchaseListRequest, SoloCheckFuncPurchaseListRequest } from "src/app/_models/common/solo-check-func-purchase-list-request";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Subject } from "rxjs";
import { AlertPanelModel } from "src/app/_models/common/alertPanelModel";
import { OrgBasicFunctionSubscriptionService } from "src/app/_services/billing-pricing/Org-BasicFunction-Subscription.service";

@Component({
  selector: "marketing-popup",
  templateUrl: "./marketing-popup.component.html",
  styleUrls: ["./marketing-popup.component.scss"],
})
export class MarketingPopupComponent implements OnInit {
  @Input("data")
  data: MarketingPopup;

  @Output()
  closePanel = new EventEmitter();

  claims: Claims;
  showImagePopup: boolean = false;
  dependentFunction: any = [];
  isFunctionAddedToCart: boolean = false;
  isDependentFunctionPurchased: boolean = false;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private organisationSettingService: OrganisationSettingService,
    private claimsService: ClaimsService,
    private sanitizer: DomSanitizer,
    private orgBasicFunctionSubscriptionService: OrgBasicFunctionSubscriptionService
  ) {
    this.claimsService.currentClaims.subscribe((claims) => {
      this.claims = claims;
    });
  }

  ngOnInit() {
    this.isDependentFunctionPurchased = true;
    if(this.claims.BandId > 0)
    {
      this.getOrgBasicFunctionSubscriptionTrialDetails();
    }
    this.getMySubscriptionSettings();
    //this.getOrgTrialingFunctionList(); // getOrgPurchaseFunctionList this function giving both record so no need of this. Nitesh
    this.getOrgPurchaseFunctionList();
    this.getCartDetails();
  }

  getCartDetails(): any {
    let getCartDetailsRequest = new ApiRequest<GetCartDetailsRequest>(
      "SoloGetCartDetails",
      1,
      "111"
    );
    getCartDetailsRequest.requestInfo = new GetCartDetailsRequest(
      this.claims.UserId,
      this.claims.OrgId
    );

    this.organisationSettingService
      .getCartDetailsList(getCartDetailsRequest)
      .subscribe((data) => {
        if (data.responseInfo != null) {
          this.claims.CartCount = data.responseInfo.listCartDetails.length;
          this.claimsService.updateClaims(this.claims);

          this.isFunctionAddedToCart = this.isFunctionInCart(
            this.data.functionPackageId,
            data.responseInfo.listCartDetails
          );
        }
      });
  }

  getOrgPurchaseFunctionList() {
    let request = new ApiRequest<IPurchaseFunctionListRequest>(
      "SoloPurchaseFunctionList",
      environment.applicationVersion,
      environment.requestId
    );
    request.requestInfo = new PurchaseFunctionListRequest(this.claims.UserId, this.claims.OrgId);
    this.organisationSettingService
      .soloPurchaseFunctionList(request)
      .subscribe((data: IApiresponse<any>) => {
        if (data.statusCode == 0) {
          if (data.responseInfo.orgPaidFunctionList.length > 0 && this.data.dependentFunctionId > 0) {
            let purchaseFuncListDetails = data.responseInfo.orgPaidFunctionList[0].paidFunctions;
            if (this.data.dependentFunction2Id == 0) {
              let functionSubscribed = purchaseFuncListDetails.find(
                (x) => x.functionId == this.data.dependentFunctionId
              );

              if (functionSubscribed) {
                this.isDependentFunctionPurchased = true;
              } else {
                this.isDependentFunctionPurchased = false;
              }
            } else {
              let mileagePurchaseResult = purchaseFuncListDetails.find(
                (x) => x.functionId == this.data.dependentFunctionId
              );
              let taxPackage = purchaseFuncListDetails.find(
                (x) => x.functionId == this.data.dependentFunction2Id
              );
              if (
                taxPackage == undefined ||
                mileagePurchaseResult == undefined
              ) {

                this.isDependentFunctionPurchased = false;
              } else {
                this.isDependentFunctionPurchased = true;
              }
            }
          }
          else {

            switch (this.data.functionPackageId) {
              case FunctionPackageEnum.NonReimbursableFuel:
              case FunctionPackageEnum.FuelCard:
              case FunctionPackageEnum.LiabilityReport:
              case FunctionPackageEnum.AuditTrail:
                this.isDependentFunctionPurchased = false;
                break;

            }
          }
        }
      });

  }

  // getOrgTrialingFunctionList() {
  //   let OrgTrialingFunctionRequest = new ApiRequest<OrgSubscription>(
  //     "SoloTrialingFunctionListDetail",
  //     0,
  //     environment.requestId
  //   );
  //   OrgTrialingFunctionRequest.requestInfo = new OrgSubscription(
  //     this.claims.OrgId
  //   );
  //   this.orgSubscriptionFunctionService
  //     .getOrgSubscriptionTrialingFunctionList(OrgTrialingFunctionRequest)
  //     .subscribe((data) => {
  //       if (data.statusCode == 0) {
  //         if (
  //           data.responseInfo.length > 0 &&
  //           this.data.dependentFunctionId > 0
  //         ) {
  //           let functionSubscribed = data.responseInfo.find(
  //             (x) => x.functionID == this.data.dependentFunctionId
  //           );
  //           if (functionSubscribed) {
  //             this.isDependentFunctionPurchased = true;
  //           } else {
  //             this.isDependentFunctionPurchased = false;
  //           }
  //         }
  //       }
  //     });
  // }

  getMySubscriptionSettings() {
    let functionDetails = [];
    this.organisationSettingService
      .GetOrganisationSettingList()
      .subscribe((functionCategories) => {
        if (functionCategories.statusCode == 0) {
          functionCategories.responseInfo.functionCategoryDetail.forEach((x) =>
            x.functionPackageDetail.forEach((y) => {
              functionDetails.push(y);
            })
          );
          this.getDependentFunction(
            this.data.functionPackageId,
            functionDetails
          );
        }
      });
  }

  dependentFunctionList: string;

  getDependentFunction(FunctionPackageId: number, functionDetailss: any[]) {
    this.dependentFunction = [];
    this.dependentFunctionList = "";

    let getOrgDependentFunctionListRequest = new ApiRequest<
      GetOrgDependentFunctionListRequest
    >("SoloGetOrgDependentFunctionList", 1, "111");
    getOrgDependentFunctionListRequest.requestInfo = new GetOrgDependentFunctionListRequest(
      FunctionPackageId.toString(),
      this.claims.OrgId
    );

    this.organisationSettingService
      .getOrgDependentFunctionList(getOrgDependentFunctionListRequest)
      .subscribe((data) => {
        if (data.responseInfo != null) {
          data.responseInfo.orgDependentFunctionList.forEach((x) => {
            if (x.functionID == FunctionPackageId) {
              functionDetailss.forEach((items) => {
                if (items.functionPackageId == x.dependentFunctionID) {
                  this.dependentFunction.push({
                    functionPackageId: items.functionPackageId,
                    functionPackageName: items.functionPackageName,
                    functionPackageDescripition:
                      items.functionPackageDescripition,
                    dependentFunctionAmount: "",
                    dependentFunctionIcon: items.iconUrl,
                    dependentFunctionVideoURL: items.videoUrl,
                    functionURL: items.packagePath,
                    isSubscribed: x.isSubscribed
                  });
                }
              });
            }
          });
          for (let index = 0; index < this.dependentFunction.length; index++) {
            if (this.dependentFunction.length == 1) {
              this.dependentFunctionList += this.dependentFunction[index].functionPackageName;
            }
            else {
              if (index == this.dependentFunction.length - 1) {
                this.dependentFunctionList += this.dependentFunction[index].functionPackageName
              }
              else if (index == this.dependentFunction.length - 2) {
                this.dependentFunctionList += this.dependentFunction[index].functionPackageName + ' & '
              }
              else {
                this.dependentFunctionList += this.dependentFunction[index].functionPackageName + ", ";
              }
            }

          }
        }
      });
  }

  openImagePopupEvent() {
    this.showImagePopup = true;
  }

  closeImagePopupEvent() {
    this.showImagePopup = false;
  }

  closeMarketingPopup() {
    this.closePanel.emit();
  }

  routerNavigate() {
    let isFree = "false";
    let packagePath = this.data.packagePath;
    let functionPackageName = this.data.functionPackageName;
    let functionPackageId = this.data.functionPackageId.toString();
    let functionStatus = "0";
    this.router.navigate(["/addtocart"], {
      queryParams: {
        isFree: this.EncryptParameters(isFree),
        packagePath: this.EncryptParameters(packagePath),
        functionPackageName: this.EncryptParameters(functionPackageName),
        functionPackageId: this.EncryptParameters(functionPackageId),
        functionStatus: this.EncryptParameters(functionStatus),
      },
    });
  }

  fnAddToCart() {

    if (!this.isFunctionAddedToCart) {
      this.isFunctionAddedToCart = true;
      this.fnSaveCartDetails();
      this.saveDependentFunction();      
      // if (this.data.functionPackageId == FunctionPackageEnum.SetupAdvancedMileage) {
      //   this.router.navigate(["/orgsetting/mileagewizard"]);
      // } else {
      //   this.isFunctionAddedToCart = true;
      //   this.fnSaveCartDetails();
      //   this.saveDependentFunction();
      // }
    }
    else {
      this.router.navigate(["/shoppingcartlist"]);
    }

  }

  fnSaveCartDetails() {
    let request = new ApiRequest<ICartDetailsRequest>(
      "SaveCartDetails",
      environment.applicationVersion,
      environment.requestId
    );
    request.requestInfo = new CartDetailsRequest(
      this.claims.UserId,
      this.claims.OrgId,
      false,
      this.data.functionPackageId,
      this.data.functionPackageName
    );
    this.organisationSettingService
      .SaveCartDetails(request)
      .subscribe((data) => {
        if (data.statusCode == 0) {
          this.claims.CartCount += 1;
          this.claimsService.updateClaims(this.claims);
          this.closePanel.emit();
          this.router.navigate(["/shoppingcartlist"]);
        }
      });
  }

  private saveDependentFunction() {
    if (this.dependentFunction.length > 0) {
      let saveCartDetailsRequest = new ApiRequest<CartDetailsRequest>(
        "SaveCartDetails",
        1,
        "1111"
      );
      this.dependentFunction.filter(x => !x.isSubscribed).forEach((depend) => {
        saveCartDetailsRequest.requestInfo = new CartDetailsRequest(
          this.claims.UserId,
          this.claims.OrgId,
          false,
          depend.functionPackageId,
          depend.functionPackageName
        );
        this.organisationSettingService
          .SaveCartDetails(saveCartDetailsRequest)
          .subscribe((data) => {
            if (data.statusCode == 0) {
              this.claims.CartCount += 1;
              this.claimsService.updateClaims(this.claims);
            }
          });
      });
    }
  }

  isFunctionInCart(functionPackageId, cartList) {
    return cartList.some((item) => item.functionPackageId == functionPackageId);
  }

  EncryptParameters(encryptValue) {
    let rsaHelper = new RSAHelper();
    let encrypt = rsaHelper.Encrypt(encryptValue);
    return encrypt;
  }
  safeText: SafeHtml;
  sanitizerHtml(element) {
    this.safeText = this.sanitizer.bypassSecurityTrustHtml(element);
    return this.safeText;
  }

  dependentFunctionVideoURL: string;

  eventsSubject: Subject<void> = new Subject<void>();

  showDependentVideo(videoLink) {
    this.dependentFunctionVideoURL = videoLink;
    this.eventsSubject.next();
  }
  navigateToFunction(url) {
    this.router.navigate([url]);
  }
  alertPanelModel = new AlertPanelModel(
    false,
    "",
    [""],
    "",
    "",
    true,
    this.translate.instant("please_review_or_confirm")
  );
  alertPanelReceiver(event) {
    this.alertPanelModel = new AlertPanelModel(
      false,
      "",
      [""],
      "",
      "",
      true,
      this.translate.instant("please_review_or_confirm")
    );
    if(event)
    {
      localStorage.setItem("isEssentialPackSubscribeDisplay", "1");
      this.router.navigate(["/essential-pack"]);  
    }
    else
    {
      localStorage.removeItem("isEssentialPackSubscribeDisplay");
    }
  }
  isEssentialPackAlertRequired: boolean;
  getOrgBasicFunctionSubscriptionTrialDetails() {
    this.orgBasicFunctionSubscriptionService
      .GetOrgBasicFunctionSubscriptionTrialDetails(this.claims.OrgId)
      .subscribe((res: any) => {
        if (res.responseInfo) {
          if (!res.responseInfo.isBasicFunctionPurchased) {
            this.isEssentialPackAlertRequired = true;
          }
          else {
            this.isEssentialPackAlertRequired = false;
          }
        }
      });
  }
  fnEssentialPackAlert() {

    this.alertPanelModel = new AlertPanelModel(
      true,
      this.translate.instant('label_alert'),
      [],
      this.translate.instant('label_cancel'),
      this.translate.instant('label_ok'),
      true,
      this.translate.instant('duty_of_care_compliance_subtext')
    );
    let panelMsg = [];
    panelMsg.push({
      iconClass: 'fas fa-exclamation-circle color-red align-middle',
      textHtml: this.translate.instant("ess_pack_alert_msg1")
    });
    panelMsg.push({
      iconClass: 'fas fa-exclamation-circle text-mustard align-middle',
      textHtml: this.translate.instant("ess_pack_alert_msg2")
    });
    this.alertPanelModel.htmlMessage = panelMsg;
  }
}
