import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { environment } from "../../../environments/environment";
import { IApiRequest, IApiresponse } from "../../_models";
import { ClaimsHeaderService } from "src/app/shared/claimsHeader/claims-header.service";
import {
  IOrgBasicFunctionSubscriptionTrialDetails,
  IOrgBasicFunctionSubscriptionSaveRequest,
} from "src/app/_models/billing-pricing/OrgBasicFunctionSubscriptionTrialDetails";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class OrgBasicFunctionSubscriptionService {

  organisationTrialCacheKey = "OrganisationFunctionSubscriptionCache";

  constructor(
    private http: HttpClient,
    private claimsHeaderService: ClaimsHeaderService
  ) { }

  GetOrgBasicFunctionSubscriptionTrialDetails(
    orgId: number
  ): Observable<IApiresponse<IOrgBasicFunctionSubscriptionTrialDetails>> {
    let cacheData = sessionStorage.getItem(this.organisationTrialCacheKey);
    if (cacheData) {
      return of(JSON.parse(cacheData));
    }
    else {
      let params = new HttpParams();
      params = params.append("organisationId", orgId.toString());
      let url = `${environment.billingApiUrl}GetOrgBasicFunctionSubscriptionTrialDetails`;
      return this.http.get<
        IApiresponse<IOrgBasicFunctionSubscriptionTrialDetails>
      >(url, {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }).pipe(map(result => {
        sessionStorage.setItem(this.organisationTrialCacheKey, JSON.stringify(result));
        return result;
      }));
    }
  }
  SaveOrgBasicFunctionSubscription(
    requestinfo: IApiRequest<IOrgBasicFunctionSubscriptionSaveRequest>
  ): Observable<IApiresponse<IOrgBasicFunctionSubscriptionSaveRequest>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<
      IApiresponse<IOrgBasicFunctionSubscriptionSaveRequest>
    >(
      `${environment.billingApiUrl}SaveOrgBasicFunctionSubscription`,
      body,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    ).pipe(map(result => {
      sessionStorage.removeItem("OrganisationFunctionSubscriptionCache");
      return result;
    }));
  }

  DropOrganisationFunctionSubscriptionCache() {
    const cacheKey = "OrganisationFunctionSubscriptionCache";
    sessionStorage.removeItem(cacheKey);
  }

}
