import { Component, OnInit, Inject, forwardRef } from "@angular/core";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map, debounceTime } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { ApiRequest, IApiRequest, IApiresponse } from "src/app/_models";
import { Observable, of } from "rxjs";
import {
  ISetCurrencyRequest,
  IGetCurrencyRequest,
  IGetExpenseCounterRequestDto,
} from "src/app/_models/claimant/currencyrequest";
import {
  IGetCurrencyResponse,
  ISetCurrencyResponse,
  IGetExpenseCounterResponseDto,
} from "src/app/_models/claimant/currencyresponse";
import { Currency } from "src/app/_models/common/currency";
import { setHeader } from "src/app/_helpers/setRequestHeader";
import { ClaimsHeaderService } from "src/app/shared/claimsHeader/claims-header.service";
import { ClaimsService } from "../common/claims.service";
import { Claims } from "../../_models/common/claims";
import { CurrencyFormModel, ICurrencyRequest } from "../../_models/Currency/currencyFormModel";
import { CurrencyFetchRequest } from "../../_models/fxRate/fx-rate.model";


@Injectable({
  providedIn: "root",
})
export class CurrencyService implements OnInit {

  claims: Claims;
  OrgId: number = 0;

  constructor(
    private http: HttpClient,
    private claimsHeaderService: ClaimsHeaderService,
    private claimsService: ClaimsService
  ) {
    this.claimsService.currentClaims.subscribe(claims => {
      this.claims = claims;
    });
  }

  ngOnInit() {
    if (this.claims.OrgId) {
      this.OrgId = this.claims.OrgId;
    }
    this.getAllExpenseCurrency(this.OrgId);
  }

 getAllExpenseCurrency(orgId, expenseDate: any = 0,isForeignCurrency=false) {
    let reqFetchCurrency = new ApiRequest<CurrencyFetchRequest>(
      "AddUpdateFxRateSetup",
      environment.applicationVersion,
      environment.requestId
    );
    let reqFetchCurrencyInfo = new CurrencyFetchRequest();   
    reqFetchCurrencyInfo.expenseDate = expenseDate == 0 ? new Date().getTime() : new Date(expenseDate).getTime();

    if(expenseDate == 0){
      reqFetchCurrencyInfo.expenseDate = new Date().getTime();
    }
    else{
      //handle negative date issue  
      if (typeof expenseDate == "string") {
        let convertedDate = new Date();
        let tempDate = expenseDate.replace(/-/g, '/');
        convertedDate = new Date(tempDate);
        reqFetchCurrencyInfo.expenseDate = new Date(convertedDate).getTime();
      }
      else {
        reqFetchCurrencyInfo.expenseDate = new Date(expenseDate).getTime();
      }
    }
    reqFetchCurrencyInfo.isForeignCurrency=isForeignCurrency;
    reqFetchCurrency.requestInfo = reqFetchCurrencyInfo;  
    let body = JSON.stringify(reqFetchCurrency);
    const CurrencyUrl = `${environment.catalogApiUrl}GetCurrency`;
      return this.http.post<Currency[]>(CurrencyUrl, body, {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }).pipe(map(data => {        
        return data;
      }));    
  }

  setCurrencyForOrganisation(
    requestinfo: IApiRequest<ISetCurrencyRequest>
  ): Observable<IApiresponse<ISetCurrencyResponse>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<ISetCurrencyResponse>>(
      `${environment.billingApiUrl}UpdateCurrencyForOrganisation`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }


  addCurrencyForOrganisation(
    requestinfo: IApiRequest<ICurrencyRequest>
  ): Observable<any> {
    //Observable<IApiresponse<Currency>> {

    const cacheKey = "ExpenseCurrencyListData";
    sessionStorage.removeItem(cacheKey);

    let formData = new FormData();

    formData.append('currencyData', requestinfo.requestInfo.currencyData);
    formData.append('icon', requestinfo.requestInfo.icon);

    return this.http.post<IApiresponse<any>>(
      `${environment.catalogApiUrl}AddCurrency`,
      formData,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeaderWithoutContentTypeHeader() }
    );
  }


  deleteOrgCurrency(
    currencyId, OrgId, userid, userName
  ): Observable<any> {
    const cacheKey = "ExpenseCurrencyListData";
    const CurrencyUrl = `${environment.catalogApiUrl}DeleteCurrency/` + currencyId + `/` + OrgId + `/` + userid + `/` + userName;
    return this.http.get<Currency[]>(CurrencyUrl, {
      headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
    }).pipe(map(data => {
      sessionStorage.removeItem(cacheKey);
      sessionStorage.setItem(cacheKey, JSON.stringify(data));
      return data;
    }));
  }


  getCurrencyForOrganisation(
    requestinfo: IApiRequest<IGetCurrencyRequest>
  ): Observable<IApiresponse<IGetCurrencyResponse>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IGetCurrencyResponse>>(
      `${environment.billingApiUrl}GetCurrencyForOrganisation`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  getExpenseCounter(
    requestinfo: IApiRequest<IGetExpenseCounterRequestDto>
  ): Observable<IApiresponse<IGetExpenseCounterResponseDto>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IGetExpenseCounterResponseDto>>(
      `${environment.expenseApiUrl}GetOrganisationExpensesCounter`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }
}
