import { INRGetDashboardClaimaintDetail, NRGetDashboardClaimaintDetail } from "./nrget-dashboard-claimaint-detail";
export interface INRGetDashboardClaimaintResponse {
    totalCount: number;
    totalCompliantCount: number;
    totalNonCompliantCount: number;
    hasMoreRecords: number;
    nrGetDashboardClaimaintList: INRGetDashboardClaimaintDetail[];
    dateFilter: Date;
}
export class NRGetDashboardClaimaintResponse implements INRGetDashboardClaimaintResponse {
    totalCount: number;
    totalCompliantCount: number;
    totalNonCompliantCount: number;
    hasMoreRecords: number;
    nrGetDashboardClaimaintList: NRGetDashboardClaimaintDetail[];
    dateFilter: Date;
}
