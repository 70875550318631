<div class="overLayDiv active" *ngIf="isOverlayRequired"></div>
<div id="rightSideBar">
    <div class="rightSidePanel w-85pc active">
        <header class="py-1 d-flex justify-content-between align-items-center height-60px">
            <h2>
                <span *ngIf="!isPayStepEnabled"> {{ "enable_this_step" | translate }} </span>
                <span *ngIf="isPayStepEnabled"> {{ "disable_this_step" | translate }} </span>
            </h2>
            <div>
               <button type="button" (click)="fnShowLoggingPanel();"
                    class="btn eod-btn-outline-warning height-38px mr-2">
                    {{ "log" | translate }}
                </button>
                <button type="button" (click)="fnSubmitConfiguration()" 
                [ngClass]="{ disabled: disableSaveButton || !(this.setupPayStepForm && this.setupPayStepForm.form && this.setupPayStepForm.form.dirty)}"

                    class="btn eod-btn-primary height-38px mr-2">
                    {{ "label_save" | translate }}
                </button>
                <button type="button" (click)="fnClosePanel();"
                    class="btn eod-btn-outline-warning height-38px">
                    {{ 'label_close' | translate }}
                </button>
            </div>
        </header>
        <div class="block mt-2">
            <dynamic-form [model]="setupPayStepFormModel" [fields]="setupPayStepFormFields">
            </dynamic-form>

            <div class="f-15 margin-top-10 pointer-events-none">
                <i class="fas fa-circle text-logo-blue f-12 pr-2"></i>
                {{ "setup_pay_step_note1_1" | translate }}
                <strong>{{ "setup_pay_step_note1_2" | translate }}</strong>
                {{"setup_pay_step_note1_3" | translate }}
            </div>
            <div class="f-15 margin-top-10 pointer-events-none">
                <i class="fas fa-circle text-logo-blue f-12 pr-2"></i>
                {{ "setup_pay_step_note2" | translate }}
            </div>
            <div class="f-15 margin-top-10 pointer-events-none">
                <i class="fas fa-circle text-logo-blue f-12 pr-2"></i>
                {{ "setup_pay_step_note3_1" | translate }}
                <strong>{{ "setup_pay_step_note3_2" | translate }}</strong>
                {{ "setup_pay_step_note3_3" | translate }}
            </div>
            <div class="f-15 margin-top-10 pointer-events-none">
                <i class="fas fa-circle text-logo-blue f-12 pr-2"></i>
                {{ "setup_pay_step_note4_1" | translate }}
                <strong>{{ "setup_pay_step_note4_2" | translate }}</strong>
                {{ "setup_pay_step_note4_3" | translate }}
                <strong> {{ "setup_pay_step_note4_4" | translate }}</strong>
            </div>
        </div>
        <div class="clear"></div>
        <div class="block mt-2 pb-0">
            <h6 class="fw-600 border-bottom padding-bottom-15 mb-0"> {{"expense_flow" | translate}}</h6>
        </div>
        <div class="block mt-0 eod-bg-white" id="dashboard">
            <div class="overLayDiv active position-absolute"></div>

            <div class="row mb-2" *ngIf="financeApprovalPendingCount > 0 || financePayPendingCount > 0">
                <div class="col-md-12 d-flex justify-content-center">
                    <div class="items-for-approval">
                        <button class="btn eod-btn-primary btn-sm mx-1"  *ngIf="financeApprovalPendingCount > 0">
                            {{"items_for_finance_approval" | translate}}
                            <span class="eod-btn-badge eod-bg-danger">{{financeApprovalPendingCount}}</span>
                        </button>
                        <button class="btn eod-btn-primary btn-sm mx-1" *ngIf="financePayPendingCount > 0">
                            {{ "items_for_payment" | translate }}
                            <span class="eod-btn-badge eod-bg-danger">{{financePayPendingCount}}</span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="row" id="overview-expenseStatus">
                <div class="col-md-2 px-2">
                    <div class="dashboard-widgets-sm">
                    <div class="d-flex justify-content-between">
                        <p class="widget-p-head">{{"incomplete_claims" | translate}}</p>
                    </div>
                    <div class="d-flex justify-content-between">
                        <div>
                            <h6>{{ this.incompleteExpenses.claimants}}</h6>
                            <p class="eod-text-grey-02">{{ "claimants" | translate }}</p>
                        </div>
                        <div class="text-right">
                            <h6>{{ this.incompleteExpenses.count }}</h6>
                            <p class="eod-text-grey-02">{{"item_count" | translate}}</p>
                        </div>
                    </div>
                    </div>
                    <div class="widgets-b-border eod-bg-warning"></div>
                    <i class="fas fa-long-arrow-alt-right fa-lg"></i>
                </div>
                <div class="col-md-2 px-2" >
                    <div class="dashboard-widgets-sm">
                    <div class="d-flex justify-content-between">
                        <p class="widget-p-head">{{ "pending_with_approver" | translate }}</p>
                    </div>
                    <div class="d-flex justify-content-between">
                        <div>
                            <h6>{{ this.pendingExpensesWithApprover.claimants }}</h6>
                            <p class="eod-text-grey-02">{{ "claimants" | translate }}</p>
                        </div>
                        <div class="text-right">
                            <h6>{{ this.pendingExpensesWithApprover.count }}</h6>
                            <p class="eod-text-grey-02">{{"item_count" | translate }}</p>
                        </div>
                    </div>
                    </div>
                    <div class="widgets-b-border eod-bg-warning"></div>
                    <i class="fas fa-long-arrow-alt-right fa-lg"></i>
                </div>
                <div class="col-md-2 px-2" >
                    <div class="dashboard-widgets-sm">
                    <div class="d-flex justify-content-between">
                        <p class="widget-p-head">{{"pending_finance_approval" | translate}}</p>
                    </div>
                    <div class="d-flex justify-content-between">
                        <div>
                            <h6>{{this.pendingExpensewithFA.claimants}}</h6>
                            <p class="eod-text-grey-02">{{ "claimants" | translate }}</p>
                        </div>
                        <div class="text-right">
                            <h6>{{ this.pendingExpensewithFA.count }}</h6>
                            <p class="eod-text-grey-02">{{"item_count" | translate}}</p>
                        </div>
                    </div>
                    </div>
                    <div class="widgets-b-border eod-bg-warning"></div>
                    <i class="fas fa-long-arrow-alt-right fa-lg"></i>
                </div>
                <div class="col-md-2 px-2 position-relative z-index-10000">
                    <div class="cs-pending-payment-inactive" [ngClass]="{'cs-pending-payment-active': this.setupPayStepFormModel?.isPayStepEnabled}">
                        <div class="dashboard-widgets-sm disabled opacity-100">
                            <div class="cs-overlay-div" [ngClass]="{'opacity-0': this.setupPayStepFormModel?.isPayStepEnabled}"></div>
                            <div class="d-flex justify-content-between">
                                <p class="widget-p-head">{{ "pending_passed_for_payment" | translate }}</p>
                            </div>
                           
                            <div class="d-flex justify-content-between">
                                <div>
                                    <h6>{{this.pendingPassedForPayment.claimants}}</h6>
                                    <p class="eod-text-grey-02">{{ "claimants" | translate }}</p>
                                </div>
                                <div class="text-right">
                                    <h6>{{ this.pendingPassedForPayment.count }}</h6>
                                    <p class="eod-text-grey-02">{{"item_count" | translate }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="widgets-b-border eod-bg-warning mb-0"></div>
                        <span class="disable-arrow-badge" [ngClass]="{ 'active-arrow-badge': this.setupPayStepFormModel?.isPayStepEnabled}">
                           <span *ngIf="this.setupPayStepFormModel?.isPayStepEnabled">
                            {{"label_enabled" | translate }}
                           </span>
                           <span *ngIf="!this.setupPayStepFormModel?.isPayStepEnabled">
                            {{"label_disabled" | translate }}
                           </span>
                        </span>
                    </div>
                </div>
                <div class="col-md-2 px-2" >
                    <i class="fas fa-long-arrow-alt-right fa-lg -left-8"></i>
                    <div class="dashboard-widgets-sm">
                    <div class="d-flex justify-content-between">
                        <p class="widget-p-head">{{"passed_for_payment" | translate }}</p>
                    </div>
                    <div class="d-flex justify-content-between">
                        <div>
                            <h6>{{this.passedForPayment.claimants}}</h6>
                            <p class="eod-text-grey-02">{{ "claimants" | translate }}</p>
                        </div>
                        <div class="text-right">
                            <h6>{{ this.passedForPayment.count }}</h6>
                            <p class="eod-text-grey-02">{{"item_count" | translate}}</p>
                        </div>
                    </div>
                    </div>
                    <div class="widgets-b-border eod-bg-success"></div>
                </div>
               
            </div>
        </div> 
        
    </div>
</div>


<div class="overLayDiv active"  *ngIf="showPendingItemsAlert"></div>
<div id="rightSideBar" *ngIf="showPendingItemsAlert">
    <form novalidate>
        <div class="rightSidePanel active eod-sidepanel w-60pc">
            <header class="py-1 d-flex justify-content-between align-items-center height-60px">
                <h2>{{ "label_alert" | translate }}</h2>
            </header>
            <div class="block">
                <div class="d-flex">
                    <i class="fas fa-exclamation-circle mr-2 mt-1 eod-text-danger fa-lg"></i>
                    <h6> {{ "pending_items_alert_note1" | translate }}</h6>
                </div>
                <div class="d-flex">
                    <i class="fas fa-exclamation-circle mr-2 mt-1 eod-text-danger fa-lg"></i>
                    <h6> {{ "pending_items_alert_note2" | translate }}</h6>
                </div>
                <div class="d-flex">
                    <i class="fas fa-exclamation-circle mr-2 mt-1 eod-text-danger fa-lg"></i>
                    <h6> {{ "pending_items_alert_note3" | translate }}</h6>
                </div>
                <div class="mt-3 d-flex justify-content-center">
                    <div class="w-30pc mr-3">
                        <div class="dashboard-widgets-sm eod-bg-grey-04">
                            <div class="d-flex justify-content-between">
                                <h6 class="widget-p-head">{{ "label_expenses" | translate }}</h6>
                            </div>
                            <div class="d-flex justify-content-between">
                                <div>
                                    <h6>{{pendingCountFinancePayment}}</h6>
                                    <p class="eod-text-grey-02">{{ "item_count" | translate }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="widgets-b-border eod-bg-primary"></div>
                    </div>
                    <div class="w-30pc mr-3">
                        <div class="dashboard-widgets-sm eod-bg-grey-04">
                            <div class="d-flex justify-content-between">
                                <h6 class="widget-p-head">{{ "label_advances" | translate }}</h6>
                            </div>
                            <div class="d-flex justify-content-between">
                                <div>
                                    <h6>{{pendingCountAdvancePay}}</h6>
                                    <p class="eod-text-grey-02">{{ "item_count" | translate }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="widgets-b-border eod-bg-primary"></div>
                    </div>
                    <div class="w-30pc">
                        <div class="dashboard-widgets-sm eod-bg-grey-04">
                            <div class="d-flex justify-content-between">
                                <h6 class="widget-p-head">{{ "label_invoices" | translate }}</h6>
                            </div>
                            <div class="d-flex justify-content-between">
                                <div>
                                    <h6>{{pendingCountInvoicePay}}</h6>
                                    <p class="eod-text-grey-02">{{ "item_count" | translate }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="widgets-b-border eod-bg-primary"></div>
                    </div>
                </div>
            </div>
            <div class="mt-auto d-flex justify-content-center p-2 height-55px eod-bg-white">
                <button class="btn eod-btn-outline-warning-wbg height-38px mr-2" (click)="fnPendingItemsAlertReceived(false)">
                    {{ "cancel" | translate }}
                </button>
                <button class="btn eod-btn-primary height-38px" (click)="fnPendingItemsAlertReceived(true)">
                    {{ "label_confirm" | translate }}
                </button>
            </div>
        </div>
    </form>
</div>