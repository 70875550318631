export enum EmployeeStatusEnum {
  Active = 1,
  Inactive = 2,
  NotInvited = 3,
  Pending = 4,
  Delete = 5
}

export enum UserTypeEnum {
  Claimant = 1,
  Approver = 2,
  FinanceManager = 3,
  Agent = 4,
  Vendor = 5
}


