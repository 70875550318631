import { Component, OnDestroy, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DateFormatPipe } from 'src/app/_helpers/pipe.module';
import { BaseCategoryTypeEnum, MileageUOMEnum, ApiRequest, IApiresponse, JourneyTypeEnum } from 'src/app/_models';
import {
  MileageMultipleDateDetails, SelectDetourInputModel, SelectDetourOutputModel,
  SelectTagsPopupModel, LocationCommonDetail, SelectRecentJourneyInputModel, SelectRecentJourneyOutputModel
} from 'src/app/_models/claimant-on-web/claimant-on-web';
import { AlertPanelModel } from 'src/app/_models/common/alertPanelModel';
import { ClaimantOnWebService } from 'src/app/_services/claimant-on-web/claimant-on-web.service';
import { ClaimsService } from 'src/app/_services/common/claims.service';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';
import { IOrganisationGooglePackageUpdateCallsRequest, OrganisationGooglePackageUpdateCallsRequest } from 'src/app/_models/google-mapping/google-mapping';
import { GoogleMappingService } from 'src/app/_services/google-mapping/google-mapping.service';
import { environment } from 'src/environments/environment';
import { Claims } from "src/app/_models/common/claims";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'formly-mileage-details',
  templateUrl: "./formly-mileage-details.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyMileageDetailsComponent extends FieldType implements OnInit, OnDestroy, OnChanges {

  get mileageUOMEnum() {
    return MileageUOMEnum;
  }

  claimsSubscription: Subscription;
  claims: Claims;
  userHomeCurrencyCode: string;

  isFormSubmitted: boolean = false;
  formSubmissionSubscription: Subscription;
  fieldKey: string;

  showSelectDetourPanel: boolean = false;
  selectDetourInputModel: SelectDetourInputModel;
  selectedMileageDateUniqueId: number;

  showDebtourNotActivatedAlertPanel: boolean;
  debtourNotActivatedAlertModel: AlertPanelModel;

  showCreateMileageTagPanel: boolean = false;
  createMileageTagInputModel: SelectTagsPopupModel;

  activeTab: string;
  showSelectRecentJourneyPanel: boolean = false;
  selectRecentJourneyInputModel: SelectRecentJourneyInputModel;
  gm_lable: string;

  queryWait: boolean = false;
  googleDynamicAddressList: any;
  isFromGoogleDropdownVisible: boolean = false;
  isToGoogleDropdownVisible: boolean = false;
  isFromHomeOfcDropdownVisible: boolean = false;
  isToHomeOfcDropdownVisible: boolean = false;
  isFromGoogleAddressNotFound: boolean = false;
  isToGoogleAddressNotFound: boolean = false;
  countOfGoogleCalls: number = 0;

  fromLocation: string = '';
  toLocation: string = '';

  showSwitchMultipleLegAlertPanel: boolean;
  switchMultipleLegAlertModel: AlertPanelModel;
  showMileageSection: boolean = true;
  carbonEmissionText: string;
  isCarbonFootprintReporting: boolean = false;
  constructor(private dynamicFormService: DynamicFormService,
    private datePipe: DateFormatPipe,
    private translate: TranslateService,
    private claimsService: ClaimsService,
    private claimantOnWebService: ClaimantOnWebService,
    private googleMappingService: GoogleMappingService,
    private cdr: ChangeDetectorRef, private sanitizer: DomSanitizer

  ) {
    super();
    this.claimsSubscription = this.claimsService.currentClaims.subscribe((claims) => {
      this.claims = claims;
      this.userHomeCurrencyCode = claims.UserHomeCurrencyCode;
    });
  }

  ngOnInit() {

    if (this.field.key) {
      this.fieldKey = this.field.key.toString();
    }

    if (this.field?.templateOptions?.isCarbonFootprintReporting) {
      this.carbonEmissionText = this.translate.instant('text_carbonemission');
      this.isCarbonFootprintReporting = this.field?.templateOptions?.isCarbonFootprintReporting;
    }

    this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
      this.isFormSubmitted = data;
    });
    if (this.fnIsMileageGoogleExpense()) this.gm_lable = ' ' + this.translate.instant('gm_message');
    this.fnSetGoogleCallsMsg();
  }

  ngOnDestroy() {
    if (this.formSubmissionSubscription) {
      this.formSubmissionSubscription.unsubscribe();
    }
    if (this.claimsSubscription) {
      this.claimsSubscription.unsubscribe();
    }
  }

  fnGetActiveMileageMultipleDateDetails() {
    return this.model.mileageMultipleDateDetails.filter(item => !item.isDeleted);
  }
  fnSetGoogleCallsMsg() {
    if (this.model.baseCategoryId == BaseCategoryTypeEnum.MileageGoogle) {
      if (this.model.fromLocation) {
        this.isFromGoogleNotesDisplay = false;
        this.isFromDelIconDisplay = true;
      }
      if (this.model.toLocation) {
        this.isToGoogleNotesDisplay = false;
        this.isToDelIconDisplay = true;
      }
    }

  }
  fnIsMileageGoogleExpense() {
    return this.model.baseCategoryId == BaseCategoryTypeEnum.MileageGoogle;
  }
  fnIsManualMileageExpense() {
    return this.model.baseCategoryId == BaseCategoryTypeEnum.Mileage;
  }

  fnGetActiveMileageTab() {
    this.showMileageSection = false;
    let type = 'nav-roundTrip';
    if (this.model.mileage && this.model.mileage.journeyType == JourneyTypeEnum.Multiple) {
      type = 'nav-multipleLeg'
    }
    this.activeTab = type;
    this.showMileageSection = true;
    return type;
  }

  fnOpenSelectDetourPanel(mileageDateDetails: MileageMultipleDateDetails) {

    if (this.model.isDetourEnabled) {

      this.selectedMileageDateUniqueId = mileageDateDetails.mileageDateUniqueId;
      this.selectDetourInputModel = new SelectDetourInputModel();
      this.selectDetourInputModel.detour = mileageDateDetails.detour;
      this.selectDetourInputModel.detourJustificationNotes = mileageDateDetails.detourJustificationNotes;

      this.showSelectDetourPanel = true;

    } else {
      this.fnShowDebtourNotActivatedAlertPanel();
    }
  }

  fnCloseSelectDetourPanel(isClose: boolean) {
    if (isClose) {
      this.showSelectDetourPanel = false;
    }
  }

  fnSelectDetourPanel(data: SelectDetourOutputModel) {
    if (data) {
      let selectedMileageDateDetails = this.model.mileageMultipleDateDetails.find(item => item.mileageDateUniqueId == this.selectedMileageDateUniqueId);
      if (selectedMileageDateDetails) {
        selectedMileageDateDetails.detour = data.detour;
        selectedMileageDateDetails.detourJustificationNotes = data.detourJustificationNotes;
      }
      this.showSelectDetourPanel = false;
      this.props.click(this.field, {
        key: 'calculateMileage',
        data: data,
        type:'detour'
      });
    }
  }

  fnShowDebtourNotActivatedAlertPanel() {
    this.debtourNotActivatedAlertModel = new AlertPanelModel(
      true,
      this.translate.instant("label_alert"),
      [],
      "",
      this.translate.instant("text_ok"),
      true,
      this.translate.instant("create_expense_detour_not_subscribed_alert")
    );

    this.showDebtourNotActivatedAlertPanel = true;
  }

  fnCloseDebtourNotActivatedAlertPanel(event) {
    this.showDebtourNotActivatedAlertPanel = false;
  }

  //Mileage Tag
  fnOpenCreateMileageTagPanel(mileageDateDetails: MileageMultipleDateDetails) {
    this.selectedMileageDateUniqueId = mileageDateDetails.mileageDateUniqueId;
    this.createMileageTagInputModel = new SelectTagsPopupModel();
    this.createMileageTagInputModel.tagList = mileageDateDetails.tags;
    this.createMileageTagInputModel.claimantId = this.model.userId;
    this.createMileageTagInputModel.claimantName = this.model.userName;
    this.showCreateMileageTagPanel = true;
  }

  fnCloseCreateMileageTagPanel(isClose: boolean) {
    if (isClose) {
      this.showCreateMileageTagPanel = false;
    }
  }

  fnSelectCreateMileageTag(data: SelectTagsPopupModel) {
    if (data) {
      let selectedMileageDateDetails = this.model.mileageMultipleDateDetails.find(item => item.mileageDateUniqueId == this.selectedMileageDateUniqueId);
      if (selectedMileageDateDetails) {
        selectedMileageDateDetails.tags = data.tagList;
      }
    }
    this.showCreateMileageTagPanel = false;
  }

  fnViewMileageMapAndCalculations() {
    if (this.model.mileageMultipleDateDetails && this.model.mileageMultipleDateDetails.length > 0) {
      this.props.click(this.field, {
        key: 'viewMileageMap',
        data: this.model.mileageMultipleDateDetails[0]
      });
    }
  }
  fnOpenAddPassengerLoadPanel() {
    this.props.click(this.field, {
      key: 'addPassengerLoad'
    });
  }

  fnSetAllowanceText() {
    if (this.model.mileage.selectedMileageAllowance && this.model.mileage.selectedMileageAllowance.allowanceId) {
      return this.claimantOnWebService.fnSetAllowanceText(this.model.mileage.selectedMileageAllowance, this.model.mileage.selectedMileageAllowance.allowanceId)
    }
    else {
      return null;
    }

  }

  fnOnRoundTripClick() {
    if (this.model.mileage.journeyType == JourneyTypeEnum.Multiple && this.model.mileage.sourceDestinationInformation && this.model.mileage.sourceDestinationInformation.length > 0) {
      this.fnShowSwitchMultipleLegAlertPanel();
    }
    else {
      this.activeTab = 'nav-roundTrip';
      this.model.mileage.journeyType = JourneyTypeEnum.Single;
      this.props.click(this.field, {
        key: 'switchRoundTrip'
      });

    }
  }

  fnOnMultipleLegsClick() {
    this.activeTab = 'nav-multipleLeg';
    this.model.mileage.journeyType = JourneyTypeEnum.Multiple;
    if (this.model.mileage.sourceDestinationInformation && this.model.mileage.sourceDestinationInformation.length == 0) {
      this.props.click(this.field, {
        key: 'switchMultipleLeg'
      });
    }
  }

  fnViewMultipleLegPanel() {
    this.props.click(this.field, {
      key: 'viewMultipleLegs'
    });
  }
  fncheckMultipleVehicle() {
    let todaysDate = this.getUTCTimeStamp(new Date());
    let expenseDateUnix = this.getUTCTimeStamp(new Date(this.model.expenseDate));
    if (this.model.isMultipleVehiclePurchase && this.model.mileage.vehicleId) {
      return false;
    }
    else if (!this.model.isMultipleVehiclePurchase) { return false; }
    else if (expenseDateUnix < todaysDate) { return false; }
    else if (expenseDateUnix == todaysDate && !this.model.mileage.vehicleId) { return true; }
    else { return false; }
  }
  getUTCTimeStamp(date) {
    let now_utc = Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      0,
      0,
      0
    );

    return now_utc;
  }
  fnOpenSelectRecentJourneyPanel() {
    this.selectRecentJourneyInputModel = new SelectRecentJourneyInputModel();
    this.selectRecentJourneyInputModel.baseCategoryId = this.model.baseCategoryId;
    this.selectRecentJourneyInputModel.claimantId = this.model.userId;
    this.selectRecentJourneyInputModel.claimantName = this.model.userName;

    this.showSelectRecentJourneyPanel = true;
  }

  fnCloseSelectRecentJourneyPanel() {
    this.showSelectRecentJourneyPanel = false;
  }

  fnSelectRecentJourney(data: SelectRecentJourneyOutputModel) {
    if (data) {
      this.props.click(this.field, {
        key: 'recentJourney',
        data: data
      });
      this.fnIsDelIconDisplay('all')
    }
    this.fnCloseSelectRecentJourneyPanel();
  }

  onReturnJourneyInput() {
    this.props.click(this.field, {
      key: 'returnJourney'
    });
    if (this.model.mileageMultipleDateDetails && this.model.mileageMultipleDateDetails.length > 0) {
      if (this.model.mileageMultipleDateDetails[0].totalTriangulatedDistanceText) {

        this.model.mileageMultipleDateDetails[0].totalTriangulatedDistanceText = '';
      }
    }
  }

  onCalculateMileageClick() {
    this.props.click(this.field, {
      key: 'calculateMileage',
      templateOptions: {
        change: (field, $event) => {
          this.cdr.detectChanges();
        }
    },
    });
  }

  onTotalDistanceOfMultipleInput() {
    this.props.change(this.field, {
      key: 'totalDistanceOfMultiple'
    });
  }

  // Google search

  onTextInput(key: any) {
    this.model.mileage[key.toString()].location = this.model[key.toString()];
    this.props.change(this.field, { key: key.toString() });

  }
  isCharLimitFrom: boolean = false;
  isCharLimitTo: boolean = false;
  fnKeypress(key: any, value) {

    if (key == 'fromLocation') {
      this.isCharLimitFrom = false;
      if (this.model.baseCategoryId == BaseCategoryTypeEnum.MileageGoogle && this.model.mileage.googleCallsInfo
        && Number(this.model.mileage.googleCallsInfo.limitChar) > 0
        && value.length >= Number(this.model.mileage.googleCallsInfo.limitChar)) {
        this.isCharLimitFrom = true;
        return false;
      }
    }
    else if (key == 'toLocation') {

      this.isCharLimitTo = false;
      if (this.model.baseCategoryId == BaseCategoryTypeEnum.MileageGoogle && this.model.mileage.googleCallsInfo
        && Number(this.model.mileage.googleCallsInfo.limitChar) > 0
        && value.length >= Number(this.model.mileage.googleCallsInfo.limitChar)) {
        this.isCharLimitTo = true;
        return false;
      }
    }

    return true;
  }
  fnSearchAddress(key: any, value) {
    if (key == 'fromLocation') {
      if (value.length == 0) {
        this.isFromDelIconDisplay = false;
      }
      this.isFromHomeOfcDropdownVisible = false;
      if (this.model.baseCategoryId == BaseCategoryTypeEnum.MileageGoogle && this.model.mileage.googleCallsInfo
        && Number(this.model.mileage.googleCallsInfo.limitChar) > 0
        && value.length <= Number(this.model.mileage.googleCallsInfo.limitChar)) {
        this.isCharLimitFrom = false;
      }
    }
    else if (key == 'toLocation') {
      if (value.length == 0) {
        this.isToDelIconDisplay = false;
      }
      this.isToHomeOfcDropdownVisible = false;
      if (this.model.baseCategoryId == BaseCategoryTypeEnum.MileageGoogle && this.model.mileage.googleCallsInfo
        && Number(this.model.mileage.googleCallsInfo.limitChar) > 0
        && value.length <= Number(this.model.mileage.googleCallsInfo.limitChar)) {
        this.isCharLimitTo = false;
      }
    }
    if (!this.isCharLimitFrom || !this.isCharLimitTo) {
      if (this.fnIsMileageGoogleExpense()) {
        this.model.mileage[key.toString()].placeId = null;
        this.model.mileage[key.toString()].location = this.model[key.toString()];

        if (!this.queryWait) {
          this.queryWait = true;
          setTimeout(() => {
            this.fnGetFromAutocompletePredictions(key);
            this.queryWait = false;
          }, 200);
        }
       
      }
    }
  }

  private fnGetFromAutocompletePredictions(key: any) {

    if (this.model.mileage[key.toString()].location.length < 6) {
      this.googleDynamicAddressList = null;
      if (this.model.mileage[key.toString()].location.length > 0) {

        if (key == 'fromLocation') {
          this.isFromGoogleAddressNotFound = true;
          this.isFromHomeOfcDropdownVisible = false;
          this.cdr.detectChanges();
        }
        else if (key == 'toLocation') {
          this.isToGoogleAddressNotFound = true;
          this.isToHomeOfcDropdownVisible = false;
        }

      }
      else {
        if (this.model.mileage.triangulationRule) {

          if (key == 'fromLocation') {
            this.isFromHomeOfcDropdownVisible = true;
            this.cdr.detectChanges();
          }
          else if (key == 'toLocation') {
            this.isToHomeOfcDropdownVisible = true;
          }
        }

        if (key == 'fromLocation') {
          this.isFromGoogleAddressNotFound = false;
          this.isFromGoogleDropdownVisible = false;
        }
        else if (key == 'toLocation') {
          this.isToGoogleAddressNotFound = false;
          this.isToGoogleDropdownVisible = false;
        }
      }
    }
    if (this.model.mileage[key.toString()].location.length != 6 && this.model.mileage[key.toString()].location.length < 10) {
      return;
    }
    this.fnGetPlacePredictions(this.model.mileage[key.toString()].location)
      .then(data => {
        this.googleDynamicAddressList = data;

        if (key == 'fromLocation') {
          this.isFromGoogleDropdownVisible = this.googleDynamicAddressList != null ? true : false;
          this.isFromGoogleAddressNotFound = this.googleDynamicAddressList == null ? true : false;
          this.isFromHomeOfcDropdownVisible = false;
        }
        else if (key == 'toLocation') {
          this.isToGoogleDropdownVisible = this.googleDynamicAddressList != null ? true : false;
          this.isToGoogleAddressNotFound = this.googleDynamicAddressList == null ? true : false;
          this.isToHomeOfcDropdownVisible = false;
        }

        this.countOfGoogleCalls++;
        this.cdr.detectChanges();
      })
      .catch(err => {

        if (key == 'fromLocation') {
          this.isFromGoogleDropdownVisible = false;
          this.isFromGoogleAddressNotFound = true;
          this.isFromHomeOfcDropdownVisible = false;
        }
        else if (key == 'toLocation') {
          this.isToGoogleDropdownVisible = false;
          this.isToGoogleAddressNotFound = true;
          this.isToHomeOfcDropdownVisible = false;
        }
      });
  }

  private fnGetPlacePredictions(query: string): Promise<any> {
    let autocompleteSrv = new google.maps.places.AutocompleteService();
    return new Promise((resolve, reject) => {
      autocompleteSrv.getPlacePredictions({
        types: ['geocode'],
        input: query
      }, function (predictions, status) {
        if (status == google.maps.places.PlacesServiceStatus.OK) {
          resolve(predictions);
        } else {
          reject(status);
        }
      });
    });
  }
  fnOnInputClick(key: any) {
    if (this.model.mileage.triangulationRule) {
      if ((!this.googleDynamicAddressList || this.googleDynamicAddressList == null
        || this.googleDynamicAddressList.length == 0) && (!this.model[key.toString()] || this.model[key.toString()] == '')) {

        if (key == 'fromLocation') {
          this.isFromGoogleDropdownVisible = false;
          this.isFromGoogleAddressNotFound = false;
          this.isFromHomeOfcDropdownVisible = true;
          this.cdr.detectChanges();
        }
        else if (key == 'toLocation') {
          this.isToGoogleDropdownVisible = false;
          this.isToGoogleAddressNotFound = false;
          this.isToHomeOfcDropdownVisible = true;
        }

      }
    }
  }
  fnFromKeyDown() {
    if (this.isFromDelIconDisplay) {
      return false;
    }
    else {
      return true;
    }
  }
  fnToKeyDown() {
    if (this.isToDelIconDisplay) {
      return false;
    }
    else {
      return true;
    }
  }
  fnCloseGoogleDropdown(key: any) {
    if (this.fnIsMileageGoogleExpense()) {
      setTimeout(() => {

        if (key == 'fromLocation') {
          this.isFromGoogleDropdownVisible = false;
          this.isFromGoogleAddressNotFound = false;
          this.isFromHomeOfcDropdownVisible = false;
        }
        else if (key == 'toLocation') {
          this.isToGoogleDropdownVisible = false;
          this.isToGoogleAddressNotFound = false;
          this.isToHomeOfcDropdownVisible = false;
        }

      }, 300);

      if (!(this.isToDelIconDisplay || this.isFromDelIconDisplay) && this.countOfGoogleCalls > 0) {
        this.fnUpdateOrganisationGooglePackageCalls();
      }
    }
  }

  fnUpdateOrganisationGooglePackageCalls() {
    let request = new ApiRequest<IOrganisationGooglePackageUpdateCallsRequest>("UpdateOrganisationGooglePackageCalls", environment.applicationVersion, environment.requestId);
    let requestInfo = new OrganisationGooglePackageUpdateCallsRequest();
    requestInfo.OrganisationId = this.claims.OrgId;
    requestInfo.CallsUsed = this.countOfGoogleCalls;
    requestInfo.UpdatedBy = this.claims.UserId;
    request.requestInfo = requestInfo;
    this.googleMappingService.UpdateOrganisationGooglePackageCalls(request).subscribe((res: IApiresponse<any>) => {
      this.countOfGoogleCalls = 0;
    });
  }
  isFromGoogleNotesDisplay: boolean = true;
  isToGoogleNotesDisplay: boolean = true;
  fnSetAddress(place: object, key: any) {
    this.model[key.toString()] = place['description'];
    this.model.mileage[key.toString()].location = place['description'];
    this.model.mileage[key.toString()].placeId = place['place_id'];
    this.googleDynamicAddressList = null;

    if (key == 'fromLocation') {
      this.isFromGoogleDropdownVisible = false;
      this.isFromGoogleAddressNotFound = false;
      this.isFromHomeOfcDropdownVisible = false;
      this.isFromGoogleNotesDisplay = false;
      this.fnIsDelIconDisplay(key);
    }
    else if (key == 'toLocation') {
      this.isToGoogleDropdownVisible = false;
      this.isToGoogleAddressNotFound = false;
      this.isToHomeOfcDropdownVisible = false;
      this.isToGoogleNotesDisplay = false;
      this.fnIsDelIconDisplay(key);
    }

    this.props.change(this.field, { key: key.toString() });
    this.cdr.detectChanges();
  }
  fnSetAddressFromTriangulation(location: LocationCommonDetail, key: any) {
    if (location) {
      this.model[key.toString()] = location.address
      this.model.mileage[key.toString()].location = location.address;
      this.model.mileage[key.toString()].placeId = location.placeId;
      this.googleDynamicAddressList = null;
      if (key == 'fromLocation') {
        this.isFromGoogleDropdownVisible = false;
        this.isFromGoogleAddressNotFound = false;
        this.isFromHomeOfcDropdownVisible = false;
        this.isFromGoogleNotesDisplay = false;
        this.fnIsDelIconDisplay(key);
      }
      else if (key == 'toLocation') {
        this.isToGoogleDropdownVisible = false;
        this.isToGoogleAddressNotFound = false;
        this.isToHomeOfcDropdownVisible = false;
        this.isToGoogleNotesDisplay = false;
        this.fnIsDelIconDisplay(key);
      }
      this.props.change(this.field, { key: key.toString() });
      this.cdr.detectChanges();
    }

  }

  fnSearchPlacePrediction(key: any) {
    this.fnGetPlacePredictions(this.model.mileage[key.toString()].location)
      .then(data => {
        this.googleDynamicAddressList = data;
        if (key == 'fromLocation') {
          this.isFromGoogleDropdownVisible = this.googleDynamicAddressList != null ? true : false;
          this.isFromGoogleAddressNotFound = this.googleDynamicAddressList == null ? true : false;
          this.isFromHomeOfcDropdownVisible = false;
        }
        else if (key == 'toLocation') {
          this.isToGoogleDropdownVisible = this.googleDynamicAddressList != null ? true : false;
          this.isToGoogleAddressNotFound = this.googleDynamicAddressList == null ? true : false;
          this.isToHomeOfcDropdownVisible = false;
        }
        this.countOfGoogleCalls++;
      })
      .catch(_err => {
        if (key == 'fromLocation') {
          this.isFromGoogleDropdownVisible = false;
          this.isFromGoogleAddressNotFound = true;
          this.isFromHomeOfcDropdownVisible = false;
        }
        else if (key == 'toLocation') {
          this.isToGoogleDropdownVisible = false;
          this.isToGoogleAddressNotFound = true;
          this.isToHomeOfcDropdownVisible = false;
        }
      });
  }
  fnDeleteText(key: any) {
    if (key == 'fromLocation') {
      this.model.mileage[key.toString()].placeId = null;
      this.isFromGoogleNotesDisplay = true;

      this.model[key.toString()] = null;
      this.model.mileage[key.toString()].location = null;
      this.props.change(this.field, { key: key.toString() });
      this.isFromDelIconDisplay = false;
    }
    else if (key == 'toLocation') {
      this.model.mileage[key.toString()].placeId = null;
      this.model[key.toString()] = null;
      this.isToGoogleNotesDisplay = true;
      this.model.mileage[key.toString()].location = null;
      this.props.change(this.field, { key: key.toString() });
      this.isToDelIconDisplay = false;

    }
    if (this.model.mileageMultipleDateDetails && this.model.mileageMultipleDateDetails.length > 0) {
      if (this.model.mileageMultipleDateDetails[0].totalTriangulatedDistanceText) {

        this.model.mileageMultipleDateDetails[0].totalTriangulatedDistanceText = '';
      }
    }
  }
  isToDelIconDisplay: boolean;
  isFromDelIconDisplay: boolean;
  fnIsDelIconDisplay(key: any) {
    if (this.model.baseCategoryId == BaseCategoryTypeEnum.MileageGoogle) {
      if (key == 'fromLocation') {
        this.isFromDelIconDisplay = true;
      }
      else if (key == 'toLocation') {
        this.isToDelIconDisplay = true;
      }
      else if (key == 'all') {
        this.isFromDelIconDisplay = true;
        this.isToDelIconDisplay = true;
        this.isToGoogleNotesDisplay = false;
        this.isFromGoogleNotesDisplay = false;
      }
    }

  }

  fnShowSwitchMultipleLegAlertPanel() {
    this.switchMultipleLegAlertModel = new AlertPanelModel(
      true,
      this.translate.instant("label_alert"),
      [
        this.translate.instant("switch_multiple_leg_confirmation_note1"),
        this.translate.instant("switch_multiple_leg_confirmation_note2")
      ],
      this.translate.instant("label_cancel"),
      this.translate.instant("label_confirm"),
      true,
      this.translate.instant("switch_multiple_leg_confirmation_note2")
    );

    this.showSwitchMultipleLegAlertPanel = true;
  }

  fnCloseSwitchMultipleLegAlertPanel(returnValue) {
    this.showMileageSection = false;
    if (returnValue) {
      this.activeTab = 'nav-roundTrip';
      this.model.mileage.journeyType = JourneyTypeEnum.Single;
      this.model.mileage.sourceDestinationInformation = [];
      this.props.click(this.field, {
        key: 'switchRoundTrip'
      });
      this.isFromDelIconDisplay = false;
      this.isToDelIconDisplay = false;
    }
    else {
      this.activeTab = 'nav-multipleLeg';
      this.model.mileage.journeyType = JourneyTypeEnum.Multiple;
    }
    setTimeout(() => {
      this.showMileageSection = true;
      this.switchMultipleLegAlertModel = null;
      this.showSwitchMultipleLegAlertPanel = false;
    }, 100);

  }

  fnGetRateText(mileageDateDetails: MileageMultipleDateDetails) {
    let rateText = "";
    if (mileageDateDetails) {
      let rate = mileageDateDetails.mileageRateInfo && mileageDateDetails.mileageRateInfo.length > 0
        ? this.fnGetMileageRateString(mileageDateDetails.mileageRateInfo)
        : 0;
      rateText = `${rate}${this.userHomeCurrencyCode} / ${this.model.mileage.mileageUOM == MileageUOMEnum.Kilometre ? "km" : "m"}`;
    }
    return rateText;
  }
  fnGetMileageRateString(mileageRateInfo) {
    let str = "";
    let length = 1;
    let arraylength = mileageRateInfo.length;
    mileageRateInfo.forEach(itemMileage => {
      if (length == arraylength)
        str = str + itemMileage.mileageRate.toFixed(2) + " ";
      else
        str = str + itemMileage.mileageRate.toFixed(2) + " / " + "";
      ++length;
    });
    return str;
  }

  fnGetAmountText(mileageDateDetails: MileageMultipleDateDetails) {
    let amountText = "";
    if (mileageDateDetails) {
      let amount = mileageDateDetails.amount
        ? mileageDateDetails.amount
        : 0;
      amountText = `${amount.toFixed(2)} ${this.userHomeCurrencyCode}`;
    }
    return amountText;
  }
  isTriangulatedDistanceTextDisplay: boolean;


  sanitizerHtml(model) {
    if (model.mileageMultipleDateDetails && model.mileageMultipleDateDetails.length > 0) {
      if (model.mileageMultipleDateDetails[0].totalTriangulatedDistanceText) {

        let safeText = this.sanitizer.bypassSecurityTrustHtml(model.mileageMultipleDateDetails[0].totalTriangulatedDistanceText);
        return safeText;
      }
      else {
        return '&nbsp';
      }
    }
  }

  fnGetDetour(data) {
    let detour = null;
    if (data) {
      detour = Number(data).toFixed(2);
    }
    return detour;
  }
  isOfficeAddressPanel: boolean;
  ofcKeyType: string;
  closeOfficeAddPanel(location) {
    this.isOfficeAddressPanel = false;
    if (location) {
      this.model[this.ofcKeyType.toString()] = location.officeAddress
      this.model.mileage[this.ofcKeyType.toString()].location = location.officeAddress;
      this.model.mileage[this.ofcKeyType.toString()].placeId = location.placeId;
      this.googleDynamicAddressList = null;
      if (this.ofcKeyType == 'fromLocation') {
        this.isFromGoogleDropdownVisible = false;
        this.isFromGoogleAddressNotFound = false;
        this.isFromHomeOfcDropdownVisible = false;
        this.isFromGoogleNotesDisplay = false;
        this.fnIsDelIconDisplay(this.ofcKeyType);
      }
      else if (this.ofcKeyType == 'toLocation') {
        this.isToGoogleDropdownVisible = false;
        this.isToGoogleAddressNotFound = false;
        this.isToHomeOfcDropdownVisible = false;
        this.isToGoogleNotesDisplay = false;
        this.fnIsDelIconDisplay(this.ofcKeyType);
      }
      this.props.change(this.field, { key: this.ofcKeyType.toString() });
      this.cdr.detectChanges();
    }

  }
  openOfficeAddPanel(key: any) {
    this.ofcKeyType = key;
    this.isOfficeAddressPanel = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.form.valueChanges.subscribe( () => {
      this.cdr.detectChanges()
    });
  }

 
}




