import { Component, OnDestroy, OnInit, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FieldType } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';

@Component({
  selector: 'formly-dynamic-radio-input',
  templateUrl: "./formly-dynamic-radio-input.component.html"
})
export class FormlyDynamicRadioInputComponent extends FieldType implements OnInit, OnDestroy {

  param: any;

  isFormSubmitted: boolean = false;
  formSubmissionSubscription: Subscription;
  fieldKey: string;

  constructor(
    private dynamicFormService: DynamicFormService,
    private sanitizer: DomSanitizer
  ) {
    super();
  }

  ngOnInit() {
    if (this.field.key) {
      this.fieldKey = this.field.key.toString();
    }
    this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
      this.isFormSubmitted = data;
    });
  }

  ngOnDestroy() {
    if (this.formSubmissionSubscription) {
      this.formSubmissionSubscription.unsubscribe();
    }
  }

  onValueChange() {
    if (this.to.change) {
      this.to.change(this.field, this.formControl.value);
    }
  }

  sanitizerHtml(element) {
    var safeText = this.sanitizer.bypassSecurityTrustHtml(element);
    return safeText;
  }
}
