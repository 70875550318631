<div class="container-fluid padding-LR-15"
     id="manageEmployee">
  <section class="pageTitle-block">
    <div class="col-md-12 no-padding-right-left">
      <div class="row">
        <div class="col-md-6 padding-left-0">
          <div class="pageTitle">
            <div class="viewDemo">
              <ul>
                <li class="no-padding-right-left">
                  <h2 class="padding-right">
                    <em>Calendar</em>
                  </h2>
                </li>
                <li class="margin-top-7"></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-6 text-right padding-right-0">
          <div class="actionItems">
            <ul>
              <li class="btn-default padding-right-0">
                <button>
                  <span><i class="fas fa-reply-all"></i>Close</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<div class="clear"></div>

<mat-progress-spinner *ngIf="!events"
                      color="primary"
                      mode="indeterminate"
                      class="mx-auto mt-5">
</mat-progress-spinner>

<div class="calendar-container"
     *ngIf="events">
  <div class="row mt-1">
    <div class="col-md-4 text-left">
    </div>
    <div class="col-md-4 text-center">
      <h4 class="mt-0">{{ viewDate | calendarSchedulerDate:(view +
        'ViewTitle'):locale:weekStartsOn:excludeDays:viewDays:startsWithToday }}</h4>
    </div>
    <div class="col-md-4 text-right">
      <div class="btn-group m-1"
           role="group">
        <button type="button"
                class="btn eod-btn-secondary mr-1"
                mwlCalendarPreviousView
                [view]="view"
                [(viewDate)]="viewDate"
                [daysInWeek]="viewDays"
                [excludeDays]="excludeDays"
                [disabled]="prevBtnDisabled"
                (viewDateChange)="dateOrViewChanged()">
          <span class="valign-center">
            <i title="Precedente"
               class="material-icons md-18">Previous</i>
          </span>
        </button>
        <button type="button"
                class="btn eod-btn-success mr-1"
                mwlCalendarToday
                [(viewDate)]="viewDate"
                (viewDateChange)="dateOrViewChanged()">
          <span class="valign-center">
            <i title="Oggi"
               class="material-icons md-18">Today</i>
          </span>
        </button>
        <button type="button"
                class="btn eod-btn-primary"
                mwlCalendarNextView
                [view]="view"
                [(viewDate)]="viewDate"
                [daysInWeek]="viewDays"
                [excludeDays]="excludeDays"
                [disabled]="nextBtnDisabled"
                (viewDateChange)="dateOrViewChanged()">
          <span class="valign-center">
            <i title="Successivo"
               class="material-icons md-18">Next</i>
          </span>
        </button>
      </div>
    </div>
  </div>
  <div [ngSwitch]="view">
    <calendar-scheduler-view *ngSwitchCase="CalendarView.Week"
                             [viewDate]="viewDate"
                             [events]="events"
                             [locale]="locale"
                             [viewDays]="viewDays"
                             [weekStartsOn]="weekStartsOn"
                             [excludeDays]="excludeDays"
                             [startsWithToday]="startsWithToday"
                             [hourSegments]="hourSegments"
                             [dayStartHour]="dayStartHour"
                             [dayEndHour]="dayEndHour"
                             [dayModifier]="dayModifier"
                             [hourModifier]="hourModifier"
                             [segmentModifier]="segmentModifier"
                             [eventModifier]="eventModifier"
                             [showEventActions]="false"
                             [showSegmentHour]="false"
                             [zoomEventOnHover]="true"
                             (dayHeaderClicked)="dayHeaderClicked($event.day)"
                             (hourClicked)="hourClicked($event.hour)"
                             (segmentClicked)="segmentClicked('Clicked', $event.segment)"
                             (eventClicked)="eventClicked('Clicked', $event.event)"
                             (eventTimesChanged)="eventTimesChanged($event)"
                             [refresh]="refresh">
    </calendar-scheduler-view>
  </div>
</div>