import { Component, ViewEncapsulation } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
    selector: 'privilege-count-cell',
    templateUrl: './privilege-count-renderer.component.html',
    encapsulation: ViewEncapsulation.None
})

export class PrivilegeCountRendererComponent implements ICellRendererAngularComp {

    text: string = '';
    class: string = '';
    toolTipText: string = '';
    constructor() {
    }

    initilize(params: ICellRendererParams) {
        let value = params.getValue();
        if (value && params.data) {
          this.text = value;
          switch(params.data.fmRoleType) {
            case 1:
              this.class = 'text-left font-weight-bold color-green';
              break;
            case 3:
              this.class = 'text-left font-weight-bold color-red';
              break;
            default:
              this.class = 'text-left font-weight-bold ';
              break;
          }
          if (params.data.isTooltipTextDisplay) {
            this.toolTipText = params.data.tooltipText;
          }
        }
    }

    agInit(params: any): void {
        this.initilize(params);
    }

    refresh(params: any): boolean {
        this.initilize(params);
        return true;
    }
}
