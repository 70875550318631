export class Constants {
  static readonly MONTH_FMT = 'MMMM';
  static readonly DATE_FMT = 'dd-MMM-yyyy';
  static readonly TIME_FMT = `hh:mm a`;
  static readonly TIME_FMT_HHmm = `HH:mm`;
  static readonly EODDATE_FMT = 'dd-MMM-yyyy HH:mm:ss';

  static readonly ADDITIONAL_FIELD_DYNAMIC_KEY = 'additionalField-';
  static readonly ADDITIONAL_FIELD_DYNAMIC_ID_KEY = '-Id';
  static readonly CUSTOM_FIELD_DYNAMIC_KEY = 'customField';

}
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])((?=.*[0-9])|(?=.*[$@#$!%*?&])[A-Za-z\d$@#$!%*?&]).{8,}$/;
export const EMAIL_REGEX = /^([\w-'\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,50}|[0-9]{1,50})(\]?)$/;
