import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-text-renderer',
  templateUrl: './text-renderer.component.html'
})
export class TextRendererComponent implements ICellRendererAngularComp {

  text: string = '';
  isMandatory: boolean = false;
  params: ICellRendererParams;
  constructor() {
  }

  initilize(params: ICellRendererParams) {             
    if (params.data) {
        this.isMandatory = params.data.isMandatory;
        this.text = params.data.setupName;
    }      
  }

  agInit(params: any): void {
      this.initilize(params);
  }

  refresh(params: any): boolean {
      this.initilize(params);
      return true;
  }

}
