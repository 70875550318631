<div class="modal fade function-modal2" id="function-modal2" tabindex="-1" data-bs-backdrop="static" role="dialog" aria-labelledby="myLargeModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg box-shadow">
    <div class="modal-content p-0" style="border-radius: 5px;">
      <div class="modal-header align-items-center eod-bg-secondary" style="padding-top: 1.5rem !important; padding-bottom: 1.5rem !important;">
        <h5 class="mb-0 f-22 fw-700" id="staticBackdropLabel">{{ "pricing_summary_header" | translate }}</h5>
        <button id="close_summary" type="button" class="eod-text-white bg-transparent f-20" data-bs-dismiss="modal" aria-label="Close"><i class="fal fa-times"></i></button>
      </div>
      <div class="modal-body">
        <section>

          <table class="table table-row" *ngIf="getSelectedFunctionCategory();" aria-describedby="summary">
  
            <thead>
              <tr>
                <th class="d-flex justify-content-between" style="padding-left:0 !important;">
                  <h6 class="fw-700 mb-0">{{ "pricing_function_name" | translate }}</h6>
                  <h6 class="fw-700 mb-0" style="margin-right: 6.67rem;">{{ "price" | translate }}</h6>
              </tr>
            </thead>
            <tbody>
              <tr class="no-hover">
                <td style="padding-left: 0px !important;">
                  <div>
                    <h6 class="text-logo-blue fw-600 d-flex justify-content-between mb-0">
                      <span>Essential Pack</span>
                      <span class="eod-text-secondary" style="margin-right: 6.67rem;">
                        {{ currencySymbol }}{{ (accessCharge + essentialPack) | number: "1.2-2" | noComma }}
                      </span>
                    </h6>
                  </div>
                  <div *ngFor="let cat of selectedFunctionCategory">
                    <div class="margin-top-10 float-left w-100pc" *ngIf="cat.selected">
  
                      <h6 *ngIf="cat.selected" class="text-logo-blue fw-600" style="margin-bottom:0rem !important ;">
                        {{cat.functionCategoryName}}</h6>
                      <div *ngIf="cat.selected">
                        <div  *ngFor="let paidlist of cat.functionPackage" class="d-flex justify-content-between mb-0 my-1 fw-600 f-14">
                          <p *ngIf="paidlist.selected" [hidden]="!paidlist.selected">{{paidlist.websiteFunctionPackageName}}</p>
                          <div class="d-flex align-items-center" style="margin-right: 5.4rem;">
                            <p [hidden]="!paidlist.selected">
                              {{currencySymbol}}{{paidlist.functionAmount | number: "1.2-2" | noComma}}
                            </p>
                            <button type="button" class="bg-transparent f-20" [hidden]="!paidlist.selected"
                              (click)="removeFunPackage(paidlist.functionPackageId, paidlist.functionCategoryId)">
                              <i class="fal fa-times pl-2"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="no-hover">
                <td  class="text-right  font-weight-bold padding-top-0"
                  style="padding-right: 3px !important; font-size: 14px !important;">
                  <span class="text-logo-blue">{{ "pricing_summary_total" | translate }} </span><span
                    class="padding-left-10">{{currencySymbol}}{{essentialPack + accessCharge + this.fnGetSelectedFunctionCost() | number: "1.2-2" |
                    noComma}}&nbsp;/&nbsp;{{ "pricing_label_user" |
                    translate }}&nbsp;/&nbsp;{{ "pricing_per_month" |
                    translate }}</span>
                </td>
              </tr>
  
  
            </tbody>
  
          </table>
  
        </section>



        <section>
          <form [formGroup]="formctrl">
            <div class="d-flex justify-content-between summary-form">
  
              <div class="input-group ">
                <input type="text" class="form-control grey-bg-2 w-90pc padding-10 has-value" formControlName="firstName"
                  [ngClass]="{ 'has-value': f.firstName.value }">
                <label for="user-fName">{{ "label_first_name" | translate}}<span class="color-red f-16">*</span></label>
              </div>
              <div class="input-group ">
                <input type="text" class="form-control grey-bg-2 w-90pc padding-10" formControlName="lastName"
                  [ngClass]="{ 'has-value': f.lastName.value }">
                <label for="user-fName">{{ 'label_last_name' | translate}}<span class="color-red f-16"></span></label>
              </div>
            </div>
            <div class="alert-danger d-flex justify-content-between summary-form"
              *ngIf="(!f.firstName.value || !f.firstName.value.trim()) && isFormSubmitted">
              <span class="w-100pc left-10px position-relative">
                <strong>{{ "label_Required" | translate }}</strong>
              </span>
            </div>
            <div class="d-flex justify-content-between mt-1 summary-form">
              <div class="input-group ">
                <input type="text" formControlName="email" class="form-control grey-bg-2 w-90pc padding-10"
                  [ngClass]="{ 'has-value': f.email.value }">
                <label for="user-fName">{{ 'label_email' | translate }}<span class="color-red f-16">*</span></label>
              </div>
              <div class="input-group ">
                <input type="text" formControlName="business" class="form-control grey-bg-2 w-90pc padding-10"
                  [ngClass]="{ 'has-value': f.business.value }">
                <label for="user-fName">{{ 'pricing_label_business' | translate }}<span
                    class="color-red f-16"></span></label>
              </div>
            </div>
            <div class="d-flex justify-content-between"
              *ngIf="f.email.invalid && f.email.value && (f.email.dirty || f.email.touched)">
              <span class="alert-danger left-10px position-relative">
                <strong>{{ "enter_valid_email" | translate }}</strong>
              </span>
            </div>
            <div class="alert-danger d-flex justify-content-between"
              *ngIf="(!f.email.value || !f.email.value.trim()) && isFormSubmitted">
              <span class="left-10px position-relative">
                <strong>{{ "label_Required" | translate }}</strong>
              </span>
            </div>
            <div class="d-flex justify-content-between mt-1 summary-form">
              <div class="input-group ">
                <input type="text" [ngClass]="{ 'has-value': f.noOfEmployee.value }"
                  class="form-control grey-bg-2 w-90pc padding-10" formControlName="noOfEmployee">
                <label for="user-fName">{{ 'pricing_label_emplyee' | translate }}</label>
              </div>
              <div class="input-group ">
                <input type="text" class="form-control grey-bg-2 w-90pc padding-10" formControlName="hearAboutUs"
                  [ngClass]="{ 'has-value': f.hearAboutUs.value }">
                <label for="user-fName">{{ 'pricing_label_hearaboutus' | translate }}</label>
              </div>
            </div>
            <div class="alert-danger d-flex justify-content-between"
              *ngIf="(formctrl.controls['noOfEmployee'].invalid && isFormSubmitted)">
  
              <span class="left-10px position-relative">
                <strong>{{ "pricing_error_message_invalid_NUmber" | translate }}</strong>
              </span>
            </div>
            <div class="d-flex justify-content-center my-3 mx-auto w-50pc">
              <button type="button" class="btn eod-btn-warning height-38px fw-700" (click)="raiseQuote(true)">
                {{ "pricing_discuss_quote" | translate }}
              </button>
            </div>
          </form>
        </section>
      </div>
    </div>
  </div>
  </div>