<div class="input-group margin-top-20">
    <input id="{{ 'int' + fieldKey }}" dynamicDigitBeforeAndDynamicDigitAfterDecimalNumber  [beforeDecimal]="6" [afterDecimal]="2" type="text" class="form-control" (keypress)="numberOnly($event)"
        (paste)="$event.preventDefault()" (input)="onNumberInput()" [formControl]="formControl"
        [formlyAttributes]="field"
        [ngClass]="{'has-value': formControl.value || formControl.value == 0,
          'color-light-grey-disabledType':to.disabled, 'color-red': formControl.value < 0}">
    <label for="IntegerInput" [ngClass]="{'color-light-grey-disabledType': to.disabled }">
      {{field.templateOptions.label}}<span *ngIf="to.required">*</span>
    </label>
    <p class="rightTinyText text-logo-blue"
      *ngIf="model.isEditMode && model.isCreditCardExpense && formControl.value < 0"
      (click)="fnOpenAssociateCreditCardExpensePanel()">
      {{'text_associate' | translate:param}}
    </p>
    <div *ngIf="((formControl.errors && formControl.errors.required) || (formControl.value == 0 && field.templateOptions.allowNotZero)) && isFormSubmitted" class="alert-danger">
        <span *ngIf="!formControl.value || !formControl.value.trim() || formControl.value == 0">
            <b>{{ "label_Required" | translate: param }}</b>
        </span>
    </div>
</div>

<app-alert-panel [data]="selectAssociateCategoryAlert"
  (callbackEvent)="selectAssociateCategoryAlertPanelReceiver($event)">
</app-alert-panel>

<associate-credit-card-expense-panel *ngIf="showAssociateCreditCardExpensePanel"
  [inputData]="associateCreditCardExpenseInputModel"
  (closePanelEvent)="fnCloseAssociateCreditCardExpensePanel($event)"
  (selectItemEvent)="fnSelectAssociateCreditCardExpense($event)">
</associate-credit-card-expense-panel>
