<div *ngFor="let option of to.options" [ngClass]="option.class">
    <span class="position-absolute left-0px top-4px">
        <input type="radio" [name]="to.name" [formControl]="formControl" [formlyAttributes]="field" [value]="option.id"
        class="svg-18"> </span>
    <div class="float-left w-70pc text-left">
        <p class="lH-normal  f-16 d-flex justify-content-between"> 
            <span class="w-50pc">{{ option.value }}</span>   
            <span class="w-50pc padding-left-10 color-light-grey-disabledType f-14">{{ option.description }}</span> </p>
    </div>
</div>
