<div class="overLayDiv active"></div>
<div id="rightSideBar">
    <div class="rightSidePanel active" style="z-index: 99999;">
        <header>
            <div class="row">
                <div class="col-lg-12 no-padding-right-left">
                    <div class="row">
                        <div class="col-lg-8 padding-left-0">
                            <h2>{{'select_passenger_load' | translate:param}}</h2>
                        </div>
                        <div
                            class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 padding-right-0 header-icons text-right">
                            <ul class="d-flex justify-content-end w-100pc">
                                <li class="padding-right-1" (click)="fnClosePanel()">
                                    <Button
                                        class="btn btn-outline-secondary border-color-warning text-mustard height-38px">
                                        {{ 'label_close' | translate: param }}
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div class="clear"></div>
        <div class="row">
            <div class="col-lg-12 margin-top-10 no-padding-right-left" id="rowHover">
                <div class="block float-left w-100pc padding-top-25 padding-left-10">
                    <div class="row" *ngFor="let item of inputData;" (click)="fnSelectPassengerLoad(item)">
                        <div class="col-lg-6 padding-left-0" *ngIf="item.allowanceId<5">
                            <span class="align-middle d-block">
                                <img alt="" src="{{item.allowanceIcon}}" class="svg-36">
                            </span>
                        </div>
                        <div class="col-lg-6 padding-right-0 text-right align-middle fw-700 lH-35"
                            *ngIf="item.allowanceId<5">
                            <span class="padding-right-10" *ngIf="item.selected">
                                <i class="f-20 color-green fa fa-check align-middle"></i>
                            </span>
                            ({{item.allowanceDescription}})
                        </div>
                    </div>
                    <hr class="border-top margin-0 padding-0" *ngIf="fnCheckForItems()"
                        style="margin:10px 0 !important">
                    <div class="row " *ngFor="let item of inputData;" (click)="fnSelectPassengerLoad(item)">
                        <div class="col-lg-6 padding-left-0" *ngIf="item.allowanceId>4">
                            <span class="align-middle d-inline-block position-relative ">
                                <img alt="" src="{{item.allowanceIcon}}" class="svg-36">
                            </span>
                        </div>
                        <div class="col-lg-6 padding-right-0 text-right align-middle fw-700 lH-35"
                            *ngIf="item.allowanceId>4">
                            <span class="padding-right-10" *ngIf="item.selected">
                                <i class="f-20 color-green fa fa-check align-middle"></i>
                            </span>
                            ({{item.allowanceDescription}})
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>