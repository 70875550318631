import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'translated-text-renderer',
    templateUrl: './translated-text-renderer.component.html',
})
export class TranslatedTextTendererComponent implements ICellRendererAngularComp {

    public text: string;

    constructor(
        private translate: TranslateService,
        ) {
    }

    initilize(params: ICellRendererParams) {
        let text = params.getValue();
        if(text){
            this.text = this.translate.instant(text);
        }   
    }

    // called on init
    agInit(params: any): void {
        this.initilize(params);
    }

    // called when the cell is refreshed
    refresh(params: any): boolean {
        this.initilize(params);
        return true;
    }

}
