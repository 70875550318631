<div id="layout-wrapper">
    <!-- Top Bar -->
    <app-topbar (mobileMenuButtonClicked)="onToggleMobileMenu()"
                (settingsButtonClicked)="onSettingsButtonClicked()">
    </app-topbar>
    <!-- Side Bar -->
    <app-sidebar *ngIf="claims.orgRoleId == 3"></app-sidebar>
    <div class="main-content"
         id="mainContent">
        <div class="page-content"
             [style.padding-top]="routeIsActive('/claimant') 
             || routeIsActive('/approver')
             || routeIsActive('/financeapprover') 
             || routeIsActive('/pay') 
             || routeIsActive('/export')
             || routeIsActive('/universalimport/ccuploadfile')
             || routeIsActive('/card')? '110px' : '60px'">
            <div class="container-fluid">
                <!-- content -->
                <router-outlet></router-outlet>
            </div>
        </div>
        <!-- footer -->
        <!-- <app-footer></app-footer> -->
    </div>
</div>
<app-rightsidebar></app-rightsidebar>