import { OnInit, Component, EventEmitter, Input, Output, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { Subscription } from "rxjs";
import { DatePipe } from "@angular/common";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { TranslateService } from "@ngx-translate/core";
import { ApiRequest, IApiresponse } from "src/app/_models";
import { first } from "rxjs/operators";
import { HowDoIVideoLinks } from "src/app/common/commonvariables";
import { BaseComponent } from "src/app/shared/base/base-component";
import { AlertService } from "src/app/alert-panel/alert.service";
import { environment } from "src/environments/environment";
import { ProjectBudgetService } from "src/app/_services/project-budget/project-budget.service";
import {
    IGetCustomerCostCentreProjectsListRequest, GetCustomerCostCentreProjectsListRequest,
    ICostCentreCustomerProjectListResponse, CostCentreCustomerProjectList
} from "src/app/_models/project-budget/project-budget";
import { SelectProjectPopupModel } from "src/app/_models/claimant-on-web/claimant-on-web";
import { ProjectBudgetEntityType } from "src/app/_models/enum/solo-enum";

@Component({
    selector: "select-project-panel",
    templateUrl: "./select-project-panel.component.html"
})
export class SelectProjectPanelComponent extends BaseComponent implements OnInit, OnDestroy {

    @Input()
    inputData: SelectProjectPopupModel;

    @Output() closePanelEvent = new EventEmitter<boolean>();
    @Output() selectItemEvent = new EventEmitter<SelectProjectPopupModel>();

    userActionEventSubscription: Subscription;
    claimsSubscription: Subscription;
    localOrganisationId: any;
    param: any;
    howDoIVideoLinks = HowDoIVideoLinks;
    searchText: string = '';
    hasFormChanges: boolean = false;

    customerCostCentreProjectList = <CostCentreCustomerProjectList[]>[];
    selectedEntityId: number = 0;
    selectedProjectDetailsId: number = 0;

    isCostCentrePackagePurchasedOrTrial: boolean = false;
    isCustomerPackagePurchasedOrTrial: boolean = false;

    get projectBudgetEntityType() {
        return ProjectBudgetEntityType;
    }

    constructor(
        public datepipe: DatePipe,
        private claimsService: ClaimsService,
        private translate: TranslateService,
        private alertService: AlertService,
        private projectBudgetService: ProjectBudgetService,
        private cdfr: ChangeDetectorRef
    ) {
      super();
      this.claimsSubscription = this.claimsService.currentClaims.subscribe((claims) => {
          this.localOrganisationId = claims.OrgId;
      });
      this.userActionEventSubscription = this.alertService.getUserActionEvent().subscribe(action => {
        if (action) {
            this.closePanelEvent.emit(true);
        }
      });
    }
    ngOnInit(): void {
        this.fnSetProjectInputValues();
        this.fnGetCustomerCostCentreProjectList();
    }

    ngOnDestroy(): void {
        this.claimsSubscription.unsubscribe();
        this.userActionEventSubscription.unsubscribe();
    }

    canDeactivate(): boolean {
        return !this.hasFormChanges;
    }

    fnClosePanel() {
        if (this.canDeactivate()) {
            this.closePanelEvent.emit(true);
        }
        else {
            this.alertService.onFormClosed();
        }
    }

    fnGetCustomerCostCentreProjectList() {
        let isCostCentrePackagePurchasedOrTrial = this.isCostCentrePackagePurchasedOrTrial ? this.isCostCentrePackagePurchasedOrTrial : false;
        let isCustomerPackagePurchasedOrTrial = this.isCustomerPackagePurchasedOrTrial ? this.isCustomerPackagePurchasedOrTrial : false;

        let request = new ApiRequest<IGetCustomerCostCentreProjectsListRequest>("GetPreApprovalTagList", environment.applicationVersion, environment.requestId);
        let requestInfo = new GetCustomerCostCentreProjectsListRequest(this.localOrganisationId, this.inputData.claimantId, isCostCentrePackagePurchasedOrTrial, isCustomerPackagePurchasedOrTrial);
        request.requestInfo = requestInfo;
        this.projectBudgetService
            .GetCustomerCostCentreProjectList(request)
            .pipe(first())
            .subscribe((response: IApiresponse<ICostCentreCustomerProjectListResponse>) => {
                if (response.responseInfo != null) {
                    this.customerCostCentreProjectList = response.responseInfo.customerCostCentreProjectList;
                    this.fnGetSelectedProjectOnTop();
                    this.cdfr.detectChanges();
                }
            });
    }

    fnSelectCustomerCostCentreProject() {
        let selectProjectOutputModel = new SelectProjectPopupModel();
        if (this.selectedEntityId > 0) {
            let entityDetails = this.customerCostCentreProjectList.find(item => item.entityId == this.selectedEntityId);
            if (entityDetails) {
                selectProjectOutputModel.entityId = entityDetails.entityId;
                selectProjectOutputModel.entityName = entityDetails.entityName +' - '+ entityDetails.entityCode;
                selectProjectOutputModel.entityType = entityDetails.entityType;
                selectProjectOutputModel.projectBudgetDetailsId = entityDetails.projectBudgetDetailsId;
                if (this.selectedProjectDetailsId > 0) {
                    let projectDetails = entityDetails.projectList ? entityDetails.projectList.find(p => p.projectDetailsId == this.selectedProjectDetailsId) : null;
                    if (projectDetails) {
                        selectProjectOutputModel.projectDetailsId = projectDetails.projectDetailsId;
                        selectProjectOutputModel.projectName = projectDetails.projectName;
                    } else {
                        selectProjectOutputModel.projectDetailsId = 0;
                        selectProjectOutputModel.projectName = null;
                    }
                }
            }
        } else {
            selectProjectOutputModel.entityId = 0;
            selectProjectOutputModel.entityName = null;
            selectProjectOutputModel.entityType = 0;
            selectProjectOutputModel.projectBudgetDetailsId = 0;
            selectProjectOutputModel.projectDetailsId = 0;
            selectProjectOutputModel.projectName = null;
        }
        this.selectItemEvent.emit(selectProjectOutputModel);
    }

    fnOnChangeEntity(entityId: number) {
        if (entityId == this.selectedEntityId) {
            this.selectedEntityId = 0;
            this.selectedProjectDetailsId = 0;
        } else {
            this.selectedEntityId = entityId;
            this.selectedProjectDetailsId = 0;
        }
    }

    fnOnChangeProject(event, projectDetailsId: number) {
        if (event.target.checked) {
            this.selectedProjectDetailsId = projectDetailsId;
        } else {
            this.selectedProjectDetailsId = 0;
        }
    }

    fnSetProjectInputValues() {
        if (this.inputData) {
            this.isCostCentrePackagePurchasedOrTrial = this.inputData.isCostCentrePackagePurchasedOrTrial;
            this.isCustomerPackagePurchasedOrTrial = this.inputData.isCustomerPackagePurchasedOrTrial;
            this.selectedEntityId = this.inputData.entityId;
            this.selectedProjectDetailsId = this.inputData.projectDetailsId;
        }
    }

    fnIsSubmitDisabled() {
        let isDisabled = false;
        if (this.selectedEntityId > 0) {
            if (this.customerCostCentreProjectList && this.customerCostCentreProjectList.length > 0) {
                let entityDetails = this.customerCostCentreProjectList.find(item => item.entityId == this.selectedEntityId);
                if (entityDetails && entityDetails.projectList && entityDetails.projectList.length > 0 && this.selectedProjectDetailsId == 0) {
                    isDisabled = true;
                }
            }
        }
        return isDisabled;
    }

    fnGetPanelTitle() {
        let title = "";
        if (this.isCustomerPackagePurchasedOrTrial && this.isCostCentrePackagePurchasedOrTrial) {
            title = this.translate.instant("select_customer_cost_centre_project");
        } else if (this.isCustomerPackagePurchasedOrTrial && !this.isCostCentrePackagePurchasedOrTrial) {
            title = this.translate.instant("select_customer_project");
        } else if (!this.isCustomerPackagePurchasedOrTrial && this.isCostCentrePackagePurchasedOrTrial) {
            title = this.translate.instant("select_cost_centre_project");
        }
        return title;
    }

    fnGetGridTitle() {
        let title = "";
        if (this.isCustomerPackagePurchasedOrTrial && this.isCostCentrePackagePurchasedOrTrial) {
            title = this.translate.instant("select_customers_cost_centres");
        } else if (this.isCustomerPackagePurchasedOrTrial && !this.isCostCentrePackagePurchasedOrTrial) {
            title = this.translate.instant("select_customer");
        } else if (!this.isCustomerPackagePurchasedOrTrial && this.isCostCentrePackagePurchasedOrTrial) {
            title = this.translate.instant("select_cost_centres");
        }
        return title;
    }

    fnGetSelectedProjectOnTop() {
        let entityId = this.inputData && this.inputData.entityId ? this.inputData.entityId : 0;

        if (entityId && this.customerCostCentreProjectList && this.customerCostCentreProjectList.length > 0) {
            const sortedArr = this.customerCostCentreProjectList.reduce((acc, element) => {
                if (element.entityId == entityId) {
                    return [element, ...acc];
                }
                return [...acc, element];
            }, []);
            this.customerCostCentreProjectList = sortedArr;
        }
    }
}
