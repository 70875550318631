<div class="overLayDiv active"></div>
<div id="rightSideBar">
  <div class="rightSidePanel w-98pc active">
    <header>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding-right-left">
          <div class="row">
            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8 padding-left-0">
              <h2 class="padding-left-0">{{headerText}}</h2>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 padding-right-0 header-icons text-right">

              <ul class="d-flex justify-content-end w-100pc f-12">

                <li class="margin-left-15 margin-right-15"><button class="btn btn-primary height-38px"
                    (click)="downloadCSV()">{{"label_export"| translate:param}}</button>
                </li>
                <li><button (click)=" ClosePanel()"
                    class="btn btn-outline-secondary border-color-warning text-mustard height-38px">{{"Close"|
                    translate:param}}</button></li>
              </ul>

            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="clear"></div>
    <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 margin-top-10 no-padding-right-left">

      <div class="block padding-top-10 overflow-auto heightCalc-6 scrollbar-visible">

        <div class="row mt-4">

          <div class="col-lg-3 padding-left-0">
            <div class="input-group categoryTemplate  z-index-101">
              <ng-select class="solo-dropdown claimantDropdown exportDropdown auditTrailDropdown" [items]="filterByList"
                bindLabel="filterByName" bindValue="filterById" placeholder="Filter By:" [selectOnTab]="true"
                [clearable]="false" [(ngModel)]="selectedFilterBy" name="selectedFilterBy"
                notFoundText="{{ 'no_data_available' | translate: param }}" (change)="onFilterByChange($event)">
              </ng-select>
            </div>
          </div>

          <div class="col-lg-3 padding-left-0" *ngIf="selectedFilterBy>0">
            <div class="input-group categoryTemplate  z-index-101">
              <ng-select class="solo-dropdown claimantDropdown multiSelectCategoryDropdown exportDropdown"
                [items]="filterList" [multiple]="true" bindLabel="name" groupBy="groupByField" [selectableGroup]="true"
                [selectableGroupAsModel]="false" [closeOnSelect]="false" bindValue="id" [clearable]="false"
                [placeholder]="selectedFilterByPlaceHolder" (change)="onFilterSelectionChange($event)"
                [(ngModel)]="selectedFilterValues" notFoundText="{{ 'no_data_available' | translate: param }}"
                (open)="onOpen()">
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div *ngIf="items.length != filterList.length">
                    <div class="ng-value" *ngFor="let item of items | slice: 0:1">
                      {{ item.name }}
                    </div>
                  </div>
                  <div *ngIf="items.length == filterList.length" class="ng-value">
                    {{ 'all' | translate}}
                  </div>
                  <div *ngIf="
                                      items.length != filterList.length &&
                                      items.length > 1
                                    " class="ng-value">
                    <span class="ng-value-label">(+{{ items.length - 1 }})</span>
                  </div>
                </ng-template>
                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                  <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" />
                  {{ 'all' | translate}}
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" />
                  {{ item.name }}
                </ng-template>
              </ng-select>
            </div>
          </div>

          <div class="padding-left-0" [ngClass]="selectedFilterBy>0?'col-lg-3':'col-lg-6'">

            <button class="btn btn-primary f-12 fw-600 height-30px"
              [ngClass]="{'pointer-events-none': !enableViewButton}" (click)="filterGridData()">{{"view"|
              translate:param}}</button> <button class="btn btn-outline-primary f-12 fw-600 height-30px ml-2"
              (click)="resetFilters()">{{"reset"| translate:param}}</button>
          </div>


          <div class="col-lg-3 text-right table-total f-12 padding-right-0 padding-top-0">
            <span class="icon-themeColor fw-600">{{"claimant_count"| translate:param}}: {{claimantCount}}</span> <span
              class="text-charcoal-black d-block fw-600">{{"expenses_count"| translate:param}}: {{expenseCount}}</span>
          </div>

        </div>

        <div class="row mt-4">
          <div class="block heightCalc-13 padding-0">
            <app-grid [columns]="columnDefs" [data]="gridData" [gridFilterItems]="gridFilterItems"
              [gridConfigItems]="gridConfigItems">
            </app-grid>
          </div>



        </div>

      </div>
    </div>

  </div>
</div>