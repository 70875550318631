<div class="overLayDiv active"></div>
<div id="rightSideBar">
  <div class="rightSidePanel active z-99999"
       [ngClass]="{ 'w-80pc': this.dependentFunction.length != 0 }">
    <header>
      <div class="row">
        <div class="
            col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12
            no-padding-right-left
          ">
          <div class="row">
            <div class="col-xl-10 padding-left-0">
              <h2 class="padding-left-70 text-truncate">
                <span placement="bottom"
                      [ngbPopover]="popContent"
                      container="body"
                      triggers="mouseenter:mouseleave">
                  <span class="
                      color-orange
                      font-weight-bold
                      position-absolute
                      top--10px
                      left-0px
                    ">
                    <img src="../../assets/svg-icons/paid-function-LR.svg"
                         class="svg-64" />
                  </span>
                  {{ data.title }}
                </span>

                <ng-template #popContent>
                  <div>
                    {{ data.title }}
                  </div>
                </ng-template>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="clear"></div>

    <div class="
        col-md-12 col-sm-12 col-lg-12 col-xl-12
        margin-top-10
        no-padding-right-left
      "
         id="liability-report">
      <div class="
          block
          padding-top-10
          overflow-auto
          heightCalc-10
          scrollbar-visible
        ">
        <h6 class="
            text-uppercase
            f-13
            font-weight-bold
            padding-bottom-10
            border-bottom
          ">
          {{ data.description1 }}
        </h6>
        <div class="row margin-top-15">
          <div class="no-padding-right-left"
               [ngClass]="{
              'border-right col-lg-8': this.dependentFunction.length != 0
            }">
            <div class="row">
              <div class="col-lg-7">
                <img [src]="data.image1"
                     alt="image" />
              </div>
              <div class="col-lg-5 padding-left-0"
                   [ngClass]="{
                  ' padding-right-0': this.dependentFunction.length == 0
                }">
                <div class="row">
                  <div style="white-space: pre-line"
                       class="
                      box-shadow
                      bg-grey-1
                      padding-15
                      f-13
                      text-charcoal-black
                      font-weight-normal
                      w-100pc
                    ">
                  <span  [innerHTML]="
                  sanitizerHtml(data.description2)
                "></span>  

                    <span *ngIf="data.description3"
                          class="
                        d-block
                        color-blue
                        text-uppercase
                        font-weight-bold
                        border-bottom
                        padding-bottom-10
                      ">
                      {{ data.description3 }}
                    </span>
                    <ul class="mb-2"
                        *ngIf="data.descList?.length > 1">
                      <p class="lH-normal">{{data.descListHeader}}</p>
                      <li class="d-flex justify-content-between mt-2"
                          *ngFor="let item of data.descList; index as i">
                        {{i+1}}.
                        <p class="lH-normal padding-left-5">
                          {{item}}
                        </p>
                      </li>
                    </ul>
                    <span *ngIf="data.descListFooter"
                          class="d-block border-bottom padding-TB-10">{{data.descListFooter}}</span>
                    <h6 class="
                        margin-top-15
                        font-weight-bold
                        color-green
                        text-uppercase text-center
                      ">
                      {{
                      "liabilityReport_available_for_trial_now" | translate
                      }}
                    </h6>
                  </div>
                </div>

                <div class="row margin-top-25">
                  <p class="
                      import-summary-info
                      w-100pc
                      margin-all-0
                      height-auto
                      cursor-pointer
                      padding-0
                      border-radius-1
                      position-relative
                    "
                     id="viewReceipt">
                    <img [src]="data.image2"
                         class="border-radius-1" />
                    <span class="enlarge-img">
                      <i (click)="openImagePopupEvent()"
                         class="fas fa-search-plus"
                         style="color: #30a5cf">
                      </i>
                    </span>
                    <span class="text f-13"></span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 padding-right-0"
               *ngIf="this.dependentFunction.length != 0">
            <!-- <h6 class="text-uppercase f-13 font-weight-bold padding-bottom-10 border-bottom">Below
                            function(s) is required for smooth functioning of {{ data.title | uppercase }}</h6> -->
            <h6 class="
                text-uppercase
                f-13
                font-weight-bold
                padding-bottom-10
                border-bottom
              ">
              For the {{ data.title | uppercase }}
              {{ "to_work_effectively" | translate }}
              {{ dependentFunctionList | uppercase }} is required.
            </h6>

            <div *ngFor="let pack of this.dependentFunction"
                 class="overflow-auto scrollbar-visible padding-5">
              <div class="
                  box
                  rounded
                  padding-top-10 padding-left-10
                  position-relative
                ">
                <div class="d-block w-100pc float-left">
                  <div>
                    <ul class="card-details">
                      <li class="float-left w-10pc">
                        <span class="card-icon"><img class="svg-width-height"
                               src="assets/svg-icons/dashboard/{{
                              pack.dependentFunctionIcon
                            }}.svg" /></span>
                      </li>
                      <li class="
                          float-left
                          w-90pc
                          padding-left-10
                          dependentFunction padding-bottom-35
                        ">
                        <div class="position-relative top--5px"
                             style="padding-right: 5px">
                          <p class="card-heading">
                            <strong>{{ pack.functionPackageName }}</strong>
                          </p>
                          <p class="ellipsis-Contant position-relative"></p>
                          <span class="f-13"
                                [innerHTML]="
                              sanitizerHtml(pack.functionPackageDescripition)
                            ">
                          </span>
                          <p></p>
                          <!---->
                        </div>
                        <span *ngIf="pack.isSubscribed"
                              _ngcontent-iwo-c142=""
                              class="free"><span _ngcontent-iwo-c142="">{{
                            "subscribed" | translate
                            }}</span></span>

                        <!-- <app-video-icon [VideoUrl]="howDoIVideoLinks.AttendeeType | safeUrl">
                                                </app-video-icon> -->

                        <p *ngIf="pack.isSubscribed"
                           class="float-right position-relative"
                           style="padding-bottom: 5px; padding-right: 5px">
                         
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="clear"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="
        d-flex
        position-absolute
        bottom-0
        w-100pc
        justify-content-center
        bg-white
        padding-15
        my-custom-tooltip-home
      ">
      <span class="margin-right-15">
        <button class="btn btn-outline-primary bg-white"
                (click)="closeMarketingPopup()">
          {{ "Close" | translate }}
        </button>
      </span>
      <span class="margin-right-15">
        <button class="btn btn-primary w-100pc"
                (click)="routerNavigate()">
          {{ "label_watch_video" | translate }}
        </button>
      </span>
      <span class="margin-right-15"
            placement="top">
        <button *ngIf="!isEssentialPackAlertRequired && !isFunctionAddedToCart"
                class="btn btn-primary w-100pc"
                (click)="fnAddToCart()">
          {{ "add_to_cart" | translate }}
        </button>
        <button *ngIf="isEssentialPackAlertRequired"
        class="btn btn-primary w-100pc"
        (click)="fnEssentialPackAlert()">
  {{ "add_to_cart" | translate }}
</button>

        <button *ngIf="isFunctionAddedToCart"
                class="btn btn-primary w-100pc"
                (click)="fnAddToCart()">
          {{ "go_to_cart" | translate }}
        </button>
      </span>
    </div>
  </div>
</div>

<div *ngIf="showImagePopup"
     class="overLayDiv active z-99999"></div>
<div *ngIf="showImagePopup"
     class="modal modal-lg lR-modal-popup active z-99999"
     id="viewReceiptModal"
     tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button (click)="closeImagePopupEvent()"
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="{{ 'Close' | translate }}">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title"
            id="ImagePopupTitle">
          {{ data.title }}
        </h4>
      </div>
      <div class="modal-body position-relative">
        <div class="imgContainer height-auto">
          <span class="d-block text-center">
            <img [src]="data.image2"
                 class="mx-auto" />
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
<app-alert-panel [data]="alertPanelModel"
                   (callbackEvent)="alertPanelReceiver($event)"></app-alert-panel>
<!-- <app-video-icon class="dependant-function-video" [events]="eventsSubject.asObservable()"
    [VideoUrl]="dependentFunctionVideoURL"></app-video-icon> -->