<div class="overLayDiv active"></div>
<div id="rightSideBar">
    <div class="rightSidePanel w-70pc active">
        <header>
            <div class="row">
                <div class="
              col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12
              no-padding-right-left
            ">
                    <div class="row">
                        <div class="
                  col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8
                  padding-left-0
                ">
                            <h2>
                                {{ "item_for_approval_as_an_approver" | translate }}
                            </h2>
                        </div>
                        <div class="
                  col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4
                  padding-right-0
                  header-icons
                  text-right
                ">
                            <ul class="d-flex justify-content-end w-100pc">
                                <li class="padding-right-0" (click)="fnClosePanel()">
                                    <button
                                        class="btn btn-outline-secondary border-color-warning text-mustard height-38px margin-right-15">
                                        {{ 'label_close' | translate }}</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div class="clear"></div>

        <div class="row">
            <div class="block padding-top-10 heightCalc-6">
                <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 no-padding-right-left heightCalc-12">
                    <!----------------------Start Grid--------------------------->
                    <app-grid [columns]="gridColumnDefs" [data]="fmAsApproverSummary"
                        [gridConfigItems]="gridConfigItems" (onCellClick)="fnOnGridCellClick($event)">
                    </app-grid>
                    <!----------------------End Grid--------------------------->
                </div>
            </div>
        </div>

    </div>
</div>

<employee-status-panel [hidden]="!showApproverStatusPanel" (closePanel)="showApproverStatusPanel = false"
    [employeesList]="employeesList" [costCentre]="approverListCostCentre" [costCentreCode]="approverListCostCentreCode"
    [costCentreId]="approverListCostCentreId" [itemType]="panelItemType">
</employee-status-panel>

<assign-approver-panel [(selectedItems)]="selectedCostCentres" *ngIf="selectedCostCentres.length > 0"
    (closePanel)="closeAssignPanel($event)" [columnDefinitions]="assignApproversGridColumnDefs" [itemType]="2">
</assign-approver-panel>