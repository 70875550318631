export interface INRGetDashboardClaimaintDetail {
    userId: number;
    claimantName: string;
    isCompliant: boolean;
    compliantString: string;
    totalDistance: number;
}
export class NRGetDashboardClaimaintDetail implements INRGetDashboardClaimaintDetail {
    userId: number;
    claimantName: string;
    isCompliant: boolean;
    compliantString: string;
    totalDistance: number;
}