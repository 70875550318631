
export interface IApiRequest<T> {
    webApiName: string;
    webApiVersion: number;
    requestId: string;
    requestInfo: T;

}

export class ApiRequest<T> implements IApiRequest<T> {
    webApiName: string;
    webApiVersion: number;
    requestId: string;
    requestInfo: T;

  constructor(webApiName: string,
    webApiVersion: number,
    requestId: string) {
    this.webApiName = webApiName;
    this.webApiVersion = webApiVersion;
    this.requestId = requestId;
  }
}


