export class InfoModelPopup {
    constructor(public panelKey: string, public title: string, public subTitle: string, public description: string,
        public description2: string, public image: string, public videoURLs: any, public tooltip: string,
        public btnLabel: string, public actionButton: boolean) {

    }
}

export class InfoPanelOutput {
    constructor(public panelKey: string, public buttonKey: string, public outputModel: any) {
    }
}