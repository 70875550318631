import { OnInit } from "@angular/core";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, debounceTime } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { State } from "src/app/_models";
import { ClaimsHeaderService } from "src/app/shared/claimsHeader/claims-header.service";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class StateService implements OnInit {
  constructor(private http: HttpClient, private claimsHeaderService: ClaimsHeaderService) { }

  private StateUrl = `${environment.catalogApiUrl}GetState`;

  ngOnInit() {
    this.getExpenseState();
  }

  getExpenseState() {
    var listOfState = this.http.get(this.StateUrl,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }).pipe(
        debounceTime(500), // WAIT FOR 500 MILISECONDS ATER EACH KEY STROKE.
        map((data: any) => {
          return data.length != 0
            ? (data as any[])
            : [{ State: "No Record Found" } as any];
        })
      );

    return listOfState;
  }

  getExpenseStates() {
    return this.http.get<State[]>(this.StateUrl,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  getAllExpenseState() {

    const cacheKey = "ExpenseStateListData";
    let cacheData = sessionStorage.getItem(cacheKey);

    if (cacheData) {
      return of(JSON.parse(cacheData));
    } else {
      return this.http.get<State[]>(this.StateUrl, {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }).pipe(map(data => {
        sessionStorage.setItem(cacheKey, JSON.stringify(data));
        return data;
      }));
    }

  }
}