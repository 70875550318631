import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { authPasswordFlowConfig } from "./auth-password-flow.config";
import { OAuthService } from "angular-oauth2-oidc";
import { AlertService } from "./alert-panel/alert.service";
import { AlertPanelModel } from "./_models/common/alertPanelModel";
export let browserRefresh = false;


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {


  alertPanelModel = new AlertPanelModel(
    false,
    "",
    [""],
    "",
    "",
    true,
    this.translate.instant("please_review_or_confirm")
  );


  alertPanelModelForReload = new AlertPanelModel(
    false,
    "",
    [""],
    "",
    "",
    true,
    this.translate.instant("Alert")
  );

  constructor(private translate: TranslateService, 
        private oauthService: OAuthService, private alertService: AlertService,) {

   

    this.translate.use(this.translate.getBrowserLang());

    this.oauthService.configure(authPasswordFlowConfig);
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.loadDiscoveryDocument();


    this.alertService.getFormClosingEvent().subscribe((hasChanges) => {
      if (hasChanges) {
        this.alertPanelModel = new AlertPanelModel(
          true,
          this.translate.instant("label_confirm"),
          [this.translate.instant("business_purpose_discard_msg")],
          this.translate.instant("label_cancel"),
          this.translate.instant("label_confirm"),
          true,
          this.translate.instant("please_review_or_confirm")
        );
      } else {
        this.alertPanelModel = new AlertPanelModel(
          false,
          "",
          [""],
          "",
          "",
          true,
          this.translate.instant("please_review_or_confirm")
        );
      }
    });
  }



  closeAlertPanel(returnValue) {
    this.alertPanelModel = new AlertPanelModel(
      false,
      "",
      [""],
      "",
      "",
      true,
      this.translate.instant("please_review_or_confirm")
    );
    this.alertService.onUserAction(returnValue);
  }

  closeAlertPanelForReload(returnValue) {
    this.alertPanelModelForReload = new AlertPanelModel(
      false,
      "",
      [""],
      "",
      "",
      true,
      this.translate.instant("please_review_or_confirm")
    );
    if (returnValue) {
      location.reload();
    }
  }

}
