<div class="custom-switch toggle-renderer" [hidden]="isHidden">
    <input type="checkbox" class="custom-control-input" id="customSwitches1{{informationId}}" [(ngModel)]="isEnabled" [checked]="isEnabled" (change)="onChange($event)"/>   
    <label *ngIf="showText" class="custom-control-label cursor-pointer f-14 fw-600 lH-25 padding-left-5"
                    for="customSwitches1{{informationId}}">
                    <p *ngIf="isEnabled">{{ showSwitchText2 }}</p>
    </label>
    <label *ngIf="!showText" class="custom-control-label cursor-pointer f-14 fw-600 lH-25 padding-left-5"
                    for="customSwitches1{{informationId}}">
                   </label>
        
</div>
