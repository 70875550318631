import { Component } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: 'log-cell',
  templateUrl: './status-renderer.component.html'
})
export class StatusRenderComponent implements ICellRendererAngularComp {
  public text: string;
  public statusValue: any;

  constructor() {

  }

  initilize(params: ICellRendererParams) {
    this.statusValue = params.getValue();
    if (this.statusValue) {
      this.text = this.statusValue.toString() == "1" ? "Active" : "Inactive";
    }
  }

  // called on init
  agInit(params: any): void {
    this.initilize(params);

  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.initilize(params);
    return true;
  }

}
