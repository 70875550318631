<div class="overLayDiv active"></div>
<div id="rightSideBar">
    <div class="rightSidePanel w-85pc active">
        <header>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding-right-left">
                    <div class="row">
                        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8 padding-left-0">
                            <div class="pageTitle">
                                <div class="viewDemo">
                                    <ul class="top--12px position-relative">
 
                                        <li class="no-padding-right-left">
                                            <h2 class="d-block position-relative lH-42">
                                                {{ panelHeader }}
                                            </h2>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 padding-right-0 header-icons text-right">
                            <ul class="d-flex justify-content-end w-100pc">

                                <li class="margin-right-10" *ngIf="gridData.length > 0">
                                    <button class="btn btn-outline-secondary border-color-warning text-mustard height-38px" 
                                    (click)="fnViewMileageMapAndCalculations()" *ngIf="!isNotMileageCategory">
                                    {{ 'view_map_calculation' | translate }}
                                    </button>
                                </li>

                                <li class="margin-right-10" *ngIf="gridData.length > 0 && gridData.length < legsCount">
                                    <button class="btn btn-outline-secondary border-color-warning text-mustard height-38px" 
                                    [ngClass]="{'disabled-state': gridData && gridData.length >= legsCount}"
                                    (click)="fnShowAddJourneyPanel()">
                                    {{ 'add_journey' | translate }}
                                    </button>
                                </li>

                                <li class="margin-right-10">
                                    <button class="btn btn-primary height-38px" 
                                    [ngClass]="{'disabled-state': disableSaveButton || !hasFormChanges}"
                                    (click)="fnSaveMultiplLegDetails()">
                                    {{ 'save_text' | translate }}
                                    </button>
                                </li>


                            <li class="padding-right-0" (click)="fnClosePanel()">
                                <button
                                    class="btn btn-outline-secondary border-color-warning text-mustard height-38px">
                                    {{ "label_close" | translate }}
                                </button>
                            </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    
        <div class="clear"></div>

        <div class="block padding-top-10 " *ngIf="gridData.length == 0">
            <div class="col-md-12 no-padding-right-left margin-top-0 position-relative heightCalc-11 scrollbar-visible overflow-auto">
                <div class="">
                    <button class="btn btn-primary align-center-middle" [ngClass]="{'disabled-state': gridData.length == legsCount}" (click)="fnShowAddJourneyPanel()">
                        {{ 'add_journey' | translate }}
                        <span class="d-block">({{gridData.length}} / {{ legsCount }}) </span>
                    </button>
                </div>
            </div>
        </div>

        <div class="block" [hidden]="!(gridData && gridData.length >0)">
            <div class="col-lg-12 no-padding-right-left ">
                <div class="row d-flex justify-content-between">
        
                    <p class="w-100pc d-flex justify-content-between fw-600 border-bottom padding-bottom-10 ">
                        <span class="fw-600 color-orange">{{ 'multiple_legs_list' | translate }}</span>
                        <span *ngIf="!isCarbonFootprintReporting">{{ 'total_legs' | translate }}:&nbsp; {{gridData.length}} | {{ 'total_distance' | translate }}: 
                            {{this.fnGetTotalDistanceOflegs()}} {{mileageUOMText}} </span>
                        <span *ngIf="isCarbonFootprintReporting">{{ 'total_legs' | translate }}:&nbsp; {{gridData.length}} | {{ 'total_distance' | translate }}: 
                                {{this.fnGetTotalDistanceOflegs()}} {{mileageUOMText}} | {{ 'carbon_emission' | translate }}: {{this.fnGetTotalCarbonEmission()}} {{carbonEmissionText}} </span>
                    </p>
    
                </div>
            </div>
            <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 no-padding-right-left heightCalc-12">
                <!----------------------Start Grid--------------------------->
                <app-grid [columns]="gridColumnDefs" [data]="gridData" [gridConfigItems]="gridConfigItems" 
                (onCellClick)="fnOnGridCellClick($event)"
               >
                </app-grid>
                <!----------------------End Grid--------------------------->
            </div>
        </div>






    </div>
</div>

<!----------------------Side Panels--------------------------->

<add-journey-panel *ngIf="showAddJourneyPanel" [(inputModel)]="addJourneyInputModel"
 [(mileageAllowance)]="mileageAllowance" [(resourceMileageDetails)]="resourceMileageDetails" 
  [(vehicleResponse)]="vehicleResponse" [(expenseModel)]="expenseModel" [isCarbonFootprintReporting]="isCarbonFootprintReporting"
 (closePanelEvent)="fnCloseAddJourneyPanel($event)" (submitEvent)="fnSubmitAddJourneyPanel($event)" 
 (deleteEvent)="fnDeleteAddJourneyPanel($event)">
</add-journey-panel>

<app-alert-panel *ngIf="showDeleteConfirmationAlert" [data]="deleteConfirmationAlertModel"
    (callbackEvent)="fnDeleteConfirmationAlertReceiver($event)">
</app-alert-panel>

<app-alert-panel *ngIf="showOneAddJourneyAlert" [data]="oneAddJourneyAlertModel"
    (callbackEvent)="fnOneAddJourneyAlertReceiver($event)">
</app-alert-panel>


<app-view-receipt #viewReceiptComponent [milesTravelled]="milesTravelled" [triangulationMiles]="triangulationMiles"
    [detourMiles]="detourMiles" [amount]="receiptAmount" [distance]="distance" [mileageRate]="mileageRate" [mileageArray]="mileageArray" 
    showDeleteButton=true [mileage]="calculateMileageDetails?.mileageDetailList[0]" [isMileageCategory]="isMileageCategory">
</app-view-receipt>

<app-alert-panel *ngIf="showGoogleServiceErrorPopup" [data]="GoogleServiceErrorPopupModel"
(callbackEvent)="fnGoogleServiceErrorPopupReceiver($event)">
</app-alert-panel>