import { Injectable, forwardRef, Inject } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { IApiresponse } from "../../_models/common/request-response/apiresponse";
import { IApiRequest } from "../../_models/common/request-response/api-request";
import {
  ICardOrganisationSaveResponse,
  ICardOrganisationSaveRequest,
  ICardImportTemplateSaveRequest,
  ICardImportTemplateSaveResponse,
  ICardRulesFinancialYearResponse,
  ICardRulesFinancialYearSaveRequest,
  ICardRulesEmailReminderResponse,
  ICardRulesEmailReminderSaveRequest,
  ICardRulesImportResponse,
  ICardRulesImportSaveRequest,
  ICardUserAssignmentListRequest,
  ICardUserAssignmentListResponse,
  ICardUserAssignmentRequest,
  ICSVRecordExport,
  CSVRecordExport,
  ICSVRecordSampleCCImportTemplateExport,
  CSVRecordSampleCCImportTemplateExport,
  ICardReviewUploadedStatementRequest,
  ICardAssignCardExpensesToClaimantRequest,
  IGetAllEmployeesRequest,
  IDismissCardExpenseRequest,
  IClaimantAssociatedExpenseListRequest,
  IClaimantAssociatedExpenseListResponse,
  ClaimantAssociatedExpenseListRequest,
  ICardPendingExpenseListResponse,
  ICardPendingExpenseListByImportIdRequest,
  ICardPendingExpenseListRequest,
  ICardReassignedAndDeletedExpenseListResponse,
  ICardSaveSupervisoryActionRequest,
  ICardReviewUploadedTransactionsRequest,
  ICardImportTemplateSaveRequestModel,
  IGoLiveWithCardFunctionRequest,
  ICardImportTemplateDetailSaveDateFormatRequest,
  ICardPendingExpenseRequest,
  IPendingCreditCardExpenseListByCardRequest,
  UsersForAssignCCExpense,
  IUnassignExpenseByCardRequest,
  IDuplicateCardDetailsRequest,
  IDuplicateCardDetailsResponse,
  ICCDashboardRequest,
  ICCDashboardResponse,
  ICCExpenseResponse,
  ICCPendingTransactionRequest,
  IBulkImportAssignCreditCardResponse,
  IBulkImportAssignCreditCardRequest,
  ICCImportedTransactionRequest,
  ICCDashboardImportDetails,
  CCImportedFileDateDetails,
  IAssignCreditCardToClaimantRes,
  SaveUserCreditCardDetailsRequest,
  ICardDetailsForImport
} from "../../_models/credit-card/credit-card";
import { ClaimsHeaderService } from "src/app/shared/claimsHeader/claims-header.service";
import { ExcelExportService } from "../common/excel-export.service";

import { BackgroundTaskKeys, BackgroundTaskNotificationBody, BackgroundTaskNotificationHeaders, BackgroundTaskTopics } from 'src/app/common/commonvariables';
import { NotificationService } from 'src/app/_services/common/notification.service';
import { FunctionPackageEnum, FunctionPrivilege } from 'src/app/_models';
import { BackgroundTask } from 'src/app/_models/common/background-task';
import { IPendingExpenseResponse} from "../../_models/multiple-companies/multiple-companies";

@Injectable({
  providedIn: "root",
})
export class CreditCardService {
  constructor(
    private http: HttpClient,
    private claimsHeaderService: ClaimsHeaderService,
    private excelExportService: ExcelExportService,
    private notificationService: NotificationService,
  ) { }

  SaveCardOrganisation(
    requestinfo: IApiRequest<ICardOrganisationSaveRequest[]>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.expenseApiUrl}SaveCardOrganisation`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetCardOrganisation(organisationId: number) {
    let params = new HttpParams();
    params = params.append("organisationId", organisationId.toString());

    return this.http.get<any>(
      `${environment.expenseApiUrl}GetCardOrganisation`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  SaveCardImportTemplate(
    requestinfo: IApiRequest<ICardImportTemplateSaveRequest>
  ): Observable<IApiresponse<ICardImportTemplateSaveResponse>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<ICardImportTemplateSaveResponse>>(
      `${environment.expenseApiUrl}SaveCardImportTemplate`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetCardImportTemplate(organisationId: number) {
    let params = new HttpParams();
    params = params.append("organisationId", organisationId.toString());

    return this.http.get<any>(
      `${environment.expenseApiUrl}GetCardImportTemplate`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  GetCardImportTemplateHistory(organisationId: number) {
    let params = new HttpParams();
    params = params.append("organisationId", organisationId.toString());

    return this.http.get<any>(
      `${environment.expenseApiUrl}GetCardImportTemplateHistoryByOrganisationId`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  SaveCardRulesImport(
    requestinfo: IApiRequest<ICardRulesImportSaveRequest>
  ): Observable<IApiresponse<ICardRulesImportResponse>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<ICardRulesImportResponse>>(
      `${environment.expenseApiUrl}SaveCardRulesImport`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetCardRulesImport(organisationId: number) {
    let params = new HttpParams();
    params = params.append("organisationId", organisationId.toString());

    return this.http.get<any>(
      `${environment.expenseApiUrl}GetCardRulesImport`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  SaveCardRulesEmailReminder(
    requestinfo: IApiRequest<ICardRulesEmailReminderSaveRequest>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.expenseApiUrl}SaveCardRulesEmailReminder`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetCardRulesEmailReminder(organisationId: number) {
    let params = new HttpParams();
    params = params.append("organisationId", organisationId.toString());

    return this.http.get<any>(
      `${environment.expenseApiUrl}GetCardRulesEmailReminder`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  SaveCardRulesFinancialYear(
    requestinfo: IApiRequest<ICardRulesFinancialYearSaveRequest>
  ): Observable<IApiresponse<ICardRulesFinancialYearResponse>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<ICardRulesFinancialYearResponse>>(
      `${environment.expenseApiUrl}SaveCardRulesFinancialYear`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetCardRulesFinancialYear(organisationId: number) {
    let params = new HttpParams();
    params = params.append("organisationId", organisationId.toString());

    return this.http.get<any>(
      `${environment.expenseApiUrl}GetCardRulesFinancialYear`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  GetCardUserAssignmentForOrg(
    requestInfo: IApiRequest<ICardUserAssignmentListRequest>
  ): Observable<IApiresponse<ICardUserAssignmentListResponse>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<ICardUserAssignmentListResponse>>(
      `${environment.expenseApiUrl}GetCardUserAssignmentByOrgId`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SaveCardUserAssignment(
    requestInfo: IApiRequest<ICardUserAssignmentRequest>
  ): Observable<IApiresponse<ICardUserAssignmentListResponse>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<ICardUserAssignmentListResponse>>(
      `${environment.expenseApiUrl}SaveCardUserAssignment`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetCardUserAssignmentHistory(cardUserAssignmentId: number) {
    let params = new HttpParams();
    params = params.append(
      "cardUserAssignmentId",
      cardUserAssignmentId.toString()
    );

    return this.http.get<any>(
      `${environment.expenseApiUrl}GetCardUserAssignmentHistoryByCardUserAssignmentId`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  exportData(
    exportedData: any[],
    excelFileName: string,
    addErrorColumn: boolean
  ) {
    let mappedData: ICSVRecordExport[] = [];

    for (let i = 0; i < exportedData.length; i++) {
      let recordExport = new CSVRecordExport();

      recordExport.EmployeeID = exportedData[i].employeeCode;
      recordExport.ClaimantName = exportedData[i].fullName;
      recordExport.Email = exportedData[i].claimantEmail;
      recordExport.CreditCardNumber = exportedData[i].cardNumber;

      if (addErrorColumn) {
        recordExport.Error = exportedData[i].error;
      }

      mappedData.push(recordExport);
    }

    this.excelExportService.exportAsExcelFile(mappedData, excelFileName);
  }

  exportErrorLogData(
    exportedData: any[],
    excelFileName: string,
    addErrorColumn: boolean
  ) {
    let mappedData: ICSVRecordExport[] = [];

    for (let i = 0; i < exportedData.length; i++) {
      let recordExport = new CSVRecordExport();

      recordExport.EmployeeID = exportedData[i].claimantCode;
      recordExport.ClaimantName = exportedData[i].employeeName;
      recordExport.Email = exportedData[i].email;
      recordExport.CreditCardNumber = exportedData[i].cardNumber;

      if (addErrorColumn) {
        recordExport.Error = exportedData[i].error;
      }

      mappedData.push(recordExport);
    }

    this.excelExportService.exportAsExcelFile(mappedData, excelFileName);
  }

  GetCardSetupCount(organisationId: number) {
    let params = new HttpParams();
    params = params.append("organisationId", organisationId.toString());

    return this.http.get<any>(
      `${environment.expenseApiUrl}GetCardSetupCount`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  GetReferenceNumbersFromImportedTransactions(organisationId: number) {
    let params = new HttpParams();
    params = params.append("organisationId", organisationId.toString());

    return this.http.get<any>(
      `${environment.expenseApiUrl}GetReferenceNumbersFromImportedTransactionsByOrganisationId`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  exportSampleCCImportData(
    exportedData: any[],
    excelFileName: string,
    addErrorColumn: boolean
  ) {
    let mappedData: ICSVRecordSampleCCImportTemplateExport[] = [];

    for (let i = 0; i < exportedData.length; i++) {
      let recordExport = new CSVRecordSampleCCImportTemplateExport();

      recordExport.ExpenseDate = exportedData[i].ExpenseDate;
      recordExport.CardNo = exportedData[i].CardNo;
      recordExport.ReferenceNo = exportedData[i].ReferenceNo;
      recordExport.Amount = exportedData[i].Amount;
      recordExport.Supplier = exportedData[i].Supplier;

      mappedData.push(recordExport);
    }

    this.excelExportService.exportAsExcelFile(mappedData, excelFileName);
  }

  ReviewUploadedCardStatement(
    requestInfo: IApiRequest<ICardReviewUploadedStatementRequest>
  ): Observable<IApiresponse<object>> {
    let body = JSON.stringify(requestInfo);

    return this.http.post<IApiresponse<any>>(
      `${environment.expenseApiUrl}ReviewUploadedCardStatement`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  ReviewUploadedCardStatementNew(
    requestInfo: IApiRequest<ICardReviewUploadedTransactionsRequest>
  ): Observable<IApiresponse<object>> {
    let body = JSON.stringify(requestInfo);

    return this.http.post<IApiresponse<any>>(
      `${environment.expenseApiUrl}ReviewUploadedCardStatement`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SaveReviewedImports(
    requestInfo: IApiRequest<object>
  ): Observable<IApiresponse<object>> {
    let body = JSON.stringify(requestInfo);

    return this.http.post<IApiresponse<any>>(
      `${environment.expenseApiUrl}SaveReviewedImports`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetDismissedTransactions(
    requestInfo: IApiRequest<object>
  ): Observable<IApiresponse<object>> {
    let body = JSON.stringify(requestInfo);

    return this.http.post<IApiresponse<any>>(
      `${environment.expenseApiUrl}GetDismissedExpenseList`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  getCardDashboardData(
    organisationId: number
  ): Observable<IApiresponse<object>> {
    let params = new HttpParams();
    params = params.append("organisationId", organisationId.toString());

    return this.http.get<IApiresponse<any>>(
      `${environment.expenseApiUrl}GetCardDashboardData`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  getCardDashboardPendingTransactionsData(
    organisationId: number
  ): Observable<IApiresponse<object>> {
    let params = new HttpParams();
    params = params.append("organisationId", organisationId.toString());

    return this.http.get<IApiresponse<any>>(
      `${environment.expenseApiUrl}GetCardDashboardPendingTransactionsData`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  getCardDashboardTopSpendor(
    organisationId: number
  ): Observable<IApiresponse<object>> {
    let params = new HttpParams();
    params = params.append("organisationId", organisationId.toString());

    return this.http.get<IApiresponse<any>>(
      `${environment.expenseApiUrl}GetTopSpendor`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  getCardDashboardUsedCategories(
    organisationId: number
  ): Observable<IApiresponse<object>> {
    let params = new HttpParams();
    params = params.append("organisationId", organisationId.toString());

    return this.http.get<IApiresponse<any>>(
      `${environment.expenseApiUrl}GetTopFrequentlyUsedCategories`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  getCardDashboardTopQuickestActionTakers(
    organisationId: number
  ): Observable<IApiresponse<object>> {
    let params = new HttpParams();
    params = params.append("organisationId", organisationId.toString());

    return this.http.get<IApiresponse<any>>(
      `${environment.expenseApiUrl}GetQuickestActionTakers`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  getCardDashboardTopSlowestActionTakers(
    organisationId: number
  ): Observable<IApiresponse<object>> {
    let params = new HttpParams();
    params = params.append("organisationId", organisationId.toString());

    return this.http.get<IApiresponse<any>>(
      `${environment.expenseApiUrl}GetTopSlowestActionTakers`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  getCreditCardDashboardGraphsData(
    organisationId: number
  ): Observable<IApiresponse<object>> {
    let params = new HttpParams();
    params = params.append("organisationId", organisationId.toString());

    return this.http.get<IApiresponse<any>>(
      `${environment.expenseApiUrl}GetCreditCardDashboardGraphsData`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  getCreditCardDashboardDownloadImportedTransactions(
    organisationId: number,
    cardOrginialFieldId: number
  ): Observable<IApiresponse<object>> {
    let params = new HttpParams();
    params = params.append("organisationId", organisationId.toString());
    params = params.append(
      "cardImportOriginalFileId",
      cardOrginialFieldId.toString()
    );

    return this.http.get<IApiresponse<any>>(
      `${environment.expenseApiUrl}GetCreditCardDashboardDownloadImportedTransactions`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  getCreditCardDashboardDownloadNotImportedTransactions(
    organisationId: number,
    cardOrginialFieldId: number
  ): Observable<IApiresponse<object>> {
    let params = new HttpParams();
    params = params.append("organisationId", organisationId.toString());
    params = params.append(
      "cardImportOriginalFileId",
      cardOrginialFieldId.toString()
    );

    return this.http.get<IApiresponse<any>>(
      `${environment.expenseApiUrl}GetCreditCardDashboardDownloadNotImportedTransactions`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  getCreditCardDashboardDownloadPendingTransactions(
    organisationId: number,
    cardOrginialFieldId: number
  ): Observable<IApiresponse<object>> {
    let params = new HttpParams();
    params = params.append("organisationId", organisationId.toString());
    params = params.append(
      "cardImportOriginalFileId",
      cardOrginialFieldId.toString()
    );

    return this.http.get<IApiresponse<any>>(
      `${environment.expenseApiUrl}GetCreditCardDashboardDownloadPendingTransactions`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  GetUnassignedCardExpenses() {
    let url = `${environment.expenseApiUrl}GetUnassignedCardExpenses`;
    return this.http.get<any>(url, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  AssignCardExpensesToClaimant(
    requestinfo: IApiRequest<ICardAssignCardExpensesToClaimantRequest>
  ): Observable<IApiresponse<object>> {
    let body = JSON.stringify(requestinfo);

    return this.http.post<IApiresponse<any>>(
      `${environment.expenseApiUrl}AssignCardExpensesToClaimant`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  getDismissedExpenseCount(
    organisationId: number
  ): Observable<IApiresponse<object>> {
    let params = new HttpParams();
    params = params.append("organisationId", organisationId.toString());

    return this.http.get<IApiresponse<any>>(
      `${environment.expenseApiUrl}GetDismissedExpenseCount`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  DismissExpense(
    requestinfo: IApiRequest<IDismissCardExpenseRequest>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestinfo);

    return this.http.post<IApiresponse<any>>(
      `${environment.expenseApiUrl}DismissExpense`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }


  GetClaimantAssociatedExpenseList(
    requestInfo: IApiRequest<IClaimantAssociatedExpenseListRequest>
  ): Observable<IApiresponse<IClaimantAssociatedExpenseListResponse>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<IClaimantAssociatedExpenseListResponse>>(
      `${environment.expenseApiUrl}GetClaimantAssociatedExpenseList`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetCardRulesSetupStatus(organisationId: number) {
    let params = new HttpParams();
    params = params.append("organisationId", organisationId.toString());

    return this.http.get<any>(
      `${environment.expenseApiUrl}GetCardRulesSetupStatus`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  GetCardNumberLength(organisationId: number) {
    let params = new HttpParams();
    params = params.append("organisationId", organisationId.toString());

    return this.http.get<any>(
      `${environment.expenseApiUrl}GetCardNumberLength`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  GetPendingCreditCardExpenseListByImportId(
    requestInfo: IApiRequest<ICardPendingExpenseListByImportIdRequest>
  ): Observable<IApiresponse<ICardPendingExpenseListResponse[]>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<ICardPendingExpenseListResponse[]>>(
      `${environment.expenseApiUrl}GetPendingCreditCardExpenseListByImportId`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetAllPendingCreditCardExpenseList(
    requestInfo: IApiRequest<ICardPendingExpenseListRequest>
  ): Observable<IApiresponse<ICardPendingExpenseListResponse[]>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<ICardPendingExpenseListResponse[]>>(
      `${environment.expenseApiUrl}GetAllPendingCreditCardExpenseList`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetReassignedAndDeletedCreditCardExpenseList(
    requestInfo: IApiRequest<ICardPendingExpenseListRequest>
  ): Observable<IApiresponse<ICardReassignedAndDeletedExpenseListResponse[]>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<ICardReassignedAndDeletedExpenseListResponse[]>>(
      `${environment.expenseApiUrl}GetReassignedAndDeletedCreditCardExpenseList`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SaveCardSupervisoryAction(
    requestInfo: IApiRequest<ICardSaveSupervisoryActionRequest>
  ): Observable<number> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<number>(
      `${environment.expenseApiUrl}SaveCardSupervisoryAction`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetUnassignedCardExpenseCount(): Observable<number> {
    let url = `${environment.expenseApiUrl}GetUnassignedCardExpenseCountByOrgId`;
    return this.http.get<number>(url, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  GetUsersWithInActiveAndNoCardsForOrg(
    requestInfo: IApiRequest<ICardUserAssignmentListRequest>
  ): Observable<IApiresponse<ICardUserAssignmentListResponse>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<ICardUserAssignmentListResponse>>(
      `${environment.expenseApiUrl}GetUsersWithInActiveAndNoCardsForOrg`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SaveCardImportTemplateDetail(
    requestinfo: IApiRequest<ICardImportTemplateSaveRequestModel>
  ): Observable<IApiresponse<ICardImportTemplateSaveResponse>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<ICardImportTemplateSaveResponse>>(
      `${environment.expenseApiUrl}SaveCardImportTemplateDetail`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetCardImportTemplateDetail(organisationId: number, cardOrganisationId: number) {
    let params = new HttpParams();
    params = params.append("organisationId", organisationId.toString());
    params = params.append("cardOrganisationId", cardOrganisationId.toString());

    return this.http.get<any>(
      `${environment.expenseApiUrl}GetCardImportTemplateDetail`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  BulkCreditCardExpensesImport(
    saveRequest: IApiRequest<any>): Observable<any> {
    const notificationBody = BackgroundTaskNotificationBody.BulkCreditCardExpensesImport;
    const route = "universalimport/importsummary";
    let jobInfo = new BackgroundTask(BackgroundTaskTopics.Expense, BackgroundTaskKeys.BulkCreditCardExpensesImport,
      saveRequest, saveRequest.requestInfo.processedImportDataList.map(e => e.cardNumber), BackgroundTaskNotificationHeaders.BulkCreditCardExpensesImport,
      notificationBody, route, FunctionPackageEnum.UniversalImport, FunctionPrivilege.SetupCreditCard, saveRequest.requestInfo.fmName);

    return this.notificationService.submitBackgroundJob(jobInfo);

  }

  GoLiveWithCardFunction( requestinfo: IApiRequest<IGoLiveWithCardFunctionRequest>)
  : Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.expenseApiUrl}GoLiveWithCardFunction`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SaveCardImportTemplateDateFormat(
    requestinfo: IApiRequest<ICardImportTemplateDetailSaveDateFormatRequest>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.expenseApiUrl}SaveCardImportTemplateDateFormat`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetOrgTop5CreditCardImportList(organisationId: number) {
    let params = new HttpParams();
    params = params.append("organisationId", organisationId.toString());

    return this.http.get<any>(
      `${environment.expenseApiUrl}GetOrgTop5CreditCardImportList`,
      {
        params: params,
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  GetCardPendingExpenseList(
    requestInfo: IApiRequest<ICardPendingExpenseRequest>): Observable<IApiresponse<IPendingExpenseResponse[]>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<IPendingExpenseResponse[]>>(
        `${environment.expenseApiUrl}GetCardPendingExpenseList`,
        body,
        { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
}


GetPendingCreditCardExpenseListByCard(
  requestInfo: IApiRequest<IPendingCreditCardExpenseListByCardRequest>
): Observable<IApiresponse<ICardPendingExpenseListResponse[]>> {
  let body = JSON.stringify(requestInfo);
  return this.http.post<IApiresponse<ICardPendingExpenseListResponse[]>>(
    `${environment.expenseApiUrl}GetPendingCreditCardExpenseListByCard`,
    body,
    { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
  );
}

GetUsersForAssignCCExpense(orgId: number): Observable<IApiresponse<UsersForAssignCCExpense[]>> {
  let params = new HttpParams();
  params = params.append("orgId", orgId.toString());
  return this.http.get<IApiresponse<UsersForAssignCCExpense[]>>(
      `${environment.billingApiUrl}GetUsersForAssignCCExpense`,
      {
          params: params,
          headers: this.claimsHeaderService.createLoginAuthorizationHeader()
      }
  );
}

GetUnassignCreditCardExpenseList(orgId: number): Observable<IApiresponse<ICardPendingExpenseListResponse[]>> {
  let params = new HttpParams();
  params = params.append("orgId", orgId.toString());
  return this.http.get<IApiresponse<ICardPendingExpenseListResponse[]>>(
      `${environment.expenseApiUrl}GetUnassignCreditCardExpenseList`,
      {
          params: params,
          headers: this.claimsHeaderService.createLoginAuthorizationHeader()
      }
  );
}

GetUnassignExpenseByCard( requestinfo: IApiRequest<IUnassignExpenseByCardRequest>)
: Observable<IApiresponse<any[]>> {
  let body = JSON.stringify(requestinfo);
  return this.http.post<IApiresponse<any[]>>(
    `${environment.expenseApiUrl}GetUnassignExpenseByCard`,
    body,
    { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
  );
}

GetOrgDuplicateCardDetails(
  requestInfo: IApiRequest<IDuplicateCardDetailsRequest>
): Observable<IApiresponse<IDuplicateCardDetailsResponse[]>> {
  let body = JSON.stringify(requestInfo);
  return this.http.post<IApiresponse<IDuplicateCardDetailsResponse[]>>(
    `${environment.expenseApiUrl}GetOrgDuplicateCardDetails`,
    body,
    { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
  );
}

GetCCDashboard(
  requestInfo: IApiRequest<ICCDashboardRequest>
): Observable<IApiresponse<ICCDashboardResponse>> {
  let body = JSON.stringify(requestInfo);
  return this.http.post<IApiresponse<ICCDashboardResponse>>(
    `${environment.expenseApiUrl}GetCCDashboard`,
    body,
    { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
  );
}


GetCCDeletedExpenseList(orgId: number): Observable<IApiresponse<ICCExpenseResponse[]>> {
  let params = new HttpParams();
  params = params.append("orgId", orgId.toString());

  return this.http.get<IApiresponse<ICCExpenseResponse[]>>(
    `${environment.expenseApiUrl}GetCCDeletedExpenseList`,
    {
      params: params,
      headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
    }
  );
}

GetCCPendingTransactionList(
  requestInfo: IApiRequest<ICCPendingTransactionRequest>
): Observable<IApiresponse<ICCExpenseResponse[]>> {
  let body = JSON.stringify(requestInfo);
  return this.http.post<IApiresponse<ICCExpenseResponse[]>>(
    `${environment.expenseApiUrl}GetCCPendingTransactionList`,
    body,
    { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
  );
}


ValidateBulkImportAssignCreditCard(
  requestInfo: IApiRequest<IBulkImportAssignCreditCardRequest>): Observable<IApiresponse<IBulkImportAssignCreditCardResponse>> {
  let body = JSON.stringify(requestInfo);
  return this.http.post<IApiresponse<IBulkImportAssignCreditCardResponse>>(
    `${environment.expenseApiUrl}ValidateBulkImportAssignCreditCard`,
    body,
    { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
  );
}


BulkImportAssignCreditCard(
  requestInfo: IApiRequest<IBulkImportAssignCreditCardRequest>): Observable<IApiresponse<IBulkImportAssignCreditCardResponse>> {
  let body = JSON.stringify(requestInfo);
  return this.http.post<IApiresponse<IBulkImportAssignCreditCardResponse>>(
    `${environment.expenseApiUrl}BulkImportAssignCreditCard`,
    body,
    { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
  );
}

GetAssignCreditCardToClaimantList(orgId: number): Observable<IApiresponse<IAssignCreditCardToClaimantRes[]>> {
  let params = new HttpParams();
  params = params.append("orgId", orgId.toString());

  return this.http.get<IApiresponse<IAssignCreditCardToClaimantRes[]>>(
    `${environment.expenseApiUrl}GetAssignCreditCardToClaimantList`,
    {
      params: params,
      headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
    }
  );
}

SaveUserCreditCardDetails(requestInfo: IApiRequest<SaveUserCreditCardDetailsRequest>): Observable<IApiresponse<any>> {
  let body = JSON.stringify(requestInfo);
  return this.http.post<IApiresponse<any>>(`${environment.expenseApiUrl}SaveUserCreditCardDetails`, body, 
  { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
}

GetCreditCardDetailsForImport(orgId: number): Observable<IApiresponse<ICardDetailsForImport>> {
  let params = new HttpParams();
  params = params.append("orgId", orgId.toString());

  return this.http.get<IApiresponse<ICardDetailsForImport>>(
    `${environment.expenseApiUrl}GetCreditCardDetailsForImport`,
    {
      params: params,
      headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
    }
  );
}

GetCCImportedTransactionList(
  requestInfo: IApiRequest<ICCImportedTransactionRequest>
): Observable<IApiresponse<ICCExpenseResponse[]>> {
  let body = JSON.stringify(requestInfo);
  return this.http.post<IApiresponse<ICCExpenseResponse[]>>(
    `${environment.expenseApiUrl}GetCCImportedTransactionList`,
    body,
    { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
  );
}


GetCCImportedFileDateList(orgId: number): Observable<IApiresponse<CCImportedFileDateDetails[]>> {
  let params = new HttpParams();
  params = params.append("orgId", orgId.toString());
  return this.http.get<IApiresponse<CCImportedFileDateDetails[]>>(
      `${environment.expenseApiUrl}GetCCImportedFileDateList`,
      {
          params: params,
          headers: this.claimsHeaderService.createLoginAuthorizationHeader()
      }
  );
}


}
