<span [hidden]="!toolTipText" class="cursor-pointer"
  placement="right" [ngbPopover]="popContent" container="body"
  triggers="mouseenter:mouseleave"
> {{ text }}
</span>

<span [hidden]="toolTipText" class="cursor-pointer">
  {{ text }}
</span>

<ng-template #popContent>
  <div>{{ toolTipText }}</div>
</ng-template>
