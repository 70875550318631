import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-toggle-renderer-second',
  templateUrl: './toggle-renderer-second.component.html'
})
export class ToggleRendererSecondComponent implements ICellRendererAngularComp {

  informationId:number;
  isEnabled: boolean;  
  params: ICellRendererParams;
  isHidden: boolean = false;
  showText:boolean=false;
  showSwitchText2:string = "";
  constructor() {
  }

  initilize(params: ICellRendererParams) {
    if (params.data) {
      this.params = params;
      if(params.data.informationId)
      {
        this.isEnabled = params.data.isSwitchedOn2;     
        this.informationId = params.data.informationId; 
        this.isHidden = params.data.isHidden;
        this.showText = params.data.showText;
        this.showSwitchText2 = params.data.showSwitchText2;
      }      
    }
  }

  agInit(params: any): void {
    this.initilize(params);
  }

  refresh(params: any): boolean {
    this.initilize(params);
    return true;
  }

  onChange(event) {
    this.isEnabled = event.target.checked;
    this.params.setValue(this.isEnabled);
  }

}
