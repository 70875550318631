import { Injectable, OnInit, forwardRef, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { IUser } from '../../_models/common/user';
import { ClaimsHeaderService } from 'src/app/shared/claimsHeader/claims-header.service';
import { CreditCardModule } from 'src/app/_modules/modules/creditcard.module';

@Injectable({
  providedIn: 'root'
})
export class UserService implements OnInit {

  constructor(private http: HttpClient, private claimsHeaderService: ClaimsHeaderService) { }
  ngOnInit() {


  }

  GetFinanceManagerCount(organisationId: number) {
    let params = new HttpParams();
    params = params.append("organisationId", organisationId.toString());

    return this.http.get<any>(
      `${environment.billingApiUrl}GetOrgFinanceManagerCount`,
      { params: params, headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetOrgActiveFinanceManagerCount(organisationId: number) {
    let params = new HttpParams();
    params = params.append("organisationId", organisationId.toString());

    return this.http.get<any>(
      `${environment.billingApiUrl}GetOrgActiveFinanceManagerCount`,
      { params: params, headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }


}
