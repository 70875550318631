import { OnInit, Component, EventEmitter, Input, Output } from "@angular/core";
import { StateService } from "src/app/_services";
import { first } from "rxjs/operators";
import { HowDoIVideoLinks } from "src/app/common/commonvariables";
import { State } from "src/app/_models";
import { SelectStatesPopupModel } from "src/app/_models/claimant-on-web/claimant-on-web";

@Component({
    selector: "select-state-panel",
    templateUrl: "./select-state-panel.component.html"
})
export class SelectStatePanelComponent implements OnInit {

    @Input()
    inputData: SelectStatesPopupModel;

    @Output() closePanelEvent = new EventEmitter<boolean>();
    @Output() selectItemEvent = new EventEmitter<SelectStatesPopupModel>();

    param: any;
    howDoIVideoLinks = HowDoIVideoLinks;
    searchText: string = '';

    stateList = <State[]>[];
    selectedStateId: number = 0;

    constructor(private stateService: StateService) {
    }
    ngOnInit(): void {
        this.fnGetStates();
        this.fnSetStatesInputValues();
    }

    fnClosePanel() {
        this.closePanelEvent.emit(true);
    }

    fnGetStates() {
        this.stateService.getAllExpenseState()
            .pipe(first())
            .subscribe((response: State[]) => {
                this.stateList = response;
                this.fnGetSelectedStateOnTop();
            });
    }

    fnSelectStates(item: State) {
        if (item) {
            let selectStatesOutputModel = new SelectStatesPopupModel();
            selectStatesOutputModel.stateId = item.stateId;
            selectStatesOutputModel.stateName = item.stateName;
            this.selectItemEvent.emit(selectStatesOutputModel);
        }
    }

    fnSetStatesInputValues() {
        if (this.inputData) {
            this.selectedStateId = this.inputData.stateId;
        }
    }
    fnGetSelectedStateOnTop() {
        let stateId = this.inputData && this.inputData.stateId ? this.inputData.stateId : 0;

        if (stateId && this.stateList && this.stateList.length > 0) {
            const sortedArr = this.stateList.reduce((acc, element) => {
                if (element.stateId == stateId) {
                    return [element, ...acc];
                }
                return [...acc, element];
            }, []);
            this.stateList = sortedArr;
        }
    }

}
