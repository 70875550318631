
export interface IEmailSendRequest {
    templateName: string;
    userId: number;
    orgId: number;
    keyValuePairs: any;
}

export class EmailSendRequest implements IEmailSendRequest {
    templateName: string;
    userId: number;
    orgId: number;
    keyValuePairs: any;
    constructor(
        templateName: string,
        userId: number,
        orgId: number,
        keyValuePairs: any

    ) {
        this.templateName = templateName;
        this.userId = userId;
        this.orgId = orgId;
        this.keyValuePairs = keyValuePairs;
    }
}

export interface ISendReminderResponse {
    responseString: string;
}

export class SendReminderResponse implements ISendReminderResponse {
    responseString: string;
}
