import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Meta, Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { OAuthService } from "angular-oauth2-oidc";
import { ToastrService } from "ngx-toastr";
import { first } from "rxjs/operators";
import { RSAHelper } from "src/app/_helpers/RSAHelper";
import { UserTypeEnum } from "src/app/_models";
import { Claims } from "src/app/_models/common/claims";
import {
  UserRequestBase,
  Userrequestdto,
} from "src/app/_models/user/userrequestdto";
import { AuthenticationService } from "src/app/_services";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { VersionHistoryService } from "src/app/_services/common/version-history.service";
import { emailValidator } from "src/app/validators/email-validator";
import { CookieService } from "ngx-cookie-service";
import { RecaptchaErrorParameters } from "ng-recaptcha";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { UpToDateBuildService } from '../../../up-to-date.service';
import { environment } from "src/environments/environment";
import { ConvertNonAsciiToAscii } from "src/app/common/commonvariables";

@Component({
  selector: "app-login-new",
  templateUrl: "./login-new.component.html",
  styleUrls: ["./login-new.component.scss"],
})
export class LoginNewComponent implements OnInit, AfterViewInit {
  loginForm: FormGroup;

  params: any;
  submitted = false;
  claims: Claims;
  showCaptcha = false;
  caprchaResult = "";
  disableLoginButton = true;
  rsaHelper: RSAHelper;
  userrequest: UserRequestBase;
  registrationsubmitted = false;
  isOnboardingProcessComplete = false;
  showCaptchaMessage = false;
  showPassword = false;
  referralCode: string = "";
  impersonate: string = "";
  cookieValue: string;
  claimValue: string;
  impersonationResponse: any;
  impersonationUserClaim: any;
  loading = false;

  @Output() gotoNextStepEvent = new EventEmitter<any>();
  interval: any;

  isChrome: boolean =
    navigator.userAgent.toLowerCase().indexOf("chrome") > -1 &&
    navigator.vendor.toLowerCase().indexOf("google") > -1 &&
    !(navigator.userAgent.toLowerCase().indexOf("edg/") > -1);

  constructor(
    private translate: TranslateService,
    private claimsService: ClaimsService,
    private authenticationService: AuthenticationService,
    private oauthService: OAuthService,
    private meta: Meta,
    private title: Title,
    private router: Router,
    private versionHistoryService: VersionHistoryService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private upToDateService: UpToDateBuildService,
  ) {
    this.userrequest = new UserRequestBase();
    this.loginForm = this.createFormGroup();

    upToDateService.pollForBuildNumber().subscribe(buildIsUpToDate => {
      if (this.upToDateService.buildNumberAtStartup !== buildIsUpToDate.buildNumber)
        window.location.reload();
    });

  }

  popupVisible = false;
  validLength = false;
  containsLowercase = false;
  containsUppercase = false;
  containsSpecialChar = false;

  showPasswordPopup() {
    this.popupVisible = true;
  }

  hidePasswordPopup() {
    this.popupVisible = false;
  }

  validatePassword() {
    const passwordField = document.getElementById(
      "password"
    ) as HTMLInputElement;
    const password = passwordField.value;

    this.validLength = password.length >= 8;
    this.containsLowercase = /[a-z]/.test(password);
    this.containsUppercase = /[A-Z]/.test(password);
    this.containsSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\-]/.test(password);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.title.setTitle("Customer Login");
      this.meta.updateTag({
        name: "description",
        content:
        this.translate.instant('meta_tag_login_description'),
      });
    }, 1000);

    this.authenticationService.logout();
    this.authenticationService.loadAccessToken();
    this.SetRememberPassword();
    this.authenticationService.loadAccessToken();
    this.fnCheckRouteParams();
    //this.getIpCliente();
    this.params = this.route.snapshot.queryParams;
    var paramKeys = Object.keys(this.params);
    if (paramKeys.length > 0) {
      this.referralCode = this.params.referralCode;
      this.impersonate = this.params.impersonate;
      this.getUserImprosonated();
    }
  }

  SetRememberPassword() {
    if (
      localStorage.getItem("rememberpassword") &&
      localStorage.getItem("username")
    ) {
      this.username.setValue(localStorage.getItem("username"));
      this.password.setValue(localStorage.getItem("rememberpassword"));
      this.rememberpassword.setValue(true);
    }
  }

  fnCheckRouteParams() {
    let params = this.route.snapshot.queryParams;
    var paramKeys = Object.keys(params);
    if (paramKeys.length > 0 && params["code"]) {
      this.authenticationService.getXeroUserDetails(params["code"]).subscribe(
        (userData) => {
          const token = userData.idToken;

          localStorage.setItem("signupxerotoken", token);
          this.gotoSignUp();
        },
        (error) => { }
      );
    }
  }
  getUserImprosonated() {
    this.claims = new Claims();
    try {
      if (this.impersonate) {
        localStorage.clear();
        this.cookieValue = this.cookieService.get("UserIdentity");

        if (this.cookieValue == "") {
          this.router.navigate(["/login"]);
        } else {
          this.claimValue = this.cookieService.get("AdditionalUserClaim");
          //this.authenticationService.showAppLoader();
          localStorage.setItem("currentUser", this.cookieValue);
          localStorage.setItem("currentUserTemp", this.cookieValue);

          this.impersonationResponse = JSON.parse(this.cookieValue);
          this.oauthService.tokenEndpoint = environment.token_endpoint;
          this.oauthService
            .fetchTokenUsingPasswordFlowAndLoadUserProfile(
              this.impersonationResponse.EmailId,
              "testpassword"
            )
            .then(() => {
              localStorage.setItem("loginToken", this.impersonationResponse.Token);
              localStorage.setItem(
                "refresh_token",
                this.impersonationResponse.RefreshToken
              );
              localStorage.setItem(
                "access_token",
                this.impersonationResponse.Token
              );

              var tokenNew = this.oauthService.getAccessToken();
              this.claims.OrgId = this.impersonationResponse.OrganisationId;
              this.claims.EmailId = this.impersonationResponse.EmailId;
              this.claims.UserId = this.impersonationResponse.UserId;
              this.claims.CurrentOrganisationCurrency =
                this.impersonationResponse.CurrentOrganisationCurrency;
              this.claims.CurrentOrganisationCurrencyId =
                this.impersonationResponse.CurrentOrganisationCurrencyId;
              if (this.impersonationResponse.CurrentOrganisationCurrency) {
                this.claims.CurrentOrganisationCurrencyCode =
                  this.impersonationResponse.CurrentOrganisationCurrency.split(
                    "-"
                  )[1].trim();
                this.claims.CurrentOrganisationCurrency =
                  this.impersonationResponse.CurrentOrganisationCurrency.split(
                    "-"
                  )[1].trim();
              }
              this.claims.FinanceManager =
              ConvertNonAsciiToAscii.removeAccents(this.impersonationResponse.FirstName) +
                " " +
                ConvertNonAsciiToAscii.removeAccents(this.impersonationResponse.LastName);
              this.claims.LoginId = this.impersonationResponse.EmailId;
              this.claims.IsFinanceManager =
                this.impersonationResponse.IsFinanceManager;
              this.claims.Token = tokenNew;
              this.claims.FMRoleType = this.impersonationResponse.FMRoleType;
              this.claims.UserHomeCurrencyId =
                this.impersonationResponse.UserHomeCurrencyId;
              this.claims.UserHomeCurrencyCode =
                this.impersonationResponse.UserHomeCurrencyCode;
              this.claims.UserHomeCurrencyName =
                this.impersonationResponse.UserHomeCurrencyName;
              this.claims.IsDelegator = this.impersonationResponse.IsDelegator;
              this.claims.IsDelegate = this.impersonationResponse.IsDelegate;

              this.impersonationUserClaim = JSON.parse(this.claimValue);
              this.claims.OrgLogo = "assets/images/logo.png";
              this.claims.OrgName = this.impersonationUserClaim.Name;
              this.claims.TwoStepApproval =
                this.impersonationUserClaim.TwoStepApproval;
              this.claims.IsCardSetupComplete =
                this.impersonationUserClaim.IsCardSetupComplete;
              this.claims.accountingSystemId =
                this.impersonationUserClaim.AccountingSystemId;
              this.claims.accountingSystemType =
                this.impersonationUserClaim.AccountingSystemType;
              this.claims.IsOrgBasicFunctionTrialEndsReminder =
                this.impersonationUserClaim.IsOrgBasicFunctionTrialEndsReminder;
              this.claims.IsOrgBasicFunctionTrialEndsAlert =
                this.impersonationUserClaim.IsOrgBasicFunctionTrialEndsAlert;

              this.claims.ReferAndEarnRole =
                this.impersonationUserClaim.ReferAndEarnRole;
              this.claims.CompanyApproverRule =
                this.impersonationUserClaim.CompanyApproverRule;
              this.claims.isClaimOnWebAllowed =
                this.impersonationUserClaim.IsClaimOnWebAllowed;
              this.claims.IsFromOrgLogin = false;
              this.claims.ApprovalRuleSetting =
                this.impersonationUserClaim.ApprovalRuleSetting;
              this.claims.orgRoleId = this.impersonationUserClaim.RoleId;
              this.claims.showCostCentresWithoutApproverCount = true;
              this.claims.IsCashAdvanceEnabled =
                this.impersonationUserClaim.IsCashAdvanceEnabled;
              this.claims.IsCreditCardAdvanceEnabled =
                this.impersonationUserClaim.IsCreditCardAdvanceEnabled;
              this.claims.triangulationRule =
                this.impersonationUserClaim.TriangulationRule;
              this.claims.isPolicyOnVehicleChange =
                this.impersonationUserClaim.IsPolicyOnVehicleChange;
              this.claims.isUniversalImportAllowed =
                this.impersonationUserClaim.IsUniversalImportAllowed;
              this.claims.IsAPAutomationAllow =
                this.impersonationUserClaim.IsAPAutomationAllow;
                this.claims.IsAutomaticScanEnabled =
                this.impersonationUserClaim.IsAutomaticScanEnabled;
              this.claims.IsDelegateFunctionPurchasedOrTrial =
                this.impersonationUserClaim.IsDelegateFunctionPurchasedOrTrial;
              this.claims.isStripPaymentFailed =
                this.impersonationUserClaim.IsStripPaymentFailed;
              this.claims.RateType = this.impersonationUserClaim.RateType;
              this.claims.nrfcStatus = this.impersonationUserClaim.NRFCStatus;
              this.claims.isNRPackagePurchasedOrTrial = this.impersonationUserClaim.IsNRPackagePurchasedOrTrial;
              this.claims.isFCPackagePurchasedOrTrial = this.impersonationUserClaim.IsFCPackagePurchasedOrTrial;
              this.claims.userType = this.impersonationUserClaim.UserType ?? 0;

              localStorage.setItem("istokenImpersonated", "true");
              this.claimsService.updateClaims(this.claims);
              this.routeUser();
              this.cookieService.delete(
                "UserIdentity",
                "/",
                "expenseondemand.com",
                true
              );
              this.cookieService.delete(
                "AdditionalUserClaim",
                "/",
                "expenseondemand.com",
                true
              );
              this.title.setTitle("ExpenseOnDemand");
            })
            .catch((err) => {
              this.disableLoginButton = false;
              console.error("error logging in", err);
            });
        }
      }
    } catch (Exception) {
      this.router.navigate(["/login"]);
      this.cookieService.delete(
        "UserIdentity",
        "/",
        "expenseondemand.com",
        true
      );
      this.cookieService.delete(
        "AdditionalUserClaim",
        "/",
        "expenseondemand.com",
        true
      );
      this.loading = false;
    }
  }

  createFormGroup() {
    return new FormGroup({
      username: new FormControl("", [Validators.required, emailValidator]),
      password: new FormControl("", [Validators.required]),
      rememberpassword: new FormControl(false),
    });
  }

  get username() {
    return this.loginForm.get("username");
  }

  get password() {
    return this.loginForm.get("password");
  }

  get rememberpassword() {
    return this.loginForm.get("rememberpassword");
  }

  onEmailIdEntered() {
    if (this.username.value != "") {
      var domain = this.username.value.split("@").pop();
      this.authenticationService.checkDomainEnabledForSSO(domain).subscribe(
        (userData) => {
          if (userData.responseInfo.isDomainEnabled) {
            localStorage.setItem("isfromorglogin", "true");
            this.router.navigate(["/orglogin"]);
            return;
          } else {
            this.showPasswordBox = this.username.value != "" && true;
            setTimeout(() => {
              if (this.showPasswordBox) {
                this.passwordField.nativeElement.focus();
              }
            }, 200);
          }
        },
        (error) => { }
      );
    }
  }

  showPasswordBox = false;
  onLoginSubmit() {
    this.claims = new Claims();
    this.submitted = true;
    this.registrationsubmitted = false;
    if (this.loginForm.invalid) {
      return;
    }

    if (this.showCaptcha && this.caprchaResult == "") {
      return;
    }

    this.translate.getTranslation(this.translate.getBrowserLang());
    this.disableLoginButton = true;
    this.rsaHelper = new RSAHelper();
    this.userrequest.RequestInfo = new Userrequestdto();
    this.userrequest.WebApiName = "SoloLogin";
    this.userrequest.WebApiVersion = 1;
    this.userrequest.RequestId = "111";
    this.userrequest.RequestInfo.LoginId = this.username.value;
    this.userrequest.RequestInfo.Password = this.rsaHelper.Encrypt(
      this.password.value
    );
    if (this.rememberpassword.value == true) {
      this.claims.LoginId = this.userrequest.RequestInfo.LoginId;
      this.claims.Password = this.userrequest.RequestInfo.Password;
      localStorage.setItem("rememberpassword", this.password.value);
      localStorage.setItem("username", this.username.value);
    } else {
      localStorage.removeItem("rememberpassword");
      localStorage.removeItem("username");
    }
    this.authenticationService.login(this.userrequest).subscribe(
      (data) => {
        if (data.statusCode == 0) {
          //
          this.oauthService
            .fetchTokenUsingPasswordFlowAndLoadUserProfile(
              data.responseInfo.emailId,
              this.password.value
            )
            .then(() => {
              localStorage.removeItem("isfromorglogin");

              this.title.setTitle("ExpenseOnDemand");

              sessionStorage.removeItem("CurrentUserLoginAttempt");
              sessionStorage.removeItem("CurrentUserTriedLogin");
              localStorage.setItem("showComplianceStatement", "1");
              var tokenNew = this.oauthService.getAccessToken();
              this.claims.IsFromOrgLogin = false;
              this.claims.OrgId = data.responseInfo.organisationId;
              this.claims.EmailId = data.responseInfo.emailId;
              this.claims.UserId = data.responseInfo.userId;
              this.claims.supplierRule = data.responseInfo.supplierRule;
              this.claims.showCompliancePopup =
                data.responseInfo.showComplianceStatement;
              this.claims.complianceHeader = data.responseInfo.complianceHeader;
              this.claims.complianceStatement =
                data.responseInfo.complianceStatement;
              this.claims.CurrentOrganisationCurrency =
                data.responseInfo.currentOrganisationCurrency;
              this.claims.CurrentOrganisationCurrencyId =
                data.responseInfo.currentOrganisationCurrencyId;
              if (data.responseInfo.currentOrganisationCurrency) {
                this.claims.CurrentOrganisationCurrencyCode =
                  data.responseInfo.currentOrganisationCurrency
                    .split("-")[1]
                    .trim();
              }
              this.claims.FinanceManager =
              ConvertNonAsciiToAscii.removeAccents(data.responseInfo.firstName) + " " + ConvertNonAsciiToAscii.removeAccents(data.responseInfo.lastName);
              this.claims.LoginId = data.responseInfo.emailId;
              this.claims.IsFinanceManager = data.responseInfo.isFinanceManager;
              this.claims.IsMarqueeOn = data.responseInfo.isMarqueeOn;
              this.claims.IsAPAutomationAllow = data.responseInfo.isAPAutomationAllow;
              this.claims.IsAutomaticScanEnabled = data.responseInfo.isAutomaticScanEnabled;
              this.claims.MarqueeText = data.responseInfo.marqueeText;
              this.isOnboardingProcessComplete =
                data.responseInfo.isOnboardingProcessComplete;

              this.claims.userType = data.responseInfo.userType ?? 0;
              this.claims.kycStatus = data.responseInfo.kycStatus;
              this.claims.docStatus = data.responseInfo.docStatus;
              this.claims.IsKYCMandatoryForInvoice =
                data.responseInfo.isKYCMandatoryForInvoice;
              this.claims.Token = tokenNew;
              this.claims.FMRoleType = data.responseInfo.fmRoleType;
              this.claims.isClaimOnWebAllowed =
                data.responseInfo.isClaimOnWebAllowed;
              this.claims.orgRoleId = data.responseInfo.orgRoleId;

              this.claims.IsLeaveManagementAllowed =
                data.responseInfo.isLeaveManagementAllowed;
              this.claims.ApprovalRuleSetting =
                data.responseInfo.approvalRuleSetting;
              this.claims.showCostCentresWithoutApproverCount = true;
              this.claims.isUniversalImportAllowed =
                data.responseInfo.isUniversalImportAllowed;
              this.claims.IsAPAutomationAllow =
                data.responseInfo.isAPAutomationAllow;
              this.claims.IsAutomaticScanEnabled =
                data.responseInfo.isAutomaticScanEnabled;
              this.claims.UserHomeCurrencyId =
                data.responseInfo.userHomeCurrencyId;
              this.claims.UserHomeCurrencyCode =
                data.responseInfo.userHomeCurrencyCode;
              this.claims.UserHomeCurrencyName =
                data.responseInfo.userHomeCurrencyName;
              this.claims.IsDelegator = data.responseInfo.isDelegator;
              this.claims.IsDelegate = data.responseInfo.isDelegate;
              this.claims.isMultiAccSysIntAllow =
                data &&
                  data.responseInfo &&
                  data.responseInfo.isMultiAccSysIntAllow
                  ? data.responseInfo.isMultiAccSysIntAllow
                  : false;
              this.claimsService.updateClaims(this.claims);
              this.bindVersionHistory();
              if (!data.responseInfo.isOnboardingProcessComplete) {
                //this.router.navigate(["/onboard/prewelcomecurrency"]);
                this.gotoNextStepEvent.emit(4);
              }
              this.authenticationService
                .organisationdetails(
                  data.responseInfo.userId.toString(),
                  data.responseInfo.emailId,
                  data.responseInfo.token
                )
                .pipe(first())
                .subscribe(
                  (orgData) => {
                    if (orgData.statusCode == 0) {
                      if (orgData.responseInfo[0].icon !== null) {
                        if (orgData.responseInfo[0].icon == "") {
                          this.claims.OrgLogo = "assets/images/logo.png";
                        } else {
                          this.claims.OrgLogo =
                            "data:image/jpeg;base64," +
                            orgData.responseInfo[0].icon;
                        }
                      }
                      this.claims.RateType = orgData.responseInfo[0].rateType;
                      this.claims.nrfcStatus =
                        orgData.responseInfo[0].nrfcStatus;
                      this.claims.OrgName = orgData.responseInfo[0].name;
                      this.claims.TwoStepApproval =
                        orgData.responseInfo[0].twoStepApproval;
                      this.claims.IsCardSetupComplete =
                        orgData.responseInfo[0].isCardSetupComplete;
                      this.claims.accountingSystemId =
                        orgData.responseInfo[0].accountingSystemId;
                      this.claims.accountingSystemType =
                        orgData.responseInfo[0].accountingSystemType;
                      this.claims.showGooglePackagePurchaseReminder =
                        orgData.responseInfo[0].showGooglePackagePurchaseReminder;
                      this.claims.googlePackagePurchaseReminderThreshold =
                        orgData.responseInfo[0].googlePackagePurchaseReminderThreshold;
                      this.claims.IsOrgBasicFunctionTrialEndsReminder =
                        orgData.responseInfo[0].isOrgBasicFunctionTrialEndsReminder;
                      this.claims.IsOrgBasicFunctionTrialEndsAlert =
                        orgData.responseInfo[0].isOrgBasicFunctionTrialEndsAlert;

                      this.claims.IsLeaveManagementAllowed =
                        orgData.responseInfo[0].isLeaveManagementAllowed;
                      this.claims.ApprovalRuleSetting =
                        orgData.responseInfo[0].approvalRuleSetting;
                      this.claims.IsCostCentrePackagePurchasedOrTrial =
                        orgData.responseInfo[0].isCostCentrePackagePurchasedOrTrial;
                      this.claims.IsCustomerPackagePurchasedOrTrial =
                        orgData.responseInfo[0].isCustomerPackagePurchasedOrTrial;
                      this.claims.ReferAndEarnRole =
                        orgData.responseInfo[0].referAndEarnRole;
                      this.claims.CompanyApproverRule =
                        orgData.responseInfo[0].companyApproverRule;
                      this.claims.IsClaimantWiseMonthlyCappingEnabled =
                        orgData.responseInfo[0].isClaimantWiseMonthlyCappingEnabled;
                      this.claims.IsClaimantWiseYearlyCappingEnabled =
                        orgData.responseInfo[0].isClaimantWiseYearlyCappingEnabled;

                      this.claims.isUniversalImportAllowed =
                        orgData.responseInfo[0].isUniversalImportAllowed;
                      this.claims.IsAPAutomationAllow =
                        orgData.responseInfo[0].isAPAutomationAllowed;
                      this.claims.IsAutomaticScanEnabled =
                        orgData.responseInfo[0].isAutomaticScanEnabled;
                      this.claims.IsCashAdvanceEnabled =
                        orgData.responseInfo[0].isCashAdvanceEnabled;
                      this.claims.IsCreditCardAdvanceEnabled =
                        orgData.responseInfo[0].isCreditCardAdvanceEnabled;
                      this.claims.isMultiAccSysIntAllow =
                        orgData &&
                          orgData.responseInfo[0] &&
                          orgData.responseInfo[0].isMultiAccSysIntAllow
                          ? orgData.responseInfo[0].isMultiAccSysIntAllow
                          : false;
                      this.claims.isPolicyOnVehicleChange =
                        orgData.responseInfo[0].isPolicyOnVehicleChange;
                      this.claims.triangulationRule =
                        orgData.responseInfo[0].triangulationRule;
                      this.claims.isStripPaymentFailed =
                        orgData.responseInfo[0].isStripPaymentFailed;
                      this.claims.IsDelegateFunctionPurchasedOrTrial =
                        orgData.responseInfo[0].isDelegateFunctionPurchasedOrTrial;
                      this.claims.isDocRequired =
                        orgData.responseInfo[0].isDocRequired;
                      this.claims.BandId = orgData.responseInfo[0].bandId;
                      this.claims.IsPayStepEnabled = orgData.responseInfo[0].isPayStepEnabled;
                      this.claims.IsAPAutomationAllow = orgData.responseInfo[0].isAPAutomationAllowed;
                      this.claims.IsAutomaticScanEnabled = orgData.responseInfo[0].isAutomaticScanEnabled;
                      this.claims.isNRPackagePurchasedOrTrial = orgData.responseInfo[0].isNRPackagePurchasedOrTrial;
                      this.claims.isFCPackagePurchasedOrTrial = orgData.responseInfo[0].isFCPackagePurchasedOrTrial;
                      this.claimsService.updateClaims(this.claims);
                      this.authenticationService.setloginnav();
                      this.routeUser();
                    }
                  },
                  (error) => {
                    this.disableLoginButton = false;
                  }
                );
            })
            .catch((err) => {
              this.disableLoginButton = false;
              console.error("error logging in", err);
            });
        } else {
          this.toastr.error(data.errorInfo[0].message);
          this.checkForCaptchaVisibility();
        }
      },
      (error) => {
        this.disableLoginButton = false;
        this.toastr.error(error);
      }
    );
  }

  public resolved(captchaResponse: string): void {
    this.caprchaResult = captchaResponse;
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    this.caprchaResult = "";
  }

  checkForCaptchaVisibility() {
    var loginAttemptCount = 0;

    if (sessionStorage.getItem("CurrentUserTriedLogin")) {
      if (
        sessionStorage.getItem("CurrentUserTriedLogin") != this.username.value
      ) {
        sessionStorage.setItem("CurrentUserLoginAttempt", "0");
      }
    }

    if (sessionStorage.getItem("CurrentUserLoginAttempt")) {
      loginAttemptCount =
        Number(sessionStorage.getItem("CurrentUserLoginAttempt")) + 1;
    } else {
      loginAttemptCount = 1;
    }
    sessionStorage.setItem(
      "CurrentUserLoginAttempt",
      loginAttemptCount.toString()
    );
    sessionStorage.setItem("CurrentUserTriedLogin", this.username.value);
    this.showCaptchaMessage = loginAttemptCount > 0 && loginAttemptCount < 10;
    this.showCaptcha = loginAttemptCount >= 10;
  }

  bindVersionHistory() {
    if (!this.claims.appVersion) {
      this.versionHistoryService.getVersionHistory().subscribe((res: any) => {
        if (res.statusCode == 0) {
          let currentVersion = res.responseInfo.filter(
            (x) => x.isCurrentRelease
          )[0].versionNumber;

          this.claims.appVersion = currentVersion;
          this.claimsService.updateClaims(this.claims);
        }
      });
    }
  }

  routeUser() {

    if (this.claims.IsFinanceManager == true)
      this.router.navigate([""]);
    else {
      if (this.claims.orgRoleId == UserTypeEnum.Approver) {
        this.router.navigate(["/approver/appclaimant"]);
      } else {
        if (
          this.claims.IsDelegateFunctionPurchasedOrTrial &&
          this.claims.IsDelegate
        ) {
          this.router.navigate(["claimant/delegatorsexpense"]);
        } else {
          this.router.navigate(["claimant/expenselist"]);
        }
      }
    }
  }
  gotoSignUp() {
    //this.gotoNextStepEvent.emit(2);
    this.router.navigate(["register"]);
  }

  @ViewChild("emailField") emailField: ElementRef<HTMLInputElement>;
  @ViewChild("passwordField") passwordField: ElementRef<HTMLInputElement>;

  ngAfterViewInit() {
    this.emailField.nativeElement.focus();
  }
}
