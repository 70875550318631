

export interface IProjectBudgetListRequest {
    organisationId: number;
    status: number;
    pageNo: number;
    sortOrder: number;
    sortColumn: string;
    searchText: string;
    entityType: number;
}

export class ProjectBudgetListRequest implements IProjectBudgetListRequest {
    organisationId: number;
    status: number;
    pageNo: number;
    sortOrder: number;
    sortColumn: string;
    searchText: string;
    entityType: number;
    constructor(organisationId: number, status: number, pageNo: number, sortOrder: number, sortColumn: string, searchText: string,
        entityType: number) {
        this.organisationId = organisationId;
        this.status = status;
        this.pageNo = pageNo;
        this.sortOrder = sortOrder;
        this.sortColumn = sortColumn;
        this.searchText = searchText;
        this.entityType = entityType;
    }
}

export interface IProjectBudgetGridResponse {
    totalBudget: number,
    actualSpent: number,
    available: number,
    projectsCounts: number,
    overUnder: string,
    projectBudgetDetailsId: number,
    entityId: number,
    entityName: string,
    entityType: number,
    organisationId: number,
    userId: number,


    updatedOnForSort: Date;

    isRowHighlighted: boolean;
    selected: boolean;
}

export class ProjectBudgetGridResponse implements IProjectBudgetGridResponse {
    totalBudget: number;
    actualSpent: number;
    available: number;
    projectsCounts: number;
    overUnder: string;
    projectBudgetDetailsId: number;
    entityId: number;
    entityName: string;
    entityType: number;
    organisationId: number;
    userId: number;
    updatedOn: number;
    updatedOnForSort: Date;
    isRowHighlighted: boolean;
    selected: boolean;

}

export interface IProjectBudgetListResponse {
    projectBudgetList: IProjectBudgetGridResponse[];
    totalCount: number;
    costCentreCount: number;
    customerCount: number;
}

export class ProjectSummaryPopupModel {
    constructor(public projectBudgetDetailsId: number, public entityType: number, public entityName: string) { }
}

export class ProjectSummary {
    projectCode: string;
    projectName: string;
    projectBudget: number;
    actual: number;
    available: number;
    used: number;
    overUnder: string;
}

export class ProjectSummaryResponse {
    projectSummaryList: ProjectSummary[];
    totalBudget: number;
    totalActual: number;
    totalAvailable: number;
}

export class ProjectSummaryRequest {
    constructor(public projectBudgetDetailsId: number, public organisationId: number) { }
}

export class ProjectBudgetOperationPopupModel {
    constructor(public projectBudgetDetailsId: number) { }
}

export class GetCustomerCostCentreListRequest {
    constructor(public organisationId: number, public includeCostCentre: boolean, public includeCustomer: boolean) { }
}

export class CostCentreCustomerList {
    entityId: number;
    entityName: string;
    entityType: number;
    projectBudgetDetailsId: number;
    disabled: boolean;
    mergedEntityName: string;
}

export class GetActiveEmployeesForProjectBudgetRequest {
    constructor(public organisationId: number) { }
}

export class GetActiveEmployeesForProjectBudgetResponse {
    userId: number;
    userName: string;
    loginId: string;
    orgRoleId: number;
    mergedUserName: string;
}

export class GetProjectBudgetAllDetailsByProgBudIdRequest {
    constructor(public projectBudgetDetailsId: number, public organisationId: number) {

    }
}

export class BudgetDetailsResponse {
    budgetDetailsId: number;
    projectBudgetDetailsId: number;
    totalBudget: any;
    status: number;
    approvalRule: number;
    overallSpendAlertAmount: any;
    overallSpendEMailToUserId: number;
    overallSpendEMailSent: boolean;
    organisationId: number;
}

export class ProjectDetailsResponse {
    projectDetailsId: number;
    projectBudgetDetailsId: number;
    projectCode: string;
    projectName: string;
    projectManagerId: number;
    projectBudget: number;
    isBillable: boolean;
    status: number;
    organisationId: number;
    isProjectContainsExpense: boolean;
    projectAlertDetails: ProjectAlertDetailsResponse[];
    projectAlertRecipients: ProjectAlertRecipientsResponse[];
    updatedBy: number;
    updatedOn: number;
    closedType: number;
    spentAmount: number;
}

export class ProjectAlertDetailsResponse {
    projectAlertDetailsId: number;
    projectDetailsId: number;
    expenseCategoryId: number;
    expenseCategoryBudget: number;
    alert1: number;
    alert2: number;
    alert3: number;
    alert1EmailSent: boolean;
    alert2EmailSent: boolean;
    alert3EmailSent: boolean;
    organisationId: number;
}

export class ProjectAlertRecipientsResponse {
    projectAlertRecipientsId: number;
    projectDetailsId: number;
    recipientsUserId: number;
    organisationId: number;
}

export class ProjectBudgetResponse {
    projectBudgetDetailsId: number;
    entityId: number;
    entityName: string;
    entityType: number;
    organisationId: number;
    userId: number;
    createdBy: number;
    createdOn: number;
    updatedBy: number;
    updatedOn: number;
    budgetDetails: BudgetDetailsResponse;
    projectDetails: ProjectDetailsResponse[];
}

export class ProjectCategorySummaryRequest {
    constructor(public projectBudgetDetailsId: number, public organisationId: number) { }
}

export class CategorySummaryDetailsResponse {
    expenseCategoryId: number;
    expenseCategoryBudget: number;
    expenseCategoryName: string;
    expenseCategoryActual: number;
    expenseCategoryAvailable: number;
    overUnder: string;
    used: number;
}

export class CategorySummaryResponse {
    projectDetailsId: number;
    projectCode: string;
    projectName: string;
    projectManagerId: number;
    projectManagerName: string;
    projectBudget: number;
    categorySummaryDetailsResponse: CategorySummaryDetailsResponse[];
}

export class ProjectBudgetForEntity {
    entityId: number;
}

export class GetProjectBudgetByEntityTypeRequest {

    constructor(public organisationId: number, public entityTypeId: number) { }
}

export class GetProjectManagerCountRequest {
    constructor(public organisationId: number) { }
}

export class ProjectManagerDetails {
    projectManagerId: number;
    projectCount: number;
}

export class UpdateProjectManagerRequest {
    constructor(public oldApproverId: number, public newApproverId: number, public organisationId: number, public claimantsIds: number[]) { }
}

export class GetProjectListForCloseModel {
    projectBudgetDetailsId: number;
    projectDetailsId: number;
    entityName: string;
    entityType: string;
    projectName: string;
    projectCode: string;
    projectBudget: number;
    projectManager: string;
    status: string;
    selected: boolean;
    updatedOn: number;
    isRowHighlighted: boolean;
    tempStatus: string;
    closedType: number;
    spentAmount: number;
}

export class GetProjectListForCloseResponse {
    projectList: GetProjectListForCloseModel[];
    totalProjectsCount: number;
    totalCustomersProjectsCount: number;
    totalCostCentreProjectsCount: number;
    totalOpenProjectsCount: number;
    totalClosedProjectsCount: number;
}

export class CloseProjectRequest {
    constructor(public projectBudgetDetailsId: number, public projectDetailsId: number, public organisationId: number, public userId: number, public closedType: number, public spentAmount: number, public userName: string) { }
}




export interface IGetCustomerCostCentreProjectsListRequest {
    organisationId: number;
    userId: number;
    includeCostCentre: boolean;
    includeCustomer: boolean;
}

export class GetCustomerCostCentreProjectsListRequest implements IGetCustomerCostCentreProjectsListRequest {
    organisationId: number;
    userId: number;
    includeCostCentre: boolean;
    includeCustomer: boolean;
    constructor(organisationId: number, userId: number, includeCostCentre: boolean, includeCustomer: boolean) {
        this.organisationId = organisationId;
        this.userId = userId;
        this.includeCostCentre = includeCostCentre;
        this.includeCustomer = includeCustomer;
    }
}

export interface ICostCentreCustomerProjectListResponse {
    customerCostCentreProjectList: CostCentreCustomerProjectList[];
}

export class CostCentreCustomerProjectListResponse implements ICostCentreCustomerProjectListResponse {
    customerCostCentreProjectList: CostCentreCustomerProjectList[];
}

export class CostCentreCustomerProjectList {
    entityId: number;
    entityName: string;
    entityCode:string;
    entityType: number;
    isHomeCostCentre: boolean;
    projectBudgetDetailsId: number;
    projectList: ProjectInfo[]
}

export class ProjectInfo {
    projectDetailsId: number;
    projectCode: string;
    projectName: string;
}