import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-log-icon',
  templateUrl: './log-icon.component.html',
  styleUrls: ['./log-icon.component.scss']
})
export class LogIconComponent implements OnInit {
  public param: string;
  constructor() { }

  ngOnInit() {
  }

}
