import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { Claims } from "src/app/_models/common/claims";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Location } from "@angular/common";
import { BaseComponent } from "src/app/shared/base/base-component";
import { ApiRequest, IApiresponse } from "src/app/_models";
import { AlertService } from "src/app/alert-panel/alert.service";
import { environment } from "src/environments/environment";
import { SetupPayStepRequest } from "src/app/_models/Dashboard/pay-step";
import {DashboardService} from "src/app/_services/common/dashboard.service";
import { DashboardExpenseStatusSummary } from "src/app/_models/Dashboard/dashboard-expense-status-summary";
import { DasboardExpenseStatusEnum, LogObjectActionTemplateEnum} from "src/app/_models/enum/solo-enum";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { DynamicFormComponent } from "src/app/shared/forms/dynamic-form/dynamic-form.component";
import { DynamicFormService } from "src/app/shared/forms/dynamic-form/dynamic-form.service";
import { GetField } from "src/app/shared/forms/form-functions/form-functions";
import { LoggingPanelModel } from "src/app/_models/common/LoggingPanelModel";
import { LoggingPanelService } from "src/app/_services/logging-panel/logging-panel.service";

@Component({
    selector: "setup-pay-step-panel",
    templateUrl: "./setup-pay-step-panel.component.html"
})

export class SetupPayStepPanelComponent extends BaseComponent implements OnInit {
    @ViewChild(DynamicFormComponent) setupPayStepForm: DynamicFormComponent;
    @Input() data: any;
    @Output() closePanelEvent = new EventEmitter<boolean>();
    @Output() submitEvent = new EventEmitter<any>();

    claims: Claims;
    disableSaveButton: boolean = false;
    isOverlayRequired: boolean= true;
    showPendingItemsAlert: boolean= false;

    setupPayStepFormModel: SetupPayStepRequest;
    setupPayStepFormFields: FormlyFieldConfig[] = [];

    incompleteExpenses: DashboardExpenseStatusSummary;
    pendingExpensesWithApprover: DashboardExpenseStatusSummary;
    pendingExpensewithFA: DashboardExpenseStatusSummary;
    passedForPayment: DashboardExpenseStatusSummary;
    pendingPassedForPayment: DashboardExpenseStatusSummary;

    pendingCountFinancePayment: number = 0;
    pendingCountAdvancePay: number = 0;
    pendingCountInvoicePay: number = 0;

    financeApprovalPendingCount: number = 0;
    financePayPendingCount: number = 0;

    isPayStepEnabled: boolean = false;

    constructor(
        private toastr: ToastrService,
        private claimsService: ClaimsService,
        private router: Router,
        private location: Location,
        private translate: TranslateService,
        private alertService: AlertService,
        private dynamicFormService: DynamicFormService,
        private dashboardService: DashboardService,
        private loggingPanelService: LoggingPanelService
    ) {
        super();
        this.claimsService.currentClaims.subscribe((claims) => {
            this.claims = claims;
            this.isPayStepEnabled = this.claims.IsPayStepEnabled;
        });

        this.observeUnsavedChangesAlerts();
    }

    ngOnInit() {
      this.fnGetOrgExpenseDetails();
      this.fnInitializeForm();
    }

    fnClosePanel() {
        if (this.canDeactivate()) {
            this.closePanelEvent.emit(true);
        }
        else {
            this.alertService.onFormClosed();
        }
    }

    canDeactivate(): boolean {
        return !this.setupPayStepForm.form.dirty;
    }

    observeUnsavedChangesAlerts() {
        var currentContext = this;
       let userActionEventSubscription = this.alertService.getUserActionEvent().subscribe(action => {
            if (action) {
                this.closePanelEvent.emit(true);
            }
        });
    }

    fnInitializeForm() {

        let fields: FormlyFieldConfig[] = [];

        this.setupPayStepFormModel = new SetupPayStepRequest();
        this.setupPayStepFormModel.isPayStepEnabled = this.claims.IsPayStepEnabled;

        fields.push({
            fieldGroupClassName: 'row border-bottom',
            fieldGroup: [
                {
                    key: 'isPayStepEnabled',
                    type: 'formly-left-text-with-toggle',
                    className: 'margin-bottom-10',
                    templateOptions: {
                         label: this.translate.instant("setup_pay_step_subtitle"),
                         enableBigToggle: true
                    }
                }
            ]
        });

        this.setupPayStepFormFields = fields;
    }

    fnGetOrgExpenseDetails(){

        let dashboardExpenseStatusSummary = this.data.dashboardExpenseStatusSummary;
        let approvalSummary = this.data.approvalSummary;

        if (dashboardExpenseStatusSummary != null && dashboardExpenseStatusSummary.length != 0) 
        {
            this.incompleteExpenses = dashboardExpenseStatusSummary.find((x) => x.status == DasboardExpenseStatusEnum.Incomplete);
            this.pendingExpensesWithApprover = dashboardExpenseStatusSummary.find((x) => x.status == DasboardExpenseStatusEnum.PendingWithApprover);
            this.pendingExpensewithFA = dashboardExpenseStatusSummary.find((x) =>x.status == DasboardExpenseStatusEnum.PendingFinanceApproval);
            this.passedForPayment = dashboardExpenseStatusSummary.find((x) => x.status == DasboardExpenseStatusEnum.PassedForPayment);
            this.pendingPassedForPayment = dashboardExpenseStatusSummary.find((x) =>x.status == DasboardExpenseStatusEnum.PendingPassforPayment);
        }

        if(approvalSummary){
            this.pendingCountFinancePayment = approvalSummary.pendingCountFinancePayment;
            this.pendingCountAdvancePay = approvalSummary.pendingCountAdvancePay;
            this.pendingCountInvoicePay = approvalSummary.pendingCountInvoicePay;

            this.financeApprovalPendingCount =
            approvalSummary.pendingCountFinanceApproval +
            approvalSummary.pendingCountNRFinanceApproval +
            approvalSummary.pendingCountFCFinanceApproval +
            approvalSummary.pendingCountAdvanceFinanceApproval +
            approvalSummary.pendingCountInvoiceFinanceApproval;
  
            this.financePayPendingCount =
                approvalSummary.pendingCountFinancePayment +
                approvalSummary.pendingCountAdvancePay +
                approvalSummary.pendingCountInvoicePay;
        }
    }
    
    fnSubmitConfiguration(){
        let financePayPendingCount = this.pendingCountFinancePayment + this.pendingCountAdvancePay + this.pendingCountInvoicePay;
        
        if(!this.setupPayStepFormModel.isPayStepEnabled && financePayPendingCount > 0){
            this.showPendingItemsAlert = true;
        }
        else{
            this.fnSetupPayStep();
        }
    }

    fnSetupPayStep() {

        this.disableSaveButton = true;

        let request = new ApiRequest<SetupPayStepRequest>("SetupPayStep", environment.applicationVersion, environment.requestId);

        let requestInfo = new SetupPayStepRequest();
        requestInfo.isPayStepEnabled = this.setupPayStepFormModel.isPayStepEnabled;
        requestInfo.organisationId = this.claims.OrgId;
        requestInfo.financeManager = this.claims.FinanceManager;
        requestInfo.userId = this.claims.UserId;

        request.requestInfo = requestInfo;

        this.dashboardService.SetupPayStep(request)
            .subscribe((response: IApiresponse<any>) => {
                if (response && response.responseInfo) {
                   this.submitEvent.emit(true);
                }
                this.disableSaveButton = false;
                this.claims.IsPayStepEnabled = this.setupPayStepFormModel.isPayStepEnabled;
                this.claimsService.updateClaims(this.claims);
            });
    }

    fnShowPendingItemsAlert(){
        this.isOverlayRequired = true;
    }

    fnPendingItemsAlertReceived(returnValue) {
        if(returnValue){
            this.showPendingItemsAlert = false;
            this.fnSetupPayStep();
        }
        else{
            this.showPendingItemsAlert = false;
        }
      
    }

    fnShowLoggingPanel() {
        const model = new LoggingPanelModel(
            this.claims.OrgId.toString(),
            LogObjectActionTemplateEnum.SetupPayStep.toString(),
            "0"
        );
        this.loggingPanelService.onShowLogPanel(model);
    }

}
