import { OnInit } from "@angular/core";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { ClaimsHeaderService } from "src/app/shared/claimsHeader/claims-header.service";

@Injectable({
  providedIn: "root",
})
export class VersionHistoryService implements OnInit {
  private versionHistoryUrl = `${environment.catalogApiUrl}GetVersionHistory`;
  constructor(
    private http: HttpClient,
    private claimsHeaderService: ClaimsHeaderService
  ) { }
  ngOnInit() { }

  getVersionHistory() {
    return this.http.get<any[]>(this.versionHistoryUrl, {
      headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
    });
  }
}
