export interface IRequestSaveFxRateSetting {
    fxRateRule:number;
    fxRateSubRule:number;
}

export class RequestSaveFxRateSetting implements IRequestSaveFxRateSetting{
    fxRateRule:number;
    fxRateSubRule:number;
}

export interface IFxRateConfiguration{
    fxRateId:number;
    currencyId:number;
    name:number;
    currencyCode:string;
    currencyRate:number;
    effectiveFrom:number;
    modifiedOn:number;
}

export interface ICurrencyFetchRequest
{
    expenseDate:number;
    isForeignCurrency:boolean;
}

export class CurrencyFetchRequest implements ICurrencyFetchRequest
{
    expenseDate:number;
    isForeignCurrency:boolean;
}

export class FxRateConfiguration implements IFxRateConfiguration{
    fxRateId:number;
    currencyId:number;
    name:number;
    currencyCode:string;
    currencyRate:number;
    effectiveFrom:number;
    modifiedOn:number;
}

export interface ICurrencyConfiguration
{
    currencyWithRate:number;
    currencyWithoutRate:number;
    lstRateConfigurations:IFxRateConfiguration[];
}

export class CurrencyConfiguration implements ICurrencyConfiguration
{
    currencyWithRate:number;
    currencyWithoutRate:number;
    lstRateConfigurations:IFxRateConfiguration[];
}

export interface IExpiredRateConfiguration
{
    currencyId:number;
    currencyCode:string;
    expiredRatesCount:number;
    lstRateConfigurations:IFxRateConfiguration[];
}

export class IExpiredRateConfiguration implements IExpiredRateConfiguration
{
    currencyId:number;
    currencyCode:string;
    expiredRatesCount:number;
    lstRateConfigurations:IFxRateConfiguration[];
}


export interface IRespFxRateConfiguration
{
    currentCurrencyConfig : ICurrencyConfiguration;
    futureCurrencyConfig: ICurrencyConfiguration;   
    expiredCurrencyConfig:IExpiredRateConfiguration[];
}

export class RespFxRateConfiguration implements IRespFxRateConfiguration
{
    currentCurrencyConfig : ICurrencyConfiguration;
    futureCurrencyConfig: ICurrencyConfiguration;    
    expiredCurrencyConfig:IExpiredRateConfiguration[];
}

export interface IRequestUpdateFxRateSetup{
  fxRateDetails : ICurrencyRequest[];
  fmId:number;
  fmName:string;
}

export class RequestUpdateFxRateSetup implements IRequestUpdateFxRateSetup{
   fxRateDetails : ICurrencyRequest[];
   fmId:number;
  fmName:string;
}

export interface IFxRateExpenseCounter 
{
    fxRateId:number;
    expenseCount:number;
}

export class FxRateExpenseCounter implements IFxRateExpenseCounter
{
    fxRateId:number;
    expenseCount:number;
}

export interface IResponseExpenseFxRateCounter
{
    lstFxRateExpenseCounter: IFxRateExpenseCounter[];
}

export class ResponseExpenseFxRateCounter implements IResponseExpenseFxRateCounter
{
    lstFxRateExpenseCounter: IFxRateExpenseCounter[];
}

export interface ICurrencyRequest {
    fxRateId:number;
    orgId:number;
    currencyId:number;
    currencyRate:number;
    effectiveFrom:number;
    isImported:boolean;
    createdBy:number;
    createdOn:number;
    updatedBy:number;
    updatedOn:number;    
}

export class CurrencyRequest implements ICurrencyRequest {
    fxRateId:number;
    orgId:number;
    currencyId:number;
    currencyRate:number;
    effectiveFrom:number;
    isImported:boolean;
    createdBy:number;
    createdOn:number;
    updatedBy:number;
    updatedOn:number;        
}

export interface ICurrencyResponse {
    
}

export class CurrencyResponse implements ICurrencyResponse {
    
}

export interface IBulkSaveCurrenciesRequest {
    organisationId: number;
    createdBy: number;
    fmName: string;
    fmEmail: string;
    fmUserId: number;
    currencyList: ICurrencyRequest[];
}

export class BulkSaveCurrenciesRequest implements IBulkSaveCurrenciesRequest {
    organisationId: number;
    createdBy: number;
    fmName: string;
    fmEmail: string;
    fmUserId: number;
    currencyList: ICurrencyRequest[];
    constructor(
        organisationId: number,
        createdBy: number,
        fmName: string,
        fmEmail: string,
        fmUserId: number,
        currencyList: ICurrencyRequest[]
    ) {
        this.organisationId = organisationId;
        this.createdBy = createdBy;
        this.fmName = fmName;
        this.fmEmail = fmEmail;
        this.fmUserId = fmUserId;
        this.currencyList = currencyList;
    }
}



export interface IBulkSaveCurrencyResponse {
    OrganisationId: number;
    TotalRecords: number;
    FailedRecords: number;
    PassedRecords: number;
    CurrencyList: ICurrencyResponse[];
}
