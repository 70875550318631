import { HostListener, Component } from "@angular/core";

@Component({
    selector: 'base-component',
    template: '<div></div>'
})
export abstract class BaseComponent {

    abstract canDeactivate(): boolean;

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        if (!this.canDeactivate()) {
            $event.returnValue = true;
        }
    }
}