import { OnInit, Component, EventEmitter, Input, Output, ChangeDetectorRef } from "@angular/core";
import { IApiresponse } from "src/app/_models";
import { HowDoIVideoLinks } from "src/app/common/commonvariables";
import { SelectBusinessPurposePopupModel } from "src/app/_models/claimant-on-web/claimant-on-web";
import { PolicyDataService } from 'src/app/_services/policy/policy-data.service';
import { IRespGetBusinessPurposeValues, IBPValues } from 'src/app/_models/policy/policy-data';

@Component({
    selector: "select-business-purpose-panel",
    templateUrl: "./select-business-purpose-panel.component.html"
})
export class SelectBusinessPurposePanelComponent implements OnInit {

    @Input()
    inputData: SelectBusinessPurposePopupModel;

    @Output() closePanelEvent = new EventEmitter<boolean>();
    @Output() selectItemEvent = new EventEmitter<SelectBusinessPurposePopupModel>();

    param: any;
    howDoIVideoLinks = HowDoIVideoLinks;
    searchText: string = '';

    bpValuesList = <IBPValues[]>[];
    selectedBpId: number = 0;
    businessPurposeCaption: string = '';

    constructor(private policyService: PolicyDataService, private cd:ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.fnGetBusinessPurposeValues();
        this.fnSetBusinessPurposeInputValues();
    }

    fnClosePanel() {
        this.closePanelEvent.emit(true);
    }
    fnGetBusinessPurposeValues() {
        this.policyService.GetBusinessPurposeValues()
            .subscribe((response: IApiresponse<IRespGetBusinessPurposeValues>) => {
                if (response.responseInfo != null) {
                    this.bpValuesList = response.responseInfo.bpValues;
                    this.fnGetSelectedBpValueOnTop();
                }
            });
    }

    fnSelectBusinessPurposeValues(item: IBPValues) {
        if (item) {
            let selectBusinessPurposeOutputModel = new SelectBusinessPurposePopupModel();
            selectBusinessPurposeOutputModel.bpId = item.bpId;
            selectBusinessPurposeOutputModel.bpValue = item.bpValue;
            this.selectItemEvent.emit(selectBusinessPurposeOutputModel);
        }
    }
    fnSetBusinessPurposeInputValues() {
        if (this.inputData) {
            this.selectedBpId = this.inputData.bpId;
            this.businessPurposeCaption = this.inputData.businessPurposeCaption;
        }
    }

    fnGetSelectedBpValueOnTop() {
        let bpId = this.inputData && this.inputData.bpId ? this.inputData.bpId : 0;

        if (bpId && this.bpValuesList && this.bpValuesList.length > 0) {
            const sortedArr = this.bpValuesList.reduce((acc, element) => {
                if (element.bpId == bpId) {
                    return [element, ...acc];
                }
                return [...acc, element];
            }, []);
            this.bpValuesList = sortedArr;
        }
        this.cd.detectChanges();
    }

}
