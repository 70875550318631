export class ExpenseCategoryRequest {
  WebApiName: string;
  WebApiVersion: number;
  RequestId: number;
  RequestInfo: ExpenseCategoryRequestInfo;
}

export class ExpenseCategoryRequestInfo {
  OrgId: number;
  LastUpdatedTimeStamp: number;
  IsForCreditCardExpense: boolean;
  UserId:number;
}

export class ExpenseCategoryInExpenseRequest {
  WebApiName: string;
  WebApiVersion: number;
  RequestId: string;
  RequestInfo: number;
}


