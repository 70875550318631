import { OnInit, Component, ViewChild, Output, EventEmitter, Input, OnChanges } from "@angular/core";
import { Subscription } from "rxjs";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { TranslateService } from "@ngx-translate/core";
import { Claims } from "src/app/_models/common/claims";
import { HowDoIVideoLinks } from "src/app/common/commonvariables";
import { AddUpdateEmployeeRequestDto, AddUpdateBulkEmployeeRequestDto } from "src/app/_models/manage-emp/manage-emp";
import { ManageempService } from "src/app/_services/manage-emp/manage-emp.service";
import { AppGridComponent } from "src/app/shared/grid/grid-component/grid.component";
import { ColDef } from "ag-grid-community";
import { CountByStatus, GridConfig } from "src/app/_models/common/grid/grid-config";
import { NgOption } from "@ng-select/ng-select";
import { EmployeeStatusEnum } from "src/app/_models/common/manage-emp-enum.enum";
import { ToastrService } from "ngx-toastr";
import { AssignCostCentreApprover, SaveCostCentreApproverRequest } from "src/app/_models/costCentre/costCentre";
import { CostCentreService } from "src/app/_services";
import { BaseComponent } from "src/app/shared/base/base-component";
import { AlertService } from "src/app/alert-panel/alert.service";
import { first } from "rxjs/operators";

enum ItemType {
    Approvers = 1,
    ApproversForCC = 2,
    ClaimantsForCC = 3,
    ApproversForExpense = 4
}

@Component({
    selector: "employee-status-panel",
    templateUrl: "./employee-status-panel.component.html"
})
export class EmployeeStatusPanelComponent extends BaseComponent implements OnInit, OnChanges {

    @ViewChild(AppGridComponent) gridComponent: AppGridComponent;

    @Output() closePanel = new EventEmitter<boolean>();
    @Output() onRemoveApprover = new EventEmitter<number[]>();
    @Input() employeesList: any = [];
    @Input() itemType: number;
    @Input() costCentre: string;
    @Input() costCentreCode: string;
    @Input() costCentreId: number;

    userActionEventSubscription: Subscription;
    claimsSubscription: Subscription;
    claims: Claims;
    currentUser: any;
    videoLink = HowDoIVideoLinks.SetupOrganisation;

    defaultSortColumn = 'displayName';
    gridData: any[] = [];
    gridColumnDefs: ColDef[];
    gridConfigItems: GridConfig;
    gridFilterStatus: number = 0;
    gridFilterItems: NgOption[] = [];

    successResponseMsg: string[] = [this.translate.instant('success'), this.translate.instant('invite_sent_success'), this.translate.instant('invite_resent_success')];
    inviteButtonText: string = '';
    inviteButtonClass: string = 'fal fa-envelope color-white';
    panelHeading: string;
    isSaved: boolean = false;
    showCostCentreListPanel: boolean = false;
    selectedApproverData: any = null;

    removedApprovers: any[] = [];
    hasFormChanges: boolean = false;
    hideSave: boolean = false;
    disableSave: boolean = true;
    ccDisplaySetting:number=0;
    constructor(
        private claimsService: ClaimsService,
        private translate: TranslateService,
        private manageEmpService: ManageempService,
        private costCentreService: CostCentreService,
        private alertService: AlertService,
        private toastr: ToastrService
    ) {
        super();
        this.claimsSubscription = this.claimsService.currentClaims.subscribe((claims) => {
            this.claims = claims;
        });
        this.userActionEventSubscription = this.alertService.getUserActionEvent().subscribe(action => {
            if (action) {
                this.resetFields();
                this.closePanel.emit(false);
            }
        });
        this.getCustomerCCInfo();
    }

    ngOnInit(): void {
        this.fnInitialiseEmployeesGrid();
    }

    getCustomerCCInfo() {
        this.costCentreService.GetCustomerCCInfo()
            .pipe(first())
            .subscribe(
                data => {
                    if(data.statusCode==0)
                    {                
                    this.ccDisplaySetting = data.responseInfo.ccDisplaySetting;    
                    }
                }); 
                
    }

    getPanelHeading(ccDisplaySetting, panelHeading, costCentreName, costCentreCode)
    {
        if (ccDisplaySetting==1) {
            return panelHeading.replace('{0}', costCentreName+' / '+ costCentreCode);            
        } 
        else if (ccDisplaySetting==2) 
        { 
            return panelHeading.replace('{0}', costCentreCode+' / '+costCentreName);            
        } 
        else if (ccDisplaySetting==3) 
        { 
            return panelHeading.replace('{0}', costCentreCode+' - '+costCentreName);            
        }
        else if (ccDisplaySetting==4) {
            return panelHeading.replace('{0}', costCentreName +' - '+costCentreCode);            
        }
        else if (ccDisplaySetting==5) {
            return panelHeading.replace('{0}', costCentreCode);            
        }
        else if (ccDisplaySetting==6) {
            return panelHeading.replace('{0}', costCentreName);            
        }
        else
            return panelHeading.replace('{0}', costCentreName +' - '+costCentreCode);        
    }

    ngOnDestroy() {
        this.claimsSubscription.unsubscribe();
        this.userActionEventSubscription.unsubscribe();
    }

    canDeactivate() {
        return !this.hasFormChanges;
    }

    ngOnChanges(changes) {
        if (this.itemType == ItemType.Approvers) {
            this.panelHeading = this.translate.instant('label_approver_status');
        }
        else if (this.itemType == ItemType.ApproversForCC) {
            this.panelHeading = this.getPanelHeading(this.ccDisplaySetting,this.translate.instant('label_approver_details'),this.costCentre,this.costCentreCode);            
        }
        else if (this.itemType == ItemType.ClaimantsForCC) {
            this.panelHeading = this.getPanelHeading(this.ccDisplaySetting,this.translate.instant('label_claimant_details'),this.costCentre,this.costCentreCode)
        }
        else if (this.itemType == ItemType.ApproversForExpense) {
          this.panelHeading = this.translate.instant('approver_details');
        }

        if (this.itemType == ItemType.Approvers) {
          this.gridFilterItems = [
              { id: EmployeeStatusEnum.Pending, name: this.translate.instant("label_pending") },
              { id: EmployeeStatusEnum.NotInvited, name: this.translate.instant("label_notinvited") }
          ];
        }
        else {
            this.gridFilterItems = [
              { id: 0, name: this.translate.instant("all") },
              { id: EmployeeStatusEnum.Pending, name: this.translate.instant("label_pending") },
              { id: EmployeeStatusEnum.NotInvited, name: this.translate.instant("label_notinvited") }
          ];
        }

        this.gridFilterStatus = this.gridFilterItems[0].id;

        this.updateGridColumnDefs();

        this.hideSave = this.itemType != ItemType.ApproversForCC;
        this.disableSave = true;
    }

    updateGridColumnDefs() {
        if (this.gridComponent) {
            this.fnCreateColumnDefs();
            this.fnGetGridConfigItems();
            this.gridComponent.updateGridColumnDefinitions(this.gridColumnDefs);
            this.fnGridFilterChanged(this.gridFilterStatus);
        }
    }

    fnCreateColumnDefs() {
        if (this.itemType == ItemType.ApproversForCC) {
            this.gridColumnDefs = [
                {
                    headerName: this.translate.instant('deputy_approver_name'),
                    field: 'displayName',
                    resizable: true,
                    sortable: true,
                    suppressMenu: true,
                    minWidth: 100,
                },
                {
                    headerName: this.translate.instant('approving_for'),
                    field: 'costCentresData',
                    resizable: true,
                    sortable: true,
                    suppressMenu: true,
                    minWidth: 100,
                    cellRenderer: 'formattedTextRenderer',
                    headerComponentParams: {
                        class: 'd-inline-block text-logo-blue cursor-pointer text-underline lH-12'
                    }
                },
                {
                    headerName: this.translate.instant('label_email'),
                    field: 'email',
                    resizable: true,
                    sortable: true,
                    suppressMenu: true,
                    minWidth: 100,
                },
                {
                    headerName: this.translate.instant('text_cost_centre'),
                    field: 'costCentreName',
                    resizable: true,
                    sortable: true,
                    suppressMenu: true,
                    minWidth: 100
                },

                {
                    headerName: this.translate.instant('cost_centre_code'),
                    field: 'costCentreCode1',
                    resizable: true,
                    sortable: true,
                    suppressMenu: true,
                    minWidth: 100
                },

                {
                    headerName: this.translate.instant('status_text'),
                    field: 'status',
                    resizable: true,
                    sortable: true,
                    suppressMenu: true,
                    cellRenderer: 'fieldValueRenderer',
                    minWidth: 100,
                    headerComponentParams: [
                        { class: 'active-color font-weight-bold', value: EmployeeStatusEnum.Active, text: this.translate.instant("label_active") },
                        { class: 'pending-color font-weight-bold', value: EmployeeStatusEnum.Pending, text: this.translate.instant("label_pending") },
                        { class: 'not-invited-color font-weight-bold', value: EmployeeStatusEnum.NotInvited, text: this.translate.instant("label_notinvited") },
                        { class: 'inactive-color font-weight-bold', value: EmployeeStatusEnum.Inactive, text: this.translate.instant("label_inactive") },
                    ]
                },
                {
                    headerName: "",
                    valueGetter: 'data.employeeID',
                    cellRenderer: 'actionRenderer',
                    field: 'userActionField',
                    suppressMovable: true,
                    suppressMenu: true,
                    sortable: false,
                    minWidth: 10,
                    headerComponentParams:
                        [
                            {
                                buttonClass: 'fas fa-envelope',
                                buttonTooltip: this.translate.instant('label_resend_invite'),
                                userAction: 1,
                                hideProperty: 'hideResendInvite'
                            },
                            {
                                buttonClass: 'fas fa-envelope',
                                buttonTooltip: this.translate.instant('label_invite'),
                                userAction: 1,
                                hideProperty: 'hideSendInvite'
                            },
                            {
                                buttonClass: 'fas fa-trash-alt color-red',
                                buttonTooltip: this.translate.instant('remove_approver_cc'),
                                userAction: 2
                            }
                        ]
                }
            ];
        }
        else if (this.itemType == ItemType.Approvers || this.itemType == ItemType.ApproversForExpense) {
            this.gridColumnDefs = [
                {
                    headerName: this.translate.instant('deputy_approver_name'),
                    field: 'displayName',
                    resizable: true,
                    sortable: true,
                    suppressMenu: true,
                    minWidth: 100,
                },
                {
                    headerName: this.translate.instant('label_email'),
                    field: 'email',
                    resizable: true,
                    sortable: true,
                    suppressMenu: true,
                    minWidth: 100,
                },
                {
                    headerName: this.translate.instant('text_cost_centre'),
                    field: 'costCentreName',
                    resizable: true,
                    sortable: true,
                    suppressMenu: true,
                    minWidth: 100
                },
                 {
                    headerName: this.translate.instant('cost_centre_code'),
                    field: 'costCentreCode1',
                    resizable: true,
                    sortable: true,
                    suppressMenu: true,
                    minWidth: 100
                },
                {
                    headerName: this.translate.instant('status_text'),
                    field: 'status',
                    resizable: true,
                    sortable: true,
                    suppressMenu: true,
                    cellRenderer: 'fieldValueRenderer',
                    minWidth: 100,
                    headerComponentParams: [
                        { class: 'active-color font-weight-bold', value: EmployeeStatusEnum.Active, text: this.translate.instant("label_active") },
                        { class: 'pending-color font-weight-bold', value: EmployeeStatusEnum.Pending, text: this.translate.instant("label_pending") },
                        { class: 'not-invited-color font-weight-bold', value: EmployeeStatusEnum.NotInvited, text: this.translate.instant("label_notinvited") },
                        { class: 'inactive-color font-weight-bold', value: EmployeeStatusEnum.Inactive, text: this.translate.instant("label_inactive") },
                    ]
                },
                {
                    headerName: "",
                    valueGetter: 'data.employeeID',
                    cellRenderer: 'actionRenderer',
                    field: 'userActionField',
                    suppressMovable: true,
                    suppressMenu: true,
                    sortable: false,
                    minWidth: 10,
                    headerComponentParams:
                        [
                            {
                                buttonClass: 'fas fa-envelope',
                                buttonTooltip: this.translate.instant('label_resend_invite'),
                                userAction: 1,
                                hideProperty: 'hideResendInvite'
                            },
                            {
                                buttonClass: 'fas fa-envelope',
                                buttonTooltip: this.translate.instant('label_invite'),
                                userAction: 1,
                                hideProperty: 'hideSendInvite'
                            }
                        ]
                }
            ];
        }
        else if (this.itemType == ItemType.ClaimantsForCC) {
            this.gridColumnDefs = [
                {
                    headerName: this.translate.instant('claimant_name'),
                    field: 'displayName',
                    resizable: true,
                    sortable: true,
                    suppressMenu: true,
                    minWidth: 100,
                },
                {
                    headerName: this.translate.instant('label_email'),
                    field: 'email',
                    resizable: true,
                    sortable: true,
                    suppressMenu: true,
                    minWidth: 100,
                },
                {
                    headerName: this.translate.instant('text_cost_centre'),
                    field: 'costCentreName',
                    resizable: true,
                    sortable: true,
                    suppressMenu: true,
                    minWidth: 100
                },
                {
                    headerName: this.translate.instant('cost_centre_code'),
                    field: 'costCentreCode1',
                    resizable: true,
                    sortable: true,
                    suppressMenu: true,
                    minWidth: 100
                },
                {
                    headerName: this.translate.instant('status_text'),
                    field: 'status',
                    resizable: true,
                    sortable: true,
                    suppressMenu: true,
                    cellRenderer: 'fieldValueRenderer',
                    minWidth: 100,
                    headerComponentParams: [
                        { class: 'active-color font-weight-bold', value: EmployeeStatusEnum.Active, text: this.translate.instant("label_active") },
                        { class: 'pending-color font-weight-bold', value: EmployeeStatusEnum.Pending, text: this.translate.instant("label_pending") },
                        { class: 'not-invited-color font-weight-bold', value: EmployeeStatusEnum.NotInvited, text: this.translate.instant("label_notinvited") },
                        { class: 'inactive-color font-weight-bold', value: EmployeeStatusEnum.Inactive, text: this.translate.instant("label_inactive") },
                    ]
                },
                {
                    headerName: "",
                    valueGetter: 'data.employeeID',
                    cellRenderer: 'actionRenderer',
                    field: 'userActionField',
                    suppressMovable: true,
                    suppressMenu: true,
                    sortable: false,
                    minWidth: 10,
                    headerComponentParams:
                        [
                            {
                                buttonClass: 'fas fa-envelope',
                                buttonTooltip: this.translate.instant('label_resend_invite'),
                                userAction: 1,
                                hideProperty: 'hideResendInvite'
                            },
                            {
                                buttonClass: 'fas fa-envelope',
                                buttonTooltip: this.translate.instant('label_invite'),
                                userAction: 1,
                                hideProperty: 'hideSendInvite'
                            }
                        ]
                }
            ];
        }
    }

    fnGetGridConfigItems() {

        let filterClass = "d-inline-block cursor-pointer text-underline lH-12 ";
        let totalEmployeeCount = this.employeesList.length;
        let pendingEmployeeCount = this.employeesList.filter(item => Number(item.status) == EmployeeStatusEnum.Pending).length;
        let notInvitedEmployeeCount = this.employeesList.filter(item => Number(item.status) == EmployeeStatusEnum.NotInvited).length;

        let countByStatusArray = [];

        const empCountKey = this.itemType == ItemType.ClaimantsForCC ? 'total_claimants' : 'total_approvers';
        countByStatusArray.push(new CountByStatus("d-block", this.translate.instant(empCountKey), totalEmployeeCount, false, 0));

        if (this.itemType != ItemType.Approvers) {
            countByStatusArray.push(new CountByStatus(filterClass + "inactive-state padding-right-10 padding-left-10 border-right-2px",
                this.translate.instant('all'), totalEmployeeCount, true, 0));
        }

        countByStatusArray.push(new CountByStatus(filterClass + "pending-state padding-right-10 padding-left-10",
            this.translate.instant('label_pending'), pendingEmployeeCount, true, EmployeeStatusEnum.Pending));
        countByStatusArray.push(new CountByStatus(filterClass + "not-invited-state padding-left-10 border-left-2px",
            this.translate.instant('label_notinvited'), notInvitedEmployeeCount, true, EmployeeStatusEnum.NotInvited));

        const noDataMessage = this.itemType == ItemType.ApproversForCC ? 'no_approvers_assigned' :  'no_data_availabe';

        this.gridConfigItems = new GridConfig(
            false,
            true,
            this.defaultSortColumn,
            "employeeID",
            this.gridFilterStatus,
            false,
            countByStatusArray,
            true,
            true,
            false,
            null,
            'asc',
            40,
            noDataMessage,
            null,
            null,
            false, false,
      '',
      '',
      false
        );
    }

    fnInitialiseEmployeesGrid() {
        this.fnCreateColumnDefs();
        this.fnGetGridConfigItems();
        setTimeout(() => {
            this.gridComponent.initializeGrid();
        }, 100);
    }

    fnOnGridCellClick(params) {
        if (params.column.colId === 'userActionField') {
            switch (params.data.userAction) {
                case 1:
                    this.sendInvitation([params.data]);
                    break;
                case 2:
                    this.removeApproverFromCC(params.data);
                    break;
            }
        }
        else if (params.column.colId === 'costCentresData') {
            if (this.itemType == ItemType.ApproversForCC) {
                this.showCostCentreListPanel = true;
                this.selectedApproverData = params.data;
            }
        }
    }

    fnGridFilterChanged(status) {
        this.gridData = [];
        this.gridFilterStatus = status;
        switch (status) {
            case 0:
                this.gridData = this.employeesList;
                this.inviteButtonText = '';
                this.inviteButtonClass = '';
                break;
            case EmployeeStatusEnum.NotInvited:
                this.gridData = this.employeesList.filter(e => e.status == EmployeeStatusEnum.NotInvited);
                this.inviteButtonText = this.translate.instant('label_send_invite_to_notinvited');
                this.inviteButtonClass = 'fal fa-envelope color-white';
                break;
            case EmployeeStatusEnum.Pending:
                this.gridData = this.employeesList.filter(e => e.status == EmployeeStatusEnum.Pending);
                this.inviteButtonText = this.translate.instant('label_resend_invite_to_pending');
                this.inviteButtonClass = 'fal fa-envelope color-white';
                break;
        }

        if (this.inviteButtonText) {
            this.inviteButtonText += ' (' + this.gridData.length + ')';
        }

        this.fnGetGridConfigItems();
        setTimeout(() => {
            this.gridComponent.refreshGridData();
        }, 100);
    }

    fnClosePanel() {
        if (this.canDeactivate()) {
            if (this.removedApprovers.length > 0) {
                const removedApproverIds = this.removedApprovers.map(a => a.employeeID);
                this.onRemoveApprover.emit(removedApproverIds);
            }
            else {
                this.closePanel.emit(this.isSaved);
            }
            this.resetFields();
        }
        else {
            this.alertService.onFormClosed();
        }
    }

    removeApproverFromCC(data) {
        this.hasFormChanges = true;
        this.disableSave = false;
        if (!this.removedApprovers.find(a => a.employeeID == data.employeeID)) {
            this.removedApprovers.push({
                employeeID: data.employeeID,
                displayName: data.displayName
            });
            this.employeesList = this.employeesList.filter(e => e.employeeID != data.employeeID);
            this.fnGridFilterChanged(this.gridFilterStatus);
        }
    }

    resetFields() {
        this.hasFormChanges = false;
        this.removedApprovers = [];
        this.isSaved = false;
        this.disableSave = true;
    }

    saveApproverAssignment() {

        this.disableSave = true;
        const infoToast = this.toastr.info(this.translate.instant('request_processing_info'));

        let requestInfo: AssignCostCentreApprover[] = [];
        this.removedApprovers.forEach(approver => {
            requestInfo.push(new AssignCostCentreApprover(this.costCentreId, this.costCentre,this.costCentreCode, approver.employeeID, approver.displayName, 2));
        });

        const saveRequest = new SaveCostCentreApproverRequest(this.claims.FinanceManager, requestInfo);
        this.costCentreService.saveCostCentreApproverAssignment(saveRequest).subscribe(response => {
            if (response && response.responseInfo) {
                this.hasFormChanges = false;
                this.isSaved = true;
                this.toastr.remove(infoToast.toastId);
                this.toastr.success(this.translate.instant("success"));
            }
        });
    }

    resendInviteAll() {
        const filteredUsers = this.employeesList.filter(e => e.status == this.gridFilterStatus);
        this.sendInvitation(filteredUsers);
    }

    sendInvitation(employees) {
        if (employees && employees.length > 0) {
            this.toastr.info(this.translate.instant("request_processing_info"));
            let requestInfo = new AddUpdateBulkEmployeeRequestDto(this.claims.EmailId, this.claims.FinanceManager, this.claims.UserId, [], false);
            employees.forEach(employee => {
                requestInfo.employees.push(new AddUpdateEmployeeRequestDto(
                    employee.employeeID,
                    employee.employeeCode,
                    employee.employeeCode2,
                    employee.employeeCode3,
                    employee.employeeCode4,
                    employee.firstName,
                    employee.lastName,
                    employee.email,
                    EmployeeStatusEnum.Pending,
                    true,
                    Number(employee.employeeRole),
                    Number(employee.vehicleTypeId),
                    employee.vehicleStatus,
                    employee.vehicleSubTypeId,
                    employee.rateType,
                    employee.orgVehicleTypeMapId,
                    employee.derivedName,
                    employee.approverId,
                    '',
                    employee.costCentreId,
                    employee.costCentreName,
                    employee.costCentreCode1,
                    employee.costCentreRuleId,
                    0,
                    null,
                    null
                ));
            });
            localStorage.setItem("employeeUpdatedDateTime" + this.claims.OrgId, JSON.stringify(new Date().toUTCString()));
            this.manageEmpService.BulkAddUpdateEmployee(requestInfo).subscribe((data) => {
                if (data) {
                    if (data.responseInfo) {
                        this.toastr.success(this.translate.instant("success"));
                    } else {
                        this.fnClosePanel();
                        this.toastr.success(this.translate.instant("JobSubmittedToaster"));
                    }
                    this.isSaved = true;
                }
            });
        }
    }

    closeCostCentreListPanel(isSaved) {
        this.showCostCentreListPanel = false;
        if (isSaved) {
            this.isSaved = true;
            if (this.selectedApproverData) {
                if (!this.selectedApproverData.costCentresForApprover.find(c => c.costCentreId == this.costCentreId)) {
                    this.employeesList = this.employeesList.filter(r => r.employeeID != this.selectedApproverData.employeeID);
                    this.selectedApproverData = null;
                    this.fnGridFilterChanged(this.gridFilterStatus);
                }
            }
        }
    }

}
