import { Component, OnInit, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertPanelModel } from '../../../../_models/common/alertPanelModel';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'formly-image-uploader2',
    templateUrl: "./formly-image-uploader2.component.html"
})
export class FormlyImageUploader2Component extends FieldType implements OnInit, OnDestroy, AfterViewInit {

    fieldKey: string;
    formSubmissionSubscription: Subscription;
    isFormSubmitted: boolean = false;
    isDisabled:boolean;
    imgUrl: string = "";
    alertPanelModel: AlertPanelModel;
    showAlertPanel: boolean = false;
    fileExtensions: string[] = [".png", ".jpg", ".jpeg"];
    ifNoImage: boolean = false;
    ifNoValidation: boolean = false;
    btnText:string;
    constructor(
        private translate: TranslateService,
        private dynamicFormService: DynamicFormService, private cdRef: ChangeDetectorRef) {
        super();
        this.btnText = this.translate.instant("chage_logo");
    }

    ngOnInit() {
        if (this.field.key) {
            this.fieldKey = this.field.key.toString();
        }
        this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
            this.isFormSubmitted = data;
        });
        this.imgUrl = this.field.templateOptions.defaultLogo;
        if (this.field.templateOptions.ifNoImage) {
            this.ifNoImage = true;
        }
        if (this.field.templateOptions.ifNoValidation) {
            this.ifNoValidation = true;
        }
        if (this.field.templateOptions.btnText) {
            this.btnText = this.field.templateOptions.btnText;
        }
        if (this.field.templateOptions.disabled) {
            this.isDisabled = this.field.templateOptions.disabled;
        }
    }

    ngOnDestroy() {
        if (this.formSubmissionSubscription) {
            this.formSubmissionSubscription.unsubscribe();
        }
    }

    ngAfterViewInit() {
        this.form.valueChanges.subscribe(() => {
            this.cdRef.detectChanges()
        });
    }

    onValueChange(files: any) {
        if (files) {

            if (this.isValidFile(files[0])) {
                let reader = new FileReader();
                reader.readAsDataURL(files[0]);
                reader.onload = (event) => {
                    const img = new Image();
                    img.src = reader.result as string;
                    img.onload = () => {
                        const height = img.naturalHeight;
                        const width = img.naturalWidth;
                        if(this.ifNoValidation)
                        {
                            this.imgUrl = reader.result.toString();
                            this.formControl.setValue(this.imgUrl);
                            this.to.change(this.field, files[0]);
                            this.ifNoImage = false;

                        }
                        else
                        {
                            if (height <= 50 && width <= 160) {
                                this.imgUrl = reader.result.toString();
                                this.formControl.setValue(this.imgUrl);
                                this.to.change(this.field, files[0]);
                            } else {
                                this.showAlertPanel = true;
                                this.alertPanelModel = new AlertPanelModel(
                                    true,
                                    this.translate.instant("label_alert"),
                                    [],
                                    '',
                                    this.translate.instant("Close"),
                                    true,
                                    this.translate.instant("logo_resize_validation")
                                );
                                this.formControl.setValue(null);
                                this.to.change(this.field, files[0]);
                                this.cdRef.detectChanges();
                                return;
                            }
                        }
                        
                    };
                };
            } else {
                this.formControl.setValue(null);
                this.to.change(this.field, files[0]);
            }

        }
        setTimeout(() => {
            this.cdRef.detectChanges()
        }, 100);
    }

    fnShowAttachReceiptOptionsPanel() {
        document.getElementById('fileSecond').click();
        return false;
      }

    getSupportedExtensionsText() {
        let text = "";
        if (this.fileExtensions) {
            text = this.fileExtensions.join(' / ');
        }
        return text;
    }

    isValidFile(file: any) {
        let isValid = true;
        if (!this.checkExtension(file.name)) {
            this.showAlertPanel = true;
            this.alertPanelModel = new AlertPanelModel(
                true,
                this.translate.instant("label_alert"),
                [
                    this.translate.instant("Please_upload_the_images_only") + ' <span class="text-uppercase font-weight-bold">' + this.getSupportedExtensionsText() + '</span>'
                ],
                '',
                this.translate.instant("Close"),
                true,
                this.translate.instant("invalid_image_format")
            );
            isValid = false;
            this.cdRef.detectChanges();
            return isValid;
        }

        let mimeType = file.type;

        if (mimeType.match(/image\/*/) == null) {
            this.showAlertPanel = true;
            this.alertPanelModel = new AlertPanelModel(
                true,
                this.translate.instant("label_alert"),
                [this.translate.instant("Please_upload_the_images_only"),
                this.translate.instant("only_images_are_supported")
                ],
                '',
                this.translate.instant("Close"),
                true
                , this.translate.instant("only_images_are_supported")
            );
            isValid = false;
            this.cdRef.detectChanges();
            return isValid;
        }

        if (file.size > 200000 && !this.ifNoValidation) {
            this.showAlertPanel = true;
            this.alertPanelModel = new AlertPanelModel(
                true,
                this.translate.instant("label_alert"),
                [],
                '',
                this.translate.instant("Close"),
                true,
                this.translate.instant("file_size_exceeded")
            );
            isValid = false;
            this.cdRef.detectChanges();
            return isValid;
        }
        return isValid;
    }

    checkExtension(fileName: any) {
        return this.fileExtensions.some(el => fileName.toLowerCase().endsWith(el));
    }

    alertPanelReceiver(returnValue: any) {
        this.showAlertPanel = false;
        this.alertPanelModel = null;
        this.cdRef.detectChanges();
    }

}
