import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { Claims } from "src/app/_models/common/claims";
import { NotificationService } from "src/app/_services/common/notification.service";
import {
  NotificationGroup,
  Notification,
  NotificationActionType,
} from "src/app/_models/common/notification";
import { Subscription } from "rxjs";
import { OrganisationFunctionService } from "src/app/_services/organisation-setting/organisation-function.service";
import { RSAHelper } from "src/app/_helpers/RSAHelper";
import {
  animate,
  sequence,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { BackgroundTaskStatus, BackgroundTaskStatusEnum } from "src/app/_models/common/background-task";
import {
  ApprovalRuleSetting,
  CommonStatusEnum,
  FunctionPackageEnum,
  IImportEmployeeResponse,
  ImportEmployeesResponseEnum,
  UniversalImportTemplateEnum,
  UserTypeEnum,
} from "src/app/_models";
import { BackgroundTaskNotificationBody, BackgroundTaskNotificationHeaders } from "src/app/common/commonvariables";
import { EODDateFormatPipe } from "src/app/_helpers/pipe.module";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { CheckListData, CheckListPanelModel, StorageData } from "src/app/_models/universal-importer/universal-importer";

@Component({
  selector: "notification-panel",
  templateUrl: "./notification-panel.component.html",
  styleUrls: ["./notification-panel.component.scss"],
  animations: [
    trigger("anim", [
      transition("* => void", [
        style({
          height: "*",
          opacity: "1",
          transform: "translateX(0)",
          "box-shadow": "0 1px 4px 0 rgba(0, 0, 0, 0.3)",
        }),
        sequence([
          animate(
            ".25s ease",
            style({
              height: "*",
              opacity: ".2",
              transform: "translateX(20px)",
              "box-shadow": "none",
            })
          ),
          animate(
            ".1s ease",
            style({
              height: "0",
              opacity: 0,
              transform: "translateX(20px)",
              "box-shadow": "none",
            })
          ),
        ]),
      ]),
      transition("void => true", [
        style({
          height: "0",
          opacity: "0",
          transform: "translateX(20px)",
          "box-shadow": "none",
        }),
        sequence([
          animate(
            ".1s ease",
            style({
              height: "*",
              opacity: ".2",
              transform: "translateX(20px)",
              "box-shadow": "none",
            })
          ),
          animate(
            ".35s ease",
            style({
              height: "*",
              opacity: 1,
              transform: "translateX(0)",
              "box-shadow": "0 1px 4px 0 rgba(0, 0, 0, 0.3)",
            })
          ),
        ]),
      ]),
    ]),
  ],
})
export class NotificationPanelComponent implements OnInit, OnDestroy {
  @Output()
  notificationsCountChange = new EventEmitter<number>();

  @Output()
  closePanel = new EventEmitter();

  @Output()
  openPanel = new EventEmitter();

  claims: Claims;
  activeNotificationIds: string[] = [];
  notificationGroups: NotificationGroup[] = [];
  notificationRequestStarted: boolean = false;
  availableFunctions: any;
  currentExpandedGroupKey: string = "";
  internalGroupClicks: string[] = [];

  claimSubscription: Subscription;
  notificationSubscription: Subscription;

  NotificationActionTooltip: string;
  DownloadTooltip: string;
  ExportFailedMessage: string[] = [];
  ExportFailedDisplayText: string;
  DownloadButton: boolean = false;
  backgroundTaskNotificationHeaders = BackgroundTaskNotificationHeaders;
  constructor(
    private translate: TranslateService,
    private router: Router,
    private claimsService: ClaimsService,
    private notificationService: NotificationService,
    private organisationFunctionService: OrganisationFunctionService,
    private eodDate: EODDateFormatPipe,
    private sanitizer: DomSanitizer
  ) {
    this.NotificationActionTooltip = this.translate.instant(
      "NotificationActionTooltip"
    );
    this.DownloadTooltip = this.translate.instant("download");

    this.claimSubscription = this.claimsService.currentClaims.subscribe(
      (claims) => {
        this.claims = claims;
        if (claims && claims.UserId) {
          if (!this.notificationRequestStarted) {
            this.notificationRequestStarted = true;
            this.getNotifications();
          }
        } else {
          this.notificationRequestStarted = false;
        }
      }
    );
    this.notificationSubscription = this.notificationService
      .onNotificationRaised()
      .subscribe((notification) => {
        if (notification.functionId > 0) {
          this.addNotificationToPanel(notification, false);
        }
      });
  }

  ngOnInit() {
    this.ExportFailedMessage = this.translate
      .instant("export_expense_fail_message")
      .split(";");
  }

  ngOnDestroy() {
    this.claimSubscription.unsubscribe();
    this.notificationSubscription.unsubscribe();
  }
  currentJobId: string = '';
  
  getNotifications() {
    this.organisationFunctionService
      .getAvailableFunctionsForOrganisation(this.claims.OrgId, false)
      .subscribe((data) => {
        this.availableFunctions = data;

        this.notificationService.GetNotificationsForUser().subscribe((data) => {
          this.notificationService.startConnection();
          if (data && data.length > 0) {
            data.forEach((notification) => {
              if (notification.functionId > 0) {
                this.addNotificationToPanel(notification, true);
              }
            });

            if (
              this.notificationService.openNotificationPanel &&
              this.activeNotificationIds.length > 0
            ) {
              this.showNotificationPanel();
              this.notificationService.openNotificationPanel = false;
            }
          }
        });
      });
  }

  removeNotificationFromPanel(notification: Notification) {
    const notificationGroup = this.notificationGroups.filter(function (g) {
      return (
        g.notifications.filter(function (n) {
          return n.id == notification.id;
        }).length > 0
      );
    });
    if (notificationGroup.length > 0) {
      notificationGroup[0].notifications =
        notificationGroup[0].notifications.filter(function (n) {
          return n.id != notification.id;
        });
      this.notificationGroups = this.notificationGroups.filter(function (g) {
        return g.notifications.length > 0;
      });
    }
  }

  addNotificationToPanel(notification: Notification, isInitialLoad: boolean) {

    if (
      this.claims.orgRoleId == UserTypeEnum.FinanceManager ||
      (this.claims.orgRoleId == UserTypeEnum.Approver &&
        notification.text.header == "ApproverApprovingExpensesHeader")
    ) {
      const notificationTime = this.getDisplayTime(notification);

      notification.iconUrl = this.getIconUrl(notification);

      if (notification.action.type == NotificationActionType.BackgroundJob) {
        notification.snoozeBtnVisible = false;
        notification.actionsBtnVisible =
          notification.jobStatus != BackgroundTaskStatusEnum.NotStarted &&
          notification.jobStatus != BackgroundTaskStatusEnum.InProgress;

        if (notification.text.params.length > 3) {
          if (
            parseInt(notification.text.params[3]) == 100 &&
            notification.jobStatus == BackgroundTaskStatusEnum.NotStarted
          ) {
            notification.actionsBtnVisible = true;
            notification.jobStatus = BackgroundTaskStatusEnum.Failed;
          }
        }

        let job = this.getJobInfo(notification.jobStatus);

        if (notification.functionId == FunctionPackageEnum.ExportReport) {
          if (
            notification.text.params.length > 3 &&
            parseInt(notification.text.params[3]) == 100 &&
            notification.jobStatus == BackgroundTaskStatusEnum.InProgress
          ) {
            job.status = this.translate.instant("export_preparing_export_file");
          }
        }

        if (
          notification.jobStatus != BackgroundTaskStatusEnum.NotStarted &&
          notification.jobStatus != BackgroundTaskStatusEnum.InProgress
        ) {
          if (notification.functionId == FunctionPackageEnum.ExportReport) {
            if (notification.jobStatus == BackgroundTaskStatusEnum.Success) {
              job.status = this.translate.instant("export_job_completed");
            }
            if (notification.jobStatus != BackgroundTaskStatusEnum.Success) {
              job.status = this.translate.instant("export_failed");
              var displayFailText = '<ul class="big-blue-disc mt-2">';
              this.ExportFailedMessage.forEach((fm) => {
                displayFailText =
                  displayFailText +
                  '<li class="lH-normal padding-bottom-5 border-bottom-0"><span class="d-inline-block float-left w-2pc"><i class="fas fa-circle f-10 text-logo-blue position-relative top--1px" aria-hidden="true"></i></span><span class="d-inline-block float-left w-98pc padding-left-5">';
                displayFailText = displayFailText + fm + "</span></li>";
              });
              displayFailText = `${displayFailText} </ul>`;
            }
          }
        }


        if (displayFailText != undefined) {
          notification.displayText = displayFailText;
          notification.failNotification = true;
          notification.displayText = displayFailText + `         
         
          <small class='d-block text-logo-blue fw-600'>${notificationTime} -
            <span class="text-charcoal-black">${this.getDisplayText(
            "NotificationJobActionBy",
            notification.text.params
          )}</span>
          </small>

          <small class='d-block color-red f-12'>Note -
            <span class="text-charcoal-black">`+ this.translate.instant("export_15_mins_download") + `</span>
          </small>
        `;

        } else {
          notification.displayText = `
          ${this.getDisplayText(
            notification.text.key,
            notification.text.params
          )}
          <span class="d-block fw-700 ${job.color}">
            ${this.getDisplayText(job.status, notification.text.params)}
            <span class="d-inline-block padding-left-5"><i class="${job.icon
            }"></i></span>
          </span>
          <small class='d-block text-logo-blue fw-600'>${notificationTime} -
            <span class="text-charcoal-black">${this.getDisplayText(
              "NotificationJobActionBy",
              notification.text.params
            )}</span>
          </small>
        `;

          if (notification.jobStatus == BackgroundTaskStatusEnum.Success && notification.functionId == FunctionPackageEnum.ExportReport) {
            notification.displayText = notification.displayText + `  <small class='d-block color-red f-12'>Note -
          <span class="text-charcoal-black">`+ this.translate.instant("export_15_mins_download") + `</span>
        </small>
      `
          }
        }
      } else {
        notification.snoozeBtnVisible = true;
        notification.actionsBtnVisible = true;

        notification.displayText = `
          ${this.getDisplayText(
          notification.text.key,
          notification.text.params
        )}
          <small class='d-block text-logo-blue fw-600'>${notificationTime}</small>
        `;
      }

      let isNewNotification = false;
      const headerText = this.getDisplayText(
        notification.text.header,
        notification.text.params
      ).replace("-", "");

      const header = notification.text.header;
      const existingGroup = this.notificationGroups.filter(
        (g) => g.header === header
      );
      if (existingGroup.length > 0) {
        var existingNotification = existingGroup[0].notifications.find(
          (n) => n.id === notification.id
        );
        if (!existingNotification) {
          isNewNotification = true;
          existingGroup[0].notifications.push(notification);
        } else {
          existingNotification.isNew = notification.isNew;
          existingNotification.displayText = notification.displayText;
          existingNotification.actionsBtnVisible =
            notification.actionsBtnVisible;
          existingNotification.action.route = notification.action.route;
        }
        existingGroup[0].notifications.sort(this.sortAddOnDesc);
        existingGroup[0].unreadCount = existingGroup[0].notifications.filter(
          (n) => n.isNew
        ).length;
        existingGroup[0].isUnread = existingGroup[0].unreadCount > 0;
      } else {
        isNewNotification = true;
        this.notificationGroups.splice(
          0,
          0,
          new NotificationGroup(
            header,
            headerText,
            [notification],
            notification.isNew ? 1 : 0,
            notification.isNew
          )
        );
      }

      if (
        notification.isNew &&
        (isNewNotification || notification.actionsBtnVisible)
      ) {
        this.addActiveNotification(notification);
      }
      if (!isInitialLoad && notification.jobId && isNewNotification) {
        this.openNotificationPanelForJobStart(notification);
      }
    }
  }

  openNotificationPanelForJobStart(notification) {
    this.showNotificationPanel();
    setTimeout(() => {
      const elements = document.querySelectorAll("[aria-expanded='false']");
      elements.forEach((element) => {
        const notificationGroupHeader = element.id;
        if (
          notificationGroupHeader ==
          "notificationAccordion" + notification.text.header
        ) {
          (element as HTMLElement).click();
        }
      });
    }, 100);
  }

  getJobInfo(backgroundJobStatus): any {
    let result: any;
    switch (backgroundJobStatus) {
      case BackgroundTaskStatusEnum.NotStarted:
        result = {
          status: "NotificationJobNotStartedBody",
          icon: "fas fa-spinner fa-spin",
          color: "text-mustard",
        };
        break;
      case BackgroundTaskStatusEnum.InProgress:
        result = {
          status: "NotificationJobProgressBody",
          icon: "fas fa-spinner fa-spin",
          color: "text-mustard",
        };
        break;
      case BackgroundTaskStatusEnum.Failed:
        result = {
          status: "NotificationJobFailedBody",
          icon: "fas fa-info-circle",
          color: "color-red",
        };
        break;
      default:
        result = {
          status: "NotificationJobCompleteBody",
          icon: "fas fa-check-circle",
          color: "color-green",
        };
        break;
    }
    return result;
  }

  getDisplayText(key: string, params: string[]): string {
    let displayText = this.translate.instant(key);
    for (let i = 0; i < params.length; i++) {
      displayText = displayText.replace(`{${i}}`, params[i]);
    }
    return displayText;
  }

  getDisplayTime(notification: Notification): string {
    return this.eodDate.transform(notification.addOn);
  }

  getIconUrl(notification: Notification): string {
    let iconUrl = "";

    for (let i = 0; i < this.availableFunctions.length; i++) {
      for (
        let j = 0;
        j < this.availableFunctions[i].functionPackageDetail.length;
        j++
      ) {
        let pack = this.availableFunctions[i].functionPackageDetail[j];
        if (pack && pack.functionPackageId == notification.functionId) {
          if (pack.functionPackageId == FunctionPackageEnum.ExportReport) {
            iconUrl = `assets/svg-icons/dashboard/export.svg`;
          } else {
            iconUrl = `assets/svg-icons/dashboard/${pack.iconUrl}.svg`;
          }

          break;
        }
      }
      if (iconUrl) {
        break;
      }
    }

    return iconUrl;
  }

  addActiveNotification(notification: Notification) {
    if (!this.activeNotificationIds.find((n) => n == notification.id)) {
      this.activeNotificationIds.push(notification.id);
      this.updateNotificationCount();
    }
  }
  removeActiveNotification(notification: Notification) {
    this.activeNotificationIds = this.activeNotificationIds.filter(
      (i) => i != notification.id
    );
    this.updateNotificationCount();
  }

  updateNotificationCount() {
    this.notificationsCountChange.emit(this.activeNotificationIds.length);
  }

  onNotificationGroupClick(notificationGroup: NotificationGroup) {
    this.updateReadNotifications(notificationGroup);

    const internalItem = this.internalGroupClicks.filter(
      (g) => g == "notificationAccordion" + notificationGroup.header
    );
    if (internalItem && internalItem.length > 0) {
      this.internalGroupClicks = this.internalGroupClicks.filter(
        (g) => g != "notificationAccordion" + notificationGroup.header
      );
      return;
    }

    if (this.currentExpandedGroupKey == notificationGroup.header) {
      this.currentExpandedGroupKey = "";
      return;
    } else {
      this.currentExpandedGroupKey = notificationGroup.header;
    }

    setTimeout(() => {
      this.closeNotificationGroups(notificationGroup);
    }, 100);
  }

  updateReadNotifications(notificationGroup: NotificationGroup) {
    const newNotifications = notificationGroup.notifications.filter(function (
      n
    ) {
      return n.isNew;
    });
    if (newNotifications.length > 0 && notificationGroup.isUnread) {
      notificationGroup.isUnread = false;
      const notificationsToUpdate = JSON.parse(
        JSON.stringify(newNotifications)
      );
      notificationsToUpdate.forEach((notification) => {
        notification.isNew = false;
        this.removeActiveNotification(notification);
      });
      this.notificationService
        .MarkNotificationRead(notificationsToUpdate)
        .subscribe(() => { });

      setTimeout(() => {
        newNotifications.forEach((n) => (n.isNew = false));
        notificationGroup.unreadCount = 0;
      }, 5000);
    }
  }

  onSnoozeClick(notification: Notification) {
    this.notificationService
      .SnoozeNotification(notification)
      .subscribe(() => { });
    this.removeNotificationFromPanel(notification);
  }

  onActionClick(notification: Notification) {
    this.onRemoveClick(notification);
    if (!notification.functionId) {
      this.closeNotificationPanel();
      this.notificationService.setNotificationActionModel(notification);
      if (notification.action.route) {
        this.router.navigate([notification.action.route]);
      }
    } else {
      this.organisationFunctionService
        .getAvailableFunctionsForOrganisation(this.claims.OrgId, false)
        .subscribe((data) => {
          this.availableFunctions = data;
          let functionPackage = null;
          for (let i = 0; i < this.availableFunctions.length; i++) {
            for (
              let j = 0;
              j < this.availableFunctions[i].functionPackageDetail.length;
              j++
            ) {
              let pack = this.availableFunctions[i].functionPackageDetail[j];
              if (pack && pack.functionPackageId == notification.functionId) {
                functionPackage = pack;
                break;
              }
            }
            if (functionPackage) {
              break;
            }
          }
          if (functionPackage) {
            const functionAvailable =
              functionPackage.isFree || functionPackage.functionStatus;
            if (functionAvailable) {
              this.notificationService.setNotificationActionModel(notification);

              if (notification.action.route) {
                if (
                  notification.text.key ==
                  BackgroundTaskNotificationBody.BulkUpdateExpenseApprovers
                ) {
                  if (
                    this.claims.ApprovalRuleSetting ==
                    ApprovalRuleSetting.Manual
                  ) {
                    this.closeNotificationPanel();
                    this.router.navigate(["/orgsetting/manageemployee"], {
                      queryParams: { step: "4" },
                    });
                  } else {
                    this.closeNotificationPanel();
                    this.router.navigate(["/orgsetting/manageemployee"], {
                      queryParams: { step: "5" },
                    });
                  }
                } else if (
                  notification.functionId == FunctionPackageEnum.ExportReport
                ) {
                  this.downloadExportFiles(notification);
                } else {
                  this.closeNotificationPanel();
                  this.router.navigate([notification.action.route]);
                }
              }
            } else {
              if (notification.functionId == FunctionPackageEnum.ExportReport) {
                this.downloadExportFiles(notification);
              } else if (
                notification.text.header == "GoogleMappingNotificationHeader"
              ) {
                this.closeNotificationPanel();
                this.router.navigate([notification.action.route], {
                  queryParams: {
                    ot: "gms",
                  },
                });
              } else {
                this.closeNotificationPanel();
                this.router.navigate(["/addtocart"], {
                  queryParams: {
                    isFree: this.EncryptParameters(
                      functionPackage.isFree.toString()
                    ),
                    packagePath: this.EncryptParameters(
                      functionPackage.packagePath
                    ),
                    functionPackageName: this.EncryptParameters(
                      functionPackage.functionPackageName
                    ),
                    functionPackageId: this.EncryptParameters(
                      functionPackage.functionPackageId.toString()
                    ),
                    functionStatus: this.EncryptParameters(
                      (functionPackage.functionStatus
                        ? functionPackage.functionStatus
                        : 0
                      ).toString()
                    ),
                  },
                });
              }
            }
          }
        });
      if (notification.functionId == FunctionPackageEnum.UniversalImport) {
        this.notificationService.setNotificationActionModel(notification);
        this.currentJobId = notification.jobId;
        if (notification.text.header == BackgroundTaskNotificationHeaders.BulkImportEmployees
        ) {
          this.getCurrentJobStatus();
        }
        else {
          this.closeNotificationPanel();
          this.router.navigate([notification.action.route]);
        }

      }
    }
  }

  downloadExportFiles(notification) {
    if (notification.text?.params?.length > 6) {
      window.open(notification.text.params[6], "_blank");
    } else {
      this.notificationService
        .getBackgroundJobStatus(notification.jobId)
        .subscribe((result) => {
          var dLinks = result.output.ReasonPhrase.split(",");

          if (notification.failNotification) {
            if (dLinks.length == 2) {
              if (dLinks[1] !== "") {
                window.open(dLinks[1], "_blank");
              }
            } else {
              if (notification.text?.params) {
                window.open(
                  notification.text.params[notification.text.params.length - 1],
                  "_blank"
                );
              }
            }
          } else {
            window.open(dLinks[0], "_blank");
          }

          if (this.router.url.includes("export/expenseexportlog")) {
            this.router.navigate(["export/expenseexportlog"]);
          }
        });
    }
  }

  onRemoveClick(notification: Notification) {
    this.notificationService
      .RemoveNotifications(notification.id)
      .subscribe(() => { });
    this.removeNotificationFromPanel(notification);
  }

  showNotificationPanel() {
    this.openPanel.emit();
  }

  closeNotificationPanel() {
    this.closeNotificationGroups(null);
    this.updateNotificationCount();
    this.closePanel.emit();
  }

  closeNotificationGroups(openedGroup: NotificationGroup) {
    const elements = document.querySelectorAll("[aria-expanded='true']");
    elements.forEach((element) => {
      const notificationGroupHeader = element.id;
      if (
        !openedGroup ||
        "notificationAccordion" + openedGroup.header != notificationGroupHeader
      ) {
        this.internalGroupClicks.push(notificationGroupHeader);
        (element as HTMLElement).click();
      }
    });
  }

  sortAddOnDesc(a: Notification, b: Notification) {
    if (a.addOn > b.addOn) {
      return -1;
    }
    if (a.addOn < b.addOn) {
      return 1;
    }
    return 0;
  }

  EncryptParameters(encryptValue) {
    let rsaHelper = new RSAHelper();
    let encrypt = rsaHelper.Encrypt(encryptValue);
    return encrypt;
  }

  safeText: SafeHtml;
  sanitizerHtml(element) {
    this.safeText = this.sanitizer.bypassSecurityTrustHtml(element);
    return this.safeText;
  }

  onDeleteNotifications() {
    this.notificationGroups = [];

    this.notificationService
      .RemoveAllUserNotification(this.claims.UserId.toString())
      .subscribe(() => {
        this.getNotifications();
      });
  }


  showCheckListPanel: boolean = false;
  checkListPanelModel: CheckListPanelModel;
  checkListDetailsPanelModel: CheckListPanelModel;

  showCheckListDetailsPanel: boolean = false;
  fnShowCurrentTemplateDetails() {
    switch (this.storageData.importTemplate) {
      case UniversalImportTemplateEnum.ImportEmployees:
        this.fnShowImportEmployeesDetails();
        break;

    }
  }
  noOfRecordsImported: number = 0;
  fnShowImportEmployeesDetails() {
    if (this.storageData.importTemplate == UniversalImportTemplateEnum.ImportEmployees) {
      if (this.importEmployeeResponse) {
        this.noOfRecordsImported = this.importEmployeeResponse.SuccessRecordsCounts;
        this.fnOnViewCheckList()
      }
    }
  }


  fnCloseCheckList(response) {
    if(response)
    {
      if(response.close && response.route)
      {
        this.checkListPanelModel = new CheckListPanelModel();
        this.showCheckListPanel = false;
        if(response.route)
        {
          this.closeNotificationPanel();
          this.router.navigate([response.route]);
        }
      }
      else
      {
        this.checkListPanelModel = new CheckListPanelModel();
        this.showCheckListPanel = false;
      }
    }
    
  }
  fnSubmitCheckList(response: CheckListData) {
    if (response) {
      switch (this.storageData.importTemplate) {
        case UniversalImportTemplateEnum.ImportEmployees:
          this.fnShowImportEmployeesCheckListDetails(response);
          break;
      }
    }
  }
  fnShowImportEmployeesCheckListDetails(response: CheckListData) {

    if (response) {
      let checkListDetails = [];

      switch (response.id) {
        case ImportEmployeesResponseEnum.CostCentre:
          let newAddedCostCentre = this.importEmployeeResponse.NewCostCentreList;
          if (newAddedCostCentre) {
            newAddedCostCentre.forEach((item) => {
              let data = new CheckListData();
              data.id = ImportEmployeesResponseEnum.CostCentre;
              data.icon = "fas fa-check color-green padding-right-5";
              data.name = item;
              data.buttonTitle = "";
              data.isButtonVisible = false;
              checkListDetails.push(data);
            });
            this.fnShowCheckListDetails(checkListDetails, this.translate.instant("cost_centres"));
          }
          break;

        case ImportEmployeesResponseEnum.Grade:
          let newAddedGrades = this.importEmployeeResponse.NewGradeList;
          if (newAddedGrades) {
            newAddedGrades.forEach((item) => {
              let data = new CheckListData();
              data.id = ImportEmployeesResponseEnum.Grade;
              data.icon = "fas fa-check color-green padding-right-5";
              data.name = item;
              data.buttonTitle = "";
              data.isButtonVisible = false;
              checkListDetails.push(data);
            });
          }
          this.fnShowCheckListDetails(checkListDetails, this.translate.instant("grades"));
          break;

        case ImportEmployeesResponseEnum.EmployeeErrorsList:
          let errorEmployeeList = this.importEmployeeResponse.ErrorEmployeeList;
          if (errorEmployeeList && errorEmployeeList.length > 0) {
            this.showImportEmployeeErrorPanel = true;
          }
          break;
      }

    }

  }

  fnShowCheckListDetails(data: CheckListData[] = [], title: string) {
    this.checkListDetailsPanelModel = new CheckListPanelModel();
    this.checkListDetailsPanelModel.title = title;
    this.checkListDetailsPanelModel.isSmallPanel = true;
    this.checkListDetailsPanelModel.isGridPanel = false;
    this.checkListDetailsPanelModel.data = data;
    this.showCheckListDetailsPanel = true;
  }

  fnCloseCheckListDetails() {
    this.checkListDetailsPanelModel = new CheckListPanelModel();
    this.showCheckListDetailsPanel = false;
  }
  fnShowCheckList(data: CheckListData[] = [], title: string) {
    this.checkListPanelModel = new CheckListPanelModel();
    this.checkListPanelModel.title = title;
    this.checkListPanelModel.isSmallPanel = false;
    this.checkListPanelModel.isGridPanel = true;
    this.checkListPanelModel.data = data;
    this.showCheckListPanel = true;
  }
  importEmployeeResponse: IImportEmployeeResponse;
  getCurrentJobStatus() {
    this.notificationService.getBackgroundJobStatus(this.currentJobId)
      .subscribe(result => this.ProcessResult(result));
  }
  storageData: StorageData = new StorageData();
  ProcessResult(result: BackgroundTaskStatus) {
    if (result) {
      this.currentJobId = result.id;
      if (result.output) {
        var response = result.output.ResponseInfo;
        if (response) {
          if(response.ErrorEmployeeList && response.ErrorEmployeeList.length > 0){
            response.ErrorEmployeeList = response.ErrorEmployeeList.map(e=>{
                e.FailedErrors = this.fnGetErrorMessages(e.FailedErrors),
                e.FailedWarnings = this.fnGetErrorMessages(e.FailedWarnings)
                return e;
            })
        }
          this.importEmployeeResponse = response;
          if (!this.storageData) {
            this.storageData = new StorageData();
          }
          this.storageData.importTemplate = UniversalImportTemplateEnum.ImportEmployees;
          this.fnShowCurrentTemplateDetails();
        }
      }
    }
  }
  fnGetErrorMessages(err: string): string {
    let messages = [];
    const errors = err.split("##");
    for (let j = 0; j < errors.length; j++) {
        if (errors[j]) {
            messages.push(this.translate.instant('InviteEmpValidation_' + errors[j]));
        }
    }
    return messages.join("; ");
}
  showImportEmployeeErrorPanel: boolean = false;
  fnCloseImportEmployeeErrorPanel(isClose: boolean) {
    if (isClose) {
      this.showImportEmployeeErrorPanel = false;
    }
  }
  fnOnViewCheckList() {
    switch (this.storageData.importTemplate) {
      case UniversalImportTemplateEnum.ImportEmployees:
        this.fnShowImportEmployeesCheckList();
        break;
    }
  }

  fnShowImportEmployeesCheckList() {
    let checkList = [];

    let newAddedCostCentre = this.importEmployeeResponse.NewCostCentreList;
    let newAddedGrades = this.importEmployeeResponse.NewGradeList;
    let errorEmployeeList = this.importEmployeeResponse.ErrorEmployeeList;
    let changedClaimantsApproverCounts = this.importEmployeeResponse.ChangedClaimantsApproverCounts;
    let changedClaimantsGradeCounts = this.importEmployeeResponse.ChangedClaimantsGradeCounts;
    let changedClaimantsCostCentreCounts = this.importEmployeeResponse.ChangedClaimantsCostCentreCounts;
    let changedClaimantsLastNameCounts = this.importEmployeeResponse.ChangedClaimantsLastNameCounts;
    let changedClaimantsFirstNameCounts = this.importEmployeeResponse.ChangedClaimantsFirstNameCounts;
    let successRecordsCounts = this.importEmployeeResponse.SuccessRecordsCounts;
    if (successRecordsCounts && successRecordsCounts > 0) {
      let data = {
        id: ImportEmployeesResponseEnum.SuccessRecordsCounts,
        icon: CommonStatusEnum.Success,
        iconClass: '<i class="fas fa-check color-green f-20"></i>',
        value: successRecordsCounts,
        text: this.translate.instant("emp_imp_exp_msg2"),
        safetext:'',
        isButtonHide: true,
        sequence:1
      }
      checkList.push(data);
    }
    if (changedClaimantsApproverCounts && changedClaimantsApproverCounts > 0) {
      let data = {
        id: ImportEmployeesResponseEnum.ChangedClaimantsApproverCounts,
        icon: CommonStatusEnum.Success,
        iconClass: '<i class="fas fa-check color-green f-20"></i>',
        value: changedClaimantsApproverCounts,
        text: this.translate.instant("emp_imp_exp_msg3"),
        safetext:'',
        isButtonHide: true,
        sequence:2
      }
      checkList.push(data);
    }
    if (changedClaimantsGradeCounts && changedClaimantsGradeCounts > 0) {
      let data = {
        id: ImportEmployeesResponseEnum.ChangedClaimantsGradeCounts,
        icon: CommonStatusEnum.Success,
        iconClass: '<i class="fas fa-check color-green f-20"></i>',
        value: changedClaimantsGradeCounts,
        text: this.translate.instant("emp_imp_exp_msg4"),
        safetext:'',
        isButtonHide: true,
        sequence:3
      }
      checkList.push(data);
    }
    if (changedClaimantsFirstNameCounts && changedClaimantsFirstNameCounts > 0) {
      let data = {
        id: ImportEmployeesResponseEnum.ChangedClaimantsFirstNameCounts,
        icon: CommonStatusEnum.Success,
        iconClass: '<i class="fas fa-check color-green f-20"></i>',
        value: changedClaimantsFirstNameCounts,
        text: this.translate.instant("emp_imp_exp_msg5"),
        safetext:'',
        isButtonHide: true,
        sequence:4
      }
      checkList.push(data);
    }
    if (changedClaimantsLastNameCounts && changedClaimantsLastNameCounts > 0) {
      let data = {
        id: ImportEmployeesResponseEnum.ChangedClaimantsLastNameCounts,
        icon: CommonStatusEnum.Success,
        iconClass: '<i class="fas fa-check color-green f-20"></i>',
        value: changedClaimantsLastNameCounts,
        text: this.translate.instant("emp_imp_exp_msg6"),
        safetext:'',
        isButtonHide: true,
        sequence:5
      }
      checkList.push(data);
    }
    if (changedClaimantsCostCentreCounts && changedClaimantsCostCentreCounts > 0) {
      let data = {
        id: ImportEmployeesResponseEnum.ChangedClaimantsCostCentreCounts,
        icon: CommonStatusEnum.Success,
        iconClass: '<i class="fas fa-check color-green f-20"></i>',
        value: changedClaimantsCostCentreCounts,
        text: this.translate.instant("emp_imp_exp_msg7"),
        safetext:'',
        isButtonHide: true,
        sequence:6
      }
      checkList.push(data);
    }
    if (newAddedGrades && newAddedGrades.length > 0) {
      let data = {
        id: ImportEmployeesResponseEnum.Grade,
        icon: CommonStatusEnum.Success,
        iconClass: '<i class="fas fa-check color-green f-20"></i>',
        value: newAddedGrades.length,
        text: this.translate.instant("emp_imp_exp_msg8"),
        safetext:'<div class="text-underline cursor-pointer text-logo-blue">'+this.translate.instant('label_view')+' </div>',
        isButtonHide: false,
        sequence:7
      }
      checkList.push(data);
    }
    if (newAddedCostCentre && newAddedCostCentre.length > 0) {
      let data = {
        id: ImportEmployeesResponseEnum.CostCentre,
        icon: CommonStatusEnum.Success,
        iconClass: '<i class="fas fa-check color-green f-20"></i>',
        value: newAddedCostCentre.length,
        text: this.translate.instant("emp_imp_exp_msg9"),
        safetext:'<div class="text-underline cursor-pointer text-logo-blue">'+this.translate.instant('label_view')+' </div>',
        isButtonHide: false,
        sequence:8
      }
      checkList.push(data);
    }
    if (errorEmployeeList && errorEmployeeList.length > 0) {
      let data2 = {
        id: ImportEmployeesResponseEnum.EmployeeErrorsList,
        icon: CommonStatusEnum.Failed,
        iconClass: '<i class="fas fa-exclamation-circle color-red"></i>',
        value: errorEmployeeList.length,
        text: `${'<span class="color-red">'} ${this.translate.instant("emp_import_approver_check_note2")} ${'</span>'}`,
        safetext:'<div class="text-underline cursor-pointer text-logo-blue">'+this.translate.instant('label_view')+' </div>',
        isButtonHide: false,
        sequence:10
      };
      checkList.push(data2);
    }

    this.fnShowCheckList(checkList, this.translate.instant("emp_imp_exp_msg1"));
    }
    sendEmployeeInvite(email) {
        localStorage.setItem("fromClaimantsTryingNewSubscription", JSON.stringify(email.text));
        this.onRemoveClick(email);
        this.router.navigateByUrl('/', {
            skipLocationChange: true
        }).then(() => {
            this.router.navigate(["/orgsetting/manageemployee"], {
                queryParams: { step: "1" }
            });
        });
    }

}
