import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DynamicFormService {

    private static isFormSubmittedSubject: Subject<boolean> = new Subject<boolean>();
    private static valueChangeSubject: Subject<any> = new Subject<any>();
    private static openPanelSubject: Subject<string> = new Subject<string>();

    constructor() {
    }

    public onFormSubmitted(isSubmitted: boolean) {
        DynamicFormService.isFormSubmittedSubject.next(isSubmitted);
    }

    public getFormSubmittedEvent(): Observable<boolean> {
        return DynamicFormService.isFormSubmittedSubject.asObservable();
    }

    public getValueChangeEvent(): Observable<any> {
        return DynamicFormService.valueChangeSubject.asObservable();
    }

    public onValueChange(data: any) {
        DynamicFormService.valueChangeSubject.next(data);
    }

    public getOpenPanelEvent(): Observable<string> {
      return DynamicFormService.openPanelSubject.asObservable();
    }

    public onOpenPanel(key: string) {
        DynamicFormService.openPanelSubject.next(key);
    }

    getField(key: string, fields: FormlyFieldConfig[]): FormlyFieldConfig {
        for (let i = 0, len = fields.length; i < len; i++) {
            const f = fields[i];
            if (f.key === key) {
                return f;
            }

            if (f.fieldGroup && !f.key) {
                const cf = this.getField(key, f.fieldGroup);
                if (cf) {
                    return cf;
                }
            }
        }
    }

}
