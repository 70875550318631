import { OnInit, Component, ViewChild, Output, EventEmitter, Input, OnChanges } from "@angular/core";
import { Subscription } from "rxjs";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { TranslateService } from "@ngx-translate/core";
import { Claims } from "src/app/_models/common/claims";
import { AppGridComponent } from "src/app/shared/grid/grid-component/grid.component";
import { ColDef } from "ag-grid-community";
import { GridConfig } from "src/app/_models/common/grid/grid-config";
import { AlertService } from "src/app/alert-panel/alert.service";
import { Router } from "@angular/router";
import { NrFcLogicsService } from "src/app/_services/NrFc/nr-fc-logics.service";
import { EmployeeStatusEnum } from "src/app/_models/common/manage-emp-enum.enum";

@Component({
  selector: 'app-approval-items',
  templateUrl: './approval-items-panel.component.html',
  styleUrls: ['./approval-items-panel.component.scss']
})
export class ApprovalItemsPanelComponent implements OnInit {

  @ViewChild(AppGridComponent) gridComponent: AppGridComponent;

  @Output() closePanel = new EventEmitter<boolean>();
  @Input() fmAsApproverSummary: any = [];
  @Input() fmAsApproverData: any;

  userActionEventSubscription: Subscription;
  claimsSubscription: Subscription;
  claims: Claims;
  currentUser: any;

  defaultSortColumn = 'amount';
  gridData: any[] = [];
  gridColumnDefs: ColDef[];
  gridConfigItems: GridConfig;
  showApproverStatusPanel: boolean = false;
  employeesList: any[] = [];
  approverListCostCentre: string;
  approverListCostCentreCode: string;
  approverListCostCentreId: number;
  panelItemType: number;

  selectedCostCentres: any[] = [];
  assignApproversGridColumnDefs: any;

  constructor(
    private claimsService: ClaimsService,
    private translate: TranslateService,
    private alertService: AlertService,
    private router: Router,
    private rrFcLogicsService: NrFcLogicsService,
  ) {

    this.claimsSubscription = this.claimsService.currentClaims.subscribe((claims) => {
      this.claims = claims;
    });
    this.userActionEventSubscription = this.alertService.getUserActionEvent().subscribe(action => {
      if (action) {
        this.closePanel.emit(false);
      }
    });

  }

  ngOnInit(): void {
    if (this.fmAsApproverSummary) {
      this.mapGridData();
      this.fnInitialiseEmployeesGrid();
    }

  }

  ngOnDestroy() {
    this.claimsSubscription.unsubscribe();
    this.userActionEventSubscription.unsubscribe();
  }

  mapGridData() {
    let mapData = this.fmAsApproverSummary
    this.fmAsApproverSummary = [];
    for (let index = 0; index < mapData.length; index++) {
      let gridDataItem = {
        id: index + 1,
        amount: mapData[index]['amount'].toFixed(2),
        count: mapData[index]['count'],
        costCentreName: mapData[index]['costCentreName'],
        costCentreId: mapData[index]['costCentreId'],
        reason: this.mapReason(mapData[index]['status'], mapData[index]['costCentreId']),
        status: mapData[index]['status'],
      };
      this.fmAsApproverSummary.push(gridDataItem);
    }
  }

  mapReason(status, costCentreId) {
    let reason = '';
    if (this.claims.ApprovalRuleSetting == 1) {
      switch (status) {
        case 0:
          reason = this.translate.instant('approver_not_assigned')
          break;
        case 4:
          reason = this.translate.instant('approve_expenses')
          break;
        default:
          reason = this.translate.instant('approver_not_joined_pending')
          break;
      }
    }
    else {
      const costCentre = this.fmAsApproverData.costCentresList.find(c => c.costCentreId == costCentreId);
      if (costCentre == undefined && status == 4) {
        reason = this.translate.instant('approve_expenses')
      }
      else {
        if (costCentre.approversList && costCentre.approversList.length > 0) {
          if (status == 1 && costCentre.approversList.length == 1
            && costCentre.approversList[0].status == EmployeeStatusEnum.Active
            && costCentre.approversList[0].employeeID != this.claims.UserId) {
            reason = this.translate.instant('approver_not_assigned');
          }
          else if (status == 4) {
            reason = this.translate.instant('approve_expenses')
          }
          else {
            reason = this.translate.instant('approver_not_joined_pending')
          }
        }
        else {
          reason = this.translate.instant('approver_not_assigned')
        }
      }

    }

    return reason;
  }

  fnCreateColumnDefs() {
    this.gridColumnDefs = [
      {
        headerName: this.translate.instant('expense_count'),
        field: 'count',
        resizable: true,
        sortable: true,
        suppressMenu: true,
        minWidth: 75,
        headerClass: 'text-right',
        cellClass: 'text-right',
      },
      {
        headerName: this.translate.instant('text_amount'),
        field: 'amount',
        resizable: true,
        sortable: true,
        suppressMenu: true,
        minWidth: 75,
        headerClass: 'text-right',
        cellClass: 'text-right',
      },
      {
        headerName: this.translate.instant('text_cost_centre'),
        field: 'costCentreName',
        resizable: true,
        sortable: true,
        suppressMenu: true,
        minWidth: 200,
        hide: this.claims.ApprovalRuleSetting == 1
      },
      {
        headerName: this.translate.instant('reason_action'),
        field: 'reason',
        resizable: true,
        sortable: false,
        suppressMenu: true,
        cellRenderer: 'approverRenderer',
        minWidth: 310
      }
    ];

    this.assignApproversGridColumnDefs = [
      {
        headerName: this.translate.instant('cost_centre_name'),
        field: 'costCentreName',
        resizable: true,
        sortable: true,
        suppressMenu: true,
        minWidth: 200
      },
      {
        headerName: this.translate.instant('NumberofClaimants'),
        field: 'claimantCount',
        headerClass: 'text-right',
        cellClass: 'text-right',
        resizable: true,
        sortable: true,
        suppressMenu: true,
        cellRenderer: 'formattedTextRenderer',
        minWidth: 200,
        headerComponentParams: {
          class: 'd-inline-block text-logo-blue cursor-pointer text-underline lH-12'
        }
      },
      {
        headerName: this.translate.instant('deputy_approver_name'),
        field: 'approvers',
        resizable: true,
        sortable: true,
        suppressMenu: true,
        cellRenderer: 'approverRenderer',
        minWidth: 200
      },
      {
        headerName: "",
        valueGetter: 'data.costCentreId',
        cellRenderer: 'actionRenderer',
        field: 'userActionField',
        suppressMovable: true,
        suppressMenu: true,
        sortable: false,
        minWidth: 100,
        headerComponentParams:
          [
            {
              buttonClass: 'fas fa-edit color-green',
              buttonTooltip: this.translate.instant('label_edit'),
              userAction: 1
            }
          ]
      }
    ];
  }

  fnGetGridConfigItems() {

    let countByStatusArray = [];

    this.gridConfigItems = new GridConfig(
      true,
      true,
      this.defaultSortColumn,
      "id",
      0,
      false,
      countByStatusArray,
      true,
      true,
      false,
      null,
      'asc',
      40,
      'no_data_availabe',
      null,
      null,
      false, false,
      '',
      '',
      false
    );
  }

  fnInitialiseEmployeesGrid() {
    this.fnCreateColumnDefs();
    this.fnGetGridConfigItems();
    setTimeout(() => {
      this.gridComponent.initializeGrid();
    }, 100);
  }

  fnOnGridCellClick(params) {
    if (params.column.colId === 'reason') {
      if (params.data.status != 4) {
        if (this.claims.ApprovalRuleSetting == 1) {
          this.openEmployeeListPanel(params.data);
        }
        else {
          this.openCostCentrePanel(params.data)
        }
      }
      else {
        this.fnRedirectToApprovalArea();
      }
    }
  }
  fnRedirectToApprovalArea() {
    if (this.claims.pendingExpenseCountApprover > 0) {
      this.router.navigate(["approver/appclaimant/1"]);
    }
    else if (this.claims.pendingCountNRApprover > 0) {
      this.router.navigate(["approver/appnrreceipts"]);
    }
    else if (this.claims.pendingCountFCApprover > 0) {
      this.router.navigate(["approver/appfcreceipts"]);
    }
    else if (this.claims.pendingCountPreApproval > 0) {
      this.router.navigate(["approver/papproval"]);
    }
    else if (this.claims.pendingAdvancesForApproval > 0) {
      this.router.navigate(["approver/advanceclaimantlist"]);
    }
    else if (this.claims.pendingCountInvoiceApproval > 0) {
      this.router.navigate(["approver/appclaimant/2"]);
    }
  }

  openEmployeeListPanel(paramsData) {
    if (paramsData.status == 0) {
      this.router.navigate(["/orgsetting/manageemployee"], {
        queryParams: { step: "4" },
      });
    }
    else {
      this.panelItemType = 1;
      this.showApproverStatusPanel = true;
      this.employeesList = this.fmAsApproverData.approversList;
      this.approverListCostCentre = '';
      this.approverListCostCentreCode ='';
      this.approverListCostCentreId = 0;
    }
  }

  openCostCentrePanel(paramsData) {
    const costCentre = this.fmAsApproverData.costCentresList.find(c => c.costCentreId == paramsData.costCentreId);
    if (costCentre.approversList && costCentre.approversList.length > 1) {
      this.panelItemType = 2;
      this.showApproverStatusPanel = true;
      this.employeesList = costCentre.approversList;
      this.approverListCostCentre = paramsData.costCentreName;
      this.approverListCostCentreCode = costCentre.costCentreCode1;
      this.approverListCostCentreId = paramsData.costCentreId;
    }
    else {
      this.selectedCostCentres = [costCentre];
    }
  }

  closeAssignPanel(isSaved) {
    const costCentre = this.fmAsApproverData.costCentresList;
    this.selectedCostCentres = [];
    // this.gridComponent.uncheckAll();
    // if (isSaved) {
    //     this.fnGetAllEmployeesList();
    // }
  }

  fnClosePanel() {
    this.closePanel.emit(false);
    // if (this.canDeactivate()) {
    //     if (this.removedApprovers.length > 0) {
    //         this.onRemoveApprover.emit(this.removedApprovers);
    //     }
    //     else {
    //         this.closePanel.emit(this.isSaved);
    //     }
    //     this.resetFields();
    // }
    // else {
    //     this.alertService.onFormClosed();
    // }
  }


}
