import { OnInit, Component, EventEmitter, Input, Output, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { Subscription } from "rxjs";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { ApiRequest, IApiresponse } from "src/app/_models";
import { first } from "rxjs/operators";
import { HowDoIVideoLinks } from "src/app/common/commonvariables";
import { environment } from "src/environments/environment";
import { IRecentJourneysResponse, IRecentJourneysRequest, RecentJourneysRequest, SelectRecentJourneyInputModel, SelectRecentJourneyOutputModel } from "src/app/_models/claimant-on-web/claimant-on-web";
import { ClaimantOnWebService } from "src/app/_services/claimant-on-web/claimant-on-web.service";
import { MileageUOMEnum } from "src/app/_models/enum/solo-enum";

@Component({
    selector: "select-recent-journeys-panel",
    templateUrl: "./select-recent-journeys-panel.component.html"
})
export class SelectRecentJourneysPanelComponent implements OnInit, OnDestroy {

    @Input()
    inputData: SelectRecentJourneyInputModel;

    @Output() closePanelEvent = new EventEmitter<boolean>();
    @Output() selectItemEvent = new EventEmitter<SelectRecentJourneyOutputModel>();

    claimsSubscription: Subscription;
    localOrganisationId: any;
    param: any;
    howDoIVideoLinks = HowDoIVideoLinks;

    recentJourneysList = <any[]>[];

    get mileageUOMEnum() {
        return MileageUOMEnum;
    }

    constructor(private claimsService: ClaimsService,
        private claimantOnWebService: ClaimantOnWebService, private cdr: ChangeDetectorRef) {
        this.claimsSubscription = this.claimsService.currentClaims.subscribe((claims) => {
            this.localOrganisationId = claims.OrgId;
        });
    }

    ngOnInit(): void {
        this.fnGetRecentJourneys();
    }

    ngOnDestroy(): void {
        this.claimsSubscription.unsubscribe();
    }

    fnClosePanel() {
        this.closePanelEvent.emit(true);
    }

    fnGetRecentJourneys() {
        let request = new ApiRequest<IRecentJourneysRequest>("RecentJourneys", environment.applicationVersion, environment.requestId);
        let requestInfo = new RecentJourneysRequest(this.inputData.baseCategoryId, this.localOrganisationId, this.inputData.claimantId);
        request.requestInfo = requestInfo;

        this.claimantOnWebService
            .GetRecentJourneys(request)
            .pipe(first())
            .subscribe((response: IApiresponse<IRecentJourneysResponse>) => {
                if (response.responseInfo != null) {
                    this.recentJourneysList = response.responseInfo.journeyList;
                }
                this.cdr.detectChanges();
            });
    }
    fnSelectRecentJourneys(item: any) {
        
        if (item) {
            let selectRecentJourneyOutputModel = new SelectRecentJourneyOutputModel();
            selectRecentJourneyOutputModel.fromLocation = item.fromLocation;
            selectRecentJourneyOutputModel.toLocation = item.toLocation;
            selectRecentJourneyOutputModel.totalDistance = item.totalDistance;
            this.selectItemEvent.emit(selectRecentJourneyOutputModel);
        }
    }

}
