import { ErrorInfo } from "../common/error-info";
import { EmployeeStatusEnum, UserTypeEnum } from "src/app/_models/common/manage-emp-enum.enum";

export interface IVehicleList {
  vehicleTypeId: number;
  orgVehicleTypeMapId: number;
  vehicleSubTypeId: number;
  mileageRate: number;
  derivedName: string;
  vehicleLabel: string;
  isMultiRange: boolean;
  mileageRateInfo: IMileageRateInfo[];
}

export class VehicleList implements IVehicleList {
  vehicleTypeId: number;
  orgVehicleTypeMapId: number;
  vehicleSubTypeId: number;
  mileageRate: number;
  derivedName: string;
  vehicleLabel: string;
  isMultiRange: boolean;
  mileageRateInfo: IMileageRateInfo[];
}

export interface IMileageRateInfo {
  rangeFrom: number;
  rangeTo: number;
  mileageRate: number;
  milesTravelled: number;
  amount: number;
}

export class MileageRateInfo implements IMileageRateInfo {
  rangeFrom: number;
  rangeTo: number;
  mileageRate: number;
  milesTravelled: number;
  amount: number;
}

export interface IOrgVehiclTypes {
  orgID: number;
  mileageUOM: number;
  mileageUOMLabel: string;
  rateType: number;
  vehicleTypes: IVehicleList[];
}
export class OrgVehiclTypes implements IOrgVehiclTypes {
  orgID: number;
  mileageUOM: number;
  mileageUOMLabel: string;
  rateType: number;
  vehicleTypes: IVehicleList[];
}

export interface IVehicleListRequestDto {
  orgId: number;
}

export class VehicleListRequestDto implements IVehicleListRequestDto {
  orgId: number;
  constructor(
    orgId: number
  ) {
    this.orgId = orgId;
  }
}

export interface IResponseInviteEmployee {
  approverId: number;
  employeeEmail: string;
  employeeId: number;
  employeeRole: number;
  failedRecord: EmployeeFailedRecord;
}

export class ResponseInviteEmployee implements IResponseInviteEmployee {
  employeeEmail: string;
  employeeId: number;
  employeeRole: number;
  approverId: number;
  responseMessage: string;
  errors: ErrorInfo[]
  failedRecord: EmployeeFailedRecord;
}

export class EmployeeFailedRecord {
  uid: number;
  eid: string;
  eid2: string;
  eid3: string;
  eid4: string;
  fn: string;
  ln: string;
  e: number;
  r: number;
  ai: number;
  err: string;
}


export interface IFailedEmpImportInfo {
  employeeCode: string;
  firstName: string;
  lastName: string;
  email: string;
  employeeRole: string;
  vehicleType: number;
  approverEmail: string;
  error: string;
}
export class FailedEmpImportInfo implements IFailedEmpImportInfo {
  employeeCode: string;
  firstName: string;
  lastName: string;
  email: string;
  employeeRole: string;
  vehicleType: number;
  approverEmail: string;
  error: string;
  constructor(
    employeeCode: string,
    firstName: string,
    lastName: string,
    email: string,
    employeeRole: string,
    vehicleType: number,
    approverEmail: string,
    error: string,
  ) {
    this.employeeCode = employeeCode;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.employeeRole = employeeRole;
    this.vehicleType = vehicleType;
    this.approverEmail = approverEmail;
    this.error = error;
  }
}

export interface IFailedEmpImportReqDto {
  employeeImportId: number;
}
export class FailedEmpImportReqDto implements IFailedEmpImportReqDto {
  employeeImportId: number;
  constructor(employeeImportId: number) {
    this.employeeImportId = employeeImportId;
  }
}

export interface IFailedEmpImportRespDto {
  failedImportInfo: IFailedEmpImportInfo[];
}
export class FailedEmpImportRespDto implements IFailedEmpImportRespDto {
  failedImportInfo: IFailedEmpImportInfo[];
}

export interface IAddEmpLogRequestDto {
  employeeImportId: number;
  orgId: number;
  totalRecords: number;
  recordsImported: number;
  recordsFailed: number;
  notes: string;
  isFailedExported: boolean;
  createdBy: number;
}
export class AddEmpLogRequestDto implements IAddEmpLogRequestDto {
  employeeImportId: number;
  orgId: number;
  totalRecords: number;
  recordsImported: number;
  recordsFailed: number;
  notes: string;
  isFailedExported: boolean;
  createdBy: number;
  constructor(employeeImportId: number,
    orgId: number,
    totalRecords: number,
    recordsImported: number,
    recordsFailed: number,
    notes: string,
    isFailedExported: boolean,
    createdBy: number
  ) {
    this.employeeImportId = employeeImportId;
    this.orgId = orgId;
    this.totalRecords = totalRecords;
    this.recordsImported = recordsImported;
    this.recordsFailed = recordsFailed;
    this.notes = notes;
    this.isFailedExported = isFailedExported;
    this.createdBy = createdBy;
  }
}
export interface IAddEmpImportLogResponseDto {
  employeeImportId: number;
}
export class AddEmpImportLogResponseDto implements IAddEmpImportLogResponseDto {
  employeeImportId: number;
}

export interface IAddFailedImportDto {
  failedImportData: AddFailedEmpImportList[];
}
export class AddFailedImportDto implements IAddFailedImportDto {
  failedImportData: AddFailedEmpImportList[];
  constructor(failedImportData: AddFailedEmpImportList[]) {
    this.failedImportData = failedImportData;
  }
}

export interface IAddFailedEmpImportList {
  employeeImportId: number;
  employeeCode1: string;
  employeeCode2: string;
  employeeCode3: string;
  employeeCode4: string;
  firstName: string;
  lastName: string;
  email: string;
  employeeRole: string;
  approverEmail: string;
  error: string;
}
export class AddFailedEmpImportList implements IAddFailedEmpImportList {
  employeeImportId: number;
  employeeCode1: string;
  employeeCode2: string;
  employeeCode3: string;
  employeeCode4: string;
  firstName: string;
  lastName: string;
  email: string;
  employeeRole: string;
  approverEmail: string;
  error: string;
  constructor(employeeImportId: number,
    employeeCode1: string,
    employeeCode2: string,
    employeeCode3: string,
    employeeCode4: string,
    firstName: string,
    lastName: string,
    email: string,
    employeeRole: string,
    approverEmail: string,
    error: string,
  ) {
    this.employeeImportId = employeeImportId;
    this.employeeCode1 = employeeCode1;
    this.employeeCode2 = employeeCode2;
    this.employeeCode3 = employeeCode3;
    this.employeeCode4 = employeeCode4;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.employeeRole = employeeRole;
    this.approverEmail = approverEmail;
    this.error = error;
  }
}

export interface IEmpLogRequestDto {
  OrgId: number;
}
export class EmpLogRequestDto implements IEmpLogRequestDto {
  OrgId: number;
  constructor(OrgId: number) {
    this.OrgId = OrgId;
  }
}

export interface IEmpLogResponseDto {
  logDetails: IEmpLogList[];
}
export class EmpLogResponseDto implements IEmpLogResponseDto {
  logDetails: IEmpLogList[];
}

export interface IEmpLogList {
  employeeImportId: number;
  totalRecords: number;
  recordsImported: number;
  recordsFailed: number;
  notes: string;
  isFailedExported: boolean;
}

export class EmpLogList implements IEmpLogList {
  employeeImportId: number;
  totalRecords: number;
  recordsImported: number;
  recordsFailed: number;
  notes: string;
  isFailedExported: boolean;
}

export interface IAddUpdateEmployeeRequestDto {
  employeeID: number;
  employeeCode: string;
  employeeCode2: string;
  employeeCode3: string;
  employeeCode4: string;
  firstName: string;
  lastName: string;
  email: string;
  status: number;
  employeeRole: number;
  vehicleTypeId: number;
  vehicleStatus: number;
  vehicleSubTypeId: number;
  rateType: number;
  orgVehicleTypeMapId: number;
  derivedName: string;
  approverId: number;
  approverEmail: string;
  costCentreId: number;
  costCentreRuleId:number
  userType:number;
  assignedExpCatIds:string;
  assignedFormIds:string;
}


export class CSVRecord {

  public EmployeeID1: any;
  public EmployeeID2: any;
  public EmployeeID3: any;
  public EmployeeID4: any;
  public FirstName: any;
  public LastName: any;
  public Email: any;
  public Role: any;
  public ApproverName: any;
  public ApproverEmail: any;
  public CostCentreName: string;
  public CostCentreCode: string;
  public GradeName: string;
  public Error: string;
  public Warning: string;
  constructor() {

  }
}

export class AddUpdateEmployeeRequestDto implements IAddUpdateEmployeeRequestDto {
  constructor(
    public employeeID: number,
    public employeeCode: string,
    public employeeCode2: string,
    public employeeCode3: string,
    public employeeCode4: string,
    public firstName: string,
    public lastName: string,
    public email: string,
    public status: number,
    public isPasswordReset: boolean,
    public employeeRole: number,
    public vehicleTypeId: number,
    public vehicleStatus: number,
    public vehicleSubTypeId: number,
    public rateType: number,
    public orgVehicleTypeMapId: number,
    public derivedName: string,
    public approverId: number,
    public approverEmail: string,
    public costCentreId: number,
    public costCentreName: string,
    public costCentreCode: string,
    public costCentreRuleId: number,
    public userType: number,
    public assignedExpCatIds: string,
    public assignedFormIds: string

  ) {
  }
}

export interface IAddUpdateBulkEmployeeRequestDto {
  fmEmail: string;
  fmName: string;
  fmUserId: number;
  employees: IAddUpdateEmployeeRequestDto[]
  isImport: boolean;
}

export class AddUpdateBulkEmployeeRequestDto implements IAddUpdateBulkEmployeeRequestDto {
  constructor(public fmEmail: string, public fmName: string, public fmUserId: number,
    public employees: AddUpdateEmployeeRequestDto[], public isImport: boolean) {

  }
}

export interface IAppListRequestDto {
  orgId: number;
}

export class AppListRequestDto implements IAppListRequestDto {
  orgId: number;
  constructor(
    orgId: number
  ) {
    this.orgId = orgId;
  }
}

export interface IAppList {
  employeeId: number;
  displayName: string;
  email: string;
}


export class AppList implements IAppList {
  employeeId: number;
  displayName: string;
  email: string;
}

export interface IAppListResponseDto {
  approvers: IAppList[];
}


export class AppListResponseDto implements IAppListResponseDto {
  approvers: IAppList[];
}

export interface IEmpListRequestDto {
  orgId: number;
  status: number;
  searchText: string;
  sortOrder: number;
  sortColumn: string;
  pageNumber: number;
  pageSize: number
}

export class EmpListRequestDto implements IEmpListRequestDto {
  constructor(
    public orgId: number,
    public status: number,
    public searchText: string,
    public sortOrder: number,
    public sortColumn: string,
    public pageNumber: number,
    public pageSize: number
  ) {
  }
}

export interface IEmpList {
  employeeID: number;
  employeeCode: string;
  employeeCode2: string;
  employeeCode3: string;
  employeeCode4: string;
  displayName: string;
  firstName: string;
  lastName: string;
  email: string;
  status: string;
  employeeRole: string;
  vehicleTypeId: string;
  derivedName: string;
  vehicleStatus: number
  orgVehicleTypeMapId: number;
  rateType: number;
  vehicleSubTypeId: number;
  approverId: number;
  approverName: string;
  costCentreId: number;
  costCentreName: string;
  emailDisplayName: string;
  selected: boolean;
  updatedOn: any;
  isRowHighlighted: boolean;
  disableDeactivate: boolean;
  disableOthers: boolean;
  hideDeactivate: boolean;
  disableResendInvite: boolean;
  hideResendInvite: boolean;
  disableSendInvite: boolean;
  hideSendInvite: boolean;
  disableActivate: boolean;
  hideActivate: boolean;
  disableEdit: boolean;
  hideEdit: boolean;
  isCheckboxDisabled: boolean;
  createdOn: any;
  perPersonBillingType: boolean;
  hybridBillingType: boolean;
  costCentreCode1: string;
  gradeId: number;
  gradeName: string;
  linkedToXero: number;
  userType:number;
  kycStatus:number;
  assignedExpCatIds:string;
  assignedFormIds:string;
  companyName: string;
  isConvertedToPowerUser: boolean;
  costCentreRuleId:number;
  isDeleted:boolean;
  userUpdatedOn: number;
}


export class EmpList implements IEmpList {
  employeeID: number;
  employeeCode: string;
  employeeCode2: string;
  employeeCode3: string;
  employeeCode4: string;
  displayName: string;
  firstName: string;
  lastName: string;
  email: string;
  status: string;
  employeeRole: string;
  vehicleTypeId: string;
  derivedName: string;
  vehicleStatus: number
  orgVehicleTypeMapId: number;
  rateType: number;
  vehicleSubTypeId: number;
  approverId: number;
  approverName: string;
  costCentreId: number;
  costCentreName: string;
  emailDisplayName: string;
  selected: boolean;
  updatedOn: any;
  isRowHighlighted: boolean;
  disableDeactivate: boolean;
  disableOthers: boolean;
  hideDeactivate: boolean;
  disableResendInvite: boolean;
  hideResendInvite: boolean;
  disableSendInvite: boolean;
  hideSendInvite: boolean;
  disableActivate: boolean;
  hideActivate: boolean;
  disableEdit: boolean;
  hideEdit: boolean;
  isCheckboxDisabled: boolean;
  createdOn: any;
  perPersonBillingType: boolean;
  hybridBillingType: boolean;
  costCentreCode1: string;
  gradeId: number;
  gradeName: string;
  linkedToXero: number;
  userType:number;
  kycStatus:number;
  assignedExpCatIds:string;
  assignedFormIds:string;
  companyName: string;
  isConvertedToPowerUser: boolean;
  costCentreRuleId: number;
  isDeleted:boolean;
  userUpdatedOn: number;
}

export interface IEmpListResponseDto {
  counter: IEmployeeCounter;
  employees: IEmpList[];
}

export class EmpListResponseDto implements IEmpListResponseDto {
  counter: IEmployeeCounter;
  employees: IEmpList[];
}

export interface ILoginIDHistoryList {
  UserID: number;
  PrimaryEmail: string;
  UpdatedEmail: string;
  Notes: string;
}



export class LoginIDHistoryList implements ILoginIDHistoryList {
  UserID: number;
  PrimaryEmail: string;
  UpdatedEmail: string;
  Notes: string;
  constructor(UserID: number, PrimaryEmail: string, UpdatedEmail: string, Notes: string) {
    this.UserID = UserID;
    this.PrimaryEmail = PrimaryEmail;
    this.UpdatedEmail = UpdatedEmail;
    this.Notes = Notes;
  }
}

export interface IOwnershipTypeList {
  Wheeler4Id: number;
  Wheeler2Id: number;
}

export interface IGetAllEmployeesRequest {
  OrgId: number;
  Type:number;
  IsFromManageEmployee:boolean;
}
export class GetAllEmployeesRequest implements IGetAllEmployeesRequest {
  OrgId: number;
  Type:number;
  IsFromManageEmployee:boolean = false;
  constructor(OrgId: number) {
    this.OrgId = OrgId;    
  }
}

export interface IGetAllTypeEmployeesRequest {
  OrgId: number;
  Type:number[];
}
export class GetAllTypeEmployeesRequest implements IGetAllTypeEmployeesRequest {
  OrgId: number;
  Type:number[];
  constructor(OrgId: number) {
    this.OrgId = OrgId;    
  }
}

export interface IGetExistingEmployeeCountRequest {
  email: string;
}
export class GetExistingEmployeeCountRequest implements IGetExistingEmployeeCountRequest {
  email: string;
  constructor(email: string) {
    this.email = email;
  }
}

export interface IGetExistingEmployeeCountResponse {
  count: number;
}
export class GetExistingEmployeeCountResponse implements IGetExistingEmployeeCountResponse {
  count: number;
}

export interface IGetAllEmployeesResponse {
  employees: IEmpList[];
}
export class GetAllEmployeesResponse implements IGetAllEmployeesResponse {
  employees: IEmpList[];
}


export class OwnershipTypeList implements IOwnershipTypeList {
  Wheeler4Id: number;
  Wheeler2Id: number;
  constructor(Wheeler4Id: number, Wheeler2Id: number) {
    this.Wheeler4Id = Wheeler4Id;
    this.Wheeler2Id = Wheeler2Id;
  }
}
export interface IEmployeeCounter {
  EmployeeCount: number;
  ActiveEmployeeCount: number;
  InactiveEmployeeCount: number;
  NIEmployeeCount: number;
  PendingEmployeeCount: number;
}

export class EmployeeCounter implements IEmployeeCounter {
  EmployeeCount: number;
  ActiveEmployeeCount: number;
  InactiveEmployeeCount: number;
  NIEmployeeCount: number;
  PendingEmployeeCount: number;
  constructor(EmployeeCount: number, ActiveEmployeeCount: number, InactiveEmployeeCount: number,
    NIEmployeeCount: number, PendingEmployeeCount: number) {
    this.EmployeeCount = EmployeeCount;
    this.ActiveEmployeeCount = ActiveEmployeeCount;
    this.InactiveEmployeeCount = InactiveEmployeeCount;
    this.NIEmployeeCount = NIEmployeeCount;
    this.PendingEmployeeCount = PendingEmployeeCount;
  }
}

export interface IUpdateVehicleTypeRequest {
  BulkUpdateEmployeeVehicle: IBulkUpdateEmployeeVehicle[];
}

export class UpdateVehicleTypeRequest implements IUpdateVehicleTypeRequest {
  BulkUpdateEmployeeVehicle: IBulkUpdateEmployeeVehicle[];
}


export interface IBulkUpdateEmployeeVehicle {
  EmployeeID: number;
  OrgID: number;
  RateType: number;
  VehicleTypeId: number;
  vehicleStatus: number;
  vehicleSubTypeId: number;
  rateType: number;
  orgVehicleTypeMapId: number;
  derivedName: string;
  userId: number;
  openingBalance: number;
  averageMiles: number;
  isEmailTrigger: boolean;
}

export class BulkUpdateEmployeeVehicle implements IBulkUpdateEmployeeVehicle {
  EmployeeID: number;
  OrgID: number;
  RateType: number;
  VehicleTypeId: number;
  vehicleStatus: number;
  vehicleSubTypeId: number;
  rateType: number;
  orgVehicleTypeMapId: number;
  derivedName: string;
  userId: number;
  openingBalance: number = 0;
  averageMiles: number = 0;
  isEmailTrigger: boolean = false;
  constructor(EmployeeID: number, VehicleTypeId: number, OrgID: number, RateType: number,
    vehicleStatus: number, vehicleSubTypeId: number, orgVehicleTypeMapId: number, derivedName: string, userId: number) {
    this.EmployeeID = EmployeeID;
    this.VehicleTypeId = VehicleTypeId;
    this.OrgID = OrgID;
    this.RateType = RateType;
    this.vehicleStatus = vehicleStatus;
    this.vehicleSubTypeId = vehicleSubTypeId;
    this.orgVehicleTypeMapId = orgVehicleTypeMapId;
    this.derivedName = derivedName;
    this.userId = userId;
  }
}

export interface IBulkUpdateEmployeeRole {
  EmployeeID: number;
  EmployeeRole: number;
}

export class BulkUpdateEmployeeRole implements IBulkUpdateEmployeeRole {
  EmployeeID: number;
  EmployeeRole: number;
  constructor(EmployeeID: number, EmployeeRole: number) {
    this.EmployeeID = EmployeeID;
    this.EmployeeRole = EmployeeRole;
  }
}

export interface IBulkUpdateEmployeeStatus {
  EmployeeID: number;
  Status: number;
}

export class BulkUpdateEmployeeStatus implements IBulkUpdateEmployeeStatus {
  EmployeeID: number;
  Status: number;
  constructor(EmployeeID: number, Status: number) {
    this.EmployeeID = EmployeeID;
    this.Status = Status;
  }
}


export interface IOrgVehicleTypeRequest {
  orgId: number;
  employeeId: number;
  rateType: number;
  purchaseFunctionList: number[];
}

export class OrgVehicleTypeRequest implements IOrgVehicleTypeRequest {
  orgId: number;
  employeeId: number;
  rateType: number;
  purchaseFunctionList: number[];
  constructor(
    orgId: number
  ) {
    this.orgId = orgId;
  }
}

export interface IOrgVehicleTypeResponse {
  orgID: number;
  mileageUOM: number;
  mileageUOMLabel: string;
  rateType: number;
  triangulationRule: number;
  vehicleTypes: IVehicleList[];
}
export class OrgVehicleTypeResponse implements IOrgVehicleTypeResponse {
  orgID: number;
  mileageUOM: number;
  mileageUOMLabel: string;
  rateType: number;
  triangulationRule: number;
  vehicleTypes: IVehicleList[];
}

export interface IDropdownOptionModel {
  id: any;
  value: any;
}

export class DropdownOptionModel implements IDropdownOptionModel {
  id: any;
  value: any;
  description: any;
  groupByField: any;
  disabled: boolean;
  isLink: boolean;
  constructor(id: any, value: any, description: any, groupByField: any, isLink: boolean) {
    this.id = id;
    this.value = value;
    this.description = description;
    this.groupByField = groupByField;
    this.isLink = isLink;
  }
}

export class EmployeesFormModel {
  employeeId: number;
  employeeFirstName: string;
  employeeLastName: string;
  employeeEmail: string;
  employeeRole: number;
  employeeCode: string;
  employeeCode2: string;
  employeeCode3: string;
  employeeCode4: string;
  approverId: number;
  costCentreId: number;
  orgVehicleTypeMapId: number;
  vehicleTypeId: number;
  vehicleStatus: number;
  vehicleSubTypeId: number;
  rateType: number;
  derivedName: string;
  advancedMileagePurchased: boolean;
  isCompanyFmPopup: boolean;
  employeeStatus: number;
  employeeStatusToggle: boolean;
  loginUserId: number;
  isFmOnly: boolean;
  ismultipleVehiclePurchased: boolean;
  costCentreRuleId:number;  
  userType:number;
  assignedExpCatIds:string;
  assignedFormIds:string;
  assignedExpenseCategories:number[];
  assignedForms:number[];  
}

export class InviteEmployeesInputModel {
  public title: string;
  public employeeId: number;
  public isEditMode: boolean;
  public isCompanyFmPopup: boolean;
  public isFmOnly: boolean;
  public isNotOverLayDiv: boolean;
}

export class InviteEmployeesOutputModel {
  public filterStatus: number;
}

export class EmployeeBulkActionInputModel {
  public checkedEmployeesList: IEmpList[];
  public filterStatus: number;
}

export class AssignNewApproverInputModel {
  public approveeList: IEmpList[];
  public approverList: IEmpList[];
  public oldApprover: IEmpList;
  public isProjectManager: boolean;
}

export class AssignApproverFormModel {
  public newApproverId: number;
}

export class AssignNewApproverOutputModel {
  public isProjectManagerUpdated: boolean;
}

export class EmployeeBulkActionOutputModel {
  public filterStatus: number;
}


export class AssignFmInputModel {
  public title: string;
}

export class AssignFmOutputModel {

}

export class AssignFmFormModel {
  employeeId: number;
}


//Bulk Save

export interface IImportEmployeeRequest {
  orgId: number;
  createdBy: number;
  fmName: string;
  fmEmail: string;
  fmUserId: number;
  employees: ImportEmployeeModel[];
}

export class ImportEmployeeRequest implements IImportEmployeeRequest {
  orgId: number;
  createdBy: number;
  fmName: string;
  fmEmail: string;
  fmUserId: number;
  employees: ImportEmployeeModel[];
  constructor(orgId: number, createdBy: number, fmName: string, fmEmail: string, fmUserId: number, employees: ImportEmployeeModel[]) {
    this.orgId = orgId;
    this.createdBy = createdBy;
    this.fmName = fmName;
    this.fmEmail = fmEmail;
    this.fmUserId = fmUserId;
    this.employees = employees;
  }
}

// Response
export interface IImportEmployeeResponse {
  NewGradeList: string[];
  NewCostCentreList: string[];
  ErrorEmployeeList: ImportEmployeeModel[];
  SuccessRecordsCounts: number;
  ChangedClaimantsApproverCounts: number;
  ChangedClaimantsGradeCounts: number;
  ChangedClaimantsCostCentreCounts: number;
  ChangedClaimantsLastNameCounts: number;
  ChangedClaimantsFirstNameCounts: number;
}

export class ImportEmployeeResponse implements IImportEmployeeResponse {
  NewGradeList: string[];
  NewCostCentreList: string[];
  ErrorEmployeeList: ImportEmployeeModel[];
  SuccessRecordsCounts: number;
  ChangedClaimantsApproverCounts: number;
  ChangedClaimantsGradeCounts: number;
  ChangedClaimantsCostCentreCounts: number;
  ChangedClaimantsLastNameCounts: number;
  ChangedClaimantsFirstNameCounts: number;
}

export interface IImportEmployeeModel {
  uniqueRowId: number;
  employeeId: number;
  employeeCode: string;
  employeeCode2: string;
  employeeCode3: string;
  employeeCode4: string;
  firstName: string;
  lastName: string;
  email: string;
  status: number;
  employeeRole: number;
  approverId: number;
  approverName: string;
  approverEmail: string;
  gradeName: string;
  gradeDescription: string;
  costCentreName: string;
  costCentreCode1: string;
  costCentreCode2: string;
  error: any;
  warning: any;
  failedErrors: any;
  failedWarnings: any;
}

export class ImportEmployeeModel implements IImportEmployeeModel {
  uniqueRowId: number = 0;
  employeeId: number = 0;
  employeeCode: string = "";
  employeeCode2: string = "";
  employeeCode3: string = "";
  employeeCode4: string = "";
  firstName: string = "";
  lastName: string = "";
  email: string = "";
  status: number = EmployeeStatusEnum.NotInvited;
  employeeRole: number = UserTypeEnum.Claimant;
  approverId: number = 0;
  approverName: string = "";
  approverEmail: string = "";
  gradeName: string = "";
  gradeDescription: string = "-";
  costCentreName: string = "";
  costCentreCode1: string = "";
  costCentreCode2: string = "";
  error: any;
  warning: any;
  failedErrors: any;
  failedWarnings: any;
}
