import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';

@Component({
  selector: 'formly-advance-policy',
  templateUrl: "./formly-advance-policy.component.html"
})
export class FormlyAdvancePolicyComponent extends FieldType implements OnInit, OnDestroy {
  isFormSubmitted: boolean = false;
  formSubmissionSubscription: Subscription;

  advanceCappingLimit = false;

  constructor(private dynamicFormService: DynamicFormService) {
    super();
  }

  ngOnInit() {

    if(this.model.advanceLimit) {
      this.advanceCappingLimit =true;
    }

    this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
      this.isFormSubmitted = data;
    });
  }

  ngOnDestroy() {
    if (this.formSubmissionSubscription) {
      this.formSubmissionSubscription.unsubscribe();
    }
  }

}
