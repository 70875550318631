<div class="input-group">
    <span>
        <input id="{{ 'loc' + fieldKey }}" autocomplete="off" type="text" class="form-control"
            [formControl]="formControl" [formlyAttributes]="field" (input)="onTextInput()" (click)="fnOnInputClick()"
            (keyup)="fnSearchAddress($event.target.value)" (blur)="fnCloseGoogleDropdown()"
            [ngClass]="{'has-value': formControl.value, 'padding-right-30' : fnIsMileageGoogleExpense(), 'disabled color-light-grey-disabledType': to.disabled }">

        <label [ngClass]="{'disabled color-light-grey-disabledType': to.disabled }" for="ExpenseCategory">
            {{field.templateOptions.label}}{{gm_lable}}<span *ngIf="to.required">*</span>
        </label>

        <span class="icons" *ngIf="fnIsMileageGoogleExpense() && !isToDelIconDisplay" (click)="fnSearchPlacePrediction()">
            <a [ngClass]="{'disabled color-light-grey-disabledType': to.disabled }">
                <i class="fas fa-search" aria-hidden="true"></i>
            </a>
        </span>
        <span class="icons" *ngIf="isToDelIconDisplay" (click)="fnDeleteText()">
            <a>
                <i class="fas fa-times-circle color-light-grey" aria-hidden="true"></i>
            </a>
        </span>

        <div class="dropdown-menu dropdown-menu-list" [ngClass]="{ active: isHomeOfcDropdownVisible }">
            <ul>
                <li (click)="fnSetAddressFromTriangulation(model.mileage.userHomeAddressInfo)">
                    <p>
                        <strong>{{'home' | translate }}</strong>
                    </p>
                    <p>
                        {{model.mileage?.userHomeAddressInfo?.address}}
                    </p>
                </li>
                <li (click)="fnSetAddressFromTriangulation(model.mileage.userOfficeAddressInfo)">
                    <p>
                        <strong>{{'office' | translate }}</strong>
                    </p>
                    <p>
                        {{model.mileage?.userOfficeAddressInfo?.address}}
                    </p>
                </li>
            </ul>
        </div>
        <div class="dropdown-menu dropdown-menu-list" [ngClass]="{ active: isGoogleDropdownVisible }">
            <ul *ngFor="let item of googleDynamicAddressList">
                <li (click)="fnSetAddress(item)"> {{item.description}} </li>
            </ul>
        </div>

        <div class="dropdown-menu dropdown-menu-list" [ngClass]="{ active: isGoogleAddressNotFound }">
            <ul>
                <li>{{'no_match_found' | translate }}</li>
            </ul>
        </div>

        <div *ngIf="formControl.errors && formControl.errors.required && isFormSubmitted" class="alert-danger">
            <span *ngIf="!formControl.value || !formControl.value.trim()">
                <b>{{ "label_Required" | translate }}</b>
            </span>
        </div>
    </span>

    <div class="float-left w-100pc margin-top-15 f-15" *ngIf="isCharLimit && this.model.mileage.googleCallsInfo">
        <div class="float-right text-right w-50pc">
            <a class="f-14">
                {{ this.model.mileage.googleCallsInfo.notesForGoogle }}
            </a>
        </div>
    </div>
    <div class="float-left w-100pc margin-top-15 f-15" *ngIf="field.key == 'toLocation'">
        <div class="float-left text-left w-50pc" *ngIf="fnIsMileageGoogleExpense()">
            <input type="checkbox" [(ngModel)]="hasReturnJourney" (input)="onReturnJourneyInput()">
            {{ 'return_journey' | translate }}
        </div>

        <div class="float-right text-right w-50pc text-logo-blue" (click)="fnOpenSelectRecentJourneyPanel()">
            <a class="f-14">
                <span>
                    <img src="../../assets/svg-icons/recent-journey-claimant.svg" class="svg-18" alt="">
                </span>
                {{ 'recent_journeys' | translate }}
            </a>
        </div>
    </div>

</div>

<select-recent-journeys-panel *ngIf="showSelectRecentJourneyPanel" [inputData]="selectRecentJourneyInputModel"
    (closePanelEvent)="fnCloseSelectRecentJourneyPanel($event)" (selectItemEvent)="fnSelectRecentJourney($event)">
</select-recent-journeys-panel>