<div *ngIf="isOnboardingFeedback || isTrialFeedback"></div>
<div class="overLayDiv active"></div>
<div id="rightSideBar"  *ngIf="isOnboardingFeedback || isTrialFeedback">
    <div class="rightSidePanel active" [ngClass]="isOnboardingFeedback ? 'w-60pc ' : 'w-50pc'">
        <header class="bg-logo-blue-marketing height-150px justify-content-start d-flex align-items-center">
            <div class="row">
                <div class="col-lg-12 no-padding-right-left f-36 fw-700 color-white text-capitalize">
                    <span class="w-100pc">{{ 'hello' | translate}} {{ userName }}!</span>
                    <span class="header-text d-block f-20">  {{ (isOnboardingFeedback ? 'your_onboarding_is_complete' : 'trial_feedback') | translate }}</span>

                </div>
            </div>
        </header>
        <div class="clear"></div>
        <div _ngcontent-qvs-c78=""
             class="block padding-top-0 ng-star-inserted" *ngIf="isOnboardingFeedback">
            <div _ngcontent-qvs-c78=""
                 class="col-lg-12 no-padding-right-left margin-top-0">
                <div _ngcontent-qvs-c78=""
                     class="row">
                    <h5 class="mt-3">
                        <strong>{{ 'onboarding_feedback' | translate}}</strong>
                    </h5>

                    <div class="col-lg-12 no-padding-right-left margin-top-30 padding-10 buttonHoverOnboarding">
                        <div _ngcontent-qvs-c78=""
                             class="w-100pc d-flex justify-content-center">
                            <div _ngcontent-qvs-c78="">
                                <button _ngcontent-qvs-c78=""
                                        [ngClass]="{ 'btn-click': isNoChecked }"
                                        (click)="onOptionChange(false)"
                                        class="fw-700">
                                    <span _ngcontent-qvs-c78=""
                                          class="padding-right-10"><i _ngcontent-qvs-c78=""
                                           class="far fa-desktop f-35 align-middle"
                                           aria-hidden="true"></i></span>
                                           {{ 'pricing_label_booknow' | translate}}
                                </button>
                            </div>
                            <div class="ml-4">
                                

                                <button _ngcontent-qvs-c78=""
                                        [ngClass]="{ 'btn-click': isYesChecked }"
                                        (click)="onOptionChange(true)"
                                        class="fw-700">
                                    <span _ngcontent-qvs-c78=""
                                          class="padding-right-10">
                                        <i _ngcontent-qvs-c78=""
                                           class="fal fa-smile f-35 align-middle"
                                           aria-hidden="true"></i> </span>{{ 'onboarding_feedback_yes' | translate}}
                                </button>
                            </div>
                        </div>

                        <div class="row text-center d-flex justify-content-center mt-3 color-red">
                            <strong>{{"onboarding_feedback_response_select" | translate}}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="block" *ngIf="isOnboardingFeedback">
            <div class="row margin-top-30 padding-bottom-30 virtualAssistant">
                <div class="d-flex justify-content-between w-100pc flex-column position-relative">
                    <span class="f-24 w-60pc margin-left-30 fw-600">{{ 'need_help_along' | translate}} <br />
                    {{"virtual_assistant_help" | translate}}
                    </span>

                    <span class="d-flex align-self-center position-absolute right-25">
                        <img src="../../../assets/images/chatbot.png"
                             alt=""
                             class="position-relative"
                             style="top: -28px !important" />
                    </span>
                </div>
            </div>
            <!-- <div class="row text-center margin-top-50">
                    <button class="btn btn-primary padding-10 mx-auto">Submit Response</button>
                </div> -->
        </div>

        <div class="block padding-top-0" *ngIf="isTrialFeedback">
            <div class="row padding-bottom-15">
                <div class="col-lg-12 no-padding-right-left margin-top-0">
                    <div class="row padding-bottom-15">
                        <div class="col-lg-12 no-padding-right-left margin-top-10">
                            <div class="row w-100pc">
                                <h6 class="border-bottom padding-bottom-10 w-100pc margin-bottom-10 w-100pc">
                                    {{ 'trial_feedback_question' | translate }}
                                </h6>
                                <div class="col-lg-6 padding-left-0">
                                    <div class="row">
                                        <label for="TrialYes" (click)="onOptionChange(true)">
                                            <input type="radio" id="TrialYes" name="TrialYes"
                                                class="align-middle" [checked]="isYesChecked">
                                            <span class="padding-left-10 logo-blue-marketing-text fw-700">{{
                                                'trial_feedback_yes' | translate }}</span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-lg-6 padding-left-0" (click)="onOptionChange(false)">
                                    <div class="row">
                                        <label for="TrialNo">
                                            <input type="radio" id="TrialNo" name="TrialNo" class="align-middle"
                                                [checked]="isNoChecked">
                                            <span class="padding-left-10 logo-blue-marketing-text fw-700">{{
                                                'trial_feedback_no' | translate }}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="block padding-10 position-absolute bottom-0">
            <div class="row text-center margin-top-0">
                <div class="d-flex justify-content-center w-100pc">
                    <span class="margin-left-0">
                        <button (click)="onSubmitFeedback()"
                                class="btn btn-primary padding-10 mx-auto"
                                [ngClass]="{ 'disabled-state': !isNoChecked && !isYesChecked }">
                                {{ 'submit_response2' | translate}}
                        </button></span>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showCalendly"
     class="overLayDiv active"></div>
<div id="rightSideBar">
    <div class="rightSidePanel w-50pc active"
         *ngIf="showCalendly">
        <header class="bg-logo-blue-marketing height-150px justify-content-start d-flex align-items-center">
            <div class="row">
                <div class="col-lg-12 no-padding-right-left f-36 fw-700 color-white text-capitalize">
                    <span class="w-100pc">{{ 'pricing_label_booknow' | translate}}</span>
                </div>
            </div>
        </header>
        <div class="clear"></div>

        <div class="block padding-0 padding-bottom-10  heightCalc-15">
            <div class="row virtualAssistant"
                 style="height: 100%">
                <div class="d-flex justify-content-between w-100pc flex-column position-relative">
                    <iframe src="https://calendly.com/expenseondemand/discoverycall"
                            width="100%"
                            height="100%"
                            frameborder="0"
                            style="border: 0"
                            allowfullscreen></iframe>
                </div>
            </div>
        </div>

        <div class="block padding-10 position-absolute bottom-0">
            <div class="row text-center margin-top-0">
                <div class="d-flex justify-content-center w-100pc">
                    <span class="margin-left-0">
                        <button (click)="showCalendly = false"
                                class="btn btn-primary padding-10 mx-auto">
                                {{ 'label_close' | translate}}
                        </button></span>
                </div>
            </div>
        </div>
    </div>
</div>