import { OnInit, Component, EventEmitter, Input, Output, ChangeDetectorRef } from "@angular/core";
import { ApiRequest, IApiresponse } from "src/app/_models";
import { SelectExpenseCategoryPopupModel } from "src/app/_models/claimant-on-web/claimant-on-web";
import { IRequestGetPolicyDetail, IResponsePolicyDetail, PolicyDetail, RequestGetPolicyDetail } from "src/app/_models/twostepapproval/claimant-expense-detail";
import { PolicyDataService } from "src/app/_services/policy/policy-data.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "view-exp-policy-panel",
    templateUrl: "./view-expense-policy-panel.component.html"
})
export class ViewExpensePolicyPanelComponent implements OnInit {
    @Input()
    inputData: SelectExpenseCategoryPopupModel;
    @Output() closePanelEvent = new EventEmitter<boolean>();
    param: any;
    policyDetails = <PolicyDetail[]>[];
    expDate: number;
    expenseCategoryName: string;
    constructor(private policyService: PolicyDataService, private cdRef: ChangeDetectorRef,) { }

    ngOnInit() {
        this.expenseCategoryName = this.inputData.expenseCategoryName;
        this.fnGetExpensePolicyData();
    }

    fnCloseExpensePolicyPanel() {
        this.closePanelEvent.emit(true);
    }

    fnGetExpensePolicyData() {
        let request = new ApiRequest<IRequestGetPolicyDetail>("GetPolicyDetail", environment.applicationVersion, environment.requestId);
        let requestInfo = new RequestGetPolicyDetail()
        requestInfo.orgExpenseCategoryId = this.inputData.orgExpenseCategoryId;
        requestInfo.expenseDate = this.inputData.expenseDate;
        requestInfo.userId = this.inputData.claimantId;
        request.requestInfo = requestInfo;
        this.policyService.GetPolicyDetail(request)
            .subscribe(
                (response: IApiresponse<IResponsePolicyDetail>) => {
                    if (response.statusCode == 0 && response.responseInfo != null) {
                        this.policyDetails = response.responseInfo.policy != null ? response.responseInfo.policy : [];
                        if (this.inputData.policyViolationForFM && this.policyDetails && this.policyDetails.length > 0) {
                            this.inputData.policyViolationForFM.forEach(element => {
                                this.policyDetails.forEach(item => {
                                    if (element == item.policyTypeForFM) {
                                        item.highlightedClass = 'color-red';
                                    }
                                    else
                                    {
                                        item.highlightedClass = '';
                                    }
                                });
                            });
                        }
                    }
                    this.cdRef.detectChanges();
                }
            );
    }
}
