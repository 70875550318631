import {
  Component,
  OnInit,
  HostListener,
  Inject,
  ElementRef,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
  NgZone,
  AfterViewInit,
} from "@angular/core";
import { DecimalPipe } from "@angular/common";
import { ToastrService } from "ngx-toastr";
import { first, map, tap } from "rxjs/operators";
import { Subject, Subscription } from 'rxjs';
import { IApiRequest, ApiRequest, IApiresponse } from "src/app/_models";
import { TranslateService } from "@ngx-translate/core";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { Claims } from "src/app/_models/common/claims";
import { environment } from "src/environments/environment";
import { PricingService } from "src/app/_services/pricing/pricing.service";
import { DomSanitizer, Meta, SafeHtml, Title } from "@angular/platform-browser";
import { FunctionCategoryEnum, FunctionCategoryNameEnum, FunctionPackageEnum } from "src/app/_models/enum/solo-enum";
import { AuthenticationService } from "src/app/_services";
import { websiteURl } from "src/app/common/commonvariables";
import {
  InfoModelPopup,
  InfoPanelOutput,
} from "src/app/_models/common/info-model-popup";
import {
  IPricingEmailQuoteRequest,
  PricingEmailQuoteRequest,
  QuoteModel,
  FunctionPackageQuote,
} from "src/app/_models/pricing/pricing";
import { ICountryCurrencyMappingResponse } from "src/app/_models/country-currency-mapping/country-currency-mapping";
import { CountryCurrencyMappingService } from "src/app/_services/country-currency-mapping/country-currency-mapping.service";
import { OrgSubscriptionFunctionService } from "src/app/_services/billing-pricing/Org-Subscription-Function.service";
import { OrgSubscription } from "src/app/_models/common/OrgSubscription";
import { Guid } from "guid-typescript";
import { OrgSubscriptionPurchaseFunctionList } from "src/app/_models/billing-pricing/IOrgSubscriptionPurchaseFunctionList";
import { NgScrollbar } from 'ngx-scrollbar';

@Component({
  selector: "pricing-external-list",
  templateUrl: "./pricing-external-list.component.html",
  styleUrls: ["./pricing-external-list.component.scss"],
})
export class PricingExternalListComponent implements OnInit, AfterViewInit, OnDestroy {
  claims: Claims;
  public param: string;
  paidFunctionPackageList: any = [];
  selectedFunctionPackageList: any = [];
  starterPackList: any[] = [];
  currencyId: number;
  currencyCode: string;
  currencySymbol: string;
  ipDetails: any;
  functionCategoryGroup: any[] = [];
  functionCategory: any[] = [];
  allFunctionCategoryGroup: any[] = [];
  functionCategorydropdownList: any[] = [];
  selectedFunctionCategoryGroup: any[] = [];
  showPricingExternalCalculatorPanel: boolean = false;
  isFreeTrialBoxDisplay: boolean = false;
  scrolling: boolean = false;
  websiteLink: string = websiteURl;
  infoPopupVisible: boolean = false;
  infoModel: InfoModelPopup;
  selectedFunctionCategory: any;
  searchText: string;
  pricingSummaryVisible: boolean = false;
  showMobilMenu: boolean = false;
  essentialPackAmount: String;
  orgID: any;
  functionPackageName: any[] = [];
  @ViewChild("containerSection") private containerSection: ElementRef;
  accessChargeFunction: any[];
  OrgPurchaseFunctionRequest: IApiRequest<OrgSubscription>;
  public UniqueId: Guid;
  accessAmount: any;
  PurchaseFunctionResponse: OrgSubscriptionPurchaseFunctionList;
  readonly size$ = new Subject();

  // Unsubscriber for elementScrolled stream.
  private _scrollSubscription = Subscription.EMPTY;

  // Get NgScrollbar reference
  @ViewChild(NgScrollbar, { static: true }) scrollbarRef: NgScrollbar;


  constructor(
    private toastr: ToastrService,
    private translate: TranslateService,
    private claimsService: ClaimsService,
    private pricingService: PricingService,
    private sanitizer: DomSanitizer,
    private authenticationService: AuthenticationService,
    private _decimalPipe: DecimalPipe,
    private meta: Meta,
    private title: Title,
    private countryCurrencyMappingService: CountryCurrencyMappingService,
    private ngZone: NgZone
  ) {
    this.claimsService.currentClaims.subscribe((claims) => {
      this.claims = claims;
    });
  }
  ngOnInit() {
    setTimeout(() => {
      this.title.setTitle("Customer Pricing Calculator");
      this.meta.updateTag({
        name: "description",
        content:
        this.translate.instant('meta_tag_pricing_description'),
      });
    }, 1000);
    this.authenticationService.logout();
    this.authenticationService.loadAccessToken();
    this.fnGetIpCliente();
    this.selectedFunctionCategory = 0;
    this.functionCategorydropdownList = this.allFunctionCategoryGroup; 
    this.UniqueId = Guid.create();
  }

  
  
  ngAfterViewInit() {
    this._scrollSubscription = this.scrollbarRef.verticalScrolled
      .pipe(
        map((e: any) => (e.target.scrollTop > 0 ? 'navbar-hidden' : '')),
        tap((size: string) => this.ngZone.run(() => this.size$.next(size)))
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._scrollSubscription.unsubscribe();
  } 
  
  fnGetOrgPricingFunctionList() {
    let currencyId = this.currencyId;
    this.pricingService
      .GetPricingFunctionListbyCurrencyCode(this.currencyCode)
      .subscribe(
        (data) => {
          if (data.responseInfo) {
            this.currencySymbol = data.responseInfo.orgPaymentCurrencySymbol;
            this.currencyId = data.responseInfo.orgPaymentCurrencyId;
              this.currencyCode = data.responseInfo.orgPaymentCurrencyCode;
              let accessChargeFunctions = data.responseInfo.paidFunctionPackageDetail
                  .filter(x => x.functionPackageId == FunctionPackageEnum.AccessCharge);
              data.responseInfo.paidFunctionPackageDetail = data.responseInfo.paidFunctionPackageDetail
                  .filter(x => x.functionPackageId != FunctionPackageEnum.AccessCharge);

              this.essentialPackAmount = data.responseInfo.essentialPackAmount;
              this.accessAmount = accessChargeFunctions[0].functionAmount;
              this.accessChargeFunction = accessChargeFunctions
                  .reduce(function (arr, item) {
                      arr[item.functionCategoryId] = arr[item.functionCategoryId] ||
                      {
                          functionCategoryId: item.functionCategoryId,
                          functionCategoryName: FunctionCategoryNameEnum[FunctionCategoryEnum[item.functionCategoryId]],
                          functionSelected: true,
                          functionPackage: []
                      };
                      item.selected = true;
                      arr[item.functionCategoryId].functionPackage.push(item);
                      return arr;
                  }, []);

              data.responseInfo.paidFunctionPackageDetail.sort((a, b) => (a.websiteFunctionPackageName < b.websiteFunctionPackageName) ? -1 : 1);

            data.responseInfo.paidFunctionPackageDetail.sort((a, b) =>
              a.websiteFunctionPackageName < b.websiteFunctionPackageName
                ? -1
                : 1
            );
            this.paidFunctionPackageList =
              data.responseInfo.paidFunctionPackageDetail;
            this.paidFunctionPackageList =
              data.responseInfo.paidFunctionPackageDetail.map((f) => ({
                ...f,
                selected: false,
              }));
            if (this.paidFunctionPackageList.length > 0) {
              this.paidFunctionPackageList = this.paidFunctionPackageList.sort(
                (a: any, b: any) => {
                  return (
                    Number(a.websiteFunctionPackageSequence) -
                    Number(b.websiteFunctionPackageSequence)
                  );
                }
              );
            }
            this.starterPackList = data.responseInfo.freeFunctionPackageDetail;
            this.functionPackageName = data.responseInfo.essentialPackList;
            this.fnFunctionCategoryPackagePriceList();
            this.functionCategorydropdownList = this.allFunctionCategoryGroup;
          }
        },
        (error) => {
          this.toastr.error(error);
        }
      );
  }
  sanitizerHtml(element) {
    let safeText = this.sanitizer.bypassSecurityTrustHtml(element);
    return safeText;
  }
  
  async fnGetIpCliente() {
    const response = await fetch("https://api.ipapi.is/?key=02107353329c92f8");
    const jsonData = await response.json();
    this.ipDetails = jsonData;
    if (this.ipDetails) {
      this.fnGetCountryCurrencyMapping(jsonData.location.country_code);
    }
  }

  countryList: ICountryCurrencyMappingResponse[] = [];

  fnGetCountryCurrencyMapping(countryCode) {
    this.countryCurrencyMappingService
      .GetCountryCurrencyMapping()
      .subscribe(
        (response: IApiresponse<ICountryCurrencyMappingResponse[]>) => {
          if (response.responseInfo != null) {
            this.countryList = response.responseInfo;

            let countryDetails = this.countryList.find(
              (c) => c.countryCode == countryCode
            );

            if (countryDetails) {
              this.currencyCode = countryDetails.currencyCode;
              this.fnGetOrgPricingFunctionList();
            } else {
              let defaultCurrencyDetails = this.countryList.find(
                (c) => c.countryName == "United States"
              );
              if (defaultCurrencyDetails) {
                this.currencyCode = defaultCurrencyDetails.currencyCode;
                this.fnGetOrgPricingFunctionList();
              }
            }
          }
        }
      );
  }

  isVisible() {
    let visible = false;
    if (localStorage.getItem("loginToken") && this.ipDetails) {
      if (this.currencyId && this.currencyCode) {
        visible = true;
      }
    }
    return visible;
  }
  isFunctionChecked(functionPackageId) {
    let selected = false;
    if (
      this.selectedFunctionPackageList &&
      this.selectedFunctionPackageList.length > 0
    ) {
      let isAvailable = this.selectedFunctionPackageList.find(
        (item) => item.functionPackageId == functionPackageId
      );
      if (isAvailable) {
        selected = true;
      }
    }
    return selected;
  }
  fnShowFuncDetailPopup(event: any, item: any) {
    let fCategory = this.allFunctionCategoryGroup.filter(
      (cat) => cat.functionCategoryId == item.functionCategoryId
    )[0];
    let functionAmout = this._decimalPipe.transform(
      item.functionAmount,
      "1.2-2"
    );
    let btnLabel =
      this.translate.instant("pricing_add_to_quote") +
      " - " +
      this.currencySymbol +
      "" +
      functionAmout;
    if (this.isVisible()) {
      this.infoPopupVisible = true;
      this.infoModel = new InfoModelPopup(
        item.functionPackageId,
        fCategory?.functionCategoryName,
        item.functionPackageName,
        item.functionPackageDescripition,
        "",
        "",
        this.sanitizer.bypassSecurityTrustResourceUrl(item.videoUrl),
        "",
        btnLabel,
        true
      );
    }
  }
  fnFunctionCategoryPackagePriceList() {
    // Group BY function package by function category
    this.functionCategoryGroup = this.paidFunctionPackageList.reduce(function (
      arr,
      item
    ) {
      arr[item.functionCategoryId - 1] = arr[item.functionCategoryId - 1] || {
        functionCategoryId: item.functionCategoryId,
        functionCategoryName:
          FunctionCategoryNameEnum[
            FunctionCategoryEnum[item.functionCategoryId]
          ],
        functionSelected: false,
        functionPackage: [],
      };
      arr[item.functionCategoryId - 1].functionPackage.push(item);
      return arr;
    },
    []);
    this.functionCategoryGroup = this.functionCategoryGroup.filter(
      (x) => x.functionCategoryId
    );
    this.functionCategoryGroup.sort((a, b) =>
      a.functionCategoryName < b.functionCategoryName ? -1 : 1
    );
    this.functionCategoryGroup.forEach((x) => {
      x.functionPackage.sort((a, b) =>
        a.websiteFunctionPackageName < b.websiteFunctionPackageName ? -1 : 1
      );
    });
    this.allFunctionCategoryGroup = this.functionCategoryGroup;
  }
  fnCloseInfoPopup() {
    this.infoPopupVisible = false;
  }
  fnUpdateSummary(event: any, item: any) {
   
    if (event?.target?.checked || !item.selected) {
      item.selected = true;
      this.selectedFunctionPackageList.push(item);
    } else {
      this.selectedFunctionPackageList =
        this.selectedFunctionPackageList.filter(
          (e) => e?.functionPackageId != item.functionPackageId
        );
    }
    this.selectedFunctionPackageList = this.selectedFunctionPackageList.slice();
    this.selectedFunctionCategoryGroup = this.fnCategoryGroupPackageList(
      this.selectedFunctionPackageList
    );
  }
  fnChangeFunctionCategory(selectedFunctionCategory: any) {
    if (selectedFunctionCategory == 0) {
      this.functionCategoryGroup = this.allFunctionCategoryGroup;
    } else {
      this.selectedFunctionCategory = selectedFunctionCategory;
      this.functionCategoryGroup = this.allFunctionCategoryGroup.filter(
        (cat) => cat.functionCategoryId == selectedFunctionCategory
      );
    }
  }
  searchTextKeypressEvent(event) {
    this.fnFunctionCategoryPackagePriceList();
    let text = event.target.value?.toUpperCase();
    let func = this.allFunctionCategoryGroup.filter((cat) =>
      cat?.functionCategoryName.toUpperCase().includes(text)
    );
    if (func.length > 0) {
      this.functionCategoryGroup = func;
    } else {
      let matched = this.paidFunctionPackageList
        .filter((x) =>
          x.websiteFunctionPackageName.toUpperCase().includes(text)
        )
        .map((m) => m.functionCategoryId);
      if (matched) {
        this.functionCategoryGroup = this.allFunctionCategoryGroup.filter(
          (fc) => matched.includes(fc.functionCategoryId)
        );
        this.functionCategoryGroup.forEach((fc) => {
          fc.functionPackage = fc.functionPackage.filter(
            (p) => p.websiteFunctionPackageName.toUpperCase().indexOf(text) > -1
          );
        });
      }
    }
    $("#pricing-ext-res .functions .right-col").attr(
      "style",
      "bottom:55% !important"
    );
  }
  fnGetTotalCost() {
    return this.fnGetSelectedFunctionCost();
  }
  fnGetSelectedFunctionCost() {
    let amount = 0;
    if (
      this.selectedFunctionPackageList &&
      this.selectedFunctionPackageList.length > 0
    ) {
      this.selectedFunctionPackageList.forEach((fn) => {
        let fnAmount = this._decimalPipe.transform(fn.functionAmount, "1.2-2");
        let decimalVal = +fnAmount;
        amount += decimalVal;
      });
    }
    return amount;
  }
  onInfoPanelBtnClick(result: InfoPanelOutput) {
    let item = this.paidFunctionPackageList.filter(
      (x) => x.functionPackageId == result?.panelKey
    )[0];
    if (
      this.selectedFunctionPackageList.some(
        (x) => x.functionPackageId == item.functionPackageId
      )
    ) {
      return;
    }
    this.selectedFunctionPackageList.push(item);
    this.infoPopupVisible = false;
    this.functionCategoryGroup
      .filter((x) => x.functionCategoryId == item.functionCategoryId)
      .forEach((fun) => {
        fun.functionPackage
          .filter((p) => p.functionPackageId == item.functionPackageId)
          .forEach((element) => {
            element.selected = true;
          });
      });
    this.selectedFunctionCategoryGroup = this.fnCategoryGroupPackageList(
      this.selectedFunctionPackageList
    );
  }
  fnRemoveFunPackage(functionCategory: any) {
    this.selectedFunctionPackageList = this.selectedFunctionPackageList.filter(
      (e) => e?.functionPackageId != functionCategory.functionPackageId
    );
    this.selectedFunctionCategoryGroup = functionCategory.functionCategoryGroup;
    this.functionCategoryGroup = functionCategory.functionCategoryGroup;
  }
  fnCategoryGroupPackageList(funPackage: any) {
    // Group BY function package by function category
    let functionCategoryGroup = funPackage.reduce(function (arr, item) {
      arr[item.functionCategoryId - 1] = arr[item.functionCategoryId - 1] || {
        functionCategoryId: item.functionCategoryId,
        functionCategoryName:
          FunctionCategoryNameEnum[
            FunctionCategoryEnum[item.functionCategoryId]
          ],
        functionPackage: [],
      };
      arr[item.functionCategoryId - 1].functionPackage.push(item);
      return arr;
    }, []);
    functionCategoryGroup = functionCategoryGroup.filter(
      (x) => x.functionCategoryId
    );
    functionCategoryGroup.sort((a, b) =>
      a.functionCategoryName < b.functionCategoryName ? -1 : 1
    );
    functionCategoryGroup.forEach((x) => {
      x.functionPackage.sort((a, b) =>
        a.websiteFunctionPackageName < b.websiteFunctionPackageName ? -1 : 1
      );
    });
    return functionCategoryGroup;
  }
  fnViewSummary() {
    this.fnFunctionCategoryPackagePriceList();
    this.pricingSummaryVisible = true;
    this.functionCategoryGroup.forEach((x) => {
      if (x.functionPackage.some((f) => f.selected)) {
        x.selected = true;
      }
    });
  }
  disableSummary() {
    let btnSummaryDisabled = false;
    this.allFunctionCategoryGroup.forEach((x) => {
      if (x.functionPackage.some((f) => f.selected)) {
        btnSummaryDisabled = false;
        return btnSummaryDisabled;
      }
    });
    return btnSummaryDisabled;
  }
  raisePricingQuote(quote: QuoteModel) {
    if (!quote.emailQuote) {
      this.discussPricingQuote(quote);
    } else {
      this.emailPricingQuote(quote);
    }
  }
  discussPricingQuote(quote: QuoteModel) {
    let request = new ApiRequest<QuoteModel>("DiscussPricingQuote", 1, "");
    request.requestInfo = quote;
    this.pricingService.DiscussPricingQuote(request).subscribe((data) => {
      if (data) {
        console.log("Discuss quote mail has sent.");
      }
    });
  }
  emailPricingQuote(quote: QuoteModel) {
    let functionPackageQuote = this.selectedFunctionPackageList.map(
      (x) =>
        new FunctionPackageQuote(
          x.functionPackageName,
          x.functionPackageId,
          x.functionAmount,
          this.currencySymbol + "" + x.functionAmount
        )
    );
    if(this.accessChargeFunction.length > 0)
    { 
      let accessChargeFunction =  this.accessChargeFunction[0]?.functionPackage[0]?.functionPackageId;
      if(!functionPackageQuote.some(x => x.functionCategoryId == accessChargeFunction))
      {
        let x = this.accessChargeFunction[0].functionPackage[0];
        let accessFunction = new FunctionPackageQuote(
          x.functionPackageName,
          x.functionPackageId,
          x.functionAmount,
          this.currencySymbol + "" + x.functionAmount
        ); 
        functionPackageQuote.push(accessFunction);
      }

    }
    let emailQuoteRequest = new PricingEmailQuoteRequest(
      quote.email,
      quote.business,
      quote.firstName,
      quote.lastName,
      quote.noOfEmployee,
      quote.hearAboutUs,
      quote.emailQuote,
      this.currencySymbol,
      this.currencyCode
    );
    emailQuoteRequest.functionPackages = functionPackageQuote;
    let request = new ApiRequest<IPricingEmailQuoteRequest>(
      "EmailPricingQuote",
      1,
      ""
    );
    request.requestInfo = emailQuoteRequest;
    this.pricingService.EmailPricingQuote(request).subscribe((data) => {
      if (data) {
        console.log("Email quote mail Success");
      }
    });
  }
  getPricingDescriptionText() {
    return this.sanitizerHtml(this.translate.instant("pricing_description"));
  }


  @HostListener("window:scroll", ["$event"]) getScrollHeight() {
    let pos1 = window.innerWidth;
    if (pos1 <= 767) {
      $("#pricing-ext-res .functions .right-col").removeAttr("style");
      return;
    }
    let pos =
      (document.documentElement.scrollTop || document.body.scrollTop) +
      document.documentElement.offsetHeight;

    if (pos > 600 && pos < 1100) {
      $("#pricing-ext-res .functions .right-col").attr("style", "");
    } 
    
    else if (pos > 1100 && pos < 1600) 
    {
      $("#pricing-ext-res .functions .right-col").attr("style", "z-index:100");
    } 
    
    else if (pos > 1600 && pos < 1700) 
    {
      $("#pricing-ext-res .functions .right-col").attr("style", "z-index:100");
    } 
    
    
    
    else if (pos > 1700 && pos < 1800) {
      $("#pricing-ext-res .functions .right-col").attr("style", "z-index:100");
    }   

  
    
    if (
      this.functionCategoryGroup.length == 0 ||
      this.functionCategoryGroup.length == 1
    ) {
      $("#pricing-ext-res .functions .right-col").attr(
        "style",
        "bottom:65% !important;"
      );
    }
  }



@HostListener('window:resize', ['$event'])
    onResize(event) {
        event.target.innerWidth;
        if (this.functionCategoryGroup.length == 1) {
            $('#pricing-ext-res .functions .right-col').attr('style', 'top:65%');
        }
        let pos1 = window.innerWidth;
        if(pos1 <= 767)
        {
            $('#pricing-ext-res .functions .right-col').removeAttr('style');
            return;
        }
      
    }

  resetSelectedFunction() {
    this.selectedFunctionPackageList = [];
    this.functionCategoryGroup.forEach((x) => {
      x.functionPackage.forEach((f) => {
        f.selected = false;
      });
    });
  } 
}