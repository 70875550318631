import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ClaimsService } from 'src/app/_services/common/claims.service';
import { IApiresponse } from 'src/app/_models';
import { ClaimsHeaderService } from 'src/app/shared/claimsHeader/claims-header.service';

import { Claims } from 'src/app/_models/common/claims';
import { environment } from '../../../environments/environment';
import { IRecommendation, IOrgRecommendation, IExpenseRecommendationDetail } from 'src/app/_models/recommendation/recommendation';

@Injectable({
  providedIn: 'root'
})
export class RecommendationService {
  claims: Claims;
  constructor(private http: HttpClient, private claimsHeaderService: ClaimsHeaderService
    , private claimsService: ClaimsService) {
    this.claimsService.currentClaims.subscribe(claims => this.claims = claims);
  }

  getAllRecommendation() {

    return this.http.get<IApiresponse<IRecommendation[]>>(
      `${environment.catalogApiUrl}GetAllRecommendations`,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  getOrgRecommendation(orgId, recommId) {

    let params = new HttpParams();
    params = params.append("orgId", orgId);

    return this.http.get<IApiresponse<IOrgRecommendation[]>>(
      `${environment.catalogApiUrl}GetOrgRecommendation/${recommId}`,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader(), params: params }
    );
  }

  getExpenseRecommendation(orgRecommId, monthDuration) {

    let params = new HttpParams();
    params = params.append("orgRecommId", orgRecommId);
    params = params.append("monthDuration", monthDuration);

    return this.http.get<IApiresponse<IExpenseRecommendationDetail[]>>(
      `${environment.expenseApiUrl}GetExpenseRecomm`,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader(), params: params }
    );
  }

  SnoozeOrgRecommendation(orgRecommId) {

    return this.http.get<IApiresponse<IExpenseRecommendationDetail[]>>(
      `${environment.catalogApiUrl}SnoozeOrgRecommendation/${orgRecommId}`,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

}
