import { Component, OnInit, Input, ElementRef, Output, EventEmitter } from "@angular/core";

@Component({
    selector: "app-pdf-viewer",
    templateUrl: "./pdf-viewer.component.html",
    styleUrls: ['./pdf-viewer.component.scss']
})

export class PdfViewerComponent implements OnInit {

    @Input() pdfUrl: string;
    @Input() pdfName: string;
    @Input() pdfExpenseReceiptId: number;
    @Input() showPdfViewerPanel: boolean;
    @Input() showDeleteButton: boolean = false;
    @Input() isOverLayHide: boolean = false;
    @Input() panelWidth: string = "w-80pc";
    
    @Input() claimType: number = 1;
    @Input() isClaimPage: boolean = false;

    constructor() { }

    ngOnInit() {}

    @Output() callbackEvent = new EventEmitter<boolean>();
    @Output() deletePdfEvent = new EventEmitter<number>();


    dismiss() {
        this.showPdfViewerPanel = false;
        this.pdfUrl = "";
        this.pdfName = "";
        this.pdfExpenseReceiptId = 0;
        this.callbackEvent.emit(false);
    }

    delete() {
        this.showPdfViewerPanel = false;
        this.pdfUrl = "";
        this.pdfName = "";
        this.deletePdfEvent.emit(this.pdfExpenseReceiptId);
    }

    downloadReceipt() {
       // window.open(this.pdfUrl);
        let pdfWindow = window.open("");
        pdfWindow.document.write("<html><head><title>'"+this.pdfName+"'</title></head><body>" + "<iframe src='" + this.pdfUrl + "' width='100%' height='100%' style='border: none;' type='application/pdf' ></iframe>");
    
    }
} 
