<span>
    {{ claimantName }}
    <span [hidden]="!approverAsDeputy" class="text-logo-blue fw-700">
        ({{ 'label_deputy' | translate }})
        <em class="fas fa-info-circle text-mustard" [ngbPopover]="asaContent" triggers="mouseenter:mouseleave"
            placement="right-bottom" container="body"></em>
        <ng-template #asaContent>
            <div class="popoverCustomWidth">
                <p>
                    {{ 'since_deputy_for' | translate }} {{actualApproverName}} {{ 'deputy_msg' | translate
                    }}
                </p>
            </div>
        </ng-template>
    </span>
    <span [hidden]="!approverAsCA" class="text-logo-blue fw-700">
        ({{ 'counter_approver' | translate }})
        <em class="fas fa-info-circle text-mustard" [ngbPopover]="caContent" triggers="mouseenter:mouseleave"
            placement="right-bottom" container="body"></em>
        <ng-template #caContent>
            <div class="popoverCustomWidth">
                <p>
                    {{ 'counterapp_msg' | translate }} {{actualApproverName}}'s' {{ 'counter_approver' | translate
                    }}
                </p>
            </div>
        </ng-template>
    </span>


    <span *ngIf="isExpenseEditedByFMIconDisplay && isExpenseEditedByFM" class="text-logo-blue fw-700">
        <em class="fas fa-solid fa-pencil color-black padding-LR-5" placement="right" container="body"
        ngbTooltip="{{ 'fm_edit_expense_for_msg12' | translate }}" tooltipClass="my-custom-class-additional-fields">
    </em>
    </span>

    <span [hidden]="isOtherPolicyDisplay">
        <span class="policyViolationPopOver">
            <span [hidden]="!isPolicyViolated || !isPolicyIconDisplay ">
                <em class="fas fa-exclamation-triangle icon-Orange padding-LR-5" placement="right" container="body"
                    ngbTooltip="{{violationTooltip}}" tooltipClass="my-custom-class-additional-fields">
                </em>
            </span> 
        </span>
    </span>
    <span [hidden]="!isOtherPolicyDisplay">
        <ng-template #htmlContent>
            <div class="popoverCustomWidth">
                <p *ngFor="let item of policyArray">
                    # {{ item | translate }}<br />
                </p>
            </div> 
        </ng-template>
        <span class="policyViolationPopOver">
            <em [hidden]="!isPolicyViolated" class="fas fa-exclamation-triangle icon-Orange" [ngbPopover]="htmlContent"
                popoverTitle="{{breachTooltip}}" triggers="mouseenter:mouseleave"
                placement="right-bottom" container="body"></em>
        </span>
    </span>
</span>
