import { Mileage } from "../claimant-on-web/claimant-on-web";
import { IMileageRateInfo } from "../manage-emp/manage-emp";
import { CustomFields } from "../manage-category/manage-category-list-response";
import { TaxDetails } from "../tax-rate/tax-details";
import { ManualVatDetail } from "./manual-vat-detail";

export interface IRequestGetPolicyDetail {
  orgExpenseCategoryId: number;
  expenseDate: number;
  userId: number;
}
export class RequestGetPolicyDetail implements IRequestGetPolicyDetail {
  orgExpenseCategoryId: number;
  expenseDate: number;
  userId: number;
}
export interface IPolicyDetail {
  policyName: string;
  policyType: number;
  policyValue: number;
  functionId: number;
  notes: string;
  highlightedClass: string;
  policyTypeForFM: number;
}

export class PolicyDetail implements IPolicyDetail {
  policyName: string;
  policyType: number;
  policyValue: number;
  functionId: number;
  notes: string;
  highlightedClass: string;
  policyTypeForFM: number;
}
export class IResponsePolicyDetail {
  policy: IPolicyDetail[];
}
export class ResponsePolicyDetail implements IResponsePolicyDetail {
  policy: IPolicyDetail[];
}

export interface IRequestPolicyViolation {
  expenseId: number;
}

export class RequestPolicyViolation implements IRequestPolicyViolation {
  expenseId: number;
}

export interface IRequestFraudDetails {
  expenseId: number;
}

export class RequestFraudDetails implements IRequestFraudDetails {
  expenseId: number;
}

export interface IResponsePolicyViolation {
  policyViolation: IPolicyViolation;
}

export class ResponsePolicyViolation implements IResponsePolicyViolation {
  policyViolation: IPolicyViolation;
}

export interface IResponseFraudDetails {
  lstExpenses: IPotentialFraudDetails[];
}

export class ResponseFraudDetails implements IResponseFraudDetails {
  lstExpenses: IPotentialFraudDetails[];
}

export interface IPotentialFraudDetails {
  expenseId: number;
  userId: number;
  displayName: string;
  supplierName: string;
  supplierId: number;
  expenseDate: number;
  expenseAmount: number;
  receiptInfo: IReceiptInfo[];
  viewMapDetail: IMileageInfo[];
}

export interface IPolicyViolation {
  duplicate: IDuplicatePolicy[];
  perNightLimit: IPerItemLimit;
  perPersonLimit: IPerItemLimit;
  dailyCappingLimit: IPerItemLimit;
  monthlyCappingLimit: IPerItemLimit;
  yearlyCappingLimit: IPerItemLimit;
  overallDailyLimit: IOverallLimit;
  expenseTimeLimit: IExpenseTimeLimitViolation;
  vatNovatReceiptType: IVatNovatReceiptType;
  expenseOperationalInfo: ExpenseOperationalInfo[];
  defaultVehicleType: DefaultVehicleType;
}

export class PolicyViolation implements IPolicyViolation {
  duplicate: IDuplicatePolicy[];
  perNightLimit: IPerItemLimit;
  perPersonLimit: IPerItemLimit;
  dailyCappingLimit: IPerItemLimit;
  monthlyCappingLimit: IPerItemLimit;
  yearlyCappingLimit: IPerItemLimit;
  overallDailyLimit: IOverallLimit;
  expenseTimeLimit: IExpenseTimeLimitViolation;
  vatNovatReceiptType: IVatNovatReceiptType;
  expenseOperationalInfo: ExpenseOperationalInfo[];
  defaultVehicleType: DefaultVehicleType;
}

export interface IDefaultVehicleType {
  defaultVehicle: string;
  selectedVehicle: string;
}

export class DefaultVehicleType implements IDefaultVehicleType {
  defaultVehicle: string;
  selectedVehicle: string;
}

export interface IExpenseOperationalInfo {
  fieldId: number;
  expenseId: number;
  fieldTitle: string;
  fieldValue: boolean;
  fieldType: number;
  isMandatory: boolean;
  isPolicyViolated: boolean;
  fieldValueString: string;
}

export class ExpenseOperationalInfo implements IExpenseOperationalInfo {
  fieldId: number;
  expenseId: number;
  fieldTitle: string;
  fieldValue: boolean;
  fieldType: number;
  isMandatory: boolean;
  isPolicyViolated: boolean;
  fieldValueString: string;
}

export interface IExpenseTimeLimitViolation {
  expenseTimeLimit: number;
}

export class ExpenseTimeLimitViolation implements IExpenseTimeLimitViolation {
  expenseTimeLimit: number;
}

export interface IPerItemLimit {
  limit: number;
  spentAmount: number;
  currencyId: number;
  spentCurrencyId: number;
  violation: number;
  violationDate: number;
  expenseItems: number;
  categoryName: string;
}
export class PerItemLimit implements IPerItemLimit {
  limit: number;
  spentAmount: number;
  currencyId: number;
  spentCurrencyId: number;
  violation: number;
  violationDate: number;
  expenseItems: number;
  categoryName: string;
}

export interface IOverallLimit {
  dailiLimit: number;
  amountSpent: number;
  currencyId: number;
  spentCurrencyId: number;
  violation: number;
}

export class OverallLimit implements IOverallLimit {
  dailiLimit: number;
  amountSpent: number;
  currencyId: number;
  spentCurrencyId: number;
  violation: number;
}

export interface IDuplicatePolicy {
    expenseId: number;
    expenseCategoryId: number;
    expenseDate: number;
    expenseAmount: number;
    spentCurrencyId: number;
    currencyId: number;
    receiptNumber:string;
    receiptInfo: IReceiptInfo[];
    viewMapDetail: IMileageInfo[];
}

export class DuplicatePolicy implements IDuplicatePolicy {
    expenseId: number;
    expenseCategoryId: number;
    expenseDate: number;
    expenseAmount: number;
    spentCurrencyId: number;
    currencyId: number;
    receiptNumber:string;
    receiptInfo: IReceiptInfo[];
    viewMapDetail: IMileageInfo[];
}

export interface IVatNovatReceiptType {
  receiptJustificationNotes: string;
  vatNoVatReceiptTypeId: number;
  vatNoVatReceiptTypeName: string;
}

export class VatNovatReceiptType implements IVatNovatReceiptType {
  receiptJustificationNotes: string;
  vatNoVatReceiptTypeId: number;
  vatNoVatReceiptTypeName: string;
}

export interface IMileageInfo {
  fromLocation: ILocationInfo;
  toLocation: ILocationInfo;
  gpsLocation: ILocationInfo[];
  route: ILocationInfo[];
  returnRoute: ILocationInfo[];
  distance: number;
  returnDistance: number;
  totalDistance: number;
  mileageUOM: number;
  hasReturnJourney: boolean;
  mileageRate: number;
  detour: number;
  detourJustificationNotes: string;
  triangulatedDistance: number;
  returnTriangulatedDistance: number;
  totalTriangulatedDistance: number;
  milesTraveled: number;
  returnMilesTraveled: number;
  totalMilesTraveled: number;
  isMultiRange: boolean;
  mileageRateInfo: IMileageRateInfo[];
}

export class MileageInfo implements IMileageInfo {
  fromLocation: ILocationInfo;
  toLocation: ILocationInfo;
  gpsLocation: ILocationInfo[];
  route: ILocationInfo[];
  returnRoute: ILocationInfo[];
  distance: number;
  returnDistance: number;
  totalDistance: number;
  mileageUOM: number;
  hasReturnJourney: boolean;
  mileageRate: number;
  detour: number;
  detourJustificationNotes: string;
  triangulatedDistance: number;
  returnTriangulatedDistance: number;
  totalTriangulatedDistance: number;
  milesTraveled: number;
  returnMilesTraveled: number;
  totalMilesTraveled: number;
  isMultiRange: boolean;
  mileageRateInfo: IMileageRateInfo[];
}

export interface ILocationInfo {
  location: string;
  latitude: number;
  longitude: number;
  locationTrackTime: number;
  placeId: string;
}
export class LocationInfo implements ILocationInfo {
  location: string;
  latitude: number;
  longitude: number;
  locationTrackTime: number;
  placeId: string;
}

export interface IMapDetails {
  gpsLocation: ILocationInfo[];
  route: ILocationInfo[];
  returnRoute: ILocationInfo[];
}

export class MapDetails implements IMapDetails {
  gpsLocation: ILocationInfo[];
  route: ILocationInfo[];
  returnRoute: ILocationInfo[];
}

export interface IClaimantExpenseDetailResponse {
    expenseId: number;
    expenseDate: number;
    orgExpenseCategoryId: number;
    baseCategoryId: number;
    stateId: number;
    expenseCategory: string;
    noOfEmployees: number;
    noOfGuests: number;
    noOfRooms: number;
    noOfNight: number;
    billAmount: number;
    tips: number;
    amount: number;
    orgId: number;
    currencyId: number;
    notes: string;
    supplier: string;
    paymentTypeId: number;
    hasReceipt: boolean;
    hasTags: boolean;
    hasVoiceMeno: boolean;
    Tags: string;
    bpValue: string;
    workflowCount: number;
    approverNotes: IApproverNotes[];
    taxRate: number;
    isPolicyViolated: boolean;
    receipts: IReceiptInfo[];
    mileage: Mileage;
    employeesTaxRate: number;
    guestTaxRate: number;
    tipsTaxRate: number;
    customerName: string;
    isWorkflowAvailable: boolean;
    expenseCategoryId: number;
    taxAmount: number;
    expenseManualVatList: ManualVatDetail[];
    reasonForVATChange: string;
    vatNoVatReceiptTypeId: number;
    receiptJustificationNotes: string;
    additionalFields: IDataSave[];
    projectBudgetDetailsName: string;
    projectDetailsName: string;
    entityType: number;
    spentAmount: number;
    spentCurrencyId: number;
    conversionRate: number;
    isManualVatPanelVisible: boolean;
    paymentType: string;
    expenseDateString: string;
    attendeeList: AttendeeList;
    cardNumber: string;
    cardReferenceNo: string;
    cardDescription: string;
    currentStatus: number;
    taxDetails: TaxDetails
    newCostCentreName: string;
    bpCaption: string;
    newCostCentreCode1: string;
    customerCode1: string;
    isTipsEnabled: boolean;
    customFields: CustomFields[];
    costCentreDerivedName: string;
    isApproverNotesMandatory:boolean;
    receiptNumber:string;
    isExpenseEditedByFM: boolean;
    exportId: number;
    isCFPReporting: boolean;
}

export class ClaimantExpenseDetailResponse implements IClaimantExpenseDetailResponse {
    expenseId: number;
    expenseDate: number;
    orgExpenseCategoryId: number;
    baseCategoryId: number;
    stateId: number;
    expenseCategory: string;
    noOfEmployees: number;
    noOfGuests: number;
    noOfRooms: number;
    noOfNight: number;
    billAmount: number;
    tips: number;
    amount: number;
    orgId: number;
    currencyId: number;
    notes: string;
    supplier: string;
    paymentTypeId: number;
    bpValue: string;
    hasReceipt: boolean;
    hasTags: boolean;
    hasVoiceMeno: boolean;
    Tags: string;
    workflowCount: number;
    approverNotes: IApproverNotes[];
    taxRate: number;
    isPolicyViolated: boolean;
    receipts: IReceiptInfo[];
    mileage: Mileage;
    employeesTaxRate: number;
    guestTaxRate: number;
    tipsTaxRate: number;
    customerName: string;
    isWorkflowAvailable: boolean;
    expenseCategoryId: number;
    additionalFields: IDataSave[];
    taxAmount: number;
    expenseManualVatList: ManualVatDetail[];
    reasonForVATChange: string;
    vatNoVatReceiptTypeId: number;
    receiptJustificationNotes: string;
    projectBudgetDetailsName: string;
    projectDetailsName: string;
    entityType: number;
    spentAmount: number;
    spentCurrencyId: number;
    conversionRate: number;
    isManualVatPanelVisible: boolean;
    paymentType: string;
    expenseDateString: string;
    attendeeList: AttendeeList;
    cardNumber: string;
    cardReferenceNo: string;
    cardDescription: string;
    currentStatus: number;
    taxDetails: TaxDetails;
    newCostCentreName: string;
    bpCaption: string;
    newCostCentreCode1: string;
    customerCode1: string;
    isTipsEnabled: boolean;
    customFields: CustomFields[];
    costCentreDerivedName: string;
    isApproverNotesMandatory:boolean;
    receiptNumber:string;
    isExpenseEditedByFM: boolean;
    exportId: number;
    isCFPReporting: boolean;
}

export interface IDataSave {
  fieldValueId: number;
  fieldValue: string;
  fieldName: string;
}

export class DataSave implements IDataSave {
  fieldValueId: number;
  fieldValue: string;
  fieldName: string;
}

export interface IReceiptInfo {
  expenseReceiptId: number;
  receiptName: string;
  action: number;
  isDeletable: boolean;
  receiptDate: number;
  receiptLocationInfo: IReceiptLatLong;
  receiptUrl: string;
  receiptFor: number;
  isEmailedReceipt: boolean;
  receiptFormatId: number;
  receiptSize: number;
}

export class ReceiptInfo implements IReceiptInfo {
  expenseReceiptId: number;
  receiptName: string;
  action: number;
  isDeletable: boolean;
  receiptDate: number;
  receiptLocationInfo: ReceiptLatLong = new ReceiptLatLong();
  receiptUrl: string;
  receiptFor: number;
  isEmailedReceipt: boolean;
  receiptFormatId: number;
  receiptSize: number;
}

export interface IReceiptLatLong {
  location: string;
  latitude: number;
  longitude: number;
}

export class ReceiptLatLong implements IReceiptLatLong {
  location: string;
  latitude: number;
  longitude: number;
}

export interface IApproverNotes {
  actionString: number;
  justificationNotes: string;
  justificationNotesLength: number;
  createdOn: number;
}

export class ApproverNotes implements IApproverNotes {
  actionString: number;
  justificationNotes: string;
  justificationNotesLength: number;
  createdOn: number;
}

export class EmpList {
  employeeId: number;
  name: string;
  emailId: string;
  costCenterId: number;
  costCenterName: string;
  customerId?: any;
  customerName?: any;
}

export class AttendeeList {
  empList: EmpList[];
  guestList: EmpList[];
}

export class ReceiptWithMultipleRatesPanelInputModel {
  amount: any;
  taxAmount: any;
  updatedAmount: any;
  reasonForVATChange: string;
  manualVatInfo: any;
  expenseManualVatList: any;
  receiptInfo: IReceiptInfo[];
  receiptCount: number = 0;
  currency: any;
  claimType: any;
}

export class ReceiptWithMultipleRatesPanelOutputModel {
  reasonForVATChange: string;
  expenseManualVatList: any;
  updatedAmount: number;
}

export class MultipleLineFormModel {
  amount: any;
  taxAmount: any;
  updatedAmount: any;
  updatedAmountText: any;
  reasonForVATChange: string;
  manualVatInfo: any;
  expenseManualVatList: any;
}
