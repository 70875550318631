<header id="page-topbar">
    <div class="navbar-header">
        <div class="d-flex w-100pc" >
            <!-- LOGO -->
            <div *ngIf="claims.orgRoleId == 3" class="navbar-brand-box"
                 style="background: white;">
                <a   href="javascript: void(0)"
                   class="logo logo-dark">
                    <span class="logo-sm">
                        <img src="assets/images/solo-logo.png"
                             alt=""
                             height="22">
                    </span>
                    <span class="logo-lg">
                        <img [src]="imageSource"
                             alt=""
                             height="23"
                             style="max-width: auto;">
                    </span>
                </a>

                <a   href="javascript: void(0)"
                   class="logo logo-light d-flex justify-content-center">
                    <span class="logo-sm">
                        <img src="assets/images/solo-logo.png" alt="" height="22">
                    </span>
                    <span class="logo-lg">
                        <img  [src]="imageSource" alt="" height="23" width="auto" style="max-width: 200px" >
                    </span>
                </a>
            </div>

            <!-- <button type="button" style="border-color: transparent;"
                    class="btn btn-sm px-3 font-size-16 vertinav-toggle header-item waves-effect"
                    id="vertical-menu-btn"
                    (click)="toggleMobileMenu($event)">
                <i class="fa fa-fw fa-bars"></i>
            </button> -->

         

            <!-- App Search-->
            <!-- <form class="app-search d-none d-lg-block">
                <div class="position-relative">
                    <input type="text"
                           class="form-control"
                           placeholder="{{'HEADER.SEARCH' | translate}}">
                    <span class="mdi mdi-magnify"></span>
                </div>
            </form> -->
            <app-nav-menu class="w-100pc" (mobileMenuButtonClicked)="toggleMobileMenu()" (toggleRightSidebarEvent)="toggleRightSidebar()"></app-nav-menu>

        </div>

<!--         

        <div class="d-flex">


            <div class="dropdown d-inline-block">
                <button type="button"
                        class="btn header-item noti-icon right-bar-toggle waves-effect"
                        (click)="toggleRightSidebar()">
                    <i class="bx bx-cog bx-spin"></i>
                </button>
            </div> 
        </div>-->

    </div>
</header>