import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { TranslateService } from '@ngx-translate/core';
import { ClaimsService } from '../../../../_services/common/claims.service';
import { Claims } from '../../../../_models/common/claims';

@Component({
  selector: 'app-policy-amount-renderer',
  templateUrl: './policy-amount-renderer.component.html',
})
export class PolicyAmountRendererComponent implements ICellRendererAngularComp {
  public isOtherPolicyDisplay: boolean = false;
  public isPolicyIconDisplay: boolean = false;  
  public isPolicyViolated: boolean = false;
  public policyArray: string[] = [];  
  public amount: number;
  public exhangeRateTooltip: string;
  public hasDetour: boolean = false;
  violationTooltip: string;
  breachTooltip: string;
  claims: Claims;
  public potentialFraud :boolean = false;
  public iconDisplay : boolean = false;
  public violationDisplay : boolean = false;
  public isPotentialFraudDisplay: boolean = false;
  public isExpenseEditedByFM: boolean = false;
  public IsRecalledExpense: boolean = false;
  public RecalledExportId: number;
  public RecalledExpenseDate: any;

  constructor(private translate: TranslateService,
    private claimsService: ClaimsService,
    private sanitizer: DomSanitizer 
    ) {
      this.claimsService.currentClaims.subscribe(claims => this.claims = claims);
  }

  initilize(params: ICellRendererParams) {
    if (params.colDef && params.colDef.headerComponentParams) {
      this.isPotentialFraudDisplay = params.colDef.headerComponentParams.isPotentialFraudDisplay;
      this.isOtherPolicyDisplay = params.colDef.headerComponentParams.isOtherPolicyDisplay;
      this.isPolicyIconDisplay = params.colDef.headerComponentParams.isPolicyIconDisplay;      
    }
    if (params.data) { 
      this.policyArray = params.data.policyArray;
      this.amount = params.data.amount;
      this.isPolicyViolated = params.data.isPolicyViolated || (params.data.potentialFraud && this.isPotentialFraudDisplay);
      this.potentialFraud = params.data.potentialFraud; 
      if (params.data.exhangeRateTooltip) {
        this.exhangeRateTooltip = params.data.exhangeRateTooltip;
      }
      if (params.data.hasDetour) {
        this.hasDetour = params.data.hasDetour;
      }
      if (params.data.isExpenseEditedByFM) {
        this.isExpenseEditedByFM = params.data.isExpenseEditedByFM;
      }
      if (params.data.isRecalledExpense) {
        this.IsRecalledExpense = params.data.isRecalledExpense;
        this.RecalledExpenseDate = params.data.recalledExpenseDate;
        this.RecalledExportId  = params.data.recalledExportId;
      }

      if(params.data.advanceId || params.data.advancesCount) {
        this.violationTooltip = this.translate.instant('advance_policy_violation');
        this.breachTooltip = this.translate.instant('advance_policy_breach');
      }
      else {
        if(this.potentialFraud && params.data.isPolicyViolated)
           this.violationTooltip = this.translate.instant('policy_violation_potential_fraud');
        if(params.data.isPolicyViolated && !this.potentialFraud)
           this.violationTooltip = this.translate.instant('expense_policy_violation');
        if(this.potentialFraud && !params.data.isPolicyViolated)
           this.violationTooltip = this.translate.instant('potential_fraud');
        this.breachTooltip = this.translate.instant('expense_policy_breach');
      }
    }
  }

  // called on init
  agInit(params: any): void {
    this.initilize(params);
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.initilize(params);
    return true;
  }

  sanitizerHtml(element) {
    let safeText = this.sanitizer.bypassSecurityTrustHtml(element);
    return safeText;
  }

}
