import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import {
  HttpClientModule,
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpEventType
} from "@angular/common/http";
import { AlertPanelModel } from '../../../../_models/common/alertPanelModel';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'formly-currency-image-uploder',
  templateUrl: "./formly-currency-image-uploder.component.html"
})
export class FormlyCurrencyImageUploderComponent extends FieldType {

  fieldKey: string;
  size: number;
  width: number;
  height: number;
  imgUrl: string;
  imgName: string;
  alertPanelModel = new AlertPanelModel(false, "", [""], "", "", true, this.translate.instant("please_review_or_confirm"));
  public message: string;
  ShowErrorPanel: boolean = false;
  formSubmissionSubscription: Subscription;
  isFormSubmitted: boolean = false;
  filedata: any;

  constructor(private translate: TranslateService, private dynamicFormService: DynamicFormService) {
    super();
  }

  ngOnInit() {
    if (this.field.key) {
      this.fieldKey = this.field.key.toString();
    }
    this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
      this.isFormSubmitted = data;
    });
    this.imgUrl = this.field.templateOptions.orgLogo;
    //this.formControl.setValue(this.field.templateOptions.orgLogo);
  }

  ngOnDestroy() {
    if (this.formSubmissionSubscription) {
      this.formSubmissionSubscription.unsubscribe();
    }
  }

  onChange(evt: any) {
    this.realImgDimension(evt);
    let tempFile = evt.target.files[0];

    if (tempFile) {
      let fileExtension = tempFile.type.split('/')[1];
      if (fileExtension &&
        (fileExtension.toLowerCase() == "png" ||
          fileExtension.toLowerCase() == "jpg" ||
          fileExtension.toLowerCase() == "jpeg")) {
      } else {
        this.ShowErrorPanel = true;
        this.alertPanelModel = new AlertPanelModel(
          true,
          this.field.templateOptions.alert,
          [],
          '',
          this.field.templateOptions.close,
          true
          ,
          this.field.templateOptions.formatNotSupport
        );
        return;
      }
      if (fileExtension && tempFile.size < 50000) {
      } else {
        this.ShowErrorPanel = true;
        this.alertPanelModel = new AlertPanelModel(
          true,
          this.field.templateOptions.alert,
          [],
          '',
          this.field.templateOptions.close,
          true
          ,
          this.field.templateOptions.fileSizeMessage
        );
        return;
      }
    }
    if (tempFile) {
      this.filedata = tempFile;
      this.formControl.setValue(tempFile);
      this.to.change(this.field, tempFile);
    }
  }

  realImgDimension(evt: any) {
    let image: any = evt.target.files[0];
    let fr = new FileReader();
    fr.readAsDataURL(image);

    fr.onload = (event) => {
      // when file has loaded
      var img = new Image();
      img.onload = () => {
        this.width = img.width;
        this.height = img.height;
        if (img.width > 50 && img.height > 50) {
          this.ShowErrorPanel = true;
          this.alertPanelModel = new AlertPanelModel(
            true,
            this.field.templateOptions.alert,
            [],
            '',
            this.field.templateOptions.close,
            true,
            this.field.templateOptions.widthHeighMessage
          );
          return;
        }
        else {
          this.imgUrl = event.target.result.toString();
        }
      };
      img.src = fr.result.toString();
    };
  }


  alertPanelReceiver(returnValue) {
    this.ShowErrorPanel = false;
  }

}
