import { Component, OnInit } from "@angular/core";
import { Claims } from "src/app/_models/common/claims";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { OrgBasicFunctionSubscriptionService } from "src/app/_services/billing-pricing/Org-BasicFunction-Subscription.service";
import { DateFormatPipe } from "src/app/_helpers/pipe.module";
import { IApiRequest,ApiRequest } from "src/app/_models";
import { first } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from "@angular/router";
import { OrgSubscription } from "src/app/_models/common/OrgSubscription";
import { PaymentCustomerAndIntentIdResponse } from "src/app/_models/stripe/PaymentCustomerAndIntentIdResponse";
import { SaveOrderCartDetailsService } from "src/app/_services/cart-order/SaveOrder-Cart-Details.service";
import { AuthenticationService } from "src/app/_services";
import { environment } from "src/environments/environment";


@Component({
  selector: "app-basicFunctionTrialEndsAlertPanel",
  templateUrl: "./basicFunctionTrialEndsAlertPanel.component.html"
})
export class BasicFunctionTrialEndsAlertPanelComponent implements OnInit {
  param: any;
  claims: Claims;
  organisationId: number;
  isBasicFunctionPurchased: boolean = false;
  basicFunctionRemainingTrialDays: number;
  basicFunctionTrialDays: number;
  nevigationDetailsRequest: IApiRequest<OrgSubscription>;
  nevigationDetailsResponse: PaymentCustomerAndIntentIdResponse;
  basicFunctionTrialGraceDays: number;
  explorationDays: number;
  basicFunctionTrialStartDate: number;
  basicFunctionTrialEndDate: number;
  basicFunctionTrialGraceEndDate: number;
  showStripPanel: boolean = false;

  constructor(
    private claimsService: ClaimsService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private orgBasicFunctionSubscriptionService: OrgBasicFunctionSubscriptionService,
    private saveOrderCartDetailsService: SaveOrderCartDetailsService,
    private authenticationService: AuthenticationService,
    public datePipe: DateFormatPipe,
  ) {
    this.claimsService.currentClaims.subscribe((claims) => {
      this.claims = claims;
      this.organisationId = this.claims.OrgId;
    });
  }

  ngOnInit() {
    if (this.route.snapshot.queryParams["sessionId"]) {
         this.fnShowStripePanel(); 
    }
    this.fnGetOrgBasicFunctionSubscriptionTrialDetails();
  }
  
  fnGetOrgBasicFunctionSubscriptionTrialDetails() {
    this.orgBasicFunctionSubscriptionService
      .GetOrgBasicFunctionSubscriptionTrialDetails(this.organisationId)
      .subscribe((res: any) => {
        if (res.responseInfo != null) {
          this.basicFunctionRemainingTrialDays = res.responseInfo.basicFunctionRemainingTrialDays;
          this.basicFunctionTrialDays = res.responseInfo.basicFunctionTrialDays;
          this.isBasicFunctionPurchased = res.responseInfo.isBasicFunctionPurchased;
          this.basicFunctionTrialGraceDays = res.responseInfo.basicFunctionTrialGraceDays;
          this.explorationDays = res.responseInfo.explorationDays;
          this.basicFunctionTrialStartDate = res.responseInfo.basicFunctionTrialStartDate;
          this.basicFunctionTrialEndDate = res.responseInfo.basicFunctionTrialEndDate;
          this.basicFunctionTrialGraceEndDate = res.responseInfo.basicFunctionTrialGraceEndDate;
        }
      });
  }

fnShowStripePanel() {
    this.showStripPanel = true;
 }

fnCloseStripePanel(isClose: boolean) {
  if (isClose) {
    this.showStripPanel = false;
  }
}

fnSubscribeNow(){

  let request = new ApiRequest<OrgSubscription>("FindNavigationForPayment", environment.applicationVersion, environment.requestId);
  let requestInfo = new OrgSubscription(this.claims.OrgId);
  request.requestInfo = requestInfo;

 this.saveOrderCartDetailsService.FindNavigationForPayment(request)
 .subscribe((data) => {
             if (data.statusCode == 0) {
                 if (data.responseInfo.isFound) {
                     localStorage.setItem("IsFromBasicFunctionSubscriptionStrip",JSON.stringify(true));
                     this.router.navigate(["/settings"]);
                 } else {
                     localStorage.setItem("IsFromBasicFunctionSubscription",JSON.stringify(true));
                     this.fnShowStripePanel();
                 }
             } else {
                 this.toastr.error(data.errorInfo[0].message);
             }
         },
         (error) => {
             this.toastr.error(error);
         }
     );
 }

  logout() {
    // remove user from local storage to log user out
    this.authenticationService.logout();
    this.router.navigate(["login"]);
  }
}
