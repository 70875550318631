
export interface IRequestEscalationSettings {
  orgId: number;
  reminderDuration: number;
  escalationReminderCount: number;
  escalationCopyToReminderCount: number;
  fmLoginId: string;
  fmName: string;
}

export class RequestEscalationSettings implements IRequestEscalationSettings {
  orgId: number;
  reminderDuration: number;
  escalationReminderCount: number;
  escalationCopyToReminderCount: number;
  fmLoginId: string;
  fmName: string;
}

export interface IResponseEscalationSettings {
  responseSaveString: string;
}

export class ResponseEscalationSettings implements IResponseEscalationSettings {
  responseSaveString: string;
}

export interface IRequestOtherPolicyData {
  orgId: number;
  policyType: number;
  gradeId: number;
  policyStatus: number;
  pageNo: number;
  noOfRecords: number;
  sortOrder: number;
  sortColumn: string;
  skipRecords: number;
  searchText: string;
}

export class RequestOtherPolicyData implements IRequestOtherPolicyData {
  orgId: number;
  policyType: number;
  gradeId: number;
  policyStatus: number;
  pageNo: number;
  noOfRecords: number;
  sortOrder: number;
  sortColumn: string;
  skipRecords: number;
  searchText: string;
}

export interface IRequestDuplicatePolicyData {
  orgId: number;
  duplicatePolicyStatus: number;
}

export class RequestDuplicatePolicyData implements IRequestDuplicatePolicyData {
  orgId: number;
  duplicatePolicyStatus: number;
}


export interface IDuplicatePolicyData {
  duplicatePolicyId: number;
  orgId: number;
  orgExpenseCategoryId: number;
  isEnabled: boolean;
}
export class DuplicatePolicyData implements IDuplicatePolicyData {
  duplicatePolicyId: number;
  orgId: number;
  orgExpenseCategoryId: number;
  isEnabled: boolean;
}




export interface IOtherPolicyData {
  orgId: number;
  gradeId: number;
  gradeName: string;
  employeeCount: number;
  amount: number;
  policyType: number;
  catCount: number;
  catWithPolicy: number;
  catWithoutPolicy: number;
  otherPolicyExpCatDataCurrent: OtherPolicyExpCatData[];
  otherPolicyExpCatDataFuture: OtherPolicyExpCatData[];
  otherPolicyExpCatDataExpired: OtherPolicyExpCatData[];
  selected: boolean;
  userEmail: string;
  userId: number;
  userName: string;
  catCountFormattedText: string;
}
export class OtherPolicyData implements IOtherPolicyData {
  orgId: number;
  gradeId: number;
  gradeName: string;
  employeeCount: number;
  amount: number;
  policyType: number;
  catCount: number;
  catWithPolicy: number;
  catWithoutPolicy: number;
  otherPolicyExpCatDataCurrent: OtherPolicyExpCatData[];
  otherPolicyExpCatDataFuture: OtherPolicyExpCatData[];
  otherPolicyExpCatDataExpired: OtherPolicyExpCatData[];
  selected: boolean;
  userEmail: string;
  userId: number;
  userName: string;
  catCountFormattedText: string;
}

export interface IOtherPolicyExpCatData {
  policyId: number;
  amount: number;
  expenseCategoryId: number;
  expenseCategoryName: string;
  effectiveFrom: string;
  effectiveTo: string;
  hasPolicy: boolean;
  isFuturePolicy: boolean;
  isEdit: boolean;
  status: number;
  errAmount: boolean;
  futurePolicyCount: number;
  currentPolicyCount: number;
  expiredPolicyCount:number;
  addFutureDateClicked: boolean;
  stoppedPolicyCount: number;
  showEditDelete: boolean;
  notes:string;
  isAmountChanged:boolean;
}

export class OtherPolicyExpCatData implements IOtherPolicyExpCatData {
  policyId: number;
  amount: number;
  expenseCategoryId: number;
  expenseCategoryName: string;
  effectiveFrom: string;
  effectiveTo: string;
  hasPolicy: boolean;
  isFuturePolicy: boolean;
  isEdit: boolean;
  status: number;
  errAmount: boolean;
  futurePolicyCount: number;
  currentPolicyCount: number;
  expiredPolicyCount:number;
  addFutureDateClicked: boolean;
  stoppedPolicyCount: number;
  showEditDelete: boolean;
  alertPercentage: number;
  errAlertPercentage: boolean;
  isEditClicked: boolean;
  notes : string;
  isAmountChanged:boolean;
  constructor(policyId: number,
    amount: number,
    expenseCategoryId: number,
    expenseCategoryName: string,
    effectiveFrom: string,
    effectiveTo: string,
    hasPolicy: boolean,
    isFuturePolicy: boolean,
    isEdit: boolean,
    status: number,
    errAmount: boolean,
    futurePolicyCount: number,
    currentPolicyCount: number,
    addFutureDateClicked: boolean,
    stoppedPolicyCount: number,
    showEditDelete: boolean,
    alertPercentage: number,
    errAlertPercentage: boolean,
    isEditClicked: boolean,
    notes:string,
    isAmountChanged:boolean) {
    this.policyId = policyId;
    this.amount = amount;
    this.expenseCategoryId = expenseCategoryId;
    this.expenseCategoryName = expenseCategoryName;
    this.effectiveFrom = effectiveFrom;
    this.effectiveTo = effectiveTo;
    this.hasPolicy = hasPolicy;
    this.isFuturePolicy = isFuturePolicy;
    this.isEdit = isEdit;
    this.status = status;
    this.errAmount = errAmount;
    this.futurePolicyCount = futurePolicyCount;
    this.currentPolicyCount = currentPolicyCount;
    this.addFutureDateClicked = addFutureDateClicked;
    this.stoppedPolicyCount = stoppedPolicyCount;
    this.showEditDelete = showEditDelete;
    this.alertPercentage = alertPercentage;
    this.errAlertPercentage = errAlertPercentage;
    this.isEditClicked = isEditClicked;
    this.notes = notes;
    this.isAmountChanged = isAmountChanged;
  }
}

export interface IResponseOtherPolicyData {
  totalNoOfGrades: number;
  gradeWithPolicy: number;
  gradeWithoutPolicy: number;
  otherPolicyData: IOtherPolicyData[];
}

export class ResponseOtherPolicyData implements IResponseOtherPolicyData {
  totalNoOfGrades: number;
  gradeWithPolicy: number;
  gradeWithoutPolicy: number;
  otherPolicyData: IOtherPolicyData[];
}

export interface IResponseDuplicatePolicyData {
  duplicatePolicyData: IDuplicatePolicyData[];
  totalPolicyData: number;
  categoryOffCount: number;
  categoryOnCount: number;
}

export class ResponseDuplicatePolicyData implements IResponseDuplicatePolicyData {
  duplicatePolicyData: IDuplicatePolicyData[];
  totalPolicyData: number;
  categoryOffCount: number;
  categoryOnCount: number;
}

export interface IRequestSaveOtherPolicyData {
  otherPolicyData: IOtherPolicyData[];
  userId: number;
  isSkipApproverRuleSet: boolean;
}

export class RequestSaveOtherPolicyData implements IRequestSaveOtherPolicyData {
  otherPolicyData: IOtherPolicyData[];
  userId: number;
  isSkipApproverRuleSet: boolean;
}

export interface IRequestSaveDuplicatePolicyData {
  duplicatePolicyData: IDuplicatePolicyData[];
  userId: number;
}

export class RequestSaveDuplicatePolicyData implements IRequestSaveDuplicatePolicyData {
  duplicatePolicyData: IDuplicatePolicyData[];
  userId: number;
}

export interface IResponseSaveOtherPolicyData {
  responseSaveString: string;
}

export class ResponseSaveOtherPolicyData implements IResponseSaveOtherPolicyData {
  responseSaveString: string;
}

export interface IResponseSaveDuplicatePolicyData {
  responseSaveString: string;
}

export class ResponseSaveDuplicatePolicyData implements IResponseSaveDuplicatePolicyData {
  responseSaveString: string;
}

export interface IReqBusinessPurpose {
  orgId: number;
  isBPAvailale: boolean;
  bpCaption: string;
}

export class ReqBusinessPurpose implements IReqBusinessPurpose {
  orgId: number;
  isBPAvailale: boolean;
  bpCaption: string;
}

export interface IRespBusinessPurpose {
  responseString: string;
}

export class RespBusinessPurpose implements IRespBusinessPurpose {
  responseString: string;
}
export interface ISaveAdditionalFieldsResponse {
  responseString: string;
}

export class SaveAdditionalFieldsResponse implements ISaveAdditionalFieldsResponse {
  responseString: string;
}

export interface IResponseGetAdditionalFieldValues {
  additionalFields: AdditionalFields[];
}

export class ResponseGetAdditionalFieldValues implements IResponseGetAdditionalFieldValues {
  additionalFields: AdditionalFields[];
}

export interface IAdditionalFields {
  additionalFieldId: number;
  isSwitchedOn: boolean;
  fieldName: string;
  isDataAvailable: boolean;
  fieldType: number;
  instruction: string;
  data: Data[];
}

export class AdditionalFields implements IAdditionalFields {
  additionalFieldId: number;
  isSwitchedOn: boolean;
  fieldName: string;
  isDataAvailable: boolean;
  fieldType: number;
  instruction: string;
  data: Data[];
}

export interface IData {
  fieldValueId: number;
  fieldValue: string;
  code: string;
  name: string;
  isRowHighlighted: boolean;
}

export class Data implements IData {
  fieldValueId: number;
  fieldValue: string;
  code: string;
  name: string;
  isRowHighlighted: boolean;
  constructor(fieldValueId: number, fieldValue: string, code: string, name: string, isRowHighlighted: boolean) {
    this.fieldValueId = fieldValueId;
    this.fieldValue = fieldValue;
    this.code = code;
    this.name = name;
    this.isRowHighlighted = isRowHighlighted;
  }
}

export interface ISaveAdditionalFieldsData {
  additionalFieldDataId: number;
  additionalFieldId: number;
  status: number;
  code: string;
  name: string;
}

export class SaveAdditionalFieldsData implements ISaveAdditionalFieldsData {
  additionalFieldDataId: number;
  additionalFieldId: number;
  status: number;
  code: string;
  name: string;
  fieldValue: string;
  constructor(
    additionalFieldDataId: number, additionalFieldId: number, status: number, code: string, name: string, fieldValue: string) {
    this.additionalFieldDataId = additionalFieldDataId;
    this.additionalFieldId = additionalFieldId;
    this.status = status;
    this.code = code;
    this.name = name;
    this.fieldValue = fieldValue;
  }
}

export interface ISaveAdditionalFieldsConfig {
  fieldNumber:number;
  additionalFieldId: number;
  isSwitchedOn: boolean;
  fieldName: string;
  isDataAvailable: boolean;
  fieldType: number;
  instruction: string;
  data: SaveAdditionalFieldsData[];
}

export class SaveAdditionalFieldsConfig implements ISaveAdditionalFieldsConfig {
  fieldNumber: number;
  additionalFieldId: number;
  isSwitchedOn: boolean;
  fieldName: string;
  isDataAvailable: boolean;
  fieldType: number;
  instruction: string;
  data: SaveAdditionalFieldsData[];
  constructor(additionalFieldId: number, isSwitchedOn: boolean, fieldName: string, isDataAvailable: boolean, fieldType: number, instruction: string
    , data: SaveAdditionalFieldsData[]) {
    this.additionalFieldId = additionalFieldId;
    this.isSwitchedOn = isSwitchedOn;
    this.fieldName = fieldName;
    this.isDataAvailable = isDataAvailable;
    this.fieldType = fieldType;
    this.instruction = instruction;
    this.data = data;
  }
}

export interface ISaveAdditionalFieldsRequest {
  AdditionalFields: SaveAdditionalFieldsConfig[];
  OrgID: number;
  UserID: number;
}

export class SaveAdditionalFieldsRequest implements ISaveAdditionalFieldsRequest {
  AdditionalFields: SaveAdditionalFieldsConfig[];
  OrgID: number;
  UserID: number;
  constructor(AdditionalFields: SaveAdditionalFieldsConfig[], OrgID: number, UserID: number) {
    this.AdditionalFields = AdditionalFields;
    this.OrgID = OrgID;
    this.UserID = UserID;
  }
}

export interface IDeleteFieldByIdRequest {
  AdditionalFieldDataId: number;
}

export class DeleteFieldByIdRequest implements IDeleteFieldByIdRequest {
  AdditionalFieldDataId: number;
  constructor(AdditionalFieldDataId: number) {
    this.AdditionalFieldDataId = AdditionalFieldDataId;
  }
}


export interface IDeleteAdditionalFieldRequest {
  AdditionalFieldId: number;
  AdditionalFieldType: number;
}

export class DeleteAdditionalFieldRequest implements IDeleteAdditionalFieldRequest {
  AdditionalFieldId: number;
  AdditionalFieldType: number;
  constructor(AdditionalFieldId: number, AdditionalFieldType: number) {
    this.AdditionalFieldId = AdditionalFieldId;
    this.AdditionalFieldType = AdditionalFieldType;
  }
}

export interface IRespGetBusinessPurposeValues {
  isBusinessPurposeAvailable: number;
  BusinessPurposeCaption: string;
  IsBusinessPurposeValuesAvailable: number;
  bpValues: IBPValues[];
}

export class RespGetBusinessPurposeValues implements IRespGetBusinessPurposeValues {
  isBusinessPurposeAvailable: number;
  BusinessPurposeCaption: string;
  IsBusinessPurposeValuesAvailable: number;
  bpValues: IBPValues[];
}

export interface IBPValues {
  bpId: number;
  bpValue: string;
  updatedOn: number;
  isRowHighlighted: boolean;
}

export class BPValues implements IBPValues {
  bpId: number;
  bpValue: string;
  updatedOn: number;
  isRowHighlighted: boolean;
  constructor(
    bpId: number, bpValue: string, isRowHighlighted: boolean) {
    this.bpId = bpId;
    this.bpValue = bpValue;
    this.isRowHighlighted = isRowHighlighted;
  }
}

export interface IReqSaveBPValues {
  userId: number;
  orgId: number;
  bPStatus: number;
  bpValues: IBPValues[];
}

export class ReqSaveBPValues implements IReqSaveBPValues {
  userId: number;
  orgId: number;
  bPStatus: number;
  bpValues: IBPValues[];
  constructor(userId: number, orgId: number, bPStatus: number, bpValues: IBPValues[]) {
    this.userId = userId;
    this.bpValues = bpValues;
    this.orgId = orgId;
    this.bPStatus = bPStatus;
  }
}

export interface IRespSaveBPValues {
  responseSaveString: string;
}

export class RespSaveBPValues implements IRespSaveBPValues {
  responseSaveString: string;
}

export interface IExpTimeLimitSaveRequest {
  orgId: number;
  expenseTimeLimit: number;
  notes:string;
}

export class ExpTimeLimitSaveRequest implements IExpTimeLimitSaveRequest {
  orgId: number;
  expenseTimeLimit: number;
  notes:string;
  constructor(orgId: number, expenseTimeLimit: number, notes:string) {
    this.orgId = orgId;
    this.expenseTimeLimit = expenseTimeLimit;
    this.notes=notes;
  }
  
}
export interface IYearEndSaveRequest {

  orgId: number;
  isYearEndRuleEnabled:boolean;
  yearEndDate: any;
  yearEndNoOfDays:number;
  yearEndNotes:string;
}

export class YearEndSaveRequest implements IYearEndSaveRequest {
  orgId: number;
  isYearEndRuleEnabled:boolean;
  yearEndDate: any;
  yearEndNoOfDays:number;
  yearEndNotes:string;
  constructor(orgId: number,isYearEndRuleEnabled:boolean ,yearEndDate:any , yearEndNoOfDays: number, yearEndNotes:string) {
    this.orgId = orgId;
    this.isYearEndRuleEnabled=isYearEndRuleEnabled;
    this.yearEndDate=yearEndDate;
    this.yearEndNoOfDays = yearEndNoOfDays;
    this.yearEndNotes=yearEndNotes;
  }
}

export class SelectAdditionalFieldDataPopupModel {
  additionalFieldId: number;
  fieldValueId: number;
  fieldValue: string;
  additionalFieldDataList: Data[];
}

export interface IRequestPolicyConversion {
  orgId: number;
  policyType: number;
  changedBy: number;
}

export class RequestPolicyConversion implements IRequestPolicyConversion {
  orgId: number;
  policyType: number;
  changedBy: number;
}

export interface IRequestDeleteOtherPoliciesForConversionToGradeOrClaimantWise {
  orgId: number;
  policyType: number;
  changedBy: number;
  isClaimantWiseCappingEnabled: boolean;
}

export class RequestDeleteOtherPoliciesForConversionToGradeOrClaimantWise implements IRequestDeleteOtherPoliciesForConversionToGradeOrClaimantWise {
  orgId: number;
  policyType: number;
  changedBy: number;
  isClaimantWiseCappingEnabled: boolean;
}

export interface IClaimantWisePolicyExport {
  ClaimantName: string;
  Category: string;
  Limit: number;
  EffectiveFrom: string;
  AlertPercentage: number;
}

export class ClaimantWisePolicyExport implements IClaimantWisePolicyExport {
  ClaimantName: string;
  Category: string;
  Limit: number;
  EffectiveFrom: string;
  AlertPercentage: number;
}

export interface IRequestClaimantWisePolicyExport {
  orgId: number;
  policyType: number;
}

export class RequestClaimantWisePolicyExport implements IRequestClaimantWisePolicyExport {
  orgId: number;
  policyType: number;
}

export interface IResponseClaimantWisePolicyExport {
  claimantName: string;
  expenseCategoryName: string;
  limit: number;
  effectiveFrom: string;
  alertPercentage: number;
}

export class ResponseClaimantWisePolicyExport implements IResponseClaimantWisePolicyExport {
  claimantName: string;
  expenseCategoryName: string;
  limit: number;
  effectiveFrom: string;
  alertPercentage: number;
}

export interface IRequestClaimantWisePolicyHistoricalView {
  userId: number;
  orgId: number;
  policyType: number;
}
export class RequestClaimantWisePolicyHistoricalView implements IRequestClaimantWisePolicyHistoricalView {
  userId: number;
  orgId: number;
  policyType: number;
}

export interface IPolicyHistoryForCategory {
  month: string;
  limit: number;
  spentAmount: number;
  balanceAmount: number;
}
export class PolicyHistoryForCategory implements IPolicyHistoryForCategory {
  month: string;
  limit: number;
  spentAmount: number;
  balanceAmount: number;

  constructor(
    month: string,
    limit: number,
    spentAmount: number,
    balanceAmount: number
  ) {
    this.month = month;
    this.limit = limit;
    this.spentAmount = spentAmount;
    this.balanceAmount = balanceAmount;
  }
}

export interface IHistoricalDataForCategory {
  expenseCategoryId: number;
  expenseCategoryName: string;
  historicalData: PolicyHistoryForCategory[];
  isSelected: boolean;
}
export class HistoricalDataForCategory implements IHistoricalDataForCategory {
  expenseCategoryId: number;
  expenseCategoryName: string;
  historicalData: PolicyHistoryForCategory[];
  isSelected: boolean;
}

export interface IResponseClaimantWisePolicyHistoricalView {
  claimantName: string;
  historicalDataForCategory: IHistoricalDataForCategory[];
}
export class ResponseClaimantWisePolicyHistoricalView implements IResponseClaimantWisePolicyHistoricalView {
  claimantName: string;
  historicalDataForCategory: IHistoricalDataForCategory[];
}


export class PolicyDashboardOverviewDetail  {
  expensesSubmitted :number;
  policyViolatedExpensesApproved:number; 
  policyViolatedExpensesRejected :number;
  duplicateExpensesAttempts :number;
  duplicateExpensesAttemptsApproved:number;
}

export class BreachedExpenseCategories{
  id:number;
  name:string;
  type:number;
  policyBreachCount:number;
}
export class ClaimantsViolatingExpenses{
  id:number;
  name:string;
  type:number;
  policyBreachCount:number;
}
export class ApproversApprovingExpenses{
  id:number;
  name:string;
  type:number;
  policyBreachCount:number;
}
export class PolicyDashboardData{
  policyDashboardOverviewDetail:PolicyDashboardOverviewDetail;
  breachedExpenseCategories:BreachedExpenseCategories[];
  claimantsViolatingExpenses:ClaimantsViolatingExpenses[];
  approversApprovingExpenses:ApproversApprovingExpenses[];
}
export interface IPolicyDashboardFilter {
  orgId:number;
  companyId:number;
  fromDate:Date;
  toDate:Date;
  loadCase:number;
}

export class PolicyDashboardFilter implements IPolicyDashboardFilter {
  orgId:number;
  companyId:number;
  fromDate:Date;
  toDate:Date;
  loadCase:number;
}
export class PolicyGridData
{
  id:number;
  name:string;
  count:number;
  type:number
}
export class GridHeader
{
  Id:string;
  Name:string;
  Count:string;
}
export class PolicyDashBoardPanelModel
{
  fromDate?:any;
  toDate?:any;
  type?:number;
  Id?:number;
  case?:number;
  orgId?:number;
  companyId:number;
  Ids?:string;
}
export class PanelInputData
{
  fromDate?:Date;
  toDate?:Date;
  gridType?:number;
}
export class PolicyDashboardPanelData
{
  amount:number;
  approverId:number;
  approverName:string;
  category:string;
  companyId:number;
  companyName:string;
  currentStatus:string;
  currentStatusId:number;
  expenseDate:any;
  expenseDateToShow:any;
  expenseId:number
  isPolicyViolated:boolean;
  breachDetails?:BreachDetail[];
  policyBreached:string;
  breachAmountClass:string;
  breachAmount:any;
  isMultiplePolicyVoilated:boolean;
  orgExpenseCategoryId:number;
  status:number;
  submissionDate:number;
  userId:number;
  userName:string;
}
export class BreachDetail
{
  expenseId:number;
  policyType:number;
  violation:number;
}

export class RespCategoryAdditionalFieldSettings
{
  lstCategoryDetails: CategoryAdditionalFieldData[];
}

export class CategoryAdditionalFieldData
{
  orgExpenseCategoryId:number;
  expenseCategoryName:string;
  additionalField1:boolean;
  additionalField2:boolean;
  additionalField3:boolean;
  additionalField4:boolean;  
  totalSwitchedOn:number;
  countAsPerCategory:number;
}

export class ReqSaveCategoryAdditionalFieldDetails
{
  lstCategoryDetails: CategoryAdditionalFieldData[];
}


