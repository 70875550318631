import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ColDef } from "ag-grid-community";
import { first } from "rxjs/operators";
import { AppGridComponent } from "src/app/shared/grid/grid-component/grid.component";
import { ApiRequest, BaseCategoryTypeEnum, ExpenseStatusEnum, IApiresponse } from "src/app/_models";
import { GridConfig } from "src/app/_models/common/grid/grid-config";
import { IPresetExport, PresetExport } from "src/app/_models/Dashboard/preset-export";
import { OrgEntertainmentCategoryView } from "src/app/_models/manage-category/org-entertainment-category-view";
import { IWorkflowRequest, WorkflowRequest } from "src/app/_models/Workflow/workflow-request";
import { ExportToCSVService } from "src/app/_services/common/export-to-csv.service";
import { WorkflowService } from "src/app/_services/common/workflow.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "preset-export",
  templateUrl: "preset-export.component.html",
  styleUrls: ["./preset-export.component.scss"],
})
export class PresetExportComponent implements OnInit {
  @Output() closePanel = new EventEmitter<boolean>();
  @Input() selectedPresetExportStatus: number;
  @Input() isCounterApproverPurchased:boolean;
  @ViewChild(AppGridComponent) gridComponent: AppGridComponent;
  param: any;
  columnDefs: ColDef[];
  gridFilterItems = [];
  gridConfigItems: GridConfig;

  presetexportData: IPresetExport[] = [];
  gridData: IPresetExport[] = [];

  selectedFilterBy = 0;
  filterByList: any[];

  filterList: any[];
  headerText: string;
  
  constructor(private workflowService: WorkflowService,
    private exportToCSVService: ExportToCSVService,
    private translate: TranslateService) {

  }
  ngOnInit() {
    this.initialiseGrid();
    this.setHeaderText();
    this.bindFilterBy();

    this.getDashboardPresetData(this.selectedPresetExportStatus);
  }

  setHeaderText() {
    this.headerText = (this.selectedPresetExportStatus == ExpenseStatusEnum.PendingWithApprover
      ? this.translate.instant('label_pending_with_approver')
      : (this.selectedPresetExportStatus == ExpenseStatusEnum.Approved ? " " + this.translate.instant('pending_finance_approval') : " " + this.translate.instant('panding_passed')));
  }

  initialiseGrid() {
    this.createColumnDefs();
    this.getGridConfigItems();
    setTimeout(() => {
      this.gridComponent.initializeGrid();
    }, 1000);
  }

  getGridConfigItems() {

    this.gridConfigItems = new GridConfig(
      true,
      true,
      "claimantName",
      "expenseId",
      0,
      false,
      [],
      true,
      true,
      false,
      null,
      'asc',
      40,
      this.translate.instant('no_data_availabe'),
      null,
      null,
      false, false,
      '',
      '',
      false
    );
  }

  createColumnDefs() {
    this.columnDefs = [
      {
        headerName: this.translate.instant('claimant_name'),
        field: 'claimantName',
        suppressMenu: true,
        sortable: true,
        resizable: true,
        minWidth: 100,
        headerClass: 'text-left',
        cellClass: 'text-left',
      },
      {
        headerName: this.translate.instant('ExpenseCategory'),
        field: 'orgExpenseCategory',
        suppressMenu: true,
        sortable: true,
        resizable: true,
        minWidth: 100,
        headerClass: 'text-left',
        cellClass: 'text-left',
      },
      {
        headerName: this.translate.instant('expense_date'),
        field: 'expenseDate',
        resizable: true,
        sortable: true,
        suppressMenu: true,
        minWidth: 100,
        cellRenderer: 'dateRenderer'
      },
      {
        headerName: this.translate.instant('amount'),
        field: 'amount',
        suppressMenu: true,
        sortable: true,
        resizable: true,
        minWidth: 100,
        headerClass: 'text-right',
        cellClass: 'text-right',
        cellRenderer: 'amountRenderer',
      },
      {
        headerName: this.translate.instant('approver_name'),
        field: 'approverName',
        suppressMenu: true,
        sortable: true,
        resizable: true,
        minWidth: 100,
        headerClass: 'text-left',
        cellClass: 'text-left',
        'cellStyle': params => {
          if (params?.data?.approverName == 'Auto Approved') {
            return { color: 'red' };
          }
        }
      }];

    if (this.selectedPresetExportStatus == ExpenseStatusEnum.PendingWithApprover) {
      if(this.isCounterApproverPurchased)
      {
          this.columnDefs.push({
            headerName: this.translate.instant('approval_role'),
            field: 'approverRole',
            resizable: true,
            sortable: true,
            suppressMenu: true,
            minWidth: 100,
            headerClass: 'text-left',
            cellClass: 'text-left'
          });
      }
      this.columnDefs.push({
        headerName: this.translate.instant('submitted_on'),
        field: 'submittedOn',
        resizable: true,
        sortable: true,
        suppressMenu: true,
        minWidth: 100,
        cellRenderer: 'dateRenderer'
      });
      this.columnDefs.push({
        headerName: this.translate.instant('receipt'),
        field: 'receipt',
        suppressMenu: true,
        sortable: true,
        resizable: true,
        minWidth: 100,
        headerClass: 'text-left',
        cellClass: 'text-left',
      });
      this.columnDefs.push({
        headerName: this.translate.instant('policy_violated'),
        field: 'policyVoilation',
        suppressMenu: true,
        sortable: true,
        resizable: true,
        minWidth: 100,
        headerClass: 'text-left',
        cellClass: 'text-left',
      });
    }

    if (this.selectedPresetExportStatus == ExpenseStatusEnum.Approved) {
      this.columnDefs.push({
        headerName: this.translate.instant('audittrail_approval_date'),
        field: 'approvalDate',
        resizable: true,
        sortable: true,
        suppressMenu: true,
        minWidth: 100,
        cellRenderer: 'dateRenderer'
      });
      this.columnDefs.push({
        headerName: this.translate.instant('finance_approver_name'),
        field: 'financeApproverName',
        resizable: true,
        sortable: true,
        suppressMenu: true,
        minWidth: 100,
        headerClass: 'text-left',
        cellClass: 'text-left',
      });
      this.columnDefs.push({
        headerName: this.translate.instant('receipt'),
        field: 'receipt',
        suppressMenu: true,
        sortable: true,
        resizable: true,
        minWidth: 100,
        headerClass: 'text-left',
        cellClass: 'text-left',
      });
      this.columnDefs.push({
        headerName: this.translate.instant('tax_rate'),
        field: 'taxRatevalue',
        suppressMenu: true,
        sortable: true,
        resizable: true,
        minWidth: 280,
        headerClass: 'text-left',
        cellClass: 'text-left',
      });
      this.columnDefs.push({
        headerName: this.translate.instant('tax_amount'),
        field: 'taxRateAmount',
        suppressMenu: true,
        sortable: true,
        resizable: true,
        minWidth: 330,
        headerClass: 'text-left',
        cellClass: 'text-left',
      });
      this.columnDefs.push({
        headerName: this.translate.instant('policy_violated'),
        field: 'policyVoilation',
        suppressMenu: true,
        sortable: true,
        resizable: true,
        minWidth: 100,
        headerClass: 'text-left',
        cellClass: 'text-left',
      });
    }
    if (this.selectedPresetExportStatus == ExpenseStatusEnum.ApprovedForPayment) {
      this.columnDefs.push({
        headerName: this.translate.instant('audittrail_approval_date'),
        field: 'approvalDate',
        resizable: true,
        sortable: true,
        suppressMenu: true,
        minWidth: 100,
        cellRenderer: 'dateRenderer'
      });
      this.columnDefs.push({
        headerName: this.translate.instant('finance_approver_name'),
        field: 'financeApproverName',
        resizable: true,
        sortable: true,
        suppressMenu: true,
        minWidth: 100,
        headerClass: 'text-left',
        cellClass: 'text-left',
      });
      this.columnDefs.push({
        headerName: 'FM Approval Date',
        field: 'financeApprovalDate',
        resizable: true,
        sortable: true,
        suppressMenu: true,
        minWidth: 100,
        cellRenderer: 'dateRenderer'
      });
      this.columnDefs.push({
        headerName: this.translate.instant('receipt'),
        field: 'receipt',
        suppressMenu: true,
        sortable: true,
        resizable: true,
        minWidth: 100,
        headerClass: 'text-left',
        cellClass: 'text-left',
      });
      this.columnDefs.push({
        headerName: this.translate.instant('tax_rate'),
        field: 'taxRatevalue',
        suppressMenu: true,
        sortable: true,
        resizable: true,
        minWidth: 280,
        headerClass: 'text-left',
        cellClass: 'text-left',
      });
      this.columnDefs.push({
        headerName: this.translate.instant('tax_amount'),
        field: 'taxRateAmount',
        suppressMenu: true,
        sortable: true,
        resizable: true,
        minWidth: 330,
        headerClass: 'text-left',
        cellClass: 'text-left',
      });

    }
  }
  getDashboardPresetData(currentStatus) {

    let request = new ApiRequest<IWorkflowRequest>(
      "GetDashboardPresetData",
      environment.applicationVersion,
      environment.requestId
    );
    let expenseIds: number[] = [];
    expenseIds.push(1);
    let requestInfo = new WorkflowRequest(expenseIds);
    requestInfo.currentStatus = currentStatus;
    request.requestInfo = requestInfo;
    this.workflowService
      .SoloGetDashboardPresetData(request)
      .subscribe((res: IApiresponse<IPresetExport[]>) => {
        if (res.statusCode == 0) {
          this.presetexportData = res.responseInfo;
          this.presetexportData = this.presetexportData.map(item => {
            if (this.selectedPresetExportStatus != ExpenseStatusEnum.PendingWithApprover) {
              if (item.baseCategoryId == BaseCategoryTypeEnum.Entertainment) {
                item.taxRateAmount = this.fnSetEntRowInGrid(item, 'amount');
                item.taxRatevalue = this.fnSetEntRowInGrid(item, 'value');
              }
              else {
                if (item.taxRateAmount == 0 || item.taxRateAmount) {
                  item.taxRateAmount = Number(item.taxRateAmount).toFixed(2);
                }
                if (item.taxRateMasterId == 0 || item.taxRateMasterId) {
                  item.taxRatevalue = item.taxRatevalue + "%";
                }
              }

            }
            return item;
          });
          this.gridData = this.presetexportData;

          this.bindFilterList();
          setTimeout(() => {
            this.gridComponent.refreshGridData();
          }, 1000);
          this.filterGridData();
        }
      });
  }

  bindFilterList() {
    this.filterList = [];

    for (var i = 0; i < this.presetexportData.length; i++) {
      if (this.selectedFilterBy == 1) {
        if (this.filterList.filter(x => x.id == this.presetexportData[i].orgExpenseCategoryId).length == 0) {
          this.filterList.push({
            id: this.presetexportData[i].orgExpenseCategoryId,
            name: this.presetexportData[i].orgExpenseCategory,
          });
        }
        this.filterList.sort((a, b) =>
          a.name > b.name ? 1 : -1
        );
        for (
          let index = 0;
          index < this.filterList.length;
          index++
        ) {
          this.filterList[index].groupByField = "All";
        }
      }
      else if (this.selectedFilterBy == 2) {
        if (this.filterList.filter(x => x.id == this.presetexportData[i].claimantId).length == 0) {
          this.filterList.push({
            id: this.presetexportData[i].claimantId,
            name: this.presetexportData[i].claimantName,
          });
        }
        this.filterList.sort((a, b) =>
          a.name > b.name ? 1 : -1
        );

        for (
          let index = 0;
          index < this.filterList.length;
          index++
        ) {
          this.filterList[index].groupByField = "All";
        }
      }

      this.selectedFilterValues = [];

      for (let index = 0; index < this.filterList.length; index++) {
        this.selectedFilterValues.push(
          this.filterList[index].id
        );
      }

      this.expenseCount = this.gridData.length;
      this.claimantCount = this.gridData.map(a => a.claimantId).filter((v, i, a) => a.indexOf(v) === i).length;
    }
  }


  selectedFilterValues: any[];
  onFilterSelectionChange(item) {
    

    this.selectedFilterValues = [];

    for (let index = 0; index < item.length; index++) {
      this.selectedFilterValues.push(
        item[index].id
      );
    }

    this.checkToEnableViewButton();
  }



  bindFilterBy() {
    this.filterByList = [];
    this.filterByList.push({
      filterById: Number(0),
      filterByName: "All",
    });
    this.filterByList.push({
      filterById: Number(1),
      filterByName: "Expense Category",
    });
    this.filterByList.push({
      filterById: 2,
      filterByName: this.translate.instant('claimant_name'),
    });
  }

  onFilterByChange(item) {
    
    this.selectedFilterBy = item.filterById;
    this.bindFilterList();
    this.setPlaceHolder();
    this.checkToEnableViewButton();
  }

  selectedFilterByPlaceHolder: string;
  setPlaceHolder() {
    if (this.selectedFilterBy == 1) {
      this.selectedFilterByPlaceHolder = "Expense Category:";
    }
    else if (this.selectedFilterBy == 2) {
      this.selectedFilterByPlaceHolder = this.translate.instant('claimant_name') + ":";

    }
  }

  filterGridData() {
    if (this.selectedFilterBy == 0) {
      this.gridData = this.presetexportData;
    }
    else if (this.selectedFilterBy == 1) {
      this.gridData = this.presetexportData.filter(el => {
        return this.selectedFilterValues.find(element => {
          return element === el.orgExpenseCategoryId;
        });
      });
    }
    else if (this.selectedFilterBy == 2) {
      this.gridData = this.presetexportData.filter(el => {
        return this.selectedFilterValues.find(element => {
          return element === el.claimantId;
        });
      });
    }

    setTimeout(() => {
      this.gridComponent.refreshGridData();
    }, 1000);

    this.expenseCount = this.gridData.length;
    this.claimantCount = this.gridData.map(a => a.claimantId).filter((v, i, a) => a.indexOf(v) === i).length;

    this.enableViewButton = false;
  }

  ClosePanel() {
    this.closePanel.emit(false)
  }

  onOpen() {
    setTimeout(() => {
      const scrollContainer = document.querySelector(
        ".ng-dropdown-panel-items"
      );
      if (scrollContainer) {
        scrollContainer.scrollTop = 0;
      }
    }, 0);
  }
  resetFilters() {
    this.selectedFilterBy = 0;
    this.filterGridData();
  }
  enableViewButton: boolean = false;
  checkToEnableViewButton() {
    if (this.selectedFilterBy == 0 || this.selectedFilterValues.length > 0) {
      this.enableViewButton = true;
    } else {
      this.enableViewButton = false;
    }
  }

  claimantCount: number = 0;
  expenseCount: number = 0;

  downloadCSV() {
    var headerText = this.gridComponent.columnDefs.map(x => x.headerName);
    var headerProperties = this.gridComponent.columnDefs.map(x => x.field);
    this.exportToCSVService.downloadFile(this.gridData, headerText, headerProperties, "Preset Export")
  }
  fnSetEntRowInGrid(item: IPresetExport, columnName) {
    if (!item)
      return '';

    let rowText = "";
    if (item.employeeTaxRateAmount != 0) {
      item.employeeTaxRateAmount =
        item.employeeTaxRateAmount != "" && item.employeeTaxRateAmount != null
          ? item.employeeTaxRateAmount
          : null;
    }
    if (item.guestTaxRateAmount != 0) {
      item.guestTaxRateAmount =
        item.guestTaxRateAmount != "" && item.guestTaxRateAmount != null
          ? item.guestTaxRateAmount
          : null;
    }

    if (item.isTipsEnabled) {
      if (item.tipsTaxRateAmount != 0) {
        item.tipsTaxRateAmount =
          item.tipsTaxRateAmount != "" && item.tipsTaxRateAmount != null
            ? item.tipsTaxRateAmount
            : null;
      }

    }
    else { item.tipsTaxRateAmount = null; }

    if (columnName == 'amount') {
      if (
        item.employeeTaxRateAmount != null &&
        item.guestTaxRateAmount != null &&
        item.tipsTaxRateAmount != null
      ) {
        let empAmount = Number(item.employeeTaxRateAmount).toFixed(2);
        let guestAmount = Number(item.guestTaxRateAmount).toFixed(2);
        let tipsAmount = Number(item.tipsTaxRateAmount).toFixed(2);
        rowText =
          this.translate.instant("manageCat_emp") + ": " + empAmount + " | " +
          this.translate.instant("manageCat_guests") + ": " + guestAmount + " | " +
          this.translate.instant("manageCat_tips") + ": " + tipsAmount;
      } else if (
        item.employeeTaxRateAmount == null &&
        item.guestTaxRateAmount != null &&
        item.tipsTaxRateAmount != null
      ) {
        let guestAmount = Number(item.guestTaxRateAmount).toFixed(2);
        let tipsAmount = Number(item.tipsTaxRateAmount).toFixed(2);
        rowText =
          this.translate.instant("manageCat_guests") + ": " + guestAmount + " | " +
          this.translate.instant("manageCat_tips") + ": " + tipsAmount;
      } else if (
        item.employeeTaxRateAmount == null &&
        item.guestTaxRateAmount == null &&
        item.tipsTaxRateAmount != null
      ) {
        let tipsAmount = Number(item.tipsTaxRateAmount).toFixed(2);
        rowText =
          this.translate.instant("manageCat_tips") + ": " + tipsAmount;
      } else if (
        item.employeeTaxRateAmount == null &&
        item.guestTaxRateAmount != null &&
        item.tipsTaxRateAmount == null
      ) {
        let guestAmount = Number(item.guestTaxRateAmount).toFixed(2);
        rowText =
          this.translate.instant("manageCat_guests") + ": " + guestAmount;
      } else if (
        item.employeeTaxRateAmount != null &&
        item.guestTaxRateAmount == null &&
        item.tipsTaxRateAmount != null
      ) {
        let empAmount = Number(item.employeeTaxRateAmount).toFixed(2);
        let tipsAmount = Number(item.tipsTaxRateAmount).toFixed(2);
        rowText =
          this.translate.instant("manageCat_emp") + ": " + empAmount + " | " +
          this.translate.instant("manageCat_tips") + ": " + tipsAmount;
      } else if (
        item.employeeTaxRateAmount != null &&
        item.guestTaxRateAmount == null &&
        item.tipsTaxRateAmount == null
      ) {
        let empAmount = Number(item.employeeTaxRateAmount).toFixed(2);
        rowText =
          this.translate.instant("manageCat_emp") + ": " + empAmount + " | " +
          "";
      } else if (
        item.employeeTaxRateAmount != null &&
        item.guestTaxRateAmount != null &&
        item.tipsTaxRateAmount == null
      ) {
        let empAmount = Number(item.employeeTaxRateAmount).toFixed(2);
        let guestAmount = Number(item.guestTaxRateAmount).toFixed(2);
        rowText =
          this.translate.instant("manageCat_emp") + ": " + empAmount + " | " +
          this.translate.instant("manageCat_guests") + ": " + guestAmount;
      }
    } else {
      if (item.employeeTaxRateValue != 0) {
        item.employeeTaxRateValue =
          item.employeeTaxRateValue != null && item.employeeTaxRateValue != 0
            ? item.employeeTaxRateValue
            : null;
      }
      if (item.guestTaxRateValue != 0) {
        item.guestTaxRateValue =
          item.guestTaxRateValue != null && item.guestTaxRateValue != 0
            ? item.guestTaxRateValue
            : null;
      }
      if (item.isTipsEnabled) {
        if (item.tipsTaxRateValue != 0) {
          item.tipsTaxRateValue =
            item.tipsTaxRateValue != null && item.tipsTaxRateValue != 0
              ? item.tipsTaxRateValue
              : null;
        }
      }
      else { item.tipsTaxRateValue = null; }
      if (
        item.employeeTaxRateValue != null &&
        item.guestTaxRateValue != null &&
        item.tipsTaxRateValue != null
      ) {
        rowText =
          this.translate.instant("manageCat_emp") + ": " + item.employeeTaxRateValue + "% | " +
          this.translate.instant("manageCat_guests") + ": " + item.guestTaxRateValue + "% | " +
          this.translate.instant("manageCat_tips") + ": " + item.tipsTaxRateValue + "%";
      } else if (
        item.employeeTaxRateValue == null &&
        item.guestTaxRateValue != null &&
        item.tipsTaxRateValue != null
      ) {
        rowText =
          this.translate.instant("manageCat_guests") + ": " + item.guestTaxRateValue + "% | " +
          this.translate.instant("manageCat_tips") + ": " + item.tipsTaxRateValue + "%";
      } else if (
        item.employeeTaxRateValue == null &&
        item.guestTaxRateValue == null &&
        item.tipsTaxRateValue != null
      ) {
        rowText =
          this.translate.instant("manageCat_tips") + ": " + item.tipsTaxRateValue + "%";
      } else if (
        item.employeeTaxRateValue == null &&
        item.tipsTaxRateValue == null &&
        item.guestTaxRateValue != null
      ) {
        rowText =
          this.translate.instant("manageCat_guests") + ": " + item.guestTaxRateValue + "%";
      } else if (
        item.guestTaxRateValue == null &&
        item.employeeTaxRateValue != null &&
        item.tipsTaxRateValue != null
      ) {
        rowText =
          this.translate.instant("manageCat_emp") + ": " + item.employeeTaxRateValue + "% | " +
          this.translate.instant("manageCat_tips") + ": " + item.tipsTaxRateValue + "%";
      } else if (
        item.guestTaxRateValue == null &&
        item.employeeTaxRateValue != null &&
        item.tipsTaxRateValue == null
      ) {
        rowText =
          this.translate.instant("manageCat_emp") + ": " + item.employeeTaxRateValue + "%";
      } else if (
        item.guestTaxRateValue != null &&
        item.employeeTaxRateValue != null &&
        item.tipsTaxRateValue == null
      ) {
        rowText =
          this.translate.instant("manageCat_emp") + ": " + item.employeeTaxRateValue + "% | " +
          this.translate.instant("manageCat_guests") + ": " + item.guestTaxRateValue + "%";
      }
    }
    return rowText;
  }

}

