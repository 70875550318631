import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LayoutComponent } from "./layouts/layout.component";
import { OnboardingComponent } from "./user/onboarding/onboarding.component";
import { OrgLoginComponent } from "./user/org-login/org-login.component";
import { MsalGuard } from "@azure/msal-angular";
import { PricingExternalListComponent } from "./pricing/pricing-external/pricing-external-list/pricing-external-list.component";
import { AuthGuardService } from "./_services/common/authguard.service";

const routes: Routes = [
  { path: "login", component: OnboardingComponent },
  { path: "register", component: OnboardingComponent},
  { path: "orglogin", component: OrgLoginComponent, canActivate: [MsalGuard] },
  { path: "pricingcalculator", component: PricingExternalListComponent },
  { 
    path: "account",
    loadChildren: () =>
      import("./account/account.module").then((m) => m.AccountModule),
  },
  {
    path: "",
    component: LayoutComponent,
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
    canActivate: [AuthGuardService],
  },

  {
    path: "verifyemail",
    loadChildren: () =>
      import("../app/_modules/modules/verify-email.module").then(
        (m) => m.VerifyEmailModule
      ),
  },
  {
    path: "forgotpassword",
    loadChildren: () =>
      import("../app/_modules/modules/forgot-password.module").then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: "resetpassword",
    loadChildren: () =>
      import("../app/_modules/modules/reset-password.module").then(
        (m) => m.ResetPasswordModule
      ),
  },    
  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
