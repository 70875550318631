import { Injectable, Inject, forwardRef } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { IApiresponse } from "src/app/_models/common/request-response/apiresponse";
import { IApiRequest } from "src/app/_models/common/request-response/api-request";
import { setHeader } from "src/app/_helpers/setRequestHeader";
import { ClaimsHeaderService } from "src/app/shared/claimsHeader/claims-header.service";
import { ICountryCurrencyMappingResponse } from "src/app/_models/country-currency-mapping/country-currency-mapping";


@Injectable({
    providedIn: "root",
})

export class CountryCurrencyMappingService {
    httpHeaders: HttpHeaders;
    constructor(
        private http: HttpClient,
        private claimsHeaderService: ClaimsHeaderService,
        private header: setHeader
    ) { }

    GetCountryCurrencyMapping() {
        return this.http.get<IApiresponse<ICountryCurrencyMappingResponse[]>>(
            `${environment.catalogApiUrl}GetCountryCurrencyMapping`, { headers: this.header.authorizationHeader() });
    }
}