<div class="overLayDiv active"></div>
<div id="rightSideBar">
    <div class="rightSidePanel active" style="z-index: 99999;">
        <header>
            <div class="row">
                <div class="col-lg-12 no-padding-right-left">
                    <div class="row">
                        <div class="col-lg-8 padding-left-0">
                            <h2 class="f-18">{{'link_to_pre_approval_tag' | translate:param}}</h2>
                        </div>
                        <div
                            class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 padding-right-0 header-icons text-right">
                            <ul class="d-flex justify-content-end w-100pc">
                                <li class="padding-left-0" [ngClass]="{ disabled: !this.hasFormChanges}"
                                    (click)="fnSelectApprovalTag()">
                                    <Button class="btn btn-primary height-38px margin-right-15"> {{ 'label_save' |
                                        translate: param }}
                                    </Button>
                                </li>
                                <li class="padding-right-0" (click)="fnClosePanel()">
                                    <Button
                                        class="btn btn-outline-secondary border-color-warning text-mustard height-38px">
                                        {{ 'label_close' | translate: param }}
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div class="clear"></div>
        <div class="row">
            <div
                class="col-md-12 col-sm-12 col-lg-12 col-xl-12 margin-top-10 no-padding-right-left heightCalc-7 overflow-auto scrollbar-visible">

                <div class="block ">
                    <h6 class="border-bottom padding-bottom-10">{{'choose_a_pre_approval_tag' | translate:param}}</h6>

                    <div class="text-center noDataAvailable disabled opacity-60"
                        *ngIf="(preApprovalTagList).length === 0">
                        {{ "no_data_available" | translate: param }}
                    </div>

                    <div class="row margin-top-20 f-13 lH-normal padding-left-30 border-bottom padding-bottom-20 position-relative"
                        *ngFor="let item of preApprovalTagList">
                        <span class="position-absolute left-0px top-25pc ">
                            <input type="checkbox" class="svg-18"
                                (click)="fnOnChangeApprovalTag($event,item.preApprovalId);this.hasFormChanges= true;"
                                [checked]="item.preApprovalId ==selectedPreApprovalTag">
                        </span>
                        <div class="float-left w-70pc text-left ">
                            <p class="lH-normal text-ellipsis">{{item.preApprovalTag}} <span
                                    class="d-block color-red disabled">{{item.remainingDays}}
                                    {{'days_left' | translate:param}}</span><span
                                    class="d-block disabled">{{'date_of_approval' | translate:param}}:
                                    {{item.approvalDate| date: "dd-MMM-yyyy" }}</span> <span
                                    class="d-block input-field-values">Pre-Approval
                                    {{'label_balance' | translate:param}}:
                                    {{item.balance | number: "1.2-2" | noComma}}</span></p>
                        </div>
                        <!-- <div class="float-right text-right w-30pc text-right align-middle lH-70"> <a href="#">View
                                Linked Expenses</a> <span
                                class="badge badge-pill badge-danger badge-pill-style right-10"
                                style="top:-10px !important">1</span></div> -->
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>