<div class="input-group">
    <input type="text" [formControl]="formControl" [formlyAttributes]="field" style="display: none;">
    <a class="create-expense-tag" (click)="fnOpenCreateTagPanel()">
        <span><i class="fas fa-tag text-charcoal-black"></i></span>
        {{ 'tag' | translate }}
    </a>
    <span class="badge badge-pill badge-danger badge-pill-style right-20"
        *ngIf="formControl.value && formControl.value.length > 0" style="top:-17px !important">
        {{formControl.value.length}}
    </span>
</div>

<create-tag-panel *ngIf="showCreateTagPanel" [inputData]="inputModel"
    (closePanelEvent)="fnCloseCreateTagPanel($event)" (selectItemEvent)="fnSelectCreateTag($event)"></create-tag-panel>
