import { Component, ElementRef, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Claims } from "src/app/_models/common/claims";
import { ClaimsService } from "src/app/_services/common/claims.service";

@Component({
  selector: "app-vertical",
  templateUrl: "./vertical.component.html",
  styleUrls: ["./vertical.component.scss"],
})

/**
 * Vertical Component
 */
export class VerticalComponent implements OnInit {
  claims: Claims;
  isCondensed = false;
  constructor(
    private router: Router,
    private claimsService: ClaimsService,
    private host: ElementRef<HTMLElement>
  ) {
    this.claimsService.currentClaims.subscribe((claims) => {
      this.claims = claims;
    });
    router.events.forEach((event) => {
      if (window.screen.width <= 768) {
        if (event instanceof NavigationEnd) {
          document.body.classList.remove("sidebar-enable");
        }
      }
    });
  }

  ngOnInit(): void {
    //this.host.nativeElement.style.setProperty('$sidebar-width', '0');
    //document.body.getElementsByClassName('main-content')[0].cl;
    document.body.removeAttribute("data-layout");

    if (this.claims.orgRoleId != 3) {
      document.body.setAttribute("data-sidebar-size", "smc");
    }
  }

  /**
   * On mobile toggle button clicked
   */
  onToggleMobileMenu() {
    this.claimsService.onToggleMobileMenu(this.claims.IsLeftMenuCollapseAndStopToggle);
    this.isCondensed = !this.isCondensed;
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle("right-bar-enabled");
  }

  routeIsActive(routePath: string) {
    let currentUrl = this.router.url;
    return currentUrl.startsWith(routePath);
    //return currentUrl.indexOf(routePath) > -1;
  }
}
