import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';


@Component({
    selector: 'formly-two-decimal-number-input',
    templateUrl: "./formly-two-decimal-number-input.component.html"
})
export class FormlyTwoDecimalNumberInputComponent extends FieldType implements OnInit, OnDestroy {

    isFormSubmitted: boolean = false;
    formSubmissionSubscription: Subscription;
    fieldKey: string;
   
    constructor(private dynamicFormService: DynamicFormService,private cdRef: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        if (this.field.key) {
            this.fieldKey = this.field.key.toString();
        }
        this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
          this.isFormSubmitted = data;
          setTimeout(() => {
              this.cdRef.detectChanges()
          }, 100);
      });
    }

    ngOnDestroy() {
        if (this.formSubmissionSubscription) {
            this.formSubmissionSubscription.unsubscribe();
        }
    }

    onNumberInput() {
        this.to.change(this.field, this.formControl.value);
    }

    onKeyPress(event) {
      const value = parseInt(this.formControl.value + event.key);
      if(this.to.max) {
        if(value > this.to.max) {
          return false;
        }
      }
      if(this.to.min) {
        if(value < this.to.min) {
          return false;
        }
      }
      if(this.to.omitNegativeSign){
        return event.charCode != 45;        
      }
      return true;
    }

}
