import { Component } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
    selector: 'discount-renderer',
    templateUrl: './discount-renderer.component.html'
})
export class DiscountRendererComponent implements ICellRendererAngularComp {
    public amount: number;
    public percentage: number;
    public discountedAmount: number = 0;
    public currency: any;

    initilize(params: ICellRendererParams) {
        if (params.colDef.headerComponentParams) {
            this.amount = params.colDef.headerComponentParams.amount ? params.colDef.headerComponentParams.amount : 0;
            this.currency = params.colDef.headerComponentParams.currency ? params.colDef.headerComponentParams.currency: '';

        }
        this.percentage = params.getValue();
        if(this.amount && this.percentage){
            this.discountedAmount = this.amount - (this.amount * this.percentage) / 100;
        }
    }

    // called on init
    agInit(params: any): void {
        this.initilize(params);
    }

    // called when the cell is refreshed
    refresh(params: any): boolean {
        this.initilize(params);
        return true;
    }

}
