import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';

@Component({
  selector: 'formly-ul',
  templateUrl: "./formly-ul.component.html"
})
export class FormlyUlComponent extends FieldType implements OnInit, OnDestroy {

  isFormSubmitted: boolean = false;
  formSubmissionSubscription: Subscription;
  fieldKey: string;

  listOptions: any;

  constructor(private dynamicFormService: DynamicFormService) {
    super();
  }

  ngOnInit() {
    if (this.field.key) {
      this.fieldKey = this.field.key.toString();
    }
    this.listOptions = this.to.options;
    this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
      this.isFormSubmitted = data;
    });
  }

  ngOnDestroy() {
    if (this.formSubmissionSubscription) {
      this.formSubmissionSubscription.unsubscribe();
    }
  }

  onClick(item) {
    this.model[this.field.key.toString()] = item.key;
    if (this.to.click) {
      this.to.click(this, item.key);
    }
  }

}