<div class="overLayDiv active"></div>
<div id="rightSideBar">
    <div class="rightSidePanel active w-55pc">
        <div class="d-flex flex-column bd-highlight h-100" >
          <header class="py-1 d-flex justify-content-between align-items-center height-60px">
              <h2>
                {{ "label_alert" | translate }}
              </h2>
          </header>
          <div class="block mt-2 px-0 pt-0">
            <ul class="px-3">
                <li class="py-3 border-bottom w-100pc d-flex align-items-center eod-text-danger">
                  <i class="fas fa-exclamation-circle fa-2x pr-2 inline-block"></i><span class="f-16 fw-600"> {{ "essential_pack_explore_note1" | translate }}</span>
                </li>
            </ul>
            <ul>
                <li class="py-3 border-bottom w-100pc d-flex align-items-center font-weight-bold eod-bg-secondary">
                  <i class="fas fa-circle f-8 pr-2 ml-3 inline-block eod-text-white" aria-hidden="true"></i>
                  <span>{{ "essential_pack_explore_note2" | translate }} {{this.basicFunctionTrialDays}} {{"essential_pack_explore_note2_1" |translate}}</span>
                  <img class="ml-2" src="../../../../assets/svg-icons/functioncategory/active/crown.svg" alt="">
                </li>
            </ul>
            <ul class="px-3">
                <li class="py-3 border-bottom w-100pc d-flex align-items-center">
                  <i class="fas fa-circle f-8 pr-2 inline-block eod-text-primary" aria-hidden="true"></i><span>{{ "essential_pack_explore_note3" | translate }}</span>
                </li>
                <li class="py-3 border-bottom w-100pc d-flex align-items-center">
                  <i class="fas fa-circle f-8 pr-2 inline-block eod-text-primary" aria-hidden="true"></i><span>{{ "essential_pack_explore_note4" | translate }} <strong> {{this.explorationDays}} days</strong> {{"essential_pack_explore_note4_1" | translate }}</span>
                </li>
                <!-- <li class="py-3 border-bottom w-100pc d-flex align-items-center eod-text-danger">
                    <i class="fas fa-circle f-8 pr-2 inline-block eod-text-primary" aria-hidden="true"></i><span>{{ "essential_pack_explore_note5" | translate }}</span>
                  </li> -->
                  <li class="py-3 border-bottom w-100pc d-flex align-items-center">
                    <i class="fas fa-circle f-8 pr-2 inline-block eod-text-primary" aria-hidden="true"></i><span>{{ "essential_pack_explore_note6" | translate }}</span>
                  </li>
            </ul>
          </div>
          <div class="block mt-2">
            <div class="fw-600 eod-text-grey-02">
              {{ "trial_end_alert_subtitle" | translate }}
            </div>
            <div class="font-weight-bold mt-2">
              {{ "trial_end_alert_subnote1" | translate }} {{ this.datePipe.transform(basicFunctionTrialStartDate) }}
            </div>
            <div class="color-red font-weight-bold mt-2">
              {{ "trial_end_alert_subnote2" | translate }} {{ this.datePipe.transform(basicFunctionTrialEndDate) }} {{ "trial_end_alert_subnote3" | translate }}
            </div>
          </div>
          <div class="mt-auto d-flex justify-content-center bg-white p-2">
           
            <button type="button" class="btn btn-outline-primary bg-white fw-600 height-38px mr-2" (click)="fnClose()">
              <b> {{ "Explore" | translate }}</b>
            </button>

            <button type="button" class="btn eod-btn-primary height-38px" (click)="fnSubscribeNow()">
                <b> {{ "essential_pack_subscribe_now" | translate }}</b>
              </button>
          </div>
        </div>
    </div>
</div>
