import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: 'log-cell',
  templateUrl: './log-icon-renderer.component.html'
})
export class LogIconRendererComponent implements ICellRendererAngularComp {

  tooltipText = this.translate.instant('label_log');
  constructor(private translate: TranslateService) {

  }

  initilize(params: ICellRendererParams) {
    if (params && params.colDef && params.colDef.headerComponentParams && params.colDef.headerComponentParams.isComingSoon) {
      this.tooltipText = this.translate.instant('coming_soon');
    }
  }

  // called on init
  agInit(params: any): void {
    this.initilize(params);
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.initilize(params);
    return true;
  }

}
