
//
export interface IBillingDataForPricingRequest {
    currencyId: number;
}

export class BillingDataForPricingRequest implements IBillingDataForPricingRequest {
    currencyId: number;
    constructor(currencyId: number) {
        this.currencyId = currencyId;
    }
}

export interface IBillingDataForPricingResponse {
    billingMultiCurrencySetting: IBillingMultiCurrencySettingResponse;
    subscriptionDiscount: ISubscriptionDiscountResponse[];
}

export class BillingDataForPricingResponse implements IBillingDataForPricingResponse {
    billingMultiCurrencySetting: IBillingMultiCurrencySettingResponse;
    subscriptionDiscount: ISubscriptionDiscountResponse[];
}

export interface IBillingMultiCurrencySettingResponse {
    billingMultiCurrencySettingId: number;
    currencyId: number;
    basicPrice: number;
    assistedSupportPrice: number;
    minimumPricePerTransaction: number;
    minimumPricePerUser: number;
    isDefault: boolean;
    isActive: boolean;
    createdBy: number;
    createdOn: number;
    updatedBy: number;
    updatedOn: number;
}
export interface ISubscriptionDiscountResponse {
    subscriptionDiscountId: number;
    discountName: string;
    minUnitCount: number;
    maxUnitCount: number;
    isPercentage: boolean;
    discount: number;
    createdBy: number;
    createdDate: number;
    modifiedBy: number;
    modifiedDate: number;
    userCount: number;
    userCountDiscount: number;
    userCountDiscountName: string;
}

export class QuoteModel {
    firstName: string;
    lastName: string;
    email: string;
    business: string;
    emailQuote: boolean;
    noOfEmployee: number;
    hearAboutUs: string;

}

export interface IPricingEmailQuoteRequest {
    email: string;
    business: string;
    firstName: string;
    lastName: string;
    noOfEmployee: number;
    hearAboutUs: string;
    CurrencySymbol: string;
    CurrencyCode: string;
}

export class FunctionPackageQuote {
    FunctionPackageName: string;
    FunctionPackageId: number;
    FunctionAmount: number;
    DisplayAmount: string;
    constructor(
        FunctionPackageName: string,
        FunctionPackageId: number,
        FunctionAmount: number,
        DisplayAmount: string) {
        this.FunctionPackageName = FunctionPackageName;
        this.FunctionPackageId = FunctionPackageId;
        this.FunctionAmount = FunctionAmount;
        this.DisplayAmount = DisplayAmount;

    }
}

export class PricingEmailQuoteRequest implements IPricingEmailQuoteRequest {
    email: string;
    business: string;
    firstName: string;
    lastName: string;
    noOfEmployee: number;
    hearAboutUs: string;
    emailQuote: boolean;
    CurrencySymbol: string;
    CurrencyCode: string;
    functionPackages: FunctionPackageQuote[];

    constructor(
        email: string,
        buisness: string,
        firstName: string,
        lastName: string,
        noOfEmployee: number,
        hearAboutUs: string,
        emailQuote: boolean,
        CurrencySymbol: string,
        CurrencyCode: string) {
        this.email = email;
        this.business = buisness;
        this.firstName = firstName;
        this.lastName = lastName;
        this.noOfEmployee = noOfEmployee;
        this.hearAboutUs = hearAboutUs;
        this.emailQuote = emailQuote;
        this.CurrencySymbol = CurrencySymbol;
        this.CurrencyCode = CurrencyCode;
    }
}
