<div class="input-group">
    <input id="{{ 'num' + fieldKey }}" type="text" class="form-control searchField" twoDigitDecimaNumber
        [formControl]="formControl" [formlyAttributes]="field" (input)="onNumberInput()"
        [ngClass]="{'has-value': formControl.value }">
    <label for="NumberInput">
        {{field.templateOptions.label}} 
        <span *ngIf="field.templateOptions.required">*</span>
    </label>
    <div *ngIf="formControl.errors && formControl.errors.required && isFormSubmitted" class="alert-danger">
        <span *ngIf="!formControl.value || !formControl.value.trim()">
            <b>{{ "label_Required" | translate: param }}</b>
        </span>
    </div>
</div>