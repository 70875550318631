import { Component, OnInit,Output,EventEmitter } from "@angular/core";
import { Claims } from "src/app/_models/common/claims";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { OrgBasicFunctionSubscriptionService } from "src/app/_services/billing-pricing/Org-BasicFunction-Subscription.service";
import { DateFormatPipe } from "src/app/_helpers/pipe.module";
import { IApiRequest,ApiRequest } from "src/app/_models";
import { first } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from "@angular/router";
import { OrgSubscription } from "src/app/_models/common/OrgSubscription";
import { PaymentCustomerAndIntentIdResponse } from "src/app/_models/stripe/PaymentCustomerAndIntentIdResponse";
import { SaveOrderCartDetailsService } from "src/app/_services/cart-order/SaveOrder-Cart-Details.service";
import { AuthenticationService } from "src/app/_services";
import { environment } from "src/environments/environment";


@Component({
  selector: "basic-function-explore-alert-panel",
  templateUrl: "./basic-function-explore-alert-panel.component.html"
})
export class BasicFunctionExploreAlertPanelComponent implements OnInit {

  @Output() closePanelEvent = new EventEmitter<any>();

  claims: Claims;

  basicFunctionTrialDays: number;
  explorationDays: number;
  basicFunctionTrialStartDate: number;
  basicFunctionTrialEndDate: number;

  constructor(
    private claimsService: ClaimsService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private orgBasicFunctionSubscriptionService: OrgBasicFunctionSubscriptionService,
    private saveOrderCartDetailsService: SaveOrderCartDetailsService,
    private authenticationService: AuthenticationService,
    public datePipe: DateFormatPipe,
  ) {
    this.claimsService.currentClaims.subscribe((claims) => {
      this.claims = claims;
    });
  }

  ngOnInit() {
     this.fnGetOrgBasicFunctionSubscriptionTrialDetails();
  }
  
  fnGetOrgBasicFunctionSubscriptionTrialDetails() {
    this.orgBasicFunctionSubscriptionService
      .GetOrgBasicFunctionSubscriptionTrialDetails(this.claims.OrgId)
      .subscribe((res: any) => {
        if (res.responseInfo != null) {

          this.basicFunctionTrialDays = res.responseInfo.basicFunctionTrialDays;
          this.explorationDays = res.responseInfo.explorationDays;
          this.basicFunctionTrialStartDate = res.responseInfo.basicFunctionTrialStartDate;
          this.basicFunctionTrialEndDate = res.responseInfo.basicFunctionTrialEndDate;

        }
      });
  }

  fnSubscribeNow(){
    this.closePanelEvent.emit(false);
   }
  
  fnClose() {
      this.closePanelEvent.emit(true);
    }
}
