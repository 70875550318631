<div class="col-lg-12 padding-left-0 d-flex ">  
    <h6 class="text-left margin-right-7 margin-right-10 fw-600">
     <span> {{field.templateOptions.label}}</span> 
    </h6>
    <div class="custom-control custom-switch text-right position-relative top--35px">
        <input type="checkbox" checked class="custom-control-input" id="{{ 'cbx' + fieldKey }}" [checked]="formControl.value"
        [formControl]="formControl" (input)="onValueChange()" [disabled]="field.templateOptions.disabled">

        <label [ngClass]="{'bigToggle': enableBigToggle}" class="custom-control-label cursor-pointer f-14 fw-600 lH-25 padding-left-5" for="{{ 'cbx' + fieldKey }}"></label>

    </div>
</div>