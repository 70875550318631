<div class="onboarding-banner-inner h-100vh">
     <img *ngIf="currentStep==1"
          class="onboarding-banner-background"
          src="../../../../assets/images/login-img.png"
          alt="Logo">

     <img *ngIf="currentStep==2"
          class="onboarding-banner-background"
          src="../../../../assets/images/step1.png"
          alt="SignUp">

     <img *ngIf="currentStep==3"
          class="onboarding-banner-background"
          src="../../../../assets/images/step2.png"
          alt="Organisation">

     <img *ngIf="currentStep==4"
          class="onboarding-banner-background"
          src="../../../../assets/images/step3.png"
          alt="InviteEmployee">

     <img *ngIf="currentStep==5"
          class="onboarding-banner-background"
          src="../../../../assets/images/step4.png"
          alt="PlayStore">

     <img *ngIf="currentStep==6"
          class="onboarding-banner-background"
          src="../../../../assets/images/step6.png"
          alt="PlayStore">

     <div *ngIf="currentStep==1">
          <span class="icon"><i class="fas fa-lightbulb fa-2x"></i></span>
          <div class="text-desc f-12">
               <h4>{{'pro_tip' |translate }}</h4>
               <p>{{'scan_get_rid_of_paper' | translate}}</p>
          </div>
     </div>

     <div *ngIf="currentStep==2">
          <span class="icon"><i class="fas fa-lightbulb fa-2x"></i></span>
          <div class="text-desc f-12">
               <h4>{{'pro_tip' |translate }}</h4>
               <p class="pb-3">{{'instantly_snap_a_photo_of_receipt'| translate}}</p>
               <p>{{'gain_complete_clarity_of_employee' | translate}}</p>
          </div>
     </div>
     <div *ngIf="currentStep==5">
          <span class="icon"><i class="fas fa-lightbulb fa-2x"></i></span>
          <div class="text-desc f-12">
               <h4>{{'pro_tip' |translate }}</h4>
               <p class="pb-3">{{'to_setup_meaningful_trial' | translate}}
               </p>
               <p>{{'their_roles_can_be_changed' | translate}}
               </p>
          </div>
     </div>
     <div class="text-desc review"
          *ngIf="currentStep==3">
          <div class="d-flex align-items-center mb-2">
               <img class="mr-2"
                    src="../../../../assets/images/G2.png"
                    height="30px"
                    alt="">
               <span class="eod-text-warning">
                    <i class="fas fa-star px-1 fa-2x"></i>
                    <i class="fas fa-star px-1 fa-2x"></i>
                    <i class="fas fa-star px-1 fa-2x"></i>
                    <i class="fas fa-star px-1 fa-2x"></i>
                    <i class="fas fa-star px-1 fa-2x"></i>
               </span>
          </div>
          <h5>{{'great_platform_that_is_easy_to_use' | translate}}</h5>
          <h6>{{'verified_user_in_telecommunication' | translate}}</h6>
     </div>
     <div class="text-desc review"
          *ngIf="currentStep==4">
          <div class="d-flex align-items-center mb-2">
               <img class="mr-2"
                    src="../../../../assets/images/peer-insights.png"
                    height="30px"
                    alt="">
               <span class="eod-text-warning">
                    <i class="fas fa-star px-1 fa-2x"></i>
                    <i class="fas fa-star px-1 fa-2x"></i>
                    <i class="fas fa-star px-1 fa-2x"></i>
                    <i class="fas fa-star px-1 fa-2x"></i>
                    <i class="fas fa-star px-1 fa-2x"></i>
               </span>
          </div>
          <h5>{{'easy_to_use_and_great_support' | translate}}</h5>
          <h6>{{'verified_user_in_greater' | translate}}</h6>
     </div>
</div>