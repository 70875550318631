import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { CounterAppSeqType } from '../../../../_models';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-text-two-decimal-number-renderer',
  templateUrl: './text-two-decimal-number-renderer.component.html'
})
export class TextTwoDecimalNumberRendererComponent implements ICellRendererAngularComp {
  errReq: boolean = false;
  value: string;
  params: ICellRendererParams;
  isMandatory:boolean=false;
  isEnd:boolean=false;
  isVisible : boolean = false;
  placeholdertext: string = this.translate.instant('enter_amount_set_limit');

  constructor(private translate: TranslateService)
  {

  }


  initilize(params: ICellRendererParams) {
    if (params.data) {
      this.params = params;
      if(Number(params.data.allowanceRate)>0)
            this.value = params.data.allowanceRate;
      if(Number(params.data.caLimit)>0)
            this.value = params.data.caLimit;      
      if(Number(params.data.carbonFootprint) > 0)
            this.value = params.data.carbonFootprint;     
          
      if(Number(params.data.carbonFootprint) <= 0)
      {    
        this.placeholdertext = this.translate.instant('enter_cev');              
      }
            
      if(Number(this.value)>0)
           this.placeholdertext = '';

      this.isMandatory = params.data.sequence == CounterAppSeqType.Mandatory ; 
      this.isEnd = params.data.sequence == CounterAppSeqType.End;
      this.errReq = (this.value == '' || Number(this.value) <= 0)|| params.data.errorRecord? true : false;
      if(this.errReq)
      {  
        this.placeholdertext = this.translate.instant('label_Required');
        this.value = '';
      }

    }
  }

  agInit(params: any): void {
    this.initilize(params);
  }

  refresh(params: any): boolean {
    this.initilize(params);
    return true;
  }

  onChange(event) {
   
    if(event.target.value && event.target.value != ''){
      this.value = parseFloat(event.target.value).toFixed(2);
    }
    else{
      this.value = event.target.value;
    }
    this.errReq = (this.value == '' || Number(this.value) <= 0) ? true : false;
    this.params.setValue(this.value);
    this.params.node.setData(this.params.data);
    this.params.eGridCell.click();
  }

}
