export interface IMileageWizardRequestDto {
  orgId: string;
}


export class MileageWizardRequestDto implements IMileageWizardRequestDto {
  orgId: string;

  constructor(orgId: string) {
    this.orgId = orgId;
  }

}



