import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { MileageUOMEnum } from 'src/app/_models';

@Component({
    selector: 'formly-nr-display',
    templateUrl: "./formly-nr-display.component.html"
})
export class FormlyNrDisplayComponent extends FieldType implements OnInit {
    fieldKey: string;
    nrAvailableDistance: any;
    get mileageUOMEnum() {
        return MileageUOMEnum;
    }

    constructor() {
        super();
    }

    ngOnInit() {
        if (this.field.key) { this.fieldKey = this.field.key.toString(); }
        if(this.model.mileage)
        {
            if (this.model.mileage && this.model.mileage.nrAvailableDistance) {
                this.model.mileage.nrAvailableDistance = parseFloat(this.model.mileage.nrAvailableDistance).toFixed(2).toString();
            }
            else {
                this.model.mileage.nrAvailableDistance = "0.00";
            }
        }
    }
}
