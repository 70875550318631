export const environment = {
  production: true,
  AppUrl: "https://apps.expenseondemand.com/",
  GatewayUrl: "https://webapi.expenseondemand.com",
  catalogApiUrl: "https://webapim.expenseondemand.com/catalog/api/",
  expenseApiUrl: "https://webapim.expenseondemand.com/expense/api/",
  exportApiUrl: "https://webapim.expenseondemand.com/export/api/",
  billingApiUrl: "https://webapim.expenseondemand.com/billing/api/",
  docApiUrl: "https://webapim.expenseondemand.com/dutyofcare/api/",
  notificationApiUrl: "https://webapim.expenseondemand.com/notification/api/",
  functionsUrl: "https://eod-live-functions.azurewebsites.net/api/",
  apimSubscriptionKey: "af0440cfd393462ea0f12cc2b6f00441",
  apimTraceEnabled: "true",
  apimDevPortalUrl: "https://developer.expenseondemand.com",
  platform: "web",
  applicationVersion: 1.1,
  appId: "environment_appId",
  deviceId: "environment_deviceId",
  requestTimeStamp: 1569566525,
  requestId: "HJJHG2627KJH",
  assistedSupportAmount_INR: 5000,
  assistedSupportAmount_GBP: 750,
  billingPlanRenewalDay: 180,
  pricingMinimumPrice_INR: 500,
  pricingMinimumPrice_GBP: 25,
  noOfRecords: 20,
  trialFeedbackDays: 15,
  instrumentationkey: "dedca32f-d8fe-42d8-ae7b-d0e71ba9e50d",
  googleApiKey: "AIzaSyAZ9a2KC5kcWBZyhivHTmI8Mj2wxT3vJTU",
  //Prod - url
  token_endpoint: "https://identityserver.expenseondemand.com/connect/token",
  authorization_endpoint:
    "https://identityserver.expenseondemand.com/connect/authorize",
  userinfo_endpoint:
    "https://identityserver.expenseondemand.com/connect/userinfo",
  end_session_endpoint:
    "https://identityserver.expenseondemand.com/connect/endsession",
  check_session_endpoint:
    "https://identityserver.expenseondemand.com/connect/checksession",
  revocation_endpoint:
    "https://identityserver.expenseondemand.com/connect/revocation",
  issuer: "https://identityserver.expenseondemand.com",
  StripePublicKey: "pk_live_WoVeNyAyh1eRiKIBNiXsJ7iG",
  openRoutes: [
    "/dashboard/overview",
    "/mysubscription",
    "/comingsoon",
    "/pricing",
    "/support/support",
    "/universalimport",
  ],
  restrictedRoutes: [
    "/secondaryfm/list",
    "/secondaryfm/privilege",
    "/support/unsubscribe",
    "/settings",
    "/shoppingcartlist",
    "/shoppingcartreview",
    "/shoppingcartConfirmed",
    "/stripeAgreement",
    "/orgsetting",
  ],
  fmNoPrivilegeRoutes: [
    "/approver/appclaimant",
    "/claimant/expenselist",
    "/changepassword",
    "/approver/appnrreceipts",
    "/approver/appfcreceipts",
    "/approver/papproval",
    "/approver/advanceclaimantlist",
    "/claimant/claimantdashboard",
    "/claimant/claimantnrlist",
    "/claimant/claimantfclist",
    "/claimant/mymileagerate",
    "/orgsetting/claimantfcdashboard",
  ],
  xeroClientId: "4537B3C447FA4B87A7BBD0B3B74814AB",
  dateFormat: "dd-MMM-yyyy",
  defaultauth: "fakebackend",
  plaidClientId_UK: "65eed5d575f336001c691b9b",
  plaidClientSecret_UK: "42d077b929ef6d86eb07aa45914c05",
  plaidClientId_US: "61547c81573202001071271a",
  plaidClientSecret_US: "f43d1b51ab86e7913b9620bacfb0b4",
  firebaseConfig: {
    apiKey: "",
    authDomain: "",
    databaseURL: "",
    projectId: "",
    storageBucket: "",
    messagingSenderId: "",
    appId: "",
    measurementId: "",
  },
};
