import { Component, ViewEncapsulation } from "@angular/core";

import { ICellRendererAngularComp, ITooltipAngularComp } from "ag-grid-angular";
import { ITooltipParams } from "ag-grid-community";

@Component({
  selector: 'tooltip-cell',
  templateUrl: './tooltip-renderer.component.html',
  styleUrls: ['./tooltip-renderer.component.scss']
})
export class TooltipRenderComponent implements ITooltipAngularComp {
  public params: any;
  isTooltipDisplay: boolean;
  tooltipText: string;
  constructor() {
    //https://www.ag-grid.com/angular-grid/component-tooltip/
  }

  initilize(params: any) {
    if (params && params.data) {
      this.params = params;
      this.isTooltipDisplay = params.data.isTooltipDisplay;
      this.tooltipText = params.data.tooltipText;

    }
  }

  // called on init
  agInit(params: any): void {
    this.initilize(params);

  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.initilize(params);
    return true;
  }

}
