<div class="input-group margin-top-10 vehicle-types" id="vehicleTypes">
    <div class="row">
        <div class="col-md-4 no-padding-right-left btn-vehicleType" *ngFor="let option of to.options" [ngClass]="classupdateWhenNoAdvanceMileage(option)" (click)="onValueChange(option.id)">
            <input id="{{ 'txt' + fieldKey }}" type="radio" [name]="to.name" [formControl]="formControl" [formlyAttributes]="field"
                [value]="option.id" />
            <span class="width-auto">
                <i [ngClass]="option.icon ? option.icon : ''" class="f-20 align-middle icon-Orange"
                    aria-hidden="true"></i>
                <em class="padding-left-10">
                    {{ option.value }}
                </em>
            </span>
        </div>
    </div>
</div>