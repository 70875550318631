import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { FunctionPackageTypeEnum } from "src/app/_models/enum/solo-enum";

@Component({
    selector: 'app-starter-web-support-icon-renderer',
    templateUrl: './starter-web-support-icon-renderer.component.html',
})
export class StarterWebSupportIconRendererComponent implements ICellRendererAngularComp {

    public text: number;

    constructor() {
    }


    initilize(params: ICellRendererParams) {
        this.text = params.getValue();
    }

    // called on init
    agInit(params: any): void {
        this.initilize(params);
    }

    // called when the cell is refreshed
    refresh(params: any): boolean {
        this.initilize(params);
        return true;
    }

    isFunctionForWeb(type: number) {
        let isSupport = false;
        if (
            type == FunctionPackageTypeEnum.Both ||
            type == FunctionPackageTypeEnum.Web
        ) {
            isSupport = true;
        }
        return isSupport;
    }
}
