import { Component, OnDestroy, OnInit, EventEmitter } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { FunctionPackageEnum, IApiresponse } from '../../../../_models';
import { Claims } from '../../../../_models/common/claims';
import { MarketingPopup } from '../../../../_models/common/marketing-popup';
import { ApproverCADetails, IApproverCADetails, IRespApproverCADetails } from '../../../../_models/counter-approver/counter-approver.model';
import { ClaimsService } from '../../../../_services/common/claims.service';
import { CounterApproverService } from '../../../../_services/counter-approver/counter-approver.service';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'formly-ng-select-input',
    templateUrl: "./formly-ng-select-input.component.html"
})
export class FormlyNgSelectInputComponent extends FieldType implements OnInit, OnDestroy {

    param: any;
    isFormSubmitted: boolean = false;
    formSubmissionSubscription: Subscription;
    fieldKey: string;
    claims: Claims;
    linkType:boolean=false;
    orgId:number;
    constructor(
        private dynamicFormService: DynamicFormService,
        private claimsService: ClaimsService,
        private translate: TranslateService,
        private caService:CounterApproverService,
        private sanitizer: DomSanitizer
    ) {
        super();
        this.claimsService.currentClaims.subscribe(claims => {
            this.claims = claims;  
            this.orgId = this.claims.OrgId;          
          });
    }

    ngOnInit() {
        if (this.field.key) {
            this.fieldKey = this.field.key.toString();
            if(this.fieldKey == 'approverId')
              this.linkType=true;
        }
        this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
            this.isFormSubmitted = data;
        });        
    }

    ngOnDestroy() {
        if (this.formSubmissionSubscription) {
            this.formSubmissionSubscription.unsubscribe();
        }
    }

    onValueChange() {
        if (this.to.change) {
            this.to.change(this.field, this.formControl.value);
        }
    }

    allowAcuteChar(e) {
        let regex = new RegExp("^[a-zA-ZÀ-ú\p{L} '-]+$");
        let str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (regex.test(str)) {
            return true;
        }
        else return false;
    }

    omitSpecialChar(event) {      
      
        if (this.field.templateOptions.allowAcuteChar) {
            return this.allowAcuteChar(event);
        }
       
    }

    fnOpenPanel() {
        this.to.click(this.field);
    }

    sanitizerHtml(element) {
        let safeText = this.sanitizer.bypassSecurityTrustHtml(element);
        return safeText;
    }
}