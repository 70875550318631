<div class="row margin-top-20 border-bottom padding-bottom-10">
  <div class="col-lg-12 no-padding-right-left">
      <div class="{{alignMentClass}}">
          <button class="padding-15 border border-secondary">
            <span class="d-block">
                <i class="fas fa-image-polaroid f-18" aria-hidden="true">                            
                </i>
            </span>
          </button>
          <span class="d-block f-12 margin-top-5 text-charcoal-black">{{label}}</span>
          <span class="d-block f-12 margin-top-5 text-charcoal-black">
            <a class="text-logo-blue text-underline" (click)="fnOpenReceiptPanel('Add')">{{ "add_more" | translate :param }}</a> | 
            <a class="text-logo-blue text-underline" (click)="fnOpenReceiptPanel('View')">{{ "view_document" | translate :param }}
              <span class="badge badge-pill badge-danger badge-pill-style"
              *ngIf="field.templateOptions.documentCount>0">
                 {{field.templateOptions.documentCount}} 
              </span>
            </a>  
          </span>
      </div>
  </div>
</div>
<div *ngIf="formControl.errors && formControl.errors.required && isFormSubmitted && field.templateOptions.documentCount==0" class="alert-danger">
  <span> <strong>{{ "label_Required" | translate: param }}</strong></span>
</div>
