import { AbstractControl } from "@angular/forms";
import { EMAIL_REGEX } from "src/util/constants";

export function emailValidator(
  control: AbstractControl
): { [key: string]: any } | null {
  var emailPattern = EMAIL_REGEX;
  const valid = (control.value == "" || emailPattern.test(control.value));
  return valid
    ? null
    : { invalidEmail: { valid: false, value: control.value } };
}

export function numberValidator(
  control: AbstractControl
): { [key: string]: any } | null {
  var numberPattern = /^\d+$/;
  const valid = (control.value == "" || numberPattern.test(control.value));
  return valid
    ? null
    : { invalidNumber: { valid: false, value: control.value } };
}
