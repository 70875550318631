import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';

@Component({
  selector: 'app-formly-radio-input-with-side-description',
  templateUrl: './formly-radio-input-with-side-description.component.html'  
})

export class FormlyRadioInputWithSideDescriptionComponent extends FieldType implements OnInit, OnDestroy {

  param: any;
  isFormSubmitted: boolean = false;
  formSubmissionSubscription: Subscription;
  fieldKey: string;

  constructor(
    private dynamicFormService: DynamicFormService
  ) {
    super();
  }

  ngOnInit() {
    if (this.field.key) {
      this.fieldKey = this.field.key.toString();
    }
    this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
      this.isFormSubmitted = data;
    });
  }

  ngOnDestroy() {
    if (this.formSubmissionSubscription) {
      this.formSubmissionSubscription.unsubscribe();
    }
  }

  onValueChange() {
    if (this.to.change) {
      this.to.change(this.field, this.formControl.value);
    }
  }
}


