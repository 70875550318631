import { Component, ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { param } from "jquery";

@Component({
    selector: 'ng-select-renderer',
    templateUrl: './ng-select-renderer.component.html',
    styleUrls: ["./ng-select-renderer.component.scss"],
})

export class NgSelectRendererComponent implements ICellRendererAngularComp {

    showCheckIcon: boolean;
    placeholder = "";
    items = [];
    value: any;
    params: any;

    constructor() {

    }

    initilize(params: ICellRendererParams) {
        if (params.colDef.headerComponentParams) {
            this.items = params.colDef.headerComponentParams.dropdownItems;
            this.placeholder = params.colDef.headerComponentParams.placeholder;

        }
        this.value = params.getValue();
    }

    agInit(params: any): void {
        this.params = params;
        this.initilize(params);
    }

    refresh(params: any): boolean {
        this.initilize(params);
        return true;
    }

    onChange($event) {
        if (this.params.onChange instanceof Function) {
            const params = {
                event: $event,
                rowData: this.params.node.data
            }
            this.params.onChange(params);
        }
    }

}
