import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { IApiresponse } from "../../_models/common/request-response/apiresponse";
import { IApiRequest } from "../../_models/common/request-response/api-request";
import { ClaimsHeaderService } from "../../shared/claimsHeader/claims-header.service";
import {
    IClaimantExpenseListRequest, IGetClaimantExpenseListResponse, 
    IRecentTagsRequest, IRecentTagsResponse, IPreApprovalBaseRequest, IPreApprovalTagListResponse,
    IUpdateExpenseRequest, IClaimantExpenseDetail, IRecentJourneysRequest, IRecentJourneysResponse, IMediaSaveResponse, IExpenseRequest,
    IResourceMileageDetailsResponse, IGetReceiptExpensesWithAttachmentResponse, IGetReceiptExpensesWithAttachmentRequest, IVehicleListResponse, CalculateMileageRequest, CalculateMileageResponse, OrganisationRulesResponse, NRDetailRequest, ResourceMileageDetailsRequest,
    IClaimantAmendedExpenseDetailsRequest, IClaimantAmendedExpenseDetailsRes, DeleteReceiptResponseList,
    DeleteReceiptShortRequest, NRFCReceiptResponse, CreditCardExpenseInfoRes, ISaveUserCustomSettingRequest,UserCustomSetting
} from "../../_models/claimant-on-web/claimant-on-web";
import {
    ISaveExpenseInfoRequest
} from "src/app/_models/claimant-on-web/expense-info";
import { IClaimantExpenseDetailResponse } from "src/app/_models/twostepapproval/claimant-expense-detail";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { ClaimType, MileageAllowanceEnum, ClaimAction } from "src/app/_models";
import { RSAHelper } from 'src/app/_helpers/RSAHelper';
import { TranslateService } from '@ngx-translate/core';
import { KeyValue } from "src/app/_models/common/key-value";

@Injectable({
    providedIn: "root",
})
export class ClaimantOnWebService {
    httpHeaders: HttpHeaders;
    constructor(
        private http: HttpClient,
        private claimsHeaderService: ClaimsHeaderService,
        private translate: TranslateService,
    ) { }
    GetClaimantExpenseList(requestinfo: IApiRequest<IClaimantExpenseListRequest>): Observable<IApiresponse<IGetClaimantExpenseListResponse>> {
        let body = JSON.stringify(requestinfo);
        return this.http.post<IApiresponse<IGetClaimantExpenseListResponse>>(`${environment.expenseApiUrl}GetClaimantExpenseList`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
    }

    GetRecentTags(requestinfo: IApiRequest<IRecentTagsRequest>): Observable<IApiresponse<IRecentTagsResponse>> {
        let body = JSON.stringify(requestinfo);
        return this.http.post<IApiresponse<IRecentTagsResponse>>(`${environment.expenseApiUrl}RecentTags`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
    }

    UpdateExpenseStatus(
        requestInfo: IApiRequest<IUpdateExpenseRequest>
    ): Observable<IApiresponse<any>> {
        let body = JSON.stringify(requestInfo);
        return this.http.post<IApiresponse<any>>(
            `${environment.expenseApiUrl}UpdateExpenseStatus`,
            body,
            { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
        );
    }

    GetPreApprovalTagList(requestinfo: IApiRequest<IPreApprovalBaseRequest>): Observable<IApiresponse<IPreApprovalTagListResponse>> {
        let body = JSON.stringify(requestinfo);
        return this.http.post<IApiresponse<IPreApprovalTagListResponse>>(`${environment.expenseApiUrl}GetPreApprovalTagList`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
    }

    GetClaimantExpenseDetail(expenseId): Observable<IApiresponse<IClaimantExpenseDetail>> {
        let expenseDetailUrl = `${environment.expenseApiUrl}GetClaimantExpenseDetail/` + expenseId + ``;
        return this.http.get<IApiresponse<IClaimantExpenseDetail>>(expenseDetailUrl, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
    }
    GetApproveExpenseDetail(expenseID): Observable<IApiresponse<IClaimantExpenseDetailResponse>> {
        let expenseDetailUrl = `${environment.expenseApiUrl}GetApproveExpenseDetail/` + expenseID + ``;
        return this.http.get<IApiresponse<IClaimantExpenseDetailResponse>>(expenseDetailUrl, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
    }

    GetRecentJourneys(
        requestInfo: IApiRequest<IRecentJourneysRequest>
    ): Observable<IApiresponse<IRecentJourneysResponse>> {
        let body = JSON.stringify(requestInfo);
        return this.http.post<IApiresponse<IRecentJourneysResponse>>(
            `${environment.expenseApiUrl}RecentJourneys`,
            body,
            { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
        );
    }


    CalculateMileageMultipleLeg(
        requestInfo: IApiRequest<CalculateMileageRequest>
    ): Observable<IApiresponse<CalculateMileageResponse>> {
        let body = JSON.stringify(requestInfo);
        return this.http.post<IApiresponse<CalculateMileageResponse>>(
            `${environment.billingApiUrl}CalculateMileageMultipleLeg`,
            body,
            { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
        );
    }


    GetResourceMileageDetails(
        requestInfo: IApiRequest<ResourceMileageDetailsRequest>
    ): Observable<IApiresponse<IResourceMileageDetailsResponse>> {
        let body = JSON.stringify(requestInfo);
        return this.http.post<IApiresponse<IResourceMileageDetailsResponse>>(
            `${environment.billingApiUrl}GetResourceMileageDetails`,
            body,
            { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
        );
    }

    GetUserVehicleInfo(userId: number): Observable<IApiresponse<IVehicleListResponse>> {
        let params = new HttpParams();
        params = params.append("UserId", userId.toString());
        return this.http.get<IApiresponse<IVehicleListResponse>>(
            `${environment.billingApiUrl}GetUserVehicleInfo`,
            {
                params: params,
                headers: this.claimsHeaderService.createLoginAuthorizationHeader()
            }
        );
    }

    GetOrganisationRules(): Observable<IApiresponse<OrganisationRulesResponse>> {
        return this.http.get<IApiresponse<OrganisationRulesResponse>>(
            `${environment.billingApiUrl}GetOrganisationRules`,
            { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
        );
    }

    SaveExpenseReceipts(requestInfo: IApiRequest<IExpenseRequest>):
        Observable<IApiresponse<IMediaSaveResponse>> {
        let formData = new FormData();
        for (let index = 0; index < requestInfo.requestInfo.receiptFiles.length; index++) {
            formData.append('receiptFiles', requestInfo.requestInfo.receiptFiles[index]);
        }
        formData.append('requestData', requestInfo.requestInfo.requestData);
        formData.append('content', requestInfo.requestInfo.content);

        return this.http.post<IApiresponse<IMediaSaveResponse>>(
            `${environment.expenseApiUrl}SaveMediaForMultipleExpense`,
            formData,
            { headers: this.claimsHeaderService.createLoginAuthorizationHeaderWithoutContentTypeHeader() }
        );
    }

    GetReceiptExpensesWithAttachment(
        requestInfo: IApiRequest<IGetReceiptExpensesWithAttachmentRequest>
    ): Observable<IApiresponse<IGetReceiptExpensesWithAttachmentResponse>> {
        let body = JSON.stringify(requestInfo);
        return this.http.post<IApiresponse<IGetReceiptExpensesWithAttachmentResponse>>(
            `${environment.expenseApiUrl}GetReceiptExpensesWithAttachment`,
            body,
            { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
        );
    }
    getField(key: string, fields: FormlyFieldConfig[]): FormlyFieldConfig {
        for (let i = 0, len = fields.length; i < len; i++) {
            const f = fields[i];
            if (f.key === key) {
                return f;
            }

            if (f.fieldGroup && !f.key) {
                const cf = this.getField(key, f.fieldGroup);
                if (cf) {
                    return cf;
                }
            }
        }
    }
    fnSetClassForEntItems(Type, isDisabled, isTipsEnabled, pageType) {
        let disabledClass = '';
        if (isDisabled) {
            disabledClass = ' disabled';
        }
        if (!isTipsEnabled) {
            if (Type == 'emp') {
                return (pageType == 'pay' ? 'col-xl-6 col-lg-6 col-md-6 col-sm-6 padding-left-0 disabled' : 'col-xl-6 col-lg-6 col-md-6 col-sm-6 padding-left-0') + disabledClass;
            }
            else if (Type == 'guest') {
                return (pageType == 'pay' ? 'col-xl-6 col-lg-6 col-md-6 col-sm-6 no-padding-right-left disabled' : 'col-xl-6 col-lg-6 col-md-6 col-sm-6 no-padding-right-left') + disabledClass;
            }
        }
        else {
            if (Type == 'emp') {
                return (pageType == 'pay' ? 'col-xl-4 col-lg-4 col-md-4 col-sm-4 padding-left-0 disabled' : 'col-xl-4 col-lg-4 col-md-4 col-sm-4 padding-left-0 padding-left-0') + disabledClass;
            }
            else if (Type == 'guest') {
                return (pageType == 'pay' ? 'col-xl-4 col-lg-4 col-md-4 col-sm-4 no-padding-right-left disabled' : 'col-xl-4 col-lg-4 col-md-4 col-sm-4 no-padding-right-left') + disabledClass;
            }
            else {
                return (pageType == 'pay' ? 'col-xl-4 col-lg-4 col-md-4 col-sm-4 padding-right-0 disabled' : 'col-xl-4 col-lg-4 col-md-4 col-sm-4 padding-right-0') + disabledClass;
            }
        }
    }
    fnSetAllowanceText(selectedMileageAllowance, allowanceId) {
        let allowanceText = this.translate.instant('add_passenger_load');
        if (selectedMileageAllowance && allowanceId) {
            switch (allowanceId) {
                case MileageAllowanceEnum.OnePassenger:
                    allowanceText = this.translate.instant('one_passenger');
                    break;
                case MileageAllowanceEnum.TwoPassenger:
                    allowanceText = this.translate.instant('two_passenger');
                    break;
                case MileageAllowanceEnum.ThreePassenger:
                    allowanceText = this.translate.instant('three_passenger');
                    break;
                case MileageAllowanceEnum.OneLoad:
                    allowanceText = this.translate.instant('one_load');
                    break;
                case MileageAllowanceEnum.OnePassengerOneLoad:
                    allowanceText = this.translate.instant('one_passenger_one_load');
                    break;
                case MileageAllowanceEnum.TwoPassengerOneLoad:
                    allowanceText = this.translate.instant('two_passenger_one_load');
                    break;
                case MileageAllowanceEnum.ThreePassengerOneLoad:
                    allowanceText = this.translate.instant('three_passenger_one_load');
                    break;
                case MileageAllowanceEnum.FourPassenger:
                    allowanceText = this.translate.instant('four_passenger');
                    break;
                case MileageAllowanceEnum.FourPassengerOneLoad:
                    allowanceText = this.translate.instant('four_passenger_one_load');
                    break;
            }
        }
        return allowanceText;
    }
    GetCurrentNRBalance(
        requestInfo: IApiRequest<NRDetailRequest>
    ): Observable<IApiresponse<number>> {
        let body = JSON.stringify(requestInfo);
        return this.http.post<IApiresponse<number>>(
            `${environment.expenseApiUrl}GetCurrentNRBalance`,
            body,
            { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
        );
    }

    EncryptParameters(encryptValue) {
        let rsaHelper = new RSAHelper();
        let encrypt = rsaHelper.Encrypt(encryptValue);
        return encrypt;
    }
    DecryptParameters(encryptedParam): string {
        let rsaHelper = new RSAHelper();
        let decrypt = rsaHelper.Decrypt(encryptedParam);
        return decrypt;
    }

      ScanExpenseReceipts(requestInfo: IApiRequest<IExpenseRequest>):
      Observable<IApiresponse<KeyValue[]>> {
      let formData = new FormData();
      for (let index = 0; index < requestInfo.requestInfo.receiptFiles.length; index++) {
          formData.append('receiptFiles', requestInfo.requestInfo.receiptFiles[index]);
      }
      formData.append('requestData', requestInfo.requestInfo.requestData);
      formData.append('content', requestInfo.requestInfo.content);

      return this.http.post<IApiresponse<KeyValue[]>>(
          `${environment.expenseApiUrl}ScanReceiptData`,
          formData,
          { headers: this.claimsHeaderService.createLoginAuthorizationHeaderWithoutContentTypeHeader() }
      );
  }

      SaveExpenseInfo(
        requestInfo: IApiRequest<ISaveExpenseInfoRequest>
    ): Observable<IApiresponse<any>> {
        let body = JSON.stringify(requestInfo);
        return this.http.post<IApiresponse<any>>(
            `${environment.expenseApiUrl}SaveExpenseInfoOnly`,
            body,
            { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
        );
    }

    GetClaimantAmendedExpenseDetails(
        requestInfo: IApiRequest<IClaimantAmendedExpenseDetailsRequest>
    ): Observable<IApiresponse<IClaimantAmendedExpenseDetailsRes>> {
        let body = JSON.stringify(requestInfo);
        return this.http.post<IApiresponse<IClaimantAmendedExpenseDetailsRes>>(
            `${environment.expenseApiUrl}GetClaimantAmendedExpenseDetails`,
            body,
            { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
        );
    }

    CheckInvoiceNumberExistance(invoiceNumber: string): Observable<IApiresponse<boolean>> {
        let params = new HttpParams();
        params = params.append("invoiceNumber", invoiceNumber);
        return this.http.get<IApiresponse<boolean>>(
            `${environment.expenseApiUrl}CheckInvoiceNumberExistance`,
            {
                params: params,
                headers: this.claimsHeaderService.createLoginAuthorizationHeader()
            }
        );
    }

    GetClaimText(claimType: ClaimType, action: ClaimAction) {
        switch (true) {
            case action == ClaimAction.CreateClaim && claimType == ClaimType.Expense:
                {
                    return this.translate.instant('create_expense');
                }
            case action == ClaimAction.CreateClaim && claimType == ClaimType.Invoice:
                {
                    return this.translate.instant('label_create_invoice');
                }
            case action == ClaimAction.EditClaim && claimType == ClaimType.Invoice:
                {
                    return this.translate.instant('label_edit_invoice');
                }
            case action == ClaimAction.EditClaim && claimType == ClaimType.Expense:
                {
                    return this.translate.instant('label_edit_expense');
                }
            case action == ClaimAction.NoClaimMsg && claimType == ClaimType.Expense:
                {
                    return this.translate.instant('you_have_no_expenses');
                }
            case action == ClaimAction.NoClaimMsg && claimType == ClaimType.Invoice:
                {
                    return this.translate.instant('you_have_no_invoice');
                }
            case action == ClaimAction.CreateClaimBtnMsg && claimType == ClaimType.Expense:
                {
                    return this.translate.instant('create_a_new_expense_using_the_button_above');
                }
            case action == ClaimAction.CreateClaimBtnMsg && claimType == ClaimType.Invoice:
                {
                    return this.translate.instant('create_a_new_invoice_using_the_button_above');
                }
            case action == ClaimAction.ClaimList && claimType == ClaimType.Expense:
                {
                    return this.translate.instant('expense_list');
                }
            case action == ClaimAction.ClaimList && claimType == ClaimType.Invoice:
                {
                    return this.translate.instant('invoice_list');
                }
            case action == ClaimAction.ClaimDetail && claimType == ClaimType.Invoice:
                {
                    return this.translate.instant('label_invoice_detail');
                }
            case action == ClaimAction.ClaimDetail && claimType == ClaimType.Expense:
                {
                    return this.translate.instant('expense_detail');
                }
            case action == ClaimAction.ApproperClaimantListCount && claimType == ClaimType.Invoice:
                {
                    return this.translate.instant('label_approver_vendar_count');
                }
            case action == ClaimAction.ApproperClaimantListCount && claimType == ClaimType.Expense:
                {
                    return this.translate.instant('claimant_count');
                }
            case action == ClaimAction.ClaimCount && claimType == ClaimType.Invoice:
                {
                    return this.translate.instant('invoice_count');
                }
            case action == ClaimAction.ClaimCount && claimType == ClaimType.Expense:
                {
                    return this.translate.instant('expenses_count');
                }
            case action == ClaimAction.ClaimantName && claimType == ClaimType.Expense:
                {
                    return this.translate.instant('claimant_name');
                }
            case action == ClaimAction.ClaimantName && claimType == ClaimType.Invoice:
                {
                    return this.translate.instant('vendor_name');
                }
            case action == ClaimAction.FilterClaimDate && claimType == ClaimType.Invoice:
                {
                    return this.translate.instant('date_of_invoice');
                }
            case action == ClaimAction.FilterClaimDate && claimType == ClaimType.Expense:
                {
                    return this.translate.instant('date_Of_Expense');
                }
            case action == ClaimAction.AddReceipt && claimType == ClaimType.Invoice:
                {
                    return this.translate.instant('text_add_invoice_receipt');
                }
            case action == ClaimAction.AddReceipt && claimType == ClaimType.Expense:
                {
                    return this.translate.instant('text_add_receipt');
                }
            case action == ClaimAction.ViewReceipt && claimType == ClaimType.Invoice:
                {
                    return this.translate.instant('text_view_invoice_receipt');
                }
            case action == ClaimAction.ViewReceipt && claimType == ClaimType.Invoice:
                {
                    return this.translate.instant('text_view_receipt');
                }
            default:
                return "";
        }
    }


    DeleteReceipts(requestInfo: IApiRequest<DeleteReceiptShortRequest>):
        Observable<IApiresponse<DeleteReceiptResponseList>> {

        return this.http.post<IApiresponse<DeleteReceiptResponseList>>(
            `${environment.expenseApiUrl}DeleteReceipt`,
            requestInfo,
            { headers: this.claimsHeaderService.createLoginAuthorizationHeaderWithoutContentTypeHeader() }
        );
    }
    AddUpdateNRReceipt(requestInfo: IApiRequest<IExpenseRequest>):
        Observable<IApiresponse<NRFCReceiptResponse>> {
        let formData = new FormData();
        for (let index = 0; index < requestInfo.requestInfo.receiptFiles.length; index++) {
            formData.append('receiptFiles', requestInfo.requestInfo.receiptFiles[index]);
        }
        formData.append('requestData', requestInfo.requestInfo.requestData);
        formData.append('content', requestInfo.requestInfo.content);

        return this.http.post<IApiresponse<NRFCReceiptResponse>>(
            `${environment.expenseApiUrl}AddUpdateNRReceipt`,
            formData,
            { headers: this.claimsHeaderService.createLoginAuthorizationHeaderWithoutContentTypeHeader() }
        );
    }
    AddUpdateFCReceipt(requestInfo: IApiRequest<IExpenseRequest>):
        Observable<IApiresponse<NRFCReceiptResponse>> {
        let formData = new FormData();
        for (let index = 0; index < requestInfo.requestInfo.receiptFiles.length; index++) {
            formData.append('receiptFiles', requestInfo.requestInfo.receiptFiles[index]);
        }
        formData.append('requestData', requestInfo.requestInfo.requestData);
        formData.append('content', requestInfo.requestInfo.content);

        return this.http.post<IApiresponse<NRFCReceiptResponse>>(
            `${environment.expenseApiUrl}AddUpdateFCReceipt`,
            formData,
            { headers: this.claimsHeaderService.createLoginAuthorizationHeaderWithoutContentTypeHeader() }
        );
    }

    getUTCTimeStamp(date) {
        let now_utc = Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            0,
            0,
            0
        );

        return now_utc;
    }

    GetCardExpenseInfo(expenseId: number): Observable<IApiresponse<CreditCardExpenseInfoRes>> {
        let params = new HttpParams();
        params = params.append("expenseId", expenseId.toString());
        return this.http.get<IApiresponse<CreditCardExpenseInfoRes>>(
            `${environment.expenseApiUrl}GetCardExpenseInfo`,
            {
                params: params,
                headers: this.claimsHeaderService.createLoginAuthorizationHeader()
            }
        );
    }

    SaveUserCustomSetting(requestInfo: IApiRequest<ISaveUserCustomSettingRequest>): Observable<IApiresponse<any>> {
        let body = JSON.stringify(requestInfo);
        return this.http.post<IApiresponse<any>>(
            `${environment.billingApiUrl}SaveUserCustomSetting`,
            body,
            { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
        );
    }


    GetUserCustomSetting(userId: number): Observable<IApiresponse<UserCustomSetting[]>> {
        let params = new HttpParams();
        params = params.append("userId", userId.toString());
        return this.http.get<IApiresponse<UserCustomSetting[]>>(
            `${environment.billingApiUrl}GetUserCustomSetting`,
            {
                params: params,
                headers: this.claimsHeaderService.createLoginAuthorizationHeader()
            }
        );
    }

    GetExportedExpensesByExportedId(exportId: number): Observable<IApiresponse<any[]>> {
        let params = new HttpParams();
        params = params.append("exportId", exportId.toString());
        return this.http.get<IApiresponse<any[]>>(
            `${environment.expenseApiUrl}GetExportedExpensesByExportedId`,
            {
                params: params,
                headers: this.claimsHeaderService.createLoginAuthorizationHeader()
            }
        );
    }

    RecallExportedExpensesByExpenseIds(requestInfo, exportId) {
        let body = JSON.stringify(requestInfo);
        return this.http.post<IApiresponse<any>>(
            `${environment.expenseApiUrl}RecallExportedExpensesByExpenseIds?exportId=`+ exportId,
            body,
            { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
        );
    }
}


