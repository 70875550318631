<div class="input-group margin-top-20">
    <input id="{{ 'txt' + fieldKey }}" type="text" class="form-control text-logo-blue" [readonly]="to.disabled"
        [ngClass]="{'has-value': formControl.value, 'color-light-grey-disabledType': to.disabled }"
        #dateInput readonly [formControl]="formControl" [formlyAttributes]="field" (input)="onValueChange()">
    <label for="InputPanel">{{field.templateOptions.label}} <span
            *ngIf="field.templateOptions.required">*</span></label>
    <app-calendar-icon></app-calendar-icon>
    <div *ngIf="formControl.errors && formControl.errors.required && isFormSubmitted" class="alert-danger">
        <span *ngIf="!formControl.value || !formControl.value.trim()">
            <b>{{ "label_Required" | translate: param }}</b>
        </span>
    </div>
</div>
