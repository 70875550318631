import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "src/app/shared.module";
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppGridComponent } from 'src/app/shared/grid/grid-component/grid.component';
import { AgGridModule } from 'ag-grid-angular';
import { CheckBoxRendererComponent } from 'src/app/shared/grid/controls/checkbox-renderer/checkbox-renderer.component';
import { StatusRenderComponent } from 'src/app/shared/grid/controls/status-renderer/status-renderer.component';
import { LogIconRendererComponent } from 'src/app/shared/grid/controls/log-icon-renderer/log-icon-renderer.component';
import { ActionsRendererComponent } from 'src/app/shared/grid/controls/actions-renderer/actions-renderer.component';
import { CustomHeaderComponent } from 'src/app/shared/grid/controls/header-component/custom-header.component';
import { ChevronRendererComponent } from 'src/app/shared/grid/controls/chevron-renderer/chevron-renderer.component';
import { PreApprovalRendererComponent } from 'src/app/shared/grid/controls/pre-approval-renderer/pre-approval-renderer.component';
import { ReceiptIconRendererComponent } from "src/app/shared/grid/controls/receipt-icon-renderer/receipt-icon-renderer.component";
import { FieldValueRendererComponent } from "src/app/shared/grid/controls/field-value-renderer/field-value-renderer.component";
import { FormattedTextRenderer } from "src/app/shared/grid/controls/formatted-text-renderer/formatted-text-renderer.component";
import { ApproverRenderer } from "src/app/shared/grid/controls/approver-renderer/approver-renderer.component";
import { DateRendererComponent } from 'src/app/shared/grid/controls/date-renderer/date-renderer.component';
import { AmountRendererComponent } from 'src/app/shared/grid/controls/amount-renderer/amount-renderer.component';
import { PolicyNameRendererComponent } from "src/app/shared/grid/controls/policy-name-renderer/policy-name-renderer.component";
import { PolicyAmountRendererComponent } from "src/app/shared/grid/controls/policy-amount-renderer/policy-amount-renderer.component";
import { PricingTextRendererComponent } from "src/app/shared/grid/controls/pricing-text-renderer/pricing-text-renderer.component";
import { ReceiptsIconRendererComponent } from "src/app/shared/grid/controls/receipts-icon-renderer/receipts-icon-renderer.component";
import {ReceiptsTextRendererComponent} from 'src/app/shared/grid/controls/receipts-text-renderer/receipts-text-renderer.component';
import { SafeTextRendererComponent } from "src/app/shared/grid/controls/safe-text-renderer/safe-text-renderer.component";
import { StarterMobileSupportIconRendererComponent } from "src/app/shared/grid/controls/starter-mobile-support-icon-renderer/starter-mobile-support-icon-renderer.component";
import { StarterWebSupportIconRendererComponent } from "src/app/shared/grid/controls/starter-web-support-icon-renderer/starter-web-support-icon-renderer.component";
import { NgSelectRendererComponent } from "src/app/shared/grid/controls/ng-select-renderer/ng-select-renderer.component";
import { TooltipRenderComponent } from "src/app/shared/grid/controls/tooltip-renderer/tooltip-renderer.component";
import { ButtonsRendererComponent } from "src/app/shared/grid/controls/buttons-renderer/buttons-renderer.component";
import { ImportErrorsCountRendererComponent } from "src/app/shared/grid/controls/import-errors-count-renderer/import-errors-count-renderer.component";
import { CardNumberRendererComponent } from 'src/app/shared/grid/controls/card-number-renderer/card-number-renderer.component';
import { CardAssignedUsernameRendererComponent } from 'src/app/shared/grid/controls/card-assigned-username-renderer/card-assigned-username-renderer.component';
import { TextWithTooltipRendererComponent } from 'src/app/shared/grid/controls/text-tooltip-renderer/text-tooltip-renderer.component';
import { TextWithTooltipRightRendererComponent } from 'src/app/shared/grid/controls/text-tooltip-right-renderer/text-tooltip-right-renderer.component';
import { TextWithTooltipRenderer2Component } from 'src/app/shared/grid/controls/text-tooltip-renderer2/text-tooltip-renderer2.component';
import { ClickToSetupRendererComponent } from "src/app/shared/grid/controls/click-to-setup-renderer/click-to-setup-renderer.component";
import { ToggleRendererComponent } from '../../shared/grid/controls/toggle-renderer/toggle-renderer.component';
import { TextboxRendererComponent } from '../../shared/grid/controls/textbox-renderer/textbox-renderer.component';
import { TextRendererComponent } from '../../shared/grid/controls/text-renderer/text-renderer.component';
import { CheckboxIngridRendererComponent } from '../../shared/grid/controls/checkbox-ingrid-renderer/checkbox-ingrid-renderer.component';
import { SuspensionMessageRendererComponent } from '../../shared/grid/controls/suspension-message-renderer/suspension-message-renderer.component';
import { DefaultMessageRendererComponent } from '../../shared/grid/controls/default-message-renderer/default-message-renderer.component';
import { TextWithDescriptionRendererComponent } from '../../shared/grid/controls/text-with-description-renderer/text-with-description-renderer.component';
import { ApprovalDataRendererComponent } from '../../shared/grid/controls/approval-data-renderer/approval-data-renderer.component';
import { InfoStepRendererComponent } from '../../shared/grid/controls/info-step-renderer/info-step-renderer.component';
import { PrivilegeCountRendererComponent } from "src/app/shared/grid/controls/privilege-count-renderer/privilege-count-renderer.component";
import { StatusDataRenderComponent } from "src/app/shared/grid/controls/status-data-renderer/status-data-renderer.component";
import { GridNgSelectOrTextRendererComponent } from "src/app/shared/grid/controls/grid-ng-select-or-text-renderer/grid-ng-select-or-text-renderer.component";
import { GridTextBoxOrTextRendererComponent } from "src/app/shared/grid/controls/grid-textbox-or-text-renderer/grid-textbox-or-text-renderer.component";
import { CheckBoxwithTextRenderComponent } from "src/app/shared/grid/controls/check-box-with-text/check-box-with-text.component";
import { EmployeeStatusPanelComponent } from "src/app/manage-employees/approvers/employee-status-panel/employee-status-panel.component";
import { PercentageRendererComponent } from "src/app/shared/grid/controls/percentage-renderer/percentage-renderer.component";
import  {DiscountRendererComponent } from "src/app/shared/grid/controls/discount-renderer/discount-renderer.component";
import {TextTwoDecimalNumberRendererComponent} from "../../shared/grid/controls/text-two-decimal-number-renderer/text-two-decimal-number-renderer.component"
import { MultiColorTextRendererComponent } from "../../shared/grid/controls/multi-color-text-renderer/multi-color-text-renderer.component";
import { GridTextBoxRendererComponent} from "src/app/shared/grid/controls/grid-textbox-renderer/grid-textbox-renderer.component";
import { ToggleRendererSecondComponent } from '../../shared/grid/controls/toggle-renderer-second/toggle-renderer-second.component';
import {ProgressBarRendererComponent} from "src/app/shared/grid/controls/progress-bar-renderer/progress-bar-renderer.component";
import {CountWithCheckBoxRendererComponent} from "src/app/shared/grid/controls/count-with-checkbox-renderer/count-with-checkbox-renderer.component";
import { NgSelectDescriptionRendrerComponent } from '../../shared/grid/controls/ng-select-description-rendrer/ng-select-description-rendrer.component';
import { ResultRendererComponent } from '../../shared/grid/controls/result-renderer/result-renderer.component';
import {GridDropdownRendererComponent} from "src/app/shared/grid/controls/grid-dropdown-renderer/grid-dropdown-renderer.component";
import { TwoDecimalNumberInputRendererComponent } from "src/app/shared/grid/controls/two-decimal-number-input-renderer/two-decimal-number-input-renderer.component";
import {ActionButtonsRendererComponent} from "src/app/shared/grid/controls/action-buttons-renderer/action-buttons-renderer.component";
import { RateRendererComponent } from '../../shared/grid/controls/rate-renderer/rate-renderer.component';
import {CountLinkRendererComponent} from "src/app/shared/grid/controls/count-link-renderer/count-link-renderer.component";
import {TranslatedTextTendererComponent} from "src/app/shared/grid/controls/translated-text-renderer/translated-text-renderer.component";
import {RadioRendererComponent} from "src/app/shared/grid/controls/radio-renderer/radio-renderer.component";
import {MultipleButtonRendererComponent} from "src/app/shared/grid/controls/multiple-button-renderer/multiple-button-renderer.component";
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import {CustomActionsRendererComponent} from "src/app/shared/grid/controls/custom-actions-renderer/custom-actions-renderer.component";
import { AssignApproverPanelComponent } from 'src/app/manage-employees/approvers/assign-approver-panel/assign-approver-panel.component';
import {GridToggleRendererComponent} from "src/app/shared/grid/controls/grid-toggle-renderer/grid-toggle-renderer.component";
import {MultipleCardNumberRendererComponent} from "src/app/shared/grid/controls/multiple-card-number-renderer/multiple-card-number-renderer.component";
 
import { DateTimeRendererComponent } from 'src/app/shared/grid/controls/date-time-renderer/date-time-renderer.component';

 
@NgModule({
  declarations: [AppGridComponent, CheckBoxRendererComponent,
    StatusRenderComponent, LogIconRendererComponent, ActionsRendererComponent, CustomHeaderComponent,
    ChevronRendererComponent, PreApprovalRendererComponent, FormattedTextRenderer, ApproverRenderer,
    ReceiptIconRendererComponent, FieldValueRendererComponent, DateRendererComponent, AmountRendererComponent,
    PolicyNameRendererComponent, PolicyAmountRendererComponent, PricingTextRendererComponent, SafeTextRendererComponent, StarterMobileSupportIconRendererComponent, StarterWebSupportIconRendererComponent,
    ReceiptsIconRendererComponent,ReceiptsTextRendererComponent,NgSelectRendererComponent, TooltipRenderComponent, ButtonsRendererComponent, ImportErrorsCountRendererComponent,
    CardNumberRendererComponent,
    CardAssignedUsernameRendererComponent,
    TextWithTooltipRendererComponent, TextWithTooltipRightRendererComponent,
    CardAssignedUsernameRendererComponent, ClickToSetupRendererComponent, ToggleRendererComponent,
    TextboxRendererComponent, TextRendererComponent, CheckboxIngridRendererComponent,
    SuspensionMessageRendererComponent, DefaultMessageRendererComponent, TextWithDescriptionRendererComponent,
    ApprovalDataRendererComponent, InfoStepRendererComponent, TextWithTooltipRenderer2Component, PrivilegeCountRendererComponent,
    StatusDataRenderComponent, GridNgSelectOrTextRendererComponent, GridTextBoxOrTextRendererComponent, CheckBoxwithTextRenderComponent,
    EmployeeStatusPanelComponent,
    AssignApproverPanelComponent, PercentageRendererComponent, DiscountRendererComponent, MultiColorTextRendererComponent, TextTwoDecimalNumberRendererComponent,GridTextBoxRendererComponent,
    ToggleRendererSecondComponent,ProgressBarRendererComponent,CountWithCheckBoxRendererComponent,ActionButtonsRendererComponent, RateRendererComponent,GridDropdownRendererComponent,CountLinkRendererComponent,TwoDecimalNumberInputRendererComponent,
    NgSelectDescriptionRendrerComponent, ResultRendererComponent,TranslatedTextTendererComponent,RadioRendererComponent,MultipleButtonRendererComponent,GridToggleRendererComponent, MultipleCardNumberRendererComponent,CustomActionsRendererComponent, DateTimeRendererComponent
  ],
  imports: [
    TranslateModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPopoverModule,
    SharedModule,
    AgGridModule,
  ],
  exports: [AppGridComponent, EmployeeStatusPanelComponent,AssignApproverPanelComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class GridModule { }
