<div class="overLayDiv active"></div>
<div id="rightSideBar">
    <div class="rightSidePanel active" style="z-index: 99999;">
        <header>
            <div class="row">
                <div class="col-lg-12 no-padding-right-left">
                    <div class="row">
                        <div class="col-lg-8 padding-left-0">
                            <h2 class="f-18">{{"advance_passbook" | translate:param}}</h2>
                        </div>

                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 padding-right-0 header-icons text-right">
                          <ul class="d-flex justify-content-end w-100pc">
                              <li (click)="fnClosePanel()" class="padding-right-1">
                                  <Button
                                      class="btn btn-outline-secondary border-color-warning text-mustard height-38px">
                                      {{ 'label_close' | translate: param }}
                                  </Button>
                              </li>
                          </ul>
                      </div>

                    </div>
                </div>
            </div>
        </header>
        <div class="clear"></div>
        <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 margin-top-10 no-padding-right-left">
                <div class="block fw-600">
                    <div class="row">
                        <div class="col-lg-6 padding-left-0">
                            {{"advance_balance_title" | translate:param}}
                        </div>
                        <div class="col-lg-6 padding-right-10 text-right">
                            {{balanceAdvanceAmount | number: "1.2-2" | noComma}} <span
                                class="text-logo-blue ">{{currencyCode}}</span>
                        </div>
                    </div>
                </div>
                <div class="block padding-top-10 scrollbar-visible overflow-auto heightCalc-10">
                    <h6 class="border-bottom padding-bottom-10">{{"text_details" | translate:param}}</h6>
                    <div *ngFor="let item of advanceClaimantList"
                        class="row margin-top-10 f-13 lH-normal border-bottom padding-bottom-20 position-relative">
                        <div *ngIf="item.spentCurrencyId>0" class="margin-top-5 w-100pc">
                            <div class="float-left w-70pc text-left ">
                                <p class="lH-normal">
                                  <span class="fw-700">{{item.details}}</span>
                                  <span class="d-block f-13 text-logo-blue">
                                    {{'text_amount' | translate }}: {{item.spentAmount | number: "1.2-2" | noComma}}
                                    {{item.currencyCode}}
                                  </span>
                                  <span class="d-block f-13">
                                    {{"text_exchange_rate" | translate:param}}: {{item.conversionRate}}
                                  </span>
                                </p>
                              <span *ngIf="item.categoryId == 2" [ngClass]="item.isLinkedToCC ? 'color-green' : 'color-red'">
                                {{ (item.isLinkedToCC ? 'linked_cc_advance' : 'not_linked_cc_advance') | translate }}
                              </span>
                            </div>
                            <div class="float-right text-right w-30pc text-right align-middle ">
                              <small class="d-block">{{item.dateString}}</small>
                              {{item.expenseId > 0 ?item.expenseAmount: item.cashAmount}}
                              <span [ngClass]="{'color-red': item.expenseId>0, 'color-green': item.expenseId==0}">
                                {{(item.expenseId > 0 || item.advanceReturnId > 0) ? 'Dr' : 'Cr'}}
                              </span>
                            </div>
                        </div>
                        <div *ngIf="item.spentCurrencyId==0" class="margin-top-0 w-100pc">
                            <div class="float-left w-70pc text-left ">
                                <p class="lH-normal">
                                  <span class="fw-700">{{item.details}}</span>
                                </p>
                                <span *ngIf="item.categoryId == 2" [ngClass]="item.isLinkedToCC ? 'color-green' : 'color-red'">
                                  {{ (item.isLinkedToCC ? 'linked_cc_advance' : 'not_linked_cc_advance') | translate }}
                                </span>
                            </div>
                            <div class="float-right text-right w-30pc text-right align-middle ">
                              <small class="d-block">{{item.dateString}}</small>
                              {{item.expenseId > 0 ?item.expenseAmount: item.cashAmount}}
                              <span [ngClass]="{'color-red': item.expenseId>0, 'color-green': item.expenseId==0}">
                                {{(item.expenseId > 0 || item.advanceReturnId > 0) ? 'Dr' : 'Cr'}}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
