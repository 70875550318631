<h6 *ngIf="to.attributes && to.attributes.isHeadingRequired">
    <span class="padding-right-10 color-orange">
        <em [ngClass]="to.attributes.headingIcon" aria-hidden="true"></em>
    </span>{{ to.attributes.headingValue }}
</h6>
<div class="input-group margin-top-20">
    <div (click)="clickAction()"
        [ngClass]="{requiredAsterisk : field.templateOptions.required ,'disabled':to.disabled}">
        <ng-select id="{{ 'select' + fieldKey }}" [items]="to.options" bindLabel="value" bindValue="id"
            [placeholder]="field.templateOptions.placeholder" [formControl]="formControl" [searchable]="true"
            [clearable]="false" [selectOnTab]="true" [readonly]="to.attributes  && to.attributes.isReadonly==0"
            notFoundText="{{ 'no_data_available' | translate }}" class="solo-dropdown filterDropdown auditTrailDropdown"
            (change)="onValueChange()">
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div class="custom-dropdown" style="padding: 10px 0px !important;"
                    [ngStyle]="{'opacity': item.isDisabled ? .7 : 1}">
                    <p class="font-weight-bold employee-role-option">{{ item.value }}</p>
                    <p class="employee-role-option">{{ item.description }}</p>
                </div>
            </ng-template>
        </ng-select>

        <div *ngIf="formControl.errors && formControl.errors.required && isFormSubmitted" class="alert-danger">
            <span> <strong>{{ "label_Required" | translate }}</strong></span>
        </div>

        <span *ngIf="field.templateOptions.customErrors && isFormSubmitted">
            <div class="alert-danger" *ngFor="let error of field.templateOptions.customErrors">
                <span><strong>{{error}}</strong></span>
            </div>
        </span>

        <div *ngIf="field.templateOptions.alertNote" class="alert-danger">
            {{ field.templateOptions.alertNote }}
        </div>
    </div>

    <span class="rightTinyText margin-top-10" *ngIf="to.attributes && to.attributes.isTinyTextDisplay==1"
    (click)="onTinyClick()">
        <a>{{to.attributes.tinyText}}</a>
    </span>

</div>