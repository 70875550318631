<h4 class="eod-text-secondary fw-600">{{'step_2_5' | translate}}</h4>
<h1 *ngIf="!isValidOTPEntered"
    class="onboarding-title pt-2">
       {{'verify_your_email' | translate}}
</h1>


<h5 *ngIf="isValidOTPEntered"
    class="fw-600 pt-2 text-success">
       <i *ngIf="isValidOTPEntered"
          class="fas fa-check-circle text-success margin-right-5"></i>
       {{'verified' | translate}}
</h5>
<div class="form onboarding pt-2">
       <div class="d-flex flex-column align-content-between">
              <div>
                     <h5 class="mb-4" *ngIf="!isValidOTPEntered">
                            {{'we_have_sent_email_at'| translate}} <a
                               [href]="'mailto:' + this.userrequestdto.LoginId">{{this.userrequestdto.LoginId}}</a><br>
                            {{'with_a_code_otp' | translate}}
                     </h5>
                     <form>
                            <input [ngClass]="{'otp-verified disabled': isValidOTPEntered, 'otp-failed': !isValidOTPEntered}"
                                   type="text"
                                   numeric
                                   maxlength="1"
                                   [(ngModel)]="otp1"
                                   name="otp1"
                                   class="form-control form-input mr-3 text-center"
                                   [style.background-color]="mybgColorVaraible"
                                   [style.color]="myColorVaraible"
                                   (input)="validateOTP()"
                                   #digitOne
                                   (keyup)="onDigitInput($event)"
                                   style="display: inline-block;
                             width: 50px;
                             height: 50px;"
                                   [disabled]="isValidOTPEntered">
                            <input [ngClass]="{'otp-verified disabled': isValidOTPEntered, 'otp-failed': !isValidOTPEntered}"
                                   type="text"
                                   numeric
                                   maxlength="1"
                                   name="otp2"
                                   [(ngModel)]="otp2"
                                   class="form-control form-input mr-3 text-center"
                                   [style.background-color]="mybgColorVaraible"
                                   [style.color]="myColorVaraible"
                                   (input)="validateOTP()"
                                   #digitTwo
                                   (keyup)="onDigitInput($event)"
                                   style="display: inline-block;
                             width: 50px;
                             height: 50px;"
                                   [disabled]="isValidOTPEntered">
                            <input [ngClass]="{'otp-verified disabled': isValidOTPEntered, 'otp-failed': !isValidOTPEntered}"
                                   type="text"
                                   numeric
                                   maxlength="1"
                                   [(ngModel)]="otp3"
                                   name="otp3"
                                   class="form-control form-input mr-3 text-center"
                                   [style.background-color]="mybgColorVaraible"
                                   [style.color]="myColorVaraible"
                                   (input)="validateOTP()"
                                   #digitThree
                                   (keyup)="onDigitInput($event)"
                                   style="display: inline-block;
                             width: 50px;
                             height: 50px;"
                                   [disabled]="isValidOTPEntered">
                            <input [ngClass]="{'otp-verified disabled': isValidOTPEntered, 'otp-failed': !isValidOTPEntered}"
                                   type="text"
                                   numeric
                                   maxlength="1"
                                   [(ngModel)]="otp4"
                                   name="otp4"
                                   class="form-control form-input text-center"
                                   [style.background-color]="mybgColorVaraible"
                                   [style.color]="myColorVaraible"
                                   (input)="validateOTP()"
                                   #digitFour
                                   (keyup)="onDigitInput($event)"
                                   style="display: inline-block;
                             width: 50px;
                             height: 50px;"
                                   [disabled]="isValidOTPEntered">

                     </form>
                     <h5 *ngIf="isInvalidOTP"
                         class="eod-text-danger mt-2">{{'please_enter_a_valid_code' | translate}}</h5>

                     <div *ngIf="!isValidOTPEntered"
                          class="mt-4">
                            <h5>Click on <a href="javascript:void(0)"
                                      class="eod-text-primary underline"
                                      [className]="getCodeClass"
                                      (click)="showGetCodeInput = true"><u>{{'get_code_otp' | translate}}</u></a>
                                   {{'if_you_dont_have_code_otp' | translate}}</h5>
                            <div class="onboarding onboarding-width mt-4"
                                 *ngIf="showGetCodeInput">
                                   <form class="form">
                                          <div class="form-group mb-0">
                                                 <label class="input-label"
                                                        for="email">{{'label_email' | translate}}<span>*</span></label>
                                                 <input class="form-control form-input hw-100"
                                                        type="email"
                                                        [(ngModel)]="newEmail"
                                                        id="email"
                                                        name="newEmail"
                                                        (input)="isInvalidEmail()" />

                                          </div>
                                          <div class="w-100pc">
                                                 <span class="alert-danger"
                                                       *ngIf="isInvalidNewEmail"><b>{{"invalid_email"|
                                                               translate}}</b></span>
                                          </div>
                                   </form>
                                   <small class="pb-2">{{'update_your_email_to_get_otp' | translate}}</small>


                            </div>
                     </div>
                     <div *ngIf="isValidOTPEntered"
                          class="onboarding-width">
                            <form class="mt-5 position-relative">
                                   <div class="form-group">
                                          <label class="input-label"
                                                 for="password">{{'create_password' | translate}}<span>*</span></label>
                                          <input class="form-control form-input"
                                                 [type]="showPassword1 ? 'text' : 'password'"
                                                 id="password"
                                                 [(ngModel)]="password1"
                                                 name="password1"
                                                 (input)="checkPasswordValidity()"
                                                 NoWhiteSpaces
                                                 #password1Box
                                                 trim="blur" />

                                          <span class="icons right-10 top-40px"><i class="fas color-grey"
                                                    (mousedown)="showPassword1 = true"
                                                    (mouseup)="showPassword1 = false"
                                                    [ngClass]="{
                                                               'fa-eye-slash': !showPassword1,
                                                               'fa-eye': showPassword1
                                                        }"></i></span>
                                   </div>
                                   <div class="password-validation"
                                        *ngIf="isValidOTPEntered && (!passwordLengthMatch || !passwordUppercaseMatch || !passwordLowercaseMatch || !passwordNumberSymbolMatch)">
                                          <p class="pb-1"
                                             [ngClass]="passwordLengthMatch ? 'eod-text-success' : 'eod-text-danger'">
                                                 {{'password_criteria_string1' | translate}} <i
                                                    [ngClass]="passwordLengthMatch ? 'fas fa-check-circle color-green' : 'fas fa-times-circle color-red'"></i>
                                          </p>
                                          <p class="pb-1"
                                             [ngClass]="passwordUppercaseMatch && passwordLowercaseMatch ? 'eod-text-success' : 'eod-text-danger'">
                                                 {{'lowercase_uppercase' | translate}} <i
                                                    [ngClass]="passwordUppercaseMatch && passwordLowercaseMatch ? ' fas fa-check-circle color-green' : 'fas fa-times-circle color-red'"></i>
                                          </p>

                                          <p class="pb-1"
                                             [ngClass]="passwordNumberSymbolMatch ? 'eod-text-success' : 'eod-text-danger'">
                                                 {{'atleast_one_number_or_special_char' | translate}} <i
                                                    [ngClass]="passwordNumberSymbolMatch ? 'fas fa-check-circle color-green' : ' fas fa-times-circle color-red'"></i>
                                          </p>


                                   </div>
                                   <div class="form-group">
                                          <label class="input-label"
                                                 for="confirmPassword">{{'confirm_password' |
                                                 translate}}<span>*</span></label>
                                          <input class="form-control form-input"
                                                 [type]="showPassword2 ? 'text' : 'password'"
                                                 id="confirmPassword"
                                                 [(ngModel)]="password2"
                                                 name="password2"
                                                 NoWhiteSpaces
                                                 (input)="checkPasswordMatch()"
                                                 trim="blur" />

                                          <span class="icons right-10 top-40px"><i class="fas color-grey"
                                                    (mousedown)="showPassword2 = true"
                                                    (mouseup)="showPassword2 = false"
                                                    [ngClass]="{
                     'fa-eye-slash': !showPassword2,
                     'fa-eye': showPassword2
                   }"></i></span>
                                          <small *ngIf="isPasswordf1Valid && !isPasswordMatch"
                                                 class=" f-14 fw-600 lH-30 alert-danger">{{"password_doesnt_match"|
                                                 translate}}</small>
                                   </div>

                            </form>

                     </div>
              </div>
              <div class="mt-auto">
                     <button *ngIf="showGetCodeInput"
                             class="btn eod-btn-secondary btn-lg btn-block onboarding-width"
                             (click)="resendOTP()">{{'label_resend' | translate}}</button>

                     <button *ngIf="isValidOTPEntered  && !showGetCodeInput && passwordLengthMatch && passwordUppercaseMatch && passwordLowercaseMatch && passwordNumberSymbolMatch"
                             class="btn eod-btn-secondary btn-lg btn-block onboarding-width"
                             (click)="RegisterUser()">{{'verify' | translate}}</button>
              </div>
       </div>

</div>