import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';


@Component({
    selector: 'formly-toggle-input-left-aligned',
    templateUrl: "./formly-toggle-input-left-aligned.component.html"
})
export class FormlyToggleInputLeftAlignedComponent extends FieldType implements OnInit, OnDestroy {
    param: any;
    isFormSubmitted: boolean = false;
    formSubmissionSubscription: Subscription;
    fieldKey: string;
    mainClass: string;
    constructor(private dynamicFormService: DynamicFormService) {
        super();
    }

    ngOnInit() {
        if (this.to.options && this.to.options[0] && this.to.options[0].mainClass) {
            this.mainClass = this.to.options[0].mainClass;
        }
        else {
            this.mainClass = "custom-control custom-switch position-relative top--10px margin-left-10";
        }
        if (this.field.key) {
            this.fieldKey = this.field.key.toString();
        }
        this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
            this.isFormSubmitted = data;
        });
    }

    ngOnDestroy() {
        if (this.formSubmissionSubscription) {
            this.formSubmissionSubscription.unsubscribe();
        }
    }

    onValueChange() {
        this.to.change(this.field, this.formControl.value);
    }

}