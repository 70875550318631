
import { NRFCStatusEnum, FunctionPackageEnum, ExpenseStatusEnum, NRCompliantEnum } from "../enum/solo-enum";
import { Claims } from "../common/claims";
import { IApiRequest } from "../common/request-response/api-request";
import { IGetCartDetailsRequest } from "../organisation-setting/organisation-setting";
import { ITaxRateMasterResponse } from "../manage-category/tax-rate-master-response";
import { IOrgVehiclesNRFCResponse, OrgVehiclesNRFCResponse } from "./org-vehicles-nrfcresponse";
import { IVehicleTypes } from "../advance-mileage/vehicle-types";
import { IVehicleTypeSummary } from "./vehicle-type-summary";
import { IEmpListByVehicleResponseDto } from "../mileage-settings/assignvehicleresponse";
import { IAssignVehicleDetails } from "../mileage-settings/mileage-settings";
import { INRClaimantList } from "../NrFc/nrclaimant-list";
import { IFCClaimantList } from "../NrFc/fcclaimant-list";
import { INRReceiptGetList } from "../NrFc/nrreceipt-get-list";
import { IFCReceiptResponse, FCReceiptResponse } from 'src/app/_models/NrFc/fcreceipt-response';
import { INRReceiptResponse, NRReceiptResponse } from "./nrreceipt-response";
import { UntypedFormGroup } from "@angular/forms";
import { INRGetDashboardClaimaintResponse, NRGetDashboardClaimaintResponse } from "./nrget-dashboard-claimaint-response";
import { FlatpickrOptions } from "ng2-flatpickr";
import { INRDashboardReceiptResponse, NRDashboardReceiptResponse } from 'src/app/_models/NrFc/nrdashboard-receipt-response';
import { INRDashboardReceiptModal } from "../NrFc/nrdashboard-receipt-modal";
import { INRGetDashboardClaimaintDetail, NRGetDashboardClaimaintDetail } from "./nrget-dashboard-claimaint-detail";
import { ClaimantListForNRFCApproval, IClaimantListForNRFCApproval } from "./claimant-list-for-nrfcapproval";
import { MarketingPopup } from "../common/marketing-popup";
import { IFCReceiptGetList } from "./fcreceipt-get-list";
import { IFCGetReportResponse, FCGetReportResponse } from 'src/app/_models/NrFc/fcget-report-response';
import { IFCGetReportList } from "./fcget-report-list";
import { FCReportRequest } from "./fcreport-request";
export class NRFCConfigModel {
    orgVehicles = <IVehicleTypes[]>[];
    nrFCStatusEnum = NRFCStatusEnum;
    expenseStatusEnum = ExpenseStatusEnum;
    claims: Claims;
    functionPackageEnum = FunctionPackageEnum;
    getCartDetailsRequest: IApiRequest<IGetCartDetailsRequest>;
    cartList: any;
    isFuelCardInCart: boolean;
    isNonReimbursableFuelInCart: boolean;
    isFuelCardPurchased: boolean;
    isNonReimbursableFuelPurchased: boolean;
    isOwnershipEditing: boolean = false;
    taxRateMasterResponse = <any[]>[];
    isSubmitForm: boolean;
    orgVehiclesNRFCResponse: IOrgVehiclesNRFCResponse = new OrgVehiclesNRFCResponse();
    showLargeImagePopup: boolean;
    isMarketingPopUpDisplay: boolean;
    nrOwnershipCount: number;
    fcOwnershipCount: number;
    noNRFCCount: number;
    vehicleTypeSummary = <IVehicleTypeSummary[]>[];
    searchText: string;
    sortColumn: string;
    noOfRecords: number = 20;
    sortOrder: number;
    pageNumber: number;
    employeeListRes: IEmpListByVehicleResponseDto;
    employeeList = <IAssignVehicleDetails[]>[];
    orgVehicleTypeMapId: number;
    id: number;
    nrfcStatus: number;
    derivedName: string;
    nrClaimantList = <INRClaimantList[]>[];
    nrReceiptGetList = <INRReceiptGetList[]>[];
    fcReceiptGetList = <IFCReceiptGetList[]>[];

    nrReceiptResponse: INRReceiptResponse = new NRReceiptResponse();
    fcReceiptResponse: IFCReceiptResponse = new FCReceiptResponse();
    masterSelected: boolean;
    checkedList: any[];
    isEditingForm: boolean;
    showBulkActions: boolean;
    itemsCounter: number;
    formctrl: UntypedFormGroup;
    isRejectedByFM: boolean;
    user: string;
    nrDashboardResponse: INRGetDashboardClaimaintResponse = new NRGetDashboardClaimaintResponse();
    fcGetReportResponse: IFCGetReportResponse = new FCGetReportResponse();
    nrCompliantEnum = NRCompliantEnum;
    fromDate: any = new Date();
    toDate: any = new Date();
    datePickerOptionsFromDate: FlatpickrOptions = {
        mode: "single",
        defaultDate: new Date(),
        maxDate: new Date(),
        altInput: true,
        altFormat: "d-M-Y",
        dateFormat: "d-M-Y",
    };
    datePickerOptionsToDate: FlatpickrOptions = {
        mode: "single",
        defaultDate: new Date(),
        maxDate: new Date(),
        altInput: true,
        altFormat: "d-M-Y",
        dateFormat: "d-M-Y",
    };
    datePickerOptions: FlatpickrOptions = {
        mode: "single",
        defaultDate: new Date(),
        maxDate: new Date(),
        altInput: true,
        altFormat: "d-M-Y",
        dateFormat: "d-M-Y",
    };
    nrDashboardReceiptResponse: INRDashboardReceiptResponse = new NRDashboardReceiptResponse();
    nrDashboardReceiptList = <INRDashboardReceiptModal[]>[];
    //compliantStatus: string;
    //claimantName: string;
    nrGetDashboardClaimaintDetail: INRGetDashboardClaimaintDetail = new NRGetDashboardClaimaintDetail;
    isCompliant: any;
    isDateFilterApplied: boolean;
    compliantType: number;
    claimantListForNRFCApproval: IClaimantListForNRFCApproval = new ClaimantListForNRFCApproval;
    tooltipMessage: string;
    strExpenseIds: any;
    secondaryFMPopupModel: MarketingPopup;
    returnPath: string;
    fcClaimantList = <IFCClaimantList[]>[];
    fcReportRequest: FCReportRequest;
    ShowExportPanel: boolean;
    AllFilterValuesSelected: boolean = true;
    searchCategoryFilters: string;
    ShowSidePanel: boolean = false;
    ClaimantList: any[];
    FilterText: string;
    ClaimantListActual: any[];
    ClaimantSelected: any[] = [];
    newAttribute1: any = {};
    maxFuelRate: number;
}
