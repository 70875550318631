import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import { IApiresponse } from "src/app/_models/common/request-response/apiresponse";
import { IFunctionPackageCurrencyMappingResponse, } from "src/app/_models/organisation-setting/organisation-setting";
import { IApiRequest, ApiRequest } from "src/app/_models";
import { ClaimsHeaderService } from "src/app/shared/claimsHeader/claims-header.service";
import { map } from "rxjs/operators";
import { setHeader } from "../../_helpers/setRequestHeader";
import { IBillingMultiCurrencySettingResponse } from "src/app/_models/billingMultiCurrencySetting/billingMultiCurrencySetting";
import { FunctionDiscountList } from 'src/app/_models/billing-pricing/FunctionDiscountList';
import { IBillingDataForPricingResponse, IBillingDataForPricingRequest, QuoteModel, IPricingEmailQuoteRequest } from 'src/app/_models/pricing/pricing';


@Injectable({
    providedIn: "root",
})
export class PricingService {

    constructor(
        private http: HttpClient,
        private claimsHeaderService: ClaimsHeaderService,
        private header: setHeader
    ) { }

    GetFunctionPackageCurrencyMapping(currencyId: number) {
        let params = new HttpParams();
        params = params.append("currencyId", currencyId.toString());
        return this.http.get<IApiresponse<IFunctionPackageCurrencyMappingResponse>>(
            `${environment.catalogApiUrl}GetOrgFunctionPackageCurrencyMapping`,
            {
                params: params,
                headers: this.header.authorizationHeader(),
            }
        );
    }

    GetPricingFunctionList(currencyId: number) {
        let params = new HttpParams();
        params = params.append("currencyId", currencyId.toString());
        return this.http.get<any>(
            `${environment.catalogApiUrl}GetOrgPricingFunctionList`,
            {
                params: params,
                headers: this.header.authorizationHeader(),
            }
        );
    }

    GetPricingFunctionListbyCurrencyCode(currencyCode: string) {
        let params = new HttpParams();
        params = params.append("currencyCode", currencyCode);
        return this.http.get<any>(
            `${environment.catalogApiUrl}GetOrgPricingFunctionListByCurrencyCode`,
            {
                params: params,
                headers: this.header.authorizationHeader(),
            }
        );
    }

    GetMultiCurrencySetting(currencyId: number) {
        let params = new HttpParams();
        params = params.append("currencyId", currencyId.toString());
        return this.http.get<IApiresponse<IBillingMultiCurrencySettingResponse>>(
            `${environment.billingApiUrl}GetBillingMultiCurrencySetting`,
            {
                params: params,
                headers: this.header.authorizationHeader(),
            }
        );
    }

    GetSubscriptionDiscountList(): Observable<IApiresponse<FunctionDiscountList[]>> {
        return this.http.get<IApiresponse<FunctionDiscountList[]>>
            (`${environment.billingApiUrl}FunctionDiscountList`,
                {
                    headers: this.header.authorizationHeader()
                });
    }

    GetAllBillingDataForPricing(requestInfo: IApiRequest<IBillingDataForPricingRequest>)
        : Observable<IApiresponse<IBillingDataForPricingResponse>> {
        let body = JSON.stringify(requestInfo);
        return this.http.post<IApiresponse<IBillingDataForPricingResponse>>(
            `${environment.billingApiUrl}GetAllBillingDataForPricing`,
            body,
            { headers: this.header.authorizationHeader() }
        );
    }

    DiscussPricingQuote(
        request: ApiRequest<QuoteModel>
    ) {
        let body = JSON.stringify(request);
        return this.http.post<any>(
            `${environment.catalogApiUrl}DiscussPricingQuote`,
            body,
            {
                headers: this.header.authorizationHeader(),
            }
        );
    }

    EmailPricingQuote(
        request: ApiRequest<IPricingEmailQuoteRequest>
    ) {
        let body = JSON.stringify(request);
        return this.http.post<any>(
            `${environment.catalogApiUrl}EmailPricingQuote`,
            body,
            {
                headers: this.header.authorizationHeader(),
            }
        );
    }

}
