import { Component } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
    selector: 'date-cell',
    templateUrl: './date-renderer.component.html'
})
export class DateRendererComponent implements ICellRendererAngularComp {
    public text: string;

    constructor() {
    }

    initilize(params: ICellRendererParams) {
        this.text = params.getValue();
    }

    // called on init
    agInit(params: any): void {
        this.initilize(params);
    }

    // called when the cell is refreshed
    refresh(params: any): boolean {
        this.initilize(params);
        return true;
    }

}
