import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { QuoteModel } from 'src/app/_models/pricing/pricing';
import { UntypedFormGroup, FormBuilder, Validators } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { FunctionCategoryEnum } from "src/app/_models/enum/solo-enum";

@Component({
  selector: 'pricing-summary',
  templateUrl: './pricing-summary.component.html',
})
export class PricingSummaryComponent implements OnInit , AfterViewInit{

  @Input() selectedFunctionCategory: any[] = [];
  @Input() currencyCode: string;
  @Input() currencySymbol: string;
  @Input() currencyId: number;
  @Input() total: number;
  @Input() accessChargeFunction:any[]= [];
  @Input() essentialPack: number;
  @Input() accessCharge: number;

  @Output() closePanelEvent = new EventEmitter<boolean>();
  @Output() removeFunPackageEvent = new EventEmitter<any>();
  @Output() raisePricingQuoteEvent = new EventEmitter<QuoteModel>();

  quote: QuoteModel = new QuoteModel();
  isFormSubmitted: boolean = false;
  formctrl: UntypedFormGroup;
  functionSelected: boolean = true;

  constructor(private fb: FormBuilder,
    private _decimalPipe: DecimalPipe) { }

  ngOnInit(): void {
    this.quote.emailQuote = false;
   
    this.getSelectedFunctionCategory();
    this.formctrl = this.fb.group({
      firstName: [this.quote.firstName, Validators.required],
      lastName: [this.quote.lastName],
      business: [this.quote.business],
      email: [this.quote.email, Validators.compose([Validators.email, Validators.required])],
      hearAboutUs: [this.quote.hearAboutUs],
      noOfEmployee: [this.quote.noOfEmployee, Validators.pattern(new RegExp("[0-9]"))]
    });
  }

  ngAfterViewInit(){
    
  }
  get f() { return this.formctrl.controls; }

  removeFunPackage(funPackageId: number, functionCategoryId: number) {
    this.selectedFunctionCategory.filter(e => e?.functionCategoryId == functionCategoryId).forEach(fc => {
      fc.functionPackage.filter(func => func.functionPackageId == funPackageId).forEach(funPackage => {
        funPackage.selected = false;
        this.total = this.total - funPackage.functionAmount ?? 0;
      });
      if (!fc.functionPackage.some(fpackage => fpackage.selected)) {
        this.selectedFunctionCategory.filter(e => e?.functionCategoryId == functionCategoryId)[0].selected = false;
      }
    });

    this.getSelectedFunctionCategory();

    this.removeFunPackageEvent.emit({ functionCategoryGroup: this.selectedFunctionCategory, functionPackageId: funPackageId });
  }



  getSelectedFunctionCategory() {
    let selected = false;
    this.selectedFunctionCategory.forEach(x => {
      if (x.functionPackage.some(f => f.selected)) {
        x.selected = true;
        selected = true;
      }
    });

    return selected;
  }

  fnGetSelectedFunctionCost() {
    let amount = 0;
    if (this.selectedFunctionCategory && this.selectedFunctionCategory.length > 0) {
      this.selectedFunctionCategory.filter(x => x.selected).forEach(fn => {
        fn.functionPackage.filter(y => y.selected).forEach(fpackage => {
          
          let fnAmount = this._decimalPipe.transform(fpackage.functionAmount, '1.2-2');
          let decimalVal = +fnAmount;
          amount += decimalVal;
        });
      });
    }

    this.getSelectedFunctionCategory();

    return amount;
  }

  raiseQuote(emailQuote: boolean) {
    this.isFormSubmitted = true;
    if (this.formctrl.status == "VALID") {
      window.document.getElementById("close_summary").click();
      this.mapQuoteModel(emailQuote);
      this.raisePricingQuoteEvent.emit(this.quote);
    }
  }

  mapQuoteModel(emailQuote: boolean) {

    this.quote.firstName = this.f.firstName.value;
    this.quote.lastName = this.f.lastName.value;
    this.quote.business = this.f.business.value;
    this.quote.email = this.f.email.value;
    this.quote.emailQuote = emailQuote;
    this.quote.noOfEmployee = this.f.noOfEmployee.value;
    this.quote.hearAboutUs = this.f.hearAboutUs.value;
  }


}