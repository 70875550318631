import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { OAuthService } from "angular-oauth2-oidc";
import { ToastrService } from "ngx-toastr";
import { ApiRequest } from "src/app/_models";
import { EmailModel } from "src/app/_models/common/EmailModel";
import { Claims } from "src/app/_models/common/claims";
import {
  UserRequestBase,
  Userrequestdto,
} from "src/app/_models/user/userrequestdto";
import { AuthenticationService } from "src/app/_services";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { EmailService } from "src/app/_services/common/email.service";
import { emailValidator } from "src/app/validators/email-validator";
import jwt_decode from "jwt-decode";
import { NgxUiLoaderService } from "ngx-ui-loader";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit, AfterViewInit {
  @Output() gotoNextStepEvent = new EventEmitter<any>();
  @Output() gotoLoginEvent = new EventEmitter<any>();

  registerForm: FormGroup;
  claims: Claims;
  submitted = false;
  IsRegisterCanBeDisabled = false;
  emailAlreadyExists = false;
  userrequest: UserRequestBase;
  referralCode: string = "";
  registrationsubmitted = false;

  constructor(
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private emailService: EmailService,
    private ngxService: NgxUiLoaderService,
    private router: Router
  ) {
    this.userrequest = new UserRequestBase();
    this.registerForm = this.createRegistrationFormGroup();
  }

  xeroSignUp() {
    let token = localStorage.getItem("signupxerotoken");
    const decoded = jwt_decode(token);
    const firstName = decoded["given_name"];
    const lastName = decoded["family_name"];
    const email = decoded["email"];

    this.firstname.setValue(firstName);
    this.lastname.setValue(lastName);
    this.email.setValue(email);
  }

  ngOnInit(): void {
    this.authenticationService.logout();
    this.authenticationService.loadAccessToken();
    if (localStorage.getItem("signupxerotoken")) {
      this.xeroSignUp();
    }
  }

  goToNextStep() {
    this.gotoNextStepEvent.emit(3);
  }

  ShowSpecialCharErrorFName: boolean = true;
  ShowSpecialCharErrorLName: boolean = true;
  omit_special_char(event) {
    var k;
    k = event.charCode;
    if (k == 13) {
      return true;
    }
    if (event.target.id === "fName") {
      this.ShowSpecialCharErrorFName = this.allowAcuteChar(event);
    } else {
      this.ShowSpecialCharErrorLName = this.allowAcuteChar(event);
    }
    return this.allowAcuteChar(event);
  }

  allowAcuteChar(e) {
    let regex = new RegExp("^[a-zA-ZÀ-úp{L} '-]+$");
    let str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    } else return false;
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  createRegistrationFormGroup() {
    return new FormGroup({
      firstname: new FormControl("", [
        Validators.required,
        Validators.minLength(1),
        this.noWhitespaceValidator,
      ]),
      lastname: new FormControl(""),
      email: new FormControl("", [Validators.required, emailValidator]),
    });
  }

  get firstname() {
    return this.registerForm.get("firstname");
  }

  get lastname() {
    return this.registerForm.get("lastname");
  }

  get email() {
    return this.registerForm.get("email");
  }
  domainName: string;

  onRegistrationSubmit() {
    this.claims = new Claims();
    this.submitted = false;
    this.registrationsubmitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid || this.firstname.value.trim() == "") {
      return;
    }
    this.ngxService.start();
    this.IsRegisterCanBeDisabled = true;
    let em = new EmailModel();
    em.to = this.email.value;
    em.templateName = "OnboardingOTP";
    em.userId = 0;
    em.subjct = "OTP";
    em.sentTime = 0;
    em.deliveredTime = 0;
    em.status = 1;
    em.emailLogId = this.newGuid();
    var otp = Math.floor(1000 + Math.random() * 9000);
    em.keyValuePairs["OTP"] = otp.toString();

    var domain = this.email.value;
    this.authenticationService.CheckDomainRegistered(domain).subscribe(
      (domainData) => {
       
        if (domainData.responseInfo.length == 0 && domainData.statusCode == 0) {
          this.authenticationService
            .checkForUserExistance(this.email.value)
            .subscribe(
              (userData) => {
                if (userData.responseInfo != null) {
                  this.toastr.error(this.translate.instant("claimant_exists"));
                  this.IsRegisterCanBeDisabled = false;
                  this.emailAlreadyExists = true;
                } else {
                  let req = new ApiRequest<EmailModel>("", 1, "");
                  req.requestInfo = em;
                  this.emailService.SendEmail(req).subscribe(() => {
                    this.userrequest.RequestInfo = new Userrequestdto();
                    this.userrequest.WebApiName = "SoloRegister";
                    this.userrequest.WebApiVersion = 1;
                    this.userrequest.RequestId = "111";
                    this.userrequest.RequestInfo.FirstName =
                      this.firstname.value;
                    this.userrequest.RequestInfo.LastName = this.lastname.value;
                    this.userrequest.RequestInfo.LoginId = this.email.value;
                    this.userrequest.RequestInfo.ReferralCode =
                      this.referralCode;
                    this.userrequest.RequestInfo.OTP = otp;
                    localStorage.setItem(
                      "registereduser",
                      JSON.stringify(this.userrequest.RequestInfo)
                    );
                    this.ngxService.stop();
                    this.gotoNextStepEvent.emit(3);
                  });
                }
              },
              () => {}
            );
        } else {

          if(domainData.statusCode == 0){
          this.domainName = this.email.value.split("@").pop();;
          document.getElementById("openModalButton").click();
          return;
          }
          else{
            this.toastr.error(this.translate.instant("claimant_exists"));
            this.IsRegisterCanBeDisabled = false;
            this.emailAlreadyExists = true;

          }
        }
      },
      (error) => {}
    );
  }

  newGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  gotoLogin() {
    //this.gotoLoginEvent.emit(1);
    this.router.navigate(["login"]);
  }

  @ViewChild("firstNameField") firstNameField: ElementRef<HTMLInputElement>;

  ngAfterViewInit() {
    this.firstNameField.nativeElement.focus();
  }


  onCloseFoundersNote() {}

  SendInviteeNotificationToFinanceManager(){

    this.userrequest.RequestInfo = new Userrequestdto();
    this.userrequest.WebApiName = "SoloRegister";
    this.userrequest.WebApiVersion = 1;
    this.userrequest.RequestId = "111";
    this.userrequest.RequestInfo.FirstName =
      this.firstname.value;
    this.userrequest.RequestInfo.LastName = this.lastname.value;
    this.userrequest.RequestInfo.LoginId = this.email.value;


    this.authenticationService.SendInviteeNotificationToFinanceManager(this.userrequest).subscribe(
      (domainData) => {


      });

      this.router.navigate(["login"]);

  }
}
