import { ClaimType } from "../enum/solo-enum";
import { CustomMileageRate } from "../manage-category/expense-category";
import { CustomFields } from "../manage-category/manage-category-list-response";
import { CategoryAdditionalFieldData } from "../policy/policy-data";
import { AttendeeList, ExpenseOperationalInfo, IReceiptInfo } from "../twostepapproval/claimant-expense-detail";
import { InvoiceInfo, InvoiceVal } from "./expense-info";

export class SelectExpenseCategoryPopupModel {
    public orgExpenseCategoryId: number;
    public expenseCategoryName: string;
    public baseCategoryId: number;
    public value: string;
    public disableBaseCategoryIds: number[];
    public isPolicyAvailable: boolean;
    public expenseDate: number;
    public isIncompleteCreditCardExpense: boolean;
    public advanceId: number;
    public isTipsEnabled: boolean;
    public customFields: CustomFields[];
    public categoryAdditionalFieldData : CategoryAdditionalFieldData[];
    public isNotesMandatory: boolean;
    public amount: boolean;
    public receiptsInfo: ReceiptsInfo[];
    public claimantId: number;
    public claimantName: string;
    public isFmEditingExpense: string;
    public policyViolationForFM = <number[]>[];
    public isCFPReporting:boolean;
    public nrfcStatus: number;
}

export class CreateExpenseSubmitModel {
    public saveStatus: number;
    public isFmEditingExpense: boolean;
}
export interface IClaimantExpenseListRequest {
    orgId: number;
    userId: number;
    expenseStatus: string;
    sortColumn: string;
    noOfRecords: number;
    pageNo: number;
    sortOrder: number;
    searchText: string;
    skipRecords: number;
}

export class ClaimantExpenseListRequest implements IClaimantExpenseListRequest {
    orgId: number;
    userId: number;
    expenseStatus: string;
    sortColumn: string;
    noOfRecords: number;
    pageNo: number;
    sortOrder: number;
    searchText: string;
    isFirstLoad: boolean;
    skipRecords: number;
    constructor(
        orgId: number,
        userId: number,
        expenseStatus: string,
        sortColumn: string,
        noOfRecords: number,
        pageNo: number,
        sortOrder: number,
        searchText: string,
        isFirstLoad: boolean,
        skipRecords: number
    ) {
        this.orgId = orgId;
        this.userId = userId;
        this.expenseStatus = expenseStatus;
        this.sortColumn = sortColumn;
        this.noOfRecords = noOfRecords;
        this.pageNo = pageNo;
        this.sortOrder = sortOrder;
        this.searchText = searchText;
        this.isFirstLoad = isFirstLoad;
        this.skipRecords = skipRecords;
    }
}

export interface IListExpenseDetail {
    mobileExpenseUniqueId: string;
    expenseId: number;
    orgId: number;
    currencyId: number;
    orgExpenseCategoryId: number;
    baseCategoryId: number;
    expenseCategoryName: string;
    expenseDate: number;
    spentCurrencyId: number;
    amount: number;
    conversionRate: number;
    spentAmount: number;
    expenseStatus: number;
    hasReceipt: boolean;
    hasVoiceMemo: boolean;
    hasTags: boolean;
    notes: string;
    isPolicyViolated: boolean;
    hasDetour: boolean;
    cardDescription: string;
    cardNumber: string;
    cardReferenceNo: string;
    preApprovalId: number;
    taxRateMasterId: number;
    guestTaxId: number;
    tipsTaxId: number;
    employeesTaxId: number;
    isExpenseCategoryActive: boolean;
    submissionDate: number;
    isWorkflowAvailable: boolean;
    paymentTypeId: number;
    updatedOn: number;
    isRowHighlighted: boolean;
    selected: boolean;
    hideDelete: boolean;
    disableDelete: boolean;
    hideRecall: boolean;
    disableRecall: boolean;
    hideEdit: boolean;
    disableEdit: boolean;
    isTooltipDisplay: boolean;
    journeyType: number;
    isCheckboxDisabled: boolean;
    tooltipText: string;
    supplier: string;
    approverAsCA: boolean ;
    invoice : InvoiceInfo;
    exportId: number;
}

export class ListExpenseDetail implements IListExpenseDetail {
    mobileExpenseUniqueId: string;
    expenseId: number;
    orgId: number;
    currencyId: number;
    orgExpenseCategoryId: number;
    baseCategoryId: number;
    expenseCategoryName: string;
    expenseDate: number;
    spentCurrencyId: number;
    amount: number;
    conversionRate: number;
    spentAmount: number;
    expenseStatus: number;
    hasReceipt: boolean;
    hasVoiceMemo: boolean;
    hasTags: boolean;
    notes: string;
    isPolicyViolated: boolean;
    hasDetour: boolean;
    cardDescription: string;
    cardNumber: string;
    cardReferenceNo: string;
    preApprovalId: number;
    taxRateMasterId: number;
    guestTaxId: number;
    tipsTaxId: number;
    employeesTaxId: number;
    isExpenseCategoryActive: boolean;
    submissionDate: number;
    isWorkflowAvailable: boolean;
    paymentTypeId: number;
    updatedOn: number;
    isRowHighlighted: boolean;
    selected: boolean;;
    hideDelete: boolean;
    disableDelete: boolean;
    hideRecall: boolean;
    disableRecall: boolean;
    hideEdit: boolean;
    disableEdit: boolean;
    isTooltipDisplay: boolean;
    journeyType: number;
    isCheckboxDisabled: boolean;
    tooltipText: string;
    supplier: string;
    approverAsCA: boolean = false;
    exportId: number;
    invoice: InvoiceInfo;
}

export interface IGetClaimantExpenseListResponse {
    hasMorePage: boolean;
    expenseList: IListExpenseDetail[];
    totalExpenseCount: number;
    incompleteExpenseCount: number;
    incompleteCreditCardExpenseCount: number;
    rejectedExpenseCount: number;
    awaitingReviewExpenseCount: number;
    awaitingFinanceApprovalExpenseCount: number;
    approvedForPaymentExpenseCount: number;
    passedForPaymentExpenseCount: number;
}

export class GetClaimantExpenseListResponse implements IGetClaimantExpenseListResponse {
    hasMorePage: boolean;
    expenseList: IListExpenseDetail[];
    totalExpenseCount: number;
    incompleteExpenseCount: number;
    incompleteCreditCardExpenseCount: number;
    rejectedExpenseCount: number;
    awaitingReviewExpenseCount: number;
    awaitingFinanceApprovalExpenseCount: number;
    approvedForPaymentExpenseCount: number;
    passedForPaymentExpenseCount: number;
}

export class SelectForignCurrencyPopupModel {
    public expenseDate: number;
    public currencyId: number;
    public currencyCode: string;
    public spentCurrencyId: number;
    public spentCurrencyCode: string;
    public conversionRate: string;
    public baseCurrencyIcon?: boolean = false;    
}

export interface IRecentTagsResponse {
    recentTagList: string[];
}

export class RecentTagsResponse implements IRecentTagsResponse {
    recentTagList: string[];
}
export interface IRecentTagsRequest {
    orgId: number;
    userId: number;
}

export class RecentTagsRequest implements IRecentTagsRequest {
    orgId: number;
    userId: number;
    constructor(
        orgId: number,
        userId: number,

    ) {
        this.orgId = orgId;
        this.userId = userId;
    }
}

export class SelectTagsPopupModel {
    tagList: string[];
    claimantId:  number;
    claimantName: string;
}
export class SelectExpensePolicyModel {
    policyList: string[];
}

export interface IExpenseStatus {
    expenseId: number;
}

export class ExpenseStatus implements IExpenseStatus {
    expenseId: number;
    constructor(
        expenseId: number
    ) {
        this.expenseId = expenseId;
    }
}

export interface IUpdateExpenseRequest {
    orgId: number;
    userId: number;
    action: number;
    expenseInfo: IExpenseStatus[];
    isReceiptNewExpense: boolean;
}

export class UpdateExpenseRequest implements IUpdateExpenseRequest {
    orgId: number;
    userId: number;
    action: number;
    expenseInfo: IExpenseStatus[];
    isReceiptNewExpense: boolean = false;
    constructor(
        orgId: number,
        userId: number,
        action: number,
        expenseInfo: IExpenseStatus[]
    ) {
        this.orgId = orgId;
        this.userId = userId;
        this.action = action;
        this.expenseInfo = expenseInfo;
    }
}


//pre approval
export class SelectPreApprovalTagPopupModel {
    preApprovalId: number;
    preApprovalTag: string;
    preApprovalBalance: string;
    value: string;
    claimantId: number;
    claimantName: string;
}


export interface IPreApprovalBaseRequest {
    organisationId: number;
    userId: number;
}

export class PreApprovalBaseRequest implements IPreApprovalBaseRequest {
    organisationId: number;
    userId: number;
    constructor(
        organisationId: number,
        userId: number,

    ) {
        this.organisationId = organisationId;
        this.userId = userId;
    }
}


export interface IPreApprovalTagListResponse {
    preApprovalTagList: preApprovalTags[];
}

export class PreApprovalTagListResponse implements IPreApprovalTagListResponse {
    preApprovalTagList: preApprovalTags[];
}

export class preApprovalTags {
    preApprovalId: number;
    preApprovalTag: string;
    approvalDate: number;
    balance: string;
    remainingDays: number;
}

//Business Purpose
export class SelectBusinessPurposePopupModel {
    bpId: number;
    bpValue: string;
    value: string;
    businessPurposeCaption: string;
}

export class SelectVehicleModel {
    vehicleId: number;
    derivedName: string;
    registrationNumber: string;
    makeModel: string;
    mileageAllowance: MileageAllowance[];
    claimantId: number;
    claimantName: string;
    docStatus:number;    
}

export class ExpenseDetailsPanelModel {
    expenseId: number;
    expenseCategoryName: string;
    claimantId:  number;
    claimantName: string;
}

export class ExpenseDetailsActionTakenModel {
    expenseId: number;
    actionId: number;
    actionResult: boolean;
    constructor(
        expenseId: number,
        actionId: number,
        actionResult: boolean
    ) {
        this.expenseId = expenseId;
        this.actionId = actionId;
        this.actionResult = actionResult;
    }
}

export interface IVatNoVatReceiptTypeDetails{
    vatNoVatReceiptTypeId:number;
    vatNoVatReceiptTypeName:string;
}

export class VatNoVatReceiptTypeDetails implements IVatNoVatReceiptTypeDetails{
    vatNoVatReceiptTypeId:number;
    vatNoVatReceiptTypeName:string;
}

// Expense Details
export interface IClaimantExpenseDetail {
    mobileExpenseUniqueId: string;
    expenseId: number;
    currencyId: number;
    spentAmount: number;
    paymentTypeId: number;
    noOfEmployee: number;
    noOfGuest: number;
    noOfRooms: number;
    noOfNight: number;
    bpId: number;
    bpValue: string;
    orgExpenseCategoryId: number;
    baseCategoryId: number;
    stateId: number;
    conversionRate: number;
    billAmount: number;
    tips: number;
    amount: number;
    supplier: string;
    notes: string;
    isPolicyViolated: boolean;
    justificationNotes: string;
    hasReceipt: boolean;
    hasTags: boolean;
    hasVoiceMemo: boolean;
    expenseStatus: number;
    expenseDate: number;
    spentCurrencyId: number;
    approverNotes: string;
    workflowCount: number;
    tagList: any;
    mileage: Mileage;
    receiptInfo: any;
    memoInfo: any;
    preApprovalTag: any;
    cardDescription: string;
    cardNumber: string;
    cardReferenceNo: string;
    taxRateMasterId: number;
    guestTaxId: number;
    tipsTaxId: number;
    employeeTaxId: number;
    isExpenseCategoryActive: boolean;
    createdBy: number;
    createdOn: number;
    updatedBy: number;
    updatedOn: number;
    submissionDate: number;
    customerId: number;
    customerName: string;
    isWorkflowAvailable: boolean;
    vatNoVatReceiptTypeDetails: IVatNoVatReceiptTypeDetails;
    additionalFields: any;
    projectBudgetDetailsId: number;
    projectDetailsId: number;
    projectBudgetDetailsName: string;
    projectDetailsName: string;
    entityType: number;
    newCostCentreId: number;
    expenseCategoryId: number;
    newCostCentreName: string;
    costCentreDerivedName: string;
    currentBaseCategoryId: number;
    currentStatus: number;
    customerCode1: string;
    newCostCentreCode1: string;
    attendeeList: AttendeeList;
    isTipsEnabled: boolean;
    customFields: CustomFields[];
    potentialFraud: boolean;
    orgCategoryCustomFields: CustomFields[];
    isNotesMandatory: boolean;
    approverAsCA: boolean;
    categoryAdditionalFieldData: CategoryAdditionalFieldData[];
    receiptNumber:string;
    invoice: InvoiceInfo;
    exportId: number;
    isCFPReporting:boolean;
}

export class ClaimantExpenseDetail implements IClaimantExpenseDetail {
    mobileExpenseUniqueId: string;
    expenseId: number;
    currencyId: number;
    spentAmount: number;
    paymentTypeId: number;
    noOfEmployee: number;
    noOfGuest: number;
    noOfRooms: number;
    noOfNight: number;
    bpId: number;
    bpValue: string;
    orgExpenseCategoryId: number;
    baseCategoryId: number;
    stateId: number;
    conversionRate: number;
    billAmount: number;
    tips: number;
    amount: number;
    supplier: string;
    notes: string;
    isPolicyViolated: boolean;
    justificationNotes: string;
    hasReceipt: boolean;
    hasTags: boolean;
    hasVoiceMemo: boolean;
    expenseStatus: number;
    expenseDate: number;
    spentCurrencyId: number;
    approverNotes: string;
    workflowCount: number;
    tagList: any;
    mileage: any;
    receiptInfo: any;
    memoInfo: any;
    preApprovalTag: any;
    cardDescription: string;
    cardNumber: string;
    cardReferenceNo: string;
    taxRateMasterId: number;
    guestTaxId: number;
    tipsTaxId: number;
    employeeTaxId: number;
    isExpenseCategoryActive: boolean;
    createdBy: number;
    createdOn: number;
    updatedBy: number;
    updatedOn: number;
    submissionDate: number;
    customerId: number;
    customerName: string;
    isWorkflowAvailable: boolean;
    vatNoVatReceiptTypeDetails: IVatNoVatReceiptTypeDetails;
    additionalFields: any;
    projectBudgetDetailsId: number;
    projectDetailsId: number;
    projectBudgetDetailsName: string;
    projectDetailsName: string;
    entityType: number;
    newCostCentreId: number;
    expenseCategoryId: number;
    newCostCentreName: string;
    costCentreDerivedName: string;
    currentBaseCategoryId: number;
    currentStatus: number;
    customerCode1: string;
    newCostCentreCode1: string;
    attendeeList: AttendeeList;
    isTipsEnabled: boolean;
    customFields: CustomFields[];
    orgCategoryCustomFields: CustomFields[];
    isNotesMandatory: boolean;
    potentialFraud: boolean;
    approverAsCA: boolean = false;
    categoryAdditionalFieldData: CategoryAdditionalFieldData[];
    receiptNumber:string;
    invoice: InvoiceInfo;
    exportId: number;
    isCFPReporting: boolean;
}

//States
export class SelectStatesPopupModel {
    stateId: number;
    stateName: string;
}

export class AdditionalFieldsFormModel {
    additionalFieldId: number;
    fieldName: string;
    fieldValueId: number;
    fieldValue: string;
    fieldType: number;
    instruction: string;
}

export class AdditionalFieldsDataSave {
    fieldValueId: number;
    additionalFieldId: number;
    fieldValue: string;
    fieldName: string;
}

export class ExpenseCurrency {
    currencyId: number;
    currencyCode: string;
}

export class SelectCustomerPopupModel {
    customerId: number;
    customerName: string;
}

export class ViewTagsInputModel {
    tagList: [];
}

export class SelectProjectPopupModel {
    entityId: number;
    entityName: string;
    entityType: number;
    projectBudgetDetailsId: number;
    projectDetailsId: number;
    projectName: string;
    isCostCentrePackagePurchasedOrTrial: boolean;
    isCustomerPackagePurchasedOrTrial: boolean;
    isProjectBudgetPurchasedOrTrial: boolean;
    claimantId: number;
    claimantName: string;
}

export class ViewVoiceMemoInputModel {
    memoFileName: string;
    memoUrl: string;
    constructor(
        memoFileName: string,
        memoUrl: string
    ) {
        this.memoFileName = memoFileName;
        this.memoUrl = memoUrl;
    }
}

export class PolicyViolationInputModel {
    expenseId: number;
    expenseCategoryName: string;
    baseCategoryId: number;
    justificationNotes: string;
    disableJustificationNotes: boolean;
    expenseDetailResponse: any;
    constructor(
        expenseId: number,
        expenseCategoryName: string,
        baseCategoryId: number,
        justificationNotes: string,
        disableJustificationNotes: boolean,
        expenseDetailResponse: any
    ) {
        this.expenseId = expenseId;
        this.expenseCategoryName = expenseCategoryName;
        this.baseCategoryId = baseCategoryId;
        this.justificationNotes = justificationNotes;
        this.disableJustificationNotes = disableJustificationNotes;
        this.expenseDetailResponse = expenseDetailResponse;
    }
}

export class CreateExpenseInputModel {
    public title: string;
    public expenseId: number;
    public isEditMode: boolean;
    public expenseCategoryName: string;
    public isCreditCardExpense: boolean;
    public claimantId:  number;
    public claimantName: string;
    public isFmEditingExpense:boolean;
    public isFmRevertingExpense:boolean;
    public originalExpenseData:IClaimantExpenseDetail;
    public createType:number;
    public claimType: ClaimType;
}

export class DismissCreditCardExpenseInputModel {
    expenseId: number;
    claimantId:  number;
    claimantName: string;
}
export class DismissCreditCardExpenseOutputModel {
    expenseId: number;
    saveStatus: number;
}

export class AssociateCreditCardExpenseInputModel {
    orgExpenseCategoryId: number;
    supplier: string;
    expenseId: number;
    expenseCategoryName: string;
    claimantId:  number;
    claimantName: string;
}
export class AssociateCreditCardExpenseOutputModel {
    expenseId: number;
    expenseDate: number;
    supplier: string;
    currencyId: number;
    amount: string;
    currencyCode: string;
}


export interface IRecentJourneysRequest {
    baseCategoryId: number;
    orgId: number;
    userId: number;
}

export class RecentJourneysRequest implements IRecentJourneysRequest {
    baseCategoryId: number;
    orgId: number;
    userId: number;
    constructor(
        baseCategoryId: number,
        orgId: number,
        userId: number

    ) {
        this.baseCategoryId = baseCategoryId;
        this.orgId = orgId;
        this.userId = userId;
    }
}

export interface IRecentJourneysResponse {
    journeyList: any[];
}

export class RecentJourneysResponse implements IRecentJourneysResponse {
    journeyList: any[];
}


export class ViewRecentJourneyInputModel {
    baseCategoryId: number;
}

export interface IMediaSaveResponse {
    expenseIds: any[];
}

export class MediaSaveResponse implements IMediaSaveResponse {
    expenseIds: any[];
}

export interface IExpenseRequest {
    requestData: string;
    receiptFiles: File[];
    content: any;
}

export class ExpenseRequest implements IExpenseRequest {
    requestData: string;
    receiptFiles: File[];
    content: any;
    constructor(
        requestData: string,
        receiptFiles: File[],
        content: any

    ) {
        this.requestData = requestData;
        this.receiptFiles = receiptFiles;
        this.content = content;
    }
}

export class ReceiptSaveRequest {
    expenseIds: any[];
    orgId: number;
    userId: number;
    platformID: number;
    spentCurrencyId: number;
    saveStatus: number;
    content: string;
    hasReceipt: boolean;
    hasVoiceMemo: boolean;
    currencyId: number;
    updatedBy: number;
    createdBy: number;
    updatedOn: number;
    createdOn: number;
    receiptsInfo: ReceiptsInfo[];
    invoice: InvoiceVal;
}

export class ReceiptsInfo {
    isDeletable: boolean;
    receiptName: string;
    receiptDate: number;
    receiptLocationInfo: ReceiptLocationInfo;
    receiptSize: number;
    receiptFormatId: number; //1: Image, 2: PDF
    isEmailedReceipt: boolean;
    action: number; //1: Add, 2: Update (check)
    reciptLocalPath:string;
    expenseReceiptId: number;
    receiptId: number;
    receiptUrl: string | ArrayBuffer | null;
    rotationDegree: number;
    receiptFor:number;
    receiptScanOption:number;
}

export class ReceiptLocationInfo {
    location: string;
    latitude: number;
    longitude: number;
}

export class ExpenseMergeInputModel {
    orgId: number;
    userId: number;
    expenseId: number = 0;
    mergeExpenseIds = <number[]>[];
    receiptInfo: ReceiptsInfo[];
}

export class ExpenseMergePanelOutputModel {
    selectedExpenseIds = <any[]>[];
    tagsList = <any[]>[];
    receiptList = <any[]>[];
    voiceMemoList = <any[]>[];
    notesList = <any[]>[];
}

export interface IGetReceiptExpensesWithAttachmentRequest {
    orgId: number;
    userId: number;
}

export class GetReceiptExpensesWithAttachmentRequest implements IGetReceiptExpensesWithAttachmentRequest {
    orgId: number;
    userId: number;
    expenseId: number;
    constructor(
        orgId: number,
        userId: number,
        expenseId: number
    ) {
        this.orgId = orgId;
        this.userId = userId;
        this.expenseId = expenseId;
    }
}

export class LocationDetail {
    location: string;
    latitude: number;
    longitude: number;
    locationTrackTime: number;
    placeId: number;
}

export class UserDeleteMileageRateInfo {
    userDeletedMileageRateId: number;
    toBeDeleted: boolean;
    miles: string;
    userId: number;
}

export class NRFCDetails {
    nRFCStatus: number;
    nRFuelRate: string;
    nRVatRateValue: number;
    nRVatRateId: number;
    nRFCEffectiveFrom: number;
}

export class ResourceMileageDetailsRequest  {
        constructor(
        public userId: number,
        public orgID: number,
        public fromLocation: LocationDetail,
        public toLocation: LocationDetail,
        public orgExpenseCategoryId: number,
        public action: number
    ) {}
}


export interface IResourceMileageDetailsResponse {
    //orgVehicleTypeMapId: number;
    //vehicleTypeId: number;
    //mileageRate: number;
    //vehicleStatus: number;
    //averageMiles: number;
    //openingBalance: number;
    //mileageRateInfo: MileageMultiRateInfo[];
    userId: number;
    vehicleId: number;
    vehicleSubTypeId: number;
    mileageUOM: number;
    isDetourEnabled: boolean;
    rateType: number;
    isPayActualEnabled: boolean;
    triangulationRule: number;
    nrfcStatus: number;
    nrAvailableDistance: number;
    nrfcEffectiveFrom: number;
    userHomeAddressInfo: UserHomeAddressInfo;
    userOfficeAddressInfo: UserOfficeAddressInfo;
    vehicleDetailResponse: VehicleDetailResponse;
    multipleVehicleCount: number;
    customMileageRate: CustomMileageRate;
    crossOverDetail: CrossOverDetail;
    googleCallsInfo: GoogleCallsInfo;
    nrfcInfo: any;
    isOperationInfoPopupShow: boolean;
    isOtherOfficeAvailable:boolean;
}
export class GoogleCallsInfo {
    isGoogleCallsRestrict: boolean;
    limitChar: number;
    notesForGoogle: string;
}
export class CrossOverDetail {
    firstTierDistance: number;
    secondTierDistance: number;
    totalDistanceTravelled: number;
    totalAmountPaid: number;
    dateOfJourney: number;
}

export class ResourceMileageDetailsResponse implements IResourceMileageDetailsResponse {
    userId: number;
    vehicleId: number;
    vehicleSubTypeId: number;
    mileageUOM: number;
    isDetourEnabled: boolean;
    rateType: number;
    isPayActualEnabled: boolean;
    triangulationRule: number;
    officePostcodeId: number;
    homeAddress: string;
    homeLatitude: number;
    homeLongitude: number;
    homePlaceId: string;
    miles: number;
    isWorkFromHome: boolean;
    nrfcStatus: number;
    nrAvailableDistance: number;
    nrfcEffectiveFrom: number;
    userHomeAddressInfo: UserHomeAddressInfo;
    userOfficeAddressInfo: UserOfficeAddressInfo;
    vehicleDetailResponse: VehicleDetailResponse;
    multipleVehicleCount: number;
    customMileageRate: CustomMileageRate;
    crossOverDetail: CrossOverDetail;
    googleCallsInfo: GoogleCallsInfo;
    nrfcInfo: any;
    isOperationInfoPopupShow: boolean;
    isOtherOfficeAvailable:boolean;
}

export class VehicleDetailResponse {
    orgVehicleTypeMapId: number;
    vehicleTypeId: number;
    vehicleId: number;
    vehicleSubTypeId: number;
    mileageRate: number;
    vehicleStatus: number;
    averageMiles: number;
    openingBalance: number;
    mileageRateInfo: MileageMultiRateInfo[];
    docStatus: number;
    derivedName: string;
    registrationNumber: string;
    makeModel: string;
    mileageAllowance: MileageAllowance[];
}

export interface IVehicleListResponse {
    userVehicleList: IVehicleInfo[];
}

export class VehicleListResponse implements IVehicleListResponse {
    userVehicleList: IVehicleInfo[];
}

export interface IVehicleInfo {
    vehicleId: number;
    derivedName: string;
    registrationNumber: string;
    makeModel: string;
    isDefault: boolean;
    averageMiles: number;
    openingBalance: number;
    vehicleStatus: number;
    isExpenseCreated: boolean;
    vehicleTypeId: number;
    orgVehicleTypeMapId: number;
    vehicleSubType: number;
    vehicleCreationDate: number;
    deleteDate: number;
    milesDone: number;
    docStatus: number;
    mileageRate: number;
    mileageRateInfo: MileageMultiRateInfo[];
    mileageAllowance: MileageAllowance[];
}

export class VehicleInfo implements IVehicleInfo {
    vehicleId: number;
    derivedName: string;
    registrationNumber: string;
    makeModel: string;
    isDefault: boolean;
    averageMiles: number;
    openingBalance: number;
    vehicleStatus: number;
    isExpenseCreated: boolean;
    vehicleTypeId: number;
    orgVehicleTypeMapId: number;
    vehicleSubType: number;
    vehicleCreationDate: number;
    deleteDate: number;
    milesDone: number;
    docStatus: number;
    mileageRate: number;
    mileageRateInfo: MileageMultiRateInfo[];
    mileageAllowance: MileageAllowance[];
}

export class MileageMultiRateInfo {
    mileageRateSetupId: number;
    rangeFrom: number;
    rangeTo: number;
    mileageRate: number;
    milesTravelled: number;
    amount: number;
    vatRate: number;
    vatRateId: number;
    vatRateAmount: number;
}
export class LocationCommonDetail {
    address: string;
    latitude: number;
    longitude: number;
    placeId: string;
}
export class UserHomeAddressInfo extends LocationCommonDetail {
    miles: number;
    isWorkFromHome: boolean;
}
export class UserOfficeAddressInfo extends LocationCommonDetail {
    officePostcodeId: number;
    officeName: string;
}





export class MileageForSaveExpense {
    mileageDetails: Mileage;
    amount: number;
    tags: string[];
}
export class Mileage {
    fromLocation: LocationDetail;
    toLocation: LocationDetail;
    route: LocationDetail[];
    returnRoute: LocationDetail[];
    vehicleId: number;
    distance: number;
    returnDistance: number;
    totalDistance: number;
    mileageUOM: number;
    hasReturnJourney: boolean;
    mileageRate: number;
    detour: number;
    detourJustificationNotes: string;
    triangulatedDistance: number;
    returnTriangulatedDistance: number;
    totalTriangulatedDistance: number;
    milesTraveled: number;
    returnMilesTraveled: number;
    totalMilesTraveled: number;
    isMultiRange: boolean;
    journeyType: number;
    mileageDate: number;
    derivedName: string;
    registrationNumber: string;
    makeModel: string;
    mileageRateInfo: MileageMultiRateInfo[];
    operationalInformation: ExpenseOperationalInfo[];
    //sourceDestinationResponse: SourceDestinationResponse[];
    sourceDestinationInformation: SourceDestinationResponse[];
    passengerLoadAmount: number;
    carbonFootprint:number;
}

export class SelectDetourInputModel {
    detour: number;
    detourJustificationNotes: string;
}

export class SelectDetourOutputModel {
    detour: number;
    detourJustificationNotes: string;
}


export interface IGetReceiptExpensesWithAttachmentResponse {
    expenseList: ReceiptExpenseDetails[]
}

export class GetReceiptExpensesWithAttachmentResponse implements IGetReceiptExpensesWithAttachmentResponse {
    expenseList: ReceiptExpenseDetails[]
}

export class MemoInfo {
    name: string;
    url: string;
}

export class ReceiptExpenseDetails {
    expenseId: number;
    orgId: number;
    expenseDate: number;
    receiptInfo: ReceiptsInfo[];
    mobileExpenseUniqueId: string;
    currencyId: number;
    spentAmount: number;
    paymentTypeId: number;
    noOfEmployee: number;
    noOfGuest: number;
    noOfRooms: number;
    noOfNight: number;
    orgExpenseCategoryId: number;
    baseCategoryId: number;
    stateId: number;
    conversionRate: string;
    supplier: string;
    amount: string;
    notes: string;
    isPolicyViolated: boolean;
    justificationNotes: string;
    hasReceipt: boolean;
    hasTags: boolean;
    hasVoiceMemo: boolean;
    expenseStatus: number;
    approverNotes: string;
    workflowCount: number;
    tagList: string[];
    mileage: any;
    memoInfo: MemoInfo;
    preApprovalTag: any;
    cardDescription: string;
    cardNumber: string;
    cardReferenceNo: string;
    taxRateMasterId: number;
}

export class SelectRecentJourneyInputModel {
    baseCategoryId: number;
    claimantId:  number;
    claimantName: string;
}
export class SelectRecentJourneyOutputModel {
    fromLocation: LocationDetail;
    toLocation: LocationDetail;
    totalDistance: number;
}

export class SelectCostCentrePopupModel {
    costCentreId: number;
    costCentreName: string;
    isSelectionHide: boolean;
}

export class SelectReceiptTypePopupModel {
    vatNoVatReceiptTypeId: number;
    options: SelectReceiptTypeOutputModel[];
}

export class SelectReceiptTypeOutputModel {
    vatNoVatReceiptTypeId: number;
    vatNoVatReceiptType: string;
}

export class ViewExpensePolicyInputModel {
    policyList: [{ "yearly", "Monthly", "Daily" }];
}

export class MileageData {
    mileageDate: number;
    journeyType: number;
    vehicleId: number;
    customMileageRate: CustomMileageRate
    sourceDestinationInformation: SourceDestinationRequest[];
    operationalInformation: ExpenseOperationalInfo[];
}
export class SourceDestinationRequest {
    sequence: number;
    detour: number;
    distance: number;
    carbonFootprint:number;
    detourJustificationNotes: string;
    notes: string;
    route: LocationDetail[];
    fromLocation: LocationDetail;
    toLocation: LocationDetail;
    tagList: string[];
    mileageAllowance: MileageAllowance;
    totalTriangulatedDistanceText: string;
    isOtherOfficeAvailable:boolean;
}
export class MileageAllowance {
    mileageAllowanceId: number;
    allowanceRate: number;
    passengerCount: number;
    loadCount: number;
    allowanceDescription: string;
    allowanceIcon: string;
    allowanceId: number;
    selected: boolean;
}
export class CalculateMileageRequest {
    userId: number;
    orgID: number;
    baseCategoryId: number;
    orgExpenseCategoryId:number;
    orgVehicleTypeMapId: number;
    vehicleId: number;
    vehicleTypeId: number;
    journeyType: number;
    mileageDateDetails: MileageData[];
}

export class CalculateMileageResponse {
    userId: number;
    totalDistanceOfMultiple: number;
    totalCarbonFootprint:number;
    mileageUOM: number;
    journeyType: number;
    mileageDetailList: MileageDetailList[];
    nRFCDetails: NRFCDetails;
}

export class MileageDetailList {
    mileageDate: number;
    detour: number;
    amount: number;
    distance: number;    
    returnDistance: number;
    totalDistance: number;
    triangulatedDistance: number;
    returnTriangulatedDistance: number;
    totalTriangulatedDistance: number;
    totalTriangulatedAmount: number;
    detourAmount: number;
    milesTraveled: number;
    returnMilesTraveled: number;
    totalMilesTraveled: number;
    mileageRate: number;
    isMultiRange: boolean;
    vatRate: number;
    vatRateId: number;
    triangulationCall: number;
    totalNumberOfCall: number;
    docStatus: number
    mileageRateInfo: MileageMultiRateInfo[];
    userDeleteMileageRateInfoList: UserDeleteMileageRateInfo[];
    googleCallUsed: number;
    homeCountIfTakeDiffRule: number;
    addDetour: boolean;
    appliedTriangulationRule: number;
    officeToCustomerDistance: number;
    sourceDestinationInformation: SourceDestinationResponse[];
    passengerLoadAmount: number;
    totalTriangulatedDistanceText: string;
}

export class MileageMultipleDateDetails extends MileageDetailList {
    tags: string[];
    detourJustificationNotes: string;
    isDeleted: boolean;
    mileageDateUniqueId: number;
}

export class SourceDestinationResponse extends MileageAllowance {
    detour: number;
    distance: number;
    totalDistance: number;
    carbonFootprint:number;
    triangulatedDistance: number;
    officeToCustomerDistance: number;
    milesTraveled: number;
    homeCountIfTakeDiffRule: number;
    appliedTriangulationRule: number;
    sequence: number;
    detourJustificationNotes: string;
    notes: string;
    fromLocation: LocationDetail;
    toLocation: LocationDetail;
    route: LocationDetail[];
    userDeleteMileageRateInfoList: UserDeleteMileageRateInfo[];
    tagList: string[];
    mileageAllowance: MileageAllowance;
    totalTriangulatedDistanceText: string;
}

export class OrganisationRulesResponse {
    orgId: number;
    isValidateCustomerForAttendeeType: boolean;
    isValidateCostCentreForAttendeeType: boolean;
}

export class NRDetailRequest {
    effectiveFrom: number
    expenseDate: number
}

export class MultipleLegsAddJourneyFormModel extends SourceDestinationRequest{
    sequence: number;
    isEditMode: boolean = false;
    fromLocationDescription: string;
    toLocationDescription: string;
    isFromLocationDisabled : boolean= false;
    allowanceDescription: string;
    userHomeAddressInfo: UserHomeAddressInfo;
    userOfficeAddressInfo: UserOfficeAddressInfo;
    triangulationRule: number;
    disabledCalculateBtn: boolean;
    googleCallsInfo: GoogleCallsInfo;
}

export class MultipleLegsPopupModel{
    multipleLegList: SourceDestinationResponse[];
    calculateMileageDetails: CalculateMileageResponse;
    claimantId: number;
    claimantName: string;
    isAdvanceMileagePurchasedOrTrial: boolean;
    isDetourEnabled: boolean;
    isPassengerLoadPurchased: boolean;
    mileageUOM: number;
    isMultipleVehiclePurchase: boolean = false;
    isEditMode: boolean = false;
    selectedSequence: number;
    isReturnJourney: boolean;
    claimantHomeCurrencyCode: string;
    baseCategoryId:number;
    
}

export class MultipleLegsDetailsModel{
    multipleLegList: SourceDestinationResponse[];
    calculateMileageDetails: CalculateMileageResponse;
    totalLegsDistance: number;
    amount: string;
    tags:string[];
}

export class PreviewOutputModel
{
    date : string;
    amount : string;
    supplier:string;
    receiptNumber:string;
    receipt : ReceiptsInfo;
}
export class AmendedExpenseDetailsPanelInputModel {
    expenseId: number;
}


export interface IClaimantAmendedExpenseDetailsRequest {
    expenseId: number;
}

export class ReceiptOutputModel{
    previewModel: PreviewOutputModel;
    receiptModel: IReceiptInfo[];
}
export class ClaimantAmendedExpenseDetailsRequest implements IClaimantAmendedExpenseDetailsRequest {
    expenseId: number;
    constructor(
        expenseId: number

    ) {
        this.expenseId = expenseId;
    }
}

export interface IClaimantAmendedExpenseDetailsRes {
    amendedExpHistory: AmendedExpHistory[];
    originalExpenseData:IClaimantExpenseDetail;
    updatedDate: string;
    updatedBy: string;
}

export class ClaimantAmendedExpenseDetailsRes implements IClaimantAmendedExpenseDetailsRes {
    amendedExpHistory: AmendedExpHistory[];
    originalExpenseData:IClaimantExpenseDetail;
    updatedDate: string;
    updatedBy: string;
}

export class AmendedExpHistory {
    fieldId: number;
    fieldName: string;
    originalValue: string;
    amendedValue: string;
}

export class DeleteReceiptShortRequest
{
    receiptIds: number [];
    isSoftDelete: boolean;
}

export class DeleteReceiptResponse
{
    actionResponse: number;
    receiptId: number;
}

export class DeleteReceiptResponseList
{
    DeleteReceiptResponse: DeleteReceiptResponse[];
}

export class NRReceiptSaveRequest {
    nrfcEffectiveFrom: number;
    amount: number;
    currencyId: number;
    hasReceipt: boolean;
    mobileNRUniqueId: string;
    Notes: string;
    nrCurrentStatus: number;
    nrFuelDate: number;
    nrReceiptId: number;
    orgId: number;
    paymentTypeId: number;
    spentCurrencyID: number;
    submissionDate: number;
    supplier: string;
    userId: number;
    nrfcReceiptInfo: ReceiptsInfo[];
}

export class NRFCReceiptResponse {
    nrFCReceiptId:number;
    fcInfoStatus:number;
    CurrentStatus:number;
    ResponseString:string;
    NRFCReceiptStatuses: NRFCReceiptStatus[];

}

export class NRFCReceiptStatus {
    nrFCReceiptId:number;
    Status:number;
    FCInfoStatus:number;
    Error:string;

}

export class FCReceiptRequest {
    fcReceiptId: number;
    orgId: number;
    userId: number;
    vehicleId: string;
    openingBalance: number;
    openingBalanceDate: number;
    closingBalance: number;
    closingBalanceDate: number;
    distanceAvailable: number;
    distanceTravelled: number;
    businessDistance: number;
    privateDistance: number;
    notes: string;
    fcCurrentStatus: number;
    receiptDate: number;
    hasReceipt: boolean;
    isClosingBalUpdateRequired: boolean;
    nrfcEffectiveFrom: number;
    nrfcReceiptInfo: ReceiptsInfo[];
    submissionDate:number;
    action:number;
    isVehicleChanged:number;
    derivedName:number;
    registrationNumber:string;
    makeModel:string;
    vehicleTypeId:number;
    userVehicleSetupId:number
}

export class CreditCardExpenseInfoRes{
    currency : any;
    exchangeRate: any;
    localCurrencyAmount: any;
    notes1: any;
    notes2: any;
    notes3: any;
    notes4: any;
    notes5: any;
  }

  export class UserCustomSetting {
    userCustomSettingId: number;
    sectionId: number;
    sequence: number;
    userId: number;
    type: number;
}

  export interface ISaveUserCustomSettingRequest {
    type: number;
    userId: number;
    userCustomSettings: UserCustomSetting[];
}

export class SaveUserCustomSettingRequest implements ISaveUserCustomSettingRequest {
    type: number;
    userId: number;
    userCustomSettings: UserCustomSetting[];
    constructor(
        type: number,
        userId: number,
        userCustomSettings: UserCustomSetting[]
    ) {
        this.type = type;
        this.userId = userId;
        this.userCustomSettings = userCustomSettings;
    }
}


export class HistoricalJustificationInputModel{
   approverNotes: any;
}

export class CreditCardExpenseInfoInputModel{
    expenseId: number;
 }

 
export class ExpensePolicyInputModel{
    expenseId: number;
    currencyCode: string;
    isReceiptNumberSettingsEnabled: boolean;
    claimType: any;
    expenseDetails: any;
    pageType: any;
 }
