<span>
  <svg style="width: 0; height: 0; position: absolute"
       aria-hidden="true"
       focusable="false">
    <linearGradient id="blue-greens">
      <stop offset="0%"
            class="blue-green-stop-1" />
      <stop offset="50%"
            class="blue-green-stop-2" />
      <stop offset="100%"
            class="blue-green-stop-3" />
    </linearGradient>
    <radialGradient id="blue-greens-radial">
      <stop offset="0%"
            class="blue-green-stop-1" />
      <stop offset="50%"
            class="blue-green-stop-2" />
      <stop offset="100%"
            class="blue-green-stop-3" />
    </radialGradient>
    <linearGradient id="red-pink-amber"
                    x2="0"
                    y2="1">
      <stop offset="0%"
            class="red-pink-amber-1" />
      <stop offset="50%"
            class="red-pink-amber-2" />
      <stop offset="100%"
            class="red-pink-amber-3" />
    </linearGradient>
    <radialGradient id="red-pink-amber-radial"
                    x2="0"
                    y2="1">
      <stop offset="0%"
            class="red-pink-amber-1" />
      <stop offset="50%"
            class="red-pink-amber-2" />
      <stop offset="100%"
            class="red-pink-amber-3" />
    </radialGradient>
    <linearGradient id="blue-green-yellow"
                    x2="0"
                    y2="1">
      <stop offset="0%"
            class="blue-green-yellow-1" />
      <stop offset="100%"
            class="blue-green-yellow-2" />
    </linearGradient>
    <radialGradient id="blue-green-yellow-radial"
                    x2="0"
                    y2="1">
      <stop offset="0%"
            class="blue-green-yellow-1" />
      <stop offset="100%"
            class="blue-green-yellow-2" />
    </radialGradient>
    <linearGradient id="red-magenta-purple"
                    x2="0"
                    y2="1">
      <stop offset="0%"
            class="red-magenta-purple-1" />
      <stop offset="100%"
            class="red-magenta-purple-2" />
    </linearGradient>
    <radialGradient id="red-magenta-purple-radial"
                    x2="0"
                    y2="1">
      <stop offset="0%"
            class="red-magenta-purple-1" />
      <stop offset="100%"
            class="red-magenta-purple-2" />
    </radialGradient>
    <linearGradient id="black-blue-red"
                    x2="0"
                    y2="1">
      <stop offset="0%"
            class="black-blue-red-1" />
      <stop offset="50%"
            class="black-blue-red-2" />
      <stop offset="100%"
            class="black-blue-red-3" />
    </linearGradient>
    <radialGradient id="black-blue-red-radial"
                    x2="0"
                    y2="1">
      <stop offset="0%"
            class="black-blue-red-1" />
      <stop offset="50%"
            class="black-blue-red-2" />
      <stop offset="100%"
            class="black-blue-red-3" />
    </radialGradient>
    <linearGradient x1="0"
                    y1="0"
                    x2="100%"
                    y2="100%"
                    id="gradient">
      <stop class="gradient__brand-secondary"
            offset="0" />
      <stop class="gradient__brand"
            offset="50%" />
      <stop class="gradient__brand"
            offset="100%" />
    </linearGradient>
  </svg>
</span>

<div class="dashboard_body container-fluid padding-top-0 eod-bg-grey-04 scrollbar overflow-auto">
  <div id="dashboard"
       class="margin-top-0">
    <div class="row">
      <div class="col-md-12 d-flex justify-content-center position-relative "
           id="itemsForApproval">
        <div [ngClass]="fnActionBackGroundClass()">
          <button class="btn eod-btn-primary btn-sm mx-1"
                  *ngIf="isDataLoadComplete && fmAsApproverSummary && fmAsApproverSummary.length > 0"
                  (click)="showOrphanspop(true)"> {{ "items_for_approval" | translate: param }}
            <span class="eod-btn-badge eod-bg-danger">{{this.approvalSummary.totalCountOfItemForApproval }} </span>
          </button>

          <button class="btn eod-btn-primary btn-sm mx-1"
                  *ngIf="isFinanceApprovalAccess && financeApprovalPendingCount"
                  (click)="changeUrl('financeapprover/faexpenses/1')"> {{ "items_for_finance_approval" | translate:
            param }}
            <span class="eod-btn-badge eod-bg-danger">{{financeApprovalPendingCount}}</span>
          </button>

          <button class="btn eod-btn-primary btn-sm mx-1"
                  *ngIf="isPayAccess && financePayPendingCount"
                  (click)="changeUrl('pay/payexpenses/1')"> {{ "items_for_payment" | translate: param }}
            <span class="eod-btn-badge eod-bg-danger">{{financePayPendingCount}}</span>
          </button>

          <button class="btn eod-btn-primary btn-sm mx-1"
                  *ngIf="isCardAccess && this.approvalSummary.dismissedExpensesCount > 0"
                  (click)="fnShowCardDashboard()"> {{ "dismissed_credit_card_items" | translate: param }}
            <span class="eod-btn-badge eod-bg-danger">{{this.approvalSummary.dismissedExpensesCount}}</span>
          </button>

          <button class="btn eod-btn-primary btn-sm mx-1"
                  *ngIf="isAdvancedMileageAccess && isMileagePurchased && this.approvalSummary.vehicleApprovalCount > 0 "
                  (click)="goProducts()"> {{ "vehicles_for_approval" | translate: param }}
            <span class="eod-btn-badge eod-bg-danger">{{this.approvalSummary.vehicleApprovalCount}}</span>
          </button>

          <button class="btn eod-btn-primary btn-sm mx-1"
                  *ngIf="isDutyofCareAccess && this.approvalSummary.pendingDutyofCareApprovalCount > 0 "
                  (click)="changeUrl('dutyofcare/dutyofapproval')"> {{ "items_for_duty_of_care_approval" | translate:
            param }}
            <span class="eod-btn-badge eod-bg-danger">{{this.approvalSummary.pendingDutyofCareApprovalCount}}</span>
          </button>
          <button class="btn eod-btn-primary btn-sm mx-1"
                  *ngIf="isVendorAccess && this.approvalSummary.pendingKYCApprovalCount > 0"
                  (click)="changeUrl('vendorSetup/approveDoc')"> {{ "items_for_kyc_approval" | translate: param }}
            <span class="eod-btn-badge eod-bg-danger">{{ this.approvalSummary.pendingKYCApprovalCount }}</span>
          </button>



          <div class="waves wave-1"></div>
          <div class="waves wave-2"></div>
          <div class="waves wave-3"></div>
        </div>
      </div>
      <div class="col-md-12 px-2">
        <div class="row"
             id="overview-expenseStatus">
          <div class="col-md-2 px-2">
            <div class="dashboard-widgets-sm">
              <div class="d-flex justify-content-between align-items-start">
                <p class="widget-p-head">{{"incomplete_claims" | translate: param}}</p>
                <button *ngIf="isLiabilityReportAccess"
                        (click)="showLiabilityReport()"
                        class="link eod-text-primary bg-transparent">{{"view" | translate: param }}</button>
              </div>
              <div class="d-flex justify-content-between">
                <div>
                  <h6>{{this.incompleteExpenses.claimants}}</h6>
                  <p class="eod-text-grey-02">{{ "claimants" | translate: param }}</p>
                </div>
                <div class="text-right">
                  <h6>{{ this.incompleteExpenses.count }}</h6>
                  <p class="eod-text-grey-02">{{ "item_count" | translate: param}}</p>
                </div>
              </div>
            </div>
            <div class="widgets-b-border eod-bg-warning"></div>
          </div>
          <div class="col-md-2 px-2">
            <div class="dashboard-widgets-sm"
                 *ngIf="isTwoStepPurchased">
              <div class="d-flex justify-content-between align-items-start">
                <p class="widget-p-head">{{"pending_with_approver" | translate: param}}</p>
                <button class="link eod-text-primary bg-transparent"
                        (click)="fnGetDashboardPresetData(expenseStatusEnum.PendingWithApprover,this.pendingExpensesWithApprover.count)">{{
                  "view" | translate: param }}</button>
              </div>
              <div class="d-flex justify-content-between">
                <div>
                  <h6>{{this.pendingExpensesWithApprover.claimants}}</h6>
                  <p class="eod-text-grey-02">{{ "claimants" | translate: param }}</p>
                </div>
                <!-- <div *ngIf="!isMultipleCountryPackagePurchasedOrTrial">
                  <h6 class="text-right">{{this.pendingExpensesWithApprover.amount | number: ".2" | noComma}} <span
                          class="eod-text-primary">{{ this.organisationCurrency }}</span></h6>
                  <p class="eod-text-grey-02 text-right">{{"label_amount" | translate: param}}</p>
                </div> -->
                <div class="text-right">
                  <h6>{{ this.pendingExpensesWithApprover.count }}</h6>
                  <p class="eod-text-grey-02">{{"item_count" | translate: param}}</p>
                </div>
              </div>
            </div>
            <div class="dashboard-widgets-sm"
                 *ngIf="!isTwoStepPurchased"
                 [ngbPopover]="popContent"
                 triggers="mouseenter:mouseleave"
                 placement='right'>
              <div class="d-flex justify-content-between">
                <p class="widget-p-head">{{"pending_with_approver" | translate: param}}</p>
                <button class="link eod-text-primary bg-transparent"
                        (click)="showTwoStepPopup()">{{ "view" | translate:
                  param }}</button>
              </div>

              <div class="d-flex justify-content-between">
                <div>
                  <h6>{{this.pendingExpensesWithApprover.claimants}}</h6>
                  <p class="eod-text-grey-02">{{ "claimants" | translate: param }}</p>
                </div>
                <!-- <div>
                  <h6 class="text-right">{{ this.pendingExpensesWithApprover.amount | number: ".2" | noComma }} <span
                          class="eod-text-primary">{{ this.organisationCurrency }}</span></h6>
                  <p class="eod-text-grey-02 text-right">{{ "label_amount" | translate: param }}</p>
                </div> -->
                <div class="text-right">
                  <h6>{{ this.pendingExpensesWithApprover.count }}</h6>
                  <p class="eod-text-grey-02">{{"item_count" | translate: param}}</p>
                </div>
              </div>
            </div>
            <div class="widgets-b-border eod-bg-warning"></div>
            <ng-template #popContent>
              <div>{{'after_approval' | translate:param}}</div>
            </ng-template>
          </div>
          <div class="col-md-2 px-2"
               [ngClass]="{'opacity-50 cursor-pointer': !this.isTwoStepPurchased }"
               (click)="showTwoStepPopup()">
            <div class="dashboard-widgets-sm">
              <div class="d-flex justify-content-between  align-items-start">
                <p class="widget-p-head">{{ "pending_finance_approval" | translate: param }}</p>
                <button class="link eod-text-primary bg-transparent"
                        [ngClass]="{disabled: !this.isTwoStepPurchased}"
                        (click)="fnGetDashboardPresetData(expenseStatusEnum.Approved, this.pendingExpensewithFA.count)">{{
                  "view" | translate: param }}</button>
              </div>
              <div class="d-flex justify-content-between">
                <div>
                  <h6>{{ this.pendingExpensewithFA.claimants }}</h6>
                  <p class="eod-text-grey-02">{{ "claimants" | translate: param }}</p>
                </div>
                <!-- <div *ngIf="!isMultipleCountryPackagePurchasedOrTrial">
                  <h6 class="text-right">{{ this.pendingExpensewithFA.amount | number: ".2" | noComma }} <span
                          class="eod-text-primary">{{ this.organisationCurrency }}</span></h6>
                  <p class="eod-text-grey-02 text-right">{{ "label_amount" | translate: param }}</p>
                </div> -->
                <div class="text-right">
                  <h6>{{ this.pendingExpensewithFA.count }}</h6>
                  <p class="eod-text-grey-02">{{ "item_count" | translate: param }}</p>
                </div>
              </div>
            </div>
            <div class="widgets-b-border eod-bg-warning"></div>
          </div>
          <div class="col-md-2 px-2"
               [ngClass]="{'opacity-50 cursor-pointer': !this.isTwoStepPurchased }"
               (click)="showTwoStepPopup()">
            <div class="dashboard-widgets-sm">
              <div class="d-flex justify-content-between align-items-start">
                <p class="widget-p-head">{{ "pending_passed_for_payment" | translate: param }}</p>
                <button class="link eod-text-primary bg-transparent"
                        [ngClass]="{ disabled: !this.isTwoStepPurchased }"
                        (click)=" fnGetDashboardPresetData( expenseStatusEnum.ApprovedForPayment, this.pendingPassedForPayment.count)">{{
                  "view" | translate: param }}</button>
              </div>
              <div class="d-flex justify-content-center">
                <button class="btn eod-btn-warning btn-sm" *ngIf="this.actionableRecordForDashboard && this.isTwoStepPurchased && this.isTwoStepApprovalTurnOn" 
                  (click)="fnShowSetupSkipPayStepPanel()">
                  <span *ngIf="!isPayStepEnabled"> {{ "enable_this_step" | translate }} </span>
                  <span *ngIf="isPayStepEnabled"> {{ "disable_this_step" | translate }} </span>
                </button>
              </div>

              <div class="d-flex justify-content-between">
                <div>
                  <h6>{{this.pendingPassedForPayment.claimants}}</h6>
                  <p class="eod-text-grey-02">{{ "claimants" | translate: param }}</p>
                </div>
                <!-- <div>
                  <h6 class="text-right">{{ this.pendingPassedForPayment.amount | number: ".2" | noComma }} <span
                          class="eod-text-primary">{{ this.organisationCurrency }}</span></h6>
                  <p class="eod-text-grey-02 text-right">{{"label_amount" | translate: param }}</p>
                </div> -->
                <div class="text-right">
                  <h6>{{ this.pendingPassedForPayment.count }}</h6>
                  <p class="eod-text-grey-02">{{ "item_count" | translate: param }}</p>
                </div>
              </div>
            </div>
            <div class="widgets-b-border eod-bg-warning"></div>
          </div>
          <div class="col-md-2 px-2"
               [ngClass]="{ disabled: this.passedForPayment.count == 0 || !isBuildReportAccess }"
               (click)="fnExportClick()">
            <div class="dashboard-widgets-sm">
              <div class="d-flex justify-content-between align-items-start">
                <p class="widget-p-head">{{ "passed_for_payment" | translate: param }}</p>
                <button class="btn eod-btn-danger btn-sm text-decoration-none"
                        *ngIf="this.passedForPayment.count > 0 && isBuildReportAccess">{{ "label_export" | translate:
                  param
                  }}</button>
              </div>

              <div class="d-flex justify-content-between">
                <div>
                  <h6>{{ this.passedForPayment.claimants }}</h6>
                  <p class="eod-text-grey-02">{{ "claimants" | translate: param }}</p>
                </div>
                <!-- <div *ngIf="!isMultipleCountryPackagePurchasedOrTrial">
                  <h6 class="text-right">{{ this.passedForPayment.amount | number: ".2" | noComma }} <span
                          class="eod-text-primary">{{ this.organisationCurrency }}</span></h6>
                  <p class="eod-text-grey-02 text-right">{{ "label_amount" | translate: param }}</p>
                </div> -->
                <div class="text-right">
                  <h6>{{ this.passedForPayment.count }}</h6>
                  <p class="eod-text-grey-02">{{ "item_count" | translate: param }}</p>
                </div>
              </div>
            </div>
            <div class="widgets-b-border eod-bg-success"></div>
          </div>
        </div>
        <div class="row"
             id="overview-expenseGraph">
          <div class="col-md-4 px-2 mb-2">
            <div class="eod-card card h-100">
              <div class="card-header">
                <h6 class="mb-0">{{"manageCat_emp" | translate: param }}</h6>
              </div>
              <div class="card-body pb-1">
                <p class="text-center"><canvas id="canvasbar"></canvas></p>
                <div class="d-flex justify-content-between mt-2">
                  <small class="eod-text-warning"><i class="fas fa-circle"></i> <span class="eod-text-secondary pl-1 fw-600 ">{{"label_pending" | translate: param}}</span></small>
                  <small class="not-invited-state"><i class="fas fa-circle"></i> <span class="eod-text-secondary pl-1 fw-600">{{"label_notinvited" | translate: param}}</span></small>
                  <small class="color-green"><i class="fas fa-circle"></i> <span class="eod-text-secondary pl-1 fw-600">{{"label_active" | translate: param}}</span></small>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 px-2 mb-2">
            <div class="eod-card card h-100">
              <div class="card-header">
                <h6 class="mb-0">{{"mileage_analysis" | translate: param }}</h6>
              </div>
              <div class="card-body pb-1"
                   [hidden]="!isCanvasbar1Display">
                <p class="text-center">
                  <canvas id="canvasbar1"></canvas>
                </p>
                <div class="d-flex justify-content-between mt-2">
                  <small class="color-theme"><i class="fas fa-circle"></i> <span class="eod-text-secondary pl-1 fw-600 ">{{ "manual_mileage" | translate: param}}</span></small>
                  <small class="color-green"><i class="fas fa-circle"></i> <span class="eod-text-secondary pl-1 fw-600 ">{{ "mileage_google" | translate: param}}</span></small>
                  <small class="color-brown"><i class="fas fa-circle"></i> <span class="eod-text-secondary pl-1 fw-600 ">{{ "mileage_gps" | translate: param}}</span></small>
                </div>
              </div>
              <div class="card-body pb-1"
                   [hidden]="isCanvasbar1Display">
                <p class="text-center">
                  <img alt="vehicle_summary"
                       src="assets/svg-icons/dashboard/dummy-Mileage-chart.svg" />
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4 px-2 mb-2">
            <div class="eod-card card h-100">
              <div class="card-header">
                <h6 class="mb-0">{{ 'approval_rejection_trend' | translate}}</h6>
              </div>
              <div class="card-body pb-1"
                   [hidden]="isCanvasbar2Display">
                <img alt="vehicle_summary"
                     src="assets/svg-icons/dashboard/dummy-Approval-chart.svg" />
              </div>
              <div class="card-body pb-1"
                   [hidden]="!isCanvasbar2Display">
                <p class="text-center">
                  <canvas id="canvas"></canvas>
                </p>
                <div class="d-flex justify-content-between mt-2">
                  <small class="color-green"><i class="fas fa-circle"></i> <span class="eod-text-secondary pl-1 fw-600 ">{{ "approved_expenses" | translate: param}}</span></small>
                  <small class="color-red"><i class="fas fa-circle"></i> <span class="eod-text-secondary pl-1 fw-600 ">{{ "rejected_expenses" | translate: param}}</span></small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row"
             id="miscOverview">
          <div class="col-md-2 px-2">
            <div class="dashboard-xs-widget"
                 *ngIf="isAdvancedMileageAccess"
                 (click)="showMileageMarketingPopup(1)">
              <img alt="vehicle_summary"
                   src="assets/svg-icons/dashboard/vehicle_summary.svg"
                   class="svg-30" />
              <p> {{ "vehicle_type_summary" | translate: param }} </p>
            </div>
          </div>
          <div class="col-md-2 px-2">
            <div class="dashboard-xs-widget"
                 *ngIf="isCardAccess"
                 (click)="showCreditCardMarketingPopup()">
              <img alt="setup_credit_card"
                   src="assets/svg-icons/dashboard/setup_credit_card.svg"
                   class="svg-30" />
              <p>{{ "credit_card_dashboard" | translate: param }}</p>
            </div>
          </div>
          <div class="col-md-2 px-2 d-none">
            <div class="dashboard-xs-widget">
              <img alt="advances-dashboard"
                   src="/assets/svg-icons/advances-dashboard.svg"
                   class="svg-30" />
              <p>{{ "advances_dashboard" | translate: param }}</p>
            </div>
          </div>
          <div class="col-md-2 px-2">
            <div class="dashboard-xs-widget"
                 *ngIf="isAdvancedMileageAccess"
                 (click)="showMileageMarketingPopup(2)">
              <img alt="mileage"
                   src="assets/svg-icons/dashboard/Basic_mileage_advance_mileage_rates.svg"
                   class="svg-30" />
              <p>{{ "mileage_analysis" | translate: param }}</p>
            </div>
          </div>
          <div class="col-md-2 px-2">
            <div class="dashboard-xs-widget"
                 *ngIf="isManageCategoryAccess"
                 (click)="showManageCategoryMarketingPopup()">
              <img alt="expense_category_management"
                   src="assets/svg-icons/dashboard/expense_category_management.svg"
                   class="svg-30" />
              <p>{{ "top_10_spenders_categories" | translate: param }}</p>
            </div>
          </div>
          <div class="col-md-2 px-2" *ngIf="isLiabilityReportAccess">
            <div class="dashboard-xs-widget"
                 (click)="showLiabilityReport()">
              <img alt="liability_report"
                   src="assets/svg-icons/dashboard/liability_report.svg"
                   class="svg-30" />
              <p>{{ "liabilityReport_header" | translate: param }}</p>
            </div>
          </div>
          <div class="col-md-2 px-2">
            <div class="dashboard-xs-widget"
                 *ngIf="isAuditTrailAccess"
                 (click)="showAuditTrail()">
              <img alt="audittrail"
                   src="assets/svg-icons/dashboard/audittrail.svg"
                   class="svg-30" />
              <p>{{ "audit_trial" | translate: param }}</p>
            </div>
          </div>
          <div class="col-md-2 px-2">
            <div class="dashboard-xs-widget"
                 *ngIf="isNRFCAccess"
                 (click)="fnDisplayMarketingPopUp(this.functionPackageEnum.NonReimbursableFuel)">
              <img alt="Non_Reimbursable_Fuel"
                   src="assets/svg-icons/dashboard/Non_Reimbursable_Fuel.svg"
                   class="svg-30" />
              <p>{{ "nr_dashboard" | translate: param }}</p>
            </div>
          </div>
          <div class="col-md-2 px-2">
            <div class="dashboard-xs-widget"
                 *ngIf="isNRFCAccess"
                 (click)="fnDisplayMarketingPopUp(this.functionPackageEnum.FuelCard)">
              <img alt="Fuel Card"
                   src="assets/svg-icons/dashboard/Fuel Card.svg"
                   class="svg-30" />
              <p>{{ "fc_report" | translate: param }}</p>
            </div>
          </div>
          <div class="col-md-2 px-2">
            <div class="dashboard-xs-widget"
                 (click)="fnpolicyDashboardNavigation()">
              <img alt="Expense Policy Dashboard"
                   src="assets/svg-icons/dashboard/Expense_policy_settings_icon.svg"
                   class="svg-30" />
              <p>{{ "expense_policy_dashboard" | translate: param }}</p>
            </div>
          </div>
          <div class="col-md-2 px-2" *ngIf="isAdvancesAccess">
            <div class="dashboard-xs-widget"
                 (click)="showCarbonFootprint()">
              <img alt="Carbon_Footprint"
                   src="assets/svg-icons/dashboard/Carbon_Footprint.svg"
                   class="svg-30" />
              <p>{{ 'carbon_footprint' | translate }}</p>
            </div>
          </div>
          <div class="col-md-2 px-2" *ngIf="isAdvancesAccess">
            <div class="dashboard-xs-widget"
                 
                 (click)="showAdvances()">
              <img alt="advances"
                   src="assets/svg-icons/dashboard/advances_new.svg"
                   class="svg-30" />
              <p>{{ 'label_Advance_dash' | translate }}</p>
            </div>
          </div>
          <div class="col-md-2 px-2"
               *ngIf="isVendorAccess && IsAPAutomationAllowed">
            <div class="dashboard-xs-widget"
                 (click)="showAPAutomation()">
              <img alt="Vendors"
                   src="assets/svg-icons/dashboard/Vendors.svg"
                   class="svg-30" />
              <p>{{ 'label_vendor_dashborad' | translate }}</p>
            </div>
          </div>
          <div class="col-md-2 px-2 "
               *ngIf="isLeaveManagementAccess && IsLeaveManagementAllowed && !(isVendorAccess && IsAPAutomationAllowed)">
            <div class="dashboard-xs-widget"
                 (click)="showLeaveManagementDashboard()">
              <img alt="leavemanagement"
                   src="assets/svg-icons/dashboard/leavemanagement.svg"
                   class="svg-30" />
              <p>{{ 'leave_management' | translate }}</p>
            </div>
          </div>
          <div class="col-md-2 px-2"
               *ngIf="isLeaveManagementAccess && IsLeaveManagementAllowed && (isVendorAccess && IsAPAutomationAllowed)">
            <div class="dashboard-xs-widget"
                 (click)="showLeaveManagementDashboard()">
              <img alt="leavemanagement"
                   src="assets/svg-icons/dashboard/leavemanagement.svg"
                   class="svg-30" />
              <p>{{ 'leave_management' | translate }}</p>
            </div>
          </div>
        </div>
      </div>

      <marketing-popup *ngIf="apAutomationPopup"
                       [data]="marketingPopupPanel"
                       (closePanel)="closeAPAutomation()">
      </marketing-popup>

      <marketing-popup *ngIf="liabilityReportPopup"
                       [data]="liabilityReportPopupModel"
                       (closePanel)="closeLiabilityReport()">
      </marketing-popup>

      <marketing-popup *ngIf="cfpPopup"
                       [data]="cfpMarketingPopupModel"
                       (closePanel)="closeMarketingPopup()">
      </marketing-popup>

      <marketing-popup *ngIf="advancesPopup"
                       [data]="advancesMarketingPopupModel"
                       (closePanel)="closeMarketingPopup()">
      </marketing-popup>

      <marketing-popup *ngIf="auditTrailPopup"
                       [data]="liabilityReportPopupModel"
                       (closePanel)="closeAuditTrail()">
      </marketing-popup>

      <marketing-popup *ngIf="leaveManagementPopup"
                       [data]="liabilityReportPopupModel"
                       (closePanel)="closeLeaveManagement()">
      </marketing-popup>

      <marketing-popup *ngIf="twoStepApprovalPopup"
                       [data]="liabilityReportPopupModel"
                       (closePanel)="closeMarketingPopup()">
      </marketing-popup>

      <app-googlePackagePurchaseReminderPanel *ngIf="this.showGooglePackagePurchaseReminder">
      </app-googlePackagePurchaseReminderPanel>

      <app-alert-panel [data]="alertPanelModel"
                       isConfirmDisplay="false"
                       *ngIf="isAlertDisplay"
                       (callbackEvent)="alertPanelReceiver($event)">
      </app-alert-panel>

      <div *ngIf="showPendingDeputiesPopup"
           class="overLayDiv active"></div>
      <div *ngIf="showPendingDeputiesPopup"
           id="rightSideBar"
           style="display: block">
        <div class="rightSidePanel"
             style="display: block">
          <header>
            <div class="row">
              <div class="
                col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12
                no-padding-right-left
              ">
                <div class="row">
                  <div class="
                    col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8
                    padding-left-0
                  ">
                    <h2>{{ "information" | translate: param }}</h2>
                  </div>

                  <div class="
                    col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4
                    padding-right-0
                    header-icons
                    text-right
                  "></div>
                </div>
              </div>
            </div>
          </header>
          <div class="clear"></div>
          <div class="
            col-md-12 col-sm-12 col-lg-12 col-xl-12
            margin-top-10
            no-padding-right-left
          ">
            <div class="block">
              <div class="row text-left font-weight-bold">
                <p class="text-left w-100pc">
                  {{ noOfApproversDoNotHaveDeputies }}
                  {{ "approvers_do_not_have_deputies" | translate: param }}
                </p>
              </div>
            </div>
          </div>

          <div class="clear"></div>
          <div class="
            col-md-12 col-sm-12 col-lg-12 col-xl-12
            margin-top-10
            no-padding-right-left
          ">
            <div class="block">
              <ul class="manageEmp--Notes">
                <li class="list-style-none">
                  {{ "please_assign_deputies_to_approver" | translate: param }}
                </li>
              </ul>
            </div>
          </div>
          <div class="
            d-flex
            position-absolute
            bottom-10
            w-100pc
            justify-content-center
            bg-white
            padding-15
          ">
            <span class="margin-right-15"><button class="btn btn-outline-primary"
                      (click)="closePendingDeputiesPopup()">
                {{ "change_password_cancel" | translate: param }}
              </button></span>
            <span class="margin-right-15"><button class="btn btn-primary"
                      (click)="navigateToDeputiesToApprover()">
                {{ "setup" | translate: param }}
              </button></span>
          </div>
        </div>
      </div>

      <div *ngIf="showPendingEmployeeWithGradePopup"
           class="overLayDiv active"></div>
      <div *ngIf="showPendingEmployeeWithGradePopup"
           id="rightSideBar"
           style="display: block">
        <div class="rightSidePanel"
             style="display: block">
          <header>
            <div class="row">
              <div class="
                col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12
                no-padding-right-left
              ">
                <div class="row">
                  <div class="
                    col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8
                    padding-left-0
                  ">
                    <h2>{{ "information" | translate: param }}</h2>
                  </div>

                  <div class="
                    col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4
                    padding-right-0
                    header-icons
                    text-right
                  "></div>
                </div>
              </div>
            </div>
          </header>
          <div class="clear"></div>
          <div class="
            col-md-12 col-sm-12 col-lg-12 col-xl-12
            margin-top-10
            no-padding-right-left
          ">
            <div class="block">
              <div class="row text-left font-weight-bold">
                <p class="text-left w-100pc">
                  {{ noOfEmployeeDoNotHaveGrade }}
                  {{ "employee_do_not_have_grade" | translate: param }}
                </p>
              </div>
            </div>
          </div>

          <div class="clear"></div>
          <div class="
            col-md-12 col-sm-12 col-lg-12 col-xl-12
            margin-top-10
            no-padding-right-left
          ">
            <div class="block">
              <ul>
                <li>
                  <p class=""><span><i class="fas fa-info-circle text-mustard"></i></span>
                    <span class="padding-left-3">
                      {{ "please_assign_rate_to_grade" | translate: param }}</span>
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div class="
            d-flex
            position-absolute
            bottom-10
            w-100pc
            justify-content-center
            bg-white
            padding-15
          ">
            <span class="margin-right-15"><button class="btn btn-outline-primary"
                      (click)="closePendingEmployeeWithGrade()">
                {{ "change_password_cancel" | translate: param }}
              </button></span>
            <span class="margin-right-15"><button class="btn btn-primary"
                      (click)="navigateToAssignGradesToClaimant()">
                {{ "setup" | translate: param }}
              </button></span>
          </div>
        </div>
      </div>

      <marketing-popup *ngIf="this.nrfcConfigModel.isMarketingPopUpDisplay"
                       [data]="this.nrfcConfigModel.secondaryFMPopupModel"
                       (closePanel)="closeSecondaryFMPopup()">
      </marketing-popup>
      <marketing-popup *ngIf="creditCardPopup"
                       [data]="creditCardMarketingPopupModel"
                       (closePanel)="closeMarketingPopup()">
      </marketing-popup>
      <marketing-popup *ngIf="categoryMarketingPopup"
                       [data]="manageCategoryMarketingPopupModel"
                       (closePanel)="closeMarketingPopup()">
      </marketing-popup>
      <marketing-popup *ngIf="mileageMarketingPopup"
                       [data]="mileageMarketingPopupModel"
                       (closePanel)="closeMarketingPopup()">
      </marketing-popup>
    </div>

  </div>

  <div class="overLayDiv"
       [ngClass]="{'active': costCentresWithoutApproverCount }"></div>
  <div id="rightSideBar">
    <div class="rightSidePanel"
         [ngClass]="{'active': costCentresWithoutApproverCount }">
      <header>
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding-right-left">
            <div class="row">
              <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8 padding-left-0">
                <h2>{{ 'information' | translate }}</h2>
              </div>
              <div
                   class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 padding-right-0 header-icons text-right no-active">
              </div>
            </div>
          </div>
        </div>
      </header>
      <div class="clear"></div>
      <div class="block">
        <ul>
          <li class="list-style-none">
            <span class="color-black"
                  [innerHTML]="costCentresWithoutApproversMessage"></span>
          </li>
        </ul>
      </div>
      <div class="block">
        <ul class="manageEmp--Notes margin-left-rem"
            style="padding-right:5px;">
          <li class="list-style-none">
            <span class="color-black">{{ 'note_assign_approvers_cc_urgent' | translate: param }}</span>
          </li>
        </ul>
      </div>
      <div class="d-flex position-absolute bottom-0 w-100pc justify-content-center bg-white padding-15">
        <span class="margin-right-15">
          <button class="btn btn-outline-primary bg-white fw-600 height-38px"
                  (click)="closeCostCentreAlertPanel()">{{
            'cancel_text' | translate }}
          </button>
        </span>
        <span class="margin-right-0">
          <button class="btn btn-primary"
                  (click)="goToAssignCostCentreApprovers()">{{ 'label_setup' | translate : param
            }}
          </button>
        </span>
      </div>
    </div>
  </div>

  <app-approval-items *ngIf="showOrphanspopUp"
                      (closePanel)="closeOrphansPopup()"
                      [fmAsApproverSummary]="fmAsApproverSummary"
                      [fmAsApproverData]="fmAsApproverData">
  </app-approval-items>
  <app-alert-panel *ngIf="showDutyofCarePopup"
                   [data]="showDutyofCarePopupModel"
                   (callbackEvent)="fnDutyofCarePopupReceiver($event)">
  </app-alert-panel>

  <app-alert-panel [data]="alertPanelModel"
                   (callbackEvent)="alertPanelReceiver($event)"></app-alert-panel>

  <preset-export *ngIf="showPresetExportPanel"
                 [selectedPresetExportStatus]="selectedPresetExportStatus"
                 (closePanel)="closePresetExportPanel()"
                 [isCounterApproverPurchased]="isCounterApproverPurchased"></preset-export>

  <stripe-invoice-failed-details-panel *ngIf="showStripPaymentFailedPanel"
                                       (closePanelEvent)="fnCloseStripPaymentFailedPanel($event)">
  </stripe-invoice-failed-details-panel>

  <app-recommendation *ngIf="showRecommendationPanel"
                      [currentRecomm]="currentRecommModel"
                      (closePanel)="closeRecommendationPanel()"
                      [orgRecomm]="orgRecomm">
  </app-recommendation>



  <!-- Button trigger modal -->
  <button id="openModalButton"
          type="button"
          [hidden]="true"
          class="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop">
          {{ 'modal_open' | translate}}
  </button>

  <!-- Modal -->
  <div class="modal fade"
       id="staticBackdrop"
       data-backdrop="static"
       data-keyboard="false"
       tabindex="-1"
       aria-labelledby="staticBackdropLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl border-radius-15px">
      <div class="modal-content border-radius-1-5">
        <div class="modal-body p-4">
          <img src="../../assets/images/founder.png"
               class="founder-img"
               alt="">
          <p class="mb-2"><b>{{'message_from_founder' | translate}}</b></p>
          <p class="mb-2 eod-text-pink text-capitalize"><b>{{'dear'}} {{loggedInUserDisplayName}},</b></p>
          <p class="text-justify">{{'welcome_to_expense_on_demand'| translate}}
            <br><br>{{'message_from_founder_1' | translate}}
            <br><br>{{'message_from_founder_2' | translate}}
            <br><br>{{'message_from_founder_3' | translate}}
            <br><br>{{'message_from_founder_4' | translate}}
            <br><br>{{'message_from_founder_5' | translate}}
          </p>
          <p class="mt-4 mb-3">{{'thank_you_for_choosing_eod' |translate}}</p>
          <p><b>{{'sincerely' | translate}} <br> {{'founder_name' | translate}} </b></p>
        </div>
        <div class="modal-footer justify-content-center">
          <button type="button"
                  class="btn eod-btn-primary"
                  data-bs-dismiss="modal"
                  (click)="onCloseFoundersNote()">{{'label_ok' | translate}}</button>
        </div>
      </div>
    </div>
  </div>



  <setup-pay-step-panel *ngIf="showSetupSkipPayStepPanel"
                        [data]="setupSetupSkipPayStepPanelInputmodel"
                        (closePanelEvent)="fnCloseSetupSkipPayStepPanel($event)"
                        (submitEvent)="fnSubmitSetupSkipPayStepPanel($event)">
  </setup-pay-step-panel>

  <basic-function-explore-alert-panel *ngIf="this.showBasicFunctionExploreAlertPanel"
                                      (closePanelEvent)="fnCloseBasicFunctionExploreAlertPanel($event)">
  </basic-function-explore-alert-panel>

  <feedback-panel *ngIf="isFeedbackPanelActive"
                  [type]="feedbackPanelType"
                  (closePanel)="onCloseFeedbackPanel()">
  </feedback-panel>