import { Component, ViewEncapsulation } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: 'card-assigned-username-cell',
  templateUrl: './card-assigned-username-renderer.component.html',
  encapsulation: ViewEncapsulation.None
})

export class CardAssignedUsernameRendererComponent implements ICellRendererAngularComp {
  public userId: number;
  public userName: string = "";

  constructor() {
  }

  initilize(params: ICellRendererParams) {
    if (params && params.data) {
      this.userId = params.data.userId;
      this.userName = params.data.userName;
    }
  }

  // called on init
  agInit(params: any): void {
    this.initilize(params);
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.initilize(params);
    return true;
  }

}
