import { Component, ViewEncapsulation } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: 'checkbox-cell',
  templateUrl: './checkbox-renderer.component.html',
  styleUrls: ['./checkbox-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CheckBoxRendererComponent implements ICellRendererAngularComp {
  isSelected: boolean;
  isCheckboxDisabled: boolean;
  params: any;

  constructor() {
  }

  initilize(params: ICellRendererParams) {
    if (params.data) {
      this.params = params;
      this.isSelected = params.getValue();
      this.isCheckboxDisabled = params.data.isCheckboxDisabled;
    }
  }

  onClick(event) {
    this.isSelected = event.target.checked;
    this.params.setValue(this.isSelected);

    if (this.params.onClick instanceof Function && event && event.target) {
      this.params.onClick(event.target.checked);
    }

  }

  // called on init
  agInit(params: any): void {
    this.initilize(params);
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.initilize(params);
    return true;
  }

}




