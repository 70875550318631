<h4 class="eod-text-secondary fw-600">{{'step_3_5' | translate}}</h4>
<h1 class="onboarding-title pt-3">{{'details_about_your_org' | translate}}</h1>
<div class="form onboarding onboarding-width mt-5 d-flex flex-column align-content-between">
       <form class="row">
              <div class="form-group col-md-12 px-0">
                     <label class="input-label"
                            for="organisation-name">{{'organisation_Name' | translate}}<span>*</span></label>
                     <input class="form-control form-input"
                            type="text"
                            id="organisation-name"
                            name="orgName"
                            [(ngModel)]="orgName"
                            #orgNameField />
                     <div *ngIf="orgName.trim() == '' && submitted"
                          class="alert-danger">
                            <div *ngIf="orgName.trim() == '' && submitted">
                                   <b>{{ "label_Required" | translate: param }}</b>
                            </div>
                     </div>

              </div>
              <div class="form-group col-md-6 pl-0"
                   *ngIf="countryList && countryList.length>0">
                     <!-- <input class="form-control form-input" type="text" id="passwrod" required /> -->
                     <label class="input-label"
                            for="country">{{'Country' | translate }}<span>*</span></label>
                     <select class="form-input"
                             id="country"
                             name="country"
                             [(ngModel)]="selectedCountryId"
                             (change)="onCountryChange()"
                             style="max-width: 240px;">
                            <option *ngFor="let c of countryList"
                                    [value]="c.countryId">{{c.countryName}}</option>
                     </select>

              </div>
              <div class="form-group col-md-6 px-0 position-relative "
                   *ngIf="countryList && countryList.length>0">
                     <label class="input-label"
                            for="password">{{'label_currency' | translate}}<span>*</span></label>
                     <input class="form-control form-input pl-5 disabled"
                            type="text"
                            id="password"
                            [value]="this.assignedCountry?.currencyName"
                            disabled />

                     <img class="currency-flag top-35px"
                          src="{{this.assignedCountry?.currencyIcon}}"
                          alt="">
              </div>
       </form>
       <button class="btn eod-btn-secondary btn-lg btn-block mt-auto"
               (click)="onSubmit()">{{'next' | translate}}</button>
</div>