<div class="overLayDiv z-99999 active"></div>
<div id="rightSideBar">
    <div class="rightSidePanel active w-60pc" style="z-index: 99999;">
        <header>
            <div class="row">
                <div class="col-lg-12 no-padding-right-left">
                    <div class="row">
                        <div class="col-lg-8 padding-left-0">
                            <h2>{{expenseCategoryName}}{{'view_expense_policy' | translate:param}}</h2>
                        </div>
                        <div
                            class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 padding-right-0 header-icons text-right">
                            <ul class="d-flex justify-content-end w-100pc">
                                <li (click)="fnCloseExpensePolicyPanel()" class="padding-right-1">
                                    <Button
                                        class="btn btn-outline-secondary border-color-warning text-mustard height-38px">
                                        {{ 'label_close' | translate: param }}
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div class="clear"></div>
        <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 margin-top-10 no-padding-right-left">
                <div class="block padding-top-20 ">
                    <div class="row">
                        <div class="col-lg-12 no-padding-right-left">
                            <ul class="bulkList pointer-events-none">
                                
                                <li class="d-flex border-bottom padding-bottom-12  f-14"
                                    *ngFor="let policy of policyDetails">
                                    <div class="fw-600" [ngClass]="policy.highlightedClass">
                                    <span class="d-inline-block w-80pc">{{policy.policyName}}</span>
                                    <span class="d-inline-block w-20pc text-right"
                                        *ngIf="policy.policyType==2 && policy.policyValue==1">ON</span>
                                    <span class="d-inline-block w-20pc text-right"
                                        *ngIf="policy.policyType==2 && policy.policyValue==0">OFF</span>
                                    <span class="d-inline-block w-20pc text-right"
                                        *ngIf="policy.policyType==1">{{policy.policyValue.toFixed(2)}} </span>
                                    <span class="d-inline-block w-20pc text-right"
                                        *ngIf="policy.policyType==3">{{policy.policyValue}} {{'days' |
                                        translate:param}}</span>
                                    </div>
                                    <div class="mt-2" *ngIf="policy.notes.length>0">
                                        <h6 class="color-orange w-100pc mb-1">{{ 'policy_notes' | translate: param }}:</h6>
                                        <p class="f-13 lH-normal wordbreak" >{{policy.notes}}
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>