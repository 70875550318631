import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';


@Component({
  selector: 'formly-six-digit-before-after-decimal-input',
  templateUrl: "./formly-six-digit-before-after-decimal-input.component.html"
})
export class FormlySixDigitBeforeAfterDecimalInputComponent extends FieldType implements OnInit, OnDestroy {

  isFormSubmitted: boolean = false;
  formSubmissionSubscription: Subscription;
  fieldKey: string;
  beforeDecimal: number = 6;
  afterDecimal: number = 6;

  constructor(private dynamicFormService: DynamicFormService) {
    super();
  }

  ngOnInit() {
    if (this.field.key) {
      this.fieldKey = this.field.key.toString();
    }
    if (this.field?.templateOptions?.beforeDecimal) {
      this.beforeDecimal = this.field.templateOptions.beforeDecimal;
    }
    if (this.field?.templateOptions?.afterDecimal) {
      this.afterDecimal = this.field.templateOptions.afterDecimal;
    }
    this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
      this.isFormSubmitted = data;
    });
  }

  ngOnDestroy() {
    if (this.formSubmissionSubscription) {
      this.formSubmissionSubscription.unsubscribe();
    }
  }

  onNumberInput() {
    this.to.change(this.field, this.formControl.value);
  }

  onKeyPress(event) {
    const value = parseInt(this.formControl.value + event.key);
    if (this.to.max) {
      if (value > this.to.max) {
        return false;
      }
    }
    if (this.to.min) {
      if (value < this.to.min) {
        return false;
      }
    }
    if (this.to.omitNegativeSign) {
      return event.charCode != 45;
    }
    return true;
  }

}
